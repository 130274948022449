import type { ComponentProps } from "react";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "common/core/current_user_role";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { segmentTrack } from "util/segment";
import { OrganizationTypeEnum } from "graphql_globals";

import { NewBusinessTransactionMenu } from "./business";
import { NewRealEstateTransactionMenu } from "./real_estate";
import type { NewTransactionUser as User } from "./index.fragment.graphql";

type Props = {
  user: User;
  recipient?: ComponentProps<
    typeof NewBusinessTransactionMenu | typeof NewRealEstateTransactionMenu
  >["recipient"];
};

/**
 * @description
 * This is intended to be used in places where different org types can be viewing this menu. For
 * example, we show this in the Identities Profile page, where the viewing user could be a lender
 * or regular business user. In these cases, we have made the following sacrifices:
 * 1. We don't show the Dotloop import option for businesses.
 * 2. We don't check eligibilities before navigating Real Estate (specifically title) customers
 *    to the setup/v2 route.
 *
 * Please use this intentionally. If you need the above functionality, import and use the specific
 * menus for the org type directly.
 */
export function NewTransactionMenu({ user, recipient }: Props) {
  const { notaryProfile, organization } = user;
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();

  if (
    !organization ||
    !hasPermissionFor("createOrganizationTransactions") ||
    CURRENT_PORTAL === "admin"
  ) {
    return null;
  }

  const showPlaceOrderUI = Boolean(
    organization.placeAnOrderEnabled && !hasPermissionFor("manageOpenOrders"),
  );

  if (organization.organizationType === OrganizationTypeEnum.BUSINESS) {
    return (
      <NewBusinessTransactionMenu
        notaryProfile={notaryProfile}
        orgIsActive={organization.active}
        recipient={recipient}
      />
    );
  }

  if (CURRENT_PORTAL === "lender" || CURRENT_PORTAL === "title_agency") {
    return (
      <NewRealEstateTransactionMenu
        portal={CURRENT_PORTAL}
        showPlaceOrderUI={showPlaceOrderUI}
        orgIsActive={organization.active}
        handleRealEstateOption={() => {
          segmentTrack("User clicked new transaction menu", {
            portal: CURRENT_PORTAL,
          });
          navigate("/transaction/setup/v2");
        }}
        recipient={recipient}
      />
    );
  }

  return null;
}
