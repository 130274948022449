import { memo, type ComponentType, type ReactNode, useState, useEffect } from "react";
import { useIntl, defineMessages } from "react-intl";

import addIcon from "assets/images/add.svg";
import removeIcon from "assets/images/remove.svg";
import fullscreenIcon from "assets/images/fullscreen.svg";
import Svg from "common/core/svg";
import SROnly from "common/core/screen_reader";

import { usePDFContext } from ".";
import { type KitInstance, type KitModule, PDFControlButton } from "./util";
import Styles from "./zoom.module.scss";

type ZoomAction = keyof typeof MESSAGES;

const MESSAGES = defineMessages({
  zoomIn: {
    id: "7b444456-e699-48dc-908c-532fa625f9f6",
    defaultMessage: "Zoom in",
  },
  zoomOut: {
    id: "596c70bd-48fd-4333-9aef-781607b151e1",
    defaultMessage: "Zoom out",
  },
  fitToWidth: {
    id: "6ae41d6c-6d1c-4184-8170-c9c8d897579a",
    defaultMessage: "Focus",
  },
  auto: {
    id: "f2c5a0b7-3e6d-4b9c-8f0e-5f2d1d9e4f1a",
    defaultMessage: "Auto",
  },
});

const LABELS = defineMessages({
  clicked: {
    id: "a88aa3bc-5d2f-473c-a87e-fe68dc6a7929",
    defaultMessage: "{actionLabel} button clicked",
  },
});

function createZoomControl(action: ZoomAction, children: ReactNode, displayName: string) {
  const component: ComponentType = function ZoomControl() {
    const { zoom } = usePDFContext();
    const intl = useIntl();
    const actionLabel = intl.formatMessage(MESSAGES[action]);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
      const handleClick = () => {
        if (clicked) {
          return setTimeout(() => {
            setClicked(false);
          }, 1000);
        }
      };
      const timeout = handleClick();

      return () => {
        if (timeout) {
          window.clearTimeout(timeout);
        }
      };
    }, [clicked]);

    return zoom ? (
      <>
        <SROnly>
          <div role="status">{clicked && intl.formatMessage(LABELS.clicked, { actionLabel })}</div>
        </SROnly>
        <PDFControlButton
          aria-label={actionLabel}
          onClick={() => {
            zoom(action);
            setClicked(true);
          }}
        >
          {children}
        </PDFControlButton>
      </>
    ) : null;
  };
  component.displayName = displayName;
  return memo(component);
}

export const ZoomInButton = createZoomControl(
  "zoomIn",
  <Svg className={Styles.zoom} src={addIcon} aria-hidden="true" alt="" />,
  "ZoomInButton",
);
export const ZoomOutButton = createZoomControl(
  "zoomOut",
  <Svg className={Styles.zoomOut} src={removeIcon} aria-hidden="true" alt="" />,
  "ZoomOutButton",
);
export const FitToWidthButton = createZoomControl(
  "fitToWidth",
  <Svg className={Styles.fullscreen} src={fullscreenIcon} aria-hidden="true" alt="" />,
  "FitToWidthButton",
);

export const MobilePdfControlOption = ({
  action,
  children,
}: {
  action: ZoomAction;
  children: ReactNode;
}) => {
  const { zoom } = usePDFContext();
  const intl = useIntl();
  const actionLabel = intl.formatMessage(MESSAGES[action]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const handleClick = () => {
      if (clicked) {
        return setTimeout(() => {
          setClicked(false);
        }, 1000);
      }
    };
    const timeout = handleClick();

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [clicked]);

  return zoom ? (
    <>
      <SROnly>
        <div role="status">{clicked && intl.formatMessage(LABELS.clicked, { actionLabel })}</div>
      </SROnly>
      <div
        aria-label={actionLabel}
        onClick={() => {
          zoom(action);
          setClicked(true);
        }}
      >
        {children}
      </div>
    </>
  ) : null;
};

export function makeZoomCallback(
  zoomMode: KitModule["ZoomMode"],
  instance: KitInstance,
): (action: ZoomAction) => void {
  return (action) => {
    switch (action) {
      case "zoomOut":
        return instance.setViewState((old) => old.zoomOut());
      case "zoomIn":
        return instance.setViewState((old) => old.zoomIn());
      case "fitToWidth":
        return instance.setViewState((old) => old.set("zoom", zoomMode.FIT_TO_WIDTH));
      case "auto":
        return instance.setViewState((old) => old.set("zoom", zoomMode.AUTO));
    }
  };
}
