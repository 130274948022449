import Env from "config/environment";
import { LOCAL_PORTAL_DEFAULT_PORT_MAP, type Subdomain } from "constants/app_subdomains";
import { hardNavigateTo } from "util/navigation";

type Path = {
  search: string;
  pathname: string;
};

type To = string | Partial<Path>;

function isStringPathname(path: To): path is string {
  return typeof path === "string";
}

function normalizePath(path?: To) {
  if (!path || isStringPathname(path)) {
    return path;
  }
  const search = path.search ? `?${path.search}` : "";
  return `${path.pathname || window.location.pathname}${search}`;
}

export function redirectUrl(targetAppSubdomain: Subdomain, path?: string): string {
  const { hostname } = window.location;
  let domain = `${targetAppSubdomain}.${Env.hostName}`;
  if (hostname === "localhost") {
    domain = `localhost:${LOCAL_PORTAL_DEFAULT_PORT_MAP[targetAppSubdomain]}`;
  } else if (
    hostname.endsWith(".dev.notarize.rocks") ||
    hostname.endsWith(".k8s.notarize.rocks") ||
    hostname.endsWith(".e2e.notarize.rocks") ||
    hostname.endsWith(".test.notarize.rocks")
  ) {
    // We special case preview environments with heuristics. To properly fix this, we need to be able
    // to parameterize the build with true TLDs. Right now the app has an assumption that all portals
    // are subdomains with a dot, which preview envs do not have.
    domain = `${targetAppSubdomain}-${hostname.replace(/^[^-]+-/, "")}`;
  }
  const { protocol } = window.location;
  return `${protocol}//${domain}${path || ""}`;
}

export function redirectToSubdomain(
  targetAppSubdomain: Subdomain,
  path?: To,
  { replace, newTab }: { replace?: boolean; newTab?: boolean } = {},
) {
  hardNavigateTo(redirectUrl(targetAppSubdomain, normalizePath(path)), { replace, newTab });
}
