import { FormattedMessage } from "react-intl";

import type { SigningRequirementEnum } from "graphql_globals";
import FormattedSigningRequirement from "common/core/format/formatted_signing_requirements";
import { DescriptionListItem } from "common/core/description_list";

type Props = {
  signingRequirement: SigningRequirementEnum;
};

export function SigningRequirements({ signingRequirement }: Props) {
  const signingRequirementsLabel = (
    <FormattedMessage
      id="a07edb58-8de9-48a2-9324-5e9fbfbe518c"
      defaultMessage="Signing requirements"
    />
  );

  const signingRequirementsContent = (
    <FormattedSigningRequirement signingRequirement={signingRequirement} />
  );

  return (
    <DescriptionListItem term={signingRequirementsLabel} definition={signingRequirementsContent} />
  );
}
