import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  Feature,
  AuthTypes,
  ParticipantTypes,
  ProofRequirementMfa,
  type CompletionRequirement,
  type SigningRequirementEnum,
} from "graphql_globals";
import { useCopy } from "util/clipboard";
import Button from "common/core/button";
import SignerAddress from "common/signer/items/address";
import CustomerEmail from "common/signer/items/customer_email";
import TransactionCustomerName from "common/signer/items/transaction_customer_name";
import { usePermissions } from "common/core/current_user_role";
import { DescriptionListItem } from "common/core/description_list";
import { DetailsDescriptionList } from "common/transactions/details/common";

import MeetingSignerDetails from "./meeting_signer_details";
import EsignAuthSignerDetails from "./esign_auth_signer_details";
import { ProofRequirements } from "./proof_requirements";
import { SignerDetailsPhone } from "./phone";
import type {
  DocumentBundleForSignerDetails_signers as Signer,
  DocumentBundleForSignerDetails_transaction_signerIdentities as TransactionSignerIdentity,
  DocumentBundleForSignerDetails_meetings_edges_node as Meeting,
  DocumentBundleForSignerDetails_meetings_edges_node_participants as MeetingParticipant,
  DocumentBundleForSignerDetails_meetings_edges_node_signerIdentities_customerInformation as MeetingSignerIdentity,
} from "./index_fragment.graphql";
import type { DocumentBundleForTransactionDetailsSigner_transaction_customerSigners_proofRequirement as ProofRequirement } from "../index_fragment.graphql";
import { SignerDetailsWrapper } from "./signer_details_wrapper";
import { SigningRequirements } from "./signing_requirements";
import { RecipientGroupDetails } from "./recipient_group";
import type { CustomerSignerForDetails_recipientGroup as RecipientGroup } from "../customer_signer_details_fragment.graphql";

type TransactionCustomer = {
  id: string;
  transactionAccessLink?: string | null;
  alternativeNames?: string[];
  email?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  phone?: null | {
    countryCode: string;
    number: string;
  };
  order?: number | null;
  proofRequirement: ProofRequirement | null;
  signingRequirement: SigningRequirementEnum | null;
  recipientGroup: RecipientGroup | null;
};

type Props = {
  completionRequirements: (CompletionRequirement | null)[];
  organizationFeatures: string[];
  organizationTierFeatures?: Feature[];
  bundleSignerInfo?: {
    id: string;
    contactInformation?: Signer["contactInformation"];
  };
  meetingsInfo?: Meeting[];
  signerIdentityId?: string;
  subheader: ReactNode;
  participantType?: MeetingParticipant["participantType"];
  validatedByCredibleWitness?: boolean;
  transactionCustomer?: TransactionCustomer;
  onUpdate: () => void;
  authenticationRequirement?: AuthTypes;
  esignAuthSignerIdentities?: TransactionSignerIdentity[];
  expandInitially?: boolean;
  hideSensitiveData?: boolean;
  showIdentityInfo: boolean;
};

type ContactInformationType =
  | Signer["contactInformation"]
  | MeetingSignerIdentity
  | undefined
  | null;

export function signerAndMeetingsInfo({
  signerInfo,
  signerIdentityId,
  meetingsInfo,
}: {
  signerInfo?: {
    id: string;
    contactInformation?: Signer["contactInformation"];
  };
  signerIdentityId?: string;
  meetingsInfo?: Meeting[];
}) {
  let contactInformation: ContactInformationType = signerInfo?.contactInformation;
  if (meetingsInfo) {
    const meetingsSignerInfo = meetingsInfo.flatMap((meeting) => {
      const notaryState =
        meeting.publicAgentDetails?.__typename === "PublicNotaryAgentDetails"
          ? meeting.publicAgentDetails.usStateName
          : undefined;
      return !meeting.signerIdentities
        ? []
        : meeting.signerIdentities.flatMap((signerIdentity) => {
            if (
              signerIdentity &&
              signerInfo?.id &&
              signerIdentity.customer?.id === signerInfo.id &&
              signerIdentity.id === signerIdentityId
            ) {
              contactInformation = signerIdentity.customerInformation;
              return [{ signerIdentity, notaryState }];
            }
            return [];
          });
    });
    return { contactInformation, meetingsSignerInfo };
  }
  return { contactInformation, meetingsSignerInfo: [] };
}

function GlobalId({ bundleSignerInfoId }: { bundleSignerInfoId: string }) {
  const globalIdLabel = (
    <FormattedMessage id="d924e787-1c42-4d29-8463-260c8212cda9" defaultMessage="Global ID" />
  );
  return <DescriptionListItem term={globalIdLabel} definition={bundleSignerInfoId} />;
}

function SignerAltNames({ transactionCustomer }: { transactionCustomer?: TransactionCustomer }) {
  const alternativeNamesLabel = (
    <FormattedMessage
      id="150bb340-6856-41db-a896-4f2cfed39309"
      defaultMessage="Alternative names"
    />
  );
  const alternativeNamesContent = transactionCustomer!
    .alternativeNames!.map((name) => `"${name}"`)
    .join(",");
  return <DescriptionListItem term={alternativeNamesLabel} definition={alternativeNamesContent} />;
}

function TransactionAccessLink({
  transactionCustomer,
}: {
  transactionCustomer: TransactionCustomer;
}) {
  const { copy, recentlyCopied } = useCopy();

  const accessLinkLabel = (
    <FormattedMessage
      id="0da3f372-e409-4f56-98db-bf2eac3cfbb1"
      defaultMessage="Signer's access link"
    />
  );

  const accessLinkButton = (
    <Button
      variant="tertiary"
      buttonSize="condensed"
      buttonColor="action"
      withIcon={{
        name: recentlyCopied ? "tick" : "copy",
        placement: "left",
      }}
      onClick={() => copy(transactionCustomer.transactionAccessLink || "")}
    >
      {recentlyCopied ? (
        <FormattedMessage id="8cec4847-00a3-4b2b-b6ee-c10d93ca496e" defaultMessage="Copied!" />
      ) : (
        <FormattedMessage id="6132d036-fc83-4779-bf65-4cd75e3c0449" defaultMessage="Copy" />
      )}
    </Button>
  );

  return <DescriptionListItem term={accessLinkLabel} definition={accessLinkButton} />;
}

function SignerDetails({
  completionRequirements,
  organizationFeatures,
  organizationTierFeatures,
  bundleSignerInfo,
  meetingsInfo = [],
  signerIdentityId,
  subheader,
  participantType,
  validatedByCredibleWitness,
  transactionCustomer,
  onUpdate,
  authenticationRequirement,
  esignAuthSignerIdentities = [],
  expandInitially = true,
  hideSensitiveData = false,
  showIdentityInfo,
}: Props) {
  const { hasPermissionFor } = usePermissions();

  const recipientGroup = transactionCustomer?.recipientGroup;

  const { contactInformation, meetingsSignerInfo } = signerAndMeetingsInfo({
    signerInfo: bundleSignerInfo,
    signerIdentityId,
    meetingsInfo,
  });

  const esignAuthsSignerInfo = esignAuthSignerIdentities.filter((signerIdentity) => {
    return (
      signerIdentity.proofRequirement?.ca?.selfie &&
      bundleSignerInfo?.id &&
      signerIdentity.customer!.id === bundleSignerInfo.id &&
      signerIdentity.photoId
    );
  });

  const showEmailView =
    (!participantType || participantType === ParticipantTypes.SIGNER) &&
    !hasPermissionFor("showEmailViewOnSignerDetails");
  const showFullRecord = Boolean(
    hasPermissionFor("showSignerRecord") ||
      organizationTierFeatures?.includes(Feature.TRANSACTION_RECORD_FULL_ACCESS),
  );

  const caSelfie = transactionCustomer?.proofRequirement?.ca?.selfie;
  const smsProof = transactionCustomer?.proofRequirement?.mfa?.type === ProofRequirementMfa.SMS;
  const ial2Proof = caSelfie && smsProof;
  const kbaProof = transactionCustomer?.proofRequirement?.kba;
  const isProofRequirement = ial2Proof || smsProof || kbaProof || caSelfie;

  return (
    <SignerDetailsWrapper
      order={transactionCustomer?.order}
      user={contactInformation}
      recipientGroupEmail={recipientGroup?.sharedInboxEmail}
      canShowEmail={showEmailView}
      subheader={subheader}
      expandInitially={expandInitially}
    >
      <DetailsDescriptionList>
        <>
          {showFullRecord && transactionCustomer && recipientGroup?.sharedInboxEmail && (
            <RecipientGroupDetails
              email={recipientGroup.sharedInboxEmail}
              user={contactInformation}
            />
          )}
          {showFullRecord && transactionCustomer?.id && !recipientGroup?.sharedInboxEmail && (
            <TransactionCustomerName
              transactionCustomer={transactionCustomer}
              onUpdate={onUpdate}
            />
          )}
          {showFullRecord && showIdentityInfo && contactInformation?.address && (
            <SignerAddress
              address={contactInformation.address}
              hideSensitiveData={hideSensitiveData}
            />
          )}
          {showEmailView && contactInformation?.email ? (
            <CustomerEmail
              email={contactInformation.email}
              canEdit={hasPermissionFor("editCustomerEmail")}
              transactionCustomerId={transactionCustomer?.id}
              onUpdate={onUpdate}
              hideSensitiveData={hideSensitiveData}
              data-automation-id="recipient-email"
            />
          ) : null}
          {showFullRecord && transactionCustomer?.phone && (
            <SignerDetailsPhone
              transactionCustomerId={transactionCustomer.id}
              phone={transactionCustomer.phone}
              onUpdate={onUpdate}
              smsAuthRequired={authenticationRequirement === AuthTypes.SMS}
              hideSensitiveData={hideSensitiveData}
              data-automation-id="recipient-phone"
            />
          )}
          {showFullRecord && isProofRequirement && (
            <ProofRequirements
              ial2Proof={ial2Proof}
              smsProof={smsProof}
              kbaProof={kbaProof}
              caSelfie={caSelfie}
            />
          )}
          {showFullRecord && bundleSignerInfo?.id && (
            <GlobalId bundleSignerInfoId={bundleSignerInfo.id} />
          )}
          {hasPermissionFor("signerAltNames") &&
            Boolean(transactionCustomer?.alternativeNames?.length) && (
              <SignerAltNames transactionCustomer={transactionCustomer} />
            )}
          {showFullRecord && transactionCustomer?.transactionAccessLink && (
            <TransactionAccessLink transactionCustomer={transactionCustomer} />
          )}
          {showFullRecord && transactionCustomer?.signingRequirement && (
            <SigningRequirements signingRequirement={transactionCustomer.signingRequirement} />
          )}
        </>
      </DetailsDescriptionList>
      {showFullRecord &&
        esignAuthsSignerInfo.map((signerIdentity, index) => (
          <EsignAuthSignerDetails
            key={signerIdentity.id}
            signerIdentity={signerIdentity}
            organizationFeatures={organizationFeatures}
            showIdentityInfo={showIdentityInfo}
            headerText={
              <FormattedMessage
                id="8135866f-3c01-4095-8951-67ca1496faa1"
                defaultMessage="Session {index}"
                values={{
                  index: esignAuthsSignerInfo.length > 1 ? esignAuthsSignerInfo.length - index : "",
                }}
              />
            }
          />
        ))}
      {showFullRecord &&
        meetingsSignerInfo.map((meetingSignerInfo, index) => (
          <MeetingSignerDetails
            key={meetingSignerInfo.signerIdentity.id}
            completionRequirements={completionRequirements}
            organizationFeatures={organizationFeatures}
            notaryState={meetingSignerInfo.notaryState}
            signerIdentity={meetingSignerInfo.signerIdentity}
            headerText={
              <FormattedMessage
                id="2dbfbd3d-5c9b-41ec-80b4-3df1f471bec3"
                defaultMessage="Meeting {index}"
                values={{
                  index: meetingsSignerInfo.length > 1 ? meetingsSignerInfo.length - index : "",
                }}
              />
            }
            validatedByCredibleWitness={validatedByCredibleWitness}
          />
        ))}
    </SignerDetailsWrapper>
  );
}

export default SignerDetails;
