import { merge, type Subject } from "rxjs";
import { useCallback, useEffect } from "react";

import type Channel from "socket/channel";
import { fromSocketEvent } from "socket/util";
import type { SocketEventName } from "common/meeting/socket/events";

type SocketOptimisticCacheUpdaterProps = {
  events: SocketEventName[];
  channel: Channel;
  cache$: Subject<{
    hasOptimisticUpdates: boolean;
  }>;
};

export function useSocketOptimisticCacheUpdater({
  events,
  channel,
  cache$,
}: SocketOptimisticCacheUpdaterProps) {
  const handleSocketMessage = useCallback(() => {
    cache$.next({ hasOptimisticUpdates: true });
  }, []);

  useEffect(() => {
    const subscription = merge(...events.map((type) => fromSocketEvent(channel, type))).subscribe(
      handleSocketMessage,
    );
    return () => subscription.unsubscribe();
  }, [channel, events, handleSocketMessage]);
}
