import { userFullName } from "util/user";

export type UserName = {
  firstName: string | null;
  middleName?: string | null;
  lastName: string | null;
} | null;

export function recipientFullName(
  recipient: UserName,
  nameNotProvidedMsg: string,
  documentBundle?: { participants: UserName[] | null; signers: UserName[] | null } | null,
) {
  const participants = documentBundle?.participants ?? [];
  const signers = documentBundle?.signers ?? [];

  return userFullName(
    recipient,
    userFullName(participants[0], userFullName(signers[0], nameNotProvidedMsg)),
  );
}

export type Email = {
  email: string | null;
} | null;

export function recipientEmail(
  recipient: Email,
  emailNotProvidedMsg: string,
  documentBundle: { participants: Email[] | null; signers: Email[] | null } | null,
) {
  const participants = documentBundle?.participants ?? [];
  const signers = documentBundle?.signers ?? [];

  return recipient?.email ?? participants[0]?.email ?? signers[0]?.email ?? emailNotProvidedMsg;
}
