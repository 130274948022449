import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import Button from "common/core/button";
import { userFullName } from "util/user";
import { b } from "util/html";
import { IconButton } from "common/core/button/icon_button";
import { Heading, Substyle } from "common/core/typography";
import WorkflowModal from "common/modals/workflow_modal";

import Styles from "./common.module.scss";

type SetRemoveModal = () => void;
type OnRemoveProps =
  | { onRemove?: never; openRemoveModal?: never; removeButtonLabel?: never }
  | {
      onRemove?: () => Promise<unknown>;
      openRemoveModal: SetRemoveModal;
      removeButtonLabel?: string;
    };

function RemoveContactButton({
  openRemoveModal,
  automationId,
  label,
}: {
  openRemoveModal: SetRemoveModal;
  automationId: string;
  label?: string;
}) {
  return (
    <IconButton
      className={Styles.remove}
      label={label}
      automationId={`remove-${automationId}`}
      name="delete"
      onClick={openRemoveModal}
      buttonColor="danger"
      variant="secondary"
    />
  );
}

export function ContactDetailsHeading({
  header,
  subheader,
  icon,
  onRemove,
  openRemoveModal,
  automationId,
  removeButtonLabel,
}: {
  header: ReactNode | string;
  subheader: ReactNode | string;
  icon?: ReactNode;
  automationId: string;
} & OnRemoveProps) {
  return (
    <div className={Styles.header}>
      <Heading level="h2" textStyle="subtitle" className={Styles.heading}>
        <span className={Styles.headingInner}>{header}</span>
        <Substyle size="small" className={Styles.role}>
          <span className={Styles.icon}>{icon && icon}</span>
          {subheader}
        </Substyle>
      </Heading>
      {onRemove && (
        <RemoveContactButton
          label={removeButtonLabel}
          automationId={automationId}
          openRemoveModal={openRemoveModal}
        />
      )}
    </div>
  );
}

type RemoveContactModalProps = {
  contact: Parameters<typeof userFullName>[0] | string;
  onClose: () => void;
  onRemove: () => void;
  isLoading?: boolean;
};

export function RemoveContactModal({
  onClose,
  isLoading,
  onRemove,
  contact,
}: RemoveContactModalProps) {
  const isCCRecipient = typeof contact === "string";
  const formattedContact = isCCRecipient ? contact : userFullName(contact);

  return (
    <WorkflowModal
      footerSeparator={false}
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="3b94c313-f921-4e09-9fcc-9ae2a3693d19"
          defaultMessage="Delete contact"
        />
      }
      buttons={[
        <Button buttonColor="dark" variant="tertiary" key="cancel" onClick={onClose}>
          <FormattedMessage id="f3806abf-54ce-443a-be28-77ac088317d9" defaultMessage="Cancel" />
        </Button>,
        <Button
          isLoading={isLoading}
          buttonColor="danger"
          variant="primary"
          key="add"
          onClick={onRemove}
        >
          <FormattedMessage id="0aa3e23a-bf7c-4075-a72f-4c3c861e8e35" defaultMessage="Delete" />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="2343acec-ba6b-4cc5-a964-acd3fd66e5e2"
        defaultMessage="Are you sure you want to remove <b>{name}</b>? This action cannot be undone."
        values={{ name: formattedContact, b }}
      />
    </WorkflowModal>
  );
}
