import { defineMessages } from "react-intl";

import { ChargeStatuses } from "graphql_globals";
import { captureException } from "util/exception";
import { BUNDLE_PROCESSING_STATES, BUNDLE_PARTICIPANT_STATES } from "constants/document_bundle";

const MESSAGES = defineMessages({
  signerWithEmail: {
    id: "fcf542f1-933a-4e38-81cc-3d0c50faa508",
    defaultMessage: "Signer {index}{hasEmail, select, true{ {email}} other{}}",
  },
});

// Returns the first document which is an eNote.
// This implementation works as long as the assumption that zero or one eNote exists per bundle.
export function findEnoteDocument(documentConnection) {
  const enoteEdge = (documentConnection.edges || []).find((edge) => {
    return edge.node.isEnote;
  });
  return enoteEdge ? enoteEdge.node : null;
}

/** @type {<Doc extends { id: string }>(documentConnection: { edges: null | { node: Doc }[] }, id: string) => Doc | null} */
export function getDocumentFromId(documentConnection, id) {
  const documentEdge = (documentConnection.edges || []).find((edge) => {
    return edge.node.id === id;
  });
  return documentEdge ? documentEdge.node : null;
}

/** @type {(processingState: string | null, charges?: ({ status?: ChargeStatuses, state?: ChargeStatuses } | null)[] | null) => string | null} */
export function getBundleChargeState(processingState, charges) {
  // Currently, we only care if ANY of the charges have pending no card status
  return charges?.some(
    (c) =>
      c?.status === ChargeStatuses.PENDING_NO_CARD || c?.state === ChargeStatuses.PENDING_NO_CARD,
  )
    ? BUNDLE_PROCESSING_STATES.PAYMENT_FAILED
    : processingState;
}

/** @type {<P extends { userId: string }>(participants: (P | null)[] | null, currentUser: { id: string }, hideAlreadySignedSigners?: boolean, filterByCanStartSigning?: boolean) => { hasOptionalSigners: boolean; hasMultipleRequiredSigners: boolean; participantGroup: undefined | P[]; participantGroups: Record<string, P[]>; participantGroupOwners: string[] }} */
export function getParticipantGroups(
  participants,
  currentUser,
  hideAlreadySignedSigners = false,
  filterByCanStartSigning = false,
) {
  let myLeaderId;
  const userParticipant = participants?.find((p) => p.userId === currentUser.id);
  const participantsWithMatchingSigningRequirements = (participants || []).filter(
    (p) => p.signingRequirement === userParticipant?.signingRequirement,
  );
  const participantArray = filterByCanStartSigning
    ? (participantsWithMatchingSigningRequirements || []).filter((p) => p.canStartSigning)
    : participantsWithMatchingSigningRequirements || [];
  const groups = participantArray.reduce((accum, participant) => {
    const key = participant.requiredToSignWith || participant.userId;

    if (!myLeaderId && participant.userId === currentUser.id) {
      myLeaderId = key;
    }

    // recipientGroup's cannot currently sign with anyone else
    // when userParticipant is a recipientGroup, filter out everyone but themselves
    // when userParticipant is not a recipientGroup, filter out recipientGroups
    if (userParticipant.recipientGroup) {
      if (participant.userId !== currentUser.id) {
        return accum;
      }
    } else if (participant.recipientGroup) {
      return accum;
    }

    if (!accum[key]) {
      accum[key] = [];
    }
    accum[key].push(participant);
    return accum;
  }, {});
  const participantGroups = Object.fromEntries(
    Object.entries(groups).map(([ownerId, participants]) => {
      return [
        ownerId,
        participants.sort((a, b) => (a.userId === ownerId ? -1 : b.userId === ownerId ? 1 : 0)),
      ];
    }),
  );

  if (hideAlreadySignedSigners) {
    Object.keys(participantGroups).forEach((key) => {
      participantGroups[key] = participantGroups[key].filter((participant) => {
        return (
          participant.signingStatus !== BUNDLE_PARTICIPANT_STATES.COMPLETE ||
          participant.userId === currentUser.id
        );
      });
      if (participantGroups[key].length === 0) {
        delete participantGroups[key];
      }
    });
  }

  const participantGroup = participantGroups[myLeaderId];
  const participantGroupOwners = Object.keys(participantGroups);
  const hasOptionalSigners = participantGroupOwners.length > 1;
  const hasMultipleRequiredSigners = (participantGroup?.length || 0) > 1;

  return {
    participantGroup,
    participantGroups,
    participantGroupOwners,
    hasOptionalSigners,
    hasMultipleRequiredSigners,
  };
}

export function usersParticipantGroupHasSigned({ participants, user }) {
  const { participantGroups } = getParticipantGroups(participants, user);

  const usersParticipantGroup = participantGroups[user.id];

  if (!usersParticipantGroup) {
    return false;
  }

  if (usersParticipantGroup && usersParticipantGroup.length === 0) {
    captureException(new Error("Error calculating if participant group has signed"), {
      participants,
      user,
    });

    return false;
  }

  // Early return if there's at least one participant whose signing state is not complete
  const atLeastOneParticipantHasNotSigned = usersParticipantGroup.some(
    (participant) => participant.signingStatus !== BUNDLE_PARTICIPANT_STATES.COMPLETE,
  );

  // Return true if there are no participants incomplete
  return !atLeastOneParticipantHasNotSigned;
}

/**
 * Returns all of the unfinished documents of a transaction's document bundle
 */
export function getUnfinishedDocuments({ viewer: { user }, bundle }) {
  const documents = bundle.documents.edges.map((edge) => edge.node);

  return documents.filter((document) => {
    if (!document.signAhead || document.locked) {
      return false;
    }

    const designations = document.designations.edges.map((edge) => edge.node);
    return designations.filter(
      ({ signerId, fulfilled, required }) => signerId === user.id && !fulfilled && required,
    ).length;
  });
}

/** @type {(intl: IntlShape, participant: { firstName: string | null; lastName: string | null; email: string | null }, includeLastName?: boolean, index?: number, onlyEmailIfAvailable?: boolean) => string} */
export function getParticipantName(
  intl,
  participant,
  includeLastName = true,
  index = 0,
  onlyEmailIfAvailable = false,
) {
  if (participant.firstName) {
    if (includeLastName && participant.lastName) {
      return `${participant.firstName} ${participant.lastName}`;
    }
    if (!participant.firstName.includes("Signer ")) {
      return participant.firstName;
    }
  }

  if (onlyEmailIfAvailable && Boolean(participant.email)) {
    return participant.email;
  }

  return intl.formatMessage(MESSAGES.signerWithEmail, {
    email: participant.email,
    index: index + 1,
    hasEmail: Boolean(participant.email),
  });
}

/** @type {(bundle: {processingState: string | null}) => boolean} */
export function isProcessingComplete(bundle) {
  return (
    bundle.processingState === BUNDLE_PROCESSING_STATES.COMPLETED ||
    bundle.processingState === BUNDLE_PROCESSING_STATES.COMPLETED_WITH_REJECTIONS
  );
}
