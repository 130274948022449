import { OrganizationTypeEnum } from "graphql_globals";
import {
  LENDER_HYBRID_TRANSACTION_TYPES,
  LENDER_TRANSACTION_TYPES,
  TITLE_MORTGAGE_TRANSACTION_TYPES,
  TRANSACTION_TYPE_HELOC,
  TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
  TRANSACTION_TYPE_LOAN_MOD_BORROWER,
  TRANSACTION_TYPE_OTHER,
  TRANSACTION_TYPE_TRAILING_DOCS,
} from "constants/transaction";

import type { RealEstateUtilOrganization as Organization } from "../real_estate/util_organization_fragment.graphql";
import type { RealEstateUtilTransaction as Transaction } from "../real_estate/util_transaction_fragment.graphql";
import { canSendToTitleAgency, usersOrgCreatedTransaction } from "../real_estate/util";
import {
  LENDER_REFINANCE_CONFIG,
  LENDER_REFINANCE_COLLAB_CONFIG,
  LENDER_REFINANCE_COLLABORATOR_CONFIG,
  HYBRID_REFINANCE_CONFIG,
  PURCHASE_BUYER_LOAN_CONFIG,
  PURCHASE_BUYER_LOAN_COLLAB_CONFIG,
  PURCHASE_BUYER_LOAN_COLLABORATOR_CONFIG,
  HYBRID_PURCHASE_BUYER_LOAN_CONFIG,
  TRAILING_DOCS_CONFIG,
  HYBRID_TRAILING_DOCS_CONFIG,
  LENDER_HELOC_CONFIG,
  TITLE_HELOC_CONFIG,
  LENDER_LOAN_MOD_BORROWER_CONFIG,
  TITLE_LOAN_MOD_BORROWER_CONFIG,
  OTHER_CONFIG,
  PURCHASE_SELLER_CONFIG,
  PURCHASE_BUYER_CONFIG,
  HYBRID_REFINANCE_COLLAB_CONFIG,
  HYBRID_PURCHASE_BUYER_LOAN_COLLAB_CONFIG,
} from ".";

// deprecatesd - will be removed once config id project is completed BIZ-6994
function getTransactionConfig({
  transactionType,
  transactionOrganizationType,
  collabEnabled,
  orgOwnsTransaction,
}: {
  transactionType: string;
  transactionOrganizationType: string;
  collabEnabled: boolean;
  orgOwnsTransaction: boolean;
}) {
  switch (transactionType) {
    case LENDER_TRANSACTION_TYPES.REFINANCE: {
      if (collabEnabled) {
        return orgOwnsTransaction
          ? LENDER_REFINANCE_COLLAB_CONFIG
          : LENDER_REFINANCE_COLLABORATOR_CONFIG;
      }
      return LENDER_REFINANCE_CONFIG;
    }
    case LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_REFINANCE: {
      return collabEnabled ? HYBRID_REFINANCE_COLLAB_CONFIG : HYBRID_REFINANCE_CONFIG;
    }
    case LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN: {
      if (collabEnabled) {
        return orgOwnsTransaction
          ? PURCHASE_BUYER_LOAN_COLLAB_CONFIG
          : PURCHASE_BUYER_LOAN_COLLABORATOR_CONFIG;
      }
      return PURCHASE_BUYER_LOAN_CONFIG;
    }
    case LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_LOAN: {
      return collabEnabled
        ? HYBRID_PURCHASE_BUYER_LOAN_COLLAB_CONFIG
        : HYBRID_PURCHASE_BUYER_LOAN_CONFIG;
    }
    case TRANSACTION_TYPE_TRAILING_DOCS: {
      return TRAILING_DOCS_CONFIG;
    }
    case TRANSACTION_TYPE_HYBRID_TRAILING_DOCS: {
      return HYBRID_TRAILING_DOCS_CONFIG;
    }
    case TRANSACTION_TYPE_LOAN_MOD_BORROWER: {
      if (transactionOrganizationType === OrganizationTypeEnum.LENDER) {
        return LENDER_LOAN_MOD_BORROWER_CONFIG;
      }
      return TITLE_LOAN_MOD_BORROWER_CONFIG;
    }
    case TRANSACTION_TYPE_HELOC: {
      if (transactionOrganizationType === OrganizationTypeEnum.LENDER) {
        return LENDER_HELOC_CONFIG;
      }
      return TITLE_HELOC_CONFIG;
    }
    case TRANSACTION_TYPE_OTHER: {
      return OTHER_CONFIG;
    }
    case TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_SELLER: {
      return PURCHASE_SELLER_CONFIG;
    }
    case TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_BUYER_CASH: {
      return PURCHASE_BUYER_CONFIG;
    }
    default: {
      // TODO In future should throw if transaction type/variant isn't found
      throw new Error(`Invalid transaction type, got ${transactionType}`);
    }
  }
}

function getTransactionConfigFromId({
  configId,
  collabEnabled,
  orgOwnsTransaction,
}: {
  configId: string;
  collabEnabled: boolean;
  orgOwnsTransaction: boolean;
}) {
  switch (configId) {
    case LENDER_REFINANCE_CONFIG.id: {
      if (collabEnabled) {
        return orgOwnsTransaction
          ? LENDER_REFINANCE_COLLAB_CONFIG
          : LENDER_REFINANCE_COLLABORATOR_CONFIG;
      }
      return LENDER_REFINANCE_CONFIG;
    }
    case HYBRID_REFINANCE_CONFIG.id: {
      return collabEnabled ? HYBRID_REFINANCE_COLLAB_CONFIG : HYBRID_REFINANCE_CONFIG;
    }
    case PURCHASE_BUYER_LOAN_CONFIG.id: {
      if (collabEnabled) {
        return orgOwnsTransaction
          ? PURCHASE_BUYER_LOAN_COLLAB_CONFIG
          : PURCHASE_BUYER_LOAN_COLLABORATOR_CONFIG;
      }
      return PURCHASE_BUYER_LOAN_CONFIG;
    }
    case HYBRID_PURCHASE_BUYER_LOAN_CONFIG.id: {
      return collabEnabled
        ? HYBRID_PURCHASE_BUYER_LOAN_COLLAB_CONFIG
        : HYBRID_PURCHASE_BUYER_LOAN_CONFIG;
    }
    case TRAILING_DOCS_CONFIG.id: {
      return TRAILING_DOCS_CONFIG;
    }
    case HYBRID_TRAILING_DOCS_CONFIG.id: {
      return HYBRID_TRAILING_DOCS_CONFIG;
    }
    case TITLE_LOAN_MOD_BORROWER_CONFIG.id: {
      return TITLE_LOAN_MOD_BORROWER_CONFIG;
    }
    case LENDER_LOAN_MOD_BORROWER_CONFIG.id: {
      return LENDER_LOAN_MOD_BORROWER_CONFIG;
    }
    case TITLE_HELOC_CONFIG.id: {
      return TITLE_HELOC_CONFIG;
    }
    case LENDER_HELOC_CONFIG.id: {
      return LENDER_HELOC_CONFIG;
    }
    case OTHER_CONFIG.id: {
      return OTHER_CONFIG;
    }
    case PURCHASE_SELLER_CONFIG.id: {
      return PURCHASE_SELLER_CONFIG;
    }
    case PURCHASE_BUYER_CONFIG.id: {
      return PURCHASE_BUYER_CONFIG;
    }

    default: {
      throw new Error(`Invalid config type, got ${configId}`);
    }
  }
}

export function getMortageTransactionConfig(
  transaction: Transaction,
  organization: Organization,
  configId: string | null,
) {
  const { transactionType, organization: transactionOrganization } = transaction;
  const { organizationType: loggedInUserOrganizationType } = organization;

  const orgOwnsTransaction = usersOrgCreatedTransaction(transaction, organization);

  let collabEnabled = false;
  if (loggedInUserOrganizationType === OrganizationTypeEnum.LENDER) {
    collabEnabled = canSendToTitleAgency(transaction, organization);
  } else if (loggedInUserOrganizationType === OrganizationTypeEnum.TITLE_AGENCY) {
    collabEnabled = !orgOwnsTransaction;
  }

  if (configId) {
    return getTransactionConfigFromId({ configId, collabEnabled, orgOwnsTransaction });
  }

  return getTransactionConfig({
    transactionType: transactionType!,
    transactionOrganizationType: transactionOrganization.organizationType,
    collabEnabled,
    orgOwnsTransaction,
  });
}
