import { useState } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import WorkflowModal from "common/modals/workflow_modal";
import { validatePresence } from "validators/form";
import { useMutation } from "util/graphql";

import ReclaimPayoutMutation from "./reclaim_payout.mutation.graphql";
import { NotaryItemWrapper } from "../notary_item_wrapper";
import Styles from "./reclaim_payout.module.scss";

type Props = {
  payoutId: null | string | undefined;
  onReclaim?: () => void;
};

type FormValues = {
  reason: string;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type InnerProps = Props & FormProps;

function ReclaimPayout(props: InnerProps) {
  const { onReclaim } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState(false);
  const reclaimPayout = useMutation(ReclaimPayoutMutation);

  const save = async (formValues: FormValues) => {
    try {
      await reclaimPayout({
        variables: {
          input: {
            payoutTransferId: props.payoutId!,
            reason: formValues.reason,
          },
        },
      });
      onReclaim?.();
      setShowConfirm(false);
    } catch {
      setError(true);
    }
  };

  return (
    <>
      <NotaryItemWrapper
        term={
          <FormattedMessage
            id="b47d19f5-5363-4caf-a895-dd9996a6f712"
            defaultMessage="Reclaim Payout"
          />
        }
        definition={
          <div className={Styles.reclaimPayout}>
            <DeprecatedTextField placeholder="Reason for reclaim" name="reason" />
            <FormGroupErrors fields={["reason"]} errorClassName="ReclaimPayout--validation" />
            <Button
              buttonColor="action"
              buttonSize="condensed"
              variant="primary"
              onClick={props.handleSubmit(() => setShowConfirm(true))}
            >
              <FormattedMessage
                id="7391438a-a417-4173-bb9c-6056693876e9"
                defaultMessage="Reclaim Payout"
              />
            </Button>
          </div>
        }
      />

      {showConfirm && (
        <WorkflowModal
          title="Reclaim Payout"
          footerSeparator={false}
          buttons={[
            <Button
              key="cancel"
              variant="tertiary"
              buttonColor="dark"
              onClick={() => setShowConfirm(false)}
            >
              <FormattedMessage id="ca438d8f-ac45-46cc-bca6-f8e89523cb5c" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="confirm"
              variant="primary"
              buttonColor="action"
              onClick={props.handleSubmit(save)}
            >
              <FormattedMessage
                id="4871a9c3-0284-41a1-9e15-78221f0ce86e"
                defaultMessage="Confirm"
              />
            </Button>,
          ]}
        >
          {error ? (
            <FormattedMessage
              id="2ccfc70e-50a5-4bad-bfa6-6fb9a250923e"
              defaultMessage="Reclaim could not be processed. Please ensure this notary has a Stripe account."
            />
          ) : (
            <FormattedMessage
              id="240f7282-29a1-44c1-a615-f0cd00a39e73"
              defaultMessage="Are you sure you want to reclaim this payout in full?"
            />
          )}
        </WorkflowModal>
      )}
    </>
  );
}

export default reduxForm<FormValues, Props>({
  form: "reasonForReclaimPayout",
  validate: validatePresence({
    field: "reason",
    label: "Reason for change",
  }),
})(ReclaimPayout);
