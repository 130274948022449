import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useState } from "react";

import { useWatch } from "common/core/form";
import { TextInput, TextAreaInput } from "common/core/form/text";
import Button from "common/core/button";
import { Card, SectionHeader, ConfiguredField } from "common/transaction_creation/v3/common";
import {
  type SectionContract,
  type SectionComponentProps,
} from "common/transaction_creation/v3/form";
import EmailPreview from "common/transactions/form/sub_forms/customize_email/email_preview";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";

import Styles from "./index.module.scss";
import type { CustomEmail } from "./transaction_fragment.graphql";
import type { CustomEmailOrg } from "./organization_fragment.graphql";

const MESSAGES = defineMessages({
  emailSubject: {
    id: "e3a94e14-dab3-4ffb-8730-a9bdec797fc2",
    defaultMessage: "Email subject",
  },
  emailMessage: {
    id: "5788be44-0abf-49b2-bfd6-469282e1c1a2",
    defaultMessage: "Email message",
  },
  emailSignature: {
    id: "ab3a5973-4653-43c4-a8db-a545c51b8cb8",
    defaultMessage: "Email signature",
  },
  emailMessageError: {
    id: "1fa0938b-9e0d-4fa5-8d4a-b1eaef1aa635",
    defaultMessage: "A message must be included along with the email signature.",
  },
  customEmails: {
    id: "4c1f6ae1-3097-40c2-8b5d-a3ce9e200fbf",
    defaultMessage: "Custom emails",
  },
});

const CONFIGS = {
  emailSubject: "emailSubject",
  emailMessage: "emailMessage",
  emailSignature: "emailSignature",
} as const;
const FORM_FIELDS = {
  emailSubject: "emailSubject",
  emailMessage: "emailMessage",
  emailSignature: "emailSignature",
} as const;

function CustomEmailSection({
  config,
  form,
  transaction,
  organization,
}: SectionComponentProps<CustomEmail, CustomEmailOrg, unknown>) {
  const intl = useIntl();
  const [isPreviewingEmail, setIsPreviewingEmail] = useState(false);

  const emailSignature = useWatch({
    control: form.control,
    name: FORM_FIELDS.emailSignature,
  });
  const emailSignatureFieldHasValue = Boolean(emailSignature?.length);

  const formValuesForPreview = () => {
    const { emailSubject, emailMessage, emailSignature, recipients } = form.getValues();
    const { firstName, lastName, email: customerEmail } = recipients[0];

    return {
      message: emailMessage,
      emailSubject,
      emailSignature,
      firstName,
      lastName,
      customerEmail,
    };
  };

  function onPreviewEmail() {
    setIsPreviewingEmail(true);
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_PREVIEW_EMAIL, {
      organization_transaction_id: transaction.id,
    });
  }

  return (
    <>
      <SectionHeader iconName="email">{intl.formatMessage(MESSAGES.customEmails)}</SectionHeader>
      <Card title={intl.formatMessage(MESSAGES.customEmails)}>
        <ConfiguredField
          config={config}
          configField={CONFIGS.emailSubject}
          form={form}
          name={FORM_FIELDS.emailSubject}
          label={intl.formatMessage(MESSAGES.emailSubject)}
          as={TextInput}
        />
        <ConfiguredField
          config={config}
          configField={CONFIGS.emailMessage}
          form={form}
          name={FORM_FIELDS.emailMessage}
          label={intl.formatMessage(MESSAGES.emailMessage)}
          as={TextAreaInput}
          registerOptions={{
            required: emailSignatureFieldHasValue && intl.formatMessage(MESSAGES.emailMessageError),
          }}
        />
        <ConfiguredField
          config={config}
          configField={CONFIGS.emailSignature}
          form={form}
          name={FORM_FIELDS.emailSignature}
          label={intl.formatMessage(MESSAGES.emailSignature)}
          as={TextInput}
          registerOptions={{
            deps: FORM_FIELDS.emailMessage,
          }}
        />
        <div className={Styles.previewEmailButtonWrapper}>
          <Button
            buttonColor="action"
            variant="tertiary"
            buttonSize="condensed"
            withIcon={{ placement: "left", name: "witness-filled" }}
            onClick={onPreviewEmail}
          >
            <FormattedMessage
              id="0a60ed5f-0bce-445b-934f-1411194a3beb"
              defaultMessage="Preview email"
            />
          </Button>
        </div>
      </Card>
      {isPreviewingEmail && (
        <>
          {/* When we move to v3 permanently, can move EmailPreview component to this directory */}
          <EmailPreview
            onRequestClose={() => setIsPreviewingEmail(false)}
            organization={organization}
            transaction={transaction}
            formValues={formValuesForPreview()}
          />
        </>
      )}
    </>
  );
}

export type CustomEmailFormValues = {
  [FORM_FIELDS.emailSubject]: string | null;
  [FORM_FIELDS.emailMessage]: string | null;
  [FORM_FIELDS.emailSignature]: string | null;
};
type CustomEmailSubmitData = {
  messageSubject: string | null;
  message: string | null;
  messageSignature: string | null;
};
export const CUSTOM_EMAIL_SECTION = {
  Component: CustomEmailSection,
  configs: CONFIGS,
  getDefaultFormValues(transaction) {
    return {
      emailSubject: transaction.messageSubject,
      emailMessage: transaction.message,
      emailSignature: transaction.messageSignature,
    };
  },
  getSubmitData({ sectionFormValues }) {
    return {
      messageSubject: sectionFormValues.emailSubject,
      message: sectionFormValues.emailMessage,
      messageSignature: sectionFormValues.emailSignature,
    };
  },
} satisfies SectionContract<
  CustomEmailFormValues,
  CustomEmailSubmitData,
  CustomEmail,
  CustomEmailOrg,
  unknown,
  typeof CONFIGS
>;
