import {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useState,
  createContext,
  type ReactNode,
} from "react";
import { initialize, type LDClient } from "launchdarkly-js-client-sdk";

import LoadingIndicator from "common/core/loading_indicator";
import Apps from "constants/applications";
import Env from "config/environment";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { MARKETING_SESSION_ID_COOKIE_KEY } from "util/marketing_session";
import { getCookie } from "util/cookie";

declare const window: Window & {
  ldClient?: LDClient;
  __E2E_FEATURE_FLAG_STUBS__?: Record<string, unknown>;
  __E2E_FEATURE_FLAG_UPDATE_NOTIFY__?: () => void;
};

type User = { key: string } | { key?: string; anonymous: true };
type Context = Readonly<{
  setUser: <U extends User>(user: U) => Promise<unknown>;
  value: (featureKey: string, defaultValue: unknown) => unknown;
  /** Subscribe to changes to a featureKey. Returns a destructor. */
  onChange: (featureKey: string, callback: <V = unknown>(newValue: V) => void) => () => void;
}>;
type Props = { children: ReactNode };

const LDContext = createContext<Context>(
  Object.freeze({
    setUser: () => Promise.reject(new Error("Feature gating is not initialized")),
    value: (featureKey, defaultValue: unknown = false) => defaultValue,
    onChange: () => () => {},
  }),
);

function e2eStubbedValue(featureKey: string): false | { value: unknown } {
  const { __E2E_FEATURE_FLAG_STUBS__: stubs, navigator } = window;
  // In E2E, we override a features by setting a global object.
  if (navigator.webdriver && stubs && featureKey in stubs) {
    return { value: stubs[featureKey] };
  }
  return false;
}

function contextFactory(client: LDClient): Context {
  return {
    value: (featureKey: string, defaultValue: unknown = false) => {
      const stubbed = e2eStubbedValue(featureKey);
      return stubbed ? stubbed.value : client.variation(featureKey, defaultValue);
    },
    onChange: (featureKey, callback) => {
      if (e2eStubbedValue(featureKey)) {
        return () => {};
      }
      const eventKey = `change:${featureKey}`;
      client.on(eventKey, callback);
      return () => client.off(eventKey, callback);
    },
    setUser: (user) => client.identify({ ...user, kind: "user" }),
  };
}

export function useFeatureGatingContext(): Context {
  return useContext(LDContext);
}

/**
 * Returns value of a feature flag. This will **not** cause rerenders and the value
 * is memoized. Notable exception is if user logs in/logs out. Use `useHotFeatureFlag` for
 * stateful value.
 */
export function useFeatureFlag(featureKey: string, defaultValue?: boolean): boolean;
export function useFeatureFlag<V>(featureKey: string, defaultValue: V): V;
export function useFeatureFlag(featureKey: string, defaultValue: unknown = false) {
  const context = useFeatureGatingContext();
  return useMemo(
    () => context.value(featureKey, defaultValue),
    [context, featureKey, defaultValue],
  );
}

/** Like `useFeatureFlag`, but will watch for changes and trigger re-renders */
export function useHotFeatureFlag(featureKey: string, defaultValue?: boolean): boolean;
export function useHotFeatureFlag<V>(featureKey: string, defaultValue: V): V;
export function useHotFeatureFlag(featureKey: string, defaultValue: unknown = false) {
  const context = useFeatureGatingContext();
  const [value, setValue] = useState(context.value(featureKey, defaultValue));
  useEffect(() => context.onChange(featureKey, setValue), [context, featureKey]);
  return value;
}

export function FeatureGatingProvider({ children }: Props) {
  const [context, setContext] = useState<null | Context>(null);
  const unmounted = useRef(false);
  useEffect(
    () => () => {
      unmounted.current = true;
    },
    [],
  );

  useEffect(() => {
    const apiKey =
      CURRENT_PORTAL === Apps.CUSTOMER
        ? Env.launchDarklySignerApiKey
        : CURRENT_PORTAL === Apps.ADMIN
          ? Env.launchDarklyAdminApiKey
          : Env.launchDarklyApiKey;

    const marketingSessionId = getCookie(MARKETING_SESSION_ID_COOKIE_KEY);
    // We attach to the window to help debugging in production-like environments
    const client = (window.ldClient = initialize(apiKey, { anonymous: true, marketingSessionId }));
    const updateContext = () => {
      if (unmounted.current) {
        // This is potentially overkill but to prevent react warnings about leaks and keep the console clean...
        return;
      }
      const rawContext = contextFactory(client);
      setContext(
        Object.freeze({
          ...rawContext,
          // setUser should also updateContext so that we rerender flags for a newly identified user
          setUser: (user) => rawContext.setUser(user).then(updateContext),
        }),
      );
    };
    // We also attach this callback to the window so that E2E can update flags mid run, even for already
    // rendered components.
    window.__E2E_FEATURE_FLAG_UPDATE_NOTIFY__ = updateContext;
    client.on("ready", updateContext);
    client.on("failed", () => console.error("LD Failed to initialize")); // eslint-disable-line no-console
  }, []);

  return context ? (
    <LDContext.Provider value={context}>{children}</LDContext.Provider>
  ) : (
    <LoadingIndicator />
  );
}
