import { type ComponentProps } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { TextInput } from "common/core/form/text";
import { useFieldArray } from "common/core/form";
import {
  Card,
  showField,
  readonlyField,
  ConfiguredEmailField,
} from "common/transaction_creation/v3/common";
import { MAX_CC_RECIPIENTS } from "constants/transaction";
import Button from "common/core/button";

import { type TRANSACTION_DETAILS_SECTION, CONFIGS, FORM_FIELDS } from "..";
import Styles from "./index.module.scss";

type TransactionDetailsSectionProps = ComponentProps<typeof TRANSACTION_DETAILS_SECTION.Component>;

const MESSAGES = defineMessages({
  ccRecipientsTitle: {
    id: "6dc9e668-7091-40fc-bead-0a4dab174e9a",
    defaultMessage: "CC contacts",
  },
  ccRecipientsSubtitle: {
    id: "f64723cd-2b88-46f9-b1fd-4a466edc1796",
    defaultMessage:
      "CC contacts will be notified when the transaction is sent and have access to the completed documents.",
  },
  removeCCRecipient: {
    id: "b6336ee0-6d4f-434a-950e-37d0ee654907",
    defaultMessage: "Remove CC contact {number}",
  },
  emailLabel: {
    id: "e40ca233-5549-422a-8ee1-d3fe55914fe8",
    defaultMessage: "Email",
  },
  duplicateEmailError: {
    id: "63ddb293-ae77-406a-870e-028b91858a19",
    defaultMessage: "Email has already been used",
  },
  emailRequiredError: {
    id: "f89cdf84-b5ea-4391-9bcb-cffe386f9c70",
    defaultMessage: "Email required",
  },
  emailAriaLabel: {
    id: "cc581ee8-eb2b-4741-9c4a-310979aff92a",
    defaultMessage: "CC contact email {number} (required)",
  },
});

export function CCRecipientEmails({
  config,
  form,
}: {
  config: TransactionDetailsSectionProps["config"];
  form: TransactionDetailsSectionProps["form"];
}) {
  const CC_RECIPIENT_EMAILS = FORM_FIELDS.ccRecipientEmails;
  const intl = useIntl();

  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: CC_RECIPIENT_EMAILS,
  });

  const showCCRecipients = showField(config, CONFIGS.ccRecipientEmail);
  if (!showCCRecipients) {
    return null;
  }

  const maxCCRecipientsReached = fields.length === MAX_CC_RECIPIENTS;

  const addCCRecipient = () => {
    if (fields.length === MAX_CC_RECIPIENTS) {
      return;
    }
    append({ email: "" });
  };

  const readOnly = readonlyField(config, CONFIGS.ccRecipientEmail);

  return (
    <Card
      title={intl.formatMessage(MESSAGES.ccRecipientsTitle)}
      subtitle={intl.formatMessage(MESSAGES.ccRecipientsSubtitle)}
    >
      {!fields.length && (
        <Button
          onClick={addCCRecipient}
          data-pendo-id="add-cc-recipient"
          disabled={readOnly || maxCCRecipientsReached}
          buttonColor="action"
          variant="secondary"
          withIcon={{ placement: "left", name: "add" }}
        >
          <FormattedMessage
            id="8dcef430-3a46-478c-b5dd-f653735ec8fc"
            defaultMessage="Add CC contact"
          />
        </Button>
      )}
      {fields.map((ccRecipient, i) => {
        return (
          <div className={Styles.emailFieldWrapper} key={ccRecipient.id}>
            <ConfiguredEmailField
              required
              registerOptions={{
                // Using required: true instead of config bc user chooses to add a cc recipient field and once added, it should be required
                required: intl.formatMessage(MESSAGES.emailRequiredError),
                validate: {
                  validateDuplicateEmails: (value, formValues) => {
                    if (!value) {
                      return true;
                    }

                    const emailValue = value as string;
                    const hasDuplicates = formValues.ccRecipientEmails.some((recip, index) => {
                      return (
                        recip.email.trim().toLowerCase() === emailValue.trim().toLowerCase() &&
                        index !== i
                      );
                    });

                    if (hasDuplicates) {
                      return intl.formatMessage(MESSAGES.duplicateEmailError);
                    }
                    return true;
                  },
                },
              }}
              data-automation-id="cc-contact-field"
              config={config}
              configField={CONFIGS.ccRecipientEmail}
              form={form}
              name={`${CC_RECIPIENT_EMAILS}.${i}.email`}
              label={intl.formatMessage(MESSAGES.emailLabel)}
              aria-label={intl.formatMessage(MESSAGES.emailAriaLabel, {
                number: (i + 1).toString(),
              })}
              as={TextInput}
            />
            <Button
              disabled={readOnly}
              className={Styles.removeButton}
              data-pendo-id="remove-cc-recipient"
              buttonSize="condensed"
              buttonColor="danger"
              variant="tertiary"
              withIcon={{ name: "delete", placement: "left" }}
              onClick={() => remove(i)}
              aria-label={intl.formatMessage(MESSAGES.removeCCRecipient, { number: i + 1 })}
            >
              <FormattedMessage id="c84d8784-5447-4c35-93d4-d63d3a5ae0bf" defaultMessage="Remove" />
            </Button>
          </div>
        );
      })}
      {Boolean(fields.length) && (
        <Button
          disabled={readOnly || maxCCRecipientsReached}
          disabledHint={
            <FormattedMessage
              id="2c5a4525-817b-4ae9-9022-d627566bd399"
              defaultMessage="You have reached the maximum number of CC contacts allowed on the transaction."
            />
          }
          buttonSize="condensed"
          buttonColor="action"
          variant="tertiary"
          onClick={addCCRecipient}
          data-pendo-id="add-additional-cc-recipient"
          withIcon={{ name: "add", placement: "left" }}
        >
          <FormattedMessage
            id="7b96d06e-f9f6-46b9-921a-c81e1efe0e13"
            defaultMessage="Add additional CC contact"
          />
        </Button>
      )}
    </Card>
  );
}
