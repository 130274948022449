export type SocketEventName =
  | "annotation.added"
  | "designation.added"
  | "designation.removed"
  | "designation.updated"
  | "annotation.removed"
  | "annotation.text.updated"
  | "annotation.resized";

export const EVENTS_WITH_OPTIMISTIC_UPDATES: SocketEventName[] = [
  "annotation.added",
  "designation.added",
  "designation.removed",
  "designation.updated",
  "annotation.removed",
  "annotation.text.updated",
  "annotation.resized",
];
