import { memo } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import UserFullName from "common/user/user_full_name";
import Icon from "common/core/icon";

import type { BeholderHeader_meetingParticipants_NotaryParticipant as NotaryParticipant } from "../beholder/header/index_fragment.graphql";
import Styles from "./notary_details.module.scss";

type Props = {
  notaryParticipant: NotaryParticipant;
  onClose: () => void;
};

function NotaryDetails({ notaryParticipant, onClose }: Props) {
  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={<UserFullName user={notaryParticipant} />}
      headerSeparator={false}
      footerSeparator={false}
    >
      <ul className={Styles.notaryDetails}>
        <li>
          <Icon name="notaries" />
          <FormattedMessage
            id="9d1d18a7-b8eb-4d7c-8d70-cf4ed6c33a4b"
            defaultMessage={"Notarial Agent"}
          />
        </li>
        <li>
          <Icon name="location" />
          {notaryParticipant.usStateName}
        </li>
        <li>
          <Icon name="info" />
          {notaryParticipant.notaryId}
        </li>
      </ul>
    </WorkflowModal>
  );
}

export default memo(NotaryDetails);
