import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Address from "common/user/address";
import { DescriptionList, DescriptionListItem } from "common/core/description_list";
import { usePermissions } from "common/core/current_user_role";

import PayoutTransfer from "../notary_details/items/payout_transfer";

type Props = {
  witness: {
    userId: string | null;
    address: ComponentProps<typeof Address>["address"];
    fullName: string | null;
    payoutTransfer: null | { amount: number; stripeTransferId: string | null };
  };
};

function WitnessInfo({ witness }: Props) {
  const { hasPermissionFor } = usePermissions();

  return (
    <DescriptionList direction="vertical" itemGap="small">
      <DescriptionListItem
        term={
          <FormattedMessage id="4dd0903a-5d25-42b6-9884-c6f5eab3c3fe" defaultMessage="Full name" />
        }
        definition={witness.fullName}
      />
      <DescriptionListItem
        term={
          <FormattedMessage id="f47f4d1c-428e-47e4-a2d4-e4a153db2393" defaultMessage="User Id" />
        }
        definition={witness.userId}
      />
      <DescriptionListItem
        term={
          <FormattedMessage id="f9bf788d-84fa-4f3a-a5de-12f605c568a1" defaultMessage="Address" />
        }
        definition={<Address address={witness.address} />}
      />
      {hasPermissionFor("notaryPaymentInfo") && (
        <PayoutTransfer
          payoutTransfer={witness.payoutTransfer}
          renderStripeLinks={hasPermissionFor("renderStripeLinks")}
        />
      )}
    </DescriptionList>
  );
}

export default WitnessInfo;
