import { defineMessages } from "react-intl";

export const DEFAULT_TRANSACTION_NAME = "Untitled Transaction";

export const MAX_SIGNERS = 10;
export const MAX_CC_RECIPIENTS = 10;

export const EXPIRY_DATE_FORMAT = "MMM do h:mm a";
export const EXPIRY_DATE_TIMEZONE_FORMAT = "MMM do h:mm a z";
export const ESIGN_TRANSACTION_TYPE = "ESIGN";
export const PROOF_TRANSACTION_TYPE = "PROOF";
export const IDENTIFY_TRANSACTION_TYPE = "IDENTIFY";
export const VERIFY_TRANSACTION_TYPE = "VERIFY";

export const TRANSACTION_TYPE_HELOC = "heloc";
export const TRANSACTION_TYPE_LOAN_MOD_BORROWER = "loan_mod_borrower";
export const TRANSACTION_TYPE_OTHER = "other";
export const TRANSACTION_TYPE_HYBRID_TRAILING_DOCS = "hybrid_trailing_docs";
export const TRANSACTION_TYPE_TRAILING_DOCS = "trailing_docs";
export const TRANSACTION_TYPE_HYBRID_OTHER = "hybrid_other";

export const LENDER_HYBRID_TRANSACTION_TYPES = Object.freeze({
  HYBRID_REFINANCE: "hybrid_refinance",
  HYBRID_PURCHASE_BUYER_LOAN: "hybrid_purchase_buyer_loan",
  HYBRID_TRAILING_DOCS: TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
  HYBRID_OTHER: TRANSACTION_TYPE_HYBRID_OTHER,
});

export const LENDER_TRANSACTION_TYPES = Object.freeze({
  REFINANCE: "refinance",
  PURCHASE_BUYER_LOAN: "purchase_buyer_loan",
  TRAILING_DOCS: TRANSACTION_TYPE_TRAILING_DOCS,
  HELOC: TRANSACTION_TYPE_HELOC,
  LOAN_MOD_BORROWER: TRANSACTION_TYPE_LOAN_MOD_BORROWER,
  OTHER: TRANSACTION_TYPE_OTHER,
});

export const ALL_LENDER_TRANSACTION_TYPES = Object.freeze({
  ...LENDER_HYBRID_TRANSACTION_TYPES,
  ...LENDER_TRANSACTION_TYPES,
});

export const TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES = Object.freeze({
  HYBRID_TRAILING_DOCS: TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
  HYBRID_PURCHASE_SELLER: "hybrid_purchase_seller",
  HYBRID_PURCHASE_BUYER_CASH: "hybrid_purchase_buyer_cash",
  HYBRID_OTHER: TRANSACTION_TYPE_HYBRID_OTHER,
});

export const TITLE_MORTGAGE_TRANSACTION_TYPES = Object.freeze({
  TRAILING_DOCS: TRANSACTION_TYPE_TRAILING_DOCS,
  PURCHASE_SELLER: "purchase_seller",
  PURCHASE_BUYER_CASH: "purchase_buyer_cash",
  LOAN_MOD_BORROWER: TRANSACTION_TYPE_LOAN_MOD_BORROWER,
  HELOC: TRANSACTION_TYPE_HELOC,
  OTHER: TRANSACTION_TYPE_OTHER,
});

const TITLE_MORTGAGE_ALL_TRANSACTION_TYPES = Object.freeze({
  ...TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES,
  ...TITLE_MORTGAGE_TRANSACTION_TYPES,
});

export const ALL_MORTGAGE_TRANSACTION_TYPES = Object.freeze({
  ...ALL_LENDER_TRANSACTION_TYPES,
  ...TITLE_MORTGAGE_ALL_TRANSACTION_TYPES,
});

export const ESIGN_ONLY_TRANSACTIONS = Object.freeze([
  "real_estate_proof",
  "real_estate_esign",
] as const);

export const MORTGAGE_TRANSACTION_TYPE_OPTIONS = Object.freeze([
  {
    requiresNsaMeeting: true,
    transactionType: LENDER_TRANSACTION_TYPES.REFINANCE,
  },
  {
    requiresNsaMeeting: true,
    transactionType: TRANSACTION_TYPE_TRAILING_DOCS,
  },
  {
    requiresNsaMeeting: true,
    transactionType: TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_SELLER,
  },
  {
    requiresNsaMeeting: true,
    transactionType: TITLE_MORTGAGE_TRANSACTION_TYPES.PURCHASE_BUYER_CASH,
  },
  {
    requiresNsaMeeting: true,
    transactionType: LENDER_TRANSACTION_TYPES.PURCHASE_BUYER_LOAN,
  },
  {
    requiresNsaMeeting: true,
    transactionType: TRANSACTION_TYPE_HELOC,
  },
  {
    requiresNsaMeeting: true,
    transactionType: TRANSACTION_TYPE_LOAN_MOD_BORROWER,
  },
  {
    requiresNsaMeeting: true,
    transactionType: TRANSACTION_TYPE_OTHER,
  },
  {
    requiresNsaMeeting: false,
    transactionType: LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_REFINANCE,
  },
  {
    requiresNsaMeeting: false,
    transactionType: TRANSACTION_TYPE_HYBRID_TRAILING_DOCS,
  },
  {
    requiresNsaMeeting: false,
    transactionType: TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_SELLER,
  },
  {
    requiresNsaMeeting: false,
    transactionType: TITLE_MORTGAGE_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_CASH,
  },
  {
    requiresNsaMeeting: false,
    transactionType: LENDER_HYBRID_TRANSACTION_TYPES.HYBRID_PURCHASE_BUYER_LOAN,
  },
  {
    requiresNsaMeeting: false,
    transactionType: "hybrid_heloc",
  },
  {
    requiresNsaMeeting: false,
    transactionType: TRANSACTION_TYPE_HYBRID_OTHER,
  },
] as const);

export const AUDIT_TRAIL_STATES = Object.freeze({
  DocumentBundleSent: "SENT",
  DocumentBundleActive: "ACTIVE",
  DocumentBundleReceived: "RECEIVED",
  DocumentBundleViewed: "VIEWED",
  MeetingInProgress: "IN_PROGRESS",
  FailedMeetingAttempt: "ATTEMPTED",
  MeetingCompletion: "MEETING_COMPLETED",
  DocumentBundleComplete: "COMPLETED",
  DocumentBundlePartiallyComplete: "ATTEMPTED",
  DocumentBundleCompleteWithRejections: "COMPLETED_WITH_REJECTIONS",
  DocumentBundlePaymentFailed: "PAYMENT_FAILED",
  DocumentBundleRecalled: "RECALLED",
  DocumentBundleSentToClosingOps: "SENT_TO_CLOSING_OPS",
  DocumentBundleSentToTitleAgency: "SENT_TO_TITLE_AGENCY",
});

export const EMAIL_OPTION_PLACEHOLDERS = defineMessages({
  SUBJECT_LINE_DEFAULT: {
    id: "9e7f418b-dcce-49ed-b453-91e03f30d06b",
    description: "subjectLineDefault",
    defaultMessage: "{businessName} document to be signed",
  },
  MESSAGE_PLACEHOLDER: {
    id: "eb93f467-ce46-423d-a732-86d1ebff5a15",
    description: "messagePlaceholder",
    defaultMessage:
      "(Emails that include a personal note to the signer are more likely to be signed and completed promptly.)",
  },
  SIGNATURE_PLACEHOLDER: {
    id: "0f42e249-e79c-4dae-a0ff-9c441f2c63c9",
    description: "signaturePlaceholder",
    defaultMessage: "(Ms. Jane Doe)",
  },
} as const);

/**
 * A collection of different properties that a mortgage transaction
 * might require.
 */
export const MORTGAGE_TRANSACTION_REQUIREMENTS = Object.freeze({
  RECORDING_LOCATION: "recordingLocation",
  TITLE_AGENCY: "titleAgency",
  TITLE_UNDERWRITER: "titleUnderwriter",
  FILE_NUMBER: "fileNumber",
  LOAN_NUMBER: "loanNumber",
  ENOTE: "eNote",
  PAPER_NOTE_CONSENT: "paperNoteConsent",
  PROPERTY_ADDRESS: "propertyAddress",
});

export const SIGNING_SCHEDULE_TYPES = Object.freeze({
  DATE: "DATE",
  WINDOW: "WINDOW",
});

export const ACTIVATION_TIME = Object.freeze({
  HOURS: 0,
  MINUTES: 0,
});

export const EXPIRATION_TIME = Object.freeze({
  HOURS: 23,
  MINUTES: 59,
});
