import { FormattedMessage } from "react-intl";

import { DescriptionListItem } from "common/core/description_list";

export function ProofRequirements({
  ial2Proof,
  smsProof,
  kbaProof,
  caSelfie,
}: {
  ial2Proof: boolean | undefined;
  smsProof: boolean | undefined;
  kbaProof: boolean | undefined;
  caSelfie: boolean | undefined;
}) {
  const verificationMethods = [];
  if (ial2Proof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="24df835f-5fca-463c-8c2e-40dc2d28c60a"
          defaultMessage="IAL-2 compliant identity verification"
        />
      </div>,
    );
  }
  if (smsProof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="3ec75d63-fd4a-41f2-8a48-8dc53bb04039"
          defaultMessage="SMS authentication"
        />
      </div>,
    );
  }
  if (kbaProof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="2b0fddd3-6ea5-44d2-be82-38548c7a07ea"
          defaultMessage="Knowledge-based authentication (KBA)"
        />
      </div>,
    );
  }
  if (caSelfie && !ial2Proof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="0a306df5-057b-48e9-b000-720a3b80de33"
          defaultMessage="CA + selfie authentication"
        />
      </div>,
    );
  }
  const requirementsLabel = (
    <FormattedMessage
      id="cf127065-db35-426b-816b-cda139400225"
      defaultMessage="Identity proofing"
    />
  );

  return <DescriptionListItem term={requirementsLabel} definition={verificationMethods} />;
}
