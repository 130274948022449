export const TIER_NAME = Object.freeze({
  BASIC: "Basic Tier",
  PRO: "Pro Tier",
  PRO_TRIAL: "Pro Trial Tier",
  BUSINESS_PRO_IHN: "Business Pro with IHN Default",
  BUSINESS_PRO_ODN: "Business Pro only ODN Default",
  ELITE: "Elite Tier",
  TITLE_AGENT: "Title - Individual Agent Tier",
  TITLE_AGENCY_PRO: "Title Agency Pro",
  TITLE_PRO_ODN: "Title Pro Only ODN Default",
  TITLE_PRO_IHN: "Title Pro with IHN Default",
  TITLE_AGENCY_PRO_NOTARY: "Title Agency Pro with Notaries",
});

export const TIER_PATH = Object.freeze({
  BASIC: "starter",
  PRO: "pro",
  PRO_TRIAL: "pro-trial",
  BUSINESS_PRO_IHN: "proihn",
  BUSINESS_PRO_ODN: "proodn",
  ELITE: "premium",
  TITLE_AGENT: "title-agency",
  TITLE_AGENCY_PRO: "title-pro",
  TITLE_PRO_ODN: "title-proodn",
  TITLE_PRO_IHN: "title-proihn",
  TITLE_AGENCY_PRO_NOTARY: "title-pro-notary",
  NOTARY_MARKETPLACE_BASIC: "notary_basic",
});

export const GRANDFATHERED_PROFESSIONAL_PRICE_ITEMS = Object.freeze({
  FIRST_SEAL: "firstSeal",
  FIRST_IDENTITY_CONFIRMATION: "firstIdentityConfirmation",
  ADDITIONAL_SEAL: "additionalSeal",
  ADDITIONAL_SIGNER: "additionalSigner",
  ADDITIONAL_IDENTITY_CONFIRMATION: "additionalIdentityConfirmation",
  NOTAVERSE_FIRST_SEAL: "notaverseFirstSeal",
  NOTAVERSE_FIRST_IDENTITY_CONFIRMATION: "notaverseFirstIdentityConfirmation",
  NOTAVERSE_ADDITIONAL_SEAL: "notaverseAdditionalSeal",
  NOTAVERSE_ADDITIONAL_SIGNER: "notaverseAdditionalSigner",
  NOTAVERSE_ADDITIONAL_IDENTITY_CONFIRMATION: "notaverseAdditionalIdentityConfirmation",
  ESIGNED_DOC: "esignedDoc",
  ESIGNED_BUNDLE: "esignedBundle",
  NOTAVERSE_ESIGNED_DOC: "notaverseEsignDoc",
  PLATFORM_SUBSCRIPTION: "platformSubscription",
});

const BASE_PRICE_ITEMS = Object.freeze({
  FIRST_SEAL: "firstSeal",
  ADDITIONAL_SEAL: "additionalSeal",
  ADDITIONAL_SIGNER: "additionalSigner",
  NOTAVERSE_FIRST_SEAL: "notaverseFirstSeal",
  NOTAVERSE_ADDITIONAL_SEAL: "notaverseAdditionalSeal",
  NOTAVERSE_ADDITIONAL_SIGNER: "notaverseAdditionalSigner",
  PLATFORM_SUBSCRIPTION: "platformSubscription",
  PLATFORM_MINIMUM_COMMITMENT: "platformMinimumCommitment",
  COMMAND_CENTER: "commandCenter",
  COMMAND_CENTER_LITE: "commandCenterLite",
  ESIGN_AUTHENTICATION: "esignAuthentication",
  ESIGN_KBA: "esignKba",
  ESIGN_PROOF: "esignProof",
  ESIGNED_BUNDLE: "esignedBundle",
  REMOTE_ODN_WITNESS: "remoteOdnWitness",
  BYOT_REMOTE_ODN_WITNESS: "byotRemoteOdnWitness",
  IDENTIFY: "identify",
});

export const SMB_TIER_PRICE_ITEMS = Object.freeze({
  FIRST_IDENTITY_CONFIRMATION: "firstIdentityConfirmation",
  ADDITIONAL_IDENTITY_CONFIRMATION: "additionalIdentityConfirmation",
  NOTAVERSE_FIRST_IDENTITY_CONFIRMATION: "notaverseFirstIdentityConfirmation",
  NOTAVERSE_ADDITIONAL_IDENTITY_CONFIRMATION: "notaverseAdditionalIdentityConfirmation",
  ...BASE_PRICE_ITEMS,
});

export const REAL_TIER_PRICE_ITEMS = Object.freeze({
  REFINANCE: "refinance",
  PURCHASE_SELLER: "purchaseSeller",
  PURCHASE_BUYER_CASH: "purchaseBuyerCash",
  PURCHASE_BUYER_LOAN: "purchaseBuyerLoan",
  LOAN_MOD_BORROWER: "loanModBorrower",
  OTHER: "other",
  HELOC: "heloc",
  NOTAVERSE_REFINANCE: "notaverseRefinance",
  NOTAVERSE_PURCHASE_SELLER: "notaversePurchaseSeller",
  NOTAVERSE_PURCHASE_BUYER_CASH: "notaversePurchaseBuyerCash",
  NOTAVERSE_PURCHASE_BUYER_LOAN: "notaversePurchaseBuyerLoan",
  NOTAVERSE_LOAN_MOD_BORROWER: "notaverseLoanModBorrower",
  NOTAVERSE_OTHER: "notaverseOther",
  NOTAVERSE_HELOC: "notaverseHELOC",
  HYBRID_REFINANCE: "hybridRefinance",
  HYBRID_TRAILING_DOCS: "hybridTrailing",
  HYBRID_PURCHASE_SELLER: "hybridPurchaseSeller",
  HYBRID_PURCHASE_BUYER_CASH: "hybridPurchaseBuyerCash",
  HYBRID_PURCHASE_BUYER_LOAN: "hybridPurchaseBuyerLoan",
  HYBRID_HELOC: "hybridHeloc",
  HYBRID_OTHER: "hybridOther",
  ADDITIONAL_REFINANCE_NSA_MEETING: "additionalRefinanceNsaMeeting",
  ADDITIONAL_PURCHASE_SELLER_NSA_MEETING: "additionalPurchaseSellerNsaMeeting",
  ADDITIONAL_PURCHASE_BUYER_CASH_NSA_MEETING: "additionalPurchaseBuyerCashNsaMeeting",
  ADDITIONAL_PURCHASE_BUYER_LOAN_NSA_MEETING: "additionalPurchaseBuyerLoanNsaMeeting",
  ADDITIONAL_LOAN_MOD_BORROWER_NSA_MEETING: "additionalLoanModBorrowerNsaMeeting",
  ADDITIONAL_HELOC_NSA_MEETING: "additionalHELOCNsaMeeting",
  NOTAVERSE_ADDITIONAL_REFINANCE_NSA_MEETING: "notaverseAdditionalRefinanceNsaMeeting",
  NOTAVERSE_ADDITIONAL_PURCHASE_SELLER_NSA_MEETING: "notaverseAdditionalPurchaseSellerNsaMeeting",
  NOTAVERSE_ADDITIONAL_PURCHASE_BUYER_CASH_NSA_MEETING:
    "notaverseAdditionalPurchaseBuyerCashNsaMeeting",
  NOTAVERSE_ADDITIONAL_PURCHASE_BUYER_LOAN_NSA_MEETING:
    "notaverseAdditionalPurchaseBuyerLoanNsaMeeting",
  NOTAVERSE_ADDITIONAL_LOAN_MOD_NSA_MEETING: "notaverseAdditionalLoanModBorrowerNsaMeeting",
  NOTAVERSE_ADDITIONAL_HELOC_NSA_MEETING: "notaverseAdditionalHELOCNsaMeeting",
  CLOSING_CONCIERGE: "closingConcierge",
  ...BASE_PRICE_ITEMS,
});
