import { useState } from "react";

import { BulkActionButton } from "common/core/bulk_actions/common";
import { capitalizeFirstLetter } from "util/string";

import { EditProfileModal } from "./edit_profile_modal";
import type { CompanyOrgsPaginated_node_Organization_settingProfiles as AvailableProfile } from "../graphql/company_orgs_paginated.graphql";
import type { OrgTablePaginationItem } from "../table_with_pagination";

export function BulkEditProfileButton({
  profileType,
  selectedItems,
  availableProfiles,
}: {
  profileType: string;
  selectedItems: OrgTablePaginationItem[];
  availableProfiles: AvailableProfile[];
}) {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  return (
    <>
      <BulkActionButton
        buttonColor="action"
        onClick={() => setShowEditModal(!showEditModal)}
        withIcon={{ name: "pencil", placement: "left" }}
        automationSuffix={profileType}
        label={capitalizeFirstLetter(profileType)}
      />
      {showEditModal && (
        <EditProfileModal
          profileType={profileType}
          setShowEditModal={setShowEditModal}
          items={selectedItems}
          availableProfiles={availableProfiles}
        />
      )}
    </>
  );
}
