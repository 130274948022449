import "./index.scss";
import classnames from "classnames";
import type { ReactNode } from "react";

/**
 * A component that serves as the divider between different parts of a subform.
 * The SubForm Section should break up different function groups inside of a form.
 *
 * For example, if a SubForm had two sections: signer details, and a radio button
 * asking "require secondary ID? yes/no", each of the prior would be a SubForm Section.
 *
 * @param {String} className
 * @param {Array<FormRow>} children All children inside of the content section should be
 * instances of the FormRow component.
 * @param {TipWell} tipWell Any tip well that we should display with this sub form section.
 * @type any
 */

type DeprecatedSubFormSectionProps = {
  className?: string;
  automationId?: string;
  children: ReactNode;
  tipWell?: ReactNode;
  fullWidth?: boolean;
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedSubFormSection = ({
  className,
  automationId = "subForm",
  children,
  tipWell,
  fullWidth,
}: DeprecatedSubFormSectionProps) => {
  return (
    <div className={classnames("SubFormSection", className)} data-automation-id={automationId}>
      <div className={classnames("SubFormSection--content", { fullWidth })}>{children}</div>
      {tipWell}
    </div>
  );
};
