import { defineMessages, useIntl } from "react-intl";

import { MailtoLink } from "common/core/mailto_link";
import { userFullName } from "util/user";
import { DescriptionListItem } from "common/core/description_list";

type Props = {
  email: string;
  user?: {
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
  mailToEmail?: boolean;
};

const titles = defineMessages({
  signingGroup: {
    id: "fc70f346-e3d2-4fad-bb8e-4d3fa94bd678",
    defaultMessage: "Signing group",
  },
  signer: {
    id: "4f06a322-9c35-4f12-8bf6-e642ce6c3b77",
    defaultMessage: "Signer",
  },
});

export function RecipientGroupDetails({ email, user, mailToEmail = false }: Props) {
  const intl = useIntl();
  // signer is not claimed until we have email and name data on the user
  // TODO: we can remove name check once this is completed: BIZ-5697
  const isClaimed = user?.email && (user.firstName || user.lastName);

  const emailContent = mailToEmail ? <MailtoLink emailAddress={email} /> : email;

  return (
    <>
      <DescriptionListItem
        term={intl.formatMessage(titles.signingGroup)}
        definition={emailContent}
      />
      {isClaimed && (
        <DescriptionListItem
          term={intl.formatMessage(titles.signer)}
          definition={userFullName(user)}
        />
      )}
    </>
  );
}
