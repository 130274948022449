import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { useMutation, useQuery } from "util/graphql";
import WorkflowModal from "common/modals/workflow_modal";
import { useFeatureFlag } from "common/feature_gating";
import { useForm } from "common/core/form";
import Button from "common/core/button";
import LoadingIndicator from "common/core/loading_indicator";
import { AutomaticFormRow, Label } from "common/core/form/layout";
import { TextInput } from "common/core/form/text";
import { defaultRequiredMessage } from "common/core/form/error";
import { AddressInput } from "common/core/form/address";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { COMMAND_CENTER_DIRECTORY_PAGINATION } from "constants/feature_gates";

import UpdateOrganizationDetailsMutation from "./graphql/update_organization_details_mutation.graphql";
import OrganizationDetailsQuery, {
  type OrganizationDetails_organization_Organization as Organization,
  type OrganizationDetails_organization_Organization_address as Address,
} from "./graphql/organization_details_query.graphql";
import CompanyOrgStructureRootQuery, {
  type CompanyOrgStructureRoot as RootOrgType,
  type CompanyOrgStructureRoot_rootOrganization_Organization as RootOrg,
  type CompanyOrgStructureRootVariables,
  type CompanyOrgStructureRoot_rootOrganization_Organization_subsidiaryOrganizations as SubsidiaryOrganization,
} from "../hierarchy_structure/graphql/index_root.query.graphql";
import CompanyOrgsPaginatedQuery, {
  type CompanyOrgsPaginated as CompanyOrgsPaginatedType,
  type CompanyOrgsPaginated_node_Organization as PaginatedRootOrg,
  type CompanyOrgsPaginated_node_Organization_companyOrganizations_edges as CompanyOrganizations,
  type CompanyOrgsPaginatedVariables,
} from "../table_structure/graphql/company_orgs_paginated.graphql";
import Styles from "./organization_details_modal.module.scss";

export type OrganizationDetailsProps = {
  mainOrgId: string;
  organizationId: string;
  onCancel: () => void;
};
type OrganizationDetailsInnerProps = {
  mainOrgId: string;
  onCancel: () => void;
  organization: Organization;
  offset: number;
  query: string | null;
};
type OrganizationDetailsForm = { address: Address; name: string };

const MESSAGES = {
  updateOrganizationSuccess: {
    id: "d90e6380-6e54-48f5-b0c8-a1674a5dc553",
    defaultMessage: "Organization updated.",
  },
  updateOrganizationFailed: {
    id: "968ccd7f-0734-4d77-839c-17ee2bca556b",
    defaultMessage: "Failed to update organization.",
  },
};

// TODO: Remove after pagination flag is the default
function updateOldTableCache(
  subsidiaryOrganizations: SubsidiaryOrganization[],
  organization: Organization,
  name: string,
  cachedStructure: RootOrgType,
) {
  const updatedOrgIndex = subsidiaryOrganizations.findIndex(
    (org: SubsidiaryOrganization) => org.id === organization.id,
  );
  const updatedOrg = subsidiaryOrganizations[updatedOrgIndex];
  const newOrg = { ...updatedOrg, name };
  const allOrganizations = subsidiaryOrganizations.filter(
    (org: SubsidiaryOrganization) => org.id !== newOrg.id,
  );

  allOrganizations.splice(updatedOrgIndex, 0, newOrg);
  return {
    ...cachedStructure,
    rootOrganization: {
      ...cachedStructure.rootOrganization,
      subsidiaryOrganizations: allOrganizations,
    },
  };
}

function OrganizationDetailsModalInner({
  organization,
  onCancel,
  mainOrgId,
  offset = 0,
  query = null,
}: OrganizationDetailsInnerProps) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const isPaginationEnabled = useFeatureFlag(COMMAND_CENTER_DIRECTORY_PAGINATION);
  const updateOrganizationDetails = useMutation(UpdateOrganizationDetailsMutation);
  const form = useForm<OrganizationDetailsForm>({
    defaultValues: { address: organization.address, name: organization.name || "" },
  });

  const detailsOnSave = form.handleSubmit((formValues: OrganizationDetailsForm) => {
    setIsLoading(true);
    const successPush = () =>
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        message: intl.formatMessage(MESSAGES.updateOrganizationSuccess),
        position: "topCenter",
      });
    const failurePush = () =>
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(MESSAGES.updateOrganizationFailed),
        position: "topCenter",
      });

    const { address, name } = formValues;
    updateOrganizationDetails({
      variables: {
        input: {
          id: organization.id,
          address: {
            line1: address.line1,
            line2: address.line2,
            country: address.country,
            state: address.state,
            city: address.city,
            postal: address.postal,
          },
          name,
        },
      },
      update(cacheProxy, { data }) {
        if (!data?.updateOrganization?.organization?.id) {
          throw new Error("Organization failed to update.");
        }

        let cachedQuery = null;
        let cachedStructure: RootOrgType | CompanyOrgsPaginatedType | null = null;
        let rootOrganization = null;
        let variables: { organizationId: string; offset?: number; query?: string | null } = {
          organizationId: mainOrgId,
        };

        // Need to check if we're using pagination since it uses a new query
        if (isPaginationEnabled) {
          cachedStructure = cacheProxy.readQuery<
            CompanyOrgsPaginatedType,
            CompanyOrgsPaginatedVariables
          >({
            query: CompanyOrgsPaginatedQuery,
            variables: { organizationId: mainOrgId, offset, query },
          })!;
          variables = { ...variables, offset, query };
          cachedQuery = CompanyOrgsPaginatedQuery;
          rootOrganization = cachedStructure.node as PaginatedRootOrg;
        } else {
          cachedStructure = cacheProxy.readQuery<RootOrgType, CompanyOrgStructureRootVariables>({
            query: CompanyOrgStructureRootQuery,
            variables: { organizationId: mainOrgId },
          });
          rootOrganization = cachedStructure?.rootOrganization as RootOrg;
          cachedQuery = CompanyOrgStructureRootQuery;
        }

        let updatedData = {};
        // If we're updating the root, we dont need to check the other orgs
        if (rootOrganization.id === organization.id) {
          updatedData = { ...cachedStructure, name };
        } else if (!isPaginationEnabled) {
          updatedData = updateOldTableCache(
            (rootOrganization as RootOrg).subsidiaryOrganizations,
            organization,
            name,
            cachedStructure as RootOrgType,
          );
        } else {
          const companyOrganizations = (rootOrganization as PaginatedRootOrg).companyOrganizations
            .edges;
          const updatedOrgIndex = companyOrganizations.findIndex(
            (org: CompanyOrganizations) => org.node.id === organization.id,
          );
          const updatedOrg = companyOrganizations[updatedOrgIndex];
          const newOrg = { ...updatedOrg, name };
          const allOrganizations = companyOrganizations.filter(
            (org: CompanyOrganizations) => org.node.id !== newOrg.node.id,
          );
          allOrganizations.splice(updatedOrgIndex, 0, newOrg);

          updatedData = {
            node: {
              ...rootOrganization,
              companyOrganizations: {
                ...(rootOrganization as PaginatedRootOrg).companyOrganizations,
                edges: allOrganizations,
              },
            },
          };
        }

        cacheProxy.writeQuery({
          query: cachedQuery,
          variables,
          data: updatedData,
        });
      },
    })
      .then(() => {
        successPush();
        onCancel();
      })
      .catch(failurePush)
      .finally(() => setIsLoading(false));
  });

  return (
    <WorkflowModal
      large
      className={Styles.organizationDetailsModal}
      title={
        <FormattedMessage
          id="db94d64d-628b-4a5d-acb6-cc8386f1b34a"
          defaultMessage="Organization details"
        />
      }
      footerSeparator={false}
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          onClick={onCancel}
          disabled={isLoading}
          automationId="organization-details-cancel-btn"
        >
          <FormattedMessage id="8ce6a854-52d9-49a8-bb01-5962150654b1" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isLoading}
          automationId="organization-details-save-btn"
          onClick={detailsOnSave}
        >
          <FormattedMessage
            id="9e185784-919b-404c-bb50-cdf0cbf751ef"
            defaultMessage="Save changes"
          />
        </Button>,
      ]}
      closeBehavior={{ tag: "with-button", onClose: onCancel, disableClickOutside: true }}
      autoFocus
    >
      <>
        <AutomaticFormRow<OrganizationDetailsForm>
          form={form}
          name="name"
          data-automation-id="organization-details-name"
          label={
            <FormattedMessage
              id="cf163a17-6cd5-4521-86a7-600c5759e083"
              defaultMessage="Business name"
            />
          }
          registerOptions={{ required: defaultRequiredMessage(intl) }}
          as={TextInput}
          required
          fullWidth
        />
        <Label className={Styles.prefix} htmlFor="organization-address">
          <FormattedMessage id="7dd42366-36ed-48c8-995a-4fe636272ce8" defaultMessage="Address" />
        </Label>
        <AddressInput<"address", OrganizationDetailsForm>
          form={form}
          name="address"
          showAddressLookup
          required
        />
        <div>
          <Label className={Styles.prefix}>
            <FormattedMessage
              id="569e3fc2-7c11-4f95-8a48-c5f0769785d8"
              defaultMessage="Organization ID"
            />
          </Label>
          <br />
          {organization.id}
        </div>
      </>
    </WorkflowModal>
  );
}

export function OrganizationDetailsModal({
  mainOrgId,
  organizationId,
  onCancel,
}: OrganizationDetailsProps) {
  const variables = { activeOrgId: organizationId };
  const { data, loading } = useQuery(OrganizationDetailsQuery, { variables });
  const [rawQueryArgs] = useSearchParams();

  if (loading) {
    return <LoadingIndicator className={Styles.loading} />;
  }

  const organization = data!.organization as Organization | undefined;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return (
    <OrganizationDetailsModalInner
      organization={organization}
      onCancel={onCancel}
      mainOrgId={mainOrgId}
      offset={Number(rawQueryArgs.get("offset")) || 0}
      query={rawQueryArgs.get("query") || null}
    />
  );
}
