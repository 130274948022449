import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { ANNOTATION_SUBTYPES } from "constants/annotations";

import MobilePdfTool from "./mobile_pdf_tool";
import Styles from "./mobile_pdf_asset_tool.module.scss";

type Props = {
  asset?: {
    key: string | null;
    url: string | null;
  } | null;
  assetType: typeof ANNOTATION_SUBTYPES.SIGNATURE | typeof ANNOTATION_SUBTYPES.INITIALS;
  selected: boolean;
  onSelect: () => void;
  onEditAsset: () => Promise<unknown>;
};

function MobilePdfAssetTool({ asset, assetType, selected, onEditAsset, onSelect }: Props) {
  const isSignature = assetType === ANNOTATION_SUBTYPES.SIGNATURE;
  const hasAsset = asset?.url;
  if (hasAsset) {
    return (
      <li className={Styles.assetToolContainer}>
        <button
          type={"button"}
          className={classNames(Styles.assetButton, selected && Styles.selected)}
          onClick={onSelect}
        >
          <img alt="signature" crossOrigin="anonymous" src={asset.url!} />
        </button>
        <button type={"button"} className={Styles.editButton} onClick={onEditAsset}>
          <FormattedMessage id="a3641525-c3f6-4f98-a938-277bf6e32458" defaultMessage={"Edit"} />
        </button>
      </li>
    );
  }
  return (
    <MobilePdfTool
      label={
        isSignature ? (
          <FormattedMessage
            id="5dcc7604-9bdc-46c1-af44-3511101bfcd9"
            defaultMessage={"Create signature"}
          />
        ) : (
          <FormattedMessage
            id="9cd58bf2-35a8-4a19-b6f6-8c1c5bad01e8"
            defaultMessage={"Create initials"}
          />
        )
      }
      iconName="pen-tip"
      onClick={async () => {
        await onEditAsset();
        onSelect();
      }}
    />
  );
}

export default MobilePdfAssetTool;
