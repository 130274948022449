import "./row.scss";

import { useEffect, useRef, memo, type ChangeEvent, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import Icon from "common/core/icon";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import Tooltip from "common/core/tooltip";
import SROnly from "common/core/screen_reader";
import { LongFormattedDateTime } from "common/core/format/date";
import { truncate } from "util/string";
import { annotationDesignationTextDefaults } from "common/pdf/util";
import { ProcessingStates, DocumentNoteContext } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";
import { IconButton } from "common/core/button/icon_button";
import { Paragraph } from "common/core/typography";
import Button from "common/core/button";
import { b } from "util/html";
import { ineligibleReasonMessages } from "common/banners/ineligible_notarization_banner";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import TooltipOverlay from "common/core/tooltip/overlay";

import {
  ReplaceDocumentUploader,
  type FileReplace,
  type onReplaceDocument,
} from "../replace_document_uploader";

function isRenamingFn(currentWorkingTitle: string | null | undefined) {
  return Boolean(currentWorkingTitle || currentWorkingTitle === "");
}

const noop = () => {};

const messages = defineMessages({
  edit: { id: "14d3da4e-4c29-4af3-8272-715af1cc55be", defaultMessage: "Edit document title" },
  download: { id: "de4a616b-dbd7-499b-8125-710e5c932646", defaultMessage: "Download" },
  delete: { id: "e7805d77-2019-49b8-a413-5ca4d333014e", defaultMessage: "Delete" },
  resubmit: { id: "7685cb80-3c83-475a-88e1-e4e198f58745", defaultMessage: "Resubmit" },
  cancel: { id: "5ded048e-4239-45af-80d2-507d605db04e", defaultMessage: "Cancel renaming" },
  noPermission: {
    id: "a9ef9885-3a07-4a8b-9294-a8d90c981a3f",
    defaultMessage: "You do not have permission to edit the document title",
  },
});

export type Document = {
  id: string;
  hidden: boolean;
  signerCanAnnotate?: boolean;
  notarizationRequired?: boolean;
  signingRequiresMeeting?: boolean;
  witnessRequired?: boolean;
  metadata: { pagesTotal: number | null } | null;
  createdAt?: NotarizeScalarDateWithTimezone | null;
  deletedAt?: NotarizeScalarDateWithTimezone | null;
  failedTemplateMatch?: boolean;
  designations?: { edges: ({ node: { type: string; signerRole: { index: string } } } | null)[] };
  notes?: { context: DocumentNoteContext; resolution: boolean; category: string }[];
};

type Props = {
  onStartRename?: () => void;
  onStopRename?: () => void;
  onCancelRename?: () => void;
  onResubmitDocument: (document: Document) => void;
  onChangeTitle?: (event: ChangeEvent<HTMLInputElement>) => void;
  onDeleteDocument?: () => void;
  /** Called with a document to set which one to replace */
  setDocumentToReplace?: (doc?: Document) => void;
  /** Takes in argument file to update document bundle/template with document replacement */
  onReplaceDocument?: (file: FileReplace) => ReturnType<typeof onReplaceDocument>;
  onDownloadDocument?: () => void;
  name: string;
  truncateName?: boolean;
  status?: ProcessingStates;
  onDocumentClick?: () => void;
  allowDelete?: boolean;
  allowReplace?: boolean;
  disableClick?: boolean;
  allowRename?: boolean;
  cxName?: string;
  index?: number;
  allowDownload?: boolean;
  isHybrid?: boolean;
  showDocumentType?: boolean;
  placeAnOrderEnabled?: boolean;
  placeAnOrderLenderEnabled?: boolean;
  workingTitle?: string | undefined;
  templateDeleteDisabled?: boolean;
  hasPermissionFor?: boolean;
  isLockedDocument?: boolean;
  customerSigners?: { id: string }[];
  document?: Document;
  viewer?: { user: { id: string } };
  ineligibleReason?: string;
};

const notaryMeetingLabel = (
  <FormattedMessage id="fd54e204-1891-4d34-83a0-b2dfcc5adee0" defaultMessage="Notary Meeting" />
);
const wetSignLabel = (
  <FormattedMessage id="50d68d7d-89ec-4058-a682-ed20be5d7a98" defaultMessage="Wet Sign" />
);
const esignLabel = (
  <FormattedMessage id="4f6e5371-201e-4ea4-8d67-2d91dbfacd11" defaultMessage="eSign" />
);
const hiddenLabel = (
  <FormattedMessage
    id="3b7d2eb3-dc0a-4518-abb9-29092925a2ee"
    defaultMessage="Hidden from signers"
  />
);

/** Component that contains the document title, rename and delete single item behavior. */
function RowTitle(props: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { hasPermissionFor } = usePermissions();
  const intl = useIntl();
  const {
    cxName = "DocumentUploaderRow",
    onDownloadDocument = () => {},
    index = -1,
    placeAnOrderEnabled = false,
    placeAnOrderLenderEnabled = false,
    isHybrid,
    showDocumentType,
    document,
    workingTitle,
    name,
    truncateName = true,
    customerSigners,
    status,
    onDeleteDocument,
    setDocumentToReplace,
    onReplaceDocument,
    onChangeTitle,
    onStartRename,
    onStopRename,
    onCancelRename,
    onResubmitDocument,
    allowDownload,
    onDocumentClick,
    disableClick,
    allowDelete,
    allowReplace,
    allowRename,
    templateDeleteDisabled,
    isLockedDocument,
    viewer,
    ineligibleReason,
  } = props;
  const [replacingDocId, setReplacingDocId] = useState<string | null>(null);
  const uploadFailed = status === ProcessingStates.FAILED;
  const isRenaming = isRenamingFn(workingTitle);
  const editingTitle = `${cxName}--Title__editing`;
  const error = `${cxName}--Title__error DocumentUploaderRow--UploadFailed`;
  const ineligible = "DocumentUploaderRow--Ineligible";
  const rowClasses = classnames(`${cxName}--Title`, {
    [editingTitle]: isRenaming,
    [error]: uploadFailed,
    [ineligible]: !!ineligibleReason,
  });

  // Focus the input when renaming
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const lastIndex = name.lastIndexOf(".") > 0 ? name.lastIndexOf(".") : name.length;
    const newName = name.slice(0, lastIndex);
    if (workingTitle === newName) {
      const current = inputRef.current;
      current.focus();
      current.setSelectionRange(lastIndex, lastIndex);
    }
  }, [workingTitle]);

  function renderDocumentType() {
    if (!showDocumentType || !document) {
      return null;
    }

    let documentType;
    const {
      signerCanAnnotate,
      notarizationRequired,
      signingRequiresMeeting,
      witnessRequired,
      hidden,
      deletedAt,
      createdAt,
    } = document;
    const isEsign =
      !signerCanAnnotate && !notarizationRequired && !signingRequiresMeeting && !witnessRequired;
    const cx = classnames(
      `${cxName}--DocumentType`,
      { [`${cxName}--RequiresMeeting`]: !isEsign },
      { [`${cxName}--hidden`]: hidden },
      { [`${cxName}--Deleted`]: deletedAt },
      { [`${cxName}--Created`]: createdAt },
    );

    if (hidden) {
      documentType = hiddenLabel;
    } else if (isEsign) {
      documentType = esignLabel;
    } else {
      documentType = isHybrid ? wetSignLabel : notaryMeetingLabel;
    }

    return (
      <div className={cx}>
        <Paragraph size="small">{documentType}</Paragraph>
      </div>
    );
  }

  function renderFileInfo({ cxName }: { cxName: string }) {
    if (!document) {
      return null;
    }
    const { deletedAt, createdAt } = document;
    const pagesTotal = document.metadata?.pagesTotal;
    return (
      <div className={`${cxName}--Text`}>
        <Paragraph size="small">
          <FormattedMessage
            id="a8f77890-dccc-499c-bca1-161fa9100854"
            defaultMessage="{pagesTotal} {pagesTotal, plural, one{page} other{pages}}, {date}"
            values={{
              pagesTotal,
              date: deletedAt ? (
                <LongFormattedDateTime value={deletedAt} />
              ) : (
                <LongFormattedDateTime value={createdAt} />
              ),
            }}
          />
        </Paragraph>
      </div>
    );
  }

  function renderSignerDesignationCounts(index: number, signerId: string, cxName: string) {
    if (!document) {
      return;
    }
    const { id } = document;
    const designations = document.designations;
    const notes = document.notes;

    const unresolvedDerivedNotes = notes?.filter(
      (note) =>
        note.context === DocumentNoteContext.DERIVED_TAG &&
        !note.resolution &&
        note.category !== "resolution",
    );

    /**
     * We only need one tagging failure message even if there is more than one signer
     */
    if (unresolvedDerivedNotes && unresolvedDerivedNotes.length > 0 && index === 0) {
      const message = unresolvedDerivedNotes[0].category;
      const toolTipMessage = (
        <div className="DocumentUploaderRow--FailureMessage">
          <FormattedMessage
            id="ef5ee361-8d39-470a-a2a9-1ff79aedd7aa"
            defaultMessage="{message}"
            values={{ message }}
          />
        </div>
      );
      const designationMessage = (
        <div className="DocumentUploaderRow--TaggingFailure">
          <FormattedMessage
            id="b332f2ee-d80c-4238-9740-d58568b5858c"
            defaultMessage="Tagging Failure"
          />
        </div>
      );
      return (
        <Tooltip target={designationMessage} placement="right">
          {toolTipMessage}
        </Tooltip>
      );
    } else if (!signerId || !designations || designations.edges.length < 1) {
      return null;
    }

    const signerDesignationTypes = designations.edges
      .filter((node) => {
        return (
          node?.node.signerRole.index === signerId || node?.node.signerRole.index === `${index}`
        );
      })
      .map((node) => annotationDesignationTextDefaults(node?.node.type));
    const totalDesignations = signerDesignationTypes.length;

    const designationCounts = {} as Record<string, number>;
    for (let i = 0; i < signerDesignationTypes.length; i++) {
      const designationType = signerDesignationTypes[i];
      designationCounts[designationType] = designationCounts[designationType]
        ? designationCounts[designationType] + 1
        : 1;
    }

    const designationCountMessage = Object.entries(designationCounts).map(([key, value]) => (
      <Paragraph size="small" key={`${key}-${signerId}-DesignationTip`}>
        {key === "Signature" ? (
          <FormattedMessage
            id="fd44053d-42ca-4afd-bcad-a5fa77442145"
            defaultMessage="{value} {key}{value, plural, one{} other{s}}"
            values={{ key, value }}
          />
        ) : (
          <FormattedMessage
            id="fd44053d-42ca-4afd-bcad-a5fa77442145"
            defaultMessage="{value} {key}"
            values={{ key, value }}
          />
        )}
      </Paragraph>
    ));

    const toolTipMessage = () => (
      <div>
        <Paragraph size="small">
          <FormattedMessage
            id="74f20e3b-0a12-41d8-b07d-e50850e92262"
            defaultMessage={"Signer {index}: {count} field{count, plural, one{} other{s}}"}
            values={{ index: index + 1, count: totalDesignations }}
          />
        </Paragraph>
        {designationCountMessage}
      </div>
    );
    const designationMessage = (
      <div key={`${signerId}-${id}-Signatures`} className={`${cxName}--DesignationMessage`}>
        <Icon name="name" />
        <Paragraph size="small">
          <FormattedMessage
            id="b67df905-8ba4-49a0-a7fb-2bea985e662e"
            defaultMessage="<b><u>S{signerIndex}: {totalDesignations}</u></b>"
            values={{ signerIndex: index + 1, totalDesignations, u: (word) => <u>{word}</u>, b }}
          />
        </Paragraph>
      </div>
    );
    return totalDesignations > 0 ? (
      <div key={`${signerId}-${id}-designationMessage`}>
        <Tooltip
          target={designationMessage}
          className={`${cxName}--SignerTooltip`}
          placement="topLeft"
          trigger="hover"
        >
          {toolTipMessage()}
        </Tooltip>
      </div>
    ) : (
      <div key={`${signerId}-${id}-designationMessage`}>{designationMessage}</div>
    );
  }

  function renderSignerDesignations({ cxName }: { cxName: string }) {
    if (!customerSigners) {
      return null;
    }

    return (
      <div className={`${cxName}--SignerDesignations`}>
        {customerSigners.map((signer, index) => {
          return renderSignerDesignationCounts(index, signer.id, cxName);
        })}
      </div>
    );
  }

  function renderFileIcon() {
    const failedToMatch = document?.failedTemplateMatch;

    let content = null;
    if (status === ProcessingStates.PENDING || document?.id === replacingDocId) {
      content = <SmallLoadingIndicator />;
    }
    if (failedToMatch) {
      content = (
        <div>
          <Tooltip
            placement="topLeft"
            trigger="hover"
            target={<Icon className="not-matched-icon" name="warning" size="extraLarge" />}
          >
            {placeAnOrderEnabled || placeAnOrderLenderEnabled ? (
              <FormattedMessage
                id="e446b298-d692-4123-a349-a3eb01b20bd6"
                defaultMessage="Document Auto-tag failed to process. Closing Operations will apply tags."
              />
            ) : (
              <FormattedMessage
                id="21712472-9081-421b-9d07-8c14780c7cef"
                defaultMessage="Document Auto-tag failed to process. Add tags manually before proceeding."
              />
            )}
          </Tooltip>
        </div>
      );
    }
    if (uploadFailed) {
      content = (
        <div className="ErrorIcon">
          <Icon className="failed-icon" name="doc-warning" size="extraLarge" />
        </div>
      );
    }
    return content;
  }

  /**
   * This constant determines if a user can resubmit a document through our templating engine. This is only intended
   * to function for closing ops users within a test transaction.
   *
   * TODO: Since test transactions are not yet implemented, we will have to add that requirement to this constant when it is available.
   */
  const userCanResubmit = hasPermissionFor("resubmitDocument") && viewer;

  const fileIndex = index >= 0 ? `-${index}` : "";

  const fileIcon = renderFileIcon();

  const filename =
    status === ProcessingStates.PENDING || disableClick ? (
      <div data-automation-id={`document-uploader-filename${fileIndex}`}>
        {isRenaming && allowRename && onChangeTitle && (
          <input type="text" ref={inputRef} onChange={onChangeTitle} value={workingTitle} />
        )}
        {!isRenaming && truncateName ? truncate(name, 40) : name}
      </div>
    ) : (
      <div
        onClick={isRenaming ? noop : onDocumentClick}
        data-automation-id="document-uploader-document-click"
      >
        {isRenaming && allowRename && onChangeTitle && (
          <input type="text" ref={inputRef} onChange={onChangeTitle} value={workingTitle} />
        )}
        {!isRenaming && (
          <div className={`${cxName}--FileLinkContainer`}>
            <LinkStyledButton
              data-automation-id={`document-uploader-document-link${fileIndex}`}
              className={`${cxName}--Filelink`}
              title={name}
              underlined={false}
            >
              {truncateName ? truncate(name, 40) : name}
            </LinkStyledButton>
            <TooltipOverlay trigger="hover" placement="top">
              <FormattedMessage
                id="5a171f2b-c0c5-4171-9ce9-584baceec9db"
                defaultMessage="Edit document"
              />
            </TooltipOverlay>
          </div>
        )}
      </div>
    );

  const editLabel = isLockedDocument
    ? intl.formatMessage(messages.noPermission)
    : intl.formatMessage(messages.edit);
  return (
    <div className={rowClasses}>
      <div className={`${cxName}--File`}>
        {fileIcon && !ineligibleReason && <div className={`${cxName}--FileIcon`}>{fileIcon}</div>}
        {ineligibleReason && (
          <div className="DocumentUploaderRow--WarningIcon">
            <Icon name="warning" size="extraLarge" />
          </div>
        )}
        <div>
          <div className={`${cxName}--Filename`}>
            {filename}
            {!uploadFailed && (
              <div className={`${cxName}--FileInfo`}>
                {renderDocumentType()}
                {renderFileInfo({ cxName: `${cxName}--FileInfo` })}
                {renderSignerDesignations({ cxName: `${cxName}--FileInfo` })}
              </div>
            )}
          </div>
          {uploadFailed && (
            <Paragraph className={`${cxName}--UploadFailure`} textColor="danger" size="small">
              <FormattedMessage
                id="a603d965-19ab-4a90-8a6c-b9ab46bd78dc"
                defaultMessage="Failed to upload"
              />
            </Paragraph>
          )}
          {ineligibleReason && (
            <Paragraph className="DocumentUploaderRow--IneligibleMessage" size="small">
              {ineligibleReasonMessages[ineligibleReason]}
            </Paragraph>
          )}
        </div>
      </div>
      <div className={`${cxName}--SignatureAction`}>
        <div className={`${cxName}--HoverActions`}>
          {isRenaming && allowRename && (
            <Button
              variant="tertiary"
              buttonColor="action"
              buttonSize="condensed"
              onClick={onStopRename}
              automationId={`document-uploader-save-rename-document${fileIndex}`}
            >
              <FormattedMessage id="df68d1a5-7d25-4935-a975-6f745d9c9b86" defaultMessage="Save" />
            </Button>
          )}
          {!isRenaming && allowRename && status === ProcessingStates.DONE && onStartRename && (
            <IconButton
              name="pencil"
              onClick={onStartRename}
              buttonSize="condensed"
              iconClassName="blue-icon icon"
              disabled={isLockedDocument}
              automationId={`document-uploader-rename-document${fileIndex}`}
              label={
                <>
                  <span aria-hidden="true">{editLabel}</span>
                  <SROnly>{`${editLabel} ${name}`}</SROnly>
                </>
              }
              hoverLabel="top"
            />
          )}
          {!isRenaming && allowDownload && (
            <IconButton
              name="arrow-down-square"
              onClick={onDownloadDocument}
              buttonSize="condensed"
              iconClassName="blue-icon icon"
              automationId={`document-uploader-download-document${fileIndex}`}
              label={
                <>
                  <span aria-hidden="true">{intl.formatMessage(messages.download)}</span>
                  <SROnly>{`${intl.formatMessage(messages.download)} ${name}`}</SROnly>
                </>
              }
              hoverLabel="top"
            />
          )}
          {!isRenaming && allowDelete && userCanResubmit && document && (
            <IconButton
              name="arrow-clockwise"
              onClick={() => onResubmitDocument(document)}
              buttonSize="condensed"
              iconClassName="blue-icon icon"
              disabled={status === ProcessingStates.PENDING}
              automationId={`document-uploader-resubmit-document${fileIndex}`}
              label={
                <>
                  <span aria-hidden="true">{intl.formatMessage(messages.resubmit)}</span>
                  <SROnly>{`${intl.formatMessage(messages.resubmit)} ${name}`}</SROnly>
                </>
              }
              hoverLabel="top"
            />
          )}
          {!isRenaming && onReplaceDocument && allowReplace && document && (
            <ReplaceDocumentUploader
              handleReplaceDocument={onReplaceDocument}
              name={name}
              setDocumentToReplace={() => setDocumentToReplace?.(document)}
              setReplacingDocId={setReplacingDocId}
              documentToReplace={document}
            />
          )}
          {templateDeleteDisabled ? (
            <Tooltip target={<Icon name="delete" className="red-icon-background icon" />}>
              <FormattedMessage
                id="45defd36-e38c-40a8-826b-46112adce4d1"
                defaultMessage="A template must contain at least one document."
              />
            </Tooltip>
          ) : (
            !isRenaming &&
            allowDelete && (
              <IconButton
                name="delete"
                onClick={onDeleteDocument || noop}
                buttonSize="condensed"
                iconClassName="red-icon icon"
                disabled={templateDeleteDisabled}
                automationId={`document-uploader-delete-document${fileIndex}`}
                label={
                  <>
                    <span aria-hidden="true">{intl.formatMessage(messages.delete)}</span>
                    <SROnly>{`${intl.formatMessage(messages.delete)} ${name}`}</SROnly>
                  </>
                }
                hoverLabel="top"
              />
            )
          )}
          {isRenaming && onCancelRename && (
            <Button
              variant="tertiary"
              buttonColor="dark"
              buttonSize="condensed"
              onClick={onCancelRename}
              automationId={`document-uploader-cancel-rename-document${fileIndex}`}
            >
              <FormattedMessage id="9e995f2e-e312-48d0-a673-4f82d3e25025" defaultMessage="Cancel" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(RowTitle);
