import { FormattedMessage } from "react-intl";

import { serializerFactory, serializeSet } from "common/dashboard/filter/serializers";
import { deserializeRiskLevels } from "common/dashboard/filter/deserializers";
import { useDashboardSegmentTrack } from "common/dashboard/util";
import { getRiskLevelText } from "common/dashboard/risk_level";
import { SelectedFiltersLabel } from "common/dashboard/filter_dropdown/common";
import { SEGMENT_EVENTS } from "constants/analytics";
import { ComboboxDropdown, ComboboxDropdownMenuOption } from "common/core/combobox_dropdown";
import { RiskLevels } from "graphql_globals";

import Styles from "./filter.module.scss";

export function identityCRMDashboardDeserializer(queryArgs: URLSearchParams) {
  const page = Number(queryArgs.get("page"));
  const query = queryArgs.get("query");
  const riskLevels = deserializeRiskLevels(queryArgs.get("riskLevels"));

  return {
    page,
    query,
    riskLevels,
  };
}

const identityCRMDashboardSerializeMap = {
  page: (arg: number | null) => (arg ? String(arg) : null),
  query: (arg: string | null) => arg || null,
  riskLevels: (arg: Set<RiskLevels> | null) => serializeSet(arg),
};
export const identityCRMDashboardSerializer = serializerFactory(identityCRMDashboardSerializeMap);

type RiskLevelsFilterProps = {
  selectedRiskLevels: Readonly<Set<RiskLevels>>;
  toggleSelection: (riskLevel: RiskLevels) => void;
  onClearSelection: () => void;
  disabled: boolean;
  "data-automation-id"?: string;
};

export function RiskLevelsFilter(props: RiskLevelsFilterProps) {
  const { onClearSelection, selectedRiskLevels, toggleSelection, disabled } = props;
  const dashboardSegmentTrack = useDashboardSegmentTrack();
  return (
    <ComboboxDropdown
      label={
        <FormattedMessage
          id="05478cb1-c1a8-484f-9590-2e0c287f9022"
          defaultMessage="All risk levels"
        />
      }
      selectedLabel={
        <SelectedFiltersLabel
          count={selectedRiskLevels.size}
          label={
            <FormattedMessage
              id="05478cb1-c1a8-484f-9590-2e0c287f9022"
              defaultMessage="Risk levels"
            />
          }
        />
      }
      hasSelection={Boolean(selectedRiskLevels.size)}
      onClearSelection={onClearSelection}
      className={Styles.filterContainer}
      allowMultipleSelection
    >
      {() => (
        <>
          {(Object.keys(RiskLevels) as RiskLevels[]).map((riskLevel) => (
            <ComboboxDropdownMenuOption
              key={`option-${riskLevel}`}
              selected={selectedRiskLevels.has(riskLevel)}
              disabled={disabled}
              onChange={() => {
                if (!selectedRiskLevels.has(riskLevel)) {
                  dashboardSegmentTrack(SEGMENT_EVENTS.RISK_LEVELS_FILTER_SELECTED, {
                    value: riskLevel,
                  });
                }
                toggleSelection(riskLevel);
              }}
              optionLabel={getRiskLevelText(riskLevel, "medium").label}
            />
          ))}
        </>
      )}
    </ComboboxDropdown>
  );
}
