import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { DescriptionListItem } from "common/core/description_list";

import Styles from "./liveness_check.module.scss";

const MESSAGES = defineMessages({
  selfieImageAlt: {
    id: "24a5c752-9f1f-4edd-8df5-1973e64e307d",
    defaultMessage: "Liveness check selfie",
  },
});

type Props = {
  selfiePicture: string;
};

export function LivenessCheck({ selfiePicture }: Props) {
  const intl = useIntl();

  const livenessCheckLabel = (
    <FormattedMessage id="24a5c752-9f1f-4edd-8df5-1973e64e307d" defaultMessage="Liveness Check" />
  );
  const livenessCheckContent = (
    <img
      className={Styles.selfie}
      src={selfiePicture}
      alt={intl.formatMessage(MESSAGES.selfieImageAlt)}
    />
  );
  return <DescriptionListItem term={livenessCheckLabel} definition={livenessCheckContent} />;
}
