import { Fragment, useState, useCallback, memo } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryDocumentTypes } from "graphql_globals";
import { LongFormattedDateTime } from "common/core/format/date";
import Link from "common/core/link";
import Icon from "common/core/icon";
import NotaryDocumentViewModal from "common/notary/document_view_modal";

import type { NotaryProfileDocumentation as NotaryProfile } from "./index_fragment.graphql";
import Styles from "./index.module.scss";

type NotaryDocument = NotaryProfile["allNotaryDocuments"][number]["documents"][number];
type Props = {
  notaryProfile: NotaryProfile;
};

const DOC_TYPE_LABEL = Object.freeze({
  [NotaryDocumentTypes.BOND]: (
    <FormattedMessage id="71fe3727-1d33-4c1f-8901-252f643ffd20" defaultMessage="Bond" />
  ),
  [NotaryDocumentTypes.EDUCATION_COMPLETION]: (
    <FormattedMessage
      id="d8448914-1696-4677-a648-6486cd60f778"
      defaultMessage="Proof of Completion of State Course"
    />
  ),
  [NotaryDocumentTypes.PAPER_COMMISSION]: (
    <FormattedMessage id="3c888356-6097-4a1d-8e84-fc005189a006" defaultMessage="Paper Commission" />
  ),
  [NotaryDocumentTypes.POLICY]: (
    <FormattedMessage id="8ce984fc-14c6-4cf2-9839-0f88c6f2ca2f" defaultMessage="E&O" />
  ),
  [NotaryDocumentTypes.PROOF_NOTIFY_COMMISSION]: (
    <FormattedMessage id="8d524fe6-f49b-4c86-8546-a2db9a3155d4" defaultMessage="RON Update Proof" />
  ),
  [NotaryDocumentTypes.RON_AUTHORIZATION]: (
    <FormattedMessage
      id="5a50f47f-d1f9-400d-8b09-c65a11efdf6f"
      defaultMessage="RON Authorization Proof"
    />
  ),
  [NotaryDocumentTypes.CERTIFICATE_APPROVAL]: (
    <FormattedMessage
      id="92c6fe9b-406b-48de-aa0c-c3472a2d33a0"
      defaultMessage="Proof of Digital Certificate"
    />
  ),
  [NotaryDocumentTypes.CERTIFICATE_OF_AUTHORIZATION]: (
    <FormattedMessage
      id="e7984e33-b191-43de-9014-6e1e316fcab9"
      defaultMessage="Certificate of Authorization"
    />
  ),
  [NotaryDocumentTypes.ONLINE_COMMISSION]: (
    <FormattedMessage
      id="1cb484bd-e739-456c-ac83-d6477873ddab"
      defaultMessage="Online Commission"
    />
  ),
  [NotaryDocumentTypes.EXEMPLAR]: (
    <FormattedMessage id="f9ffce66-cc86-4ae6-a5c0-8bba39136bbc" defaultMessage="Exemplar" />
  ),
});

function NotaryDocuments({ notaryProfile }: Props) {
  const { allNotaryDocuments } = notaryProfile;
  const [selectedDocument, setSelectedDocument] = useState<NotaryDocument | null>(null);
  const handleCloseModal = useCallback(() => setSelectedDocument(null), []);
  return (
    <div className={Styles.documentsList}>
      {allNotaryDocuments.map(({ type, documents }) => (
        <Fragment key={type}>
          <div className={Styles.label}>{DOC_TYPE_LABEL[type]}</div>
          {documents.map((document) => (
            <div key={document.file.key}>
              <Icon name="blank-doc" className={Styles.docIcon} />
              <Link underlined={false} onClick={() => setSelectedDocument(document)}>
                <FormattedMessage
                  id="a803f38c-1819-4b2f-b608-ff9150b9ed1c"
                  defaultMessage="Version {version}"
                  values={{ version: document.versionId + 1 }}
                />
              </Link>
              <div className={Styles.date}>
                <FormattedMessage
                  id="51627603-444c-48a5-9657-1c7367763dfe"
                  defaultMessage="Uploaded {time}"
                  values={{ time: <LongFormattedDateTime value={document.updatedAt} /> }}
                  tagName="span"
                />
                <a href={document.file.url!} target="_blank" rel="noreferrer">
                  <Icon name="arrow-down-square" />
                </a>
              </div>
            </div>
          ))}
        </Fragment>
      ))}
      {selectedDocument && (
        <NotaryDocumentViewModal
          onClose={handleCloseModal}
          url={selectedDocument.file.url!}
          fileName={selectedDocument.file.key!}
        />
      )}
    </div>
  );
}

export default memo(NotaryDocuments);
