import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Card, CardSection } from "common/core/card";
import { format } from "common/core/format/date";
import { useId } from "util/html";
import { usePermissions } from "common/core/current_user_role";
import Icon from "common/core/icon";
import ActionButton from "common/core/action_button";
import Button from "common/core/button";
import CancelTierChange from "admin_portal/company/tier_history/cancel_tier_change";

import CommonStyles from "../index.module.scss";
import TierDetails from "./tier_details";
import type { AdminCompanyDetailsOrganization_organization_Organization_scheduledTiers as Tier } from "../organization_query.graphql";

type Props = {
  orgId: string;
  tier: Tier;
  cardType: "active" | "upcoming" | "past";
};

export default function TierCard({ orgId, tier, cardType }: Props) {
  const displayNameId = useId();
  const tierTypeId = useId();
  const { hasPermissionFor } = usePermissions();
  const canCancelTier = hasPermissionFor("editCompanyDetailsTier");
  const [showTierDetails, setShowTierDetails] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const formatDate = (timestamp: string) => {
    return format({
      value: timestamp,
      formatStyle: "MM/dd/yyyy",
      asTimeZone: "EST",
    });
  };

  const formatTime = (timestamp: string) => {
    return format({
      value: timestamp,
      formatStyle: "h:mm a zzz",
      asTimeZone: "EST",
    });
  };
  return (
    <Card
      key={tier.id}
      header={
        <>
          <div
            className={
              cardType === "active"
                ? CommonStyles.headerContainerActive
                : CommonStyles.headerContainerPast
            }
          >
            <div
              className={
                cardType === "active" ? CommonStyles.activeTierName : CommonStyles.pastTierName
              }
            >
              {tier.name}
            </div>
            {cardType === "active" && (
              <div
                className={classnames(
                  CommonStyles.headerStatusPill,
                  CommonStyles.headerStatusPillActive,
                )}
              >
                <FormattedMessage
                  id="8b2e9276-4d81-4ea3-b094-5e7ab88b8be4"
                  defaultMessage="Active tier"
                />
              </div>
            )}
            {cardType === "upcoming" && (
              <div
                className={classnames(
                  CommonStyles.headerStatusPill,
                  CommonStyles.headerStatusPillScheduled,
                )}
              >
                <FormattedMessage
                  id="c2cd0354-68e5-4f51-a4d0-5bc6f2e38a85"
                  defaultMessage="Scheduled tier"
                />
              </div>
            )}
            {cardType === "past" && (
              <div
                className={classnames(
                  CommonStyles.headerStatusPill,
                  CommonStyles.headerStatusPillPast,
                )}
              >
                <FormattedMessage
                  id="8551176d-8921-4090-b2dd-e05ae7d56fa1"
                  defaultMessage="Past tier"
                />
              </div>
            )}
          </div>
          <div>
            {cardType === "active" ? (
              <div className={CommonStyles.activeTimestamp}>
                Since {formatDate(tier.startAt)}
                <div className={CommonStyles.time}>{formatTime(tier.startAt)}</div>
              </div>
            ) : (
              <div className={CommonStyles.pastTimestamp}>
                {cardType === "upcoming" && (
                  <div className={CommonStyles.timeGroup}>
                    Starts {formatDate(tier.startAt)}
                    <div className={CommonStyles.time}>{formatTime(tier.startAt)}</div>
                  </div>
                )}
                {cardType === "past" && (
                  <>
                    <div className={CommonStyles.timeGroup}>
                      {formatDate(tier.startAt)}
                      <div className={CommonStyles.time}>{formatTime(tier.startAt)}</div>
                    </div>
                    -
                    {tier.endAt && (
                      <div className={CommonStyles.timeGroup}>
                        {formatDate(tier.endAt)}
                        <div className={CommonStyles.time}>{formatTime(tier.endAt)}</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      }
      collapsibleElement={cardType !== "active" && "icon"}
      footer={
        cardType === "upcoming" && (
          <Button
            disabled={!canCancelTier}
            variant="tertiary"
            buttonColor="danger"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            <FormattedMessage
              id="cac9220b-a8bf-4f52-aa2b-0bd82f7ce17b"
              defaultMessage="Cancel tier change"
            />
          </Button>
        )
      }
    >
      <CardSection>
        <div className={CommonStyles.tierDetailLabel} id={displayNameId}>
          <FormattedMessage
            id="a9924876-65e5-42d9-9558-d47e6b96c83e"
            defaultMessage="Display name"
          />
          <div className={CommonStyles.tierDetail} aria-describedby={displayNameId}>
            {tier.displayName}
          </div>
        </div>
        <div className={CommonStyles.tierDetailLabel} id={tierTypeId}>
          <FormattedMessage id="e0a01bb6-cad2-4809-8c79-3b1cab3cc7a8" defaultMessage="Tier type" />
          <div className={CommonStyles.tierDetail} aria-describedby={tierTypeId}>
            {tier.tier}
          </div>
        </div>
      </CardSection>
      <CardSection>
        <div className={CommonStyles.detailsToggle}>
          <ActionButton onClick={() => setShowTierDetails(!showTierDetails)}>
            <FormattedMessage
              id="8e8dd095-ea1e-4887-8a45-d5de35151bb7"
              defaultMessage="Tier details"
            />
            <Icon name={showTierDetails ? "caret-up" : "caret-down"} />
          </ActionButton>
        </div>
      </CardSection>
      {showTierDetails && (
        <TierDetails
          tier={tier.tier}
          prices={tier.prices}
          pricingDisplayNames={tier.pricingDisplayNames as Record<string, ReactNode>}
          optionDisplayNames={tier.optionDisplayNames as Record<string, ReactNode>}
          options={tier.options}
          pricesJson={tier.pricesJson}
          showHeaders
        />
      )}
      {showConfirmModal && (
        <CancelTierChange
          organizationId={orgId}
          tierPlanId={tier.id}
          tierPlanName={tier.name}
          onClose={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
    </Card>
  );
}
