import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedEmailField } from "common/form/fields/email";

type Props = {
  fieldNamePrefix: string;
  displayRequiredAsterisk?: boolean;
  readOnly?: boolean;
};

function EmailRow({ fieldNamePrefix, displayRequiredAsterisk, readOnly }: Props) {
  const prefixedEmailField = `${fieldNamePrefix}.email`;

  return (
    <FormGroup fields={[prefixedEmailField]} disableFormRowStyle>
      <DeprecatedEmailField
        id={prefixedEmailField}
        name={prefixedEmailField}
        data-automation-id={`${fieldNamePrefix}.poc-email-field`}
        placeholderAsLabel
        useStyledInput
        displayRequiredAsterisk={displayRequiredAsterisk}
        disabled={readOnly}
      />

      <FormGroupErrors fields={[prefixedEmailField]} />
    </FormGroup>
  );
}

export default EmailRow;
