import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { Heading, Paragraph } from "common/core/typography";
import EmptyState from "assets/images/defend/defend-empty-state.svg";
import { TableEmptyState } from "common/table/empty_state";
import { getParentPathByOffset } from "util/location";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

export function IdentityDashboardEmptyState() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;
  const backPath = isKeystone ? `${getParentPathByOffset(pathname, 1)}/details` : "/";

  return (
    <TableEmptyState.Container>
      <img src={EmptyState} alt="" />
      <Heading level="h1" textStyle="headingFour">
        <FormattedMessage
          id="9e37ef60-85f3-473c-b5c5-fe85a84102cd"
          defaultMessage="No identities found"
        />
      </Heading>
      <Paragraph textColor="subtle">
        <FormattedMessage
          id="0698bafa-42f8-44a7-861e-5b9d3cd786c5"
          defaultMessage="Users will appear here after they start their identity verification process. Send your first transaction to get started."
        />
      </Paragraph>
      <TableEmptyState.ActionButton
        buttonColor="action"
        variant="primary"
        onClick={() => {
          navigate(backPath);
        }}
      >
        {isKeystone ? (
          <FormattedMessage
            id="9bc5cefc-4ef5-4dee-97ab-e6dd8b473c7c"
            defaultMessage="View organization details"
          />
        ) : (
          <FormattedMessage
            id="215b8fae-f0d9-402e-a035-48bb83fa9864"
            defaultMessage="Return to dashboard"
          />
        )}
      </TableEmptyState.ActionButton>
    </TableEmptyState.Container>
  );
}
