import { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import Env from "config/environment";
import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { FormRow as CoreFormRow } from "common/core/form/layout";
import { NativeLabel, StyledTextInput } from "common/core/form/text";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { Controller } from "common/core/form";

import Styles from "./util.module.scss";

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "14px",
      color: "#040919",
      letterSpacing: "0.035em",
      "::placeholder": {
        color: "#9e9e9e",
        textAlign: "left",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "14px",
        fontFamily: "Inter, Helvetica, Arial, sans-serif",
        position: "absolute",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
const CardNumberElementOption = { ...ELEMENT_OPTIONS, placeholder: "Card number" };
const CardExpiryElementOption = { ...ELEMENT_OPTIONS, placeholder: "MM/YY" };
const CardCvcElementOption = { ...ELEMENT_OPTIONS, placeholder: "CVC" };
const FORM_MESSAGES = defineMessages({
  cardName: { id: "dc9fa614-4310-4daf-84b5-7e5bf49f390e", defaultMessage: "Cardholder name" },
  addressZip: { id: "642ba494-877a-417e-ab4b-5395ccd53ee6", defaultMessage: "Postal code" },
});
const REQUIRED_MESSAGES = defineMessages({
  cardName: {
    id: "08b3f95c-4ea6-4a5d-9073-411b3f5f82fc",
    defaultMessage: "Cardholder name required",
  },
  cardNumber: {
    id: "779f909e-df8b-476f-8369-cb8effa41832",
    defaultMessage: "Card number required",
  },
  cardExpiry: {
    id: "a393a58d-70c3-45e0-bb05-fca6fa8a0983",
    defaultMessage: "Card expiration date required",
  },
  cardCvc: {
    id: "9badbdc2-b931-4368-9d11-4280450e0fbb",
    defaultMessage: "CVC required",
  },
  addressZip: {
    id: "1356ff50-fd49-42fa-8ce6-69ad01f47c90",
    defaultMessage: "Billing zip required",
  },
});

const LABELS = defineMessages({
  cardName: {
    id: "4896296d-28cf-4773-81e5-75967ea0165e",
    defaultMessage: "Name on card",
  },
  cardNumber: {
    id: "dff38193-a90c-4c61-b6a2-3663c7f44315",
    defaultMessage: "Card number",
  },
  cardExpiryCvc: {
    id: "452d565f-b37f-4c1b-aea8-c9065f5432b2",
    defaultMessage: "Expiry date / CVC",
  },
  addressZip: {
    id: "a25887a9-f8e5-4dcb-bd27-d38682ce18bb",
    defaultMessage: "Billing zip code",
  },
});

let stripePromise;
/** @type {() => Promise<null | Stripe>} */
export function getLazyStripe() {
  if (!stripePromise) {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    stripePromise = loadStripe(Env.paymentStripeApiKey);
  }
  return stripePromise;
}

function ObfuscatedCardField({
  touch,
  onChange,
  onBlur,
  onFocus,
  options,
  obfuscatedAutomationId,
  automationId,
  StripeElement,
  mask,
  id,
  name,
  label = null,
  required = false,
  stripeElementClassName = "",
  maskElementClassName = Styles.placeholderInput,
}) {
  const [showValue, setShowValue] = useState(false);
  const cardEl = useRef(null);
  const [cardError, setCardError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused && showValue) {
      cardEl.current?.focus();
    }
  }, [showValue]);

  const nativeLabelElement = label ? (
    <NativeLabel {...{ label, displayRequiredAsterisk: required, htmlFor: id }} />
  ) : null;

  return (
    <>
      <div aria-hidden={showValue} data-automation-id={obfuscatedAutomationId}>
        {nativeLabelElement}
        <input
          disabled
          className={maskElementClassName}
          style={{ display: showValue ? "none" : "initial" }}
          value=""
          onFocus={() => {
            setShowValue(true);
            setIsFocused(true);
          }}
          {...(cardEl.current && {
            readOnly: true,
            disabled: false,
            value: mask,
          })}
        />
      </div>
      <div
        className={`${stripeElementClassName} ${!showValue ? "hide" : ""}`}
        data-automation-id={automationId}
        aria-hidden={!showValue}
      >
        <StripeElement
          id={id}
          name={name}
          onReady={(e) => {
            cardEl.current = e;
            if (e._empty) {
              setShowValue(true);
            }
          }}
          onBlur={(e) => {
            if (typeof touch === "function") {
              touch(name);
            }
            if (!cardError && !cardEl.current._empty) {
              setShowValue(false);
            }
            if (typeof onBlur === "function") {
              onBlur(e);
            }
            setIsFocused(false);
          }}
          onFocus={(e) => {
            setShowValue(true);
            setIsFocused(true);
            if (typeof onFocus === "function") {
              onFocus(e);
            }
          }}
          onChange={(e) => {
            onChange(e);
            if ((!e.complete || e.error) && !cardError) {
              setCardError(true);
            }
            if (e.complete && !e.error && cardError) {
              setCardError(false);
            }
          }}
          options={options}
          {...(!showValue && { tabIndex: -1 })}
        />
      </div>
    </>
  );
}

function ControlledStripeWrapper({
  name,
  dataAutomationId,
  className,
  StripeComponent,
  stripeProps,
  form,
}) {
  const { control, trigger } = form;
  const [isFocused, setIsFocused] = useState(false);

  // Otherwise there is an error about modifying stripe props once set
  const clonedStripeProps = { ...stripeProps };
  const { "aria-invalid": ariaInvalid } = clonedStripeProps;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur } }) => (
        <div
          className={classnames(
            isFocused && Styles.stripeElementFocus,
            Styles.stripeElement,
            className,
            ariaInvalid === "true" && Styles.stripeElementError,
          )}
          data-automation-id={dataAutomationId}
        >
          <StripeComponent
            {...stripeProps}
            onChange={(e) => {
              if (typeof stripeProps.onChange === "function") {
                stripeProps.onChange(e);
              }
              onChange({ target: { value: e.empty ? "" : "not empty" } });
              trigger(name);
            }}
            onBlur={(e) => {
              if (typeof stripeProps.onBlur === "function") {
                stripeProps.onBlur();
              }
              setIsFocused(false);
              onBlur(e);
            }}
            onFocus={() => {
              if (typeof stripeProps.onFocus === "function") {
                stripeProps.onFocus();
              }
              setIsFocused(true);
            }}
          />
        </div>
      )}
    />
  );
}

/** @type {<T>(props: { form: import("react-hook-form").UseFormReturn<T>; changeCardElement?: (event: any) => void; showLabels?: boolean, showZip?: boolean })  => import("react").ReactElement} */
export function StripeCardElementsUIForReactHookForm({
  changeCardElement,
  form,
  showLabels,
  showZip,
}) {
  const intl = useIntl();
  const { formState } = form;
  const { errors } = formState;
  const optionStyles = {
    ...ELEMENT_OPTIONS.style,
    base: {
      ...ELEMENT_OPTIONS.style.base,
      fontSize: "16px",
      "::placeholder": {
        ...ELEMENT_OPTIONS.style.base["::placeholder"],
        color: "#727679",
        fontSize: "14px",
      },
    },
  };
  return (
    <fieldset>
      <CoreFormRow>
        <StyledTextInput
          id="card-name"
          placeholder={intl.formatMessage(FORM_MESSAGES.cardName)}
          autoComplete="cc-name"
          data-automation-id="card-name-field"
          placeholderAsLabel={!showLabels}
          aria-invalid={isAriaInvalid(errors.cardName)}
          label={showLabels ? intl.formatMessage(LABELS.cardName) : undefined}
          displayRequiredAsterisk={showLabels}
          {...form.register("cardName", {
            required: intl.formatMessage(REQUIRED_MESSAGES.cardName),
          })}
        />
        <FormattedFieldError inputName="cardName" error={errors?.cardName} />
      </CoreFormRow>
      <CoreFormRow>
        {showLabels && (
          <NativeLabel
            htmlFor="card-number"
            label={intl.formatMessage(LABELS.cardNumber)}
            displayRequiredAsterisk
            ariaInvalid={isAriaInvalid(errors.cardNumber)}
          />
        )}
        <ControlledStripeWrapper
          form={form}
          StripeComponent={ObfuscatedCardField}
          stripeProps={{
            id: "card-number",
            name: "cardNumber",
            onChange: changeCardElement,
            options: {
              ...CardNumberElementOption,
              style: optionStyles,
            },
            obfuscatedAutomationId: "card-number-obfuscated-field",
            automationId: "card-number-field",
            StripeElement: CardNumberElement,
            mask: "**** **** **** ****",
            "aria-invalid": isAriaInvalid(errors.cardNumber),
          }}
          {...form.register("cardNumber", {
            required: intl.formatMessage(REQUIRED_MESSAGES.cardNumber),
          })}
        />
        <FormattedFieldError inputName={"cardNumber"} error={errors.cardNumber} />
      </CoreFormRow>
      {showLabels && (
        <NativeLabel
          htmlFor="card-expiry"
          label={intl.formatMessage(LABELS.cardExpiryCvc)}
          displayRequiredAsterisk
          ariaInvalid={isAriaInvalid(errors.cardExpiry)}
        />
      )}
      <div className={Styles.expiryCvcRow}>
        <ControlledStripeWrapper
          form={form}
          StripeComponent={ObfuscatedCardField}
          className={classnames(Styles.expiryWrapper, Styles.stripeElementExpiry)}
          stripeProps={{
            id: "card-expiry",
            name: "cardExpiry",
            onChange: changeCardElement,
            options: {
              ...CardExpiryElementOption,
              style: optionStyles,
            },
            obfuscatedAutomationId: "card-expiry-obfuscated-field",
            automationId: "card-expiry-field",
            StripeElement: CardExpiryElement,
            mask: "**/**",
            "aria-invalid": isAriaInvalid(errors.cardExpiry),
          }}
          {...form.register("cardExpiry", {
            required: intl.formatMessage(REQUIRED_MESSAGES.cardExpiry),
          })}
        />
        <ControlledStripeWrapper
          form={form}
          className={Styles.stripeElementCvc}
          dataAutomationId="card-cvc-field"
          StripeComponent={CardCvcElement}
          stripeProps={{
            id: "card-cvc",
            name: "cardCvc",
            onChange: changeCardElement,
            options: {
              ...CardCvcElementOption,
              style: optionStyles,
            },
            "aria-invalid": isAriaInvalid(errors.cardCvc),
          }}
          {...form.register("cardCvc", {
            required: intl.formatMessage(REQUIRED_MESSAGES.cardCvc),
          })}
        />
      </div>

      {errors.cardExpiry && (
        <div>
          <FormattedFieldError inputName={"cardExpiry"} error={errors.cardExpiry} />
        </div>
      )}
      {errors.cardCvc && (
        <div>
          <FormattedFieldError inputName={"cardCvc"} error={errors.cardCvc} />
        </div>
      )}

      {showZip && (
        <CoreFormRow className={Styles.addressZip}>
          <StyledTextInput
            placeholder={intl.formatMessage(FORM_MESSAGES.addressZip)}
            autoComplete="postal-code"
            placeholderAsLabel={!showLabels}
            label={showLabels ? intl.formatMessage(LABELS.addressZip) : undefined}
            aria-invalid={isAriaInvalid(errors.addressZip)}
            displayRequiredAsterisk={showLabels}
            {...form.register("addressZip", {
              required: intl.formatMessage(REQUIRED_MESSAGES.addressZip),
            })}
          />
          <FormattedFieldError inputName="addressZip" error={errors?.addressZip} />
        </CoreFormRow>
      )}
    </fieldset>
  );
}

export function getStripeCardElementsUI(changeCardElement, touch, optionStyles) {
  // Please use StripeCardElementsUIForReactHookForm with react-hook-form instead
  return (
    <div>
      <DeprecatedFormRow>
        <FormGroup fields={["cardName"]} disableFormRowStyle>
          <DeprecatedTextField
            id="card-name"
            name="cardName"
            label={
              <FormattedMessage
                id="dc9fa614-4310-4daf-84b5-7e5bf49f390e"
                defaultMessage="Cardholder name"
              />
            }
            autoComplete="cc-name"
            data-automation-id="card-name-field"
            displayRequiredAsterisk
            useStyledInput
            useCoreFormInput
          />

          <FormGroupErrors fields={["cardName"]} />
        </FormGroup>
      </DeprecatedFormRow>
      <DeprecatedFormRow>
        <FormGroup fields={["cardNumber"]} disableFormRowStyle>
          <ObfuscatedCardField
            touch={touch}
            onChange={changeCardElement}
            id="card-number"
            name="cardNumber"
            options={{ ...CardNumberElementOption, ...optionStyles }}
            obfuscatedAutomationId="card-number-obfuscated-field"
            automationId="card-number-field"
            StripeElement={CardNumberElement}
            mask="**** **** **** ****"
            stripeElementClassName="CustomizeStripeElement"
            maskElementClassName="placeholder-input"
            label={
              <FormattedMessage
                id="c6f8a39a-ad51-43b9-aad9-c971bfc75634"
                defaultMessage="Card number"
              />
            }
            required
          />
          <FormGroupErrors fields={["cardNumber"]} />
        </FormGroup>
      </DeprecatedFormRow>
      <DeprecatedFormRow>
        <FormGroup
          className={Styles.expiryCvcRow}
          fields={["cardExpiry", "cardCvc"]}
          disableFormRowStyle
        >
          <div className={classnames(Styles.stripeElementExpiry, Styles.expirySpacing)}>
            <ObfuscatedCardField
              touch={touch}
              onChange={changeCardElement}
              id="card-expiry"
              name="cardExpiry"
              options={CardExpiryElementOption}
              obfuscatedAutomationId="card-expiry-obfuscated-field"
              automationId="card-expiry-field"
              StripeElement={CardExpiryElement}
              mask="**/**"
              stripeElementClassName="CustomizeStripeElement"
              maskElementClassName="placeholder-input"
              label={
                <FormattedMessage
                  id="c56a2f5a-807a-4134-9001-b3c060a87d5b"
                  defaultMessage="Expiry"
                />
              }
              required
            />
            <FormGroupErrors fields={["cardExpiry"]} />
          </div>

          <div className={Styles.stripeElementCvc}>
            <NativeLabel
              label={
                <FormattedMessage id="b9614eae-fe9a-4b1b-94c8-c4e94eb355ae" defaultMessage="CVC" />
              }
              displayRequiredAsterisk
            />
            <div className="CustomizeStripeElement--cvc" data-automation-id="card-cvc-field">
              <CardCvcElement
                id="card-cvc"
                name="cardCvc"
                onBlur={() => {
                  touch("cardCvc");
                }}
                onChange={changeCardElement}
                options={{ ...CardCvcElementOption, ...optionStyles }}
              />
            </div>

            <FormGroupErrors fields={["cardCvc"]} />
          </div>
        </FormGroup>
      </DeprecatedFormRow>
    </div>
  );
}

export function getLabeledStripeCardElementsUI(changeCardElement, touch) {
  // Please use StripeCardElementsUIForReactHookForm with react-hook-form instead
  // with the prop showLabels: true
  return (
    <div>
      <FormGroup fields={["cardName"]}>
        <label htmlFor="card-name">Name on card</label>
        <DeprecatedTextField
          id="card-name"
          name="cardName"
          data-automation-id="card-name-field"
          placeholder={
            <FormattedMessage
              id="5d8fee3d-dadf-47c0-b92b-391f81aa9311"
              defaultMessage="Cardholder's name"
            />
          }
          useStyledInput
          autoComplete="off"
        />

        <FormGroupErrors fields={["cardName"]} />
      </FormGroup>

      <FormGroup fields={["cardNumber"]}>
        <label htmlFor="card-number">Card number</label>
        <div className="CustomizeStripeElement" data-automation-id="card-number-field">
          <CardNumberElement
            id="card-number"
            name="cardNumber"
            onBlur={() => {
              touch("cardNumber");
            }}
            onChange={changeCardElement}
            options={CardNumberElementOption}
          />
        </div>

        <FormGroupErrors fields={["cardNumber"]} />
      </FormGroup>

      <FormGroup fields={["cardExpiry", "cardCvc"]}>
        <label htmlFor="card-expiry">Expiry date / CVC</label>

        <DeprecatedMultipartRow>
          <div className="CustomizeStripeElement--expiry" data-automation-id="card-expiry-field">
            <CardExpiryElement
              id="card-expiry"
              name="cardExpiry"
              onBlur={() => {
                touch("cardExpiry");
              }}
              onChange={changeCardElement}
              options={CardExpiryElementOption}
            />
          </div>
          <div className="CustomizeStripeElement--cvc" data-automation-id="card-cvc-field">
            <CardCvcElement
              id="card-cvc"
              name="cardCvc"
              onBlur={() => {
                touch("cardCvc");
              }}
              onChange={changeCardElement}
              options={CardCvcElementOption}
            />
          </div>
        </DeprecatedMultipartRow>

        <FormGroupErrors fields={["cardExpiry", "cardCvc"]} />
      </FormGroup>
    </div>
  );
}
