import { useEffect, useRef, type ReactElement, type ReactNode } from "react";

import { segmentTrack } from "util/segment";
import type { Address } from "common/core/form/address";
import { useFeatureFlag } from "common/feature_gating";
import "./google_lookup_wrapper.scss";
import { SKIP_GOOGLE_AUTOCOMPLETE } from "constants/feature_gates";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare class AutoComplete {
  constructor(ref: HTMLInputElement, options: Record<string, unknown>);
  setFields(fields: (keyof Place)[]): void;
  addListener(eventName: string, callback: () => void): () => void;
  getPlace(): Place | null;
}
type GooglePlaceAPI = {
  maps: {
    event: {
      clearInstanceListeners: (element: HTMLInputElement | undefined) => void;
      removeListener: (listener: () => void) => void;
    };
    places: {
      Autocomplete: typeof AutoComplete;
    };
  };
};
type Place = {
  formatted_address: string;
  address_components?: { types: string[]; long_name: string; short_name: string }[];
};
declare const window: Window & { google?: GooglePlaceAPI };

export type GoogleLookupOptions = {
  onAddressSelect: (address: Address) => void;
  allowChangeInput: boolean;
};
type GoogleLookupInit = (
  ref: HTMLInputElement,
  autocompleteOptions?: Record<string, unknown>,
) => void;
type Props = GoogleLookupOptions & {
  children: (init: GoogleLookupInit) => Exclude<ReactNode, undefined | false>;
};

// Address component documentation here:
// https://developers.google.com/maps/documentation/geocoding/start#Types
function findAddressComponentValue(
  type: string,
  addressComponents: NonNullable<Place["address_components"]>,
  prop: "short_name" | "long_name" = "short_name",
): string {
  return addressComponents.find((component) => component.types.includes(type))?.[prop] || "";
}

function convertGoogleAddress(
  addressComponents: NonNullable<Place["address_components"]>,
): Address {
  const streetNumber = findAddressComponentValue("street_number", addressComponents);
  const streetName = findAddressComponentValue("route", addressComponents, "long_name");
  const line1 = streetNumber ? [streetNumber, streetName].join(" ") : streetName;
  return {
    line1,
    line2: findAddressComponentValue("subpremise", addressComponents) || undefined,
    city: findAddressComponentValue("locality", addressComponents),
    state: findAddressComponentValue("administrative_area_level_1", addressComponents),
    country: findAddressComponentValue("country", addressComponents),
    postal: findAddressComponentValue("postal_code", addressComponents),
  };
}

export function useGoogleLookup(lookupOptions: GoogleLookupOptions): GoogleLookupInit {
  const inputRef = useRef<HTMLInputElement>();
  const lastLine1Ref = useRef("");
  const autocompleteListenerRef = useRef<() => void>();
  const skipGoogleAutocomplete = useFeatureFlag(SKIP_GOOGLE_AUTOCOMPLETE);
  useEffect(
    () => () => {
      const googleEvent = window.google?.maps.event;
      const { current: listener } = autocompleteListenerRef;
      if (googleEvent && listener) {
        // https://stackoverflow.com/questions/33049322/no-way-to-remove-google-places-autocomplete
        googleEvent.removeListener(listener);
        googleEvent.clearInstanceListeners(inputRef.current);
        for (const googleElement of window.document.querySelectorAll(".pac-container")) {
          googleElement.remove();
        }
      }
    },
    [],
  );

  return function initGoogleLookup(ref, options) {
    const places = window.google?.maps.places;
    if (skipGoogleAutocomplete || !places || inputRef.current === ref) {
      return;
    }

    inputRef.current = ref;
    const autocomplete = new places.Autocomplete(ref, { types: ["address"], ...options });
    autocomplete.setFields(["address_components", "formatted_address"]);
    autocompleteListenerRef.current = autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      // This is called when someone presses enter on the input without selecting an address so ignore it if that happens
      if (!place) {
        return;
      }
      const { address_components: addressComponents, formatted_address: formattedAddress } = place;
      if (!addressComponents) {
        return;
      }
      const address = convertGoogleAddress(addressComponents);
      lastLine1Ref.current = address.line1; // We save the line1 to use and override Google's attempt to set the ref
      segmentTrack("User Autocompleted Address", {
        charactersTyped: ref.value.length,
        addressLength: formattedAddress.length,
      });
      lookupOptions.onAddressSelect(address);
    });

    ref.addEventListener("keydown", (event) => {
      // There is an issue where the enter to select an element in the list is being propagated into the form
      // and the form saves while populating the address. This is causes problems because it looks like the address is
      // persisted. This is a bit of a hack, but if there is an item selected, then we know we don't want to propagate
      // the enter key press to the form to submit it
      // https://developers.google.com/maps/documentation/javascript/places-autocomplete#style_autocomplete
      if (event.key === "Enter" && document.getElementsByClassName("pac-item-selected").length) {
        event.preventDefault();
      }
    });

    if (!lookupOptions.allowChangeInput) {
      // The following code is used so that google doesn't try to update the value of the input when we click on an entry
      // in the autocomplete menu or when we arrow down into the autocomplete menu.
      // Google sets the value directly, so we stop that here.
      // Original code is here:
      // https://stackoverflow.com/questions/42427606/event-when-input-value-is-changed-by-javascript/48528450#48528450
      // However, we only want to scope it to this particular input.
      const descriptor = Object.getOwnPropertyDescriptor(ref, "value")!;
      const originalSet = descriptor.set!;
      descriptor.set = (...args) => {
        // More inspiration for this code:
        // https://stackoverflow.com/questions/25692666/how-to-stop-address-from-populating-autocomplete-with-google-places
        if (ref.getAttribute("value") === args[0]) {
          originalSet.apply(ref, args);
        } else if (lastLine1Ref.current) {
          originalSet.call(ref, lastLine1Ref.current); // The apply takes in an object/array
          lastLine1Ref.current = "";
        }
      };
      Object.defineProperty(ref, "value", descriptor);
    }
  };
}

function GoogleLookupWrapper(props: Props) {
  return props.children(useGoogleLookup(props)) as ReactElement;
}

export default GoogleLookupWrapper;
