import { defineMessages, useIntl, FormattedMessage } from "react-intl";

import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import Button from "common/core/button";
import { Card, CardSection } from "common/core/card";
import { usePermissions } from "common/core/current_user_role";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { useForm } from "common/core/form";
import { Container } from "common/core/responsive";
import { pushNotification } from "common/core/notification_center/actions";
import { SettingsTitle } from "common/settingsv2/common";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";

import UpdateOrganizationPayerMutation from "./update_organization_payer_mutation.graphql";
import type { AdminCompanyDetailsOrganization_organization_Organization as Organization } from "../organization_query.graphql";

type FormValues = {
  managesChildBilling: "no" | "yes";
};

const messages = defineMessages({
  billingTitle: {
    id: "083ee987-df14-44f7-94cb-b2d24008c498",
    defaultMessage: "How should this company be billed, and at what pricing plans?",
  },
  organizationLevel: {
    id: "2b1af411-145c-4f06-a292-b0354d247690",
    defaultMessage: "Individual invoices and pricing plans for child organizations (default)",
  },
  organizationLevelSubtext: {
    id: "8667f490-abcc-4325-929a-8177a5b8ed9c",
    defaultMessage:
      "Sends individual invoices to the billing contact at each child organization for their charge items. Each child organization will have their own pricing plan.",
  },
  companyLevel: {
    id: "85977039-486b-4deb-865f-028eb91b4d3e",
    defaultMessage: "Single invoice and pricing plan for company",
  },
  companyLevelSubtext: {
    id: "90fa7732-c0b8-4188-b6d2-a01052c41001",
    defaultMessage:
      "Sends this (root) organization's billing contact an invoice for all child organizations' charge items. Child organizations will also use the root organization's pricing plan.",
  },
  saveChanges: {
    id: "53e707a8-599b-45b1-b6fe-e21eb4cfb1bd",
    defaultMessage: "Save changes",
  },
  saveSuccess: {
    id: "2fe54d6c-d891-4e9f-a09e-7925eb9a2c6f",
    defaultMessage: "Billing setup saved successfully.",
  },
  saveFailure: {
    id: "ecf3895e-d478-42c2-bf4d-c1e7360d8c8b",
    defaultMessage: "Error saving billing setup.",
  },
});

export function AdminCompanyBillingAndPricing(props: { organization: Organization }) {
  const {
    organization: { id, managesChildBilling },
  } = props;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const formDisabled = !hasPermissionFor("editCompanyDetailsBilling");

  const updateOrganizationPayerMutateFn = useMutation(UpdateOrganizationPayerMutation);
  const form = useForm<FormValues>({
    defaultValues: {
      managesChildBilling: managesChildBilling ? "yes" : "no",
    },
  });
  const { formState } = form;
  const handleSubmit = form.handleSubmit((data: FormValues) => {
    const { managesChildBilling } = data;

    return updateOrganizationPayerMutateFn({
      variables: {
        input: {
          id,
          managesChildBilling: managesChildBilling === "yes",
        },
      },
    })
      .then(() => {
        pushNotification({
          message: intl.formatMessage(messages.saveSuccess),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        });
      })
      .catch((err) => {
        captureException(err);
        pushNotification({
          message: intl.formatMessage(messages.saveFailure),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      });
  });

  return (
    <Container fluid style={{ width: "100%" }}>
      <SettingsTitle>
        <FormattedMessage
          id="c798f29d-7631-4218-b94f-1601d7316802"
          defaultMessage="Company billing and pricing"
        />
      </SettingsTitle>
      <Card
        footer={
          <Button
            type="submit"
            automationId="company-billing-form-submit"
            isLoading={formState.isSubmitting}
            disabled={formDisabled}
            buttonColor="action"
            variant="primary"
            onClick={handleSubmit}
          >
            {intl.formatMessage(messages.saveChanges)}
          </Button>
        }
      >
        <CardSection>
          <RadioGroup label={intl.formatMessage(messages.billingTitle)}>
            <RadioLabel
              label={intl.formatMessage(messages.organizationLevel)}
              subLabel={intl.formatMessage(messages.organizationLevelSubtext)}
              radio={
                <RadioInput<FormValues["managesChildBilling"]>
                  value={"no"}
                  data-automation-id="manages-child-billing-false"
                  {...form.register("managesChildBilling")}
                />
              }
            />
            <RadioLabel
              label={intl.formatMessage(messages.companyLevel)}
              subLabel={intl.formatMessage(messages.companyLevelSubtext)}
              radio={
                <RadioInput<FormValues["managesChildBilling"]>
                  value={"yes"}
                  data-automation-id="manages-child-billing-true"
                  {...form.register("managesChildBilling")}
                />
              }
            />
          </RadioGroup>
        </CardSection>
      </Card>
    </Container>
  );
}
