import { FormattedMessage, useIntl } from "react-intl";

import {
  MeetingEndedState,
  CompletionRequirement,
  ParticipantTypes,
  OrgTransactionStates,
  DocumentBundleParticipantStatus,
  type Feature,
} from "graphql_globals";
import { BUNDLE_PROCESSING_STATES } from "constants/document_bundle";
import { usePermissions } from "common/core/current_user_role";
import { dateComparator } from "util/date";
import { AVAILABLE_FEATURES } from "constants/organization";
import { Heading, Substyle } from "common/core/typography";
import { Badge } from "common/core/badge";
import { userFullName } from "util/user";
import Link from "common/core/link";
import APPS from "constants/applications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { NO_NAME_MESSAGE } from "common/signer/utils";
import { useShowIdentityInfo } from "util/feature_detection";
import { Card, CardSection } from "common/core/card";
import { DescriptionList, DescriptionListItem } from "common/core/description_list";
import { Hr } from "common/core/horizontal_rule";
import type { TransactionDetailsOrganization } from "common/details/identity";

import CustomerSignerDetails from "./customer_signer_details";
import SignerDetails, { signerAndMeetingsInfo } from "./signer_details";
import type {
  DocumentBundleForTransactionDetailsSigner,
  DocumentBundleForTransactionDetailsSigner_transaction_customerSigners as CustomerSigner,
  DocumentBundleForTransactionDetailsSigner_participants as Participant,
  DocumentBundleForTransactionDetailsSigner_signers as BundleSigner,
} from "./index_fragment.graphql";
import Styles from "./index.module.scss";
import type { DocumentBundleForSignerDetails_meetings_edges_node as Meeting } from "./signer_details/index_fragment.graphql";

type SignerProps = {
  bundle: DocumentBundleForTransactionDetailsSigner;
  meetingIds?: string[];
  organization?: TransactionDetailsOrganization | null;
  refetch: () => Promise<unknown>;
};

type CorrespondingCustomerSigner = Pick<
  CustomerSigner,
  | "id"
  | "transactionAccessLink"
  | "phone"
  | "firstName"
  | "middleName"
  | "lastName"
  | "alternativeNames"
  | "proofRequirement"
  | "order"
  | "signingRequirement"
  | "recipientGroup"
>;

type SignerIdentityListItem = {
  id: string;
  participantType: ParticipantTypes | null;
  user: { id: string };
};

type SignerListItem = {
  user?: BundleSigner;
  customerSigner?: CorrespondingCustomerSigner;
  signerIdentity?: SignerIdentityListItem;
  documentBundleParticipant?: Participant | null;
  signingStatus: DocumentBundleParticipantStatus;
};

type UserPreview = { name: string; id: string };

function signerDetailKey(signer: SignerListItem) {
  return signer.user?.id || signer.documentBundleParticipant?.id || signer.customerSigner?.id;
}

function SignerStatusPill({
  status = DocumentBundleParticipantStatus.INCOMPLETE,
  canStartSigning = false,
  recipientOrderEnabled = false,
}: {
  status?: DocumentBundleParticipantStatus;
  canStartSigning?: boolean;
  recipientOrderEnabled?: boolean;
}) {
  if (status === DocumentBundleParticipantStatus.COMPLETE) {
    return (
      <Badge kind="success" withIcon="success">
        <FormattedMessage id="56485da0-d19d-4446-bddc-f3e067e50630" defaultMessage="Complete" />
      </Badge>
    );
  }

  if (status === DocumentBundleParticipantStatus.IN_PROGRESS) {
    return (
      <Badge kind="infoBlue" withIcon="success">
        <FormattedMessage id="8c0b61d5-a626-4832-a5b7-9eed2bcee2a0" defaultMessage="Signing now" />
      </Badge>
    );
  }

  if (canStartSigning && recipientOrderEnabled) {
    return (
      <Badge kind="infoBlue">
        <FormattedMessage id="32153beb-70b6-4139-a2ea-f7e0d4c5b2b6" defaultMessage="Signing next" />
      </Badge>
    );
  }

  return (
    <Badge kind="infoSubtle">
      <FormattedMessage id="e466e43d-ceaf-4324-b6d0-7aa8bfe0ecaf" defaultMessage="Pending" />
    </Badge>
  );
}

function SignerListHeader({
  completedCount = 0,
  totalCount,
  recipientOrderEnabled,
  usersPreview,
}: {
  completedCount?: number;
  totalCount: number;
  recipientOrderEnabled: boolean;
  usersPreview?: UserPreview[];
}) {
  const headerHeading = (
    <Heading level="h2" textStyle="headingSix">
      <FormattedMessage
        id="bc2c1241-b2ee-4a71-81ee-91dfb56cea6b"
        defaultMessage="Recipient details"
      />
    </Heading>
  );

  const recipientsList = usersPreview?.length
    ? usersPreview.map((s, i) => {
        return (
          <FormattedMessage
            key={s.id}
            id="6b63a1d3-d6b3-4357-bcf6-32ed4ba492dd"
            defaultMessage="{name} (<link>{id}</link>){last, select, true{} other{, }}"
            values={{
              ...s,
              last: i + 1 === usersPreview.length,
              link: (text) => <Link to={`/users/${s.id}`}>{text}</Link>,
            }}
          />
        );
      })
    : null;

  const signingOrder = recipientOrderEnabled ? (
    <FormattedMessage id="77f0ff9f-4633-4b34-8ca6-923ac9edddf5" defaultMessage="Enabled" />
  ) : (
    <FormattedMessage id="90492051-fe82-4d50-95df-da5262e49720" defaultMessage="Disabled" />
  );

  const signingCompletion = (
    <FormattedMessage
      id="2405f2e8-6dda-46bd-ba89-5394acfe9ba8"
      defaultMessage="{completed} of {total} completed"
      values={{ completed: completedCount, total: totalCount }}
    />
  );

  return usersPreview?.length || totalCount > 1 ? (
    <>
      <Card className={Styles.recipientHeaderCard} fullWidth noMargin header={headerHeading}>
        {totalCount > 1 && (
          <DescriptionList itemGap="large">
            <DescriptionListItem
              term={
                <FormattedMessage
                  id="b73f6081-4fdb-4220-8f06-bba4119d37b4"
                  defaultMessage="Signing order"
                />
              }
              definition={signingOrder}
            />
            <DescriptionListItem
              term={
                <FormattedMessage
                  id="285c301a-acdf-4e3d-8bb9-dccf35da77e2"
                  defaultMessage="Signing completed"
                />
              }
              definition={signingCompletion}
            />
          </DescriptionList>
        )}
        {usersPreview?.length ? (
          <CardSection>
            <DescriptionList>
              <DescriptionListItem
                term={
                  <FormattedMessage
                    id="b0841953-2816-40b8-9080-d7da735321f6"
                    defaultMessage="Recipients"
                  />
                }
                definition={recipientsList}
              />
            </DescriptionList>
          </CardSection>
        ) : null}
      </Card>
      <Hr />
    </>
  ) : null;
}

function DraftSignerList({ bundle }: { bundle: DocumentBundleForTransactionDetailsSigner }) {
  const {
    transaction: { customerSigners },
    participants,
  } = bundle;

  const sortedCustomerSigners = [...customerSigners].sort(
    (a, b) => (a.order || 0) - (b.order || 0),
  );
  const totalCount = customerSigners.length;
  return (
    <>
      <SignerListHeader
        totalCount={totalCount}
        recipientOrderEnabled={customerSigners.some((customer) => customer.order)}
      />
      {sortedCustomerSigners.map((signer) => (
        <CustomerSignerDetails
          key={signer.id}
          participant={participants?.find((p) => p?.signerRole.index === signer.signerRole.index)}
          customerSigner={signer}
          subheader={<SignerStatusPill />}
          index={signer.order}
          expandInitially={CURRENT_PORTAL === APPS.ADMIN || totalCount === 1}
        />
      ))}
    </>
  );
}

function SentSignerList({
  bundle,
  signerList,
  completionRequirements,
  organizationFeatures,
  organizationTierFeatures,
  reversedFilteredMeetings,
  showIdentityInfo,
  execRefetch,
  validatedByCredibleWitness,
  credibleWitnessPreview,
}: {
  bundle: DocumentBundleForTransactionDetailsSigner;
  signerList: SignerListItem[];
  completionRequirements: (CompletionRequirement | null)[];
  organizationFeatures: string[];
  organizationTierFeatures?: Feature[];
  reversedFilteredMeetings?: Meeting[];
  showIdentityInfo: boolean;
  execRefetch: () => void;
  validatedByCredibleWitness?: boolean;
  credibleWitnessPreview: UserPreview[];
}) {
  const intl = useIntl();
  let recipientOrderEnabled = false;
  let completedCount = 0;
  let signerCount = 0;
  const signersPreview: UserPreview[] = [];

  signerList.forEach((signer) => {
    if (!recipientOrderEnabled && signer.customerSigner?.order) {
      recipientOrderEnabled = true;
    }
    if (signer.signingStatus === DocumentBundleParticipantStatus.COMPLETE) {
      completedCount++;
    }
    signerCount++;
    if (CURRENT_PORTAL === APPS.ADMIN && signer.user) {
      const { contactInformation } = signerAndMeetingsInfo({
        signerInfo: signer.user,
        signerIdentityId: signer.signerIdentity?.id,
        meetingsInfo: reversedFilteredMeetings,
      });
      signersPreview.push({
        name:
          userFullName(contactInformation) ||
          signer.customerSigner?.recipientGroup?.sharedInboxEmail ||
          intl.formatMessage(NO_NAME_MESSAGE),
        id: signer.user.id,
      });
    }
  });

  signersPreview.push(...credibleWitnessPreview);
  const expandInitially = CURRENT_PORTAL === APPS.ADMIN || signerCount === 1;

  return (
    <>
      <SignerListHeader
        totalCount={signerCount}
        completedCount={completedCount}
        recipientOrderEnabled={recipientOrderEnabled}
        usersPreview={signersPreview}
      />
      {signerList.map((signer) => {
        if (signer.signerIdentity) {
          return (
            <SignerDetails
              key={signerDetailKey(signer)}
              completionRequirements={completionRequirements}
              organizationFeatures={organizationFeatures}
              organizationTierFeatures={organizationTierFeatures}
              bundleSignerInfo={signer.user}
              signerIdentityId={signer.signerIdentity.id}
              meetingsInfo={reversedFilteredMeetings}
              subheader={
                <SignerStatusPill
                  status={signer.signingStatus}
                  canStartSigning={signer.documentBundleParticipant?.canStartSigning}
                  recipientOrderEnabled={recipientOrderEnabled}
                />
              }
              participantType={signer.signerIdentity.participantType}
              validatedByCredibleWitness={validatedByCredibleWitness}
              transactionCustomer={signer.customerSigner}
              onUpdate={execRefetch}
              expandInitially={expandInitially}
              hideSensitiveData={CURRENT_PORTAL === APPS.ADMIN}
              showIdentityInfo={showIdentityInfo}
            />
          );
        }

        /* For signers that don't exist in the list of signer identities, we should still display them */
        return (
          <SignerDetails
            key={signerDetailKey(signer)}
            completionRequirements={completionRequirements}
            organizationFeatures={organizationFeatures}
            organizationTierFeatures={organizationTierFeatures}
            bundleSignerInfo={signer.user}
            subheader={
              <SignerStatusPill
                status={signer.signingStatus}
                canStartSigning={signer.documentBundleParticipant?.canStartSigning}
                recipientOrderEnabled={recipientOrderEnabled}
              />
            }
            transactionCustomer={signer.customerSigner}
            onUpdate={execRefetch}
            authenticationRequirement={bundle.transaction.authenticationRequirement}
            esignAuthSignerIdentities={bundle.transaction.signerIdentities}
            expandInitially={expandInitially}
            hideSensitiveData={CURRENT_PORTAL === APPS.ADMIN}
            showIdentityInfo={showIdentityInfo}
          />
        );
      })}
    </>
  );
}

function CredibleWitnessList({
  credibleWitnesses,
  completionRequirements,
  organizationFeatures,
  organizationTierFeatures,
  reversedFilteredMeetings,
  showIdentityInfo,
  execRefetch,
}: {
  credibleWitnesses: SignerIdentityListItem[];
  completionRequirements: (CompletionRequirement | null)[];
  organizationFeatures: string[];
  organizationTierFeatures?: Feature[];
  reversedFilteredMeetings?: Meeting[];
  showIdentityInfo: boolean;
  execRefetch: () => void;
}) {
  return (
    <>
      <Heading
        level="h2"
        textStyle="allCapsLabelSmall"
        textColor="subtle"
        className={Styles.recipientSubheading}
      >
        <FormattedMessage
          id="a6e911ff-1889-4a3b-a565-c736d9b0cae8"
          defaultMessage="Other participants"
        />
      </Heading>
      {credibleWitnesses.map((credibleWitness) => {
        return (
          <SignerDetails
            key={credibleWitness.id}
            completionRequirements={completionRequirements}
            organizationFeatures={organizationFeatures}
            organizationTierFeatures={organizationTierFeatures}
            bundleSignerInfo={credibleWitness.user}
            signerIdentityId={credibleWitness.id}
            meetingsInfo={reversedFilteredMeetings}
            subheader={
              <Substyle size="small" className={Styles.witnessSubheader}>
                <FormattedMessage
                  id="3c0349a1-2e36-4b07-9766-8f5cbcd934de"
                  defaultMessage="Credible Witness"
                />
              </Substyle>
            }
            participantType={credibleWitness.participantType}
            onUpdate={execRefetch}
            expandInitially={CURRENT_PORTAL === APPS.ADMIN}
            showIdentityInfo={showIdentityInfo}
          />
        );
      })}
    </>
  );
}

function rankOrder(signer: SignerListItem) {
  return (
    (signer.customerSigner?.order || 0) -
    (signer.signingStatus === DocumentBundleParticipantStatus.COMPLETE
      ? 0.5
      : signer.signingStatus === DocumentBundleParticipantStatus.IN_PROGRESS
        ? 0.2
        : 0)
  );
}

function SignerList({ bundle, meetingIds, organization, refetch }: SignerProps) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const showIdentityInfo =
    useShowIdentityInfo(organization) || hasPermissionFor("showIdentityInfo");
  const {
    publicOrganization,
    customerSigners: originalCustomerSigners,
    isMortgage,
  } = bundle.transaction;
  const organizationFeatures = publicOrganization.featureFlags
    .filter((f) => f.value === "true")
    .map((f) => f.key);
  if (isMortgage) {
    organizationFeatures.push(AVAILABLE_FEATURES.SHOW_PICTURE_IDS);
  }
  const organizationTierFeatures = publicOrganization.featureList;
  const customerSigners = originalCustomerSigners;

  // An object that maps user IDs to a transaction customer (if it exists)
  const correspondingCustomerSigners: Record<string, CustomerSigner> = {};
  bundle.participants!.forEach((participant) => {
    const correspondingCustomerSigner = customerSigners.find(
      (customerSigner) => participant!.signerRole.index === customerSigner.signerRole.index,
    );
    // There will be no correspondingCustomerSigner if the bundle is retail or something from easylink
    if (correspondingCustomerSigner) {
      correspondingCustomerSigners[participant!.userId] = {
        ...correspondingCustomerSigner,
        firstName: participant!.firstName,
        middleName: participant!.middleName,
        lastName: participant!.lastName,
      };
    }
  });

  // Always default to notarization as completion requirement
  const completionRequirements = bundle.completionRequirements || [
    CompletionRequirement.NOTARIZATION,
  ];

  /*
    This Signer component is used in multiple places, with different contexts, where the
    root nodes might be a Meeting or an OrganizationTransaction. Depending on the context,
    with want to show all meetings, some meetings, or only 1 meeting.
    We use the `meetingIds` array as a hack when the root node is a Meeting and we only want this one.
   */
  const credibleWitnesses: SignerIdentityListItem[] = [];
  const credibleWitnessPreview: UserPreview[] = [];
  const signerIdentities: Record<string, SignerIdentityListItem> = {};
  const filteredMeetings = bundle.meetings.edges
    .filter(({ node: meeting }) => {
      if (meetingIds !== undefined) {
        return meetingIds.includes(meeting.id);
      } else if (hasPermissionFor("filterTerminatedMeetings")) {
        return meeting.endedState === MeetingEndedState.COMPLETED;
      }
      return true;
    })
    .map((edge) => edge.node)
    .sort((a, b) => dateComparator(b.timeFrame!.startedAt, a.timeFrame!.startedAt));
  const reversedFilteredMeetings = filteredMeetings.reverse();
  for (const { participants } of filteredMeetings) {
    for (const participant of participants!) {
      if (participant?.participantType === ParticipantTypes.SIGNER) {
        bundle.signers!.forEach((signer) => {
          if (signer!.id === participant.user?.id) {
            signerIdentities[signer!.id] = participant as SignerIdentityListItem;
          }
        });
      }
      if (participant?.participantType === ParticipantTypes.CREDIBLE_WITNESS) {
        if (CURRENT_PORTAL === APPS.ADMIN && participant.user) {
          const { contactInformation } = signerAndMeetingsInfo({
            signerInfo: participant.user,
            signerIdentityId: participant.id,
            meetingsInfo: reversedFilteredMeetings,
          });
          credibleWitnessPreview.push({
            id: participant.user.id,
            name: userFullName(contactInformation) || intl.formatMessage(NO_NAME_MESSAGE),
          });
        }
        credibleWitnesses.push(participant as SignerIdentityListItem);
      }
    }
  }

  // use this as the status if the participant status is not available
  const bundleBackupStatus =
    bundle.processingState === BUNDLE_PROCESSING_STATES.COMPLETED ||
    bundle.processingState === BUNDLE_PROCESSING_STATES.COMPLETED_WITH_REJECTIONS
      ? DocumentBundleParticipantStatus.COMPLETE
      : DocumentBundleParticipantStatus.INCOMPLETE;
  // create list with all signers
  const signers: SignerListItem[] =
    bundle.signers?.map((signer) => {
      const documentBundleParticipant = bundle.participants?.find((p) => p!.userId === signer!.id);
      return {
        user: signer!,
        customerSigner: correspondingCustomerSigners[signer!.id],
        documentBundleParticipant,
        signerIdentity: signerIdentities[signer!.id],
        signingStatus: documentBundleParticipant?.signingStatus || bundleBackupStatus,
      };
    }) || [];

  const hasCredibleWitnesses = credibleWitnesses.length > 0;

  // sort signers according to their order, if it exists; also rank completed signers higher
  signers.sort((a, b) => {
    return rankOrder(a) - rankOrder(b);
  });

  return (
    <>
      <SentSignerList
        bundle={bundle}
        completionRequirements={completionRequirements}
        credibleWitnessPreview={credibleWitnessPreview}
        execRefetch={() => refetch()}
        organizationFeatures={organizationFeatures}
        organizationTierFeatures={organizationTierFeatures}
        reversedFilteredMeetings={reversedFilteredMeetings}
        showIdentityInfo={showIdentityInfo}
        signerList={signers}
        validatedByCredibleWitness={hasCredibleWitnesses}
      />
      {hasCredibleWitnesses && (
        <CredibleWitnessList
          completionRequirements={completionRequirements}
          credibleWitnesses={credibleWitnesses}
          execRefetch={() => refetch()}
          organizationFeatures={organizationFeatures}
          organizationTierFeatures={organizationTierFeatures}
          reversedFilteredMeetings={reversedFilteredMeetings}
          showIdentityInfo={showIdentityInfo}
        />
      )}
    </>
  );
}

function Signer({ bundle, meetingIds, organization, refetch }: SignerProps) {
  const { state } = bundle.transaction;
  if (
    state === OrgTransactionStates.STARTED ||
    state === OrgTransactionStates.SENT_TO_CLOSING_OPS ||
    state === OrgTransactionStates.SENT_TO_TITLE_AGENCY
  ) {
    return <DraftSignerList bundle={bundle} />;
  }

  return (
    <SignerList
      bundle={bundle}
      meetingIds={meetingIds}
      organization={organization}
      refetch={refetch}
    />
  );
}

export default Signer;
