import { FormattedMessage } from "react-intl";

import { Column, Row, Container } from "common/core/responsive";
import { SenderInfo } from "common/settingsv2/sidebar_settings/brand/settings/sender_info";

import type { AdminCompanyDetailsCommunicationSettings_node_Organization as Organization } from "../index.query.graphql";
import CommonStyles from "../index.module.scss";

function CommunicationSettingsGlobalSettings({ organization }: { organization: Organization }) {
  const organizationBrand = organization.organizationBrand!;

  return (
    <>
      <Container fluid style={{ width: "100%" }}>
        <Row>
          <Column lg={12} xxl={8}>
            <div className={CommonStyles.heading}>
              <FormattedMessage
                id="0397d8f3-21e4-4fe7-9716-ab6e1656743d"
                defaultMessage="Global Settings"
              />
            </div>
            <SenderInfo
              organizationBrand={organizationBrand}
              organizationName={organization.name!}
            />
          </Column>
        </Row>
      </Container>
    </>
  );
}

export default CommunicationSettingsGlobalSettings;
