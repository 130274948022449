import { CardHeading } from "common/core/card";
import Link from "common/core/link";
import Button from "common/core/button";

export default function EligibilitiesSetup(props) {
  return (
    <>
      <CardHeading level="h3">{props.header}</CardHeading>
      <Link to={props.link}>
        <Button
          variant="tertiary"
          buttonColor="action"
          fullwidth
          automationId="setup-lender-eligibilities-btn"
        >
          {props.text}
        </Button>
      </Link>
    </>
  );
}
