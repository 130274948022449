import { memo, useEffect, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import classnames from "classnames";
import { combineLatest, from, debounceTime, map, skip, startWith, switchMap } from "rxjs";
import { useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import { SearchField } from "common/core/search_field";
import { useSubject } from "util/rxjs/hooks";
import { ActivityLogSortOptions } from "graphql_globals";
import SortableTableHeader from "common/core/sortable_table/header";
import SortableTableHeaderItem from "common/core/sortable_table/header/item";
import { HEADER_SORT_CONSTRAINTS } from "common/core/sortable_table/header/constants";
import SortableTableBody from "common/core/sortable_table/body";
import LoadingIndicator from "common/core/loading_indicator";
import SortableTableBodyItem from "common/core/sortable_table/body/item";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading } from "common/core/typography";

import ActivityLogItem from "./item";
import ActivityLogsQuery, {
  type ActivityLogs as ActivityLogsType,
  type ActivityLogsVariables,
} from "./activity_logs_query.graphql";
import type { ActivityLog } from "./activity_log_fragment.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  searchPlaceholder: {
    id: "593fec85-edba-4b07-a208-5b3bb2c5357e",
    defaultMessage: "Search",
  },
  searchLabel: {
    id: "0504b099-35c8-4068-88d5-1db9d85124d8",
    defaultMessage: "Search transaction activity logs",
  },
});

type ActivityLogsSortableTableProps = {
  activityLogs: ActivityLog[];
};

const ActivityLogsSortableTable = memo(({ activityLogs }: ActivityLogsSortableTableProps) => {
  const bodyCx = classnames(Styles.sortableTable, activityLogs.length === 0 && Styles.noItems);

  return (
    <SortableTableBody className={bodyCx}>
      {activityLogs.length === 0 ? (
        <Heading level="h2" textStyle="subtitle">
          <FormattedMessage
            id="f050ce0b-5abd-49c9-ae71-3a4edd5cb3f1"
            description="noActivityItemsFound"
            defaultMessage="No activity items found"
          />
        </Heading>
      ) : (
        activityLogs.map((activityLog) => {
          return (
            <SortableTableBodyItem key={activityLog.id}>
              <ActivityLogItem activityLog={activityLog} />
            </SortableTableBodyItem>
          );
        })
      )}
    </SortableTableBody>
  );
});

type ActivityLogsLoadingSortableTableProps = {
  data: ActivityLogsType | undefined;
  loading: boolean;
};

const ActivityLogsLoadingSortableTable = memo(
  ({ data, loading }: ActivityLogsLoadingSortableTableProps) => {
    if (loading) {
      return (
        <SortableTableBody className={Styles.sortableTable}>
          <LoadingIndicator />
        </SortableTableBody>
      );
    } else if (data?.transaction?.__typename !== "OrganizationTransaction") {
      throw new Error(`Expected OrganizationTransaction, got ${data?.transaction?.__typename}`);
    } else {
      return <ActivityLogsSortableTable activityLogs={data.transaction.activityLogs} />;
    }
  },
);

export function ActivityLogs() {
  const transactionID = useParams().transactionID!;

  const intl = useIntl();
  const { data, loading, refetch } = useQuery<ActivityLogsType, ActivityLogsVariables>(
    ActivityLogsQuery,
    { variables: { transactionID } },
  );
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState(ActivityLogSortOptions.DATE_DESC);
  const search$ = useSubject<string>();
  const sort$ = useSubject<ActivityLogSortOptions>();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().transactionDetailsActivityLog),
  });

  useEffect(() => {
    const initSearch$ = search$.pipe(
      startWith(null),
      map((v) => (v === "" ? null : v)),
    );
    const initSort$ = sort$.pipe(startWith(ActivityLogSortOptions.DATE_DESC));
    const subscription$ = combineLatest([initSearch$, initSort$])
      .pipe(
        skip(1),
        debounceTime(500),
        switchMap(([query, orderBy]) => from(refetch({ transactionID, query, orderBy }))),
      )
      .subscribe();
    return () => subscription$.unsubscribe();
  }, []);

  return (
    <div data-automation-id="activity-log" className={Styles.activityLog}>
      <SearchField
        className={Styles.search}
        placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
        value={search}
        onChange={({ value }) => {
          setSearch(value);
          search$.next(value);
        }}
      />
      <SortableTableHeader>
        <SortableTableHeaderItem
          label={
            <FormattedMessage
              id="1c3a7786-2846-44f5-8aba-12722a930f43"
              defaultMessage="Change Type"
            />
          }
          width={2}
          isDense
        />
        <SortableTableHeaderItem
          label={
            <FormattedMessage id="9652373c-cf81-46f7-9c42-18c8591cf237" defaultMessage="Summary" />
          }
          width={2}
          isDense
        />
        <SortableTableHeaderItem
          label={
            <FormattedMessage id="c5b36f6f-e22e-40fb-9701-b80e8ed3b9dc" defaultMessage="User" />
          }
          width={2}
          isDense
        />
        <SortableTableHeaderItem
          label={
            <FormattedMessage id="ee0056f6-e398-4167-8b83-ef25b43efdee" defaultMessage="Date" />
          }
          width={2}
          sortConstraint={
            sort === ActivityLogSortOptions.DATE_DESC
              ? HEADER_SORT_CONSTRAINTS.SORTED_DESC
              : HEADER_SORT_CONSTRAINTS.SORTED_ASC
          }
          onSortChange={() => {
            const newSort =
              sort === ActivityLogSortOptions.DATE_DESC
                ? ActivityLogSortOptions.DATE_ASC
                : ActivityLogSortOptions.DATE_DESC;
            setSort(newSort);
            sort$.next(newSort);
          }}
          dataAutomationId="activity-logs-sort-date"
          isDense
        />
        <SortableTableHeaderItem
          label={
            <FormattedMessage id="f25685b9-7555-4f94-b5e5-656d9222682e" defaultMessage="Detail" />
          }
          width={4}
          isDense
        />
      </SortableTableHeader>
      <div>
        <ActivityLogsLoadingSortableTable data={data} loading={loading} />
      </div>
    </div>
  );
}
