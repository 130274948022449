import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { type ReactNode, useEffect, useState } from "react";
import classnames from "classnames";

import { Paragraph, Heading } from "common/core/typography";
import Button from "common/core/button";
import { requestVerificationCode } from "common/account/login/util";
import { FormattedFieldError, isAriaInvalid, defaultRequiredMessage } from "common/core/form/error";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import { useDocumentTitles } from "util/document_title";
import { useA11y } from "common/accessibility";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import { LoginHeader, BackButtonOnlyFooter } from "./common";
import ProofCard from "../card";
import Styles from "./index.module.scss";
import type { CreatePasswordScreenType } from "./create_password";

type FormValues = {
  code: string;
};

type NextScreen = CreatePasswordScreenType;

export type VerifyEmailCodeScreenType = {
  type: "verify_email_code";
  email: string;
  isInvalidCode?: boolean;
};

const SEND_STATE = {
  SENDING: "SENDING",
  SENT: "SENT",
  FAILED: "FAILED",
  INITIAL: "INITIAL",
};

const MESSAGES = defineMessages({
  codeLabel: {
    id: "5d1710f7-1314-4b50-b901-92eee60ca2fa",
    defaultMessage: "Enter 6-digit verification code",
  },
  invalidError: {
    id: "ce0450b0-c8c6-4261-ad80-6d3ef8d8de95",
    defaultMessage: "Your code was invalid, we sent you a new one. Please try again.",
  },
  lengthError: {
    id: "4472f3a6-f1fa-4f00-bed2-3e873f685f54",
    defaultMessage: "Code is required and must be 6 digits",
  },
});

type Props = {
  onBack: () => void;
  showCard?: boolean;
  email: string;
  isInvalidCode?: boolean;
  callback?: () => void;
  wrapper?: (children: ReactNode) => ReactNode;
  onNextScreen: (screen: NextScreen) => void;
};

function VerifyEmailContent({ email, callback, onNextScreen, showCard, isInvalidCode }: Props) {
  const intl = useIntl();
  const { useDocumentEntitler } = useA11y();
  const [sendEmailState, setSendEmailState] = useState(SEND_STATE.INITIAL);

  useDocumentEntitler({
    priority: "page",
    title: intl.formatMessage(useDocumentTitles().verificationCode),
  });

  const handleSendVerifyEmail = async () => {
    await requestVerificationCode(email);
    callback?.();
  };
  const sendEmail = async () => {
    setSendEmailState(SEND_STATE.SENDING);

    try {
      await handleSendVerifyEmail();
      setSendEmailState(SEND_STATE.SENT);
    } catch {
      setSendEmailState(SEND_STATE.FAILED);
    }
  };

  useEffect(() => {
    sendEmail();
  }, []);

  const { handleSubmit, register, formState, setError } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      code: "",
    },
  });
  const { errors, isSubmitted, isSubmitting } = formState;
  const invalid = isSubmitted && !!errors.code;

  const onFormSubmit = ({ code }: FormValues) => {
    segmentTrack(SEGMENT_EVENTS.PASSWORDLESS_VERIFICATION_CODE_SUBMIT);
    onNextScreen({ type: "create_password", email, verificationCode: code });
  };

  useEffect(() => {
    if (isInvalidCode) {
      segmentTrack(SEGMENT_EVENTS.PASSWORDLESS_VERIFICATION_CODE_ERROR);
      setError("code", { type: "invalid", message: intl.formatMessage(MESSAGES.invalidError) });
    } else {
      segmentTrack(SEGMENT_EVENTS.PASSWORDLESS_VERIFICATION_CODE);
    }
  }, []);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        noValidate
        data-automation-id="authentication-form"
      >
        <Heading
          {...(showCard
            ? { level: "h2", textStyle: "subtitle" }
            : { level: "h1", textStyle: "headingFour" })}
        >
          <FormattedMessage
            id="ed136913-c88d-442c-8a53-778a60afd58e"
            defaultMessage="We’ve noticed you may have used Proof before"
          />
        </Heading>
        <Paragraph className={Styles.spacingTop} {...(!showCard && { size: "large" })}>
          <FormattedMessage
            id="066648b7-810e-40fa-be99-a79ff9afe0b2"
            defaultMessage="Please check your email for the verification code to complete your account setup."
          />
        </Paragraph>
        <div className={classnames(Styles.formContainer, Styles.spacingTop)}>
          <StyledTextInput
            aria-invalid={isAriaInvalid(invalid)}
            label={intl.formatMessage(MESSAGES.codeLabel)}
            data-automation-id="verification-code-field"
            inputMode="numeric"
            maxLength={6}
            autoComplete="one-time-code"
            {...register("code", {
              required: defaultRequiredMessage(intl),
              validate: (value) => {
                return !!value.match(/\d{6}/)?.length || intl.formatMessage(MESSAGES.lengthError);
              },
            })}
          />
          {(isSubmitted || isInvalidCode) && errors.code && (
            <FormattedFieldError
              className={classnames(Styles.formError)}
              inputName="code"
              error={errors.code}
            />
          )}
        </div>
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitted && !!errors.code}
          fullwidth
          buttonColor="action"
          variant="primary"
          type="submit"
          data-automation-id="submit-button"
        >
          <FormattedMessage id="fdb82a2d-02c9-48f2-a273-564ebb7aca4a" defaultMessage="Verify" />
        </Button>
      </form>
      <Button
        className={Styles.spacingTop}
        onClick={sendEmail}
        isLoading={sendEmailState === SEND_STATE.SENDING}
        fullwidth
        buttonColor="action"
        variant="tertiary"
      >
        <FormattedMessage
          id="37c18544-3d2b-48b8-a0d0-169d7aa48995"
          defaultMessage="Send new code"
        />
      </Button>
      {sendEmailState === SEND_STATE.FAILED && (
        <Paragraph className={Styles.verifyError}>
          <FormattedMessage
            id="6689b3b0-980b-4d35-82ba-d596ca47f994"
            defaultMessage="There was an error sending the email. Please try again or contact support."
          />
        </Paragraph>
      )}
    </div>
  );
}

export default function VerifyEmailCodeScreen({
  onBack,
  email,
  callback,
  wrapper,
  onNextScreen,
  showCard,
  isInvalidCode,
}: Props) {
  const content = (
    <VerifyEmailContent
      {...{ email, callback, onBack, wrapper, onNextScreen, showCard, isInvalidCode }}
    />
  );

  if (showCard) {
    return (
      <ProofCard
        header={<LoginHeader />}
        footer={<BackButtonOnlyFooter onClick={onBack} />}
        body={content}
      />
    );
  }

  return <>{wrapper ? wrapper(content) : content} </>;
}
