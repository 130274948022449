import {
  Component,
  createRef,
  type FocusEvent,
  type KeyboardEvent,
  type ChangeEvent,
  type RefObject,
} from "react";
import { injectIntl, type IntlShape, defineMessages } from "react-intl";
import classnames from "classnames";

import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  clearButton: {
    id: "9f8dd9d5-9e53-4061-a93c-a9586f486536",
    defaultMessage: "Clear search input",
  },
});

type SearchFieldProps = {
  className?: string;
  onChange?: (input: { value: string }) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onSearch?: (value: string) => void;
  searchOnBlur?: boolean;
  searchOnClear?: boolean;
  value: string;
  placeholder?: string;
  noIcon?: boolean;
  disabled?: boolean;
  name?: string;
  "aria-describedby"?: string;
  "aria-label": string;
  size?: "default" | "large";
  autoFocus?: boolean;
  intl: IntlShape;
  includeInKeyboardNav?: boolean;
};

class SearchFieldIntl extends Component<SearchFieldProps, { value: string }> {
  inputRef: RefObject<HTMLInputElement>;
  constructor(props: SearchFieldProps) {
    super(props);
    this.state = {
      value: props.value || "",
    };
    this.inputRef = createRef();
  }

  componentDidUpdate(prevProps: SearchFieldProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value });
    }
  }

  handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      this.sendSearchQuery();
      ev.currentTarget.blur();
    }
  };

  handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    this.setState({ value });
    this.props.onChange?.({ value });
  };

  handleBlur = () => {
    if (this.props.searchOnBlur) {
      this.sendSearchQuery();
    }
  };

  clearSearch = () => {
    const value = "";
    this.setState({ value }, () => {
      if (this.props.searchOnClear) {
        this.sendSearchQuery();
      }
    });
    this.props.onChange?.({ value });
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  sendSearchQuery = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.value);
    }
  };

  render() {
    const { value } = this.state;
    const {
      noIcon,
      className,
      disabled,
      name,
      onFocus,
      "aria-describedby": ariaDescribedBy,
      "aria-label": ariaLabel,
      intl,
      size,
      autoFocus,
      includeInKeyboardNav = false,
    } = this.props;
    const cx = classnames(
      Styles.searchField,
      noIcon && Styles.noIcon,
      disabled && Styles.disabled,
      size === "large" && Styles.largeInput,
      className,
    );

    return (
      <div className={cx} role="search">
        <input
          ref={this.inputRef}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={onFocus}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder}
          data-automation-id="search-field-input"
          disabled={disabled}
          name={name}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
          role="searchbox"
          type="text" // Note: we are purposely not using type="search" because of inconsistencies in how browsers and assistive tech handle it
          autoFocus={autoFocus}
          data-keyboard-navigation={includeInKeyboardNav}
        />
        {value && (
          <ActionButton
            automationId="clear-search-field"
            className={Styles.clearField}
            onClick={this.clearSearch}
            aria-label={intl.formatMessage(MESSAGES.clearButton)}
            color="subtle"
          >
            <Icon name="x-mark-circle" />
          </ActionButton>
        )}
      </div>
    );
  }
}

export const SearchField = injectIntl(SearchFieldIntl);
