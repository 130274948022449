import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { StandardTrack } from "common/meeting/sidebar/party/track";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

import Styles from "./witness_party.module.scss";

type Props = {
  party: {
    participants: { fullName: string | null; isCurrentPenHolder: boolean }[];
    track: ReactNode;
    useVideoIsConnected: () => boolean;
  };
  isCredible?: boolean;
};

function WitnessParty({ party: { participants, track, useVideoIsConnected }, isCredible }: Props) {
  const [witness] = participants;
  const isConnected = useVideoIsConnected();

  return (
    <MeetingSidebarParty
      track={(trackProps) => (
        <StandardTrack
          track={
            <>
              {!isConnected && (
                <p className={Styles.witnessJoining}>
                  <FormattedMessage
                    id="8711b3a8-605f-4f24-b4ba-0beba2e0ee1f"
                    defaultMessage="Witness joining..."
                  />
                </p>
              )}
              {track}
            </>
          }
          popoutMenuProps={{
            children: () => (
              <VideoCondenseAction
                toggleVideoCondensed={trackProps.toggleVideoCondensed}
                videoCondensed={trackProps.videoCondensed}
              />
            ),
          }}
        />
      )}
    >
      <MeetingSidebarPartyInfo
        header={<span className={SENSITIVE_CLASS}>{witness.fullName}</span>}
        headerSubtext={
          <FormattedMessage
            id="7bd19854-f564-479d-93af-f8846d9a2914"
            defaultMessage="{isCurrentPenHolder, select, true {Active } other {}} {isCredible, select, true {Credible } other {}}Witness"
            values={{ isCurrentPenHolder: witness.isCurrentPenHolder, isCredible }}
          />
        }
      />
    </MeetingSidebarParty>
  );
}

export default memo(WitnessParty);
