import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useState } from "react";

import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { TextInput, TextAreaInput } from "common/core/form/text";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { DeprecatedRadioButton } from "common/form/inputs/radio";
import { DeprecatedRadioGroupContainer } from "common/form/inputs/radio/radio_group_container";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { SettingGroupName } from "graphql_globals";
import { b } from "util/html";
import SettingValueUpdaterMutation from "common/settingsv2/sidebar_settings/transaction/template_settings/setting_value_update_mutation.graphql";
import { DeprecatedStyledSelectInput } from "common/form/inputs/select";

import Styles from "./index.module.scss";
import AllOrganizationFlags, {
  type AllOrganizationFlags_viewer_settingGroups_settings as FlagType,
} from "./all_organization_flags_query.graphql";

enum FieldTypes {
  BOOLEAN = "boolean",
  STRING = "string",
  INTEGER = "integer",
  DECIMAL = "decimal",
  JSON = "json",
  ARRAY = "array",
  ENUM = "enum",
}

const messages = defineMessages({
  unusedNotice: {
    id: "24d998d4-d0c1-4428-9d13-19f89ffe49ed",
    defaultMessage: "This setting is not being used by any organizations at the moment",
  },
  value: {
    id: "ed044444-cac1-4b16-9d36-3b0b164ba507",
    defaultMessage: "Value",
  },
  newValue: {
    id: "283fdadf-1008-4b28-9983-0899490134aa",
    defaultMessage: "Add value",
  },
});

type Props = {
  flag: FlagType;
  valueCounts: Record<string, number>;
  canEdit?: boolean;
};

function OrgFlagsCard({ flag, valueCounts, canEdit }: Props) {
  const intl = useIntl();
  const settingsUpdaterMutation = useMutation(SettingValueUpdaterMutation);
  const { valueType, enumValues } = flag;

  const [groupId, setGroupId] = useState("");
  const [newGroupId, setNewGroupId] = useState("");
  const [value, setValue] = useState<boolean | string | null>("");
  const [newValue, setNewValue] = useState<boolean | string | null>("");
  const [reason, setReason] = useState("");
  const [newReason, setNewReason] = useState("");
  const [editing, setEditing] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [addingValue, setAddingValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultPlaceholderText = `${flag.defaultValue?.toString().replace(/"/g, "")} (default)`;
  const inputValueString = newValue?.toString().replace(/"/g, "");

  const updateSetting = async (name: string) => {
    setLoading(true);
    const stringValue = (addingValue ? newValue : value)!.toString();
    const mutationGroupId = addingValue ? newGroupId : groupId;
    const mutationReason = addingValue ? newReason : reason;
    const groupIdCount = mutationGroupId.split(",").length;

    let mutationValue;
    if (removing) {
      mutationValue = null;
    } else if (valueType === FieldTypes.BOOLEAN) {
      mutationValue = stringValue || null;
    } else if (
      [FieldTypes.INTEGER, FieldTypes.DECIMAL, FieldTypes.ARRAY].includes(valueType as FieldTypes)
    ) {
      mutationValue = stringValue;
    } else {
      mutationValue = JSON.stringify(stringValue);
    }

    try {
      await settingsUpdaterMutation({
        variables: {
          input: {
            name,
            groupName: SettingGroupName.ORGANIZATION,
            groupId: mutationGroupId,
            value: mutationValue,
            reason: mutationReason,
          },
        },
        refetchQueries: [
          {
            query: AllOrganizationFlags,
          },
        ],
      });
      setLoading(false);
      setEditing(false);
      setRemoving(false);
      setAddingValue(false);
      setValue("");
      setNewValue("");
      setReason("");
      setNewReason("");
      setGroupId("");
      setNewGroupId("");
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: groupIdCount > 1 ? `${groupIdCount} Settings Updated` : "Setting Updated",
      });
    } catch (e) {
      setLoading(false);
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message:
          (e as Error).message === "invalid_input"
            ? "Invalid entry. No changes were made. Please review input."
            : "Unexpected error, please reach out to engineering.",
      });
    }
  };

  const defaultValueDisplayText = (defaultValue: boolean | string | null) => {
    if (!defaultValue) {
      return valueType === FieldTypes.BOOLEAN ? "Off" : "None";
    } else if (defaultValue === "true") {
      return "On";
    } else if (defaultValue === "false") {
      return "Off";
    }
    return defaultValue;
  };
  const getKeyTitle = (key: string) => {
    const split = key.split("_").join(" ");
    return split.charAt(0).toUpperCase() + split.substring(1);
  };
  const bulkGroupIdEntry = groupId.length < 35;
  const bulkNewGroupIdEntry = newGroupId.length < 35;

  return (
    <Card
      key={flag.name}
      header={
        <div className={Styles.header}>
          {flag.displayName}
          {flag.description && (
            <div className={Styles.headerTooltip}>
              <Tooltip
                target={
                  <span>
                    <Icon name="question" />
                  </span>
                }
                placement="rightBottom"
              >
                <span>{flag.description}</span>
              </Tooltip>
            </div>
          )}
        </div>
      }
      collapsibleElement="icon"
    >
      {!Object.keys(valueCounts).length && (
        <CardText>{intl.formatMessage(messages.unusedNotice)}</CardText>
      )}
      <div>
        {Object.entries(valueCounts).map(([key, count]) => {
          return (
            <div key={key}>
              <div className={Styles.valueGroup}>
                <div className={Styles.valueGroupLabel}>
                  <FormattedMessage
                    id="ed4a3d5d-cfa3-4041-aead-5da18c169659"
                    defaultMessage="Value: {key} <c>({count})</c>"
                    values={{
                      key,
                      count,
                      c: (text) => <span className={Styles.valueCount}>{text}</span>,
                    }}
                  />
                </div>
                <div>
                  <Button
                    variant="secondary"
                    buttonColor="action"
                    withIcon={{ name: "add", placement: "left" }}
                    onClick={() => {
                      setEditing(true);
                      setValue(key);
                      setRemoving(false);
                    }}
                  >
                    Add Org
                  </Button>
                </div>
                {editing && key === value && (
                  <div>
                    <TextAreaInput
                      className={bulkGroupIdEntry ? Styles.orgIdField : Styles.orgIdArea}
                      placeholder="Org IDs"
                      rows={bulkGroupIdEntry ? 1 : 0}
                      value={groupId}
                      aria-invalid={false}
                      onChange={(evt) => setGroupId(evt.target.value)}
                      readOnly={!canEdit}
                    />
                    <TextInput
                      className={Styles.reasonField}
                      placeholder="Reason for change"
                      value={reason}
                      aria-invalid={false}
                      onChange={(evt) => setReason(evt.target.value)}
                      readOnly={!canEdit}
                    />
                    <Button
                      className={Styles.cancelButton}
                      buttonColor="action"
                      variant="secondary"
                      onClick={() => {
                        setEditing(false);
                        setGroupId("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      isLoading={loading}
                      buttonColor="action"
                      variant="primary"
                      onClick={() => updateSetting(flag.name)}
                      disabled={!canEdit}
                    >
                      {removing ? "Remove" : "Add"}
                    </Button>
                  </div>
                )}
              </div>
              <br />
            </div>
          );
        })}
        <Button
          variant="tertiary"
          buttonColor="action"
          withIcon={{ name: "add", placement: "left" }}
          onClick={() => setAddingValue(true)}
        >
          {intl.formatMessage(messages.newValue)}
        </Button>
        {addingValue && (
          <>
            <div className={Styles.formArea}>
              {valueType === FieldTypes.BOOLEAN && (
                <DeprecatedRadioGroupContainer className={Styles.radioContainer}>
                  <DeprecatedRadioButton
                    className={Styles.radioButton}
                    aria-describedby={`${flag.displayName}-instructions`}
                    radioValue="true"
                    labelText={"On"}
                    onChange={() => setNewValue(true)}
                    groupValue={newValue?.toString()}
                    disabled={!canEdit}
                  />
                  <DeprecatedRadioButton
                    className={Styles.radioButton}
                    aria-describedby={`${flag.displayName}-instructions`}
                    radioValue="false"
                    labelText={"Off"}
                    onChange={() => setNewValue(false)}
                    groupValue={newValue?.toString()}
                    disabled={!canEdit}
                  />
                  <DeprecatedRadioButton
                    className={Styles.radioButton}
                    aria-describedby={`${flag.displayName}-instructions`}
                    radioValue={""}
                    labelText={`Default (${defaultValueDisplayText(flag.defaultValue)})`}
                    onChange={() => setNewValue(null)}
                    groupValue={newValue?.toString() || ""}
                    disabled={!canEdit}
                  />
                </DeprecatedRadioGroupContainer>
              )}
              {valueType === FieldTypes.STRING && (
                <TextInput
                  aria-invalid={false}
                  value={inputValueString}
                  readOnly={!canEdit}
                  placeholder={defaultPlaceholderText}
                  onChange={(evt) => setNewValue(evt.target.value)}
                  className={`config-value-field`}
                />
              )}
              {valueType === FieldTypes.ARRAY && (
                <TextInput
                  aria-invalid={false}
                  value={newValue?.toString()}
                  readOnly={!canEdit}
                  placeholder={defaultPlaceholderText}
                  onChange={(evt) => setNewValue(evt.target.value)}
                  className={`config-value-field`}
                />
              )}
              {valueType === FieldTypes.JSON &&
                typeof value === "object" &&
                !Array.isArray(value) && (
                  <div>
                    {Object.entries(value!).map(([key, settingValue]) => {
                      return (
                        <CardSection key={key}>
                          <CardHeading level="h3">{getKeyTitle(key)}</CardHeading>
                          {Object.entries(settingValue).map(([key, settingValue]) => {
                            return (
                              <div className={Styles.fieldContainer} key={key}>
                                <div className={Styles.fieldLabel}>{getKeyTitle(key)}</div>
                                {(typeof settingValue === "string" ||
                                  typeof settingValue === "object") && (
                                  <TextInput
                                    aria-invalid={false}
                                    value={settingValue?.toString().replace(/"/g, "") || "null"}
                                    className={`config-value-field`}
                                    readOnly
                                  />
                                )}
                              </div>
                            );
                          })}
                        </CardSection>
                      );
                    })}
                  </div>
                )}
              {valueType === FieldTypes.JSON &&
                (!value || value === "{}" || value === "{} (default)" || Array.isArray(value)) && (
                  <TextInput
                    aria-invalid={false}
                    value={inputValueString}
                    readOnly={!canEdit}
                    placeholder={defaultPlaceholderText}
                    onChange={(evt) => setNewValue(evt.target.value)}
                    className={`config-value-field`}
                  />
                )}
              {(valueType === FieldTypes.INTEGER || valueType === FieldTypes.DECIMAL) && (
                <TextInput
                  value={inputValueString}
                  aria-invalid={false}
                  readOnly={!canEdit}
                  placeholder={defaultPlaceholderText}
                  onChange={(evt) => setNewValue(evt.target.value)}
                  className={`config-value-field`}
                />
              )}
              {valueType === FieldTypes.ENUM && (
                <DeprecatedStyledSelectInput
                  value={
                    inputValueString
                      ? { value: inputValueString, label: inputValueString }
                      : { value: enumValues?.[0], label: enumValues?.[0] }
                  }
                  items={enumValues?.map((enumValue) => {
                    return { value: enumValue, label: enumValue };
                  })}
                  searchable={false}
                  clearable={false}
                  onChange={(value: string) => setNewValue(value)}
                  disabled={!canEdit}
                />
              )}
            </div>
            <TextAreaInput
              className={bulkNewGroupIdEntry ? Styles.orgIdField : Styles.orgIdArea}
              placeholder="Org IDs"
              rows={bulkNewGroupIdEntry ? 1 : 0}
              value={newGroupId}
              aria-invalid={false}
              onChange={(evt) => setNewGroupId(evt.target.value)}
              readOnly={!canEdit}
            />
            <TextInput
              className={Styles.reasonField}
              placeholder="Reason for change"
              value={newReason}
              aria-invalid={false}
              onChange={(evt) => setNewReason(evt.target.value)}
              readOnly={!canEdit}
            />
            <Button
              className={Styles.reasonField}
              buttonColor="action"
              variant="secondary"
              onClick={() => setAddingValue(false)}
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              className={Styles.addButton}
              buttonColor="action"
              variant="primary"
              onClick={() => updateSetting(flag.name)}
              disabled={!canEdit}
            >
              Add
            </Button>
          </>
        )}
      </div>
      {removing && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="033f2700-6cea-45ad-9c37-6949fa3ce49b"
              defaultMessage="Are you sure?"
            />
          }
          buttons={[
            <Button
              key="cancel"
              variant="tertiary"
              buttonColor="dark"
              onClick={() => {
                setRemoving(false);
                setValue("");
                setGroupId("");
              }}
            >
              <FormattedMessage id="1aa001fb-76d9-433e-a239-4d6777d31c7a" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="remove"
              buttonColor="danger"
              variant="primary"
              isLoading={loading}
              onClick={() => {
                updateSetting(flag.name);
              }}
              disabled={!canEdit}
            >
              <FormattedMessage id="6d057d25-973d-41ff-809d-415492b91415" defaultMessage="Remove" />
            </Button>,
          ]}
          footerSeparator={false}
        >
          <FormattedMessage
            id="adfec05c-323d-49aa-be13-6646b80241ef"
            defaultMessage="You are about to remove <b>{groupId}</b> from value group <b>{value}</b>"
            values={{ b, groupId, value }}
          />
        </WorkflowModal>
      )}
    </Card>
  );
}

export default OrgFlagsCard;
