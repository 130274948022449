import "./splitting_result_logs.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import SortableTableHeader from "common/core/sortable_table/header";
import SortableTableHeaderItem from "common/core/sortable_table/header/item";
import { HEADER_SORT_CONSTRAINTS } from "common/core/sortable_table/header/constants";
import SortableTableBody from "common/core/sortable_table/body";
import SortableTableBodyItem from "common/core/sortable_table/body/item";

import type { SplittingResultLog } from ".";
import SplittingResultLogItem from "./splitting_result_log_item";

type SplittingResultLogsSortableTableProps = {
  templateSplittingResults: SplittingResultLog[];
};

const SplittingResultLogsSortableTable = ({
  templateSplittingResults,
}: SplittingResultLogsSortableTableProps) => {
  const bodyCx = classnames("SplittingResultLogs--SortableTableBody", {
    "SplittingResultLogs--SortableTableBody__no-items": templateSplittingResults.length === 0,
  });

  return (
    <SortableTableBody className={bodyCx}>
      {templateSplittingResults.length === 0 ? (
        <span className="SplittingResultLogs--no-items">
          <FormattedMessage
            id="1c87a9d7-8544-4999-a771-ddfcef21e756"
            description="noSplittingResultsFound"
            defaultMessage="No splitting results found"
          />
        </span>
      ) : (
        templateSplittingResults.map((templateSplittingResult, index) => {
          return (
            <SortableTableBodyItem
              key={templateSplittingResult.id}
              className="SplittingResultLogs--SortableTableBody--item"
            >
              <SplittingResultLogItem
                index={templateSplittingResults.length - index}
                splittingLogItem={templateSplittingResult}
              />
            </SortableTableBodyItem>
          );
        })
      )}
    </SortableTableBody>
  );
};

function SplittingResultLogs({ templateSplittingResults }: SplittingResultLogsSortableTableProps) {
  const [sort, setSort] = useState(HEADER_SORT_CONSTRAINTS.SORTED_DESC);
  const sortedResults = templateSplittingResults.slice().sort((d1, d2) => {
    return sort === HEADER_SORT_CONSTRAINTS.SORTED_DESC
      ? d2.createdAt.localeCompare(d1.createdAt)
      : d1.createdAt.localeCompare(d2.createdAt);
  });

  return (
    <div className="SplittingResultLogs">
      <div className="SplittingResultLogs--fixed-header">
        <SortableTableHeader className="SplittingResultLogs--SortableTableHeader">
          <SortableTableHeaderItem
            label={
              <FormattedMessage
                id="123ae5c0-1fc9-4490-acba-c3f37f8d7b52"
                defaultMessage="Splitting Attempt"
              />
            }
            width={3}
          />
          <SortableTableHeaderItem
            label={
              <FormattedMessage id="538fb855-796c-4c07-bf1c-e8ef9ee7b7f6" defaultMessage="Status" />
            }
            width={3}
          />
          <SortableTableHeaderItem
            label={
              <FormattedMessage
                id="d345ed99-fb5a-4eb9-af39-12361e5fa6cc"
                defaultMessage="Original Document"
              />
            }
            width={3}
          />
          <SortableTableHeaderItem
            label={
              <FormattedMessage id="ee691f05-63ba-49de-b5ce-3aea5f89a5a3" defaultMessage="Date" />
            }
            width={2}
            sortConstraint={sort}
            onSortChange={() => {
              const newSort =
                sort === HEADER_SORT_CONSTRAINTS.SORTED_DESC
                  ? HEADER_SORT_CONSTRAINTS.SORTED_ASC
                  : HEADER_SORT_CONSTRAINTS.SORTED_DESC;
              setSort(newSort);
            }}
            dataAutomationId="splitting-results-log-sort-date"
          />
        </SortableTableHeader>
      </div>
      <div className="SplittingResultLogs--body">
        <SplittingResultLogsSortableTable templateSplittingResults={sortedResults} />
      </div>
    </div>
  );
}

export default SplittingResultLogs;
