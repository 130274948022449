export const DOCUMENT_ICON = Object.freeze({
  ANNOTATE: "annotation-filled",
  ANNOTATE_LINE: "annotation-line",
  IN_MEETING: "sign-meeting-on",
  IN_MEETING_LINE: "sign-meeting-off",
  NOTARIZATION: "notarize-filled",
  PROOFING: "name",
  WET_SIGN: "wetsign-on",
  WET_SIGN_LINE: "wetsign-off",
  WITNESS: "witness-filled",
  WITNESS_LINE: "witness-line",
  EVAULT: "evault",
});
