import { FormattedMessage } from "react-intl";

import { useForm } from "common/core/form";
import { FormRow } from "common/core/form/layout";
import { RadioGroup, RadioInput, RadioLabel } from "common/core/form/option";
import { OrganizationTypeEnum } from "graphql_globals";

import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "../details_query.graphql";
import type {
  OrganizationEvaultSetup_evaultIntegration_DocMagicIntegration as DocMagicIntegration,
  OrganizationEvaultSetup_evaultIntegration_EoriginalIntegration as EoriginalIntegration,
  OrganizationEvaultSetup_evaultIntegration_SmartSafeIntegration as SmartSafeIntegration,
} from "./index_fragment.graphql";
import EOriginalCredentials from "./eoriginal";
import DocMagicCredentials from "./docmagic";
import { SmartsafeCredentialsForm as SmartSafeCredentials } from "./smartsafe";
import Styles from "./index.module.scss";

type Props = {
  organization: Organization;
};

type EvaultType =
  | DocMagicIntegration["__typename"]
  | EoriginalIntegration["__typename"]
  | SmartSafeIntegration["__typename"];

type FormValues = {
  vaultType: EvaultType;
};

function EvaultSetup({ organization }: Props) {
  const { evaultIntegration, organizationType } = organization;
  const defaultVaultType = evaultIntegration?.__typename
    ? evaultIntegration.__typename
    : organizationType === OrganizationTypeEnum.LENDER
      ? "DocMagicIntegration"
      : "EoriginalIntegration";
  const { register, watch } = useForm<FormValues>({
    defaultValues: {
      vaultType: defaultVaultType,
    },
  });
  const vaultType = watch("vaultType");

  return (
    <>
      <form className={Styles.form}>
        <FormRow>
          <RadioGroup aria-labelledby="evault-radio-group">
            {organizationType === OrganizationTypeEnum.LENDER && (
              <RadioLabel
                label={
                  <FormattedMessage
                    id="5e2ce83b-dc2f-4a54-b23a-11d642773c59"
                    defaultMessage="DocMagic"
                  />
                }
                radio={<RadioInput value="DocMagicIntegration" {...register("vaultType")} />}
                data-automation-id="evault-radio-docmagic"
              />
            )}
            <RadioLabel
              label={
                <FormattedMessage
                  id="5ac11862-c84a-4d82-bb06-628e29440c56"
                  defaultMessage="eOriginal"
                />
              }
              radio={<RadioInput value="EoriginalIntegration" {...register("vaultType")} />}
              data-automation-id="evault-radio-eoriginal"
            />
            {organizationType === OrganizationTypeEnum.BUSINESS && (
              <RadioLabel
                label={
                  <FormattedMessage
                    id="c24c2d86-7248-4e08-a9f7-0a438d59ab52"
                    defaultMessage="SmartSafe"
                  />
                }
                radio={<RadioInput value="SmartSafeIntegration" {...register("vaultType")} />}
                data-automation-id="evault-radio-smartsafe"
              />
            )}
          </RadioGroup>
        </FormRow>
      </form>
      {vaultType === "DocMagicIntegration" && (
        <DocMagicCredentials
          docMagicCredentials={
            evaultIntegration?.__typename === "DocMagicIntegration" ? evaultIntegration : null
          }
          organizationId={organization.id}
        />
      )}
      {vaultType === "EoriginalIntegration" && (
        <EOriginalCredentials
          eOriginalCredentials={
            evaultIntegration?.__typename === "EoriginalIntegration" ? evaultIntegration : null
          }
          organizationId={organization.id}
        />
      )}
      {vaultType === "SmartSafeIntegration" && (
        <SmartSafeCredentials
          smartsafeCredentials={
            evaultIntegration?.__typename === "SmartSafeIntegration" ? evaultIntegration : null
          }
          organizationId={organization.id}
        />
      )}
    </>
  );
}

export default EvaultSetup;
