import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Icon from "common/core/icon";
import { CheckboxGroup } from "common/core/form/option";
import { DeprecatedCheckboxField } from "common/form/fields/checkbox";
import type {
  CompletionStatuses,
  DocumentBundleMembershipRole,
  NotarialActs,
} from "graphql_globals";
import ActionButton from "common/core/action_button";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";

import Styles from "./index.module.scss";

type Document = {
  completionStatus: CompletionStatuses | null;
  finalAsset: { url: string | null } | null;
  id: string;
  name: string | null;
  participants: ({
    documentId: string;
    role: DocumentBundleMembershipRole;
    user_id: string;
  } | null)[];
  notarialActs: NotarialActs[];
};

type Props = { document: Document };

function DocumentDropdown({ document }: Props) {
  const [sectionOpen, setSectionOpen] = useState(false);
  const iconName = `caret-${sectionOpen ? "up" : "down"}`;
  return (
    <div className={Styles.docDropdown}>
      <div className={Styles.docHeading} onClick={() => setSectionOpen(!sectionOpen)}>
        <span>{document.name}</span>
        <div>
          <Icon name={iconName} />
        </div>
      </div>
      {sectionOpen && (
        <div>
          <div className={Styles.linkText}>
            <ActionButton
              onClick={() => {
                const viewportwidth = window.document.documentElement.clientWidth;
                if (!document.finalAsset?.url) {
                  throw new Error("Document must have a final asset url to be quality controlled");
                }
                window.open(
                  document.finalAsset.url,
                  "newwindow",
                  `width=900,height=1000,left=${viewportwidth - 600}`,
                );
              }}
            >
              <FormattedMessage
                id="03cd8002-852a-447c-a5a8-653366817676"
                defaultMessage="Open Document {icon}"
                values={{ icon: <Icon name="arrow-up-right-square" /> }}
              />
            </ActionButton>
          </div>
          {document.notarialActs.length && (
            <>
              <div className={Styles.documentIssues}>
                <CheckboxGroup
                  label={
                    <div className={Styles.documentQuestionTitle}>
                      <FormattedMessage
                        id="e3e4850e-edb2-4a63-b2f1-31d6ca501634"
                        defaultMessage="Document Issues"
                      />
                    </div>
                  }
                >
                  <div className={Styles.checkboxes}>
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="698338b4-0dd6-4319-9a8b-3897681d2bd5"
                          defaultMessage="None"
                        />
                      }
                      value="none"
                      name={`documentIssues[${document.id}].documentIssues.none`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="040c734d-0fdb-450c-9c20-3384e2557a45"
                          defaultMessage="Document fields that are/will be filled in by signer are left blank"
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.documentFieldsToBeFilledBySignerAreLeftBlank`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="737c7814-5f5a-4b27-8a7b-93aca2598311"
                          defaultMessage="Missing or incorrect signer capacity (document)"
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.missingOrIncorrectSignerCapacityDoc`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="90144f9d-d191-4f7f-ae18-235bf19810c5"
                          defaultMessage="Signer didn't sign in front of notary (Jurat)"
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.signerDidNotSignInFrontOfNotary`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="c9f34c64-5292-4e31-8471-099e8691aa02"
                          defaultMessage="Witness line(s) not properly addressed."
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.witnessLineNotProperlyAddressed`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="f5bb3012-38af-4eb5-ba86-11678ffebc91"
                          defaultMessage="Signer's signature differs from name on document"
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.signerSignatureDiffersFromNameOnDoc`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="f5bb3012-38af-4eb5-ba86-11678ffebc91"
                          defaultMessage="Notary signed outside of the notarial statement"
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.notarySignedOutsideNotarialStatement`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="1a7656ff-9a8b-4515-ace2-f661ba520e4d"
                          defaultMessage="Missing Document Pages"
                        />
                      }
                      name={`documentIssues[${document.id}].documentIssues.missingDocumentPages`}
                    />
                  </div>
                </CheckboxGroup>
                <FormGroupErrors fields={[`documentIssues_${document.id}_documentIssues`]} />
              </div>
              <div className={Styles.documentIssues}>
                <CheckboxGroup
                  label={
                    <div className={Styles.documentQuestionTitle}>
                      <FormattedMessage
                        id="983e72be-f574-4444-aeb6-98de6c951e10"
                        defaultMessage="Notary Statement Issues"
                      />
                    </div>
                  }
                >
                  <div className={Styles.checkboxes}>
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="698338b4-0dd6-4319-9a8b-3897681d2bd5"
                          defaultMessage="None"
                        />
                      }
                      value="none"
                      name={`documentIssues[${document.id}].notaryStatementIssues.none`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="cabccb82-ed3d-47e8-a4b6-9459cb5a2c4d"
                          defaultMessage="Improper completion of notarial certificate"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.improperCompletionOfNotarialCertificate`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="a715046a-d012-4d7d-b55f-9b4c007780b6"
                          defaultMessage="Undated notarial statement"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.undatedNotarialStatement`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="4dcf93bb-cb22-418b-98c9-114632231ddd"
                          defaultMessage="Missing Disclosure Statement"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingDisclosureStatement`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="4894f814-67ee-4b6b-b849-14c386f02326"
                          defaultMessage="Notary name not printed below notary signature"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.notaryNameNotPrintedBelowNotarySignature`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="bae5c354-acbd-4158-a04d-31bdcfa3f0f2"
                          defaultMessage="Missing Notary Seal"
                        />
                      }
                      value="missing_notary_seal"
                      name={`documentIssues[${document.id}].notaryStatementIssues.missing_notary_seal`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="d01d42d5-325e-4740-af9f-1c7c607f517f"
                          defaultMessage="Missing Notary Signature"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingNotarySignature`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="a6a3e9d7-9d50-46cc-8a62-e058b2bb3c41"
                          defaultMessage="Missing Notary Venue"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingNotaryVenue`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="d76a3723-73eb-4f79-9ef8-5e7e19908260"
                          defaultMessage="Missing Signer Name"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingSignerName`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="dd722ed8-1140-4064-a5d6-bda3067a1478"
                          defaultMessage="Missing ID Type"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingIdType`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="6d9c326d-ca04-4054-afdf-27ee3be9270d"
                          defaultMessage="Notarial certificate was split on two pages"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.notarialCertificateWasSplitOnTwoPages`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="0feb4440-af6f-49db-932e-606a6216b72f"
                          defaultMessage="Missing description of notarial act"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingDescriptionOfNotarialAct`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="e0c28c04-613f-44b1-9610-6a4e5269d93f"
                          defaultMessage="Notary seal obstructed"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.notarySealObstructed`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="869e9d04-3163-419c-b00f-a052f7c7937f"
                          defaultMessage="Notary seal not fully visible"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.notarySealNotFullyVisible`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="d213b3a7-b01f-4360-9b5b-721f2c888d31"
                          defaultMessage="Loose leaf's notarial act differs from one on document"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.looseLeafNotarialActDiffersFromOneOnDoc`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="f889f0b5-c03e-4ea1-9895-e8b17a172716"
                          defaultMessage="Extra notary seal(s)"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.extraNotarySeal`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="3fc6d1e9-434e-4904-a60f-0db9bc155034"
                          defaultMessage="Missing or incorrect signer capacity (statement)"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingOrIncorrectSignerCapacityStatement`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="a6da22a0-e597-4497-995f-c17c139dda1d"
                          defaultMessage="Signer's name in notarial statement differs from name on document"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.signersNameInNotarialStatementDiffersFromNameOnDoc`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="fe80b136-cc34-47ec-903a-e6b8c3ea7d91"
                          defaultMessage="Notary notarized for individual not in session"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.notaryNotarizedForIndividualNotInSession`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="71e42e53-b626-4456-89fa-ae2c9d91b5db"
                          defaultMessage="Missing Notary Title"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.missingNotaryTitle`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="09c52b81-3d86-4193-8926-fff631c89127"
                          defaultMessage="Notary notarized for individual who did not sign the document"
                        />
                      }
                      name={`documentIssues[${document.id}].notaryStatementIssues.notaryNotarizedForIndividualWhoDidNotSignTheDoc`}
                    />
                  </div>
                </CheckboxGroup>
                <FormGroupErrors fields={[`documentIssues_${document.id}_notaryStatementIssues`]} />
              </div>
              <div className={Styles.documentIssues}>
                <CheckboxGroup
                  label={
                    <div className={Styles.documentQuestionTitle}>
                      <FormattedMessage
                        id="7ac2f3b4-5935-4c8a-baed-f84be3afd703"
                        defaultMessage="Journal Issues"
                      />
                    </div>
                  }
                >
                  <div className={Styles.checkboxes}>
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="698338b4-0dd6-4319-9a8b-3897681d2bd5"
                          defaultMessage="None"
                        />
                      }
                      value="none"
                      name={`documentIssues[${document.id}].journalIssues.none`}
                    />
                    <DeprecatedCheckboxField
                      label={
                        <FormattedMessage
                          id="8825c1d4-c660-46da-899e-986fd44899fa"
                          defaultMessage="Notarial act recorded in journal differs from one in notarial statement"
                        />
                      }
                      value="hia"
                      name={`documentIssues[${document.id}].journalIssues.notarialActRecordedInJournalDiffersFromOneInNotarialStatement`}
                    />
                  </div>
                </CheckboxGroup>
                <FormGroupErrors fields={[`documentIssues_${document.id}_journalIssues`]} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default DocumentDropdown;
