import { FormattedMessage } from "react-intl";

import { DescriptionListItem } from "common/core/description_list";

type Props = {
  transaction: { message: null | string };
  className?: string;
};

export function TransactionMessage({ transaction }: Props) {
  const transactionMessageLabel = (
    <FormattedMessage id="b66aed90-7e6a-481e-a334-48f0685db36b" defaultMessage="Message" />
  );
  const transactionMessageContent = transaction.message?.split(/\n/).map((line, index) => {
    return (
      <span key={index}>
        {line}
        <br />
      </span>
    );
  }) || (
    <FormattedMessage
      id="50b88db3-f81d-4617-8cea-b11db1852380"
      defaultMessage="No message provided"
    />
  );

  return (
    <DescriptionListItem term={transactionMessageLabel} definition={transactionMessageContent} />
  );
}
