import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactElement,
} from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Subject } from "rxjs";
import classNames from "classnames";

import { useId } from "util/html";
import { MeetingParticipantRoles } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { userFullName } from "util/user";
import { ChatContext, scrollMessageIntoView } from "common/chat";
import Messages from "common/chat/messages";
import Icon from "common/core/icon";
import TooltipOverlay from "common/core/tooltip/overlay";
import PreviewMessage from "common/chat/preview_message";
import ChatImg from "assets/images/chat.svg";

import Styles from "./in_meeting_chat.module.scss";

type Participant = {
  id: string;
  role: string;
  chatAccessToken: string | null;
  parentId: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
};
type CountToggleProps = {
  isOpen: boolean;
  unreadCount: number;
  onClick: () => void;
};
type Props = {
  meeting: {
    conversationSid: null | string;
    meetingParticipants: Participant[];
  };
  currentParticipant: Participant;
  isOpen: boolean;
  onOpenToggle: () => void;
  renderChatTriggerButton: (isOpen: boolean, unreadMessages: number) => ReactElement;
  previewClassName: string;
  messagesClassName: string;
  maxPreviewMessages?: number;
};

const MESSAGES = defineMessages({
  createChatFailure: {
    id: "e803a7e7-0374-4239-bac3-66d636dc8dc9",
    defaultMessage: "We're having trouble loading your messages.",
  },
  retry: {
    id: "f0e68195-df38-4617-87bf-5e108c71e3bf",
    defaultMessage: "retry",
  },
  toggleChat: {
    id: "7bff157c-7134-44c4-9761-0e1acaed71e4",
    defaultMessage: "{isOpen, select, true{Close} other{Open}} meeting messages",
  },
});

export function CountToggleButton(props: CountToggleProps) {
  const { isOpen, unreadCount } = props;
  const intl = useIntl();
  const chatTooltipId = useId();
  return (
    <>
      <button
        type="button"
        data-automation-id="toggle-chat-button"
        aria-label={intl.formatMessage(MESSAGES.toggleChat, { isOpen })}
        aria-describedby={isOpen ? undefined : chatTooltipId}
        className={classNames(Styles.chatButton, isOpen && Styles.openedChat)}
        onClick={props.onClick}
      >
        {isOpen ? (
          <Icon name="x-mark" />
        ) : (
          <>
            {unreadCount > 0 && <span className={Styles.unreadCount}>{unreadCount}</span>}
            <img className={Styles.chatImg} alt="" src={ChatImg} />
          </>
        )}
      </button>
      {!isOpen && (
        <TooltipOverlay id={chatTooltipId} trigger="hover" placement="top">
          <FormattedMessage
            id="16ab9162-d4b1-4ed8-ada4-28f22c540e4f"
            defaultMessage="In-meeting messages"
          />
        </TooltipOverlay>
      )}
    </>
  );
}

function InMeetingChat({
  currentParticipant,
  meeting,
  isOpen,
  onOpenToggle,
  renderChatTriggerButton,
  previewClassName,
  messagesClassName,
  maxPreviewMessages = 3,
}: Props) {
  const [lastSeenMessageId, setLastSeenMessageId] = useState("");
  const wasPreviouslyOpen = useRef(false);
  const intl = useIntl();
  const {
    chatError,
    conversation,
    messages,
    previewMessages,
    retryConnect,
    enablePreviewMessages,
    clearPreviewMessage,
    createConversation,
    setMaxPreviewMessages,
  } = useContext(ChatContext);
  const canSendMessages = currentParticipant.role !== MeetingParticipantRoles.SPECTATOR;
  const localParticipants = useMemo(() => {
    const otherParticipants = meeting.meetingParticipants.filter(
      (p) => p.parentId === currentParticipant.id,
    );
    return [currentParticipant].concat(otherParticipants);
  }, [meeting]);
  const sendMessage = useCallback(
    (message: string) =>
      conversation?.sendMessage(message, {
        name: localParticipants.map((p) => userFullName(p)).join(", "),
      }),
    [conversation, localParticipants],
  );

  useEffect(() => {
    setMaxPreviewMessages(maxPreviewMessages);
  }, [maxPreviewMessages]);

  useEffect(() => {
    if (!conversation && isOpen && canSendMessages) {
      createConversation();
    }
    if (isOpen && !wasPreviouslyOpen.current) {
      enablePreviewMessages(false);
      scrollMessageIntoView(lastSeenMessageId || messages[0]?.sid, messages, true);
      setLastSeenMessageId(messages[messages.length - 1]?.sid || "");
      wasPreviouslyOpen.current = true;
    } else if (!isOpen && wasPreviouslyOpen.current) {
      enablePreviewMessages(true);
      setLastSeenMessageId(messages[messages.length - 1]?.sid || "");
      wasPreviouslyOpen.current = false;
    }
  }, [isOpen, messages, conversation]);

  useEffect(() => {
    if (chatError.type === "joinError" && chatError.retryCount === 1 && !isOpen) {
      const $dismissSubject = new Subject();
      pushNotification({
        type: NOTIFICATION_TYPES.MEETING,
        message: intl.formatMessage(MESSAGES.createChatFailure),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        duration: 15_000,
        submessage: intl.formatMessage(MESSAGES.createChatFailure),
        removeSignal$: $dismissSubject,
        handleSubmessageEvent: () => {
          $dismissSubject.next(true);
          $dismissSubject.complete();
          onOpenToggle();
          enablePreviewMessages(false);
          retryConnect();
        },
      });
    }
  }, [chatError]);

  const unreadCount = Math.max(
    [...messages].reverse().findIndex((m) => m.sid === lastSeenMessageId),
    lastSeenMessageId.length ? 0 : messages.length,
  );

  return (
    <>
      {isOpen ? (
        <Messages
          loading={Boolean(!conversation) && chatError.type === null}
          containerClassName={messagesClassName}
          error={chatError}
          onErrorRetry={retryConnect}
          canSendMessages={canSendMessages}
          messages={messages}
          sendMessage={sendMessage}
          closeMessages={onOpenToggle}
          currentParticipant={currentParticipant}
        />
      ) : (
        <div role="log" aria-live="polite" className={previewClassName}>
          {previewMessages.map((preview) => (
            <PreviewMessage
              key={preview.sid}
              message={preview}
              onMessageClick={onOpenToggle}
              onDismiss={() => clearPreviewMessage(preview.sid)}
            />
          ))}
        </div>
      )}
      {renderChatTriggerButton(isOpen, unreadCount)}
    </>
  );
}

export default memo(InMeetingChat);
