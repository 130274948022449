import classnames from "classnames";
import { useEffect, useRef, useState, type ChangeEvent, type KeyboardEvent } from "react";
import { FormattedMessage } from "react-intl";

import { IconButton } from "common/core/button/icon_button";
import { TextInput } from "common/core/form/text";

import { type ProofReasonCode } from "./context";
import { useReasonCodeDiff } from "./util";
import Styles from "./proof_reason_codes.module.scss";

const DescriptionColumn = {
  Header: (
    <span>
      <FormattedMessage id="88313022-bc19-4ae0-9800-2984045a3cd5" defaultMessage="Description" />
    </span>
  ),
  render: (reasonCode: ProofReasonCode) => {
    return <DescriptionColumnCell reasonCode={reasonCode} />;
  },
  collapse: false,
};

function DescriptionColumnCell({ reasonCode }: { reasonCode: ProofReasonCode }) {
  const { canEdit, displayValue, edited, setDescription } = useReasonCodeDiff({
    reasonCode,
    fieldName: "description",
  });

  const [editValue, setEditValue] = useState(displayValue);
  const [editing, setEditing] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const handleEditRequest = () => setEditing(true);

  const handleEditConfirm = () => {
    setDescription(reasonCode.id, editValue);
    setEditing(false);
  };

  const handleEditCancel = () => {
    setEditValue(displayValue);
    setEditing(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  };

  const handleInputKey = (event: KeyboardEvent) => {
    switch (event.code) {
      case "Escape":
        return handleEditCancel();
      case "Enter":
        return handleEditConfirm();
    }
  };

  return editing ? (
    <div className={Styles.descriptionEditBar}>
      <TextInput
        ref={inputRef}
        className={classnames(edited && Styles.editedCell)}
        aria-label="Edit description"
        aria-invalid={false}
        value={editValue}
        onChange={handleInputChange}
        onKeyUp={handleInputKey}
      />
      <IconButton
        variant="primary"
        buttonColor="action"
        buttonSize="condensed"
        name="tick"
        label={
          <FormattedMessage id="23d676ea-a2b9-4a94-ba0d-b3bedb9a7e00" defaultMessage="Confirm" />
        }
        onClick={handleEditConfirm}
      />
      <IconButton
        variant="secondary"
        buttonColor="danger"
        buttonSize="condensed"
        name="x-mark"
        label={
          <FormattedMessage id="b9e0c1c2-5a54-48fe-b4ab-37b5d4431d5b" defaultMessage="Cancel" />
        }
        onClick={handleEditCancel}
      />
    </div>
  ) : (
    <button
      className={classnames(Styles.descriptionButton, edited && Styles.editedCell)}
      disabled={!canEdit}
      type="button"
      onClick={handleEditRequest}
    >
      {displayValue}
    </button>
  );
}

export default DescriptionColumn;
