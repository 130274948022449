import { defineMessages, useIntl } from "react-intl";

import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedTextField } from "common/form/fields/text";

const MESSAGES = defineMessages({
  name: {
    id: "16a8f750-32c6-410e-a327-a8ae1447d734",
    defaultMessage: "Title",
  },
});

type Props = {
  fieldNamePrefix: string;
  readOnly?: boolean;
};

function TitleRow({ readOnly, fieldNamePrefix }: Props) {
  const intl = useIntl();
  const prefixedTitleField = `${fieldNamePrefix}.title`;

  return (
    <FormGroup fields={[prefixedTitleField]} disableFormRowStyle>
      <DeprecatedTextField
        id={prefixedTitleField}
        name={prefixedTitleField}
        placeholder={intl.formatMessage(MESSAGES.name)}
        data-automation-id="poc-title-field"
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        autoComplete="nope"
        placeholderAsLabel
        useStyledInput
        disabled={readOnly}
      />

      <FormGroupErrors fields={[prefixedTitleField]} />
    </FormGroup>
  );
}

export default TitleRow;
