import { useContext, useEffect, useMemo, createContext, type ReactNode } from "react";
import { isBefore, isValid, subSeconds } from "date-fns";
import {
  of,
  defer,
  delay,
  EMPTY,
  merge,
  filter,
  switchMap,
  tap,
  share,
  repeat,
  Subject,
  type Observable,
} from "rxjs";
import classNames from "classnames";

import {
  NotaryPresenceStatuses,
  OrganizationTransactionStatus,
  type MeetingRequestTypeEnum,
  type MeetingQueueEnum,
  type Language,
  type Feature,
  type OrganizationTypeEnum,
} from "graphql_globals";
import { useApolloClient, type ApolloClient, useQuery } from "util/graphql";
import { retryBackoff } from "util/rxjs";
import { captureException } from "util/exception";
import type Channel from "socket/channel";
import { fromSocketEvent, socketChannel } from "socket/util";
import { useOnlineHeartBeat } from "common/notary/online_heartbeat";
import { isNotaryNST } from "common/notary/capacity";

import Styles from "./index.module.scss";
import ViewerQueuesPollerQuery, {
  type ViewerQueuesPoller,
  type ViewerQueuesPoller_viewer as PollerViewer,
  type ViewerQueuesPoller_viewer_meetingQueues as Queue,
  type ViewerQueuesPoller_viewer_meetingQueues_meetingRequests as MeetingRequest,
  type ViewerQueuesPoller_viewer_meetingQueues_meetingRequests_panelMembership as PanelMembership,
} from "./viewer_queues_poller_query.graphql";

type Poller = Observable<ViewerQueuesPoller>;
type RevealInfoMeetingRequest = {
  id: string;
  revealDelayMs: number | null;
};
type OrgMeetingRequest = {
  documentBundle: { organizationTransaction: { publicOrganization: { id: string } } };
};
type SignerLeftPayload = {
  meeting_request_id: string;
};
type SignerWaitingPayload = {
  meeting_request_id: string;
  document_bundle_id: string;
  organization_transaction: {
    id: string;
    organization: {
      id: string;
      feature_list: Feature[];
      name: string;
      organizationType: OrganizationTypeEnum;
    };
    expiry: string | null;
    type: string | null;
    notary_meeting_time: string | null;
    customer_signers: {
      id: string;
      first_name: string;
      middle_name: string | null;
      last_name: string;
    }[];
  };
  closer: null | {
    id: string;
    first_name: string;
    last_name: string;
  };
  notarize_closer_override: boolean;
  queue: MeetingQueueEnum;
  language: Language;
  label: string | null;
  nod_only: boolean | null;
  panelMembership: PanelMembership | null;
  created_at: string;
  requested_by: string;
  server_time: string;
  requestType: string;
  revealDelayMs: number | null;
  isRevealed: boolean | null;
};

type QueueCountProps = {
  isSideNav?: boolean;
  small?: boolean;
  countKey: "waitingNODCalls" | "waitingClientCalls";
};

const PollerContext = createContext<Poller>(EMPTY);

function makeMeetingRequest(message: SignerWaitingPayload): MeetingRequest {
  const { organization_transaction: organizationTransaction, closer, panelMembership } = message;
  const documentBundleId = `db${message.document_bundle_id}`;
  return {
    __typename: "MeetingRequest",
    id: message.meeting_request_id,
    createdAt: message.created_at,
    revealDelayMs: message.revealDelayMs || null,
    language: message.language,
    label: message.label,
    nodOnly: Boolean(message.nod_only),
    requestedBy: message.requested_by,
    requestType: message.requestType.toUpperCase() as MeetingRequestTypeEnum,
    panelMembership: panelMembership
      ? {
          __typename: "PanelNotaryMembership",
          brandColor: panelMembership.brandColor || null,
          id: panelMembership.id,
          name: panelMembership.name,
          ownerOrganization: {
            __typename: "PublicOrganization",
            id: panelMembership.ownerOrganization.id,
          },
        }
      : null,
    documentBundle: {
      __typename: "DocumentBundle",
      id: documentBundleId,
      organizationTransaction: {
        __typename: "OrganizationTransaction",
        closer: closer && {
          __typename: "User",
          id: closer.id,
          firstName: closer.first_name,
          lastName: closer.last_name,
        },
        customerSigners: organizationTransaction.customer_signers.map((customerSigner) => ({
          __typename: "TransactionCustomer",
          id: customerSigner.id,
          firstName: customerSigner.first_name,
          middleName: customerSigner.middle_name,
          lastName: customerSigner.last_name,
          // Socket doesn't send these:
          personallyKnownToNotary: false,
        })),
        documentBundle: {
          __typename: "DocumentBundle",
          id: documentBundleId,
          meetings: { __typename: "MeetingConnection", edges: [] },
          participants: [],
        },
        expiry: organizationTransaction.expiry,
        id: organizationTransaction.id,
        notarizeCloserOverride: message.notarize_closer_override,
        notaryMeetingTime: organizationTransaction.notary_meeting_time,
        publicOrganization: {
          __typename: "PublicOrganization",
          id: organizationTransaction.organization.id,
          featureList: organizationTransaction.organization.feature_list,
          name: organizationTransaction.organization.name,
          organizationType: organizationTransaction.organization.organizationType,
        },
        requiresNsaMeeting: true,
        status: OrganizationTransactionStatus.LIVE,
        transactionType: organizationTransaction.type,
      },
    },
  };
}

function logEvent(
  eventName: string,
  message: { meeting_request_id: string; server_time?: string },
) {
  if (process.env.NODE_ENV === "production") {
    const { server_time: st, meeting_request_id: id } = message;
    // eslint-disable-next-line no-console
    console.log(
      `NotaryChannel Event ${eventName} for ${id} client processing time ${new Date().toISOString()} server message time ${st}`,
    );
  }
}

function logCustomerLeftEvent(message: Parameters<typeof logEvent>[1]) {
  logEvent("customer.left", message);
}

function logCustomerWaitingEvent(message: Parameters<typeof logEvent>[1]) {
  logEvent("customer.waiting", message);
}

function ackCustomerWaitingEvent(channel: Channel): (message: SignerWaitingPayload) => void {
  return (message) => {
    channel.sendMessage("acknowledge_call_delivery", {
      meetingRequestGid: message.meeting_request_id,
      clientReceiptTime: new Date().toISOString(),
      panelMembershipGid: message.panelMembership?.id,
      revealState:
        message.revealDelayMs === 0
          ? "ZeroDelay"
          : !!message.revealDelayMs && message.revealDelayMs > 0
            ? `Delayed:${message.revealDelayMs}`
            : message.isRevealed
              ? "Revealed"
              : "NullDelay",
    });
  };
}

function makeQueueRequestsFilterFn<MR, Q extends { meetingRequests: MR[] }>(
  predicate: (meetingRequest: MR) => boolean,
): (queue: Q) => Q {
  return (queue: Q) => {
    const filteredReqs = queue.meetingRequests.filter(predicate);
    // If theres no filtering, we can be a bit more efficent with memoization here:
    return filteredReqs.length === queue.meetingRequests.length
      ? queue
      : { ...queue, meetingRequests: filteredReqs };
  };
}

function requestDoesNotMatchOrgId<R extends OrgMeetingRequest>(
  orgId: string,
): (meetingRequest: R) => boolean {
  return (r) => r.documentBundle.organizationTransaction.publicOrganization.id !== orgId;
}

/** Filter queues for all non-revealed calls */
export function makeRevealedQueueRequestsFilterFn<
  MR extends RevealInfoMeetingRequest,
  Q extends { meetingRequests: MR[] },
>() {
  return makeQueueRequestsFilterFn<MR, Q>((r) => (r.revealDelayMs ?? 0) === 0);
}

/** Filter queues for all non-byot calls */
export function makeByotQueueRequestsFilterFn<
  MR extends OrgMeetingRequest,
  Q extends { meetingRequests: MR[] },
>(byotOrgId: string) {
  return makeQueueRequestsFilterFn<MR, Q>(requestDoesNotMatchOrgId(byotOrgId));
}

/** Filter queues of particular IDs */
export function makeMeetingRequestIdFilterFn<
  MR extends { id: string },
  Q extends { meetingRequests: MR[] },
>(ids: string[]) {
  return makeQueueRequestsFilterFn<MR, Q>((mr) => !ids.includes(mr.id));
}

function writeQuery(client: ApolloClient<unknown>, data: ViewerQueuesPoller) {
  try {
    client.writeQuery<ViewerQueuesPoller>({ query: ViewerQueuesPollerQuery, data });
  } catch (e) {
    captureException(e);
  }
}

function mapViewer(
  client: ApolloClient<unknown>,
  mapFn: (oldViewer: PollerViewer) => PollerViewer,
): Poller {
  const data = client.readQuery<ViewerQueuesPoller>({ query: ViewerQueuesPollerQuery });
  return data ? of({ ...data, viewer: mapFn(data.viewer) }) : EMPTY;
}

function handleSignerLeaving(channel: Channel, client: ApolloClient<unknown>): Poller {
  return fromSocketEvent<SignerLeftPayload>(channel, "customer.left").pipe(
    tap<SignerLeftPayload>(logCustomerLeftEvent),
    switchMap(({ meeting_request_id: id }) => {
      return mapViewer(client, (oldViewer) => ({
        ...oldViewer,
        meetingQueues: oldViewer.meetingQueues.map(
          makeMeetingRequestIdFilterFn<MeetingRequest, Queue>([id]),
        ),
      }));
    }),
  );
}

function filterStaleSocketMessage(
  client: ApolloClient<unknown>,
): (message: { meeting_request_id: string; server_time?: string }) => boolean {
  return ({ meeting_request_id: meetingRequestId, server_time: messageServerTime }) => {
    if (!messageServerTime) {
      return true;
    }

    const messageServerDate = new Date(messageServerTime);
    if (!isValid(messageServerDate)) {
      return true;
    }

    const data = client.readQuery<ViewerQueuesPoller>({ query: ViewerQueuesPollerQuery });
    if (!data) {
      return false;
    }

    // We expect that if the message has a timestamp that is older than the "polled" server time
    // plus some buffer for server latency, than we can ignore the message.
    const serverTimeWithBuffer = subSeconds(new Date(data.viewer.serverTime), 30);
    const isTooOld = isBefore(messageServerDate, serverTimeWithBuffer);
    if (process.env.NODE_ENV !== "test") {
      if (isTooOld) {
        // eslint-disable-next-line no-console
        console.log(
          `Ignoring notary channel socket event ${meetingRequestId} with message time ${messageServerDate.toISOString()} because its before ${serverTimeWithBuffer.toISOString()}.`,
        );
      }
    }
    return !isTooOld;
  };
}

function handleSignerWaiting(channel: Channel, client: ApolloClient<unknown>): Poller {
  const revealSignal$ = new Subject<SignerWaitingPayload>();
  const revealObservable$ = revealSignal$.pipe(
    switchMap((message) =>
      of({ ...message, isRevealed: true, revealDelayMs: null }).pipe(
        delay(message.revealDelayMs ?? 0),
      ),
    ),
  );
  return merge(
    revealObservable$,
    fromSocketEvent<SignerWaitingPayload>(channel, "customer.waiting"),
  ).pipe(
    tap<SignerWaitingPayload>(logCustomerWaitingEvent),
    tap<SignerWaitingPayload>(ackCustomerWaitingEvent(channel)),
    filter<SignerWaitingPayload>(filterStaleSocketMessage(client)),
    switchMap((message) => {
      const { meeting_request_id: id, queue, revealDelayMs, isRevealed } = message;
      if (!isRevealed && (revealDelayMs ?? 0) > 0) {
        revealSignal$.next(message);
      }
      return mapViewer(client, (oldViewer) => {
        let newMeetingQueues = oldViewer.meetingQueues;
        const matchingMeetingQueue = oldViewer.meetingQueues.find((q) => q.title === queue);
        if (!matchingMeetingQueue) {
          newMeetingQueues = newMeetingQueues.concat([
            {
              __typename: "MeetingQueue" as const,
              title: queue,
              meetingRequests: [makeMeetingRequest(message)],
            },
          ]);
        } else if (
          !isRevealed &&
          matchingMeetingQueue.meetingRequests.every((mr) => mr.id !== id)
        ) {
          newMeetingQueues = newMeetingQueues.map((q) => {
            return q === matchingMeetingQueue
              ? { ...q, meetingRequests: q.meetingRequests.concat(makeMeetingRequest(message)) }
              : q;
          });
        } else if (isRevealed && matchingMeetingQueue.meetingRequests.some((mr) => mr.id === id)) {
          newMeetingQueues = newMeetingQueues.map((q) => {
            return q === matchingMeetingQueue
              ? {
                  ...q,
                  meetingRequests: q.meetingRequests.map((mr) =>
                    mr.id === id ? makeMeetingRequest(message) : mr,
                  ),
                }
              : q;
          });
        }
        return { ...oldViewer, meetingQueues: newMeetingQueues };
      });
    }),
  );
}

function viewerFromNetworkQuery(client: ApolloClient<unknown>): Poller {
  return defer(() =>
    client
      // This explicitly does not use the cache. Its always from the network and does _not_ update the cache.
      .query<ViewerQueuesPoller>({ query: ViewerQueuesPollerQuery, fetchPolicy: "no-cache" })
      .then(({ data }) => data),
  ).pipe(repeat({ delay: 30_000 }), retryBackoff({ maxAttempts: 100 }));
}

function usePoller(): Poller {
  const client = useApolloClient();

  return useMemo(() => {
    // This Observable represents cache updates that come from the channel/socket events.
    const newViewersFromSocket$ = socketChannel({ channelName: "NotaryChannel" }).pipe(
      switchMap((channel) =>
        merge(handleSignerWaiting(channel, client), handleSignerLeaving(channel, client)),
      ),
    );
    // This Observable represents cache updates that come from network polling.
    const newViewersFromPollingNetwork$ = viewerFromNetworkQuery(client);

    // These "new viewer" cache updates coming from these Observables ^ do _not_ update the cache.
    // The side effect in tap is what does that.
    return merge(newViewersFromPollingNetwork$, newViewersFromSocket$).pipe(
      tap((updatedData) => writeQuery(client, updatedData)),
      // This multiplexing is what allows us to have many subscribers (many `<QueueCount />`) on the page
      // but only one subscription to the socket and only one managed effect for polling. refCounting
      // allows us to teardown pollers and subscriptions when no one is listening.
      share(),
    );
  }, [client]);
}

/**
 * @description
 * This hooks responsibility is to compute a count of waiting meeting requests and report the notary as available.
 * It does so by listening to socket events on the `NotaryChannel` and by periodically polling the network.
 */
function useSubscriptionToCount(poller$: Poller, countKey: QueueCountProps["countKey"]): number {
  // This query does not use the network, only the globally shared cache. It expects other network driven queries
  // and socket events to explicitly update the cache and this is merely a subscription to the cache.
  const { data } = useQuery(ViewerQueuesPollerQuery, {
    fetchPolicy: "cache-only",
  });

  // Being subscribed to a count means the notary is available, always.
  useOnlineHeartBeat({
    status: NotaryPresenceStatuses.AVAILABLE,
    skip: data?.viewer.user?.verifyAgent,
  });

  useEffect(() => {
    const subscription = poller$.subscribe();
    return () => subscription.unsubscribe();
  }, [poller$]);

  const allRequests = data?.viewer.meetingQueues.flatMap((q) => q.meetingRequests);
  if (!allRequests?.length) {
    return 0;
  }

  if (countKey === "waitingClientCalls") {
    return allRequests.reduce((accum, { nodOnly }) => {
      return nodOnly ? accum : accum + 1;
    }, 0);
  }

  const user = data!.viewer.user!;
  const nstOrgId = isNotaryNST(user.notaryProfile) ? user.organization!.id : null;
  if (nstOrgId) {
    const doesNotMatchByot = requestDoesNotMatchOrgId(nstOrgId);
    return allRequests.reduce((accum, req) => {
      return doesNotMatchByot(req) ? accum + 1 : accum;
    }, 0);
  }

  return allRequests.length;
}

export function NotaryQueueProvider(props: { children: ReactNode }) {
  return <PollerContext.Provider value={usePoller()}>{props.children}</PollerContext.Provider>;
}

export function QueueCount(props: QueueCountProps) {
  const count = useSubscriptionToCount(useContext(PollerContext), props.countKey);
  const queueStyle = classNames(
    Styles.requestCounter,
    props.isSideNav && Styles.sideNav,
    props.small && Styles.small,
  );
  return count ? <span className={queueStyle}>{!props.small && count}</span> : null;
}
