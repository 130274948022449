import { memo } from "react";

type Props = {
  address: {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    postal?: string | null;
    country?: string | null;
  };
  inline?: boolean;
};

function FormattedAddress({ address, inline }: Props) {
  const { line1, line2, city, state, postal, country } = address;

  if (inline) {
    return (
      <span>
        {[
          line1 && `${line1},`,
          line2 && `${line2},`,
          city && `${city},`,
          state,
          postal && (country ? `${postal},` : postal),
          country,
        ]
          .filter(Boolean)
          .join(" ")}
      </span>
    );
  }

  return (
    <div>
      {line1 && <div>{line1}</div>}
      {line2 && <div>{line2}</div>}
      <div>
        {city && `${city},`} {state} {postal}
      </div>
      {country && <div>{country}</div>}
    </div>
  );
}

export default memo(FormattedAddress);
