import type { ComponentProps } from "react";
import { useIntl } from "react-intl";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import { Hr } from "common/core/horizontal_rule";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import PointsOfContact from "./points_of_contact";
import Signer from "./signer";
import type { TransactionDetailsOrganization } from "../identity";

export function RecipientsContainer({
  bundle,
  meetingIds,
  refetch,
  organization,
  transaction,
  canEdit,
}: ComponentProps<typeof Signer> &
  ComponentProps<typeof PointsOfContact> & { organization: TransactionDetailsOrganization }) {
  const isBusinessPortal = CURRENT_PORTAL === "business";
  const hasContacts = transaction.contacts.length > 0;
  const hasCCRecipients = transaction.ccRecipientEmails.length > 0;
  const showPointsOfContact = hasCCRecipients || (!isBusinessPortal && hasContacts);

  const intl = useIntl();
  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().transactionDetailsRecipients),
  });

  return (
    <>
      <Signer
        bundle={bundle}
        meetingIds={meetingIds}
        organization={organization}
        refetch={refetch}
      />
      {showPointsOfContact && (
        <>
          <Hr />
          <PointsOfContact
            organization={organization}
            transaction={transaction}
            canEdit={canEdit}
            refetch={refetch}
            ccRecipientsOnly={isBusinessPortal}
          />
        </>
      )}
    </>
  );
}
