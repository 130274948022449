import type { ReactNode } from "react";

import { DescriptionListItem } from "common/core/description_list";

export function NotaryItemWrapper({
  term,
  definition,
  automationId,
}: {
  term: ReactNode;
  definition: ReactNode;
  automationId?: string;
}) {
  return <DescriptionListItem automationId={automationId} term={term} definition={definition} />;
}
