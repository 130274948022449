import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useState } from "react";

import { Card } from "common/core/card";
import Icon from "common/core/icon";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { EmailTextInput } from "common/core/form/text";
import { defaultRequiredMessage, emailPatternValidation } from "common/core/form/error";
import { AutomaticFormRow } from "common/core/form/layout";
import { useForm } from "common/core/form";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import { useMutation } from "util/graphql";
import { useId } from "util/html";

import { ContactDetailsHeading, RemoveContactModal } from "../common";
import DeleteCCRecipientEmail from "./delete_cc_recipient_mutation.graphql";
import AddCCRecipientEmail from "./add_cc_recipient_mutation.graphql";

const MESSAGES = defineMessages({
  email: { id: "4d22eff7-a710-4a51-9170-cdd6315cf78b", defaultMessage: "Email" },
  removeButtonLabel: {
    id: "ba5dab63-9ecd-4e00-97aa-bc22cdc5e6af",
    defaultMessage: "Delete CC contact {number}",
  },
  duplicateEmailError: {
    id: "1ef9f14d-ccb6-4a55-8eb9-e26ea60a6fec",
    defaultMessage: "Email has already been used",
  },
});

export function CCRecipientDetails({
  email,
  index,
  refetch,
  organizationTransactionId,
  canEdit,
}: {
  email: string;
  index: number;
  refetch: () => Promise<unknown>;
  organizationTransactionId: string;
  canEdit: boolean;
}) {
  const intl = useIntl();
  const [removeModal, setRemoveModal] = useState(false);

  const deleteCCRecipientEmail = useMutation(DeleteCCRecipientEmail);

  const automationId = `cc-recipient-email-${index}`;
  const removeButtonLabel = intl.formatMessage(MESSAGES.removeButtonLabel, {
    number: (index + 1).toString(),
  });

  const onRemove = async () => {
    try {
      await deleteCCRecipientEmail({ variables: { input: { email, organizationTransactionId } } });
      pushNotification({
        message: (
          <FormattedMessage
            id="36183398-867f-4cbc-82de-fae109e97fb0"
            defaultMessage="Contact removed"
          />
        ),
      });
      refetch();
    } catch {
      pushNotification({
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: (
          <FormattedMessage
            id="9518bd40-ec22-4f08-b723-b57e0fbb8323"
            defaultMessage="Sorry something went wrong. Please try again."
          />
        ),
      });
    }
  };

  const removeProps = canEdit
    ? { openRemoveModal: () => setRemoveModal(true), removeButtonLabel, onRemove }
    : {};

  return (
    <>
      <Card automationId={automationId} fullWidth>
        <ContactDetailsHeading
          {...removeProps}
          header={email}
          subheader={
            <FormattedMessage
              id="9799c62e-0184-4fd3-82a7-a5b0c0eced4f"
              defaultMessage="Contact - Receive a copy (CC)"
            />
          }
          icon={<Icon name="email" />}
          automationId={automationId}
        />
      </Card>
      {removeModal && (
        <RemoveContactModal
          contact={email}
          isLoading={false}
          onClose={() => setRemoveModal(false)}
          onRemove={onRemove}
        />
      )}
    </>
  );
}

type FormValues = {
  email: string;
};

export function CCRecipientAddModal({
  onClose,
  organizationTransactionId,
  refetch,
  ccRecipientEmails,
}: {
  onClose: () => void;
  organizationTransactionId: string;
  refetch: () => Promise<unknown>;
  ccRecipientEmails: string[];
}) {
  const formId = useId();
  const intl = useIntl();
  const form = useForm<FormValues>({
    defaultValues: { email: "" },
  });
  const addCCRecipientEmail = useMutation(AddCCRecipientEmail);
  const [loading, setLoading] = useState(false);

  const onAdd = async (values: FormValues) => {
    try {
      setLoading(true);
      await addCCRecipientEmail({
        variables: { input: { email: values.email, organizationTransactionId } },
      });
      pushNotification({
        message: (
          <FormattedMessage
            id="08a8b5db-1f3e-4c7a-aa17-1068ec8d334d"
            defaultMessage="Contact added"
          />
        ),
      });
      refetch();
      onClose();
    } catch {
      pushNotification({
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: (
          <FormattedMessage
            id="3fe859a8-215f-44c4-8d5d-853e8920ac05"
            defaultMessage="Sorry something went wrong. Please try again."
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <WorkflowModal
      footerSeparator={false}
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="71823879-aa5a-42b1-8966-2af447a01198"
          defaultMessage="Add new CC contact"
        />
      }
      buttons={[
        <Button buttonColor="dark" variant="tertiary" key="cancel" onClick={onClose}>
          <FormattedMessage id="a15a1ca9-852e-42fe-bd54-8848e9e3c2d6" defaultMessage="Cancel" />
        </Button>,
        <Button
          isLoading={loading}
          form={formId}
          buttonColor="action"
          variant="primary"
          key="add"
          type="submit"
        >
          <FormattedMessage id="496a2a46-b86b-4b17-a354-d18ace8845ab" defaultMessage="Add" />
        </Button>,
      ]}
    >
      <form id={formId} onSubmit={form.handleSubmit(onAdd)}>
        <AutomaticFormRow<FormValues>
          required
          label={intl.formatMessage(MESSAGES.email)}
          aria-label={intl.formatMessage(MESSAGES.email)}
          fullWidth
          form={form}
          name="email"
          registerOptions={{
            required: defaultRequiredMessage(intl),
            pattern: emailPatternValidation(intl),
            validate: (value) => {
              const downcasedEmails = ccRecipientEmails.map((email) => email.toLowerCase());
              return (
                !downcasedEmails.includes(value.trim().toLowerCase()) ||
                intl.formatMessage(MESSAGES.duplicateEmailError)
              );
            },
          }}
          as={EmailTextInput}
        />
      </form>
    </WorkflowModal>
  );
}
