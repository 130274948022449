import { memo, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import PaymentStatus from "common/transactions/payment_status";
import { usePermissions } from "common/core/current_user_role";
import { OrganizationTransactionVariant } from "graphql_globals";
import { DescriptionList } from "common/core/description_list";
import { Card, CardHeading } from "common/core/card";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import NotarialActs from "./items/notarial_acts";
import AgentGid from "./items/agent_gid";
import AgentName from "./items/agent_name";
import AgentRegistration from "./items/agent_registration";
import MeetingPlatform from "./items/meeting_platform";
import NotarizationStatus from "./items/status";
import TerminationInfo from "./items/termination_info";
import PayoutTransfer from "./items/payout_transfer";
import ReclaimPayout from "./items/reclaim_payout";
import type { MeetingForTransactionDetailsNotaryDetail as Meeting } from "./notary_detail_fragment.graphql";
import OverrideReason from "./items/override_reason";

type Props = {
  meeting: Meeting;
  renderPriceBreakdown?: ComponentProps<typeof PaymentStatus>["renderPriceBreakdown"];
  onReclaim?: () => void;
  notary?: null | ComponentProps<typeof AgentGid>["notary"];
};

function NotaryDetail({ onReclaim, meeting, notary, renderPriceBreakdown }: Props) {
  const { documentBundle, payoutTransfer, payoutTransferReversal } = meeting;
  const { hasPermissionFor } = usePermissions();
  const showReclaimPayout =
    hasPermissionFor("reclaimPayout") && payoutTransfer && !payoutTransferReversal;
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;

  return (
    <Card
      fullWidth
      noMargin
      header={
        <CardHeading level="h2">
          <FormattedMessage id="10ca27db-00a7-4985-897a-67afc4a3cb55" defaultMessage="Notary" />
        </CardHeading>
      }
    >
      <DescriptionList direction="vertical" itemGap="small">
        {meeting.organizationTransaction.transactionVariant !==
          OrganizationTransactionVariant.PROOF && <NotarialActs meeting={meeting} />}
        <AgentName meeting={meeting} />
        {
          /* The notary infomation presented here won't be available in every context */
          notary && isKeystone && <AgentGid notary={notary} />
        }
        <AgentRegistration meeting={meeting} />
        <MeetingPlatform meeting={meeting} />
        {hasPermissionFor("notaryPaymentInfo") && (
          <>
            <PaymentStatus
              bundle={documentBundle!}
              renderPriceBreakdown={renderPriceBreakdown}
              adminCapabilities={hasPermissionFor("paymentStatus")}
            />
            <PayoutTransfer
              payoutTransfer={meeting.payoutTransfer}
              payoutTransferReversal={meeting.payoutTransferReversal}
              renderStripeLinks={hasPermissionFor("renderStripeLinks")}
            />
          </>
        )}
        {showReclaimPayout && <ReclaimPayout onReclaim={onReclaim} payoutId={payoutTransfer.id} />}
        <NotarizationStatus meeting={meeting} />
        {hasPermissionFor("terminationInfo") && <TerminationInfo meeting={meeting} />}
        {hasPermissionFor("terminationInfo") && meeting.overrideReason && (
          <OverrideReason overrideReason={meeting.overrideReason} />
        )}
      </DescriptionList>
    </Card>
  );
}

export default memo(NotaryDetail);
