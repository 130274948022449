import type { ReactNode } from "react";
import classnames from "classnames";

import Styles from "./index.module.scss";

export function Container({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={classnames(Styles.container, className)}>{children}</div>;
}

export function Row({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={classnames(Styles.row, className)}>{children}</div>;
}

export function Body({ children }: { children: ReactNode }) {
  return <div className={Styles.body}>{children}</div>;
}
