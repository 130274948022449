import { FormattedMessage } from "react-intl";

import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { ProofRequirementMfa, type ProofRequirementInputType } from "graphql_globals";

/** deprecated - use graph ProofRequirementEnum TODO BIZ-7244 */
export enum ProofRequirementFormValues {
  IAL2 = "IAL2",
  SMS = "SMS",
  KBA = "KBA",
  CA_SELFIE = "CA_SELFIE",
}

export type ProofRequirementField = ProofRequirementFormValues[];

export function Ial2CheckboxLabel() {
  return (
    <>
      <FormattedMessage
        id="23a50438-d346-4ab7-9ebb-f11f812f271f"
        defaultMessage="IAL2-compliant identity verification"
      />
      <Tooltip target={<Icon name="info" />}>
        <FormattedMessage
          id="978be66d-7ab6-4b43-8a45-f33155d9b7a9"
          defaultMessage={
            "Failed verification may result in an additional Trusted Fraud Agent meeting cost."
          }
        />
      </Tooltip>
    </>
  );
}

export function Ial2CheckboxSublabel() {
  return (
    <FormattedMessage
      id="a560ba07-8d1b-458b-97cd-bc899328813a"
      defaultMessage="Verify with credential analysis and selfie comparison. Includes SMS authentication."
    />
  );
}

export function SmsAuthCheckboxLabel() {
  return (
    <FormattedMessage
      id="19225236-a106-4db4-9cae-1656e9de22f5"
      defaultMessage="SMS authentication"
    />
  );
}

export function SmsAuthCheckboxSublabel() {
  return (
    <FormattedMessage
      id="936f4a83-b6e7-4f1d-b09e-380b7ea2e339"
      defaultMessage="Verify mobile phone with a code."
    />
  );
}

export function CaSelfieCheckboxLabel() {
  return (
    <FormattedMessage
      id="0c3794ed-9459-4a31-9f8a-555ea46f6638"
      defaultMessage="Credential analysis + selfie comparison"
    />
  );
}

export function CaSelfieCheckboxSublabel() {
  return (
    <FormattedMessage
      id="4098edfe-034d-479a-8e3a-65d28fcfedcc"
      defaultMessage="Verify with credential analysis and selfie comparison."
    />
  );
}

export function KbaCheckboxLabel() {
  return (
    <FormattedMessage
      id="be67f351-ece0-41d4-96d3-ce951a56e0cb"
      defaultMessage="Knowledge-based authentication (KBA)"
    />
  );
}

export function KbaCheckboxSublabel() {
  return (
    <FormattedMessage
      id="a66fef37-82b3-4cde-8afb-37833073f755"
      defaultMessage="Verify with a series of personal identity questions."
    />
  );
}

export function transformProofRequirementForSubmit(
  proofRequirementField?: ProofRequirementField,
): ProofRequirementInputType {
  return {
    ca:
      proofRequirementField?.includes(ProofRequirementFormValues.IAL2) ||
      proofRequirementField?.includes(ProofRequirementFormValues.CA_SELFIE)
        ? { selfie: true }
        : null,
    mfa:
      proofRequirementField?.includes(ProofRequirementFormValues.SMS) ||
      proofRequirementField?.includes(ProofRequirementFormValues.IAL2)
        ? { type: ProofRequirementMfa.SMS }
        : null,
    kba: Boolean(proofRequirementField?.includes(ProofRequirementFormValues.KBA)),
  };
}

export function blankRequirementForSubmit(isEsign: boolean): ProofRequirementInputType | undefined {
  return isEsign
    ? {
        ca: null,
        mfa: null,
        kba: false,
      }
    : undefined;
}

export function transformProofRequirementForForm(
  proofRequirement: ProofRequirementInputType | null,
): ProofRequirementField | undefined {
  if (!proofRequirement) {
    return undefined;
  }
  const smsMfa = proofRequirement.mfa?.type === ProofRequirementMfa.SMS;
  const caSelfie = proofRequirement.ca?.selfie;
  const proofValues = [
    caSelfie && smsMfa && ProofRequirementFormValues.IAL2,
    caSelfie && ProofRequirementFormValues.CA_SELFIE,
    smsMfa && ProofRequirementFormValues.SMS,
    proofRequirement.kba && ProofRequirementFormValues.KBA,
  ].filter(Boolean) as ProofRequirementField;
  return proofValues.length ? proofValues : undefined;
}
