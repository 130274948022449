import "./remove_child_organization_warning.scss";

import { useState, useCallback } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";

import RemoveChildOrganizationMutation from "./graphql/remove_child_organization_mutation.graphql";
import AdminCompanyDetailsOrgStructureRootGraph, {
  type CompanyOrgStructureRootVariables as AdminCompanyDetailsOrgStructureRootGraphVariables,
  type CompanyOrgStructureRoot as AdminCompanyDetailsOrgStructureRootGraphData,
} from "../hierarchy_structure/graphql/index_root.query.graphql";

const MESSAGES = defineMessages({
  defaultErrorMessage: {
    id: "00dfe10c-e417-4c57-bf3a-accc67fa1dec",
    defaultMessage: "Could not unlink account",
  },
});

type Props = {
  parentOrganization: { id: string; name?: string | null };
  childOrganization: Organization;
  rootOrganizationId: string;
  onClose: () => void;
};

type Organization = {
  id: string;
  name: string | null;
};

export default function RemoveChildOrganizationWarning({
  parentOrganization,
  childOrganization,
  rootOrganizationId,
  onClose,
}: Props) {
  const intl = useIntl();
  const [errorMessage, updateErrorMessage] = useState<null | string>(null);
  const [isLoading, updateIsLoading] = useState(false);
  const removeChildOrganizationMutation = useMutation(RemoveChildOrganizationMutation);
  const removeChildOrganization = useCallback(() => {
    updateIsLoading(true);
    return removeChildOrganizationMutation({
      variables: {
        input: {
          parentOrganizationId: parentOrganization.id,
          childOrganizationId: childOrganization.id,
        },
      },
      update(cacheProxy) {
        const { rootOrganization, ...old } = cacheProxy.readQuery<
          AdminCompanyDetailsOrgStructureRootGraphData,
          AdminCompanyDetailsOrgStructureRootGraphVariables
        >({
          query: AdminCompanyDetailsOrgStructureRootGraph,
          variables: { organizationId: rootOrganizationId },
        })!;
        if (rootOrganization?.__typename !== "Organization") {
          throw new Error(`Expected Organization, got ${rootOrganization?.__typename}.`);
        }
        const newSubsidiaryOrganizations = rootOrganization.subsidiaryOrganizations.filter(
          (org) => org.id !== childOrganization.id,
        );
        const newRootOrganization = {
          ...rootOrganization,
          subsidiaryOrganizations: newSubsidiaryOrganizations,
        };
        cacheProxy.writeQuery({
          query: AdminCompanyDetailsOrgStructureRootGraph,
          variables: { rootOrganizationId },
          data: { ...old, rootOrganization: newRootOrganization },
        });
      },
    })
      .then(() => {
        updateIsLoading(false);
        onClose();
      })
      .catch((error) => {
        updateIsLoading(false);
        if (isGraphQLError(error)) {
          updateErrorMessage(
            error.graphQLErrors[0]?.message ?? intl.formatMessage(MESSAGES.defaultErrorMessage),
          );
        } else {
          updateErrorMessage(intl.formatMessage(MESSAGES.defaultErrorMessage));
          captureException(error);
        }
      });
  }, [parentOrganization, childOrganization, onClose]);

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="96b6a760-4253-45ac-b8cf-1ce8afd6c0e7"
          defaultMessage="Are you sure you want to unlink this account?"
        />
      }
      buttons={[
        <Button
          key="RemoveChildOrganizationWarning-cancel"
          variant="tertiary"
          buttonColor="action"
          onClick={onClose}
          automationId="cancel-button"
        >
          <FormattedMessage id="516a1b19-18ef-49dc-a385-bc761c4e9730" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="RemoveChildOrganizationWarning-unlink"
          buttonColor="danger"
          variant="primary"
          onClick={removeChildOrganization}
          isLoading={isLoading}
          automationId="unlink-button"
        >
          <FormattedMessage id="d537adec-fe63-4236-bdfa-4003150c8364" defaultMessage="Unlink" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <div className="RemoveChildOrganizationWarning">
        <FormattedMessage
          id="6f8a4b20-7acb-4662-80f5-538b75db924b"
          defaultMessage="This action will remove {childName} from {parentName}"
          values={{ childName: childOrganization.name, parentName: parentOrganization.name }}
        />
        <div className="RemoveChildOrganizationWarning--warning">
          <FormattedMessage
            id="3b29d1c1-a3eb-45e7-ad90-fff556504210"
            defaultMessage="This action cannot be undone."
          />
        </div>
        {errorMessage && (
          <div className="RemoveChildOrganizationWarning--error">
            <FormattedMessage
              id="57fd0ce5-4740-4216-bfb4-fe0be6fe805e"
              defaultMessage="Oops! There was an error: "
            />
            {errorMessage}
          </div>
        )}
      </div>
    </WorkflowModal>
  );
}
