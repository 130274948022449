import { useState, memo, useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import toWebVTT from "srt-webvtt";

import { Heading, Paragraph } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";

import Styles from "./processing_video.module.scss";

type Props = {
  src: string | null | undefined;
  transcript?: string | null | undefined;
  transactionName?: string | null | undefined;
};

const videoMessages = defineMessages({
  downloadTranscript: {
    id: "d8f9e848-2612-4a1b-9a16-729994ad0b30",
    defaultMessage: "Download chat transcript",
  },
});

function useTranscript(transcript: Props["transcript"]) {
  const [urls, setUrls] = useState<null | { vttUrl: string; transcriptUrl: string }>(null);

  async function getTranscript(transcript: string) {
    const response = await fetch(transcript);
    const blob = await response.blob();
    const vttUrl = await toWebVTT(blob);
    const transcriptUrl = URL.createObjectURL(blob);
    setUrls({ vttUrl, transcriptUrl });
  }

  useEffect(() => {
    if (!transcript || urls) {
      return;
    }
    getTranscript(transcript);
  }, [transcript]);

  return urls;
}

function ProcessingVideo({ src, transcript, transactionName }: Props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const urls = useTranscript(transcript);

  const showVideo = (src && !transcript) || (src && urls);
  const downloadName = transactionName
    ? `transcript-${transactionName.replace(/\s+/g, "_")}.srt`
    : "transcript.srt";

  return (
    <div className={Styles.processingVideo}>
      {!src ? (
        <div className={Styles.loading}>
          <Heading level="h3" textStyle="subtitle" textColor="white" textAlign="center">
            <FormattedMessage
              id="332e75d9-ca77-4042-802f-0cf38d9be399"
              defaultMessage="Your video is still processing"
            />
          </Heading>
          <Paragraph textColor="white" textAlign="center">
            <FormattedMessage
              id="2b6df23c-0b78-4cb6-9665-3a19f11c44eb"
              tagName="p"
              defaultMessage="Please come back later. You may have to refresh your page in order to view."
            />
          </Paragraph>
        </div>
      ) : loading ? (
        <div className={Styles.loading}>
          <Paragraph textColor="white" textAlign="center">
            <FormattedMessage
              id="c9512d6c-e970-4b9a-a412-4987eefa6141"
              defaultMessage="Loading..."
            />
          </Paragraph>
        </div>
      ) : null}
      {showVideo && (
        <>
          <video key={src} controls onCanPlay={() => setLoading(false)}>
            {src && <source src={src} />}
            {urls?.vttUrl && <track kind="subtitles" src={urls.vttUrl} default />}
          </video>
          {urls?.transcriptUrl && (
            <ButtonStyledLink
              buttonSize="condensed"
              variant="tertiary"
              buttonColor="light"
              href={urls.transcriptUrl}
              withIcon={{ name: "arrow-down-square", placement: "left" }}
              className={Styles.transcriptLink}
              download={downloadName}
            >
              {intl.formatMessage(videoMessages.downloadTranscript)}
            </ButtonStyledLink>
          )}
        </>
      )}
    </div>
  );
}

export default memo(ProcessingVideo);
