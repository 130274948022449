import "./esign_option.scss";

import { memo, useState, useCallback, type ComponentProps } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import type { SmartDocsLoans } from "graphql_globals";
import ActionButton from "common/core/action_button";
import Icon from "common/core/icon";
import { IconButton } from "common/core/button/icon_button";
import LoadingIndicator from "common/core/loading_indicator";
import { ENOTE_ACCEPTED, uploadDocumentToS3 } from "util/uploader";
import type { parseFormValuesToEnoteSeedFields } from "util/enote_seed";
import type {
  OnAddEnoteToTransactionReturnType,
  OnSaveEnoteSeedReturnType,
} from "common/transaction_creation/v3/sections/promissory_note";

import MortgageBorrowerInput from "./mortgage_borrower_input";
import ManualInputModal from "./manual_input_modal";

type MortgageBorrowerInputProps = ComponentProps<typeof MortgageBorrowerInput>;

type Props = {
  showSsnError?: boolean;
  enoteMutationLoading?: boolean;
  onAddEnoteToTransaction: (s3FileHandle: string) => Promise<OnAddEnoteToTransactionReturnType>;
  checkCanAddEnote: () => Promise<{ valid: boolean }>;
  onRemoveDocumentFromTransaction: (documentId: string) => void;
  onUpdateMortgageBorrower: MortgageBorrowerInputProps["onUpdateMortgageBorrower"];
  onRemoveEnoteSeed: () => Promise<unknown>;
  onSaveEnoteSeed: (
    enoteSeedFields: ReturnType<typeof parseFormValuesToEnoteSeedFields>,
    finalize: boolean,
  ) => Promise<OnSaveEnoteSeedReturnType>;
  onInitializeEnoteSeed: (smartDocType: SmartDocsLoans) => Promise<unknown>;
  enoteDocument?: {
    id: string;
    name: string | null;
    mortgageBorrowers?: {
      edges: {
        node: {
          id: string;
          firstName: string | null;
          lastName: string | null;
          middleName: string | null;
          hasSsn: boolean;
        };
      }[];
    };
  };
  enoteSeed?: {
    id: string;
    smartDocType: SmartDocsLoans;
    externallyManaged: boolean;
    enoteSeedFields: {
      fieldName: string;
      fieldValue: string | null;
      format: string;
      inputType: string;
      label: string;
      options: string[];
      valid: boolean;
      validationErrors: string[];
    }[];
    source: string;
  };
};

const CX = "EnoteSection--EsignOption";

function EsignOption(props: Props) {
  const {
    enoteDocument,
    enoteSeed,
    enoteMutationLoading,
    onAddEnoteToTransaction,
    checkCanAddEnote,
    onUpdateMortgageBorrower,
    onSaveEnoteSeed,
    onInitializeEnoteSeed,
    showSsnError,
    onRemoveDocumentFromTransaction,
    onRemoveEnoteSeed,
  } = props;

  const [error, setError] = useState<string | null>(null);
  const intl = useIntl();

  const [isManualEnoteModalOpen, setIsManualEnoteModalOpen] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: ENOTE_ACCEPTED,
    disabled: enoteMutationLoading,
    onDrop: useCallback(
      async ([file]: File[]) => {
        setError(null);
        const { key } = (await uploadDocumentToS3(file))!;
        const checkCanAddEnoteRes = await checkCanAddEnote();

        if (!checkCanAddEnoteRes.valid) {
          return;
        }

        const onAddRes = await onAddEnoteToTransaction(key);
        const enoteSeed =
          onAddRes.data?.addEnoteToTransaction?.organizationTransaction.document_bundle?.enoteSeed;
        if (enoteSeed) {
          // If the eNote is externally managed, meaning, a XML schema version > 12.0,
          // we don't provide the ability to manually edit the eNote, instead immediately
          // send it to eOriginal.
          if (enoteSeed.externallyManaged) {
            const onSaveRes = await onSaveEnoteSeed([], true);
            if (onSaveRes?.error) {
              await onRemoveEnoteSeed();
              setError(onSaveRes.error);
            }
          } else {
            setIsManualEnoteModalOpen(true);
          }
        } else if (onAddRes.error) {
          setError(onAddRes.error);
        }
      },
      [onAddEnoteToTransaction, checkCanAddEnote],
    ),
  });
  // To avoid a flicker effect when uploading an externally managed eNote seed (immediately converting it to an eNote),
  // verify that both the seed and the eNote are present.
  const hasEnote =
    (enoteSeed?.externallyManaged && enoteDocument) ||
    (enoteSeed && !enoteSeed.externallyManaged) ||
    enoteDocument;
  const mortgageBorrowers = enoteDocument?.mortgageBorrowers?.edges || [];
  return (
    <>
      {enoteMutationLoading && <LoadingIndicator />}

      {hasEnote && (
        <div className={`${CX}--title`}>
          <FormattedMessage
            id="80c8aa48-b429-4abb-8ff4-905f9cc2f277"
            defaultMessage="Upload the electronic promissory note"
          />
        </div>
      )}

      <div className={classnames(CX, enoteMutationLoading && `${CX}--loading`)}>
        <div className={`${CX}--subtitle`}>
          <FormattedMessage
            id="0cfbf26d-81ba-4ad9-863b-99df42a7a52b"
            defaultMessage="Accepted files include XML Seed File and MISMO SMART DOC®."
          />
        </div>
        {hasEnote ? (
          <>
            <div className={`${CX}--document`}>
              {enoteDocument?.name || enoteSeed?.smartDocType}
              <div className={`${CX}--document--buttons`}>
                {Boolean(Object.keys(enoteSeed || {}).length) && (
                  <IconButton
                    name="pencil"
                    label={
                      enoteSeed?.externallyManaged
                        ? intl.formatMessage({
                            id: "b11cc263-d2ca-468e-97c6-fe80fde96134",
                            defaultMessage:
                              "XML Seed Files above version 12.0 must be edited externally.",
                          })
                        : intl.formatMessage({
                            id: "c67cd14c-09b8-488e-b304-2ef01cfd832d",
                            defaultMessage: "Edit the uploaded eNote document",
                          })
                    }
                    hoverLabel={enoteSeed?.externallyManaged ? "bottomRight" : undefined}
                    variant="secondary"
                    buttonColor="action"
                    disabled={enoteMutationLoading || enoteSeed?.externallyManaged}
                    onClick={() => setIsManualEnoteModalOpen(true)}
                    automationId="edit-enote-button"
                  />
                )}
                <IconButton
                  name="delete"
                  label={intl.formatMessage({
                    id: "c67cd14c-09b8-488e-b304-2ef01cfd831d",
                    defaultMessage: "Remove the uploaded eNote document",
                  })}
                  variant="secondary"
                  buttonColor="danger"
                  disabled={enoteMutationLoading}
                  onClick={() => {
                    if (enoteSeed && Object.keys(enoteSeed).length) {
                      onRemoveEnoteSeed();
                    }

                    if (enoteDocument && Object.keys(enoteDocument).length) {
                      onRemoveDocumentFromTransaction(enoteDocument.id);
                    }
                  }}
                  automationId="remove-enote-button"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {/*DSP-356: custom file uploader needs the axe*/}
            <div
              {...getRootProps({
                className: classnames(`${CX}--emptyView`, error && `${CX}--emptyView--error`),
                "data-automation-id": "enote-upload-zone",
              })}
            >
              <input {...getInputProps()} />

              <Icon className={`${CX}--upload-icon`} name="arrow-up-square" size="large" />

              <div className={`${CX}--dropzone-title`}>
                <FormattedMessage
                  id="48af84da-0de0-42e5-9f88-8e6997fb5658"
                  defaultMessage="Upload the electronic promissory note"
                />
              </div>

              <ActionButton className={`${CX}--dropzone-subtitle`}>
                <FormattedMessage
                  id="3fd64355-2fe2-4ad1-9357-7a1949cfdc8f"
                  defaultMessage="Browse or drop files here"
                />
              </ActionButton>
            </div>

            {error && <div className={`${CX}--doc-error`}>{error}</div>}

            <ActionButton
              className={`${CX}--manual`}
              automationId="create-manual-enote"
              onClick={() => {
                setError(null);
                setIsManualEnoteModalOpen(true);
              }}
            >
              <FormattedMessage
                id="e6371355-9069-44d3-99a1-c34163055d6f"
                defaultMessage="I need to create an eNote manually."
              />
            </ActionButton>
          </>
        )}
        {Boolean(mortgageBorrowers.length) && (
          <div className={`${CX}--ssn`} data-automation-id="esign-option-section">
            <div className={`${CX}--ssn--header`}>
              <FormattedMessage
                id="b95dc3e4-46ff-4df1-abf1-681b433215d3"
                defaultMessage="Assign a social security number for each signer on the eNote:"
              />
            </div>
            {showSsnError && (
              <div className={`${CX}--ssn--error validation-failed`}>
                <FormattedMessage
                  id="0e7d2014-f78e-4aa5-8846-125fa8d2697a"
                  defaultMessage="All signers must have an SSN in order for the eNote to register."
                />
              </div>
            )}
            {mortgageBorrowers.map(({ node: borrower }, index) => (
              <MortgageBorrowerInput
                key={index}
                borrower={borrower}
                onUpdateMortgageBorrower={onUpdateMortgageBorrower}
              />
            ))}
          </div>
        )}
      </div>
      {isManualEnoteModalOpen && (
        <ManualInputModal
          enoteSeed={enoteSeed}
          onClose={() => setIsManualEnoteModalOpen(false)}
          onSaveEnoteSeed={onSaveEnoteSeed}
          onInitializeEnoteSeed={onInitializeEnoteSeed}
          hasEnoteDocument={Boolean(Object.keys(enoteDocument || {}).length)}
        />
      )}
    </>
  );
}

export default memo(EsignOption);
