import type { ComponentProps } from "react";

import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import { DescriptionListItem } from "common/core/description_list";
import { PassFailStatus } from "common/transactions/details/common";

type Props = {
  completionRequirements: ComponentProps<
    typeof CompletionRequirementsText
  >["completionRequirements"];
};

export function ValidationStatus({ completionRequirements }: Props) {
  const validationStatusLabel = (
    <CompletionRequirementsText
      stringId="verificationMethod"
      completionRequirements={completionRequirements}
    />
  );
  const validationStatusContent = <PassFailStatus dataAutomationId="kba-status" status="pass" />;
  return <DescriptionListItem term={validationStatusLabel} definition={validationStatusContent} />;
}
