import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DescriptionListItem } from "common/core/description_list";

import CustomerId from "./customer_id";

type Props = {
  signerIdentity: ComponentProps<typeof CustomerId>["signerIdentity"];
};

function PhotoId({ signerIdentity }: Props) {
  const photoIdLabel = (
    <FormattedMessage id="2d573e27-297c-49ae-88d9-1860027d0f90" defaultMessage="Signer Photo ID" />
  );
  const photoIdContent = <CustomerId signerIdentity={signerIdentity} />;

  return <DescriptionListItem term={photoIdLabel} definition={photoIdContent} />;
}

export default PhotoId;
