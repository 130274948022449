import { FormattedMessage } from "react-intl";

import { Container } from "common/core/responsive";
import BillingSetup from "admin_portal/company/billing_setup";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { usePermissions } from "common/core/current_user_role";
import { SettingsTitle } from "common/settingsv2/common";
import { Card, CardText } from "common/core/card";
import { Heading } from "common/core/typography";
import Link from "common/core/link";

import AdminBillingViewerQuery from "./viewer_query.graphql";
import type { AdminCompanyDetailsOrganization_organization_Organization as Organization } from "../organization_query.graphql";

function AdminCompanyDetailsPricingAndBillingBilling(props: { organization: Organization }) {
  const { hasPermissionFor } = usePermissions();
  const { data, loading } = useQuery(AdminBillingViewerQuery);
  if (loading) {
    return <LoadingIndicator />;
  }
  const {
    organization: { billableOrganization, managedBilling },
  } = props;
  return (
    <Container fluid style={{ width: "100%" }}>
      <SettingsTitle>
        <FormattedMessage
          id="22b044a4-e4c0-4444-a6f2-1cda5ec75bac"
          defaultMessage="Billing settings"
        />
      </SettingsTitle>
      {managedBilling ? (
        <Card>
          <Heading textStyle="subtitleSmall" level="h3">
            <FormattedMessage
              id="d9d81025-4bd0-4677-bb99-78bf661622f5"
              defaultMessage="Billing handled by root organization"
            />
          </Heading>
          <CardText>
            <FormattedMessage
              id="ffb14850-ae76-4120-8cb5-625e9ed89a7d"
              defaultMessage="This organization's charges are being billed to {orgName} ({orgLink}). Navigate to {orgLink} to view or make changes."
              values={{
                orgName: billableOrganization.name,
                orgLink: (
                  <Link to={`/companies/${billableOrganization.id}/pricing-and-billing/billing`}>
                    {billableOrganization.id}
                  </Link>
                ),
              }}
            />
          </CardText>
        </Card>
      ) : (
        <BillingSetup
          organization={props.organization}
          viewer={data!.viewer}
          formDisabled={!hasPermissionFor("editCompanyDetailsBilling")}
        />
      )}
    </Container>
  );
}

export default AdminCompanyDetailsPricingAndBillingBilling;
