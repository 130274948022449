import { memo } from "react";
import { FormattedMessage } from "react-intl";

import MinimizeIcon from "assets/images/minimize_screen.svg";
import MaximizeIcon from "assets/images/fullscreen.svg";
import SettingsIcon from "assets/images/settings_filled.svg";
import PopoutMenuItem from "common/core/popout_menu/item";
import VideoBackgroundIcon from "assets/images/blur_full.svg";

import Styles from "./actions.module.scss";

type CondenseProps = {
  videoCondensed?: boolean;
  toggleVideoCondensed: () => void;
};
type StandardActionsProps = CondenseProps & {
  toggleAvOpen: () => void;
  toggleBackgroundOpen?: () => void;
  actAsWitness?: () => void;
};

function VideoCondenseAction({ toggleVideoCondensed, videoCondensed }: CondenseProps) {
  return (
    <PopoutMenuItem
      onClick={toggleVideoCondensed}
      backgroundImage={videoCondensed ? MaximizeIcon : MinimizeIcon}
    >
      <FormattedMessage
        id="6ac27456-22b6-4345-96bf-a401479b7bbc"
        defaultMessage="{videoCondensed, select, true{Expand} other{Minimize}} video"
        values={{ videoCondensed }}
      />
    </PopoutMenuItem>
  );
}

const VideoCondenseActionMemoized = memo(VideoCondenseAction);

function StandardLocalPartyActions({
  toggleAvOpen,
  toggleBackgroundOpen,
  videoCondensed,
  toggleVideoCondensed,
  actAsWitness,
}: StandardActionsProps) {
  return (
    <>
      <PopoutMenuItem onClick={toggleAvOpen} backgroundImage={SettingsIcon}>
        <FormattedMessage id="b0bf96c3-9058-43a4-ae89-9d2f7d648349" defaultMessage="A/V settings" />
      </PopoutMenuItem>
      <VideoCondenseAction
        videoCondensed={videoCondensed}
        toggleVideoCondensed={toggleVideoCondensed}
      />
      {toggleBackgroundOpen && (
        <PopoutMenuItem onClick={toggleBackgroundOpen} backgroundImage={VideoBackgroundIcon}>
          <FormattedMessage
            id="32a3181c-610e-4625-963f-1d3c0111b293"
            defaultMessage="Video Background"
          />
        </PopoutMenuItem>
      )}
      {actAsWitness && (
        <PopoutMenuItem onClick={actAsWitness} iconName="name" iconClass={Styles.icon}>
          <FormattedMessage
            id="0498913c-8763-49ab-aa1b-b7d3f17378c9"
            defaultMessage="Act as witness"
          />
        </PopoutMenuItem>
      )}
    </>
  );
}

export default memo(StandardLocalPartyActions);
export { VideoCondenseActionMemoized as VideoCondenseAction };
