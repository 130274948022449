import { useMemo, useState, type ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { startOfToday, endOfToday, startOfMonth, endOfMonth, add, sub, formatISO } from "date-fns";

import { getDateRangeLabel, DateRangePicker } from "common/core/date_range_picker";
import {
  FilterDropdown,
  FilterGroup,
  FilterMultiOption,
  FilterOption,
} from "common/dashboard/filter_dropdown";
import { TransactionStatus } from "common/dashboard/columns";
import {
  OrganizationTransactionStatus,
  OrganizationTransactionDetailedStatus,
  MortgageTransactionType,
  OrganizationTransactionLabels,
} from "graphql_globals";
import { SEGMENT_EVENTS } from "constants/analytics";
import { useDashboardSegmentTrack, getTransactionTypeMessage } from "common/dashboard/util";
import Button from "common/core/button";
import { LOAN_MOD_BORROWER } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";
import { useSignTransactionsEnabled } from "util/feature_detection";

import { useToggleSet } from "../filter";
import Styles from "./common.module.scss";
import { DashboardPopup } from "..";

const messages = defineMessages({
  dateRangePopupLabel: {
    id: "30179c66-6015-4c6e-b451-d4e795fbd53a",
    defaultMessage: "Choose date range",
  },
});

export const ORDER_PROGRESS_LABEL_MAP = {
  [OrganizationTransactionLabels.RECEIVED]: (
    <FormattedMessage id="0cbcddac-d0d4-464e-935f-63061ebdb797" defaultMessage="Received" />
  ),
  [OrganizationTransactionLabels.IN_QC]: (
    <FormattedMessage id="0a0a45da-5c4c-43e0-9e32-85368733bb0f" defaultMessage="In QC" />
  ),
  [OrganizationTransactionLabels.ON_HOLD_FINAL_CD]: (
    <FormattedMessage id="d486803a-3628-4cf8-b172-d2b08b334e8c" defaultMessage="Hold - FCD" />
  ),
  [OrganizationTransactionLabels.ON_HOLD_NAME_DISCREPANCY]: (
    <FormattedMessage id="454832c7-4e33-4073-b351-857fd2855cd2" defaultMessage="Hold - Name" />
  ),
  [OrganizationTransactionLabels.ON_HOLD_ENOTE]: (
    <FormattedMessage id="f106e266-fb73-4ad8-847c-c95bc49d2db8" defaultMessage="Hold - eNote" />
  ),
  [OrganizationTransactionLabels.ON_HOLD_OTHER]: (
    <FormattedMessage id="53eadddd-1c23-4280-b149-a2d35c71cf69" defaultMessage="Hold - Other" />
  ),
  [OrganizationTransactionLabels.PREPARING_ORDER]: (
    <FormattedMessage id="4f14f4de-0750-4911-9157-752260c03893" defaultMessage="Preparing order" />
  ),
  [OrganizationTransactionLabels.AWAITING_LENDER_APPROVAL]: (
    <FormattedMessage id="f63244d3-72d5-4f71-a4c4-eebc79024b9e" defaultMessage="Ready to send" />
  ),
};

export enum TransactionSectionNamespace {
  ALL = "ALL",
  OPEN_ORDER = "OPEN_ORDER",
  ARCHIVED = "ARCHIVED",
  DUPLICATED = "DUPLICATED",
}

export enum TransactionSubsectionNamespace {
  KBA_ISSUES = "KBA_ISSUES",
  CREATED_BY_ME = "CREATED_BY_ME",
  IDENTITY_RISK = "IDENTITY_RISK",
  DEFAULT = "DEFAULT",
}

type StatusFilterProps = {
  selectedStatuses: Readonly<Set<OrganizationTransactionDetailedStatus>>;
  inProgressStatuses: OrganizationTransactionDetailedStatus[];
  completeStatuses?: OrganizationTransactionDetailedStatus[];
  otherOptions: [OrganizationTransactionStatus, OrganizationTransactionDetailedStatus][];
  clearSelection: () => void;
  removeAll: (statuses: OrganizationTransactionDetailedStatus[]) => void;
  addAll: (statuses: OrganizationTransactionDetailedStatus[]) => void;
  toggleSelection: (status: OrganizationTransactionDetailedStatus) => void;
  disabled: boolean;
  "data-automation-id"?: string;
};

type TransactionTypeFilterProps = {
  selectedTypes: Readonly<Set<MortgageTransactionType>>;
  disabled: boolean;
  clearSelection: () => void;
  toggleSelection: (transactionType: MortgageTransactionType) => void;
  removeAll: (transactionTypes: MortgageTransactionType[]) => void;
  addAll: (transactionTypes: MortgageTransactionType[]) => void;
};

type SingleDateConstraint =
  | "today"
  | "tomorrow"
  | "next7days"
  | "previous7days"
  | "thismonth"
  | "previousmonth";

export type CustomDateConstraint = { startDate: string; endDate: string };

export type DateConstraint = SingleDateConstraint | CustomDateConstraint;

type ClosingDateFilterProps = {
  disabled: boolean;
  selectedConstraint: DateConstraint | null;
  setSelectedConstraint: (constraint: DateConstraint | null) => void;
};
type DateFilterProps = {
  label: ReactNode;
  disabled: boolean;
  selectedConstraint: DateConstraint | null;
  setSelectedConstraint: (constraint: DateConstraint | null) => void;
  "data-automation-id"?: string;
  renderFilterOptions: (setDateRangePickerOpen: (arg: boolean) => void) => ReactNode;
};
type SelectedFiltersLabelProps = {
  count?: number;
  label: ReactNode;
  floatingLabel?: ReactNode;
};

export function SelectedFiltersLabel({ count, label, floatingLabel }: SelectedFiltersLabelProps) {
  return (
    <div className={Styles.selectedFiltersLabelWrapper}>
      {floatingLabel && <span className={Styles.floatingLabel}>{floatingLabel}</span>}
      <span className={Styles.selectedFiltersLabel}>
        {label}
        {count && <span> ({count})</span>}
      </span>
    </div>
  );
}

const DateConstraintLabels = {
  today: <FormattedMessage id="2de74056-edfa-4dc5-8527-c1dae06d3175" defaultMessage="Today" />,
  tomorrow: (
    <FormattedMessage id="8e864051-4184-4305-8993-ab58651d37ef" defaultMessage="Tomorrow" />
  ),
  next7days: (
    <FormattedMessage id="56a1ba4c-499b-4254-9400-702902ea40dd" defaultMessage="Next 7 days" />
  ),
  previous7days: (
    <FormattedMessage id="24f638d3-9627-4968-8707-2a331de24dd4" defaultMessage="Previous 7 days" />
  ),
  thismonth: (
    <FormattedMessage id="346aea73-7642-4cf0-a6b4-f8d1e211c25a" defaultMessage="This month" />
  ),
  previousmonth: (
    <FormattedMessage id="625e5dcf-483c-45ff-8250-a5247531958a" defaultMessage="Previous month" />
  ),
  custom: <FormattedMessage id="283ebcb9-a9cd-436d-8a67-0df5891875aa" defaultMessage="Custom" />,
};

export function isCustomDateConstraint(
  selectedConstraint: DateConstraint | null,
): selectedConstraint is CustomDateConstraint {
  return !!selectedConstraint && typeof selectedConstraint === "object";
}

// NotarizeScalarDateWithTimezone
export function useDateConstraint(
  selectedConstraint: DateConstraint | null,
  setSelectedConstraint?: (arg: DateConstraint | null) => void,
) {
  const [startDate, endDate] = useMemo(() => {
    const todayStart = startOfToday();
    const todayEnd = endOfToday();
    const monthStart = startOfMonth(todayStart);

    if (selectedConstraint === "today") {
      return [todayStart, todayEnd];
    } else if (selectedConstraint === "tomorrow") {
      return [add(todayStart, { days: 1 }), add(todayEnd, { days: 1 })];
    } else if (selectedConstraint === "next7days") {
      return [todayStart, add(todayEnd, { days: 7 })];
    } else if (selectedConstraint === "previous7days") {
      return [sub(todayStart, { days: 7 }), todayEnd];
    } else if (selectedConstraint === "thismonth") {
      return [monthStart, endOfMonth(todayStart)];
    } else if (selectedConstraint === "previousmonth") {
      return [sub(monthStart, { months: 1 }), endOfMonth(sub(todayStart, { months: 1 }))];
    } else if (isCustomDateConstraint(selectedConstraint)) {
      return [new Date(selectedConstraint.startDate), new Date(selectedConstraint.endDate)];
    }
    return [null, null];
  }, [selectedConstraint]);

  return {
    selectedConstraint,
    setSelectedConstraint,
    startDate: startDate ? formatISO(startDate) : null,
    endDate: endDate ? formatISO(endDate) : null,
  };
}

export function StatusFilter(props: StatusFilterProps) {
  const {
    selectedStatuses,
    inProgressStatuses,
    completeStatuses = [],
    otherOptions,
    clearSelection,
    removeAll,
    addAll,
    toggleSelection,
    disabled,
    "data-automation-id": dataAutomationId,
  } = props;
  const inProgressStatusesSelected = inProgressStatuses.every((status) =>
    selectedStatuses.has(status),
  );
  const allCompleteStatusesSelected = completeStatuses.every((status) =>
    selectedStatuses.has(status),
  );
  const dashboardSegmentTrack = useDashboardSegmentTrack();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FilterDropdown
      data-automation-id={dataAutomationId}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hasSelection={Boolean(selectedStatuses.size)}
      unselectedLabel={
        <FormattedMessage id="a1817457-ed90-4ed5-885d-f5a5e4bbd9e4" defaultMessage="All statuses" />
      }
      label={
        <SelectedFiltersLabel
          count={selectedStatuses.size}
          label={
            <FormattedMessage id="298be429-f6ea-4031-b8a3-bf8e2ef0f99a" defaultMessage="Statuses" />
          }
        />
      }
      onSelectAll={clearSelection}
      disabled={disabled}
    >
      <FilterGroup
        label={
          <TransactionStatus
            status={OrganizationTransactionStatus.ACTIVE}
            detailedStatus={OrganizationTransactionDetailedStatus.SENT_TO_SIGNER}
            statusOverride={
              <FormattedMessage
                id="cad68fd2-e3f9-4afd-a6cd-7724fbfb338b"
                defaultMessage="All in progress"
              />
            }
          />
        }
        selected={inProgressStatusesSelected}
        onChange={() => {
          if (inProgressStatusesSelected) {
            removeAll(inProgressStatuses);
          } else {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "DROPDOWN_ALL_IN_PROGRESS",
            });
            addAll(inProgressStatuses);
          }
        }}
        disabled={disabled}
      >
        {inProgressStatuses.map((detailedStatus) => (
          <FilterMultiOption
            key={`option-${detailedStatus}`}
            selected={selectedStatuses.has(detailedStatus)}
            onChange={() => {
              if (!selectedStatuses.has(detailedStatus)) {
                dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                  value: detailedStatus,
                });
              }
              toggleSelection(detailedStatus);
            }}
            disabled={disabled}
          >
            <TransactionStatus
              status={OrganizationTransactionStatus.ACTIVE}
              detailedStatus={detailedStatus}
              hideCircle
            />
          </FilterMultiOption>
        ))}
      </FilterGroup>
      {completeStatuses.length > 0 && (
        <FilterGroup
          label={
            <TransactionStatus
              status={OrganizationTransactionStatus.SUCCESS}
              detailedStatus={OrganizationTransactionDetailedStatus.COMPLETE}
              statusOverride={
                <FormattedMessage
                  id="133fd42b-fe28-4a72-8fcd-070271d6d701"
                  defaultMessage="All completed"
                />
              }
            />
          }
          selected={allCompleteStatusesSelected}
          onChange={() => {
            if (allCompleteStatusesSelected) {
              removeAll(completeStatuses);
            } else {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: "DROPDOWN_ALL_COMPLETED",
              });
              addAll(completeStatuses);
            }
          }}
          disabled={disabled}
        >
          {completeStatuses.map((detailedStatus) => (
            <FilterMultiOption
              key={`option-${detailedStatus}`}
              selected={selectedStatuses.has(detailedStatus)}
              onChange={() => {
                if (!selectedStatuses.has(detailedStatus)) {
                  dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                    value: detailedStatus,
                  });
                }
                toggleSelection(detailedStatus);
              }}
              disabled={disabled}
            >
              <TransactionStatus
                status={OrganizationTransactionStatus.SUCCESS}
                detailedStatus={detailedStatus}
                hideCircle
              />
            </FilterMultiOption>
          ))}
        </FilterGroup>
      )}
      {otherOptions.map(([status, detailedStatus]) => (
        <FilterMultiOption
          key={`option-${detailedStatus}`}
          selected={selectedStatuses.has(detailedStatus)}
          onChange={() => {
            if (!selectedStatuses.has(detailedStatus)) {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: detailedStatus,
              });
            }
            toggleSelection(detailedStatus);
          }}
          disabled={disabled}
        >
          <TransactionStatus status={status} detailedStatus={detailedStatus} />
        </FilterMultiOption>
      ))}
    </FilterDropdown>
  );
}

export function useToggleSetWithCallback<T, U>(
  arg: Set<T>,
  onChange: (updatedQuery: Partial<U>) => void,
  key: keyof U,
) {
  const { clearSelection, removeAll, addAll, toggleSelection, setSelection } = useToggleSet<T>(arg);

  return {
    clearSelection() {
      onChange({ [key]: clearSelection() } as Partial<U>);
    },
    removeAll(itemsToRemove: T[]) {
      onChange({ [key]: removeAll(itemsToRemove) } as Partial<U>);
    },
    addAll(itemsToAdd: T[]) {
      onChange({ [key]: addAll(itemsToAdd) } as Partial<U>);
    },
    toggleSelection(item: T) {
      onChange({ [key]: toggleSelection(item) } as Partial<U>);
    },
    setSelection(items: T[]) {
      onChange({ [key]: setSelection(items) } as Partial<U>);
    },
  };
}
const deprecatedOnlineTransactionTypes = [
  MortgageTransactionType.refinance,
  MortgageTransactionType.trailing_docs,
  MortgageTransactionType.purchase_seller,
  MortgageTransactionType.purchase_buyer_cash,
  MortgageTransactionType.purchase_buyer_loan,
  MortgageTransactionType.heloc,
  MortgageTransactionType.other,
];

const currentOnlineTransactionTypes = [
  MortgageTransactionType.refinance,
  MortgageTransactionType.trailing_docs,
  MortgageTransactionType.purchase_seller,
  MortgageTransactionType.purchase_buyer_cash,
  MortgageTransactionType.purchase_buyer_loan,
  MortgageTransactionType.heloc,
  MortgageTransactionType.loan_mod_borrower,
  MortgageTransactionType.other,
];

const hybridTransactionTypes = [
  MortgageTransactionType.hybrid_refinance,
  MortgageTransactionType.hybrid_trailing_docs,
  MortgageTransactionType.hybrid_purchase_seller,
  MortgageTransactionType.hybrid_purchase_buyer_cash,
  MortgageTransactionType.hybrid_purchase_buyer_loan,
  MortgageTransactionType.hybrid_other,
];

export function TransactionTypeFilter(props: TransactionTypeFilterProps) {
  const { selectedTypes, clearSelection, addAll, removeAll, toggleSelection, disabled } = props;
  const intl = useIntl();
  const fullOnlineTransactionTypes = useFeatureFlag(LOAN_MOD_BORROWER)
    ? currentOnlineTransactionTypes
    : deprecatedOnlineTransactionTypes;

  const signTransactionsEnabled = useSignTransactionsEnabled();

  const fullOnlineTransactionTypesSelected = fullOnlineTransactionTypes.every((transactionType) =>
    selectedTypes.has(transactionType),
  );

  const hybridTransactionTypesSelected = hybridTransactionTypes.every((transactionType) =>
    selectedTypes.has(transactionType),
  );

  const dashboardSegmentTrack = useDashboardSegmentTrack();
  const [isOpen, setIsOpen] = useState(false);

  const handleMultiOptionChange = (transactionType: MortgageTransactionType) => {
    if (!selectedTypes.has(transactionType)) {
      dashboardSegmentTrack(SEGMENT_EVENTS.TRANSACTION_TYPE_FILTER_SELECTED, {
        value: transactionType,
      });
    }
    toggleSelection(transactionType);
  };

  return (
    <FilterDropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hasSelection={Boolean(selectedTypes.size)}
      unselectedLabel={
        <FormattedMessage id="f2a52fe6-33cd-44a1-be20-310e58f4bf75" defaultMessage="All types" />
      }
      label={
        <SelectedFiltersLabel
          count={selectedTypes.size}
          label={
            <FormattedMessage id="63e20df7-9d2d-4c37-989c-959544be41dc" defaultMessage="Types" />
          }
        />
      }
      onSelectAll={clearSelection}
      disabled={disabled}
    >
      <FilterGroup
        label={
          <FormattedMessage id="cb525ad8-fd65-49ee-aa8c-1691e69b7690" defaultMessage="All eClose" />
        }
        selected={fullOnlineTransactionTypesSelected}
        onChange={() => {
          if (fullOnlineTransactionTypesSelected) {
            removeAll(fullOnlineTransactionTypes);
          } else {
            dashboardSegmentTrack(SEGMENT_EVENTS.TRANSACTION_TYPE_FILTER_SELECTED, {
              value: "DROPDOWN_ALL_ECLOSE",
            });
            addAll(fullOnlineTransactionTypes);
          }
        }}
        disabled={disabled}
      >
        {fullOnlineTransactionTypes.map((transactionType) => (
          <FilterMultiOption
            key={`option-${transactionType}`}
            selected={selectedTypes.has(transactionType)}
            onChange={() => handleMultiOptionChange(transactionType)}
            disabled={disabled}
          >
            {getTransactionTypeMessage(transactionType, intl, signTransactionsEnabled)}
          </FilterMultiOption>
        ))}
      </FilterGroup>
      <FilterGroup
        label={
          <FormattedMessage id="d989a5a2-f583-4bf3-ba2f-b520c79fddab" defaultMessage="All hybrid" />
        }
        selected={hybridTransactionTypesSelected}
        onChange={() => {
          if (hybridTransactionTypesSelected) {
            removeAll(hybridTransactionTypes);
          } else {
            dashboardSegmentTrack(SEGMENT_EVENTS.TRANSACTION_TYPE_FILTER_SELECTED, {
              value: "DROPDOWN_ALL_HYBRID",
            });
            addAll(hybridTransactionTypes);
          }
        }}
        disabled={disabled}
      >
        {hybridTransactionTypes.map((transactionType) => (
          <FilterMultiOption
            key={`option-${transactionType}`}
            selected={selectedTypes.has(transactionType)}
            onChange={() => handleMultiOptionChange(transactionType)}
            disabled={disabled}
          >
            {getTransactionTypeMessage(transactionType, intl, signTransactionsEnabled)}
          </FilterMultiOption>
        ))}
      </FilterGroup>
      <FilterMultiOption
        selected={selectedTypes.has(MortgageTransactionType.wet_sign)}
        onChange={() => {
          if (!selectedTypes.has(MortgageTransactionType.wet_sign)) {
            dashboardSegmentTrack(SEGMENT_EVENTS.TRANSACTION_TYPE_FILTER_SELECTED, {
              value: MortgageTransactionType.wet_sign,
            });
          }
          toggleSelection(MortgageTransactionType.wet_sign);
        }}
        disabled={disabled}
      >
        <FormattedMessage id="90e21a96-5473-4955-b635-ed7fc2059bbe" defaultMessage="All wet sign" />
      </FilterMultiOption>
    </FilterDropdown>
  );
}

export function ClosingDateFilter(props: ClosingDateFilterProps) {
  const { disabled, selectedConstraint, setSelectedConstraint } = props;

  const dashboardSegmentTrack = useDashboardSegmentTrack();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FilterDropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hasSelection={Boolean(selectedConstraint)}
      unselectedLabel={
        <FormattedMessage
          id="a7568250-0906-4cf6-942c-65d6e4335df8"
          defaultMessage="Closing anytime"
        />
      }
      label={
        selectedConstraint && !isCustomDateConstraint(selectedConstraint) ? (
          <SelectedFiltersLabel label={DateConstraintLabels[selectedConstraint]} />
        ) : null
      }
      onSelectAll={() => {
        setSelectedConstraint(null);
      }}
      disabled={disabled}
    >
      <FilterOption
        selected={selectedConstraint === "today"}
        onChange={() => {
          if (selectedConstraint !== "today") {
            dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
              value: "today",
            });
            setSelectedConstraint("today");
          }
        }}
        disabled={disabled}
      >
        {DateConstraintLabels.today}
      </FilterOption>
      <FilterOption
        selected={selectedConstraint === "tomorrow"}
        onChange={() => {
          if (selectedConstraint !== "tomorrow") {
            dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
              value: "tomorrow",
            });
            setSelectedConstraint("tomorrow");
          }
        }}
        disabled={disabled}
      >
        {DateConstraintLabels.tomorrow}
      </FilterOption>
      <FilterOption
        selected={selectedConstraint === "next7days"}
        onChange={() => {
          if (selectedConstraint !== "next7days") {
            dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
              value: "next7days",
            });
            setSelectedConstraint("next7days");
          }
        }}
        disabled={disabled}
      >
        {DateConstraintLabels.next7days}
      </FilterOption>
      <FilterOption
        selected={selectedConstraint === "previous7days"}
        onChange={() => {
          if (selectedConstraint !== "previous7days") {
            dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
              value: "previous7days",
            });
            setSelectedConstraint("previous7days");
          }
        }}
        disabled={disabled}
      >
        {DateConstraintLabels.previous7days}
      </FilterOption>
      <FilterOption
        selected={selectedConstraint === "thismonth"}
        onChange={() => {
          if (selectedConstraint !== "thismonth") {
            dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
              value: "thismonth",
            });
            setSelectedConstraint("thismonth");
          }
        }}
        disabled={disabled}
      >
        {DateConstraintLabels.thismonth}
      </FilterOption>
      <FilterOption
        selected={selectedConstraint === "previousmonth"}
        onChange={() => {
          if (selectedConstraint !== "previousmonth") {
            dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
              value: "previousmonth",
            });
            setSelectedConstraint("previousmonth");
          }
        }}
        disabled={disabled}
      >
        {DateConstraintLabels.previousmonth}
      </FilterOption>
    </FilterDropdown>
  );
}

export function BusinessDashboardDateFilter(props: Omit<DateFilterProps, "renderFilterOptions">) {
  const {
    disabled,
    selectedConstraint,
    setSelectedConstraint,
    "data-automation-id": dataAutomationId,
  } = props;
  const options = [
    "today" as const,
    "previous7days" as const,
    "thismonth" as const,
    "previousmonth" as const,
  ];
  return (
    <DateFilter
      {...props}
      data-automation-id={dataAutomationId}
      renderFilterOptions={(setDateRangePickerOpen) => (
        <>
          {options.map((date) => {
            return (
              <FilterOption
                key={date}
                data-automation-id={`filter-option-${date}`}
                selected={selectedConstraint === date}
                onChange={() => {
                  if (selectedConstraint !== date) {
                    setSelectedConstraint(date);
                  }
                }}
                disabled={disabled}
              >
                {DateConstraintLabels[date]}
              </FilterOption>
            );
          })}
          <FilterOption
            selected={isCustomDateConstraint(selectedConstraint)}
            onChange={() => {
              setDateRangePickerOpen(true);
            }}
            disabled={disabled}
            data-automation-id={`filter-option-custom`}
          >
            {DateConstraintLabels.custom}
          </FilterOption>
        </>
      )}
    />
  );
}

export function ClosingDateFilterV2(
  props: Omit<DateFilterProps, "renderFilterOptions" | "label" | "data-automation-id">,
) {
  const { disabled, selectedConstraint, setSelectedConstraint } = props;
  const dashboardSegmentTrack = useDashboardSegmentTrack();
  const options = [
    "today" as const,
    "tomorrow" as const,
    "next7days" as const,
    "previous7days" as const,
    "thismonth" as const,
    "previousmonth" as const,
  ];
  return (
    <DateFilter
      {...props}
      data-automation-id="filter-dropdown-closing-date"
      label={
        <FormattedMessage
          id="79785666-8a37-40ec-9190-700e07043ae0"
          defaultMessage="Closing anytime"
        />
      }
      renderFilterOptions={(setDateRangePickerOpen) => (
        <>
          {options.map((date) => {
            return (
              <FilterOption
                key={date}
                selected={selectedConstraint === date}
                data-automation-id={`filter-option-${date}`}
                onChange={() => {
                  if (selectedConstraint !== date) {
                    dashboardSegmentTrack(SEGMENT_EVENTS.CLOSING_DATE_FILTER_SELECTED, {
                      value: date,
                    });
                    setSelectedConstraint(date);
                  }
                }}
                disabled={disabled}
              >
                {DateConstraintLabels[date]}
              </FilterOption>
            );
          })}
          <FilterOption
            selected={isCustomDateConstraint(selectedConstraint)}
            onChange={() => {
              setDateRangePickerOpen(true);
            }}
            disabled={disabled}
            data-automation-id={`filter-option-custom`}
          >
            {DateConstraintLabels.custom}
          </FilterOption>
        </>
      )}
    />
  );
}

function DateFilter(props: DateFilterProps) {
  const {
    label,
    disabled,
    selectedConstraint,
    setSelectedConstraint,
    renderFilterOptions,
    "data-automation-id": dataAutomationId,
  } = props;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [dateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const initialDateRange: [Date, Date] | null = isCustomDateConstraint(selectedConstraint)
    ? [new Date(selectedConstraint.startDate), new Date(selectedConstraint.endDate)]
    : null;

  const [dateLabel, setDateLabel] = useState(
    initialDateRange ? getDateRangeLabel(initialDateRange) : null,
  );

  const selectedLabel = isCustomDateConstraint(selectedConstraint) ? (
    <SelectedFiltersLabel floatingLabel={label} label={dateLabel} />
  ) : (
    <SelectedFiltersLabel label={DateConstraintLabels[selectedConstraint!]} />
  );

  const popup = (
    <DashboardPopup
      aria-label={intl.formatMessage(messages.dateRangePopupLabel)}
      footerButtons={
        <>
          <Button
            onClick={() => setDateRangePickerOpen(false)}
            variant="tertiary"
            buttonColor="action"
          >
            <FormattedMessage id="10ec1885-78f4-4c79-839b-be98e50a5892" defaultMessage="Cancel" />
          </Button>
          <Button
            buttonColor="action"
            variant="primary"
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            disabled={!dateRange?.[0] || !dateRange?.[1]}
            onClick={() => {
              setDateLabel(getDateRangeLabel(dateRange));
              setSelectedConstraint({
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
              });
              setIsOpen(false);
              setDateRangePickerOpen(false);
            }}
          >
            <FormattedMessage id="c07f8faa-06c4-42f1-a466-974a7ca72add" defaultMessage="Save" />
          </Button>
        </>
      }
    >
      <DateRangePicker initialValue={initialDateRange} onChange={setDateRange} />
    </DashboardPopup>
  );

  return (
    <FilterDropdown
      disabled={disabled}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hasSelection={Boolean(selectedConstraint)}
      label={selectedLabel}
      unselectedLabel={label}
      popup={dateRangePickerOpen && popup}
      onSelectAll={() => {
        setSelectedConstraint(null);
      }}
      data-automation-id={dataAutomationId}
    >
      {renderFilterOptions(setDateRangePickerOpen)}
    </FilterDropdown>
  );
}

type OrderProgressFilterProps = {
  disabled: boolean;
  clearSelection: () => void;
  toggleSelection: (arg: OrganizationTransactionLabels) => void;
  selectedOrderProgressTypes: Set<OrganizationTransactionLabels>;
  addAll: (args: OrganizationTransactionLabels[]) => void;
  removeAll: (args: OrganizationTransactionLabels[]) => void;
  availableTransactionLabels?: OrganizationTransactionLabels[];
};

export function OrderProgressFilter(props: OrderProgressFilterProps) {
  const {
    disabled,
    selectedOrderProgressTypes,
    toggleSelection,
    clearSelection,
    availableTransactionLabels = [],
  } = props;
  const unselectedLabel = (
    <FormattedMessage
      id="5eb87afa-9286-44db-948c-b7bce6c293ba"
      defaultMessage="All progress types"
    />
  );
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FilterDropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hasSelection={Boolean(selectedOrderProgressTypes.size)}
      unselectedLabel={unselectedLabel}
      label={
        <SelectedFiltersLabel
          count={selectedOrderProgressTypes.size}
          label={
            <FormattedMessage
              id="439889be-3f84-4cbb-8a08-19029f753aeb"
              defaultMessage="Progress types"
            />
          }
        />
      }
      onSelectAll={clearSelection}
      disabled={disabled}
    >
      {availableTransactionLabels.map((type) => {
        return (
          <FilterMultiOption
            key={`option-${type}`}
            selected={selectedOrderProgressTypes.has(type)}
            onChange={() => {
              toggleSelection(type);
            }}
            disabled={disabled}
          >
            {ORDER_PROGRESS_LABEL_MAP[type]}
          </FilterMultiOption>
        );
      })}
    </FilterDropdown>
  );
}

export function getActiveFiltersCount({
  query,
  detailedStatuses,
  transactionTypes,
  createdDateStart,
  createdDateEnd,
  completedDateStart,
  completedDateEnd,
  closingDateStart,
  closingDateEnd,
  labels,
}: {
  query?: string | null;
  detailedStatuses?: OrganizationTransactionDetailedStatus[];
  transactionTypes?: MortgageTransactionType[];
  createdDateStart?: string | null;
  createdDateEnd?: string | null;
  completedDateStart?: string | null;
  completedDateEnd?: string | null;
  closingDateStart?: string | null;
  closingDateEnd?: string | null;
  labels?: OrganizationTransactionLabels[];
  subSection?: TransactionSubsectionNamespace | null;
}) {
  let activeFiltersCount = 0;
  if (query) {
    activeFiltersCount++;
  }
  if (detailedStatuses && detailedStatuses.length > 0) {
    activeFiltersCount++;
  }
  if (transactionTypes && transactionTypes.length > 0) {
    activeFiltersCount++;
  }
  if (closingDateStart || closingDateEnd) {
    activeFiltersCount++;
  }
  if (createdDateStart || createdDateEnd) {
    activeFiltersCount++;
  }
  if (completedDateStart || completedDateEnd) {
    activeFiltersCount++;
  }
  if (labels && labels.length > 0) {
    activeFiltersCount++;
  }
  return activeFiltersCount;
}

type ClearAllButtonProps = {
  activeFiltersCount: number;
  onClick: () => void;
  className?: string;
};

export function ClearAllButton({ activeFiltersCount, onClick, className }: ClearAllButtonProps) {
  return (
    <Button
      buttonColor="dark"
      variant="tertiary"
      buttonSize="condensed"
      withIcon={{ name: "x-mark-circle", placement: "left" }}
      onClick={onClick}
      className={className}
      automationId="clear-all-filters-button"
    >
      <FormattedMessage
        id="76337b4d-00b9-40c4-8142-f618b0a0c22b"
        defaultMessage="Clear all filters ({activeFiltersCount})"
        values={{ activeFiltersCount }}
      />
    </Button>
  );
}
