import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type DocumentUpdatedActivityLogFragment = ActivityLog & {
  properties: {
    document_name: string | null;
    new_document_id: string | number | null;
    old_document_id: string | number | null;
    external_document_id: string | number | null;
  };
};
type Props = {
  activityLog: DocumentUpdatedActivityLogFragment;
};

export const DocumentUpdatedLabel = memo(() => {
  return (
    <FormattedMessage id="8af20c46-8836-4ed5-8687-b3830804dd50" defaultMessage="Document Updated" />
  );
});

export const DocumentUpdatedSummary = memo(
  ({
    activityLog: {
      properties: { document_name: documentName },
    },
  }: Props) => {
    return (
      <FormattedMessage
        id="6e965cf5-e78d-43c9-9b92-3e2a3adc8ca1"
        defaultMessage="Name: {documentName}"
        values={{ documentName }}
      />
    );
  },
);

export const DocumentUpdatedDetails = memo(
  ({
    activityLog: {
      properties: {
        document_name: documentName,
        new_document_id: newDocumentId,
        old_document_id: oldDocumentId,
        external_document_id: externalDocumentId,
      },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        <div
          className="ActivityLogItemDetailType--list-item"
          data-automation-id="transaction_details"
        >
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="47090f03-1ab4-464b-9295-0ae6ac9c4141"
              defaultMessage="Document Name"
            />
            :
          </span>
          <span key="documentName" className="ActivityLogItemDetailType--nonRangeValue">
            {documentName}
          </span>
        </div>
        <div
          className="ActivityLogItemDetailType--list-item"
          data-automation-id="transaction_details"
        >
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="2220e8d4-2255-4c6d-bb12-983646345e7b"
              defaultMessage="New Document ID"
            />
            :
          </span>
          <span key="documentName" className="ActivityLogItemDetailType--nonRangeValue">
            {newDocumentId}
          </span>
        </div>
        <div
          className="ActivityLogItemDetailType--list-item"
          data-automation-id="transaction_details"
        >
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="e4f61984-34b0-44bf-90a3-eea6af268ce5"
              defaultMessage="Old Document ID"
            />
            :
          </span>
          <span key="documentName" className="ActivityLogItemDetailType--nonRangeValue">
            {oldDocumentId}
          </span>
        </div>
        <div
          className="ActivityLogItemDetailType--list-item"
          data-automation-id="transaction_details"
        >
          <span className="ActivityLogItemDetailType--label">
            <FormattedMessage
              id="c271fa27-eef2-48ac-bf74-91df87d76d9e"
              defaultMessage="External Document ID"
            />
            :
          </span>
          <span key="documentName" className="ActivityLogItemDetailType--nonRangeValue">
            {externalDocumentId}
          </span>
        </div>
      </div>
    );
  },
);
