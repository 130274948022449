import { FormattedMessage } from "react-intl";

import { Badge } from "common/core/badge";

import Styles from "./witness_label.module.scss";

export function WitnessLabel({ witnessBundleIndex }: { witnessBundleIndex: string }) {
  const witnessNumber = witnessBundleIndex.charAt(witnessBundleIndex.length - 1);
  return (
    <Badge className={Styles.badge} kind={witnessNumber === "1" ? "infoBlue" : "infoSubtle"}>
      <div className={Styles.badgeContents}>
        <FormattedMessage
          id="660f3750-04fc-4e6c-ad47-7db6293d2ade"
          defaultMessage="Witness {witnessBundleIndex}"
          values={{ witnessBundleIndex: witnessNumber }}
        />
      </div>
    </Badge>
  );
}
