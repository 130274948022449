import { FormattedMessage } from "react-intl";

import { Card, CardHeading } from "common/core/card";
import { Substyle } from "common/core/typography";

import WitnessInfo from "./witness_info";
import type {
  MeetingForWitnessDetail as Meeting,
  MeetingForWitnessDetail_meetingParticipants as MeetingParticipant,
  MeetingForWitnessDetail_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./witness_detail_fragment.graphql";

type Props = {
  meeting: Meeting;
};

function isWitness(participant: MeetingParticipant): participant is WitnessParticipant {
  return (
    participant.__typename === "WitnessParticipant" ||
    participant.__typename === "IdentityVerifiedWitnessParticipant"
  );
}

function WitnessDetail({ meeting }: Props) {
  const witnesses = meeting.meetingParticipants.filter(isWitness);

  if (witnesses.length === 0) {
    return null;
  }

  return (
    <Card
      fullWidth
      noMargin
      header={
        <CardHeading level="h2">
          <FormattedMessage id="d709ad85-7800-4877-aa0a-95114d1a23f0" defaultMessage="Witness" />
        </CardHeading>
      }
    >
      {witnesses.map((witness, index) => (
        <div key={witness.id}>
          {witnesses.length > 1 && (
            <Substyle textStyle="allCapsLabelSmall">
              <FormattedMessage
                id="ba6541aa-8075-4c32-8d98-e11740321455"
                defaultMessage="Witness {witnessNumber, number}"
                values={{ witnessNumber: index + 1 }}
              />
            </Substyle>
          )}
          <WitnessInfo witness={witness} />
        </div>
      ))}
    </Card>
  );
}

export default WitnessDetail;
