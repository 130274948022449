import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { useDispatch } from "redux/util";
import Button from "common/core/button";
import { Heading, Paragraph } from "common/core/typography";
import { forgotPassword } from "redux/actions/authentication";

import { BackButton } from "./common";
import Styles from "./index.module.scss";
import type { EmailScreenType } from "./email";
import type { ResetPasswordScreenType } from "./reset_password";
import ProofCard from "../card";

type LoginAttemptsProps = {
  onNextScreen: (screen: ResetPasswordScreenType | EmailScreenType) => void;
  email: string;
  showCard?: boolean;
  wrapper?: (children: ReactNode) => ReactNode;
  onBack: () => void;
};

export type LoginAttemptsExceededScreenType = {
  type: "login_attempts_exceeded";
  email: string;
};

function LoginAttemptsExceeded({
  email,
  onNextScreen,
}: Pick<LoginAttemptsProps, "email" | "onNextScreen">) {
  const dispatch = useDispatch();

  return (
    <div className={Styles.loginAttemptsExceededContainer}>
      <div>
        <Heading
          level="h1"
          textStyle="headingFour"
          textAlign="center"
          className={Styles.mainHeading}
        >
          <FormattedMessage
            id="9b3ef3c1-dd28-4019-8cc7-a2dcfcd2eb15"
            defaultMessage="Check your email"
          />
        </Heading>
        <Paragraph className={Styles.bodySection}>
          <FormattedMessage
            id="92ed7ce3-9077-4c53-86b3-07d63ce95a1e"
            defaultMessage="There have been too many unsuccessful login attempts. Use the password reset link in your email to unlock your account."
          />
        </Paragraph>
      </div>

      <Button
        fullwidth
        buttonColor="action"
        buttonSize="large"
        variant="primary"
        onClick={() => {
          dispatch(forgotPassword(email));
          onNextScreen({ type: "reset_password", email });
        }}
      >
        <FormattedMessage id="b71df70a-f710-41a2-aec1-385fef35431d" defaultMessage="Resend Link" />
      </Button>
    </div>
  );
}

export default function LoginAttemptsExceededScreen({
  onNextScreen,
  email,
  showCard,
  wrapper,
  onBack,
}: LoginAttemptsProps) {
  const content = <LoginAttemptsExceeded email={email} onNextScreen={onNextScreen} />;

  if (showCard) {
    return (
      <ProofCard
        body={<LoginAttemptsExceeded email={email} onNextScreen={onNextScreen} />}
        footer={
          <div className={Styles.footerCentered}>
            <BackButton onClick={onBack} />
          </div>
        }
      />
    );
  }
  return <>{wrapper ? wrapper(content) : content}</>;
}
