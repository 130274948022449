import "./index.scss";
import classnames from "classnames";

type SubFormProps = {
  className?: string;
  children: React.ReactNode;
};

/**
 * A SubForm is a wrapper component for any form elements that fall under
 * one TransactionCreationPageSection section. It should contain one or multiple SubFormSection
 * components as its children.
 *
 * It serves as the primary structural unit of the new form layouts.
 *
 * @param {String} className
 * @param {Array<Node>} children
 */

/** @deprecated - please use components in common/core/form */
export const DeprecatedSubForm = ({ className, children }: SubFormProps) => {
  return <div className={classnames("SubForm", className)}>{children}</div>;
};
