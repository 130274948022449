import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useEffect, useRef } from "react";

import { SigningRequirementEnum } from "graphql_globals";
import type { UseForm, FormValues } from "common/transaction_creation/v3/form";
import {
  CardDivider,
  ConfiguredCheckboxGroup,
  showField,
} from "common/transaction_creation/v3/common";
import {
  type Config,
  type ConfiguredField,
  PROOF_CONFIG,
} from "common/transaction_creation/v3/config";
import { type FieldPath, useWatch } from "common/core/form";
import { useNestedError } from "common/core/form/error";
import {
  Ial2CheckboxLabel,
  SmsAuthCheckboxLabel,
  KbaCheckboxLabel,
  ProofRequirementFormValues,
  type ProofRequirementField,
  CaSelfieCheckboxLabel,
} from "common/proof_requirements/common";
import Link from "common/core/link";
import { SUPPORT_HOST } from "constants/support";
import { Paragraph } from "common/core/typography";
import { BinaryToggle } from "common/core/form/binary_toggle";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { useId } from "util/html";

import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  identify: {
    id: "c9946353-1f8a-4756-b797-d53a5f1ba15a",
    defaultMessage: "Add identity verification",
  },
  proofToggle: {
    id: "4751243b-5822-44dd-a5f3-3af268479a89",
    defaultMessage: "Add or remove identity verification",
  },
  proofRequirementsError: {
    id: "e2e9f9c0-8b9a-4b9e-9b9a-9b9a9b9a9b9a",
    defaultMessage: "Please select at least one identity verification option",
  },
});

export function ProofRequirements({
  config,
  configField,
  index,
  name,
  form,
  requiredOverride,
  makeAutomationId,
}: {
  config: Config;
  configField: ConfiguredField;
  form: UseForm;
  name: Extract<FieldPath<FormValues>, "recipients">;
  index: number;
  requiredOverride?: true;
  makeAutomationId: (field: string) => string;
}) {
  const intl = useIntl();

  const recipientProofRequirement = `${name}.${index}.proofRequirement` as FieldPath<FormValues>;
  const recipientShowProofRequirements =
    `${name}.${index}.showProofRequirements` as FieldPath<FormValues>;
  const recipientSigningRequirement =
    `${name}.${index}.signingRequirement` as FieldPath<FormValues>;

  const proofRequirement = useWatch({
    control: form.control,
    name: recipientProofRequirement,
  }) as ProofRequirementField;

  const showProofRequirements = useWatch({
    control: form.control,
    name: recipientShowProofRequirements,
  });

  const signingRequirement = useWatch({
    control: form.control,
    name: recipientSigningRequirement,
  });

  const ial2Selected = proofRequirement.includes(ProofRequirementFormValues.IAL2);
  const IAL2_BOUND_REQUIREMENTS = [
    ProofRequirementFormValues.SMS,
    ProofRequirementFormValues.CA_SELFIE,
  ];
  const REQUIREMENT_CHECKBOXES = {
    [ProofRequirementFormValues.IAL2]: {
      key: 2,
      label: <Ial2CheckboxLabel />,
      value: ProofRequirementFormValues.IAL2,
      "data-automation-id": makeAutomationId("ial2"),
    },
    [ProofRequirementFormValues.CA_SELFIE]: {
      key: 0,
      label: <CaSelfieCheckboxLabel />,
      value: ProofRequirementFormValues.CA_SELFIE,
      disabledOverride: ial2Selected || undefined,
      "data-automation-id": makeAutomationId("ca-selfie"),
    },
    [ProofRequirementFormValues.SMS]: {
      key: 1,
      label: <SmsAuthCheckboxLabel />,
      value: ProofRequirementFormValues.SMS,
      disabledOverride: ial2Selected || undefined,
      "data-automation-id": makeAutomationId("sms"),
    },
    [ProofRequirementFormValues.KBA]: {
      key: 3,
      label: <KbaCheckboxLabel />,
      value: ProofRequirementFormValues.KBA,
      "data-automation-id": makeAutomationId("kba"),
    },
  };

  // There are two cases
  // 1. Only show proof requirements if default values on recipient or previously saved
  // 2. Identity transaction type - we always default proof requirements to on
  useEffect(() => {
    form.setValue(
      recipientShowProofRequirements,
      proofRequirement.length > 0 || config.requireProofSigner,
    );
  }, []);

  const toggleShowProofRequirements = () => {
    form.setValue(recipientShowProofRequirements, !showProofRequirements);
    form.setValue(recipientProofRequirement, []);
  };

  const hasRendered = useRef(false);
  // SMS and CA/Selfie must be checked when IAL2 is checked, and unchecked when IAL2 is unchecked
  useEffect(() => {
    let requirements = proofRequirement;
    // Prevent form updates on first render
    if (!hasRendered.current) {
      hasRendered.current = true;
      return;
    }
    if (ial2Selected) {
      const shouldAddRequirement = (requirement: ProofRequirementFormValues) =>
        !proofRequirement.includes(requirement) &&
        config.transactionProofRequirements.includes(requirement);
      const requirementsToAdd = IAL2_BOUND_REQUIREMENTS.filter(shouldAddRequirement);
      requirements.push(...requirementsToAdd);
    } else {
      requirements = requirements.filter(
        (requirement) => !IAL2_BOUND_REQUIREMENTS.includes(requirement),
      );
    }
    form.setValue(recipientProofRequirement, requirements);
    // setValue is not triggering a re-render to get rid of the error, so re-validate
    form.trigger(recipientProofRequirement);
  }, [ial2Selected]);

  const error = useNestedError({ control: form.control, name: recipientProofRequirement });
  const proofCheckboxesId = useId();

  // notarization signer should not display proof requirements
  if (
    !(
      showField(config, configField) &&
      (config.requireProofSigner ||
        config.optionalProofSigner ||
        signingRequirement === SigningRequirementEnum.ESIGN)
    )
  ) {
    return null;
  }

  const checkboxes = config.transactionProofRequirements
    .map((req) => REQUIREMENT_CHECKBOXES[req])
    .sort((a, b) => a.key - b.key);

  //TODO Remove config.name !== PROOF_CONFIG.name with sign-transactions removal
  const forceProofRequirement = config.requireProofSigner && config.name !== PROOF_CONFIG.name;
  return (
    <>
      <CardDivider />
      <div className={Styles.headingWrapper}>
        {intl.formatMessage(MESSAGES.identify)}
        {forceProofRequirement ? (
          <RequiredAsterisk />
        ) : (
          <BinaryToggle
            className={Styles.toggle}
            aria-label={intl.formatMessage(MESSAGES.proofToggle)}
            onChange={toggleShowProofRequirements}
            value={!!showProofRequirements}
            size="small"
            automationId={makeAutomationId("identity-verification-toggle")}
          />
        )}
      </div>
      <div className={Styles.subtitleWrapper}>
        <Paragraph id={proofCheckboxesId} textColor="subtle">
          {
            <FormattedMessage
              id="5637f1cd-d0fb-4550-9f5c-5b2b2f507e76"
              defaultMessage="Proof uses enhanced checks to validate a signer's identity. <link>Learn more</link>"
              values={{
                link: (text) => (
                  <Link href={`${SUPPORT_HOST}/hc/en-us/articles/8706936036887`} underlined>
                    {text}
                  </Link>
                ),
              }}
            />
          }
        </Paragraph>
      </div>

      {showProofRequirements ? (
        <Paragraph className={Styles.proofSection}>
          <ConfiguredCheckboxGroup
            aria-labelledby={proofCheckboxesId}
            groupError={error}
            config={config}
            configField={configField}
            form={form}
            name={recipientProofRequirement}
            requiredOverride={requiredOverride}
            customErrorMsg={intl.formatMessage(MESSAGES.proofRequirementsError)}
            checkboxes={checkboxes}
          />
        </Paragraph>
      ) : null}
    </>
  );
}
