import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import EmptyMeeting from "assets/images/no-meeting-state.svg";
import { dateComparator } from "util/date";
import { durationString } from "util/meeting";
import { usePermissions } from "common/core/current_user_role";
import { Feature } from "graphql_globals";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import { Heading, Substyle } from "common/core/typography";
import { Card } from "common/core/card";

import type {
  OrganizationTransactionDetailsForMeeting as OrganizationTransaction,
  OrganizationTransactionDetailsForMeeting_bundle as DocumentBundle,
} from "./index_fragment.graphql";
import { NotaryDetailsContainer } from "./notary_details";
import { VideosContainer } from "./videos";
import { MeetingRiskLevelBadge } from "./videos/deepfake/common";
import WitnessDetail from "./witness_details/witness_detail";
import Styles from "./index.module.scss";

const videoMessages = defineMessages({
  meetingHeader: {
    id: "2d60413f-dd92-4819-9737-6743eae062fc",
    defaultMessage: "Meeting {index, number} - {meetingId}",
  },
  meetingInProgressHeader: {
    id: "9f4472b5-4914-43f2-bfd0-448cc4ceee4d",
    defaultMessage: "Meeting in progress",
  },
  emptyContent: {
    id: "894a283c-c125-461e-a520-0e280b4bf508",
    defaultMessage:
      "This transaction has no meetings or is still processing, please come back later",
  },
  noPermissionContent: {
    id: "f04b6eee-253c-48a9-a70c-cb2d2adb86c9",
    defaultMessage:
      "Your role in Keystone does not have permission to view this asset. Please reach out to your manager if you feel you need access.",
  },
});

type Props = {
  refetch: () => Promise<unknown>;
  transaction: OrganizationTransaction;
  bundle: DocumentBundle;
};

export function MeetingContainer({ refetch, transaction, bundle }: Props) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;
  const showFullRecord = transaction.publicOrganization.featureList.includes(
    Feature.TRANSACTION_RECORD_FULL_ACCESS,
  );

  useEffect(() => {
    // This allows user to click on the tab again to refresh the content since sometimes this fragment isn't loaded
    // after meeting is completed
    refetch();
  }, []);

  const meetings = bundle.meetings.edges
    .map((m) => m.node)
    .sort((a, b) => {
      if (b.timeFrame?.startedAt && a.timeFrame?.startedAt) {
        return dateComparator(b.timeFrame.startedAt, a.timeFrame.startedAt);
      } else if (a.timeFrame?.startedAt && !b.timeFrame?.startedAt) {
        return 1;
      }
      return -1;
    });

  if (meetings.some((meeting) => meeting.video?.recording?.url?.includes("restricted-id"))) {
    return (
      <Card fullWidth className={Styles.emptyContainer}>
        <div className={Styles.emptyContent}>
          <div className={Styles.emptyText}>
            {intl.formatMessage(videoMessages.noPermissionContent)}
          </div>
        </div>
      </Card>
    );
  }

  if (meetings.length === 0) {
    return (
      <Card fullWidth className={Styles.emptyContainer}>
        <div className={Styles.emptyContent}>
          <img className={Styles.emptyImage} alt="" src={EmptyMeeting} />
          <div className={Styles.emptyText}>{intl.formatMessage(videoMessages.emptyContent)}</div>
        </div>
      </Card>
    );
  }

  return (
    <CollapsibleList>
      {meetings.map((meeting, index) => {
        const isLiveMeeting = Boolean(meeting.video?.token);

        if (isLiveMeeting && !hasPermissionFor("observeMeeting")) {
          return null;
        }

        const headerText = isLiveMeeting
          ? intl.formatMessage(videoMessages.meetingInProgressHeader, {
              index: meetings.length - index,
            })
          : intl.formatMessage(videoMessages.meetingHeader, {
              index: meetings.length - index,
              meetingId: meeting.id,
            });

        const fullHeader = (
          <Heading textStyle="subtitle" level="h2" className={Styles.meetingHeader}>
            <span>{headerText}</span>
            <Substyle textStyle="subtitleSmall" textColor="subtle" size="small">
              {durationString(meeting)}
            </Substyle>
            <MeetingRiskLevelBadge
              className={Styles.riskBadge}
              meetingAnalysis={meeting.meetingAnalysis}
            />
          </Heading>
        );

        return (
          <CollapsibleListItem header={fullHeader} key={meeting.id}>
            <div className={Styles.meetingLayoutContainer}>
              {((showFullRecord && hasPermissionFor("viewTransactionPii")) || isKeystone) && (
                <div
                  className={Styles.videoContainer}
                  data-automation-id="meeting-tab-video-container"
                >
                  <VideosContainer bundle={bundle} meeting={meeting} />
                </div>
              )}
              <div className={Styles.notaryWitnessContainer}>
                <NotaryDetailsContainer refetch={refetch} meeting={meeting} />
                <WitnessDetail meeting={meeting} />
              </div>
            </div>
          </CollapsibleListItem>
        );
      })}
    </CollapsibleList>
  );
}
