import { FormattedMessage } from "react-intl";

import { PhotoIdVerificationStatus, PhotoIdVerificationVerificationType } from "graphql_globals";
import { FormattedPhotoIdStatus } from "common/core/format/formatted_photo_id_status";
import { DescriptionListItem } from "common/core/description_list";

import type { BiometricVerificationResult as PhotoId } from "./index_fragment.graphql";

type Props = {
  photoId: PhotoId;
};

function getMessage(failedVerificationType: PhotoIdVerificationVerificationType) {
  switch (failedVerificationType) {
    case PhotoIdVerificationVerificationType.SELFIE:
      return (
        <FormattedMessage
          id="773dc241-c7b1-455b-a99d-a5dd46bbdcc1"
          defaultMessage="invalid biometric"
        />
      );
    case PhotoIdVerificationVerificationType.ID:
      return (
        <FormattedMessage
          id="06a2ca29-da3b-400a-9885-467d8dc5cafa"
          defaultMessage="invalid credential"
        />
      );
  }
}

export function BiometricVerificationResults({ photoId }: Props) {
  const { statusV2, failedVerificationTypes } = photoId;

  const biometricVerificationLabel = (
    <FormattedMessage
      id="b18b2380-ad49-4abd-9c9e-9f73e6009cd6"
      defaultMessage="Verification Results"
    />
  );

  const biometricVerificationContent = (
    <>
      <FormattedPhotoIdStatus status={statusV2} />
      {statusV2 === PhotoIdVerificationStatus.FAILURE && (
        <>
          {" "}
          (
          {failedVerificationTypes.map((type, index) => (
            <>
              {getMessage(type)}
              {failedVerificationTypes.length > 1 &&
                index < failedVerificationTypes.length - 1 &&
                ", "}
            </>
          ))}
          )
        </>
      )}
    </>
  );

  return (
    <DescriptionListItem
      term={biometricVerificationLabel}
      definition={biometricVerificationContent}
    />
  );
}
