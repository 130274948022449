import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { Subject, fromEvent, takeUntil } from "rxjs";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";

import { ScreenClassRender } from "common/core/responsive";
import { IconButton } from "common/core/button/icon_button";
import { deprecatedInteractingWithInput } from "common/form/util";
import ActionButton from "common/core/action_button";
import { useA11y } from "common/accessibility";
import { documentMenuKey } from "common/document_bundle/menu.complex";

const MESSAGES = defineMessages({
  previous: {
    id: "be42020a-a9ec-47c5-916b-dbe75ee325a7",
    defaultMessage: "Previous Document",
  },
  next: {
    id: "0e02952e-93e1-4a3e-9432-9311d8984f10",
    defaultMessage: "Next Document",
  },
});

class DocumentNavigator extends Component {
  constructor(props) {
    super(props);
    this.unmounted$ = new Subject();
  }

  componentDidMount() {
    fromEvent(window, "keydown").pipe(takeUntil(this.unmounted$)).subscribe(this.handleKeyDown);
  }

  componentWillUnmount() {
    this.unmounted$.next();
    this.unmounted$.unsubscribe();
  }

  handleKeyDown = (e) => {
    if (deprecatedInteractingWithInput()) {
      return;
    }
    if (e.key === "ArrowRight") {
      this.onNextDocument();
    } else if (e.key === "ArrowLeft") {
      this.onPrevDocument();
    }
  };

  getCurrentDocumentIndex = () => {
    const documents = this.props.bundle.documents;

    return (documents.edges || []).findIndex((edge) => {
      return edge.node.id === this.props.selectedDocument.id;
    });
  };

  onNextDocument = () => {
    const documents = this.props.bundle.documents;
    const currentDocumentIndex = this.getCurrentDocumentIndex();
    const newDocumentIndex = currentDocumentIndex + 1;
    if (newDocumentIndex < documents.totalCount) {
      const newDocument = documents.edges[newDocumentIndex].node;
      this.props.onDocumentSelected(newDocument.id);
    }
  };

  onPrevDocument = () => {
    const documents = this.props.bundle.documents;
    const currentDocumentIndex = this.getCurrentDocumentIndex();
    const newDocumentIndex = currentDocumentIndex - 1;
    if (newDocumentIndex >= 0) {
      const newDocument = documents.edges[newDocumentIndex].node;
      this.props.onDocumentSelected(newDocument.id);
    }
  };

  // Returns null if there is no previous document
  getPreviousButton = () => {
    const currentDocumentIndex = this.getCurrentDocumentIndex();
    const isDisabled = currentDocumentIndex === 0;
    const className = classNames("DocumentNavigator--nav-icon", isDisabled ? "disabled" : "");

    return (
      <IconButton
        label={this.props.intl.formatMessage(MESSAGES.previous)}
        onClick={this.onPrevDocument}
        disabled={isDisabled}
        name="caret-left"
        key="document-bundle-prev-document-button"
        className={className}
        automationId="previous-button"
        variant="tertiary"
        buttonSize="condensed"
      />
    );
  };

  // Returns null if there is no next document
  getNextButton = () => {
    const documents = this.props.bundle.documents;
    const currentDocumentIndex = this.getCurrentDocumentIndex();
    const isDisabled = currentDocumentIndex === documents.totalCount - 1;
    const className = classNames("DocumentNavigator--nav-icon", isDisabled ? "disabled" : "");
    return (
      <IconButton
        label={this.props.intl.formatMessage(MESSAGES.next)}
        onClick={this.onNextDocument}
        disabled={isDisabled}
        name="caret-right"
        key="document-bundle-next-document-button"
        className={className}
        automationId="next-button"
        variant="tertiary"
        buttonSize="condensed"
      />
    );
  };

  render() {
    const { onDocumentListToggle, documentToggleSelected, documentMenuId } = this.props;
    const current = this.getCurrentDocumentIndex() + 1;
    const total = this.props.bundle.documents.totalCount;
    return (
      <ScreenClassRender
        render={(screenClass) => (
          <div className={`DocumentNavigator ${screenClass}`}>
            {this.getPreviousButton()}
            <div className="DocumentCounter" data-automation-id="doc-counter">
              <FormattedMessage
                id="822eb44d-599f-45d2-b980-6ad9f36ed54a"
                defaultMessage="Document {current} of {total}"
                tagName="span"
                values={{ current, total }}
              />
            </div>
            {this.getNextButton()}
            {!this.props.hideShowAllButton && (
              <ActionButton
                onClick={onDocumentListToggle}
                aria-pressed={documentToggleSelected}
                aria-expanded={documentToggleSelected}
                automationId="doc-toggle"
                aria-label={documentToggleSelected ? "Hide all documents" : "Show all documents"}
                className="ActionButton"
                aria-controls={documentMenuId}
              >
                <FormattedMessage
                  id="75ed96ee-72f9-4e91-8ef9-3f010af0e227"
                  defaultMessage="{message}"
                  tagName="span"
                  values={{ message: documentToggleSelected ? "Hide all" : "Show all" }}
                />
              </ActionButton>
            )}
          </div>
        )}
      />
    );
  }
}

DocumentNavigator.propTypes = {
  selectedDocument: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  bundle: PropTypes.shape({
    documents: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      ),
      totalCount: PropTypes.number,
    }),
  }).isRequired,
  onDocumentSelected: PropTypes.func.isRequired,
  onDocumentListToggle: PropTypes.func.isRequired,
  documentToggleSelected: PropTypes.bool,
  documentMenuId: PropTypes.string,
  hideShowAllButton: PropTypes.bool,
};

export default (props) => {
  const intl = useIntl();
  const { useLabelledOrDescribedBy } = useA11y();

  return (
    <DocumentNavigator
      {...props}
      intl={intl}
      documentMenuId={useLabelledOrDescribedBy(documentMenuKey)}
    />
  );
};
