import { FormattedMessage } from "react-intl";
import { type Dispatch, type SetStateAction, useState } from "react";

import { PopoutState } from "common/core/popout_menu/common";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import PopoutMenu from "common/core/popout_menu";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { redirectUrl } from "util/application_redirect";
import { hardNavigateTo } from "util/navigation";
import Apps from "constants/applications";
import type { OrganizationTypeEnum } from "graphql_globals";

import RemoveChildOrganizationWarning from "../modals/remove_child_organization_warning";
import AddChildOrganizationModal from "../modals/add_child_organization_modal";
import type { CompanyOrgStructureRoot_rootOrganization_Organization_subsidiaryOrganizations as RootSubsidiaryOrganization } from "../hierarchy_structure/graphql/index_root.query.graphql";

type SubsidiaryOrganizationWithChildren = RootSubsidiaryOrganization & {
  children: SubsidiaryOrganizationWithChildren[];
};

export function navigateToOrg(orgId: string, orgType: OrganizationTypeEnum) {
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  if (isAdminPortal) {
    window.open(`/companies/${orgId}/details`);
  } else {
    const subdomain = AppSubdomains[orgType.toLocaleLowerCase() as keyof typeof AppSubdomains];
    const url = redirectUrl(subdomain, `/set-active-org/${orgId}`);
    hardNavigateTo(url, { replace: true });
  }
}

export function RowPopoutMenu({
  organization,
  parentOrganization,
  rootOrganizationId,
  level,
  setItemSelected,
}: {
  organization: SubsidiaryOrganizationWithChildren;
  parentOrganization: { id: string; name?: string | null };
  rootOrganizationId: string;
  level: number;
  setItemSelected: Dispatch<SetStateAction<boolean>>;
}) {
  const [showUnlinkModal, updateShowUnlinkModal] = useState(false);
  const [showAddSubsidiaryModal, updateShowAddSubsidiaryModal] = useState(false);
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;

  return (
    <>
      <PopoutMenu
        placement="bottomRight"
        aria-label="Organization actions"
        automationId={`popout-menu-${organization.id}`}
        onStateChange={(state: PopoutState) => {
          setItemSelected(state !== PopoutState.CLOSED);
        }}
      >
        {() => (
          <>
            <PopoutMenuMultilineItem
              primaryContent={
                <FormattedMessage
                  id="97eb21c8-83e0-4d80-a31b-66d0da50e214"
                  defaultMessage="Go to organization"
                />
              }
              onClick={() => navigateToOrg(organization.id, organization.organizationType)}
              data-automation-id={`goto-org-${organization.id}`}
            />
            {isAdminPortal && (
              <PopoutMenuMultilineItem
                onClick={() => {
                  updateShowAddSubsidiaryModal(true);
                }}
                primaryContent={
                  <FormattedMessage
                    id="4ae9cd31-aab4-43d8-ae91-777bb0baa091"
                    defaultMessage="Add Subsidiary"
                  />
                }
                data-automation-id={`add-subsidiary-${organization.id}`}
              />
            )}
            {level !== 0 && isAdminPortal && (
              <PopoutMenuMultilineItem
                onClick={() => {
                  updateShowUnlinkModal(true);
                }}
                primaryContent={
                  <FormattedMessage
                    id="4c6485a1-de01-4fc1-b58f-5b0d5ef45a3d"
                    defaultMessage="Unlink"
                  />
                }
                data-automation-id={`unlink-subsidiary-${organization.id}`}
              />
            )}
          </>
        )}
      </PopoutMenu>
      {showUnlinkModal && (
        <RemoveChildOrganizationWarning
          parentOrganization={parentOrganization}
          childOrganization={organization}
          rootOrganizationId={rootOrganizationId}
          onClose={() => updateShowUnlinkModal(false)}
        />
      )}
      {showAddSubsidiaryModal && (
        <AddChildOrganizationModal
          parentOrganization={organization}
          rootOrganizationId={rootOrganizationId}
          onClose={() => {
            updateShowAddSubsidiaryModal(false);
          }}
        />
      )}
    </>
  );
}
