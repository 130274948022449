import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import SignerAddress from "common/signer/items/address";
import { MailtoLink } from "common/core/mailto_link";
import { usePermissions } from "common/core/current_user_role";
import { DescriptionListItem } from "common/core/description_list";

import type { CustomerSignerForDetails as CustomerSigner } from "./customer_signer_details_fragment.graphql";
import type { DocumentBundleForTransactionDetailsSigner_participants as Participant } from "./index_fragment.graphql";
import { SignerDetailsWrapper } from "./signer_details/signer_details_wrapper";
import { RecipientGroupDetails } from "./signer_details/recipient_group";

type Props = {
  customerSigner: CustomerSigner;
  participant: Participant | null | undefined;
  subheader: ReactNode;
  index?: number | null;
  expandInitially?: boolean;
};

function CustomerSignerDetails({
  customerSigner,
  participant,
  subheader,
  index,
  expandInitially = true,
}: Props) {
  const { hasPermissionFor } = usePermissions();
  const { email, address, alternativeNames, recipientGroup } = customerSigner;
  const recipientGroupEmail = recipientGroup?.sharedInboxEmail;

  const emailAddressLabel = (
    <FormattedMessage id="ca87d9a1-47b5-4d98-8fc3-2739628fff8a" defaultMessage="Email address" />
  );
  const emailDetails = (
    <DescriptionListItem
      term={emailAddressLabel}
      definition={<MailtoLink emailAddress={email!} />}
    />
  );

  return (
    <SignerDetailsWrapper
      order={index}
      user={participant || customerSigner}
      recipientGroupEmail={recipientGroupEmail}
      canShowEmail={false}
      subheader={subheader}
      expandInitially={expandInitially}
    >
      <SignerAddress address={address} />
      {recipientGroupEmail ? (
        <RecipientGroupDetails email={recipientGroupEmail} user={participant} mailToEmail />
      ) : (
        email && emailDetails
      )}
      {hasPermissionFor("signerAltNames") && alternativeNames.length > 0 && (
        <DescriptionListItem
          term={
            <FormattedMessage
              id="bfb6a78e-2b51-4b92-965f-7c55c39234f8"
              defaultMessage="Alternative Names"
            />
          }
          definition={alternativeNames.map((name) => `"${name}"`).join(",")}
        />
      )}
    </SignerDetailsWrapper>
  );
}

export default CustomerSignerDetails;
