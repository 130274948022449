import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import type { ActivityLog } from "../activity_log_fragment.graphql";

export type TransactionSentToClosingOpsActivityLogFragment = ActivityLog & {
  properties: {
    transaction: {
      field_name: string | null;
      value: string | null;
    }[];
  };
};
type Props = {
  activityLog: TransactionSentToClosingOpsActivityLogFragment;
};

export const TransactionSentToClosingOpsLabel = memo(() => {
  return (
    <FormattedMessage
      id="dc6f4d2d-864b-49d5-8106-2e9b4c295262"
      defaultMessage="Sent to Closing Ops"
    />
  );
});

export const TransactionSentToClosingOpsSummary = memo(() => {
  return (
    <FormattedMessage id="365689bc-0199-466d-891d-04d7211d0251" defaultMessage="Order placed" />
  );
});

export const TransactionSentToClosingOpsDetails = memo(
  ({
    activityLog: {
      properties: { transaction },
    },
  }: Props) => {
    return (
      <div className="ActivityLogItemDetailType">
        {transaction.map((detail) => {
          const { value, field_name } = detail;

          return (
            <div
              key={field_name}
              data-automation-id="transaction_details"
              className="ActivityLogItemDetailType--list-item"
            >
              <span className="ActivityLogItemDetailType--label">{field_name}:</span>
              <span className="ActivityLogItemDetailType--nonRangeValue">{value}</span>
            </div>
          );
        })}
      </div>
    );
  },
);
