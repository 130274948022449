import type { ComponentProps, AriaAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { Heading } from "common/core/typography";
import Button from "common/core/button";
import Link from "common/core/link";
import GoogleSignInButton, { type AuthCode } from "common/account/google_signin_button";
import { AccountBlurb } from "common/proof_frame/global_nav/proof_id_widget";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import Styles from "./index.module.scss";

export type SignUpScreenType = {
  type: "signup";
  email: string;
};

export function LoginHeader({ liveRegion }: { liveRegion?: AriaAttributes["aria-live"] }) {
  return (
    <div aria-live={liveRegion}>
      <Heading level="h1" textStyle="subtitle" textAlign="left" className={Styles.mainHeading}>
        <FormattedMessage
          id="02560a61-63ba-4703-9eea-34bdb25eb8a0"
          defaultMessage="Log in to your account"
        />
      </Heading>
      <AccountBlurb />
    </div>
  );
}

export function FooterLink(props: ComponentProps<typeof Link>) {
  return (
    <span className={Styles.footerPortalLink}>
      <Link {...props} />
    </span>
  );
}

export function FooterButton(props: ComponentProps<typeof Button>) {
  return (
    <span className={Styles.footerPortalLink}>
      <Button {...props} />
    </span>
  );
}
type BackButtonProps = {
  onClick: () => void;
  ["aria-label"]?: string;
};

export function BackButton({ onClick, "aria-label": ariaLabel }: BackButtonProps) {
  return (
    <FooterButton
      variant="tertiary"
      buttonColor="action"
      onClick={onClick}
      aria-label={ariaLabel}
      withIcon={{ name: "arrow-left", placement: "left" }}
    >
      <FormattedMessage id="0270eafa-929f-47a1-b036-8a6d73fde2b1" defaultMessage="Go back" />
    </FooterButton>
  );
}

export function BackButtonOnlyFooter(props: BackButtonProps) {
  return (
    <div className={Styles.footerCentered}>
      <BackButton {...props} />
    </div>
  );
}

type ContinueButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

export function Continue({ onClick, disabled }: ContinueButtonProps) {
  return (
    <Button
      onClick={onClick}
      type="submit"
      disabled={disabled}
      buttonColor="action"
      buttonSize="large"
      variant="primary"
      fullwidth
      data-automation-id="submit-button"
    >
      <FormattedMessage id="2bae9507-222d-4a90-aaf5-5d6c0f87fea2" defaultMessage="Continue" />
    </Button>
  );
}

export function SignInWithGoogle(props: { onGoogleSignIn: (authCode: AuthCode) => void }) {
  return <GoogleSignInButton signIn={props.onGoogleSignIn} />;
}

export function useIsGoogleSignInEnabled() {
  const [searchParams] = useSearchParams();
  const ssoRequired = searchParams.get("sso") === "required";

  // Google login code in backend is buggy for accounts that aren't signer or organization. It has to do with passing
  // the account type to our google auth endpoint, which only covers the signer/organization cases. Bad.
  return (
    !ssoRequired &&
    CURRENT_PORTAL !== Apps.NOTARY &&
    CURRENT_PORTAL !== Apps.TITLE_AGENCY &&
    CURRENT_PORTAL !== Apps.LENDER &&
    CURRENT_PORTAL !== Apps.ADMIN
  );
}
