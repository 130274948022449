import { defineMessages, useIntl } from "react-intl";
import type { ReactElement } from "react";

import { OrganizationTypeEnum, PartnerPayerSourcesEnum } from "graphql_globals";
import Button from "common/core/button";
import { Card, CardHeading, CardSection } from "common/core/card";
import { useForm } from "common/core/form";
import { RadioLabel, RadioGroup, RadioInput } from "common/core/form/option";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { useContextOrParamsOrgId } from "util/organization";
import Link from "common/core/link";

import Styles from "./index.module.scss";
import CollabFeeSettingQuery, {
  type CollabFeeSetting_organization_Organization as Organization,
} from "./collab_fee_setting.query.graphql";
import UpdateLenderProfileMutation from "./update_lender_profile_mutation.graphql";

type FormValues = {
  lenderPartnerPayer: PartnerPayerSourcesEnum;
};

type Props = {
  organization: Organization;
};

const MESSAGES = defineMessages({
  cardTitle: {
    id: "0197046f-f006-4eec-811e-57ccb7dd52d5",
    defaultMessage: "For transactions with Title Agents",
  },
  saveChanges: {
    id: "69028df4-29a3-4368-9812-29d4dfac15ac",
    defaultMessage: "Save Changes",
  },
  success: {
    id: "9f244b84-e3b2-4725-9d05-3fa467877e6a",
    defaultMessage: "Partner Payer successfully updated",
  },
  failure: {
    id: "e733b930-c41f-40be-8036-ed1dfec8b937",
    defaultMessage: "Partner Payer failed to update",
  },
  titleAgencyPays: {
    id: "d86da152-7e58-4544-8e39-16e561225026",
    defaultMessage: "Title agent will be prompted for payment",
  },
  lenderPays: {
    id: "3a58c060-14c8-4d73-a989-1098cb2ab260",
    defaultMessage: "This account will be charged for the transaction fees",
  },
});

const KEYSTONE_MESSAGES = defineMessages({
  billingHandler: {
    id: "28f6d6ef-aca4-48da-9e88-df96dc9d738e",
    defaultMessage:
      "How does this lender want to handle billing for transactions involving a title agent?",
  },
  titleAgencyPays: {
    id: "1731eb11-7cae-4f71-bc0b-992a5f066d02",
    defaultMessage: "Title agency pays",
  },
  lenderPays: {
    id: "c2531510-e159-4fb6-84aa-bedf0dc3b1a2",
    defaultMessage: "Lender covers fees (at their own price)",
  },
  titlePricing: {
    id: "4e924f0b-4fcd-41e6-adc9-09f631c89da4",
    defaultMessage:
      "The title agency will pay fees according to their pricing tier. This can only be changed by adding a “linked” pricing plan to the lender’s pricing tier.",
  },
  lenderPricing: {
    id: "e6f45f49-3267-478e-b79a-90e0326ac39d",
    defaultMessage:
      "Title agents fees are determined by the {linkedTierLink}, which is linked to the lender's tier ({lendersTierLink}).",
  },
});

function TitleAgencyLabel({
  lenderPartnerPayerValue,
  organization,
}: {
  lenderPartnerPayerValue: FormValues["lenderPartnerPayer"];
  organization: Organization;
}): ReactElement {
  const intl = useIntl();

  if (CURRENT_PORTAL !== Apps.ADMIN || lenderPartnerPayerValue === PartnerPayerSourcesEnum.LENDER) {
    return <></>;
  }

  const label = organization.activeTier.linkedPlan
    ? intl.formatMessage(KEYSTONE_MESSAGES.lenderPricing, {
        linkedTierLink: (
          <Link href={`/finance/details/${organization.activeTier.linkedPlan.id}`}>
            {organization.activeTier.linkedPlan.name}
          </Link>
        ),
        lendersTierLink: (
          <Link href={`/finance/details/${organization.activeTier.planId}`}>
            {organization.activeTier.name}
          </Link>
        ),
      })
    : intl.formatMessage(KEYSTONE_MESSAGES.titlePricing);

  return <div className={Styles.titleAgencyLabel}>{label}</div>;
}

function CollabFeeSettingInner({ organization }: Props) {
  const intl = useIntl();
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;

  const { register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      lenderPartnerPayer: organization.lenderPartnerPayer!,
    },
  });

  const updateCollabPayer = useMutation(UpdateLenderProfileMutation);
  return (
    <Card
      footer={
        <Button
          buttonColor="action"
          variant="primary"
          type="submit"
          onClick={handleSubmit(({ lenderPartnerPayer }) => {
            updateCollabPayer({
              variables: {
                input: {
                  organizationId: organization.id,
                  defaultPartnerPayer: lenderPartnerPayer,
                },
              },
            })
              .catch(() => {
                pushNotification({
                  subtype: NOTIFICATION_SUBTYPES.ERROR,
                  message: intl.formatMessage(MESSAGES.failure),
                });
              })
              .then(() => {
                pushNotification({
                  type: NOTIFICATION_TYPES.DEFAULT,
                  message: intl.formatMessage(MESSAGES.success),
                });
              });
          })}
        >
          <span>{intl.formatMessage(MESSAGES.saveChanges)}</span>
        </Button>
      }
    >
      <CardSection>
        <CardHeading level="h3">{intl.formatMessage(MESSAGES.cardTitle)}</CardHeading>
        {isAdminPortal && (
          <div className={Styles.radioGroupLabel}>
            {intl.formatMessage(KEYSTONE_MESSAGES.billingHandler)}
          </div>
        )}
        <RadioGroup aria-labelledby="transactions with title agents" className={Styles.radioGroup}>
          <RadioLabel
            label={intl.formatMessage(
              isAdminPortal ? KEYSTONE_MESSAGES.titleAgencyPays : MESSAGES.titleAgencyPays,
            )}
            radio={
              <RadioInput<FormValues["lenderPartnerPayer"]>
                value={PartnerPayerSourcesEnum.ORGANIZATION}
                {...register("lenderPartnerPayer")}
              />
            }
            additionalLabelContent={
              <TitleAgencyLabel
                lenderPartnerPayerValue={watch("lenderPartnerPayer")}
                organization={organization}
              />
            }
          />
          <RadioLabel
            label={intl.formatMessage(
              isAdminPortal ? KEYSTONE_MESSAGES.lenderPays : MESSAGES.lenderPays,
            )}
            radio={
              <RadioInput<FormValues["lenderPartnerPayer"]>
                value={PartnerPayerSourcesEnum.LENDER}
                {...register("lenderPartnerPayer")}
              />
            }
          />
        </RadioGroup>
      </CardSection>
    </Card>
  );
}

export function CollabFeeSetting() {
  const activeOrganizationId = useContextOrParamsOrgId();
  const { data, loading } = useQuery(CollabFeeSettingQuery, {
    variables: {
      organizationId: activeOrganizationId,
    },
  });
  const organization = data?.organization;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  if (
    CURRENT_PORTAL === Apps.ADMIN &&
    organization.organizationType !== OrganizationTypeEnum.LENDER
  ) {
    return null;
  }

  return <CollabFeeSettingInner organization={organization} />;
}
