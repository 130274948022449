import arrayToSentence from "util/array_to_sentence";
import {
  SERVER_ERROR,
  CLIENT_ERROR,
  UNAUTHORIZED_ERROR,
  FORBIDDEN_ERROR,
  TEMP_UNAVAILABLE_ERROR,
} from "errors/server";
import {
  INVALID_EMAIL_ERROR,
  TYPO_EMAIL_ERROR,
  DUPLICATE_EMAIL_ERROR,
  INVALID_PASSWORD_ERROR,
  PASSWORD_COMPROMISED,
  CURRENT_PASSWORD_ERROR,
  AUTHENTICATION_ERROR,
  NON_TITLE_AGENT_EMAIL_ERROR,
  DUPLICATE_SIGNER_ERROR,
  EMAIL_DOMAIN_RESTRICTED_ERROR,
} from "errors/account";
import {
  EMPTY_VALUE,
  INVALID_BIRTH_DATE,
  INVALID_DATE_FORMAT,
  INVALID_DATE,
  INVALID_EMAIL,
  INVALID_FUTURE_DATE,
  INVALID_TIME,
  INVALID_OPTION,
  INVALID_CHECK,
  INVALID_POSTAL,
  INVALID_COUNTRY,
  POSTAL_MISSING,
  INVALID_MATCH,
  INVALID_SSN,
  INVALID_SSN_SERVER,
  INVALID_PERMALINK,
  DUPLICATE_PERMALINK,
  INVALID_URL,
  SINGLE_DOCUMENT_UPLOAD_INCOMPLETE_ERROR,
  UNSUPPORTED_SELECTION,
  OUT_OF_RANGE,
  INVALID_PHONE_NUMBER_LENGTH,
  INVALID_PHONE_NUMBER_AREACODE,
  INVALID_PHONE_NUMBER,
  ONE_OF_TWO_FIELDS_REQUIRED,
  NO_SUPPORTED_TITLE_UNDERWRITERS,
  NO_RECORDING_LOCATION,
  INVALID_INTEGER,
  INVALID_POSITIVE_INTEGER,
  DUPLICATE_VALUE,
  CUSTOM_MESSAGE,
  UNSUPPORTED_TIMEZONE,
  INVALID_PAST_DATE,
} from "errors/form";
import { INVALID_NUMBER, INVALID_EXPIRY, INVALID_CVC, INVALID_CARD } from "errors/credit_card";
import { NON_CUSTOMER_EMAIL } from "errors/transaction";
import { DOCUMENT_PROCESSING_ERROR } from "errors/document";

// Makes it easy to import error into your app like:
//   {
//     ...ServerError, // [SERVER_ERROR]: (props) => "my message";
//     ...ValidationError, // [VALIDATION_ERROR]: (props) => ...;
//   }
function createComponent(type, msgOrFunc) {
  const func = msgOrFunc.call ? msgOrFunc : () => msgOrFunc;
  return { [type]: func };
}

// Server Errors
const ServerError = createComponent(
  SERVER_ERROR,
  "The server was unable to process your request at this time.",
);
const BadRequestError = createComponent(CLIENT_ERROR, "Unable to process your request.");
const UnauthorizedError = createComponent(
  UNAUTHORIZED_ERROR,
  "You are not authorized to view this resource.",
);
const ForbiddenError = createComponent(
  FORBIDDEN_ERROR,
  "You are not allowed to perform this action",
);
const TempUnavailableError = createComponent(
  TEMP_UNAVAILABLE_ERROR,
  "The backend is not available at this time, please try again later.",
);

export const ServerErrors = {
  ...ServerError,
  ...BadRequestError,
  ...UnauthorizedError,
  ...ForbiddenError,
  ...TempUnavailableError,
};

// Account Errors
const DuplicateEmailError = createComponent(
  DUPLICATE_EMAIL_ERROR,
  "That email has already been used. Try another?",
);

const DuplicateSignerError = createComponent(
  DUPLICATE_SIGNER_ERROR,
  "Signer with the same name and email has already been used.",
);

const InvalidEmailError = createComponent(INVALID_EMAIL_ERROR, ({ label }) => {
  return `${label || "This email"} appears to be invalid`;
});

const TypoEmailError = createComponent(TYPO_EMAIL_ERROR, ({ didYouMean }) => {
  const specifics = didYouMean
    ? `did you mean ${didYouMean}?`
    : "please double check this address.";
  return `That email looks wrong, ${specifics}`;
});

export const EmailErrors = {
  ...InvalidEmailError,
  ...TypoEmailError,
  ...DuplicateEmailError,
  ...DuplicateSignerError,
};

// Password Errors
const InvalidPasswordError = createComponent(INVALID_PASSWORD_ERROR, "Your password is too weak");
const passwordCompromisedError = createComponent(
  PASSWORD_COMPROMISED,
  "The password you are trying to use is either commonly used or has been identified in a data breach on another platform. Please choose a stronger password.",
);

const AuthenticationError = createComponent(
  AUTHENTICATION_ERROR,
  "Email address or password is invalid",
);
const PasswordResetError = createComponent(CURRENT_PASSWORD_ERROR, "Password is invalid");

export const PasswordErrors = {
  ...InvalidPasswordError,
  ...AuthenticationError,
  ...passwordCompromisedError,
};

export const PasswordResetErrors = {
  ...InvalidPasswordError,
  ...PasswordResetError,
  ...passwordCompromisedError,
};

// Form errors
const CustomError = createComponent(CUSTOM_MESSAGE, ({ message }) => {
  return message;
});

const NoRecordingLocationError = createComponent(NO_RECORDING_LOCATION, () => {
  return "Please enter a valid US address";
});

const NoSupportedTitleUnderwritersError = createComponent(NO_SUPPORTED_TITLE_UNDERWRITERS, () => {
  return "Unfortunately, the following recording location is not eligible for use with Notarize.";
});

const EmptyValueError = createComponent(EMPTY_VALUE, ({ label, plural }) => {
  if (label === "Initials" || plural) {
    return `${label} are required`;
  }

  return `${label} is required`;
});
const DuplicateValueError = createComponent(DUPLICATE_VALUE, ({ label }) => {
  return `${label} has already been used`;
});
const InvalidBirthDateError = createComponent(INVALID_BIRTH_DATE, () => {
  return "You must be 18 years or older to use Notarize";
});
const InvalidDateFormatError = createComponent(INVALID_DATE_FORMAT, ({ format, label }) => {
  return `${label} is invalid, should be in ${format} format`;
});
const InvalidDateError = createComponent(INVALID_DATE, ({ label }) => {
  return `${label} is an invalid date`;
});
const InvalidEmail = createComponent(INVALID_EMAIL, () => {
  return "Email is invalid";
});
const InvalidFutureDateError = createComponent(INVALID_FUTURE_DATE, ({ label }) => {
  return `${label} must be a valid future date`;
});
const InvalidPastDateError = createComponent(INVALID_PAST_DATE, ({ label, minDate }) => {
  return `${label} must be on or after the date provided (${minDate}).`;
});
const InvalidTimeError = createComponent(INVALID_TIME, ({ label }) => {
  return `${label} is an invalid time`;
});
const InvalidPostalError = createComponent(INVALID_POSTAL, ({ label }) => {
  return `${label} code is invalid`;
});
const InvalidCountryError = createComponent(INVALID_COUNTRY, () => {
  return "Please provide your current or most recent U.S. address.";
});
const PostalMissingError = createComponent(POSTAL_MISSING, ({ label = "ZIP/Postal" }) => {
  return `${label} code is required`;
});
const InvalidOptionError = createComponent(INVALID_OPTION, ({ label, format }) => {
  const options = arrayToSentence(Object.values(format || {}), " or ");
  return `${label} should be one of: ${options}`;
});
const InvalidCheckError = createComponent(INVALID_CHECK, ({ label, extra }) => {
  return `${label} must be ${extra || "checked"}`;
});
const InvalidMatchError = createComponent(INVALID_MATCH, ({ label, other }) => {
  return `${label} must match ${other}`;
});
const InvalidSsnError = createComponent(INVALID_SSN, ({ label }) => {
  return `You must enter a valid ${label}.`;
});
const InvalidSsnServerError = createComponent(INVALID_SSN_SERVER, ({ message }) => {
  return message;
});
const InvalidPermalinkError = createComponent(INVALID_PERMALINK, () => {
  return "Permalinks can only use letters, numbers, underscores, or hyphens.";
});
const DuplicatePermalinkError = createComponent(DUPLICATE_PERMALINK, ({ permalink }) => {
  return `You already have a permalink named "${permalink}". Permalink must be unique.`;
});
const InvalidURLError = createComponent(INVALID_URL, ({ label }) => {
  return `${label} is an invalid URL`;
});
const singleDocumentUploadIncompleteError = createComponent(
  SINGLE_DOCUMENT_UPLOAD_INCOMPLETE_ERROR,
  () => {
    return "File upload is incomplete";
  },
);
const UnsupportedSelectionError = createComponent(UNSUPPORTED_SELECTION, ({ label }) => {
  return `This ${label} is unsupported`;
});
const OutOfRangeError = createComponent(OUT_OF_RANGE, ({ value, label, min, max }) => {
  const num = parseFloat(value);
  if (value === undefined || value === null || value === "") {
    return `${label} is required`;
  } else if (min !== undefined && !isNaN(num) && num < min) {
    return `${label} below minimum`;
  } else if (max !== undefined && !isNaN(num) && num > max) {
    return `${label} above maximum`;
  }
  return `${label} has invalid format`;
});

const InvalidPhoneNumberLength = createComponent(
  INVALID_PHONE_NUMBER_LENGTH,
  "Please enter a 10 digit phone number",
);

const InvalidPhoneNumberAreacode = createComponent(
  INVALID_PHONE_NUMBER_AREACODE,
  "Phone number must not start with 1",
);

const InvalidPhoneNumber = createComponent(
  INVALID_PHONE_NUMBER,
  "Please enter a valid phone number",
);

const OneIsRequiredError = createComponent(ONE_OF_TWO_FIELDS_REQUIRED, ({ label1, label2 }) => {
  const vowels = "aeiou";
  const firstLetterIsVowel = vowels.includes(label1[0].toLowerCase());

  return `${firstLetterIsVowel ? "An" : "A"} ${label1} or ${label2} is required`;
});

const invalidIntegerError = createComponent(INVALID_INTEGER, ({ label }) => {
  return `${label} must be a natural number`;
});

const invalidPositiveIntegerError = createComponent(INVALID_POSITIVE_INTEGER, ({ label }) => {
  return `${label} must be a positive natural number`;
});

const invalidTimezone = createComponent(UNSUPPORTED_TIMEZONE, ({ value }) => {
  return `Timezone, ${value}, is unsupported`;
});

const invalidTitleAgentEmail = createComponent(NON_TITLE_AGENT_EMAIL_ERROR, () => {
  return "This email cannot be used because it is associated with a non-title account.";
});

const emailDomainRestricted = createComponent(EMAIL_DOMAIN_RESTRICTED_ERROR, () => {
  return "Signups from this email domain are restricted. Please contact your administrator for access to the Proof platform.";
});

export const FormErrors = {
  ...CustomError,
  ...NoRecordingLocationError,
  ...NoSupportedTitleUnderwritersError,
  ...EmptyValueError,
  ...InvalidBirthDateError,
  ...InvalidDateFormatError,
  ...InvalidDateError,
  ...InvalidEmailError,
  ...InvalidEmail,
  ...InvalidFutureDateError,
  ...InvalidPastDateError,
  ...InvalidTimeError,
  ...InvalidOptionError,
  ...InvalidCheckError,
  ...InvalidPostalError,
  ...InvalidCountryError,
  ...PostalMissingError,
  ...InvalidMatchError,
  ...InvalidSsnError,
  ...InvalidSsnServerError,
  ...InvalidPermalinkError,
  ...DuplicatePermalinkError,
  ...InvalidURLError,
  ...singleDocumentUploadIncompleteError,
  ...UnsupportedSelectionError,
  ...OutOfRangeError,
  ...InvalidPhoneNumberLength,
  ...InvalidPhoneNumberAreacode,
  ...InvalidPhoneNumber,
  ...OneIsRequiredError,
  ...invalidIntegerError,
  ...invalidPositiveIntegerError,
  ...DuplicateValueError,
  ...invalidTimezone,
  ...invalidTitleAgentEmail,
  ...emailDomainRestricted,
};

// Credit Card errors
const InvalidNumberError = createComponent(INVALID_NUMBER, ({ label }) => {
  return `${label} is invalid`;
});

const InvalidExpiryError = createComponent(INVALID_EXPIRY, ({ label }) => {
  return `${label} is invalid`;
});

const InvalidCVCError = createComponent(INVALID_CVC, ({ label }) => {
  return `${label} is invalid`;
});

const InvalidCardError = createComponent(INVALID_CARD, ({ message }) => {
  return message;
});

export const CreditCardErrors = {
  ...InvalidNumberError,
  ...InvalidExpiryError,
  ...InvalidCVCError,
  ...InvalidCardError,
};

// Organization Transaction errors
const NonCustomerEmail = createComponent(
  NON_CUSTOMER_EMAIL,
  "This email address is already in use, please try sending this transaction to another email address",
);

export const TransactionErrors = {
  ...NonCustomerEmail,
};

// Document errors
export const DocumentProcessingError = createComponent(
  DOCUMENT_PROCESSING_ERROR,
  "There was an error processing your document, please try again or contact support",
);

export const DocumentErrors = {
  ...DocumentProcessingError,
};
