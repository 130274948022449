import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Icon from "common/core/icon";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";

// EditableInput takes in an argument that is the input/field we want to turn
// into an "editable" version. An editable component is a component that will by
// default show a read-only version of the value, and show an edit icon
// when hovering. When clicked, the EditableInput will then render the passed
// in component as normal.

// How to use:
// Create a class component with a basic render function that conditionally renders
// the editable input version of the component, or an unmodified version.

// Note: The EditableInput version of the component needs to be defined OUTSIDE
// of the render function so the input does not lose focus as values change or a
// user types. https://redux-form.com/7.2.3/docs/api/field.md/#2-a-stateless-function

// Example:
// import Input from "./input";
// const EnhancedInput = EditableInput(Input);
//
// class Select extends React.Component {
//   render() {
//     const otherProps = omit(this.props, [ "editable" ]);
//
//     return this.props.editable ?
//       (
//         <EnhancedInput
//           {...otherProps}
//         />
//       ) : (
//         <Input
//           {...otherProps}
//         />
//       );
//   }
// }

/** @deprecated - please use components in common/core/form */
export const DeprecatedEditableInput = (OriginalComponent) => {
  class EditableInput extends Component {
    inputElement = null;

    state = {
      editing: false,
    };

    handleClick = () => {
      if (!this.state.editing) {
        this.setState({ editing: true }, () => {
          if (this.inputElement.focusInput) {
            this.inputElement.focusInput();
          }
        });
      }
    };

    render() {
      const { readOnly } = this.props;
      const { editing } = this.state;

      const shouldShowReadOnly = !editing && readOnly;

      const cx = classnames("EditableInput", SENSITIVE_CLASS, {
        "EditableInput__not-editing": shouldShowReadOnly,
      });

      return (
        <div className={cx} onClick={this.handleClick}>
          <OriginalComponent
            ref={(el) => {
              this.inputElement = el;
            }}
            {...this.props}
            readOnly={shouldShowReadOnly}
          />
          {shouldShowReadOnly && (
            <div className="EditableInput--icon">
              <Icon name="pencil" />
            </div>
          )}
        </div>
      );
    }
  }

  EditableInput.propTypes = {
    ...OriginalComponent.propTypes,
    editable: PropTypes.bool,
  };

  return EditableInput;
};
