import { memo, type ReactNode, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import supportImg from "assets/images/support.svg";
import ActionButton from "common/core/action_button";
import { useFeatureFlag } from "common/feature_gating";
import Env from "config/environment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import { COLOR } from "constants/color";
import { captureException } from "util/exception";
import { type ApolloClient, useApolloClient } from "util/graphql";
import { useLoadScript } from "util/html";
import { segmentTrack } from "util/segment";
import { userFullName } from "util/user";

import type { ViewerChatProperties_viewer as Viewer } from "./chat_query.graphql";
import ChatTransactionPropertiesQuery, {
  type ChatTransactionProperties_transaction_OrganizationTransaction as Transaction,
  type ChatTransactionProperties,
} from "./chat_transaction_query.graphql";
import ChatBundlePropertiesQuery, {
  type ChatBundleProperties_bundle_DocumentBundle as Bundle,
  type ChatBundleProperties,
} from "./chat_bundle_query.graphql";
import Styles from "./zendesk.module.scss";
import {
  AddZendeskTag,
  AddZendeskTagTransaction,
  TransactionParticipant,
  ZendeskTagField,
  idsFromURL,
} from "./zendesk_helper";

type User = Viewer["user"];
type Props = {
  viewer: Viewer | null;
  showSupportButton: boolean;
  onToggle?: (open: boolean) => void;
  onLoaded?: () => void;
};
type SetupProps = {
  addExpandedTags: boolean;
  client: ApolloClient<unknown>;
  user: User;
  locale: string;
  themeColor: string;
  onToggle: (open: boolean) => void;
  onLoaded?: () => void;
};
type ChatDataProps = { addExpandedTags: boolean; client: ApolloClient<unknown>; user: User };
type ChatQueryProps = {
  client: ApolloClient<unknown>;
  bundleId: string | null;
  transactionId: string | null;
  user: User;
};
type ButtonProps = {
  className?: string;
  onOpenCb?: () => void;
  children?: ReactNode;
};
type PrefillArgs = {
  name: {
    value: string;
    readOnly?: boolean;
  };
  email: {
    value: string | null;
    readOnly?: boolean;
  };
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    zESettings: {
      webWidget: {
        position?: { horizontal: "left" | "right"; vertical: "top" | "bottom" };
        zIndex: number;
        color?: { theme: string };
        contactForm?: {
          attachments: boolean;
        };
      };
    };
    zE?: {
      (widget: "webWidget", operation: "hide" | "open" | "show"): void;
      (widget: "webWidget", operation: "setLocale", locale: string): void;
      (widget: "webWidget", operation: "prefill", data: PrefillArgs): void;
      (widget: "webWidget", operation: "chat:addTags", tags: string[]): void;
      (widget: "webWidget:on", operation: "open" | "close", callback: () => void): void;
    };
  }
}

const { zendeskChatApiKey } = Env;
const MESSAGES = defineMessages({
  helpButtonLabel: {
    id: "7e4bd519-79fa-42d5-89af-577b5205db2a",
    defaultMessage: "Proof Help",
  },
});

export function zendeskAvailable() {
  return Boolean(window.zE);
}

export function hideZendesk() {
  window.zE?.("webWidget", "hide");
}
export function openZendesk(cb?: () => void) {
  if (window.zE) {
    window.zE("webWidget", "show");
    window.zE("webWidget", "open");
    cb?.();
  }
  segmentTrack(SEGMENT_EVENTS.OPENED_SUPPORT_CHAT);
}

function ZendeskButton({ className, children, onOpenCb }: ButtonProps) {
  return (
    <ActionButton
      color="dark"
      className={className ? className : Styles.fullButton}
      onClick={() => openZendesk(onOpenCb)}
      automationId="support-button"
    >
      <img alt="" src={supportImg} />
      {children || (
        <FormattedMessage id="f1238942-e489-4f69-b141-acf558134018" defaultMessage="Support" />
      )}
    </ActionButton>
  );
}

const MemoizedZendeskButton = memo(ZendeskButton);
export { MemoizedZendeskButton as ZendeskButton };

function FixedZendeskButton({ open, intl }: { open: boolean; intl: ReturnType<typeof useIntl> }) {
  return (
    <button
      type="button"
      onClick={() => openZendesk()}
      className={classnames(Styles.fixedButton, open && Styles.open)}
      aria-label={intl.formatMessage(MESSAGES.helpButtonLabel)}
      aria-haspopup="true"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M11.5 2C6.81 2 3 5.81 3 10.5S6.81 19 11.5 19h.5v3c4.86-2.34 8-7 8-11.5C20 5.81 16.19 2 11.5 2zm1 14.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z"
          fill="white"
        />
      </svg>
    </button>
  );
}

async function ChatQueryResults({
  client,
  user,
  bundleId,
  transactionId,
}: ChatQueryProps): Promise<Transaction | null> {
  // do not block chat creation progress if this query fails
  try {
    if (user?.id && transactionId) {
      const { data }: { data?: ChatTransactionProperties } = await client.query({
        query: ChatTransactionPropertiesQuery,
        variables: { transactionId },
      });
      return (data?.transaction as Transaction | null | undefined) ?? null;
    } else if (user?.id && bundleId) {
      const { data }: { data?: ChatBundleProperties } = await client.query({
        query: ChatBundlePropertiesQuery,
        variables: { bundleId },
      });
      return (data?.bundle as Bundle | null | undefined)?.organizationTransaction ?? null;
    }
  } catch (error) {
    // capture the exception but do not block progress
    captureException(error);
  }
  return null;
}

async function getZendeskChatData({ addExpandedTags, client, user }: ChatDataProps) {
  const tags: string[] = [CURRENT_PORTAL];
  // push the userId as is for back compat
  user?.id && tags.push(user.id);
  let paramsEmail: string | null = null;

  if (addExpandedTags) {
    // failures or exceptions in data tag creation should not block chat.
    try {
      const path = location.pathname;
      const search = location.search;
      const params = new URLSearchParams(search);
      paramsEmail = params.get("email");
      const pathTokens = path.split("/").filter((t) => !!t);
      const url_start = pathTokens[0];
      const url_end = pathTokens[pathTokens.length - 1];
      const page = `${url_start}${url_end === url_start ? "" : `_${url_end}`}`;
      const userId = user?.id || params.get("userId");
      const { bundleId, transactionId } = idsFromURL(pathTokens, search);
      const transaction = await ChatQueryResults({ client, user, bundleId, transactionId });

      const tagValues = {
        transactionId: transaction?.id ?? transactionId,
        transactionOrg: transaction?.publicOrganization.name ?? params.get("organization_name"),
        transactionOrgId: transaction?.publicOrganization.id ?? null,
        transactionParticipant: TransactionParticipant({ transaction, userId }),
        transactionSenderId: transaction?.employee.id ?? null,
        userId,
        userOrg: user?.organization?.name ?? null,
        view: page,
      };

      tagValues.transactionId &&
        AddZendeskTagTransaction(tags, {
          orgId: tagValues.transactionOrgId,
          senderId: tagValues.transactionSenderId,
          id: tagValues.transactionId,
        });
      tagValues.transactionOrg &&
        AddZendeskTag(tags, ZendeskTagField.TRANSACTION_ORG, tagValues.transactionOrg);
      tagValues.transactionParticipant &&
        AddZendeskTag(
          tags,
          ZendeskTagField.TRANSACTION_PARTICIPANT,
          tagValues.transactionParticipant,
        );
      tagValues.userId && AddZendeskTag(tags, ZendeskTagField.USER_ID, tagValues.userId);
      tagValues.userOrg && AddZendeskTag(tags, ZendeskTagField.USER_ORG, tagValues.userOrg);
      tagValues.view && AddZendeskTag(tags, ZendeskTagField.VIEW, tagValues.view);
    } catch (error) {
      // capture the exception but do not block progress
      captureException(error);
    }
  }

  const email = user?.email || paramsEmail;
  const fullName = userFullName(user);

  return { email, fullName, tags };
}

function setupZendesk({
  addExpandedTags,
  client,
  user,
  locale,
  themeColor,
  onToggle,
  onLoaded,
}: SetupProps) {
  window.zESettings = {
    webWidget: {
      color: { theme: themeColor },
      position: { horizontal: "right", vertical: "bottom" },
      zIndex: 190,
      contactForm: {
        attachments: false,
      },
    },
  };
  const zendesk = window.zE;
  if (zendesk) {
    let webWidgetTimer: ReturnType<typeof setInterval> | undefined;
    let webWidgetTimerCount = 0;
    const clearTimer = () => {
      webWidgetTimer && clearInterval(webWidgetTimer);
      webWidgetTimer = undefined;
      webWidgetTimerCount = 0;
    };
    zendesk("webWidget", "hide");
    zendesk("webWidget:on", "close", () => {
      window.zE!("webWidget", "hide");
      onToggle(false);
      clearTimer();
    });
    zendesk("webWidget:on", "open", () => {
      onToggle(true);
      getZendeskChatData({ addExpandedTags, user, client }).then(({ email, fullName, tags }) => {
        const zendesk = window.zE!;
        zendesk("webWidget", "chat:addTags", tags);
        if (email || fullName) {
          zendesk("webWidget", "prefill", {
            name: { value: fullName },
            email: { value: email, readOnly: !!email },
          });
        }
      });

      // Adding a timer here for accessibility purposes, to focus on the zendesk window on open.
      webWidgetTimer = setInterval(() => {
        webWidgetTimerCount++;
        const webWidget = document.querySelector("iframe#webWidget");
        if (webWidget) {
          (webWidget as HTMLIFrameElement).contentWindow?.focus();
          clearTimer();
        } else if (webWidgetTimerCount >= 15) {
          // kill the timer after 15 tries
          clearTimer();
        }
      }, 300);
    });
    locale && zendesk("webWidget", "setLocale", locale);
  }
  onLoaded?.();
}

function ZendeskChat({ viewer, onToggle, showSupportButton, onLoaded }: Props) {
  const intl = useIntl();
  const client = useApolloClient();
  const [open, setOpen] = useState(false);
  const addExpandedTags = useFeatureFlag("zendesk-expanded-tags");
  const onToggleRef = useRef(onToggle);

  useEffect(() => {
    onToggleRef.current = (newVal) => {
      setOpen(newVal);
      onToggle?.(newVal);
    };

    return () => {
      onToggleRef.current = undefined;
    };
  }, [onToggleRef, open]);

  useLoadScript({
    id: "ze-snippet",
    src: `https://static.zdassets.com/ekr/snippet.js?key=${zendeskChatApiKey}`,
    onLoad: () =>
      setupZendesk({
        addExpandedTags,
        client,
        user: viewer?.user ?? null,
        locale: intl.locale,
        themeColor: COLOR.BLUE,
        onToggle: (newVal) => onToggleRef.current?.(newVal),
        onLoaded,
      }),
  });

  return showSupportButton ? <FixedZendeskButton open={open} intl={intl} /> : null;
}

export default memo(ZendeskChat);
