import { useEffect, useRef } from "react";
import type { Message } from "@twilio/conversations";
import { fromEvent, switchMap, takeUntil, merge, takeWhile, map } from "rxjs";
import { defineMessages, useIntl } from "react-intl";

import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";

import Styles from "./preview_message.module.scss";

type Props = {
  message: Message;
  onMessageClick: () => void;
  onDismiss: () => void;
};
type MessageAttributes = {
  name: string;
};

// # pixels needed to move until we dismiss it on release
const DISSMISS_THRESHOLD = 75;

// max # pixels moved where we consider it to be non intentional, and treat it as a click
const CLICK_THRESHOLD_MAX = 10;

const MESSAGES = defineMessages({
  chatMessageAriaLabel: {
    id: "82c7b10c-7745-4391-be6f-61494e5d9bc6",
    defaultMessage: "New Chat Message From ",
  },
});

function PreviewMessage({ message, onMessageClick, onDismiss }: Props) {
  const intl = useIntl();
  const messageRef = useRef<HTMLDivElement | null>(null);
  const preventClick = useRef(false);

  const handleDismiss = () => {
    preventClick.current = true;
    onDismiss();
  };

  const handleMessageClick = () => {
    if (preventClick.current) {
      preventClick.current = false;
      return;
    }
    onMessageClick();
  };

  useEffect(() => {
    const timeoutId = window.setTimeout(onDismiss, 7_000);
    return () => window.clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const endValue = Symbol("endStream");
    const pointerUp$ = fromEvent<PointerEvent>(window.document, "pointerup");

    const leftOffset$ = fromEvent<PointerEvent>(messageRef.current!, "pointerdown").pipe(
      switchMap(({ clientX: startX }) => {
        const move$ = fromEvent<PointerEvent>(window.document, "pointermove").pipe(
          map((pointerMoveEvt) => pointerMoveEvt.clientX - startX),
          takeUntil(pointerUp$),
        );
        const resolvePointerUp$ = pointerUp$.pipe(
          map((pointerUpEvt) => {
            const magnitude = Math.abs(pointerUpEvt.clientX - startX);
            preventClick.current = magnitude > CLICK_THRESHOLD_MAX;
            // end the stream or reset the message leftOffset to 0
            return magnitude > DISSMISS_THRESHOLD ? endValue : 0;
          }),
        );
        return merge(resolvePointerUp$, move$);
      }),
      takeWhile((val) => val !== endValue),
    );
    const sub = leftOffset$.subscribe({
      next(leftOffset) {
        const opacity = 100 - (Math.abs(leftOffset) / DISSMISS_THRESHOLD) * 100;
        messageRef.current!.style.opacity = `${opacity}%`;
        messageRef.current!.style.left = `${leftOffset}px`;
      },
      complete: onDismiss,
    });

    return () => sub.unsubscribe();
  }, []);

  return (
    <div
      data-automation-id={`preview-message-${message.sid}`}
      ref={messageRef}
      className={Styles.previewMessage}
      onClick={handleMessageClick}
    >
      <SROnly>{intl.formatMessage(MESSAGES.chatMessageAriaLabel)}</SROnly>
      <span className={Styles.title}>{(message.attributes as MessageAttributes).name}</span>
      <span data-automation-id="preview-close" onClick={handleDismiss}>
        <Icon aria-hidden="true" name="x-mark" />
      </span>
      <span className={Styles.body}>{message.body}</span>
    </div>
  );
}

export default PreviewMessage;
