import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { defineMessages, type IntlShape } from "react-intl";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import { segmentTrack } from "util/segment";
import { MortgageTransactionType, Feature } from "graphql_globals";
import { usePermissions } from "common/core/current_user_role";

const WHITE_LIST = new Set([
  "query",
  "section",
  "scope",
  "detailedStatuses",
  "transactionTypes",
  "dateConstraint",
  "contacts",
]);

// for our purposes, we characterize any PII(Personally Identifiable Information) as letters.
// this leaves the value open to being a number, represented by a string.
export function sanitizePII(text: string) {
  return text.match(/[A-Za-z]/) ? "[FILTERED]" : text;
}

function sanitizeQueryArgs(queryArgs: URLSearchParams) {
  return new URLSearchParams(
    Array.from(queryArgs.entries())
      .filter(([key]) => WHITE_LIST.has(key))
      .reduce((agg: Record<string, string>, [key, value]) => {
        if (key === "query") {
          agg[key] = sanitizePII(value);
        } else {
          agg[key] = value;
        }
        return agg;
      }, {}),
  );
}

export function useDashboardSegmentTrack() {
  const [searchParams] = useSearchParams();
  const sanitizedQueryString = sanitizeQueryArgs(searchParams);
  const segmentContext = decodeURIComponent(sanitizedQueryString.toString());

  return useCallback(
    (event: string, metaAndOverrides?: null | Record<string, unknown>) => {
      segmentTrack(event, {
        portal: CURRENT_PORTAL,
        context: segmentContext,
        ...metaAndOverrides,
      });
    },
    [searchParams],
  );
}

export function setContainsAllValues<T>(set: Set<T>, values: T[]) {
  if (set.size !== values.length) {
    return false;
  }

  return values.every((value) => set.has(value));
}

const TRANSACTION_TYPE_MESSAGES = defineMessages({
  [MortgageTransactionType.wet_sign]: {
    id: "ae834d86-59f5-4129-a94e-b26328220649",
    defaultMessage: "Wet sign",
  },
  [MortgageTransactionType.refinance]: {
    id: "e9af6aac-f2f6-4903-ada6-ba1593adf520",
    defaultMessage: "Refinance",
  },
  [MortgageTransactionType.trailing_docs]: {
    id: "e63300f6-9998-4023-9135-6f14292727d8",
    defaultMessage: "Trailing docs",
  },
  [MortgageTransactionType.purchase_seller]: {
    id: "52259158-4ee8-4975-96c1-697bcc8b41ae",
    defaultMessage: "Seller",
  },
  [MortgageTransactionType.purchase_buyer_cash]: {
    id: "c02282ee-fc69-4fee-b115-1b6d1c445d82",
    defaultMessage: "Cash",
  },
  [MortgageTransactionType.purchase_buyer_loan]: {
    id: "4e0da1de-66f4-46bc-823b-21a027b9217d",
    defaultMessage: "Borrower",
  },
  [MortgageTransactionType.loan_mod_borrower]: {
    id: "9e839823-628e-4300-9c8d-ea53a13377ab",
    defaultMessage: "Loan modification (borrower)",
  },
  deprecated_real_estate_proof: {
    id: "e9a6f0e0-4e7c-4f2e-8e7c-2a2b5b1d0c9d",
    defaultMessage: "Proof",
  },
  deprecated_real_estate_esign: {
    id: "485b514b-675a-43f3-a85e-f323fc2b21a4",
    defaultMessage: "eSign",
  },
  [MortgageTransactionType.heloc]: {
    id: "7eb85728-bf9f-46d5-ba5c-394febcffa3e",
    defaultMessage: "HELOC",
  },
  [MortgageTransactionType.other]: {
    id: "9381651d-53cd-4265-bdf0-ae086f1ecb34",
    defaultMessage: "Other",
  },
  unsupported: {
    id: "16320ebc-97a2-4ea8-80b7-dc170845f92d",
    defaultMessage: "Unsupported",
  },
  sign: {
    id: "e9b384d9-9427-4600-bc86-d83397186e9a",
    defaultMessage: "Sign",
  },
});
export function getTransactionTypeMessage(
  transactionType: string | null,
  intl: IntlShape,
  signTransactionsEnabled?: boolean,
) {
  switch (transactionType) {
    case MortgageTransactionType.wet_sign:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.wet_sign]);
    case MortgageTransactionType.refinance:
    case MortgageTransactionType.hybrid_refinance:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.refinance]);
    case MortgageTransactionType.trailing_docs:
    case MortgageTransactionType.hybrid_trailing_docs:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.trailing_docs]);
    case MortgageTransactionType.purchase_seller:
    case MortgageTransactionType.hybrid_purchase_seller:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.purchase_seller]);
    case MortgageTransactionType.purchase_buyer_cash:
    case MortgageTransactionType.hybrid_purchase_buyer_cash:
      return intl.formatMessage(
        TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.purchase_buyer_cash],
      );
    case MortgageTransactionType.purchase_buyer_loan:
    case MortgageTransactionType.hybrid_purchase_buyer_loan:
      return intl.formatMessage(
        TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.purchase_buyer_loan],
      );
    case MortgageTransactionType.loan_mod_borrower:
      return intl.formatMessage(
        TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.loan_mod_borrower],
      );
    case MortgageTransactionType.heloc:
    case MortgageTransactionType.hybrid_heloc:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.heloc]);
    case MortgageTransactionType.other:
    case MortgageTransactionType.hybrid_other:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES[MortgageTransactionType.other]);
    case MortgageTransactionType.real_estate_proof:
      if (!signTransactionsEnabled) {
        return intl.formatMessage(TRANSACTION_TYPE_MESSAGES.deprecated_real_estate_proof);
      }
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES.sign);
    case MortgageTransactionType.real_estate_esign:
      if (!signTransactionsEnabled) {
        return intl.formatMessage(TRANSACTION_TYPE_MESSAGES.deprecated_real_estate_esign);
      }
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES.sign);
    default:
      return intl.formatMessage(TRANSACTION_TYPE_MESSAGES.unsupported);
  }
}

export function useShowDocTemplates(featureList: Feature[]) {
  const { hasPermissionFor } = usePermissions();
  return featureList.includes(Feature.DOCUMENT_TEMPLATES) && hasPermissionFor("manageTemplates");
}

export function useShowEasylinks(featureList: Feature[]) {
  return featureList.includes(Feature.PARTNER_API);
}
