import { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { DeprecatedFormRow } from "common/form/elements/row";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedSelectField } from "common/form/fields/select";
import Button from "common/core/button";
import { DeprecatedTextField } from "common/form/fields/text";
import { captureException } from "util/exception";
import { composeValidators, getFormValues } from "util/form";
import { validateIf, validatePresence } from "validators/form";
import { DeprecatedSubForm } from "common/form/sub_form";
import { DeprecatedSubFormSection } from "common/form/sub_form/section";
import AlertMessage from "common/core/alert_message";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { DeprecatedTextAreaField } from "common/form/fields/text_area";
import { EncompassContainers, FolderPushBackStrategiesEnum } from "graphql_globals";
import compose from "util/compose";
import { b } from "util/html";

import UpdateEncompassInstanceMutation from "./update_encompass_instance_mutation.graphql";
import type {
  EncompassInstance,
  EncompassInstance_closingDisclosuresFolder,
  EncompassInstance_defaultFolder,
  EncompassInstance_auditTrailFolder,
  EncompassInstance_wetSignDocsFolder,
} from "./encompass_instance_fragment.graphql";

export type Props = {
  encompassInstance: EncompassInstance;
  isAdmin: boolean;
};
type FormValues = {
  defaultFolder: EncompassInstance_defaultFolder | null;
  defaultFolderFolderName: string;
  closingDisclosuresFolder: EncompassInstance_closingDisclosuresFolder | null;
  closingDisclosuresFolderFolderName: string;
  auditTrailFolder: EncompassInstance_auditTrailFolder | null;
  auditTrailFolderFolderName: string;
  wetSignDocsFolder: EncompassInstance_wetSignDocsFolder | null;
  wetSignDocsFolderFolderName: string;
  documentsToPull: string;
  templateDuplicationFilter: boolean;
};
type GetFormValueProps = {
  formValues: FormValues | undefined;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps & GetFormValueProps;

enum EncompassFolderName {
  DEFAULT = "default",
  CLOSING_DISCLOSURES = "closingDisclosures",
  AUDIT_TRAIL = "auditTrail",
  WET_SIGN_DOCS = "wetSignDocs",
}

const CUSTOM_FOLDER = Object.freeze("custom_folder");

const DEFAULT_FOLDER_OPTIONS_ITEMS = Object.freeze([
  {
    value: EncompassContainers.FILE_MANAGER,
    label: (
      <FormattedMessage
        id="4fbe1df1-9c69-49c2-aafa-acda6116e6c1"
        defaultMessage="in the File Manager"
      />
    ),
  },
  {
    value: CUSTOM_FOLDER,
    label: (
      <FormattedMessage
        id="5af4bd8e-64a8-4342-995b-cd44ce8185a1"
        defaultMessage="in a custom Folder"
      />
    ),
  },
]);
const IN_PLACE_FOLDER_OPTIONS_ITEMS = Object.freeze([
  {
    value: EncompassContainers.NOTARIZE_TRASH,
    label: (
      <FormattedMessage id="410e6a0f-c687-4c69-b8ac-e96a7fbe2cf9" defaultMessage="delete them" />
    ),
  },
  {
    value: EncompassContainers.FILE_MANAGER,
    label: (
      <FormattedMessage
        id="4fbe1df1-9c69-49c2-aafa-acda6116e6c1"
        defaultMessage="in the File Manager"
      />
    ),
  },
  {
    value: CUSTOM_FOLDER,
    label: (
      <FormattedMessage
        id="5af4bd8e-64a8-4342-995b-cd44ce8185a1"
        defaultMessage="in a custom Folder"
      />
    ),
  },
]);

function getFolderLabel(name: EncompassFolderName) {
  switch (name) {
    case EncompassFolderName.DEFAULT:
      return (
        <FormattedMessage
          id="4ffa0419-eedf-4e0a-a2a0-c9b3ed5a74f0"
          defaultMessage="How would you like your e-sign documents to be pushed back to Encompass?"
        />
      );
    case EncompassFolderName.CLOSING_DISCLOSURES:
      return (
        <FormattedMessage
          id="29387ad5-e105-4a2b-9dad-c8bfef0fe90b"
          defaultMessage="Would you also like Closing Disclosure documents to be pushed back as an extra document to Encompass?"
        />
      );
    case EncompassFolderName.AUDIT_TRAIL:
      return (
        <FormattedMessage
          id="e7169f4d-633a-4bec-a993-9893dc6cac69"
          defaultMessage="Would you also like transaction audit trail to be pushed back as an extra document to Encompass?"
        />
      );
    case EncompassFolderName.WET_SIGN_DOCS:
      return (
        <FormattedMessage
          id="3fb83aa4-a313-4dc0-baf6-f56329d0491f"
          defaultMessage="Would you also like wet signed documents in hybrid/wet sign transactions to be pushed back to Encompass?"
        />
      );
    default:
      return "";
  }
}

function validate(values: FormValues) {
  const {
    defaultFolderFolderName,
    closingDisclosuresFolderFolderName,
    auditTrailFolderFolderName,
    wetSignDocsFolderFolderName,
  } = values;
  return composeValidators(
    validateIf({
      field: `${EncompassFolderName.DEFAULT}Folder.folderName`,
      condition: () => defaultFolderFolderName === CUSTOM_FOLDER,
      validation: validatePresence({
        field: `${EncompassFolderName.DEFAULT}Folder.folderName`,
        label: "the folder name",
      }),
    }),
    validateIf({
      field: `${EncompassFolderName.CLOSING_DISCLOSURES}Folder.folderName`,
      condition: () => closingDisclosuresFolderFolderName === CUSTOM_FOLDER,
      validation: validatePresence({
        field: `${EncompassFolderName.CLOSING_DISCLOSURES}Folder.folderName`,
        label: "the folder name",
      }),
    }),
    validateIf({
      field: `${EncompassFolderName.AUDIT_TRAIL}Folder.folderName`,
      condition: () => auditTrailFolderFolderName === CUSTOM_FOLDER,
      validation: validatePresence({
        field: `${EncompassFolderName.AUDIT_TRAIL}Folder.folderName`,
        label: "the folder name",
      }),
    }),
    validateIf({
      field: `${EncompassFolderName.WET_SIGN_DOCS}Folder.folderName`,
      condition: () => wetSignDocsFolderFolderName === CUSTOM_FOLDER,
      validation: validatePresence({
        field: `${EncompassFolderName.WET_SIGN_DOCS}Folder.folderName`,
        label: "the folder name",
      }),
    }),
  )(values);
}

function isEncompassContainer(folderName: string | undefined | null) {
  return Object.values<string | undefined | null>(EncompassContainers).includes(folderName);
}

const DEFAULT_FOLDER_STRATEGY_OPTIONS = Object.freeze([
  {
    value: FolderPushBackStrategiesEnum.MERGED,
    label: (
      <FormattedMessage
        id="669cc04a-1687-4a09-b7e5-32bdf82fcca7"
        defaultMessage="As a merged pdf"
      />
    ),
  },
  {
    value: FolderPushBackStrategiesEnum.INDIVIDUAL,
    label: (
      <FormattedMessage
        id="c9e95eb9-c324-47ce-b6d2-9b049cdba0c0"
        defaultMessage="As single pdf(s)"
      />
    ),
  },
  {
    value: FolderPushBackStrategiesEnum.IN_PLACE,
    label: (
      <FormattedMessage
        id="c9ec743f-7a1d-4be9-9bd6-4cedd63b8b02"
        defaultMessage="In their original folder(s)"
      />
    ),
  },
]);

const DefaultFolder = memo(
  ({
    change,
    currentFolderName,
    currentStrategy,
    overriddenFolderName,
    name,
  }: {
    change(a: string, b: string | null): void;
    currentFolderName: string | undefined | null;
    currentStrategy: FolderPushBackStrategiesEnum | undefined;
    overriddenFolderName: string | undefined;
    name: EncompassFolderName;
  }) => {
    const folderNameComponent = (
      <>
        <div>
          <DeprecatedSelectField
            name={`${name}FolderFolderName`}
            useStyledInput
            items={
              currentStrategy === FolderPushBackStrategiesEnum.IN_PLACE
                ? IN_PLACE_FOLDER_OPTIONS_ITEMS
                : DEFAULT_FOLDER_OPTIONS_ITEMS
            }
            clearable={false}
            searchable={false}
            onChange={(v: string) => {
              if (v === CUSTOM_FOLDER) {
                if (isEncompassContainer(currentFolderName)) {
                  change(`${name}Folder.folderName`, null);
                }
              } else {
                change(`${name}Folder.folderName`, v);
              }
            }}
          />
        </div>
        {overriddenFolderName === CUSTOM_FOLDER && (
          <div>
            <DeprecatedTextField
              name={`${name}Folder.folderName`}
              placeholder="My Folder"
              useStyledInput
            />
            <FormGroupErrors fields={[`${name}Folder.folderName`]} />
          </div>
        )}
      </>
    );

    return (
      <>
        <div className="EncompassInstanceForm--Title">{getFolderLabel(name)}</div>
        <div className="EncompassFolderRow">
          <div>
            <DeprecatedSelectField
              name="defaultFolder.strategy"
              useStyledInput
              items={DEFAULT_FOLDER_STRATEGY_OPTIONS}
              clearable={false}
              searchable={false}
              onChange={() => {
                change("defaultFolderFolderName", EncompassContainers.FILE_MANAGER);
                change("defaultFolder.folderName", EncompassContainers.FILE_MANAGER);
              }}
            />
          </div>
          {currentStrategy !== FolderPushBackStrategiesEnum.IN_PLACE && folderNameComponent}
        </div>
        {currentStrategy === FolderPushBackStrategiesEnum.IN_PLACE && (
          <>
            <div className="EncompassInstanceForm--Title">
              <FormattedMessage
                id="5442528d-7a31-45c3-9388-6ef30933f593"
                defaultMessage="Where do you want the original unsigned versions to be moved to?"
              />
            </div>
            <div className="EncompassFolderRow">{folderNameComponent}</div>
          </>
        )}
      </>
    );
  },
);

const EncompassFolder = memo(
  ({
    change,
    active,
    currentFolderName,
    overriddenFolderName,
    name,
  }: {
    change(a: string, b: string | null): void;
    active: boolean | undefined;
    currentFolderName: string | undefined | null;
    overriddenFolderName: string | undefined;
    name: EncompassFolderName;
  }) => {
    return (
      <>
        <div className="EncompassInstanceForm--Title">{getFolderLabel(name)}</div>
        <div className="EncompassFolderRow">
          <div className="test">
            <DeprecatedRadioButtonField
              name={`${name}Folder.active`}
              labelText="No"
              radioValue={false}
              size="small"
              onChange={() => {
                change(`${name}Folder.folderName`, EncompassContainers.FILE_MANAGER);
              }}
            />
            <DeprecatedRadioButtonField
              name={`${name}Folder.active`}
              labelText="Yes"
              radioValue
              size="small"
            />
          </div>
          {active && (
            <>
              <div>
                <DeprecatedSelectField
                  name={`${name}FolderFolderName`}
                  useStyledInput
                  items={DEFAULT_FOLDER_OPTIONS_ITEMS}
                  clearable={false}
                  searchable={false}
                  onChange={(v: string) => {
                    if (v === CUSTOM_FOLDER) {
                      if (isEncompassContainer(currentFolderName)) {
                        change(`${name}Folder.folderName`, null);
                      }
                    } else {
                      change(`${name}Folder.folderName`, v);
                    }
                  }}
                />
              </div>
              {overriddenFolderName === CUSTOM_FOLDER && (
                <div>
                  <DeprecatedTextField
                    name={`${name}Folder.folderName`}
                    placeholder="My Folder"
                    useStyledInput
                  />
                  <FormGroupErrors fields={[`${name}Folder.folderName`]} />
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  },
);

function EncompassInstanceDocumentsTab({
  change,
  encompassInstance: {
    id,
    documentsToPull,
    templateDuplicationFilter,
    defaultFolder,
    closingDisclosuresFolder,
    auditTrailFolder,
    wetSignDocsFolder,
  },
  handleSubmit,
  initialize,
  isAdmin,
  formValues,
}: InnerProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertKind, setAlertKind] = useState<"success" | "danger" | null>(null);
  const updateEncompassInstanceMutateFn = useMutation(UpdateEncompassInstanceMutation);

  const onSubmit = (values: FormValues) => {
    const {
      defaultFolder,
      closingDisclosuresFolder,
      auditTrailFolder,
      wetSignDocsFolder,
      documentsToPull,
      templateDuplicationFilter,
    } = values;
    setIsSubmitting(true);

    return updateEncompassInstanceMutateFn({
      variables: {
        input: {
          id,
          documentsToPull: documentsToPull
            .split("\n")
            .map((name) => name.trim())
            .filter(Boolean),
          templateDuplicationFilter,
          folders: [
            {
              id: defaultFolder!.id,
              active: defaultFolder!.active,
              strategy: defaultFolder!.strategy,
              folderName: defaultFolder!.folderName,
              templateTags: defaultFolder!.templateTags,
            },
            {
              id: closingDisclosuresFolder!.id,
              active: closingDisclosuresFolder!.active,
              strategy: closingDisclosuresFolder!.strategy,
              folderName: closingDisclosuresFolder!.folderName,
              templateTags: closingDisclosuresFolder!.templateTags,
            },
            {
              id: auditTrailFolder!.id,
              active: auditTrailFolder!.active,
              folderName: auditTrailFolder!.folderName,
              templateTags: auditTrailFolder!.templateTags,
            },
            {
              id: wetSignDocsFolder!.id,
              active: wetSignDocsFolder!.active,
              strategy: wetSignDocsFolder!.strategy,
              folderName: wetSignDocsFolder!.folderName,
              templateTags: wetSignDocsFolder!.templateTags,
            },
          ],
        },
      },
    })
      .then(() => setAlertKind("success"))
      .catch((err: Error) => {
        if (isGraphQLError(err)) {
          captureException(err);
        }
        setAlertKind("danger");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    initialize({
      defaultFolder,
      defaultFolderFolderName: isEncompassContainer(defaultFolder.folderName)
        ? defaultFolder.folderName
        : CUSTOM_FOLDER,
      closingDisclosuresFolder,
      closingDisclosuresFolderFolderName: isEncompassContainer(closingDisclosuresFolder.folderName)
        ? closingDisclosuresFolder.folderName
        : CUSTOM_FOLDER,
      auditTrailFolder,
      auditTrailFolderFolderName: isEncompassContainer(auditTrailFolder.folderName)
        ? auditTrailFolder.folderName
        : CUSTOM_FOLDER,
      wetSignDocsFolder,
      wetSignDocsFolderFolderName: isEncompassContainer(wetSignDocsFolder.folderName)
        ? wetSignDocsFolder.folderName
        : CUSTOM_FOLDER,
      documentsToPull: documentsToPull.join("\n"),
      templateDuplicationFilter,
    });
  }, []);

  return (
    <form
      name="encompassDocumentsForm"
      className="EncompassInstanceForm"
      data-automation-id="encompass-documents-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {alertKind && (
        <AlertMessage kind={alertKind} centerText>
          <FormattedMessage
            id="a4dad0b1-3c2d-44fa-98c1-026f51fc328d"
            defaultMessage="{alertKind, select, success{Changes saved successfully} other{Hmm. Looks like something went wrong. We were unable to save your changes.}}"
            values={{ alertKind }}
          />
        </AlertMessage>
      )}
      <DefaultFolder
        change={change}
        currentStrategy={formValues?.defaultFolder?.strategy}
        currentFolderName={formValues?.defaultFolder?.folderName}
        overriddenFolderName={formValues?.defaultFolderFolderName}
        name={EncompassFolderName.DEFAULT}
      />
      {isAdmin && (
        <EncompassFolder
          change={change}
          active={formValues?.closingDisclosuresFolder?.active}
          currentFolderName={formValues?.closingDisclosuresFolder?.folderName}
          overriddenFolderName={formValues?.closingDisclosuresFolderFolderName}
          name={EncompassFolderName.CLOSING_DISCLOSURES}
        />
      )}
      <EncompassFolder
        change={change}
        active={formValues?.auditTrailFolder?.active}
        currentFolderName={formValues?.auditTrailFolder?.folderName}
        overriddenFolderName={formValues?.auditTrailFolderFolderName}
        name={EncompassFolderName.AUDIT_TRAIL}
      />
      <EncompassFolder
        change={change}
        active={formValues?.wetSignDocsFolder?.active}
        currentFolderName={formValues?.wetSignDocsFolder?.folderName}
        overriddenFolderName={formValues?.wetSignDocsFolderFolderName}
        name={EncompassFolderName.WET_SIGN_DOCS}
      />
      <div className="EncompassInstanceForm--Title">
        <FormattedMessage
          id="5463d8be-f46a-485e-ba49-7712042d7d26"
          defaultMessage="Documents to pull"
        />
      </div>
      <div className="EncompassInstanceForm--SecondaryTitle">
        <FormattedMessage
          id="fa9a0664-8832-45db-bd87-0a41c1a5f9c6"
          defaultMessage="Enter the names of documents to pull form Encompass <b>on the initial Place Order</b>. Leave blank to pull all documents with <b>Closing Document type</b>."
          values={{ b }}
        />
      </div>
      <div className="EncompassInstanceForm--SecondaryTitle">
        <FormattedMessage
          id="484b489f-1366-43bc-a5d1-b39dd72e9ebc"
          defaultMessage="Enter one name per line:"
        />
      </div>
      <DeprecatedSubForm>
        <DeprecatedSubFormSection>
          <DeprecatedTextAreaField
            name="documentsToPull"
            data-automation-id="encompass-documents-to-pull"
            placeholder="Document A&#10;Document B&#10;Document C&#10;..."
          />
          <FormGroupErrors fields={["documentsToPull"]} />
        </DeprecatedSubFormSection>
      </DeprecatedSubForm>
      {isAdmin && (
        <>
          <div className="EncompassInstanceForm--Title">
            <FormattedMessage
              id="55499129-69b6-4e88-89aa-5030181cdf44"
              defaultMessage="Activate the template-base duplication filter?"
            />
          </div>
          <div className="EncompassInstanceForm--SecondaryTitle">
            <FormattedMessage
              id="395acb88-097c-4fdd-9385-284d66b3fd7a"
              defaultMessage="Activate the filter to remove duplicate documents, matched by template, pulled from different Encompass folders."
            />
          </div>
          <DeprecatedSubForm>
            <DeprecatedSubFormSection>
              <DeprecatedFormRow className="EncompassInstanceForm--FormRow">
                <DeprecatedRadioButtonField
                  name="templateDuplicationFilter"
                  automationId="encompass-documents-template-duplication-filter-no"
                  labelText="No"
                  radioValue={false}
                  size="small"
                />
                <DeprecatedRadioButtonField
                  name="templateDuplicationFilter"
                  automationId="encompass-documents-template-duplication-filter-yes"
                  labelText="Yes"
                  radioValue
                  size="small"
                />
                <FormGroupErrors fields={["templateDuplicationFilter"]} />
              </DeprecatedFormRow>
            </DeprecatedSubFormSection>
          </DeprecatedSubForm>
        </>
      )}
      <Button
        buttonColor="action"
        variant="primary"
        type="submit"
        className="EncompassInstanceFormButton"
        automationId="encompass-documents-form-submit"
        isLoading={isSubmitting}
      >
        <FormattedMessage id="45182848-891f-4301-95fa-abc1a7d55676" defaultMessage="Save" />
      </Button>
    </form>
  );
}

export default compose(
  reduxForm<FormValues, Props>({ form: "encompassDocumentsForm", validate }),
  getFormValues<InnerProps>("encompassDocumentsForm"),
)(EncompassInstanceDocumentsTab);
