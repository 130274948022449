import {
  cloneElement,
  type ReactNode,
  type ComponentProps,
  type ReactElement,
  type AriaAttributes,
} from "react";
import classnames from "classnames";
import { FocusScope } from "@react-aria/focus";
import { defineMessages, useIntl } from "react-intl";

import { Portal, useId } from "util/html";
import type Button from "common/core/button";
import { IconButton } from "common/core/button/icon_button";
import Svg from "common/core/svg";
import { FullLogo } from "common/core/logo";
import ClickOutside from "common/core/click_outside";
import { useA11y } from "common/accessibility";

import Overlay from "../overlay";
import "./index.scss";

const CX_NAME = "IllustrationModal";

const MESSAGES = defineMessages({
  closeDialog: { id: "a57b3ddb-0dc5-4526-98d9-362cca7349c9", defaultMessage: "Close modal" },
  goBack: { id: "1606307a-9958-4036-8a1b-43378d3a9013", defaultMessage: "Go back" },
});

type ButtonProps = ComponentProps<typeof Button>;
type ButtonElem = ReactElement<ButtonProps> | false | null | undefined;
type Props = {
  className?: string;
  src?: string;
  alt?: string;
  title: ReactNode;
  children?: ReactNode;
  buttons?: ButtonElem[];
  footer?: ReactNode;
  largeIllustration?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  overlayBackground?: ComponentProps<typeof Overlay>["background"];
  logo?: string;
  automationPrefix?: string;
  documentTitle?: string;
  autoFocus?: boolean;
  titleAriaLive?: AriaAttributes["aria-live"];
  overlayClassName?: string;
  isSensitive?: boolean;
};

function isRenderable(elem: ButtonElem): elem is ReactElement<ButtonProps> {
  return Boolean(elem);
}

function IllustrationModal(props: Props) {
  const {
    onBack,
    onClose,
    automationPrefix = "illustration-modal",
    src,
    children,
    buttons,
    footer,
    title,
    documentTitle,
    autoFocus,
    titleAriaLive,
    overlayClassName,
    isSensitive = true,
  } = props;
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: documentTitle,
    disableAnnounceTitle: autoFocus,
  });
  const intl = useIntl();
  const cx = classnames(CX_NAME, props.className);
  const imageCx = classnames(`${CX_NAME}--illustration`, {
    [`${CX_NAME}--illustration__large`]: props.largeIllustration,
  });
  const modalTitleId = useId();

  return (
    <Portal>
      <Overlay
        background={props.overlayBackground}
        className={overlayClassName}
        sensitive={isSensitive}
      >
        <FocusScope contain autoFocus={autoFocus} restoreFocus>
          <ClickOutside onClickOutside={() => onClose?.()}>
            <div role="dialog" aria-labelledby={modalTitleId} className={`${CX_NAME}--container`}>
              {props.logo && <FullLogo whiteLogo className={`${CX_NAME}--logo`} />}
              <div className={cx} data-automation-id={`${automationPrefix}-container`}>
                {onBack && (
                  <IconButton
                    name="arrow-left"
                    className={`${CX_NAME}--back`}
                    onClick={onBack}
                    automationId={`${automationPrefix}-back`}
                    label={intl.formatMessage(MESSAGES.goBack)}
                    variant="tertiary"
                    buttonColor="dark"
                  />
                )}
                {onClose && (
                  <IconButton
                    name="x-mark"
                    className={`${CX_NAME}--close`}
                    onClick={onClose}
                    automationId={`${automationPrefix}-close`}
                    label={intl.formatMessage(MESSAGES.closeDialog)}
                    variant="tertiary"
                    buttonColor="dark"
                  />
                )}
                {src && <Svg className={imageCx} src={src} alt={props.alt || ""} />}
                <h1
                  id={modalTitleId}
                  className={`${CX_NAME}--title`}
                  aria-live={titleAriaLive}
                  tabIndex={-1}
                >
                  {title}
                </h1>
                {children && <div className={`${CX_NAME}--content`}>{children}</div>}
                {buttons && (
                  <div className={`${CX_NAME}--buttons`}>
                    {buttons.filter(isRenderable).map((button) =>
                      cloneElement(button, {
                        key: button.key as string | number | undefined,
                        className: `${CX_NAME}--buttons--button`,
                        fullwidth: true,
                      }),
                    )}
                  </div>
                )}
              </div>
              {footer && <div className={`${CX_NAME}--footer`}>{footer}</div>}
            </div>
          </ClickOutside>
        </FocusScope>
      </Overlay>
    </Portal>
  );
}

export default IllustrationModal;
