import { type ComponentProps } from "react";
import { useIntl, defineMessages } from "react-intl";

import { TextAreaInput } from "common/core/form/text";
import { Card, ConfiguredField, showField } from "common/transaction_creation/v3/common";

import { CONFIGS, FORM_FIELDS, type SIGNING_DETAILS_SECTION } from "..";

type SigningDetailsSectionProps = ComponentProps<typeof SIGNING_DETAILS_SECTION.Component>;

const MESSAGES = defineMessages({
  signerMessage: {
    id: "5788be44-0abf-49b2-bfd6-469282e1c1a2",
    defaultMessage: "Signer message",
  },
  signerMessageDescription: {
    id: "2284b508-9722-4820-9cc3-7efc50613e1e",
    defaultMessage:
      "You can include a personal note to the signer(s) on the first email they receive to complete their documents.",
  },
  signerMessagePlaceholder: {
    id: "815fd0f3-1193-455b-b26c-ae46bc3a1d95",
    defaultMessage: "Enter a message",
  },
});

export function SignerMessageCard({
  config,
  form,
}: {
  config: SigningDetailsSectionProps["config"];
  form: SigningDetailsSectionProps["form"];
}) {
  const intl = useIntl();

  const showSignerMessage = showField(config, CONFIGS.signerMessage);

  if (!showSignerMessage) {
    return null;
  }

  return (
    <Card
      title={intl.formatMessage(MESSAGES.signerMessage)}
      subtitle={intl.formatMessage(MESSAGES.signerMessageDescription)}
    >
      <ConfiguredField
        config={config}
        configField={CONFIGS.signerMessage}
        form={form}
        name={FORM_FIELDS.emailMessage}
        aria-label={intl.formatMessage(MESSAGES.signerMessage)}
        as={TextAreaInput}
        placeholder={intl.formatMessage(MESSAGES.signerMessagePlaceholder)}
      />
    </Card>
  );
}
