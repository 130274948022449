import { defineMessages, useIntl } from "react-intl";
import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from "react";
import { type IntlShape } from "react-intl/src/types";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { Select } from "common/core/form/select";
import { useForm } from "common/core/form";
import LoadingIndicator from "common/core/loading_indicator";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { defaultRequiredMessage } from "common/core/form/error";
import { Heading } from "common/core/typography";

import ApplySettingProfile from "./graphql/apply_setting_profile_mutation.graphql";
import type { OrgTablePaginationItem } from "../table_with_pagination";
import type { CompanyOrgsPaginated_node_Organization_settingProfiles as AvailableProfile } from "../graphql/company_orgs_paginated.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  modalTitle: {
    id: "639aad20-3767-4d2d-8ff7-7850f8cbc8ba",
    defaultMessage:
      "Edit {profileType} for {itemCount} {itemCount, plural, one {organization} other {organizations}}",
  },
  currentProfiles: {
    id: "65446d1c-ec52-42b4-82ce-6e70133aaa2b",
    defaultMessage: "Current {profileType} {itemProfileCount, plural, one {} other {profiles}}",
  },
  newProfile: {
    id: "336166b4-5d99-4775-b15f-435e44295ef6",
    defaultMessage: "New {profileType}",
  },
  selectNewProfile: {
    id: "8736b7b6-601a-46ed-b003-c43df9c8c955",
    defaultMessage: "Select a {profileType}",
  },
  cancel: {
    id: "6d4fb4c7-fb33-4bf4-9417-7fd2c87cc527",
    defaultMessage: "Cancel",
  },
  saveChanges: {
    id: "948c71b6-fac7-44b1-8d9d-210da87a655d",
    defaultMessage: "Save changes",
  },
  changesSaved: {
    id: "b01ef2fd-abc7-483b-a772-aff6e2f787ab",
    defaultMessage: "Changes saved!",
  },
  applyProfileError: {
    id: "756cb813-bc49-4c10-989e-510b41b3bc60",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  noBrand: {
    id: "11f9691c-ef98-4b9a-8eff-a6142a7e3458",
    defaultMessage: "No {profileType}",
  },
});

type FormFields = {
  profileIdToApply: string;
};

function getAppliedProfilesSet(
  items: OrgTablePaginationItem[],
  profileType: string,
  intl: IntlShape,
) {
  // Loop through each item and check if they have a profile applied of the correct type
  // Store applied profiles in profileSet
  const profileSet = new Set<string>();
  items.forEach((profile) => {
    let addNoProfile = true;
    for (const appliedProfile of profile.appliedSettingProfiles) {
      if (appliedProfile.profileType === profileType.toUpperCase()) {
        profileSet.add(appliedProfile.profileName);
        addNoProfile = false;
        break;
      }
    }
    if (addNoProfile) {
      profileSet.add(intl.formatMessage(MESSAGES.noBrand, { profileType }));
    }
  });

  return profileSet;
}

export function EditProfileModal({
  profileType,
  setShowEditModal,
  items,
  availableProfiles,
}: {
  profileType: string;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  items: OrgTablePaginationItem[];
  availableProfiles: AvailableProfile[];
}) {
  const [initialLoading, setInitialLoading] = useState<boolean>(true); // start component in loading state until we process current applied profiles
  const [appliedProfiles, setAppliedProfiles] = useState<Set<string>>(new Set());
  const intl = useIntl();
  const form = useForm<FormFields>();
  const {
    formState,
    formState: { isSubmitting, isValid },
  } = form;
  const availableSelectProfiles = availableProfiles.map((profile) => ({
    value: profile.id,
    label: profile.profileName,
  }));

  useEffect(() => {
    const profileSet = getAppliedProfilesSet(items, profileType, intl);
    setAppliedProfiles(profileSet);
    setInitialLoading(false);
  }, [items]);

  const applySettingProfileMutation = useMutation(ApplySettingProfile);
  const handleSubmit = useCallback(
    async (formValues: FormFields) => {
      try {
        await applySettingProfileMutation({
          variables: {
            input: {
              orgGids: items.map((item) => item.id),
              profileId: Number(formValues.profileIdToApply),
            },
          },
        });
        setShowEditModal(false);
        pushNotification({
          position: "topCenter",
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.changesSaved),
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        });
      } catch {
        pushNotification({
          position: "topCenter",
          type: NOTIFICATION_TYPES.DEFAULT,
          message: intl.formatMessage(MESSAGES.applyProfileError),
          subtype: NOTIFICATION_SUBTYPES.ERROR,
        });
      }
    },
    [formState],
  );

  if (initialLoading) {
    return <LoadingIndicator />;
  }

  return (
    <WorkflowModal
      large
      footerSeparator={false}
      title={intl.formatMessage(MESSAGES.modalTitle, {
        profileType,
        itemCount: items.length,
      })}
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          onClick={() => setShowEditModal(false)}
          disabled={isSubmitting}
        >
          {intl.formatMessage(MESSAGES.cancel)}
        </Button>,
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid}
          onClick={form.handleSubmit(handleSubmit)}
        >
          {intl.formatMessage(MESSAGES.saveChanges)}
        </Button>,
      ]}
    >
      <Heading className={Styles.currentProfilesLabel} textStyle={"headingSix"} level={"h3"}>
        {intl.formatMessage(MESSAGES.currentProfiles, {
          profileType,
          itemProfileCount: appliedProfiles.size,
        })}
      </Heading>

      {appliedProfiles.size > 0 && (
        <ul className={Styles.appliedProfileList}>
          {[...appliedProfiles.values()].map((profile, i) => (
            <li key={i}>{profile}</li>
          ))}
        </ul>
      )}

      <Select
        label={intl.formatMessage(MESSAGES.newProfile, { profileType })}
        disabled={isSubmitting}
        items={availableSelectProfiles}
        placeholder={intl.formatMessage(MESSAGES.selectNewProfile, { profileType })}
        aria-invalid={false}
        {...form.register("profileIdToApply", { required: defaultRequiredMessage(intl) })}
      />
    </WorkflowModal>
  );
}
