import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Hr } from "common/core/horizontal_rule";
import { CardHeading } from "common/core/card";
import type {
  FullAdminOrganizationDetails_organization_Organization as Organization,
  FullAdminOrganizationDetails_viewer as Viewer,
  FullAdminOrganizationDetails_organization_Organization_closingStates as ClosingState,
} from "admin_portal/company/details_query.graphql";

import UpsertInterstatePreferenceForm from "./upsert_interstate_preference_form";
import UpdateIntraStatesPreferenceForm from "./update_intrastates_preference_form";
import { useMortgageClosingStatePreferences } from "./util";

type InnerPreferenceProps = {
  organization: Organization;
  viewer: Viewer;
};

function ClosingPreferences({ organization, viewer: { usStates } }: InnerPreferenceProps) {
  const usStateListOptions = useMemo(() => {
    return (
      usStates?.map((state) => ({
        label: state!.name,
        value: state!.id,
      })) ?? []
    );
  }, [usStates]);
  const eligibleClosingStates = useMemo(
    () =>
      (organization.closingStates?.filter((closingState) => closingState) ?? []) as ClosingState[],
    [organization],
  );
  const propertyStateOptions = useMemo(() => {
    return eligibleClosingStates
      .filter((closingState) => closingState.specificUsStates.length === 0)
      .map((closingState) => {
        const { usState } = closingState;
        return {
          label: usState.name,
          value: closingState.id,
        };
      });
  }, [eligibleClosingStates]);

  const {
    intraStatePreferenceChange,
    updateClosingStatePreference,
    deleteClosingStatePreference,
    addClosingStatePreference,
  } = useMortgageClosingStatePreferences(eligibleClosingStates);

  return (
    <>
      <CardHeading level="h3">
        <FormattedMessage
          id="e96ef8a8-75ce-4dab-8061-7e87ca726913"
          defaultMessage="Closing States Requirements"
        />
      </CardHeading>
      <Hr />

      <UpdateIntraStatesPreferenceForm
        eligibleClosingStates={eligibleClosingStates}
        propertyStateOptions={propertyStateOptions}
        intraStatePreferenceChange={intraStatePreferenceChange}
      />

      <UpsertInterstatePreferenceForm
        eligibleClosingStates={eligibleClosingStates}
        propertyStateOptions={propertyStateOptions}
        usStateListOptions={usStateListOptions}
        deleteClosingStatePreference={deleteClosingStatePreference}
        addClosingStatePreference={addClosingStatePreference}
        updateClosingStatePreference={updateClosingStatePreference}
      />
    </>
  );
}

export default ClosingPreferences;
