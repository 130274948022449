import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import BrandedAppFrame from "common/app_frame/organization_transaction";

import Container from "./container";
import { AppFrameHeader } from "./header/header";
import { CustomerFacingWarning } from "./header/dev";
import { renderSuffixText } from "./header/logo";
import { SkipToContent } from "../skip_to_content";
import type { DefaultAppFrameData_viewer_user as User } from "./default_query.graphql";

type Props = {
  children: ReactNode;
  content?: ReactNode;
  subtitle?: ReactNode;
  onLogoClick?: () => void;
  staticPage?: boolean;
  showHeader?: boolean;
  noBorder?: boolean;
  partnerLogo?: string | null;
  orgName?: string | null;
  showOrgName?: boolean;
  enableReferralBranding?: boolean;
  banners?: ReactNode;
  user?: User | null;
  showOrganizationBrand?: boolean;
  documentBundleId?: string;
  removeNavLink?: boolean;
};
/*
 * @prop {func} onLogoClick Function to apply if logo is clicked.
 * Defaults to going to home if staticPage is false; otherwise,
 * defaults to a noop.
 */
/*
 * @prop {bool} staticPage Whether the page is navigable or not
 * i.e. can we click the logo to go "home".
 */
export default function AppFrame({
  children,
  content,
  subtitle,
  onLogoClick,
  orgName,
  banners,
  user,
  showOrganizationBrand,
  enableReferralBranding,
  documentBundleId,
  partnerLogo = null,
  staticPage = false,
  showHeader = true,
  noBorder = false,
  showOrgName = false,
  removeNavLink = false,
}: Props) {
  const navigate = useNavigate();

  const onLogoClickCoerced =
    onLogoClick !== undefined ? onLogoClick : staticPage ? () => {} : () => navigate("/");
  const organization = showOrgName && user?.organization ? user.organization : null;
  const logoSuffixText = renderSuffixText(user?.accountTypes || null);
  const showLogo = showOrganizationBrand && partnerLogo;

  let header = null;
  if ((showHeader && documentBundleId) || showLogo) {
    header = (
      <BrandedAppFrame
        documentBundleId={documentBundleId!}
        content={content}
        enableReferralBranding={enableReferralBranding}
        removeNavLink={removeNavLink}
      />
    );
  } else if (showHeader) {
    header = (
      <>
        <CustomerFacingWarning />
        <AppFrameHeader
          organization={organization}
          logoSuffixText={logoSuffixText}
          subtitle={subtitle}
          staticPage={staticPage}
          noBorder={noBorder}
          onLogoClickCoerced={onLogoClickCoerced}
          partnerLogo={partnerLogo}
          orgName={orgName}
          content={content}
          removeNavLink={removeNavLink}
        />
      </>
    );
  }

  return (
    <div className="AppFrame">
      {banners}
      <SkipToContent />
      {header}
      <Container>{children}</Container>
    </div>
  );
}
