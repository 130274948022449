import { ImageCapture } from "./image_capture";
import { TransactionHistory } from "./transaction_history";
import type { Body as OrganizationCustomer } from "./index.fragment.graphql";

type Props = {
  organizationId: string;
  organizationCustomer: OrganizationCustomer;
  proofDefend: boolean;
};

export function Body({ organizationId, organizationCustomer, proofDefend }: Props) {
  const { userId } = organizationCustomer;
  return (
    <>
      {proofDefend && <ImageCapture organizationCustomer={organizationCustomer} />}

      <TransactionHistory
        organizationId={organizationId}
        userId={userId}
        proofDefend={proofDefend}
      />
    </>
  );
}
