import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { parseISO } from "date-fns";

import { Card } from "common/core/card";
import { Heading, Paragraph } from "common/core/typography";
import { Badge } from "common/core/badge";
import RiskLevel from "common/dashboard/risk_level";
import { format, formatDuration } from "common/core/format/date";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { PROOF_DEFEND_URL } from "constants/marketing";
import IdentityShield from "assets/images/identity-shield.svg";

import type { Sidebar as OrganizationCustomer } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  organizationCustomer: OrganizationCustomer;
  proofDefend: boolean;
};

function CardHeading({ children }: { children: ReactNode }) {
  return (
    <Heading
      className={Styles.cardHeading}
      textStyle="allCapsLabelSmall"
      level="h3"
      textColor="subtle"
    >
      {children}
    </Heading>
  );
}

function CardFooter({ children }: { children: ReactNode }) {
  return (
    <div className={Styles.cardFooter}>
      <Paragraph textColor="subtle">{children}</Paragraph>
    </div>
  );
}

export function Sidebar({ organizationCustomer, proofDefend }: Props) {
  const { accountCreatedAt, highestRiskSignerIdentity, totalTransactions } = organizationCustomer;
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;

  const showRiskData = proofDefend || isKeystone;

  return (
    <>
      {showRiskData && (
        <Card className={Styles.card} containerClassName={Styles.cardContent} fullWidth noMargin>
          <CardHeading>
            <FormattedMessage
              id="6784c830-51db-4ecf-9f10-a5ee0c8b5ad9"
              defaultMessage="Risk Score"
            />
          </CardHeading>
          <RiskLevel
            level={highestRiskSignerIdentity?.aggregateRiskLevel ?? null}
            textLength="medium"
          />
          <CardFooter>
            {highestRiskSignerIdentity ? (
              <FormattedMessage
                id="1a128546-6cb4-4fbf-a117-2a26e8351047"
                defaultMessage="Based on the highest recorded risk score for this identity, recorded on {createdAt}."
                values={{
                  createdAt: format({
                    value: parseISO(highestRiskSignerIdentity.createdAt),
                    formatStyle: "MMMM d, yyyy",
                  }),
                }}
              />
            ) : (
              <FormattedMessage
                id="3898a30c-b078-4ef2-af66-a02a852c370d"
                defaultMessage="This user does not have a Proof generated risk score yet."
              />
            )}
          </CardFooter>
        </Card>
      )}
      <Card className={Styles.card} containerClassName={Styles.cardContent} fullWidth noMargin>
        <CardHeading>
          <FormattedMessage
            id="f600618f-235d-4c9a-8eb5-a273d0182ee5"
            defaultMessage="Transactions"
          />
        </CardHeading>
        <Badge>
          <FormattedMessage
            id="7577d14b-43e2-42ee-8de6-a6f39d3ca377"
            defaultMessage="{totalTransactions} total"
            values={{ totalTransactions }}
          />
        </Badge>
        <CardFooter>
          <FormattedMessage
            id="13e1b1df-c3ed-4707-8602-ecbde5208904"
            defaultMessage="Based on transactions associated with {isKeystone, select, true{this} other{your}} company in Proof."
            values={{ isKeystone }}
          />
        </CardFooter>
      </Card>
      {showRiskData && (
        <Card className={Styles.card} containerClassName={Styles.cardContent} fullWidth noMargin>
          <CardHeading>
            <FormattedMessage
              id="d02a9918-9d6d-44af-a2a7-b9aa7a6bbf3e"
              defaultMessage="Account Age"
            />
          </CardHeading>
          <Badge>
            <FormattedMessage
              id="1f3a23fd-f19f-4afc-910c-7e09fdbda1e9"
              defaultMessage="{createdAt} old"
              values={{
                createdAt: formatDuration({
                  from: accountCreatedAt,
                  includeSeconds: false,
                  addRelativeIndicator: false,
                }),
              }}
            />
          </Badge>
          <CardFooter>
            <FormattedMessage
              id="af391ddd-89ed-43aa-b930-8a6901d2b559"
              defaultMessage="Based on this user’s account creation date within the Proof or Notarize ecosystem."
            />
          </CardFooter>
        </Card>
      )}
      {!showRiskData && (
        <Card className={Styles.card} containerClassName={Styles.cardContent} fullWidth noMargin>
          <CardHeading>
            <Icon name="lock" />
            <FormattedMessage
              id="85abf10c-2fc4-4215-9a18-fa108be3bcb1"
              defaultMessage="Unlock Defend"
            />
          </CardHeading>
          <img src={IdentityShield} alt="" />
          <CardFooter>
            <FormattedMessage
              id="754d997d-1d45-47c0-b01c-012ed0017d4c"
              defaultMessage="Get access to detailed behavioral risk signals for enhanced fraud monitoring and detection."
              tagName="div"
            />
            <Link underlined={false} href={PROOF_DEFEND_URL}>
              <FormattedMessage
                id="4e4a0dd1-7592-4dea-97ef-5e5bae8724d5"
                defaultMessage="Get Defend"
              />
            </Link>
          </CardFooter>
        </Card>
      )}
    </>
  );
}
