import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { usePermissions } from "common/core/current_user_role";
import { pushNotification } from "common/core/notification_center/actions";
import { useFeatureFlag } from "common/feature_gating";
import CreateOrganizationDocumentTemplateMutation from "common/organization_templates/create_organization_document_template_mutation.graphql";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { Feature, type DocumentRequirementEnum } from "graphql_globals";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { hardNavigateTo } from "util/navigation";

type DocumentEdge = {
  document: {
    id: string;
    name: string | null;
    signerCanAnnotate: boolean | null;
    witnessRequired: boolean;
    requirement?: DocumentRequirementEnum | null;
    documentToCopyId?: string;
  };
};

export function documentsInput(documentEdges: DocumentEdge[]) {
  return documentEdges.map((edge) => {
    const {
      id,
      name,
      signerCanAnnotate: customerCanAnnotate,
      witnessRequired,
      requirement,
      documentToCopyId,
    } = edge.document;
    return {
      id,
      name,
      customerCanAnnotate,
      witnessRequired,
      requirement,
      documentToCopyId,
    };
  });
}

export function useSaveAsTemplate(
  orgFeatureList: string[],
  {
    id,
    isEnote,
    isConsentForm,
  }: { id: string; isEnote: boolean | null; isConsentForm: boolean | null },
) {
  const saveAsTemplateEnabled = useFeatureFlag("save-as-template");
  const { hasPermissionFor } = usePermissions();
  const saveAsTemplateFn = useMutation(CreateOrganizationDocumentTemplateMutation);
  const [savingTemplate, setSavingTemplate] = useState(false);

  const showSaveAsTemplate =
    saveAsTemplateEnabled &&
    orgFeatureList.includes(Feature.DOCUMENT_TEMPLATES) &&
    (hasPermissionFor("manageTitleTemplates") || hasPermissionFor("manageLenderTemplates")) &&
    !isEnote &&
    !isConsentForm;

  async function onSaveAsTemplateClick() {
    try {
      setSavingTemplate(true);
      const response = await saveAsTemplateFn({
        variables: {
          input: {
            documentId: id,
            preset: hasPermissionFor("templatePreset"),
          },
        },
      });

      const templateId =
        response.data?.createOrganizationDocumentTemplate?.organizationDocumentTemplate?.id;
      setSavingTemplate(false);
      hardNavigateTo(`/templates-dashboard/${templateId}/new`, { newTab: true });
    } catch (error) {
      if (!isGraphQLError(error)) {
        captureException(error);
      }
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: (
          <FormattedMessage
            id="cae4c68c-62d7-440c-82d0-a6aa13724a27"
            defaultMessage="Error saving as template. Please try again."
          />
        ),
      });
      setSavingTemplate(false);
    }
  }

  return {
    showSaveAsTemplate,
    onSaveAsTemplateClick,
    savingTemplate,
  };
}
