import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";

import { format } from "common/core/format/date";
import { Heading } from "common/core/typography";
import { IdentityAttributeLabels } from "common/identity/attributes";
import Icon from "common/core/icon";
import Button from "common/core/button";
import Tooltip from "common/core/tooltip";
import { IconButton } from "common/core/button/icon_button";
import { IdentityAttributeName, IdentityAttributeVerificationStatus } from "graphql_globals";
import { PopoutMenuMultilineItem } from "common/core/popout_menu/multiline";
import PopoutMenu from "common/core/popout_menu";
import { useForm, type UseFormReturn } from "common/core/form";
import { isAriaInvalid } from "common/core/form/error";
import { StyledTextInput } from "common/core/form/text";
import { useMutation } from "util/graphql";
import { Badge } from "common/core/badge";

import type {
  IdentityAttributes as Meeting,
  IdentityAttributes_meetingParticipants_SignerParticipant as Participant,
  IdentityAttributes_meetingParticipants_SignerParticipant_identityAttributes as IdentityAttribute,
} from "./index.graphql";
import UpdateSignerIdentityAttribute from "./update_identity_attribute.mutation.graphql";
import Styles from "./index.module.scss";
import { useTrustedRefereeActionManagerContext } from "../../action_manager";
import { parseIdentityAttributesForParticipant } from "../..";

type FormValues = {
  attributeValue: string;
};

const EditableFields = [IdentityAttributeName.FULL_NAME];

const MESSAGES = defineMessages({
  validateLabel: {
    id: "ddd8b9a8-21ca-4ebe-884c-81f57f8a2b60",
    defaultMessage: "Validate {attributeName}",
  },
  enterLabel: {
    id: "28ef486b-b745-42ff-8211-b27322baf547",
    defaultMessage: "Enter {attributeName}",
  },
  modifyDropdownLabel: {
    id: "6739889e-c3fd-4fc2-81cd-263c53de9c34",
    defaultMessage: "Modify attribute",
  },
});

function AttributeInput({
  attribute,
  form,
}: {
  attribute: IdentityAttribute;
  form: UseFormReturn<FormValues>;
}) {
  const intl = useIntl();
  const { register, formState } = form;
  const { errors } = formState;

  const attributeName = intl.formatMessage(IdentityAttributeLabels[attribute.name]);
  const label = intl.formatMessage(MESSAGES.enterLabel, {
    attributeName,
  });

  return (
    <div className={Styles.attributeValue}>
      <StyledTextInput
        label={label}
        aria-invalid={isAriaInvalid(errors.attributeValue)}
        {...register("attributeValue", { required: true })}
      />
    </div>
  );
}

function AttributeProcedure({ attribute }: { attribute: IdentityAttribute }) {
  return (
    <ol className={Styles.procedure}>
      {attribute.name === IdentityAttributeName.FULL_NAME && (
        <>
          <li>
            <FormattedMessage
              id="32f638a9-44e3-4d2f-8d7b-ae385e591755"
              defaultMessage="Confirm that this name matches the government issued ID"
            />
          </li>
          <li>
            <FormattedMessage
              id="3b97b947-931c-4fd8-852a-671f11b4c107"
              defaultMessage="Initiate mobile retake of government ID if needed"
            />
          </li>
        </>
      )}
      {attribute.name === IdentityAttributeName.DATE_OF_BIRTH && (
        <>
          <li>
            <FormattedMessage
              id="b616f94c-f16a-4c3c-8d0e-eb8325e2a337"
              defaultMessage="This cannot be edited. Initiate retake of government ID if needed"
            />
          </li>
          <li>
            <FormattedMessage
              id="e2ddbc0e-29e4-46b9-b299-02061526cf12"
              defaultMessage="Confirm that this date of birth matches the government issued ID"
            />
          </li>
        </>
      )}
      {attribute.name === IdentityAttributeName.PHONE && (
        <>
          <li>
            <FormattedMessage
              id="250ac8d3-95d3-4796-8944-f5be0d99e198"
              defaultMessage="Ask the signer their phone number"
            />
          </li>
          <li>
            <FormattedMessage
              id="6234e6c8-7b6f-4609-9ba5-7111a71cfad0"
              defaultMessage="Confirm the phone number matches the signer’s number"
            />
          </li>
        </>
      )}
    </ol>
  );
}

function StatusBadge({ attribute }: { attribute: IdentityAttribute }) {
  const { verificationStatus } = attribute;

  switch (verificationStatus) {
    case IdentityAttributeVerificationStatus.QUEUED:
    case IdentityAttributeVerificationStatus.UNRESOLVED:
      return null;
    case IdentityAttributeVerificationStatus.FAILED:
      return (
        <Badge kind="warning" withIcon="warning">
          <FormattedMessage id="bddff668-800c-4174-82f7-a6f155258554" defaultMessage="Invalid" />
        </Badge>
      );
    case IdentityAttributeVerificationStatus.PASSED:
      return (
        <Badge kind="success" withIcon="success">
          <FormattedMessage id="7684492a-c901-432c-9577-fea2ae28615a" defaultMessage="Validated" />
        </Badge>
      );
  }
}

function getAttributeFormValue(attribute: IdentityAttribute) {
  switch (attribute.__typename) {
    case "IdentityAttributeDob":
      return format({ value: attribute.dateOfBirthValue, formatStyle: "MMMM d, yyyy" })!;
    case "IdentityAttributeFullName":
      return attribute.fullNameValue;
    case "IdentityAttributePhoneNumber":
      return parsePhoneNumber(attribute.phoneValue).formatInternational();
  }
}

function useAttributeInteraction({
  attribute,
  participant,
}: {
  attribute: IdentityAttribute;
  participant: Participant;
}) {
  const [modifying, setModifying] = useState(
    [
      IdentityAttributeVerificationStatus.UNRESOLVED,
      IdentityAttributeVerificationStatus.QUEUED,
    ].includes(attribute.verificationStatus),
  );
  const updateSignerIdentityAttribute = useMutation(UpdateSignerIdentityAttribute);
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      attributeValue: getAttributeFormValue(attribute),
    },
    reValidateMode: "onChange",
  });

  const {
    watch,
    formState: { errors },
  } = form;

  const formValue = watch("attributeValue");

  const updateAttributeStatus = async (verificationStatus: IdentityAttributeVerificationStatus) => {
    if (errors.attributeValue) {
      return;
    }

    setLoading(true);

    await updateSignerIdentityAttribute({
      variables: {
        input: {
          identityAttribute: {
            name: attribute.name,
            verificationStatus,
            ...(attribute.name === IdentityAttributeName.FULL_NAME
              ? { fullNameValue: formValue }
              : {}),
          },
          signerParticipantId: participant.id,
        },
      },
    });

    setModifying(false);
    setLoading(false);
  };

  const handleModify = async () => {
    setLoading(true);

    await updateSignerIdentityAttribute({
      variables: {
        input: {
          identityAttribute: {
            name: attribute.name,
            verificationStatus: IdentityAttributeVerificationStatus.UNRESOLVED,
          },
          signerParticipantId: participant.id,
        },
      },
    });

    setLoading(false);
    setModifying(true);
  };

  const validate = () => updateAttributeStatus(IdentityAttributeVerificationStatus.PASSED);

  const invalidate = () => updateAttributeStatus(IdentityAttributeVerificationStatus.FAILED);

  return {
    loading,
    modifying,
    modify: handleModify,
    validate,
    invalidate,
    readOnly: !EditableFields.includes(attribute.name),
    form,
  };
}

function Attribute({
  attribute,
  participant,
  disabled,
  organizationName,
}: {
  attribute: IdentityAttribute;
  participant: Participant;
  disabled: boolean;
  organizationName: string | null;
}) {
  const intl = useIntl();
  const { readOnly, modify, modifying, loading, form, validate, invalidate } =
    useAttributeInteraction({
      attribute,
      participant,
    });
  const {
    meetingCompletion: { caPassed },
  } = useTrustedRefereeActionManagerContext();

  const { errors } = form.formState;

  const attributeName = intl.formatMessage(IdentityAttributeLabels[attribute.name]);
  const attributeValue = getAttributeFormValue(attribute);

  return (
    <div className={Styles.container}>
      {modifying && (
        <>
          <Heading level="h5" textStyle="subtitleSmall" className={Styles.capitalize}>
            {attributeName}
          </Heading>
          <AttributeProcedure attribute={attribute} />
        </>
      )}
      <div className={classnames(Styles.body, modifying && Styles.expanded)}>
        <div>
          {modifying ? (
            <>
              {readOnly ? (
                <>
                  <Heading
                    level="h4"
                    textStyle="subtitleSmall"
                    className={classnames(Styles.capitalize, Styles.validateLabel)}
                  >
                    {intl.formatMessage(MESSAGES.validateLabel, { attributeName })}
                  </Heading>
                  <div className={Styles.attributeValue}>{attributeValue}</div>
                </>
              ) : disabled && attribute.name === IdentityAttributeName.FULL_NAME ? (
                <>
                  <Heading
                    level="h4"
                    textStyle="subtitleSmall"
                    className={classnames(Styles.capitalize, Styles.validateLabel)}
                  >
                    {intl.formatMessage(MESSAGES.validateLabel, { attributeName })}
                    <Tooltip target={<Icon name="question" />}>
                      <FormattedMessage
                        id="f552642c-9606-4294-92c7-acb24776fa92"
                        defaultMessage="If the name is incorrect, the signer must reach out to {organizationName} to correct their name."
                        values={{ organizationName }}
                      />
                    </Tooltip>
                  </Heading>
                  <div className={Styles.attributeValue}>{attributeValue}</div>
                </>
              ) : (
                <AttributeInput attribute={attribute} form={form} />
              )}
            </>
          ) : (
            <>
              <Heading level="h4" textStyle="subtitleSmall" className={Styles.capitalize}>
                {attributeName}
              </Heading>
              <div className={Styles.attributeValue}>{attributeValue}</div>
            </>
          )}
        </div>
        <div>
          {modifying ? (
            <PopoutMenu
              placement="bottomRight"
              hasDropdownArrow
              target={
                <Button
                  buttonColor="action"
                  variant="secondary"
                  disabled={Boolean(errors.attributeValue) || loading}
                  className={Styles.completeButton}
                >
                  <FormattedMessage
                    id="aa16c5be-f5cf-4dd2-8c90-71c8757eb8ac"
                    defaultMessage="Complete validation"
                  />
                </Button>
              }
            >
              {({ close }) => (
                <>
                  <PopoutMenuMultilineItem
                    primaryContent={
                      <div
                        className={classnames(Styles.attributeAction, Styles.pass, {
                          [Styles.disabled]: !caPassed,
                        })}
                      >
                        <Icon name="checkmark" />
                        <FormattedMessage
                          id="6ce1bd19-4356-4f9c-8436-d3a3f32df115"
                          defaultMessage="Validate attribute"
                        />
                      </div>
                    }
                    secondaryContent={
                      !caPassed && (
                        <FormattedMessage
                          id="12cd34eb-60b3-45fb-a62d-1435e867251a"
                          defaultMessage="Resolve identity alerts before validating"
                        />
                      )
                    }
                    onClick={() => {
                      close();
                      validate();
                    }}
                    disabled={!caPassed}
                  />
                  <PopoutMenuMultilineItem
                    primaryContent={
                      <div className={classnames(Styles.attributeAction, Styles.fail)}>
                        <Icon name="x-mark" />
                        <FormattedMessage
                          id="39562644-821b-4c04-a2a2-feae567988ed"
                          defaultMessage="Mark as invalid"
                        />
                      </div>
                    }
                    onClick={() => {
                      close();
                      invalidate();
                    }}
                  />
                </>
              )}
            </PopoutMenu>
          ) : (
            <>
              <StatusBadge attribute={attribute} />
              <PopoutMenu
                placement="bottomRight"
                target={
                  <IconButton
                    name="kebab-menu"
                    buttonColor="dark"
                    variant="tertiary"
                    label={intl.formatMessage(MESSAGES.modifyDropdownLabel)}
                    onClick={() => {}}
                  />
                }
              >
                {({ close }) => (
                  <>
                    <PopoutMenuMultilineItem
                      primaryContent={
                        <div className={classnames(Styles.attributeAction)}>
                          <Icon name="pencil" />
                          <FormattedMessage
                            id="daa428f6-05a8-4cf8-9d21-055367560468"
                            defaultMessage="Make changes"
                          />
                        </div>
                      }
                      onClick={() => {
                        close();
                        modify();
                      }}
                    />
                  </>
                )}
              </PopoutMenu>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default function IdentityAttributes({ meeting }: { meeting: Meeting }) {
  const signerParticipant = meeting.meetingParticipants.find(
    (participant) => participant.__typename === "SignerParticipant",
  );

  if (!signerParticipant) {
    return null;
  }

  const { resolvedAttributes, unresolvedAttributes } =
    parseIdentityAttributesForParticipant(signerParticipant);

  return (
    <>
      <Heading level="h2" textStyle="subtitleSmall">
        <FormattedMessage
          id="a33643f8-c46b-49b4-99a7-2b6ee31a274b"
          defaultMessage="Identity attributes"
        />
      </Heading>
      {Boolean(unresolvedAttributes.length) && (
        <>
          <Heading textStyle="allCapsLabelSmall" textColor="subtle" level="h3">
            <FormattedMessage
              id="2ef88226-2ebc-4f86-af31-8b72bf145a87"
              defaultMessage="Unresolved"
            />
          </Heading>
          {unresolvedAttributes.map((attribute) => (
            <Attribute
              key={attribute.name}
              attribute={attribute}
              participant={signerParticipant}
              disabled={meeting.organizationTransaction.lockRecipientName}
              organizationName={meeting.organizationName}
            />
          ))}
        </>
      )}
      {Boolean(resolvedAttributes.length) && (
        <>
          <Heading textStyle="allCapsLabelSmall" textColor="subtle" level="h3">
            <FormattedMessage id="782e7852-04ad-4f07-98ae-9111da798e73" defaultMessage="Resolved" />
          </Heading>
          {resolvedAttributes.map((attribute) => (
            <Attribute
              key={attribute.name}
              attribute={attribute}
              participant={signerParticipant}
              disabled={meeting.organizationTransaction.lockRecipientName}
              organizationName={meeting.organizationName}
            />
          ))}
        </>
      )}
    </>
  );
}
