import "./deprecated_group_errors.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { Fields } from "redux-form";
import classnames from "classnames";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

import ErrorMessage from "common/core/error_message";

export function getFieldErrorMessageId(name) {
  return `field-error-message-id-${name}`;
}

// NOTE: This component is pretty similar in behavior to FormGroup.
// In fact, most of the time, you're going to just append this next to a FormGroup.
// We haven't directly combined the two yet, because:
// 1) This gives you a slight flexibility in styling.
// 2) The legacy code has been a bitch to change over.

class FormGroupErrorsWithData extends Component {
  state = {
    touched: false,
  };

  componentDidMount() {
    // The associated redux form may have the fields already "touched" on mount
    const anyAlreadyTouched = this.props.names.some(
      (name) => this.getNestedField(this.props, name).meta.touched,
    );
    if (anyAlreadyTouched) {
      this.timeout = setTimeout(() => {
        this.setState({ touched: true });
      }, 0);
    }
  }

  componentDidUpdate() {
    // redux-form status can be manipulated through its Field API
    // in case someone externally "un-touches" this group
    // we should remove the touched state
    const { names } = this.props;
    const anyWillBeTouched = names.some(
      (name) => this.getNestedField(this.props, name).meta.touched,
    );
    if (!anyWillBeTouched && this.state.touched) {
      this.setState({ touched: false });
      return;
    }

    // HACK:
    // Because of how events bubble, any switch between inputs in the same
    // group will go like this:
    // (1) Current active input goes unactive. All inputs are inactive.
    // (2) Next input goes active.
    // The switch is _near_ instant, but because there's a brief moment
    // where everything is inactive, the group believes the user has
    // navigated away, and should show the validations.
    // A timeout of 0s takes advantage of the fact that React synthetic events
    // precede DOM actions, so that the next prop update will intercept it

    if (anyWillBeTouched && !this.state.touched) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setState({ touched: true });
      }, 0);
    }

    const anyWillBeActive = names.some((name) => this.getNestedField(this.props, name).meta.active);

    const submitFailed = names.some(
      (name) => this.getNestedField(this.props, name).meta.submitFailed,
    );

    if (anyWillBeActive && !submitFailed && !this.state.touched && this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getNestedField(props, name) {
    // HACK: ReduxForm passes in the field names in the form of a string
    // that happens to be the exact form that Lodash uses to access nested objects
    // with its "get" function. This is probably by design, but it's not entirely
    // documented. So it's unlikely to break, but it's also possible to...
    return get(props, name);
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.names.map((name) => {
          const error = this.getNestedField(this.props, name).meta.error;
          if (!(this.state.touched && error) || !error.type) {
            return null;
          }

          return (
            <ErrorMessage
              id={getFieldErrorMessageId(name)}
              key={name}
              className={classnames(
                this.props.errorClassName,
                "FormGroupErrors--error validation-failed",
              )}
              error={error}
            />
          );
        })}
      </div>
    );
  }
}

function FormGroupErrors(props) {
  return (
    <Fields
      names={props.fields}
      className={classnames(props.groupClassName, "FormGroupErrors", {
        alert: props.alert,
      })}
      errorClassName={props.errorClassName}
      // Using the Field wrapper will give FormErrorMessagesWithData access
      // to special Redux-Form props that it can use to check for errors and values
      component={FormGroupErrorsWithData}
    />
  );
}

/** @type {{ fields: readonly string[]; groupClassName?: string; errorClassName?: string; alert?: boolean }} */
FormGroupErrors.propTypes = {
  fields: PropTypes.array.isRequired,
  groupClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  alert: PropTypes.bool,
};

FormGroupErrors.defaultProps = {
  fields: [],
  alert: false,
  errorClassName: "",
  groupClassName: "",
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedFormGroupErrors = FormGroupErrors;
