import { defineMessages, useIntl } from "react-intl";

import SaveButton from "common/core/save_button";
import { useForm } from "common/core/form";
import { StyledTextInput } from "common/core/form/text";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { useMutation } from "util/graphql";
import { Select } from "common/core/form/select";
import { SmartsafeCustomField } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { captureException } from "util/exception";

import UpsertSmartsafeIntegrationMutation from "./upsert_smartsafe_integration_mutation.graphql";
import type { SmartSafeCredentials } from "./smartsafe_fragment.graphql";
import Styles from "./smartsafe.module.scss";

const MESSAGES = defineMessages({
  accountId: {
    id: "5be5147a-a9c0-4cf0-87f8-8c1f747cdd8b",
    defaultMessage: "account ID",
  },
  accountIdRequired: {
    id: "03689938-2068-420e-8114-b90ef8cdb1b1",
    defaultMessage: "account ID is required",
  },
  clientId: {
    id: "309470aa-701a-4363-ba0c-b959cb45fcee",
    defaultMessage: "client ID",
  },
  clientIdRequired: {
    id: "ce8ee19e-f256-4997-a388-bb300148a8b9",
    defaultMessage: "client ID is required",
  },
  accountIdNumberFormat: {
    id: "93de8830-9056-410b-be93-9969d1f7e785",
    defaultMessage: "account ID must be a number",
  },
  password: {
    id: "7531daf4-7d01-4bf2-bf5c-310e8df230e0",
    defaultMessage: "password",
  },
  username: {
    id: "efdd604d-1077-4a90-95e9-b2d8aac47d0b",
    defaultMessage: "username",
  },
  usernameRequired: {
    id: "5a29b9ea-1db6-4105-9b6e-06528ac4554f",
    defaultMessage: "username is required",
  },
  apiBaseUri: {
    id: "58381055-a682-4a30-9ad5-d2f1c9d62e31",
    defaultMessage: "API URL",
  },
  apiBaseUriRequired: {
    id: "4fd3956a-f70d-4f02-910a-907e77ae8ea3",
    defaultMessage: "API URL is required",
  },
  customField1: {
    id: "9f4f7d85-79f7-42f3-b8f5-90a7f0359158",
    defaultMessage: "UDF 1",
  },
  customField2: {
    id: "17f6ff69-07dd-4161-9875-3c41dd6be942",
    defaultMessage: "UDF 2",
  },
  customField3: {
    id: "20d7c2b1-b2b5-453f-927d-5874369401c6",
    defaultMessage: "UDF 3",
  },
  saveSuccess: {
    id: "ec1c8613-169d-492b-9d06-e56fe704d80b",
    defaultMessage: "SmartSafe credentials saved successfully.",
  },
  saveError: {
    id: "0291094e-f9ff-4f8e-aac8-80f8a21b273e",
    defaultMessage: "Failed to save SmartSafe credentials.",
  },
});

const CUSTOM_FIELD_OPTIONS = [
  {
    label: "",
    value: "",
  },
  {
    label: "transaction GID",
    value: SmartsafeCustomField.TRANSACTION_GID,
  },
  {
    label: "transaction name",
    value: SmartsafeCustomField.TRANSACTION_NAME,
  },
  {
    label: "transaction external ID",
    value: SmartsafeCustomField.TRANSACTION_EXTERNAL_ID,
  },
];

type Props = {
  organizationId: string;
  smartsafeCredentials: SmartSafeCredentials | null;
};

type FormValues = Omit<SmartSafeCredentials, "customField1" | "customField2" | "customField3"> & {
  password: string;
} & {
  customField1: SmartsafeCustomField | "" | null;
  customField2: SmartsafeCustomField | "" | null;
  customField3: SmartsafeCustomField | "" | null;
};

type SmartSafeCredentialsForMutation = Omit<
  SmartSafeCredentials,
  "customField1" | "customField2" | "customField3"
> & { password?: string } & {
  customField1?: SmartsafeCustomField | null;
  customField2?: SmartsafeCustomField | null;
  customField3?: SmartsafeCustomField | null;
};

export function SmartsafeCredentialsForm({ smartsafeCredentials, organizationId }: Props) {
  const intl = useIntl();
  const updateSmartsafeCredentialsMutateFn = useMutation(UpsertSmartsafeIntegrationMutation);
  const { username, clientId, accountId, apiBaseUri, customField1, customField2, customField3 } =
    smartsafeCredentials || {};
  const form = useForm<FormValues>({
    defaultValues: {
      username,
      clientId,
      accountId,
      apiBaseUri,
      customField1,
      customField2,
      customField3,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields, isDirty },
  } = form;

  const onSubmit = (data: FormValues) => {
    const payload = {
      username: data.username,
      accountId: data.accountId,
      clientId: data.clientId,
      apiBaseUri: data.apiBaseUri,
    } as SmartSafeCredentialsForMutation;
    if (touchedFields.password && data.password !== "") {
      payload.password = data.password;
    }
    if (touchedFields.customField1) {
      payload.customField1 = data.customField1 === "" ? null : data.customField1;
    }
    if (touchedFields.customField2) {
      payload.customField2 = data.customField2 === "" ? null : data.customField2;
    }
    if (touchedFields.customField3) {
      payload.customField3 = data.customField3 === "" ? null : data.customField3;
    }
    return updateSmartsafeCredentialsMutateFn({
      variables: {
        input: {
          organizationId,
          ...payload,
        },
      },
    })
      .then(() => {
        form.reset({
          password: "",
          username: data.username,
          clientId: data.clientId,
          accountId: data.accountId,
          apiBaseUri: data.apiBaseUri,
          customField1: data.customField1,
          customField2: data.customField2,
          customField3: data.customField3,
        });

        pushNotification({
          type: "DEFAULT",
          subtype: "SUCCESS",
          message: intl.formatMessage(MESSAGES.saveSuccess),
        });
      })
      .catch((error) => {
        captureException(error);
        pushNotification({
          type: "DEFAULT",
          subtype: "ERROR",
          message: intl.formatMessage(MESSAGES.saveError),
        });
      });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={Styles.form}>
      <fieldset>
        <div className={Styles.field}>
          <StyledTextInput
            data-automation-id="smartsafe-username"
            aria-label={intl.formatMessage(MESSAGES.username)}
            placeholder={intl.formatMessage(MESSAGES.username)}
            placeholderAsLabel
            aria-invalid={isAriaInvalid(errors.username)}
            {...register("username", {
              required: intl.formatMessage(MESSAGES.usernameRequired),
            })}
          />
          {errors.username && <FormattedFieldError inputName="username" error={errors.username} />}
        </div>
        <div className={Styles.field}>
          <StyledTextInput
            data-automation-id="smartsafe-password"
            aria-label={intl.formatMessage(MESSAGES.password)}
            placeholder={intl.formatMessage(MESSAGES.password)}
            placeholderAsLabel
            aria-invalid={isAriaInvalid(errors.password)}
            {...register("password")}
          />
          {errors.password && <FormattedFieldError inputName="password" error={errors.password} />}
        </div>
        <div className={Styles.field}>
          <StyledTextInput
            data-automation-id="smartsafe-account-id"
            aria-label={intl.formatMessage(MESSAGES.accountId)}
            placeholder={intl.formatMessage(MESSAGES.accountId)}
            placeholderAsLabel
            aria-invalid={isAriaInvalid(errors.accountId)}
            {...register("accountId", {
              required: intl.formatMessage(MESSAGES.accountIdRequired),
              valueAsNumber: true,
              validate: (value) =>
                (typeof value === "number" && value >= 0) ||
                intl.formatMessage(MESSAGES.accountIdNumberFormat),
            })}
          />
          {errors.accountId && (
            <FormattedFieldError inputName="accountId" error={errors.accountId} />
          )}
        </div>
        <div className={Styles.field}>
          <StyledTextInput
            data-automation-id="smartsafe-client-id"
            aria-label={intl.formatMessage(MESSAGES.clientId)}
            placeholder={intl.formatMessage(MESSAGES.clientId)}
            placeholderAsLabel
            aria-invalid={isAriaInvalid(errors.clientId)}
            {...register("clientId", {
              required: intl.formatMessage(MESSAGES.clientIdRequired),
            })}
          />
          {errors.clientId && <FormattedFieldError inputName="clientId" error={errors.clientId} />}
        </div>
        <div className={Styles.field}>
          <StyledTextInput
            data-automation-id="smartsafe-api-base-uri"
            aria-label={intl.formatMessage(MESSAGES.apiBaseUri)}
            placeholder={intl.formatMessage(MESSAGES.apiBaseUri)}
            placeholderAsLabel
            aria-invalid={isAriaInvalid(errors.apiBaseUri)}
            {...register("apiBaseUri", {
              required: intl.formatMessage(MESSAGES.apiBaseUriRequired),
            })}
          />
          {errors.apiBaseUri && (
            <FormattedFieldError inputName="apiBaseUri" error={errors.apiBaseUri} />
          )}
        </div>
        <div className={Styles.field}>
          <Select
            items={CUSTOM_FIELD_OPTIONS}
            {...register("customField1")}
            aria-label={intl.formatMessage(MESSAGES.customField1)}
            aria-invalid={isAriaInvalid(errors.customField1)}
            label={intl.formatMessage(MESSAGES.customField1)}
          />
        </div>
        <div className={Styles.field}>
          <Select
            items={CUSTOM_FIELD_OPTIONS}
            {...register("customField2")}
            aria-label={intl.formatMessage(MESSAGES.customField2)}
            aria-invalid={isAriaInvalid(errors.customField2)}
            label={intl.formatMessage(MESSAGES.customField2)}
          />
        </div>
        <div className={Styles.field}>
          <Select
            items={CUSTOM_FIELD_OPTIONS}
            {...register("customField3")}
            aria-label={intl.formatMessage(MESSAGES.customField3)}
            aria-invalid={isAriaInvalid(errors.customField3)}
            label={intl.formatMessage(MESSAGES.customField3)}
          />
        </div>
      </fieldset>
      {isDirty && (
        <SaveButton
          disabled={isSubmitting}
          isLoading={isSubmitting}
          className={Styles.button}
          title="Save Credentials"
        />
      )}
    </form>
  );
}
