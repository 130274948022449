import type { ComponentProps } from "react";

import TtlExpiredAlert, { isTtlExpired } from "common/details/summary/ttl_expired_alert";
import { usePermissions } from "common/core/current_user_role";
import { EmptyState } from "common/details/common/empty_state";
import { Card } from "common/core/card";
import DocumentProcessingSvg from "assets/images/verify/document-processing.svg";
import { isProcessingComplete } from "util/document_bundle";
import { isStandaloneIdentityVerification } from "util/transaction";

import DocumentsListContainer from "./list.container";
import type { DocumentBundleForTransactionDetailsList } from "./list_fragment.graphql";

type ListContainerProps = ComponentProps<typeof DocumentsListContainer>;
type Props = {
  bundle: DocumentBundleForTransactionDetailsList;
  lastOpenedId: string | null;
  onDocumentSelected: ListContainerProps["onDocumentSelected"];
  onSupplementaryDocSelected: ListContainerProps["onSupplementaryDocSelected"];
  viewable?: boolean;
  downloadable?: boolean;
  shareable?: boolean;
  showHeader?: boolean;
  showLegalInfoItem?: boolean;
  showMergedDocs?: boolean;
  showNotarialActs?: boolean;
  showResponsiveView?: boolean;
};

const DocumentsListEmptyState = () => {
  return (
    <Card noMargin fullWidth>
      <EmptyState standaloneIdentityVerification illustrationSrc={DocumentProcessingSvg} />
    </Card>
  );
};

function DocumentsListWithActions(props: Props) {
  const { bundle, showResponsiveView } = props;
  const { documents, organizationTransaction } = bundle;
  const { hasPermissionFor } = usePermissions();
  const notTtlExpired = !isTtlExpired(documents);
  const processingComplete = isProcessingComplete(bundle);
  const brandName = organizationTransaction.publicOrganization.organizationBrand.name;

  const emptyStateOutput =
    !processingComplete && isStandaloneIdentityVerification(organizationTransaction) ? (
      <DocumentsListEmptyState />
    ) : null;

  return (
    <div
      className={`NotarizationDetails-documents ${
        showResponsiveView ? "NotarizationDetails-responsive" : ""
      }`}
    >
      {!showResponsiveView && (
        <div className="NotarizationDetails-documents-ttlwrapper">
          {brandName && <TtlExpiredAlert orgName={brandName} documents={documents} />}
        </div>
      )}
      {emptyStateOutput || (
        <DocumentsListContainer
          bundle={bundle}
          viewable={props.viewable}
          ttlExpired={!notTtlExpired}
          showMergedDocs={props.showMergedDocs}
          onDocumentSelected={props.onDocumentSelected}
          onSupplementaryDocSelected={props.onSupplementaryDocSelected}
          showHeader={props.showHeader}
          showLegalInfoItem={props.showLegalInfoItem}
          showNotarialActs={props.showNotarialActs}
          canViewSplittingResults={hasPermissionFor("canViewSplittingResults")}
          lastOpenedId={props.lastOpenedId}
          showResponsiveView={showResponsiveView}
          downloadable={props.downloadable}
          shareable={props.shareable}
        />
      )}
    </div>
  );
}

DocumentsListWithActions.defaultProps = {
  downloadable: true,
  showLegalInfoItem: true,
};

export default DocumentsListWithActions;
