import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import {
  InvalidTokenScreenBody,
  InvalidTokenScreenFooter,
} from "common/account/login/proof/screens/proof_invalid_token";
import Button from "common/core/button";
import { HookFormPassword } from "common/account/password/password";
import LoadingIndicator from "common/core/loading_indicator";
import { UserTermsOfService, MasterPrivacyPolicyUrl } from "common/tos";
import ProofBackground from "common/account/login/proof/background";
import ProofCard from "common/account/login/proof/card";
import { Heading, Paragraph } from "common/core/typography";
import { useForm } from "common/core/form";
import { defaultRequiredMessage } from "common/core/form/error";
import { isPasswordStrong } from "util/password";
import { CLIENT_ERROR } from "errors/server";
import { COMPROMISED_PASSWORD } from "redux/actions/authentication";

import Styles from "./index.module.scss";

type FormValues = {
  password: string;
};

type Props = {
  invalidToken?: boolean;
  loading?: boolean;
  setPassword: (data: FormValues) => Promise<unknown>;
  withWelcomeText?: boolean;
  goBack?: () => void;
};

const MESSAGES = defineMessages({
  passwordLabel: {
    id: "b9135b5a-18db-44ea-a2c8-6546ef478cb5",
    defaultMessage: "Password",
  },
  passwordStrengthErrorLabel: {
    id: "b6e45d1b-5459-4829-907f-1c947202fa6b",
    defaultMessage: "Password not strong enough",
  },
  passwordCompromised: {
    id: "109b7a1b-e660-4334-b879-8239a5a73022",
    defaultMessage:
      "The password you are trying to use is either commonly used or has been identified in a data breach on another platform. Please choose a stronger password.",
  },
});
export default function SetPassword({
  invalidToken = false,
  loading = false,
  setPassword,
  withWelcomeText = true,
  goBack,
}: Props) {
  const { formState, register, setFocus, handleSubmit, setError } = useForm<FormValues>();
  const { errors, isSubmitted } = formState;
  const intl = useIntl();

  const onSubmit = (data: FormValues) => {
    setPassword(data).catch((error: { type: unknown; body?: { error?: unknown } }) => {
      if (error.type === CLIENT_ERROR && error.body?.error === COMPROMISED_PASSWORD) {
        setError("password", {
          message: intl.formatMessage(MESSAGES.passwordCompromised),
        });
      }
    });
  };

  return (
    <ProofBackground>
      {loading ? (
        <LoadingIndicator colour="white" />
      ) : (
        <ProofCard
          body={
            <>
              {invalidToken ? (
                <InvalidTokenScreenBody context="activation" />
              ) : (
                <div>
                  {withWelcomeText ? (
                    <div className={Styles.heading}>
                      <Heading textStyle="headingThree" level="h1">
                        <FormattedMessage
                          id="62309040-4733-4958-8c9e-5a1fed564cdc"
                          defaultMessage="Welcome to Proof!"
                        />
                      </Heading>
                      <Heading textStyle="headingFour" level="h2">
                        <FormattedMessage
                          id="06f2bf1c-f94b-4ed5-843b-af87ad2dcd5f"
                          description="header"
                          defaultMessage="Set a password to get started."
                        />
                      </Heading>
                    </div>
                  ) : (
                    <Heading className={Styles.heading} textStyle="headingThree" level="h2">
                      <FormattedMessage
                        id="b07c4d5a-0afe-4e74-89dd-c3bdc89fccb4"
                        defaultMessage="Set your password"
                      />
                    </Heading>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <HookFormPassword
                      setFocus={setFocus}
                      label={intl.formatMessage(MESSAGES.passwordLabel)}
                      registerProps={register("password", {
                        required: defaultRequiredMessage(intl),
                        validate: {
                          isPasswordStrong: (password) =>
                            isPasswordStrong(password) ||
                            intl.formatMessage(MESSAGES.passwordStrengthErrorLabel),
                        },
                      })}
                      invalid={isSubmitted && Boolean(errors.password)}
                      error={errors.password}
                      placeholder={intl.formatMessage(MESSAGES.passwordLabel)}
                      withRequirements
                    />

                    <Button
                      type="submit"
                      data-automation-id="set-password-button"
                      buttonColor="action"
                      buttonSize="large"
                      variant="primary"
                      fullwidth
                    >
                      <FormattedMessage
                        id="3399401c-d961-48a1-aafa-c5b65c4e9076"
                        defaultMessage="Set password"
                      />
                    </Button>
                    <Paragraph className={Styles.tou}>
                      <FormattedMessage
                        id="264fd9a8-a0ce-4f14-b5d4-18691861e9ce"
                        defaultMessage="By clicking 'Set password' and continuing, you are agreeing to our {notaryTermsOfUse}. For information on our privacy and data use practices please see our {privacyPolicy}."
                        values={{
                          notaryTermsOfUse: <UserTermsOfService />,
                          privacyPolicy: <MasterPrivacyPolicyUrl />,
                        }}
                      />
                    </Paragraph>
                  </form>
                </div>
              )}
            </>
          }
          footer={
            invalidToken ? (
              <InvalidTokenScreenFooter />
            ) : goBack ? (
              <Button variant="tertiary" buttonColor="action" onClick={goBack}>
                <FormattedMessage id="cd939d54-a799-4537-995c-0b9d86363612" defaultMessage="Back" />
              </Button>
            ) : null
          }
        />
      )}
    </ProofBackground>
  );
}
