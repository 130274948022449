import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { AutomaticFormRow } from "common/core/form/layout";
import { TextAreaInput } from "common/core/form/text";
import { useForm } from "common/core/form";

import { type RecallOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { type Validation } from "../util";

type FormValues = {
  recallReason: string;
};

export const validateRecall: Validation<Transaction> = ({ transaction, setSubmitErrorModal }) => {
  return new Promise((resolve) => {
    const { recalled, isMortgage } = transaction;
    if (!recalled || !isMortgage) {
      return resolve({ status: "passed" });
    }

    setSubmitErrorModal(
      <RecallModal
        transaction={transaction}
        onCancel={() => {
          setSubmitErrorModal(null);
          resolve({ status: "failed" });
        }}
        onSend={(recallReason) => {
          setSubmitErrorModal(null);
          resolve({ status: "passed", recallReason });
        }}
      />,
    );
  });
};

function RecallModal({
  transaction,
  onCancel,
  onSend,
}: {
  transaction: Transaction;
  onCancel: () => void;
  onSend: (recallReason: string) => void;
}) {
  const form = useForm<FormValues>({
    defaultValues: { recallReason: transaction.recallReason || "" },
  });

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="10b0f8f8-27ef-45de-9253-ae848d895cda"
          defaultMessage="What changed?"
        />
      }
      closeBehavior={{
        tag: "with-button",
        onClose: onCancel,
      }}
      buttons={[
        <Button key="cancel" variant="tertiary" buttonColor="action" onClick={onCancel}>
          <FormattedMessage id="7ec56c00-39f9-4695-96b4-eefb7387a28a" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="send"
          variant="primary"
          buttonColor="action"
          onClick={form.handleSubmit((formValues) => {
            onSend(formValues.recallReason);
          })}
        >
          <FormattedMessage
            id="e0f1999c-ccbb-4c5e-8076-7359ac0f7b6a"
            defaultMessage="Send transaction"
          />
        </Button>,
      ]}
    >
      <AutomaticFormRow
        fullWidth
        label={
          <FormattedMessage
            id="cff58cff-80e0-420f-af93-c433ebe4e951"
            defaultMessage="It's helpful to know why this transaction was recalled"
          />
        }
        form={form}
        name="recallReason"
        registerOptions={{ required: true }}
        as={TextAreaInput}
      />
    </WorkflowModal>
  );
}
