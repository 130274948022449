import type { ComponentProps, ReactNode } from "react";

import { Breadcrumb } from "common/core/breadcrumb";
import { Heading as TypographyHeading } from "common/core/typography";
import { useMatchScreenClass } from "common/core/responsive";
import CopyButton from "common/copy_button";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import Styles from "./index.module.scss";

export function Breadcrumbs(props: ComponentProps<typeof Breadcrumb> & { clipboardValue: string }) {
  const { clipboardValue, ...breadcrumbProps } = props;
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;
  return (
    <div className={Styles.breadcrumb}>
      <Breadcrumb {...breadcrumbProps} />
      {isKeystone && <CopyButton value={clipboardValue} />}
    </div>
  );
}

export function Actions({ children }: { children: ReactNode }) {
  return <div className={Styles.actions}>{children}</div>;
}

export function Info({ children }: { children: ReactNode }) {
  return <div className={Styles.info}>{children}</div>;
}

export function Heading({ children }: { children: ReactNode }) {
  const isMedium = useMatchScreenClass("xs", "sm", "md");
  return (
    <TypographyHeading
      className={Styles.heading}
      level="h1"
      textStyle={isMedium ? "headingFour" : "headingThree"}
    >
      {children}
    </TypographyHeading>
  );
}

export function Container({ children, alert }: { children: ReactNode; alert?: ReactNode }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>{children}</div>
      {alert}
    </div>
  );
}
