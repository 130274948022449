import { FormattedMessage, useIntl, type MessageDescriptor } from "react-intl";
import { useCallback, useState } from "react";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";

import { type UseFormReturn } from "common/core/form";
import { useMutation } from "util/graphql";
import { Badge } from "common/core/badge";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { XML_ACCEPTED } from "util/uploader";
import { IconButton } from "common/core/button/icon_button";
import { Dropzone } from "common/core/dropzone";
import { FieldErrorMessage, isAriaInvalid } from "common/core/form/error";
import Icon from "common/core/icon";
import { CardSection } from "common/core/card";
import Link from "common/core/link";
import RequiredAsterisk from "common/core/form/required-asterisk";
import { StyledTextInput } from "common/core/form/text";
import { RadioGroup, RadioInput, RadioLabel } from "common/core/form/option";
import { isURL } from "util/string";

import Styles from "./index.module.scss";
import DeleteSamlProviderMutation from "./delete-saml-provider.mutation.graphql";
import { type SamlProvider_organization_Organization_samlProviders as SamlProvider } from "../saml_providers.query.graphql";

export const MESSAGES = {
  deleteFailed: {
    id: "5b21f969-8223-4873-810d-6d48c430ca41",
    defaultMessage:
      "Failed to delete identity provider. If this issue persists please contact support.",
  },
  deleteModalTitle: {
    id: "efc5eabd-0006-40ae-9105-74383b091684",
    defaultMessage: "Are you sure you want to delete {idpName}?",
  },
  existingFileDescription: {
    id: "3cbe35c2-724d-4187-b45a-b0faea39511c",
    defaultMessage: "Existing file",
  },
  cancel: {
    id: "a5e67cc0-4434-4b43-b5b6-2b9e26d1b152",
    defaultMessage: "Cancel",
  },
  delete: {
    id: "f785d826-1653-4883-8ed1-1478fe5f0468",
    defaultMessage: "Delete",
  },
  saveChanges: {
    id: "21d3ad0b-c889-4c55-acad-16fa5301122e",
    defaultMessage: "Save changes",
  },
  deleteModalDescription: {
    id: "e21170c3-6262-4b26-a906-01f7f6992190",
    defaultMessage:
      "This identity provider is not in use, so it may be deleted. This action cannot be undone.",
  },
  inUseDeleteModalTitle: {
    id: "a2080a8d-7064-4179-bc08-0df350e1bee7",
    defaultMessage: "You cannot delete this identity provider because it is in use.",
  },
  inUseDeleteModalDescription: {
    id: "50d2024e-f2c0-405c-b30d-64c2fadea993",
    defaultMessage:
      "To delete this, choose a different authentication policy option for the associated domains.",
  },
  dismiss: {
    id: "84a56021-1181-4076-8f22-2e440633d237",
    defaultMessage: "Dismiss",
  },
  deleteSuccess: {
    id: "5692e2ae-73f0-4e77-a79f-8672fc65e9ed",
    defaultMessage: "{idpName} has been deleted.",
  },
  metadataFieldLabel: {
    id: "483134ba-c28b-4c6a-afc2-b8367a4adfdf",
    defaultMessage: "Metadata upload (.xml file)",
  },
  deleteMetadataFileLabel: {
    id: "856c0770-03bd-47c5-b3c1-26516b082d01",
    defaultMessage: "Delete metadata file",
  },
  invalidCertificateError: {
    id: "b4b9abe6-cac4-4597-bcf8-84153499a863",
    defaultMessage: "Invalid certificate",
  },
  missingCertificateError: {
    id: "d346d768-6556-401b-a0fd-b54474fa284d",
    defaultMessage: "You must have at least one certificate",
  },
  missingRequiredInfoError: {
    id: "7f30e0f3-325a-4410-bfef-51a0e5b62a14",
    defaultMessage: "Missing required info. Please verify Name and Metadata file are present",
  },
  idpAlreadyExists: {
    id: "d9a9fe3b-52e0-4d04-8dcb-9a69dd01244d",
    defaultMessage: "Identity provider already exists",
  },
  invalidUrlError: {
    id: "2792bbef-3411-4dd3-95e4-cf8c7a93c8aa",
    defaultMessage: "Invalid URL",
  },
  unknownError: {
    id: "695d242c-89dc-4ade-a142-92b8a40ad7f1",
    defaultMessage: "Unknown error. Please contact customer support if this issue persists.",
  },
  entityIdFieldLabel: {
    id: "6529d1e0-935b-44ef-af8f-2c593354da57",
    defaultMessage: "Entity ID",
  },
  entityIdFieldMaxLength: {
    id: "23246a36-5c25-42cc-b9ba-9715e81a3a4c",
    defaultMessage: "Entity ID is too long",
  },
  certificateFieldLabel: {
    id: "593ed222-b03d-4bad-9ec3-b04c8008eb6e",
    defaultMessage: "X509 public certificate",
  },
  certificateFieldMaxLength: {
    id: "8d8f9fff-060a-4e73-bcec-b0200f738199",
    defaultMessage: "Certificate is too long",
  },
  addCertificate: {
    id: "a424b7ff-3451-424d-9f10-9a946e3c8d0c",
    defaultMessage: "Add another X509 public certificate",
  },
  ssoUrlFieldLabel: {
    id: "2e7d3e95-6cf2-4629-b22a-00a4dd002a77",
    defaultMessage: "Single sign-on (SSO) URL",
  },
  ssoUrlFieldMaxLength: {
    id: "57c0e8ec-5e81-4a03-97e1-dc1e8fa45726",
    defaultMessage: "SSO URL is too long",
  },
  ssoRequestBindingFieldLabel: {
    id: "1c765953-e9ec-4de0-b18b-20a295541594",
    defaultMessage: "SSO request binding",
  },
  sloUrlFieldLabel: {
    id: "fa1700c5-5486-4812-a598-53f46ec617c9",
    defaultMessage: "Single log-out (SLO) URL",
  },
  sloToggleLabel: {
    id: "643705d0-412c-49ef-9a54-1d0265bd1714",
    defaultMessage: "Single log-out (SLO)",
  },
  sloUrlFieldMaxLength: {
    id: "e44f09b4-02ec-4498-a51e-a04a7ba4258f",
    defaultMessage: "SLO URL is too long",
  },
  sloRequestBindingFieldLabel: {
    id: "db070ed1-757a-403b-876c-7e47c525efc5",
    defaultMessage: "SLO request binding",
  },
  httpPost: {
    id: "5e67028f-07c5-4464-9924-b0487c85e4a7",
    defaultMessage: "HTTP POST",
  },
  httpRedirect: {
    id: "d4b260a7-2acc-4aef-bf82-0584b6b57b27",
    defaultMessage: "HTTP redirect",
  },
  missingFieldError: {
    id: "a99bbdd8-48f7-41d5-b474-1477a285720d",
    defaultMessage: "This is required.",
  },
  invalidFieldError: {
    id: "602730d5-c896-4414-b705-879e2573bc0f",
    defaultMessage: "This doesn't look right.",
  },
  deleteCertificate: {
    id: "5cced1a2-42a4-4fc9-8047-00a9404dc27e",
    defaultMessage: "Remove",
  },
};
export function SamlProviderBadge({
  samlProviderId,
  samlDomainsLength,
  className,
}: {
  samlProviderId: string;
  samlDomainsLength: number;
  className?: string;
}) {
  return (
    <>
      {samlDomainsLength > 0 ? (
        <Badge
          kind="success"
          withIcon="success"
          data-automation-id={`${samlProviderId}-badge`}
          className={className}
        >
          <FormattedMessage
            id="d4c6281e-5a53-4dbf-9ed0-e09bf9a139c6"
            defaultMessage="In use ({domainsCount} {domainsCount, plural, one {domain} other {domains}})"
            values={{ domainsCount: samlDomainsLength }}
          />
        </Badge>
      ) : (
        <Badge
          kind="infoBlue"
          withIcon="info"
          data-automation-id={`${samlProviderId}-badge`}
          className={className}
        >
          <FormattedMessage id="e817f800-8b45-4cdb-a777-f077575bd980" defaultMessage="Not in use" />
        </Badge>
      )}
    </>
  );
}

export function DeleteIdpModal({
  idp,
  closeModal,
  onDeleteSuccess,
}: {
  idp: SamlProvider;
  closeModal: () => void;
  onDeleteSuccess: () => void;
}) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const deleteSamlProviderMutation = useMutation(DeleteSamlProviderMutation);
  const deleteSamlProvider = useCallback(() => {
    setIsLoading(true);
    return deleteSamlProviderMutation({
      variables: {
        input: {
          id: idp.id,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(MESSAGES.deleteSuccess, { idpName: idp.name }),
          position: "topCenter",
        });
        onDeleteSuccess();
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(MESSAGES.deleteFailed),
          position: "topCenter",
        });
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  }, [idp, deleteSamlProviderMutation]);

  return idp.domains.length > 0 ? (
    <WorkflowModal
      autoFocus
      positionTop
      title={intl.formatMessage(MESSAGES.inUseDeleteModalTitle)}
      buttons={[
        <Button key="dismiss" buttonColor="action" variant="primary" onClick={closeModal}>
          {intl.formatMessage(MESSAGES.dismiss)}
        </Button>,
      ]}
      footerSeparator={false}
    >
      {intl.formatMessage(MESSAGES.inUseDeleteModalDescription)}
    </WorkflowModal>
  ) : (
    <WorkflowModal
      autoFocus
      positionTop
      title={intl.formatMessage(MESSAGES.deleteModalTitle, { idpName: idp.name })}
      buttons={[
        <Button key="cancel" buttonColor="dark" variant="tertiary" onClick={closeModal}>
          {intl.formatMessage(MESSAGES.cancel)}
        </Button>,
        <Button
          key="delete-idp"
          buttonColor="danger"
          variant="primary"
          isLoading={isLoading}
          onClick={() => deleteSamlProvider()}
        >
          {intl.formatMessage(MESSAGES.delete)}
        </Button>,
      ]}
      footerSeparator={false}
    >
      {intl.formatMessage(MESSAGES.deleteModalDescription)}
    </WorkflowModal>
  );
}

export type FormValues = {
  idpName: string;
  metadataFile: File | null;
  optionSelected: string | null;
  entityId: string | null;
  certificates: { data: string }[] | null;
  ssoUrl: string | null;
  ssoRequestBinding: string | null;
  sloUrl: string | null;
  sloRequestBinding: string | null;
};

const METADATA_FILE = "metadataFile";

export function MetadataUpload({
  form,
  mutationError,
  disabled = false,
  titleDescription,
  fileLocation,
  showRequiredAsterisk = false,
}: {
  form: UseFormReturn<FormValues>;
  mutationError: string | null;
  disabled?: boolean;
  titleDescription?: string | null;
  fileLocation?: string | null;
  showRequiredAsterisk?: boolean;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
    setValue,
    watch,
  } = form;
  const metadataFile = watch(METADATA_FILE);

  form.register(METADATA_FILE, { required: true });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue(METADATA_FILE, acceptedFiles[0], { shouldDirty: true });
    },
    [setValue],
  );

  const dropzoneState = useDropzone({
    accept: XML_ACCEPTED,
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  return (
    <>
      <h5
        className={classnames(Styles.metadataInputTitle, errors.metadataFile && Styles.textError)}
      >
        {intl.formatMessage(MESSAGES.metadataFieldLabel)}
        {showRequiredAsterisk && <RequiredAsterisk />}
      </h5>

      {titleDescription && <div className={Styles.titleDescription}>{titleDescription}</div>}

      {metadataFile ? (
        <>
          <div className={Styles.metadataFileLoaded}>
            <div className={Styles.metadataFileNameGroup}>
              {fileLocation ? (
                <Link href={fileLocation} className={Styles.metadataFileLink}>
                  <span className={Styles.metadataFileName}>{metadataFile.name}</span>
                  <Icon name="arrow-up-right-square" size="large" />
                </Link>
              ) : (
                <span className={Styles.metadataFileName}>{metadataFile.name}</span>
              )}
            </div>

            <IconButton
              name="delete"
              data-automation-id={"delete-metadata"}
              onClick={() => {
                setValue(METADATA_FILE, null);
              }}
              variant="tertiary"
              buttonColor="danger"
              buttonSize="condensed"
              label={intl.formatMessage(MESSAGES.deleteMetadataFileLabel)}
              disabled={disabled}
            />
          </div>
        </>
      ) : (
        <>
          <Dropzone
            condensed
            label={intl.formatMessage(MESSAGES.metadataFieldLabel)}
            dropzoneState={dropzoneState}
            disabled={disabled}
          />
          {errors.metadataFile && (
            <div>
              <FieldErrorMessage
                inputName={METADATA_FILE}
                message={
                  errors.metadataFile.message || (
                    <FormattedMessage
                      id="ec6caa10-3181-4480-83e0-b858fa48e6fc"
                      defaultMessage="Upload a file to continue"
                    />
                  )
                }
              />
            </div>
          )}
        </>
      )}
      {mutationError && (
        <CardSection>
          <div className={Styles.errorBox}>
            <Icon className={Styles.errorBoxIcon} name="doc-warning" />
            <span data-automation-id="mutation-error-message">{mutationError}</span>
          </div>
        </CardSection>
      )}
    </>
  );
}

enum RequestBinding {
  POST = "POST",
  REDIRECT = "REDIRECT",
}

export function EntityIdInput({
  form,
  disabled = false,
}: {
  form: UseFormReturn<FormValues>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
  } = form;

  return (
    <div>
      <StyledTextInput
        data-automation-id="entity-id-input"
        aria-invalid={isAriaInvalid(errors.entityId)}
        disabled={disabled}
        label={intl.formatMessage(MESSAGES.entityIdFieldLabel)}
        displayRequiredAsterisk
        {...form.register("entityId", {
          required: true,
          maxLength: {
            value: 256,
            message: intl.formatMessage(MESSAGES.entityIdFieldMaxLength),
          },
        })}
      />
      {errors.entityId && (
        <div>
          <FieldErrorMessage
            inputName="entityId"
            message={errors.entityId.message || intl.formatMessage(MESSAGES.missingFieldError)}
          />
        </div>
      )}
    </div>
  );
}

export function SsoUrlInput({
  form,
  disabled = false,
}: {
  form: UseFormReturn<FormValues>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
  } = form;

  return (
    <div>
      <StyledTextInput
        data-automation-id="sso-url-input"
        aria-invalid={isAriaInvalid(errors.ssoUrl)}
        disabled={disabled}
        label={intl.formatMessage(MESSAGES.ssoUrlFieldLabel)}
        displayRequiredAsterisk
        {...form.register("ssoUrl", {
          required: true,
          maxLength: {
            value: 2048,
            message: intl.formatMessage(MESSAGES.ssoUrlFieldMaxLength),
          },
          validate: (value) => {
            return value && isURL(value) ? true : intl.formatMessage(MESSAGES.invalidUrlError);
          },
        })}
      />
      {errors.ssoUrl && (
        <div>
          <FieldErrorMessage
            inputName="ssoUrl"
            message={errors.ssoUrl.message || intl.formatMessage(MESSAGES.missingFieldError)}
          />
        </div>
      )}
    </div>
  );
}

export function SloUrlInput({
  form,
  disabled = false,
}: {
  form: UseFormReturn<FormValues>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
  } = form;

  return (
    <div>
      <StyledTextInput
        data-automation-id="slo-url-input"
        aria-invalid={isAriaInvalid(errors.sloUrl)}
        disabled={disabled}
        label={intl.formatMessage(MESSAGES.sloUrlFieldLabel)}
        displayRequiredAsterisk
        {...form.register("sloUrl", {
          required: true,
          maxLength: {
            value: 2048,
            message: intl.formatMessage(MESSAGES.sloUrlFieldMaxLength),
          },
          validate: (value) => {
            return value && isURL(value) ? true : intl.formatMessage(MESSAGES.invalidUrlError);
          },
        })}
      />
      {errors.sloUrl && (
        <div>
          <FieldErrorMessage
            inputName="sloUrl"
            message={errors.sloUrl.message || intl.formatMessage(MESSAGES.missingFieldError)}
          />
        </div>
      )}
    </div>
  );
}

export function SsoRequestBindingInput({
  form,
  disabled = false,
}: {
  form: UseFormReturn<FormValues>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
  } = form;

  return (
    <div>
      <RadioGroup
        label={intl.formatMessage(MESSAGES.ssoRequestBindingFieldLabel)}
        displayRequiredAsterisk
        disabled={disabled}
        groupError={
          errors.ssoRequestBinding && (
            <FieldErrorMessage
              inputName="ssoRequestBinding"
              message={
                errors.ssoRequestBinding.message || intl.formatMessage(MESSAGES.missingFieldError)
              }
            />
          )
        }
      >
        <RadioLabel
          label={intl.formatMessage(MESSAGES.httpPost)}
          radio={
            <RadioInput
              data-automation-id="sso-request-binding-post"
              value={RequestBinding.POST}
              {...form.register("ssoRequestBinding", { required: true })}
              error={errors.ssoRequestBinding}
              disabled={disabled}
            />
          }
        />
        <RadioLabel
          label={intl.formatMessage(MESSAGES.httpRedirect)}
          radio={
            <RadioInput
              data-automation-id="sso-request-binding-redirect"
              value={RequestBinding.REDIRECT}
              {...form.register("ssoRequestBinding", { required: true })}
              error={errors.ssoRequestBinding}
              disabled={disabled}
            />
          }
        />
      </RadioGroup>
    </div>
  );
}

export function SloRequestBindingInput({
  form,
  disabled = false,
}: {
  form: UseFormReturn<FormValues>;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const {
    formState: { errors },
  } = form;

  return (
    <RadioGroup
      label={intl.formatMessage(MESSAGES.sloRequestBindingFieldLabel)}
      displayRequiredAsterisk
      className={Styles.sloRequestBindingGroup}
      disabled={disabled}
      groupError={
        errors.sloRequestBinding && (
          <FieldErrorMessage
            inputName="sloRequestBinding"
            message={
              errors.sloRequestBinding.message || intl.formatMessage(MESSAGES.missingFieldError)
            }
          />
        )
      }
    >
      <RadioLabel
        label={intl.formatMessage(MESSAGES.httpPost)}
        radio={
          <RadioInput
            data-automation-id="slo-request-binding-post"
            value={RequestBinding.POST}
            {...form.register("sloRequestBinding", { required: true })}
            error={errors.sloRequestBinding}
            disabled={disabled}
          />
        }
      />
      <RadioLabel
        label={intl.formatMessage(MESSAGES.httpRedirect)}
        radio={
          <RadioInput
            data-automation-id="slo-request-binding-redirect"
            value={RequestBinding.REDIRECT}
            {...form.register("sloRequestBinding", { required: true })}
            error={errors.sloRequestBinding}
            disabled={disabled}
          />
        }
      />
    </RadioGroup>
  );
}

export function MutationErrorBox({ error }: { error: string }) {
  return (
    <CardSection>
      <div className={Styles.errorBox}>
        <Icon className={Styles.errorBoxIcon} name="doc-warning" />
        <span data-automation-id="mutation-error-message">{error}</span>
      </div>
    </CardSection>
  );
}

export function handleSamlProviderMutationError(error: Error): MessageDescriptor {
  if (error.message.includes("invalid_certificate")) {
    return MESSAGES.invalidCertificateError;
  } else if (error.message.includes("missing_certificate")) {
    return MESSAGES.missingCertificateError;
  } else if (error.message.includes("missing_required_info")) {
    return MESSAGES.missingRequiredInfoError;
  } else if (error.message.includes("already_exists")) {
    return MESSAGES.idpAlreadyExists;
  } else if (error.message.includes("invalid_url")) {
    return MESSAGES.invalidUrlError;
  }
  captureException(error);
  return MESSAGES.unknownError;
}
