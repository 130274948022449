import { FormattedMessage, useIntl } from "react-intl";
import type { ComponentProps } from "react";

import { Card, CardHeading } from "common/core/card";
import { DescriptionList, DescriptionListItem } from "common/core/description_list";
import { SECTIONS } from "constants/details/summary";

import type { DocumentBundleForTransactionDetailsSummary_referralInfo as ReferralInfo } from "../index_fragment.graphql";

export function ReferralInfoContainer({
  referralInfo,
  direction,
}: {
  referralInfo: ReferralInfo;
  direction?: ComponentProps<typeof DescriptionList>["direction"];
}) {
  const intl = useIntl();

  return (
    <Card
      header={
        <CardHeading level="h2">
          <FormattedMessage
            id="3fc73de0-543f-455d-86ca-df6a91b12df9"
            defaultMessage="Referral details"
          />
        </CardHeading>
      }
      fullWidth
      noMargin
    >
      <DescriptionList direction={direction} itemGap="small">
        {referralInfo.campaignName && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.campaignName)}
            definition={referralInfo.campaignName}
          />
        )}
        {referralInfo.campaignId && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.campaignId)}
            definition={referralInfo.campaignId}
          />
        )}
        {referralInfo.code && (
          <DescriptionListItem
            term={intl.formatMessage(SECTIONS.code)}
            definition={referralInfo.code}
          />
        )}
      </DescriptionList>
    </Card>
  );
}
