import { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  FieldArray,
  type InjectedFormProps,
  reduxForm,
  type WrappedFieldArrayProps,
} from "redux-form";

import SROnly from "common/core/screen_reader";
import { DeprecatedSelectField } from "common/form/fields/select";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { EncompassContactRoles, OrganizationTransactionContactRoleType } from "graphql_globals";
import Button from "common/core/button";
import { DeprecatedBinaryToggleField } from "common/form/fields/binary_toggle";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";
import { DeprecatedFormRow } from "common/form/elements/row";
import trash from "assets/images/trash-bin.svg";

import UpdateEncompassInstanceMutation from "./update_encompass_instance_mutation.graphql";
import type {
  EncompassInstance,
  EncompassInstance_contactMappings,
} from "./encompass_instance_fragment.graphql";

type Props = {
  encompassInstance: EncompassInstance;
};
type FormValues = {
  contactMappings: EncompassInstance_contactMappings[];
};
type FormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & FormProps;
type EncompassContactRolesItemsProps = readonly {
  value: EncompassContactRoles;
  label: string;
}[];
type ContactMappingsProps = WrappedFieldArrayProps<EncompassInstance_contactMappings>;
type ContactMappingProps = {
  field: string;
  encompassContactRolesItems: EncompassContactRolesItemsProps;
  onDelete: () => void;
};

const ENCOMPASS_CONTACT_ROLE_ITEMS: EncompassContactRolesItemsProps = Object.freeze(
  Object.values(EncompassContactRoles).map((role: EncompassContactRoles) => ({
    value: role,
    label: role,
  })),
);

const NOTARIZE_CONTACT_ROLE_ITEMS = Object.freeze(
  Object.values(OrganizationTransactionContactRoleType).map(
    (role: OrganizationTransactionContactRoleType) => ({
      value: role,
      label: role,
    }),
  ),
);

const DEFAULT_CONTACT_MAPPINGS = [
  {
    id: "0",
    encompassContactRole: EncompassContactRoles.BUYERS_AGENT,
    notarizeContactRole: OrganizationTransactionContactRoleType.REAL_ESTATE_AGENT,
    shownToSigner: true,
    accessToTransaction: false,
    skipIfIncomplete: false,
  },
  {
    id: "1",
    encompassContactRole: EncompassContactRoles.DOC_SIGNING,
    notarizeContactRole: OrganizationTransactionContactRoleType.TITLE_AGENT,
    shownToSigner: true,
    accessToTransaction: true,
    skipIfIncomplete: false,
  },
  {
    id: "2",
    encompassContactRole: EncompassContactRoles.ESCROW_COMPANY,
    notarizeContactRole: OrganizationTransactionContactRoleType.ESCROW_OFFICER,
    shownToSigner: true,
    accessToTransaction: true,
    skipIfIncomplete: false,
  },
  {
    id: "3",
    encompassContactRole: EncompassContactRoles.FUNDER,
    notarizeContactRole: OrganizationTransactionContactRoleType.OTHER,
    shownToSigner: false,
    accessToTransaction: false,
    skipIfIncomplete: false,
  },
  {
    id: "4",
    encompassContactRole: EncompassContactRoles.LOAN_CLOSER,
    notarizeContactRole: OrganizationTransactionContactRoleType.CLOSER,
    shownToSigner: false,
    accessToTransaction: false,
    skipIfIncomplete: false,
  },
  {
    id: "5",
    encompassContactRole: EncompassContactRoles.LOAN_OFFICER,
    notarizeContactRole: OrganizationTransactionContactRoleType.LOAN_OFFICER,
    shownToSigner: true,
    accessToTransaction: false,
    skipIfIncomplete: false,
  },
  {
    id: "6",
    encompassContactRole: EncompassContactRoles.LOAN_PROCESSOR,
    notarizeContactRole: OrganizationTransactionContactRoleType.LOAN_PROCESSOR,
    shownToSigner: false,
    accessToTransaction: false,
    skipIfIncomplete: false,
  },
  {
    id: "7",
    encompassContactRole: EncompassContactRoles.POST_CLOSER,
    notarizeContactRole: OrganizationTransactionContactRoleType.CLOSER,
    shownToSigner: false,
    accessToTransaction: false,
    skipIfIncomplete: false,
  },
  {
    id: "8",
    encompassContactRole: EncompassContactRoles.SETTLEMENT_AGENT,
    notarizeContactRole: OrganizationTransactionContactRoleType.TITLE_AGENT,
    shownToSigner: true,
    accessToTransaction: true,
    skipIfIncomplete: false,
  },
  {
    id: "9",
    encompassContactRole: EncompassContactRoles.TITLE_COMPANY,
    notarizeContactRole: OrganizationTransactionContactRoleType.TITLE_AGENT,
    shownToSigner: true,
    accessToTransaction: true,
    skipIfIncomplete: false,
  },
];

function ContactMapping({ field, encompassContactRolesItems, onDelete }: ContactMappingProps) {
  return (
    <DeprecatedFormRow className="EncompassContactMappingRow" key={field}>
      <div className="EncompassContactMappingRow--Cell">
        <DeprecatedSelectField
          name={`${field}.encompassContactRole`}
          useStyledInput
          items={encompassContactRolesItems}
          automationId={`encompass-contact-role-${field}`}
          clearable={false}
          searchable={false}
        />
        <FormGroupErrors fields={[`${field}.encompassContactRole`]} />
      </div>
      <div className="EncompassContactMappingRow--Cell">
        <DeprecatedSelectField
          name={`${field}.notarizeContactRole`}
          useStyledInput
          items={NOTARIZE_CONTACT_ROLE_ITEMS}
          automationId={`notarize-contact-role-${field}`}
          clearable={false}
          searchable={false}
        />
        <FormGroupErrors fields={[`${field}.notarizeContactRole`]} />
      </div>
      <div className="EncompassContactMappingRow--Cell">
        <DeprecatedBinaryToggleField
          name={`${field}.shownToSigner`}
          label={
            <SROnly>
              <FormattedMessage
                id="f41d32c2-a8ee-41e1-8164-75428532746b"
                defaultMessage="Shown to signer"
              />
            </SROnly>
          }
          dataAutomationId={`shown-to-signer-${field}`}
        />
        <FormGroupErrors fields={[`${field}.shownToSigner`]} />
      </div>
      <div className="EncompassContactMappingRow--Cell">
        <DeprecatedBinaryToggleField
          name={`${field}.accessToTransaction`}
          label={
            <SROnly>
              <FormattedMessage
                id="e47f4d07-25d8-42c5-8b97-135a89ed62ab"
                defaultMessage="Access to transaction"
              />
            </SROnly>
          }
          dataAutomationId={`access-to-transaction-${field}`}
        />
        <FormGroupErrors fields={[`${field}.accessToTransaction`]} />
      </div>
      <div className="EncompassContactMappingRow--Cell">
        <DeprecatedBinaryToggleField
          name={`${field}.skipIfIncomplete`}
          label={
            <SROnly>
              <FormattedMessage
                id="5e21ac8c-3f51-4cd4-bcfe-2abb1cb49533"
                defaultMessage="Skip if incomplete"
              />
            </SROnly>
          }
          dataAutomationId={`skip-if-incomplete-${field}`}
        />
        <Button
          variant="primary"
          buttonColor="light"
          onClick={onDelete}
          automationId={`encompass-contact-mapping-${field}-remove`}
        >
          <img alt="delete-button" src={trash} />
        </Button>
      </div>
    </DeprecatedFormRow>
  );
}

const MemoizedContactMapping = memo(ContactMapping);

function ContactMappings({ fields }: ContactMappingsProps) {
  const currentEncompassRoles = fields.map((field, index) => {
    return fields.get(index).encompassContactRole;
  });
  const encompassRoleLeft = ENCOMPASS_CONTACT_ROLE_ITEMS.find((option) => {
    return !currentEncompassRoles.includes(option.value);
  });
  const defaultMappingLeft = DEFAULT_CONTACT_MAPPINGS.find((mapping) => {
    return mapping.encompassContactRole === encompassRoleLeft?.value;
  });

  return (
    <div className="EncompassInstanceForm">
      <div className="EncompassInstanceForm--Title">
        <FormattedMessage
          id="8a5e08a4-3216-487a-a1b6-9f6b08a5fc7f"
          defaultMessage="Configure which File Contacts you want added as contacts on your Proof transactions."
        />
      </div>

      <DeprecatedFormRow className="EncompassContactMappingHeaderRow">
        <div className="EncompassContactMappingHeaderRow--Cell">
          <FormattedMessage
            id="888d1593-a54c-49dd-b541-6596ff1589b1"
            defaultMessage="Encompass Contact Role"
          />
        </div>

        <div className="EncompassContactMappingHeaderRow--Cell">
          <FormattedMessage
            id="7104f29b-bbe5-4d85-975c-b3d9a771d8b3"
            defaultMessage="Proof Contact Role"
          />
        </div>

        <div className="EncompassContactMappingHeaderRow--Cell">
          <FormattedMessage
            id="8340882f-3176-497d-8d30-e5cffe1e9dd0"
            defaultMessage="Visible to Signer"
          />
        </div>

        <div className="EncompassContactMappingHeaderRow--Cell">
          <FormattedMessage
            id="119c7295-6691-4b35-96f8-b9866eb99c85"
            defaultMessage="Access to Documents"
          />
        </div>
        <div className="EncompassContactMappingHeaderRow--Cell">
          <FormattedMessage
            id="685c4418-7953-416d-bb97-33849fefa979"
            defaultMessage="Skip if Incomplete"
          />
        </div>
      </DeprecatedFormRow>
      {fields.map((field, index, fields) => {
        const availableEncompassContactRolesItems = ENCOMPASS_CONTACT_ROLE_ITEMS.filter(
          (option) => {
            const role = option.value;
            const currentRole = fields.get(index).encompassContactRole;
            return role === currentRole || !currentEncompassRoles.includes(role);
          },
        );
        return (
          <MemoizedContactMapping
            key={index}
            field={field}
            encompassContactRolesItems={availableEncompassContactRolesItems}
            onDelete={() => fields.remove(index)}
          />
        );
      })}
      {defaultMappingLeft && (
        <Button
          variant="tertiary"
          buttonColor="action"
          withIcon={{ name: "add", placement: "left" }}
          onClick={() => fields.push({ ...defaultMappingLeft, id: `${fields.length}` })}
        >
          <FormattedMessage
            id="5f66c5bc-5a3a-4d54-9e47-4803b83a253a"
            defaultMessage="add a contact mapping"
          />
        </Button>
      )}
    </div>
  );
}

const MemoizedContactMappings = memo(ContactMappings);

function ContactsTab({
  encompassInstance: { id, contactMappings },
  handleSubmit,
  initialize,
}: InnerProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertKind, setAlertKind] = useState<"success" | "danger" | null>(null);
  const updateEncompassInstanceMutateFn = useMutation(UpdateEncompassInstanceMutation);
  const onSubmit = (values: FormValues) => {
    const { contactMappings } = values;
    setIsSubmitting(true);

    return updateEncompassInstanceMutateFn({
      variables: {
        input: {
          id,
          contactMappings: contactMappings.map((contactMapping) => ({
            encompassContactRole: contactMapping.encompassContactRole,
            notarizeContactRole: contactMapping.notarizeContactRole,
            shownToSigner: contactMapping.shownToSigner,
            accessToTransaction: contactMapping.accessToTransaction,
            skipIfIncomplete: contactMapping.skipIfIncomplete,
          })),
        },
      },
    })
      .then(() => setAlertKind("success"))
      .catch((err: Error) => {
        if (isGraphQLError(err)) {
          captureException(err);
        }
        setAlertKind("danger");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    initialize({
      contactMappings: contactMappings.length === 0 ? DEFAULT_CONTACT_MAPPINGS : contactMappings,
    });
  }, []);

  return (
    <form
      name="encompassContactsForm"
      className="EncompassInstanceForm"
      data-automation-id="encompass-contacts-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {alertKind && (
        <AlertMessage kind={alertKind} centerText>
          <FormattedMessage
            id="5dc13358-f9ff-447d-ba50-b00f4e52eb43"
            defaultMessage="{alertKind, select, success{Changes saved successfully} other{Hmm. Looks like something went wrong. We were unable to save your changes.}}"
            values={{ alertKind }}
          />
        </AlertMessage>
      )}
      <FieldArray<undefined>
        props={undefined}
        name="contactMappings"
        component={MemoizedContactMappings}
      />
      <Button
        buttonColor="action"
        variant="primary"
        type="submit"
        automationId="encompass-contacts-form-submit"
        isLoading={isSubmitting}
      >
        <FormattedMessage id="0dd0c523-462b-4002-bc4d-323935e2c8ba" defaultMessage="Save" />
      </Button>
    </form>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({
  form: "encompassContactsForm",
});

export default formEnhancer(ContactsTab);
