import { useState } from "react";

import { segmentTrack } from "util/segment";
import { CredentialAnalysisRequirement, PhotoIdentification, StepType } from "graphql_globals";
import { useStableS3Url } from "util/url";

import type {
  IdentityDocumentViewerSignerIdentity,
  IdentityDocumentViewerSignerIdentity_signerStepsV2_CredentialAnalysisStep,
} from "./signer_identity.fragment.graphql";
import type { IdentityDocumentViewerSignerParticipant } from "./signer_participant.fragment.graphql";

// TODO: refactor PhotoType to just be PhotoIdentification
export enum PhotoType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Selfie = "SELFIE",
}

// TODO: get rid of this function when above todo is done
export function photoTypeToPhotoIdentification(p: PhotoType | null): PhotoIdentification | null {
  switch (p) {
    case PhotoType.Primary:
      return PhotoIdentification.PRIMARY;
    case PhotoType.Secondary:
      return PhotoIdentification.SECONDARY;
    case PhotoType.Selfie:
      return PhotoIdentification.SELFIE;
    default:
      return null;
  }
}

export type DocumentViewerPhoto = {
  frontPicture: string | null;
  backPicture: string | null;
  documentClaimedType: string | null;
  id: string;
};

type Photos = ReturnType<typeof parsePhotosForDocumentViewer>;
export type PhotoRequirements = {
  secondary: boolean;
  selfie: boolean;
  biometricOnly?: boolean;
};

type EngineProps = {
  photos: Photos;
  requirements: PhotoRequirements;
};

export type IdentityDocumentViewerEngine = {
  photos: Photos;
  requirements: PhotoRequirements;
  documentUrl: string | null;
  currentPhoto: DocumentViewerPhoto | null;
  currentPhotoType: PhotoType;
  currentPhotoIndex: number;
  totalPhotoCount: number;
  isFront: boolean;
  onChangeDocumentSide: () => void;
  onNextDocument?: () => void;
  onPreviousDocument?: () => void;
};

enum AnalyticsEvent {
  NextDocument = "Clicked next document",
  PreviousDocument = "Clicked previous document",
  ChangedSide = "Clicked change side",
}

type AnalyticsData = Record<string, unknown>;

function useDocumentViewerEngineAnalytics() {
  return (event: AnalyticsEvent, data?: AnalyticsData) => {
    segmentTrack(`Document Viewer Engine - ${event}`, data);
  };
}

function biometricRequirementsOnly(signerIdentity: IdentityDocumentViewerSignerIdentity) {
  const caSteps = signerIdentity.signerStepsV2.filter(
    (step) => step.stepType === StepType.CREDENTIAL_ANALYSIS,
  ) as IdentityDocumentViewerSignerIdentity_signerStepsV2_CredentialAnalysisStep[];

  // are all CA steps biometric and revalidation?
  const biometricOnly =
    // confirm at least one step is biometric only
    caSteps.some(
      (step) => step.credentialAnalysisRequirement === CredentialAnalysisRequirement.BIOMETRIC_ONLY,
    ) &&
    caSteps.every((step) => {
      return (
        step.credentialAnalysisRequirement === CredentialAnalysisRequirement.BIOMETRIC_ONLY ||
        step.credentialAnalysisRequirement ===
          CredentialAnalysisRequirement.IDENTITY_DOCUMENT_REVALIDATION
      );
    });

  return biometricOnly;
}

export function getPhotoRequirementsForDocumentViewer(
  signerIdentity: IdentityDocumentViewerSignerIdentity,
): PhotoRequirements {
  const caStep = signerIdentity.signerStepsV2.find(
    (step) => step.stepType === StepType.CREDENTIAL_ANALYSIS,
  ) as IdentityDocumentViewerSignerIdentity_signerStepsV2_CredentialAnalysisStep | undefined;

  if (!caStep) {
    segmentTrack("Missing CredentialAnalysisStep when rendering Identity Document Viewer");
    return {
      secondary: false,
      selfie: false,
    };
  }

  return {
    secondary: caStep.secondaryIdRequired,
    selfie:
      caStep.credentialAnalysisRequirement === CredentialAnalysisRequirement.BIOMETRIC ||
      caStep.credentialAnalysisRequirement === CredentialAnalysisRequirement.BIOMETRIC_PS1583 ||
      signerIdentity.biometricsRequired,
    biometricOnly: biometricRequirementsOnly(signerIdentity),
  };
}

export function parsePhotosForDocumentViewer({
  photoId,
  secondaryPhotoId,
  secondaryId,
}: IdentityDocumentViewerSignerIdentity | IdentityDocumentViewerSignerParticipant): Record<
  PhotoType,
  DocumentViewerPhoto | null
> {
  return {
    [PhotoType.Primary]: photoId?.frontPicture ? photoId : null,
    [PhotoType.Secondary]: secondaryPhotoId?.frontPicture
      ? secondaryPhotoId
      : secondaryId?.url
        ? {
            frontPicture: secondaryId.url,
            backPicture: null,
            documentClaimedType: null,
            id: secondaryId.url,
          }
        : null,
    [PhotoType.Selfie]: photoId?.selfiePicture
      ? {
          frontPicture: photoId.selfiePicture,
          backPicture: null,
          documentClaimedType: null,
          id: photoId.selfiePicture,
        }
      : null,
  };
}

function getPhotoIndexFromType(photos: Photos, photoType: PhotoType, biometricOnly?: boolean) {
  if (biometricOnly) {
    return [PhotoType.Primary, PhotoType.Selfie].indexOf(photoType);
  }
  return (Object.keys(photos) as unknown as (keyof typeof photos)[]).indexOf(photoType);
}

function getNextDocumentType(
  currentPhotoType: PhotoType,
  selfieExists: boolean,
  biometricsRequired?: boolean,
) {
  switch (currentPhotoType) {
    case PhotoType.Primary:
      return biometricsRequired ? PhotoType.Selfie : PhotoType.Secondary;
    case PhotoType.Secondary:
      if (selfieExists) {
        return PhotoType.Selfie;
      }
      return null;
    default:
      return null;
  }
}

function getPreviousDocumentType(currentPhotoType: PhotoType, biometricsRequired?: boolean) {
  switch (currentPhotoType) {
    case PhotoType.Secondary:
      return PhotoType.Primary;
    case PhotoType.Selfie:
      return biometricsRequired ? PhotoType.Primary : PhotoType.Secondary;
    default:
      return null;
  }
}

export function useIdentityDocumentViewerEngine({
  photos,
  requirements,
}: EngineProps): IdentityDocumentViewerEngine {
  const [currentPhotoType, setCurrentPhotoType] = useState(PhotoType.Primary);
  const [isFront, setIsFront] = useState(true);
  const track = useDocumentViewerEngineAnalytics();

  const includeSelfie = requirements.selfie || Boolean(photos[PhotoType.Selfie]);
  const nextDocumentType = getNextDocumentType(
    currentPhotoType,
    includeSelfie,
    requirements.biometricOnly,
  );
  const previousDocumentType = getPreviousDocumentType(
    currentPhotoType,
    requirements.biometricOnly,
  );

  const onChangeDocumentSide = () => {
    track(AnalyticsEvent.ChangedSide);
    setIsFront((old) => !old);
  };

  const onNextDocument = nextDocumentType
    ? () => {
        track(AnalyticsEvent.NextDocument, { targetDocument: nextDocumentType });
        setIsFront(true);
        setCurrentPhotoType(nextDocumentType);
      }
    : undefined;
  const onPreviousDocument = previousDocumentType
    ? () => {
        track(AnalyticsEvent.PreviousDocument, { targetDocument: previousDocumentType });
        setIsFront(true);
        setCurrentPhotoType(previousDocumentType);
      }
    : undefined;

  const currentPhoto = photos[currentPhotoType];
  const documentUrl = currentPhoto
    ? isFront
      ? currentPhoto.frontPicture
      : currentPhoto.backPicture
    : null;

  return {
    photos,
    documentUrl: useStableS3Url(documentUrl),
    currentPhoto,
    currentPhotoType,
    isFront,
    onChangeDocumentSide,
    onNextDocument,
    onPreviousDocument,
    currentPhotoIndex: getPhotoIndexFromType(photos, currentPhotoType, requirements.biometricOnly),
    totalPhotoCount: !requirements.biometricOnly && includeSelfie ? 3 : 2,
    requirements,
  };
}
