import type { ComponentProps, ReactNode } from "react";
import classnames from "classnames";

import Button from "common/core/button";

import Styles from "./index.module.scss";

function Container({ children }: { children: ReactNode }) {
  return <div className={Styles.container}>{children}</div>;
}

function ActionButton({ className, ...props }: ComponentProps<typeof Button>) {
  return <Button className={classnames(Styles.button, className)} {...props} />;
}

export const TableEmptyState = { Container, ActionButton };
