import { Component } from "react";
import PropTypes from "prop-types";
import {
  from,
  merge,
  forkJoin,
  Subject,
  fromEvent,
  concatMap,
  map,
  skipWhile,
  takeUntil,
} from "rxjs";
import { FormattedMessage } from "react-intl";

import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import {
  lookupMimeTypeFromName,
  SUPPORTED_FILE_EXTENSIONS,
} from "common/document/uploader/document_item_util";
import { useMutation, useApolloClient } from "util/graphql";
import { segmentTrack, segmentTrackObservable } from "util/segment";
import { uploadDocumentToS3 } from "util/uploader";
import { EVENT } from "constants/analytics";
import { TextTagSyntax, Feature, ProcessingStates } from "graphql_globals";
import UpdateTransactionBundleMutation from "common/transactions/graphql/mutations/update_transaction_bundle_mutation.graphql";
import UpdateDocumentPositionsMutation from "common/transactions/graphql/mutations/update_document_positions_mutation.graphql";
import CreateDocumentsMutation from "common/transactions/graphql/mutations/create_documents_mutation.graphql";
import AddDocumentsToTransactionMutation from "common/transactions/graphql/mutations/add_documents_to_transaction_mutation.graphql";
import RemoveDocumentFromTransaction from "common/transactions/graphql/mutations/remove_document_from_transaction_mutation.graphql";
import AddTemplateToBundleMutation from "common/transactions/graphql/mutations/add_template_to_bundle_mutation.graphql";
import DocumentUploader from "common/document/uploader";
import { useDocumentPoller } from "common/document/state_poller";
import DeletedDocuments from "common/document/uploader/manager/deleted_document_manager/deleted_documents_query.graphql";
import OrganizationTransactionFragment from "common/transactions/graphql/fragments/organization_transaction_fragment.graphql";
import { usePermissions } from "common/core/current_user_role";

import SplitDocumentsQuery from "./split_documents_query.graphql";
import TemplatesQuery from "./templates_query.graphql";
import { onReplaceDocument } from "../replace_document_uploader";

class TransactionDocumentUploader extends Component {
  constructor(props) {
    super(props);

    this.previousDocumentEdgesOrder = null;
    this.unmounted$ = new Subject();

    this.state = {
      textTagSyntax: props.organization.defaultTextTagSyntax,
      documentToReplace: null,
      disableSplitting: false,
      splitBookmarkedPdfValue: props.splitBookmarkedPdf,
      templates: null,
    };
  }

  async componentDidMount() {
    const { apolloClient, organization } = this.props;

    fromEvent(window, "keydown").pipe(takeUntil(this.unmounted$)).subscribe(this.handleKeyDown);
    // BIZ-7224 add true pagination from the BE to avoid the slow query. Move into own template table component to be completely managed there.
    const { data } = await apolloClient.query({
      query: TemplatesQuery,
      variables: { organizationId: organization.id },
    });

    if (data) {
      this.setState({ templates: data.organization.templates });
    }
  }

  componentWillUnmount() {
    this.unmounted$.next();
    this.unmounted$.unsubscribe();
  }

  handleKeyDown = (e) => {
    if (e.key === "z" && e.metaKey) {
      this.handleUndoReorderDocument();
    }
  };

  setDocumentToReplace = (document) => {
    this.setState({ documentToReplace: document });
  };

  handleTextTagSyntaxChange = (textTagSyntax) => {
    this.setState({ textTagSyntax });
  };

  handleDisableSplittingChange = () => {
    this.setState((prevState) => ({ disableSplitting: !prevState.disableSplitting }));
  };

  handleSplitBookmarkedPdfChange = () => {
    this.setState((prevState) => ({ splitBookmarkedPdfValue: !prevState.splitBookmarkedPdfValue }));
  };

  documentUploaderAddButtonContent = (count) => {
    return (
      <FormattedMessage
        id="923a5d12-666c-4cf4-81b1-b5e43c59ee11"
        description="adduploadsButton"
        defaultMessage="{count, plural, zero{No Documents Uploaded} one{Add {count} document to transaction} other{Add {count} documents to transaction}}"
        values={{ count }}
      />
    );
  };

  handleUndoReorderDocument = () => {
    if (!this.previousDocumentEdgesOrder) {
      return;
    }

    const {
      transaction: { document_bundle },
      updateDocumentPositionsMutateFn,
    } = this.props;

    const documents = this.previousDocumentEdgesOrder.map(({ node }, index) => ({
      id: node.id,
      bundlePosition: index,
    }));
    updateDocumentPositionsMutateFn({
      variables: { input: { documentBundleId: document_bundle.id, documents } },
      optimisticResponse: this.updateDocumentPositionsOptimisticResponse(
        this.previousDocumentEdgesOrder,
      ),
    });

    this.previousDocumentEdgesOrder = null;
  };

  handleDeleteDocuments = (deletetionDocIds) => {
    // NOTE this must run all the documents as individual mutations. Here apollo does not want to batch
    // them so these are run as individual requests and as a result are not as efficient as possible.
    // We probably need a different mutation schema to support this (or maybe there's some way to tell
    // apollo to batch them).
    this.previousDocumentEdgesOrder = null;
    const {
      removeDocumentFromTransactionMutateFn,
      transaction: { id },
      hasPermissionFor,
    } = this.props;
    const allDeletePromises = deletetionDocIds.map((deletedDocId) => {
      return removeDocumentFromTransactionMutateFn({
        variables: { input: { documentId: deletedDocId, organizationTransactionId: id } },
        optimisticResponse: {
          removeDocumentFromTransaction: {
            __typename: "RemoveDocumentFromTransactionPayload",
            errors: null,
          },
        },
        update(cacheProxy) {
          const cacheId = cacheProxy.identify({ __typename: "OrganizationTransaction", id });
          const cachedTransaction = cacheProxy.readFragment({
            id: cacheId,
            fragment: OrganizationTransactionFragment,
            fragmentName: "OrganizationTransaction",
          });
          const { edges } = cachedTransaction.document_bundle.documents;
          const deletedDocument = edges.find(({ node }) => node.id === deletedDocId);
          const deletedDocumentNew = {
            __typename: "DocumentEdge",
            node: {
              ...deletedDocument.node,
              deletedAt: new Date(),
            },
          };

          cacheProxy.writeFragment({
            id,
            fragment: OrganizationTransactionFragment,
            fragmentName: "OrganizationTransaction",
            data: {
              ...cachedTransaction,
              document_bundle: {
                ...cachedTransaction.document_bundle,
                documents: {
                  ...cachedTransaction.document_bundle.documents,
                  edges: edges.filter(({ node }) => node.id !== deletedDocId),
                },
              },
            },
          });

          if (hasPermissionFor("manageDeletedDocuments")) {
            const { organizationTransaction, ...restDeleted } = cacheProxy.readQuery({
              query: DeletedDocuments,
              variables: { transactionId: id },
            });
            const deletedEdges = organizationTransaction.documentBundle.documents.edges;
            cacheProxy.writeQuery({
              query: DeletedDocuments,
              data: {
                ...restDeleted,
                organizationTransaction: {
                  ...organizationTransaction,
                  documentBundle: {
                    ...organizationTransaction.documentBundle,
                    documents: {
                      ...organizationTransaction.documentBundle.documents,
                      edges: [deletedDocumentNew, ...deletedEdges],
                    },
                  },
                },
              },
            });
          }
        },
      }).then(() =>
        segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_DELETE_DOCUMENT, {
          document_id: deletedDocId,
          organization_transaction_id: id,
        }),
      );
    });

    return Promise.all(allDeletePromises);
  };

  handleReorderDocuments = (selectedDocuments, desiredLocationDocumentId, above = true) => {
    const {
      transaction: {
        document_bundle,
        document_bundle: {
          documents: { edges },
        },
      },
      updateDocumentPositionsMutateFn,
    } = this.props;

    this.previousDocumentEdgesOrder = edges;

    const filteredDocumentIds = edges
      .filter(({ node: document }) => {
        if (selectedDocuments.includes(desiredLocationDocumentId)) {
          return (
            !selectedDocuments.includes(document.id) || document.id === desiredLocationDocumentId
          );
        }
        return !selectedDocuments.includes(document.id);
      })
      .map(({ node: document }) => document.id);
    const targetIndex = filteredDocumentIds.findIndex(
      (documentId) => documentId === desiredLocationDocumentId,
    );

    // In the event a user is trying to move all docs above a document that is also selected, we need to remove
    // it from the list after calculating what index it should be in
    if (selectedDocuments.includes(desiredLocationDocumentId)) {
      filteredDocumentIds.splice(targetIndex, 1);
    }

    // Insert selected document IDs in the correct location
    filteredDocumentIds.splice(above ? targetIndex : targetIndex + 1, 0, ...selectedDocuments);

    const reorderedEdges = filteredDocumentIds.map((documentId) =>
      edges.find((edge) => edge.node.id === documentId),
    );
    const documents = reorderedEdges.map(({ node }, index) => ({
      id: node.id,
      bundlePosition: index,
    }));
    updateDocumentPositionsMutateFn({
      variables: { input: { documentBundleId: document_bundle.id, documents } },
      optimisticResponse: this.updateDocumentPositionsOptimisticResponse(reorderedEdges),
    });
  };

  /**
   * This function allows a closing ops user to resubmit a document through our templating engine within a
   * test transaction.
   */
  handleResubmitDocument = (document) => {
    this.processAddedDocuments([document]);
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      message: (
        <FormattedMessage
          id="731e193c-c4b3-48f5-9753-1eeddb7ebf6f"
          defaultMessage="Please check back in a few minutes and refresh the page to see the resubmitted documents."
        />
      ),
      subtype: NOTIFICATION_SUBTYPES.DEFAULT,
    });
  };

  handleRenameDocument = (document, newTitle) => {
    const {
      transaction: { id, document_bundle },
      updateTransactionBundleMutateFn,
    } = this.props;
    const documentId = document.id;
    const documents = [{ id: documentId, name: newTitle }];
    const newEdges = document_bundle.documents.edges.map((edge) => {
      return edge.node.id === documentId
        ? { ...edge, node: { ...edge.node, name: newTitle } }
        : edge;
    });
    updateTransactionBundleMutateFn({
      variables: { input: { organizationTransactionId: id, documents } },
      optimisticResponse: this.updateTransactionBundleOptimisticResponse(newEdges),
    }).then(() =>
      segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_RENAME_DOCUMENT, {
        organization_transaction_id: id,
        documents,
      }),
    );
  };

  uploadStrategy = (file) => {
    const { createDocumentsMutateFn } = this.props;
    const { textTagSyntax, splitBookmarkedPdfValue } = this.state;

    return from(uploadDocumentToS3(file)).pipe(
      concatMap((s3Payload) => {
        return from(
          createDocumentsMutateFn({
            variables: {
              input: {
                fileHandle: s3Payload.key,
                pdfBookmarked: splitBookmarkedPdfValue,
                textTagSyntax,
                organizationId: this.props.organization?.id,
              },
            },
          }),
        );
      }),
      concatMap(({ data }) => {
        return forkJoin(
          data.createDocuments.documents.map(({ id, name }) => {
            return this.props.startPolling(id).pipe(
              skipWhile((status) => status.processingState === ProcessingStates.PENDING),
              map((status) => ({
                id,
                name,
                status: status.processingState,
                processingError: status.processingError,
                mimeType: lookupMimeTypeFromName(name),
              })),
            );
          }),
        );
      }),
    );
  };

  handleReplaceDocument = (newDocument) => {
    const {
      transaction: { id },
      updateTransactionBundleMutateFn,
    } = this.props;
    const { documentToReplace } = this.state;

    const documents = [{ documentToCopyId: newDocument.id, id: documentToReplace.id }];

    const replaceDocumentFn = async () => {
      if (!documentToReplace) {
        return null;
      }

      const response = await updateTransactionBundleMutateFn({
        variables: { input: { organizationTransactionId: id, documents } },
      });

      const { errors } = response.data?.updateTransactionBundle || {};

      return errors;
    };

    return onReplaceDocument(replaceDocumentFn, newDocument.name);
  };

  handleDropFiles = (files) => {
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_UPLOAD_DOCUMENTS_ATTEMPTED, { files });
  };

  handleCancelUpload = () => {
    const {
      transaction: { id },
    } = this.props;
    segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_UPLOAD_DOCUMENTS_CANCELLED, {
      organization_transaction_id: id,
    });
  };

  handleUploadComplete = (documents, templates) => {
    this.previousDocumentEdgesOrder = null;

    const addDocuments$ = this.processAddedDocuments(documents);

    const addTemplatesObservables$ = this.processAddedTemplates(templates);

    return merge(addDocuments$, ...addTemplatesObservables$);
  };

  processAddedDocuments = (documents) => {
    const {
      addDocumentsToTransactionMutateFn,
      transaction: { id },
      defaultDocRequirements,
      defaultDocPermissions,
    } = this.props;
    const { disableSplitting } = this.state;
    const baseInput = {
      transactionId: id,
      documentIds: documents.map((doc) => doc.id),
    };

    const defaultDocOptions = {
      requirements: defaultDocRequirements,
      permissions: defaultDocPermissions,
      disableSplitting,
    };

    return from(
      addDocumentsToTransactionMutateFn({
        variables: {
          input: { ...baseInput, options: defaultDocOptions },
        },
      }),
    ).pipe(
      segmentTrackObservable(
        EVENT.ORGANIZATION_TRANSACTION_EDITOR_UPLOAD_DOCUMENTS_SUCCEEDED,
        () => baseInput,
      ),
    );
  };

  processAddedTemplates = (templates) => {
    const {
      addTemplateToBundleMutateFn,
      transaction: { id, document_bundle },
    } = this.props;
    const baseInput = {
      transaction_id: id,
      bundle_id: document_bundle.id,
      template_ids: templates,
    };
    const addTemplatesObservables$ = templates.map((templateId) => {
      return from(
        addTemplateToBundleMutateFn({
          variables: {
            input: { templateId, bundleId: document_bundle.id },
          },
        }),
      ).pipe(
        segmentTrackObservable(
          EVENT.ORGANIZATION_TRANSACTION_EDITOR_ADD_TEMPLATES_SUCCEEDED,
          () => baseInput,
        ),
      );
    });

    return addTemplatesObservables$;
  };

  handleChangeDocumentProperties = (selectedDocumentIds, propValues) => {
    this.previousDocumentEdgesOrder = null;

    const {
      transaction: { id, document_bundle },
      updateTransactionBundleMutateFn,
    } = this.props;
    const { edges } = document_bundle.documents;
    const allowedActions = {
      customerCanAnnotate: propValues.signerCanAnnotate,
      witnessRequired: propValues.witnessRequired,
      notarizationRequired: Boolean(propValues.notarizationRequired),
      proofingRequired: Boolean(propValues.proofingRequired),
      signingRequiresMeeting: Boolean(propValues.signingRequiresMeeting),
      vaulted: propValues.vaulted,
      esign: Boolean(propValues.esign),
      requirement: propValues.requirement,
    };
    const selectedDocumentsLookup = selectedDocumentIds.reduce((accum, docId) => {
      accum[docId] = true;
      return accum;
    }, {});
    const documents = selectedDocumentIds.map((docId) => ({ id: docId, ...allowedActions }));

    const newDocuments = edges.map((edge) => {
      return selectedDocumentsLookup[edge.node.id]
        ? { ...edge, node: { ...edge.node, ...allowedActions } }
        : edge;
    });

    updateTransactionBundleMutateFn({
      variables: { input: { organizationTransactionId: id, documents } },
      optimisticResponse: this.updateTransactionBundleOptimisticResponse(newDocuments),
    }).then(() =>
      segmentTrack(EVENT.ORGANIZATION_TRANSACTION_EDITOR_CHANGE_DOCUMENT_PROPERTIES, {
        organization_transaction_id: id,
        documents,
      }),
    );
  };

  updateTransactionBundleOptimisticResponse = (edges) => {
    const { id, document_bundle, customerSigners } = this.props.transaction;
    return {
      updateTransactionBundle: {
        __typename: "UpdateTransactionBundlePayload",
        organizationTransaction: {
          __typename: "OrganizationTransaction",
          id,
          customerSigners,
          document_bundle: {
            id,
            // required in lender portal, required due to apollo's partialRefetch issue
            includesEnote: document_bundle.includesEnote,
            enoteSeed: document_bundle.enoteSeed,

            __typename: "DocumentBundle",
            documents: {
              __typename: "DocumentConnection",
              edges,
            },
            instructions: document_bundle.instructions,
          },
        },
      },
    };
  };

  updateDocumentPositionsOptimisticResponse = (edges) => {
    const { id, instructions } = this.props.transaction.document_bundle;
    return {
      updateDocumentPositions: {
        __typename: "UpdateDocumentPositionsPayload",
        errors: null,
        document_bundle: {
          id,
          __typename: "DocumentBundle",
          instructions,
          documents: {
            __typename: "DocumentConnection",
            edges,
          },
        },
      },
    };
  };

  handleSplitDocuments = async (originalDocumentId, splitDocumentIds) => {
    const {
      apolloClient,
      transaction: {
        id: transactionId,
        document_bundle: { id: documentBundleId },
      },
      organization: { id: organizationId },
      transactionQuery: TransactionQuery,
    } = this.props;

    const {
      data: {
        documents: splitDocumentsData,
        transaction: { templateSplittingResults: templateSplittingResultsData },
      },
    } = await apolloClient.query({
      query: SplitDocumentsQuery,
      variables: { documentIds: [originalDocumentId, ...splitDocumentIds], transactionId },
      fetchPolicy: "network-only", // never read this from local cache
    });

    const normalizedSplitDocumentsData = splitDocumentsData.map((docData) => ({
      node: docData,
      __typename: "DocumentEdge",
    }));

    const { cache } = apolloClient;

    const data = cache.readQuery({
      query: TransactionQuery,
      variables: {
        transactionId,
        organizationId,
      },
    });

    const cachedEdges = data.transaction.document_bundle.documents.edges;
    const cachedEdgesWithoutOriginalDocument = cachedEdges.filter(
      (edge) => edge.node.id !== originalDocumentId,
    );

    cache.writeQuery({
      query: TransactionQuery,
      variables: { documentBundleId },
      data: {
        ...data,
        transaction: {
          ...data.transaction,
          templateSplittingResults: templateSplittingResultsData,
          document_bundle: {
            ...data.transaction.document_bundle,
            documents: {
              ...data.transaction.document_bundle.documents,
              edges: [...cachedEdgesWithoutOriginalDocument, ...normalizedSplitDocumentsData],
            },
          },
        },
      },
    });
  };

  render() {
    const {
      transaction,
      organization,
      viewer,
      supportedFileTypes,
      onOpenAnnotateModal,
      onCloseAnnotateModal,
      className,
      canSetDocRequirements,
      canSetDocPermissions,
      canRequireMeeting,
      canRequireProofing,
      showWitnessRequired,
      openAnnotateModalAfterDocumentsUploaded,
      disableTemplateUpload,
      readOnly,
      cannotEditDocs,
      allowDownload,
      defaultDocRequirements,
      showAcceptedDocuments,
      annotatingByPrompt,
      showUploaderMessaging,
      hasPermissionFor,
    } = this.props;
    const { textTagSyntax, disableSplitting, splitBookmarkedPdfValue, templates } = this.state;

    const documents = transaction.document_bundle.documents.edges.map(({ node }) => ({
      id: node.id,
      name: node.name,
      createdAt: node.createdAt,
      processingState: node.processing_state,
      mimeType: lookupMimeTypeFromName(node.name),
      notarizationRequired: node.notarization_required,
      proofingRequired: node.proofing_required,
      failedTemplateMatch: node.failedTemplateMatch,
      witnessRequired: node.witness_required,
      vaulted: node.vaulted,
      esign: node.esign,
      signerCanAnnotate: node.customer_can_annotate,
      signingRequiresMeeting: node.signing_requires_meeting,
      hidden: node.hidden,
      isConsentForm: node.is_consent_form,
      isEnote: node.isEnote,
      notes: node.notes,
      s3OriginalAsset: node.s3OriginalAsset,
      organization: node.organization,
      metadata: node.metadata,
      designations: node.designations,
      canUpdate: node.canUpdate,
      requirement: node.requirement,
      derivedFromTemplate: node.derivedFromTemplate,
    }));

    const textTagSyntaxManager =
      hasPermissionFor("setTextTagSyntax") ||
      organization.defaultTextTagSyntax !== TextTagSyntax.NONE
        ? {
            onChange: this.handleTextTagSyntaxChange,
            value: textTagSyntax,
          }
        : null;
    const disableSplittingManager =
      hasPermissionFor("disableDocumentAutoTag") ||
      organization.featureList.includes(Feature.SPLIT_AND_TAG_DOCUMENTS)
        ? {
            onChange: this.handleDisableSplittingChange,
            value: disableSplitting,
          }
        : null;
    const splitBookmarkedPdfManager = organization.transactionLevelBookmarkSplitting
      ? {
          onChange: this.handleSplitBookmarkedPdfChange,
          value: splitBookmarkedPdfValue,
        }
      : null;

    return (
      <DocumentUploader
        documentBundleId={transaction.document_bundle.id}
        onChangeDocumentProperties={this.handleChangeDocumentProperties}
        onDeleteDocuments={this.handleDeleteDocuments}
        setDocumentToReplace={this.setDocumentToReplace}
        onReplaceDocument={this.handleReplaceDocument}
        onReorderDocument={this.handleReorderDocuments}
        onRenameDocument={this.handleRenameDocument}
        onResubmitDocument={this.handleResubmitDocument}
        uploadStrategy={this.uploadStrategy}
        addButtonContent={this.documentUploaderAddButtonContent}
        onDropFiles={this.handleDropFiles}
        onCancelUpload={this.handleCancelUpload}
        onUploadComplete={this.handleUploadComplete}
        disableSplittingManager={disableSplittingManager}
        onOpenAnnotateModal={onOpenAnnotateModal}
        onCloseAnnotateModal={onCloseAnnotateModal}
        textTagSyntaxManager={textTagSyntaxManager}
        splitBookmarkedPdfManager={splitBookmarkedPdfManager}
        documents={documents}
        transaction={transaction}
        organization={{ ...organization, templates }}
        viewer={viewer}
        supportedFileTypes={supportedFileTypes}
        canRequireProofing={canRequireProofing}
        className={className}
        canSetDocRequirements={canSetDocRequirements}
        canSetDocPermissions={canSetDocPermissions}
        canRequireMeeting={canRequireMeeting}
        showWitnessRequired={showWitnessRequired}
        openAnnotateModalAfterDocumentsUploaded={openAnnotateModalAfterDocumentsUploaded}
        onOpenAddDocumentModal={this.props.checkCanOpenAddDocumentModal}
        disableTemplateUpload={disableTemplateUpload}
        readOnly={readOnly}
        cannotEditDocs={cannotEditDocs}
        allowDownload={allowDownload}
        defaultDocRequirements={defaultDocRequirements}
        showAcceptedDocuments={showAcceptedDocuments}
        annotatingByPrompt={annotatingByPrompt}
        showUploaderMessaging={showUploaderMessaging}
        onSplitDocuments={this.handleSplitDocuments}
        userCanManageOrders={hasPermissionFor("manageOpenOrders")}
      />
    );
  }
}

const EXTERNAL_PROPS = {
  /**
   * List of file extensions to support within an instance of the uploader.
   * Must be a subset of the list of the all supported types (SUPPORTED_FILE_EXTENSIONS).
   */
  supportedFileTypes: PropTypes.arrayOf(PropTypes.oneOf(SUPPORTED_FILE_EXTENSIONS)).isRequired,
  transaction: PropTypes.object.isRequired,
  viewer: PropTypes.shape({
    user: PropTypes.shape({}),
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultTextTagSyntax: PropTypes.oneOf(Object.values(TextTagSyntax)),
    transactionLevelBookmarkSplitting: PropTypes.bool,
    evaultEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  onOpenAnnotateModal: PropTypes.func.isRequired,
  onCloseAnnotateModal: PropTypes.func,
  checkCanOpenAddDocumentModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  canSetDocRequirements: PropTypes.bool,
  canSetDocPermissions: PropTypes.bool,
  showWitnessRequired: PropTypes.bool,
  defaultDocRequirements: PropTypes.shape({
    notarizationRequired: PropTypes.bool,
    proofingRequired: PropTypes.bool,
    esign: PropTypes.bool,
    signingRequiresMeeting: PropTypes.bool,
  }),
  defaultDocPermissions: PropTypes.shape({
    customerCanAnnotate: PropTypes.bool,
    witnessRequired: PropTypes.bool,
  }),
  canRequireMeeting: PropTypes.bool,
  canRequireProofing: PropTypes.bool,
  disableTemplateUpload: PropTypes.bool,
  openAnnotateModalAfterDocumentsUploaded: PropTypes.bool,
  readOnly: PropTypes.bool,
  cannotEditDocs: PropTypes.bool,
  allowDownload: PropTypes.bool,
  showAcceptedDocuments: PropTypes.bool,
  annotatingByPrompt: PropTypes.bool,
  splitBookmarkedPdf: PropTypes.bool,
  showUploaderMessaging: PropTypes.bool,
  transactionQuery: PropTypes.object.isRequired,
};
const WRAPPER_PROVIDED_PROPS = {
  hasPermissionFor: PropTypes.func.isRequired,
  startPolling: PropTypes.func.isRequired,
};
TransactionDocumentUploader.propTypes = {
  ...EXTERNAL_PROPS,
  ...WRAPPER_PROVIDED_PROPS,
};

TransactionDocumentUploader.defaultProps = {
  canRequireMeeting: false,
  canRequireProofing: false,
  canSetDocRequirements: true,
  canSetDocPermissions: true,
  showWitnessRequired: true,
  supportedFileTypes: SUPPORTED_FILE_EXTENSIONS,
  defaultDocRequirements: {
    esign: false,
    notarizationRequired: true,
    proofingRequired: false,
    signingRequiresMeeting: true,
  },
  defaultDocPermissions: {
    witnessRequired: false,
  },
  disableTemplateUpload: false,
  openAnnotateModalAfterDocumentsUploaded: false,
  readOnly: false,
  cannotEditDocs: false,
  allowDownload: false,
  showAcceptedDocuments: true,
  annotatingByPrompt: false,
  splitBookmarkedPdf: null,
};

function TransactionDocumentUploaderWrapper(props) {
  const removeDocumentFromTransactionMutateFn = useMutation(RemoveDocumentFromTransaction);
  const updateTransactionBundleMutateFn = useMutation(UpdateTransactionBundleMutation);
  const updateDocumentPositionsMutateFn = useMutation(UpdateDocumentPositionsMutation);
  const createDocumentsMutateFn = useMutation(CreateDocumentsMutation);
  const addDocumentsToTransactionMutateFn = useMutation(AddDocumentsToTransactionMutation);
  const addTemplateToBundleMutateFn = useMutation(AddTemplateToBundleMutation);
  const startPolling = useDocumentPoller();
  const { hasPermissionFor } = usePermissions();
  const apolloClient = useApolloClient();
  return (
    <TransactionDocumentUploader
      removeDocumentFromTransactionMutateFn={removeDocumentFromTransactionMutateFn}
      updateTransactionBundleMutateFn={updateTransactionBundleMutateFn}
      updateDocumentPositionsMutateFn={updateDocumentPositionsMutateFn}
      createDocumentsMutateFn={createDocumentsMutateFn}
      addDocumentsToTransactionMutateFn={addDocumentsToTransactionMutateFn}
      addTemplateToBundleMutateFn={addTemplateToBundleMutateFn}
      startPolling={startPolling}
      hasPermissionFor={hasPermissionFor}
      apolloClient={apolloClient}
      {...props}
    />
  );
}
TransactionDocumentUploaderWrapper.propTypes = EXTERNAL_PROPS;
export default TransactionDocumentUploaderWrapper;
