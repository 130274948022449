import { type ReactNode, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { type OrgTransactionStates, OrganizationTransactionContactRoleType } from "graphql_globals";
import { ROLE_LABELS } from "constants/points_of_contact";
import { userFullName } from "util/user";
import { Card } from "common/core/card";
import { captureException } from "util/exception";
import { DetailsDescriptionList } from "common/transactions/details/common";

import Styles from "./index.module.scss";
import type { ContactForTransactionDetailsContactDetails } from "../index_fragment.graphql";
import { ContactDetailsHeading, RemoveContactModal } from "../../common";

type Props = {
  contact: ContactForTransactionDetailsContactDetails;
  children: ReactNode;
  transaction: { state: OrgTransactionStates | null };
  onRemove?: () => Promise<unknown>;
  "data-automation-id": string;
};
const MESSAGES = defineMessages({
  removeButtonLabel: {
    id: "ba5dab63-9ecd-4e00-97aa-bc22cdc5e6af",
    defaultMessage: "Delete CC contact {user}",
  },
});

export function ContactDetailsWrapper({
  contact,
  children,
  onRemove,
  transaction,
  "data-automation-id": automationId,
}: Props) {
  const intl = useIntl();
  const [removeModalState, setRemoveModalState] = useState<"open" | "loading" | "closed">("closed");

  const header = userFullName(contact);
  const subheader =
    contact.role === OrganizationTransactionContactRoleType.OTHER
      ? contact.title
      : ROLE_LABELS[contact.role];
  const removeButtonLabel = intl.formatMessage(MESSAGES.removeButtonLabel, {
    user: header,
  });

  return (
    <>
      <Card
        data-automation-id={automationId}
        header={
          <ContactDetailsHeading
            header={header}
            subheader={subheader}
            onRemove={onRemove}
            removeButtonLabel={removeButtonLabel}
            openRemoveModal={() => setRemoveModalState("open")}
            automationId={automationId}
          />
        }
        collapsibleElement="icon"
        expanded
        noMargin
        fullWidth
        className={Styles.contactDetailsCard}
      >
        <DetailsDescriptionList>{children}</DetailsDescriptionList>
      </Card>
      {removeModalState !== "closed" && (
        <RemoveContactModal
          contact={contact}
          isLoading={removeModalState === "loading"}
          onClose={() => setRemoveModalState("closed")}
          onRemove={() => {
            setRemoveModalState("loading");
            onRemove!()
              .catch(() => {
                captureException(new Error("Couldn't delete organization transaction contact"), {
                  contact,
                  transaction,
                });
              })
              .finally(() => {
                setRemoveModalState("closed");
              });
          }}
        />
      )}
    </>
  );
}
