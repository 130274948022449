import { useIntl } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";
import { useForm, useFieldArray, useWatch } from "common/core/form";
import { Card, CardSection } from "common/core/card";
import { FieldErrorMessage, isAriaInvalid } from "common/core/form/error";
import { type SAMLProviderAttributeMappingInput } from "graphql_globals";
import { useMutation } from "util/graphql";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Icon from "common/core/icon";
import { Select } from "common/core/form/select";
import { StyledTextInput } from "common/core/form/text";
import UpdateSamlProviderMutation from "common/organization/access/identity_providers/view_idp/update_saml_provider.mutation.graphql";
import {
  type SamlProvider_organization_Organization_samlProviders as SamlProvider,
  type SamlProvider_organization_Organization_samlProviders_attributeMapping as AttributeMappingGraphType,
} from "common/organization/access/identity_providers/saml_providers.query.graphql";

import Styles from "./attribute_mapping.module.scss";
import CommonStyles from "./view_idp/index.module.scss";

const MESSAGES = {
  updateFailed: {
    id: "5a2e463a-b337-470b-9b1d-83cbfdc7b537",
    defaultMessage: "Failed to save changes. If this issue persists please contact support.",
  },
  cancel: {
    id: "a5e67cc0-4434-4b43-b5b6-2b9e26d1b152",
    defaultMessage: "Cancel",
  },
  saveChanges: {
    id: "21d3ad0b-c889-4c55-acad-16fa5301122e",
    defaultMessage: "Save changes",
  },
  updateSuccess: {
    id: "75acb107-155d-45e1-9caf-d5fbb43632e1",
    defaultMessage:
      "Your changes have been saved! We recommend that you now test logging in with SSO.",
  },
  modalTitle: {
    id: "9b65b36d-7705-4aa1-a91f-35982c0abef3",
    defaultMessage: "Configure attribute mapping",
  },
  modalDescription: {
    id: "c21675be-6069-4c8d-a277-8032dc74b71d",
    defaultMessage:
      "Select one of the defined Proof attributes and enter your IDP’s corresponding key. Proof will check for a custom key first, then use our system defaults.",
  },
  addCustomMapping: {
    id: "15945648-76c7-41ca-8ac4-4a2ad08b78e0",
    defaultMessage: "Add custom mapping",
  },
  removeAll: {
    id: "86d123c3-0190-4736-a4f7-5a2e32e85cbd",
    defaultMessage: "Remove all",
  },
  remove: {
    id: "0b42b0ee-44f0-47cd-bd75-ea7778e59d11",
    defaultMessage: "Remove",
  },
  proofAttribute: {
    id: "ce8949a9-855e-4d4f-b7dd-9e39101d71da",
    defaultMessage: "Proof's attribute",
  },
  idpAttribute: {
    id: "cfb95a31-9a95-490f-98e6-74f46c08d5ab",
    defaultMessage: "Your IDP's attribute",
  },
  selectOne: {
    id: "0e97cbd1-afe7-4434-a195-cc640a4f92fa",
    defaultMessage: "Select one",
  },
  valueRequired: {
    id: "6505abd0-1918-48fa-80d1-44171ec099ba",
    defaultMessage: "A value is required for this field",
  },
  configureAttributeMapping: {
    id: "dad2dccc-a16c-44bd-99d0-fa74041f164a",
    defaultMessage: "Configure attribute mapping",
  },
  cardTitle: {
    id: "e7762b04-bb8a-43eb-baea-cefa73589827",
    defaultMessage: "Attribute mapping",
  },
};

type ProofAttributeKey = keyof AttributeMappingGraphType;

const PROOF_ATTRIBUTE_KEYS: ProofAttributeKey[] = [
  "nameid",
  "firstName",
  "middleName",
  "lastName",
  "name",
  "email",
  "roles",
  "organizationId",
  "notaryState",
  "notaryLanguages",
];

const PROOF_ATTRIBUTE_KEY_LABELS = {
  nameid: "nameid",
  firstName: "first_name",
  middleName: "middle_name",
  lastName: "last_name",
  name: "name",
  email: "email",
  roles: "roles",
  organizationId: "organization_id",
  notaryState: "notary_state",
  notaryLanguages: "notary_languages",
};

type NonEmptyAttributeMap = {
  proofAttributeKey: ProofAttributeKey;
  idpAttributeKey: string;
};

type AttributeMap =
  | NonEmptyAttributeMap
  | {
      proofAttributeKey: "";
      idpAttributeKey: string;
    };

function parseAttributeMapping(mapping: AttributeMappingGraphType | null): NonEmptyAttributeMap[] {
  return PROOF_ATTRIBUTE_KEYS.reduce(
    (parsedMappings: NonEmptyAttributeMap[], key: ProofAttributeKey) => {
      if (mapping?.[key]) {
        parsedMappings.push({
          proofAttributeKey: key,
          idpAttributeKey: mapping[key],
        });
      }
      return parsedMappings;
    },
    [],
  );
}

function formDataToGraphInput({
  values,
}: AttributeMappingFormValues): SAMLProviderAttributeMappingInput {
  return values.reduce<SAMLProviderAttributeMappingInput>(
    (attributeMapping, { proofAttributeKey, idpAttributeKey }) => {
      if (proofAttributeKey && idpAttributeKey) {
        attributeMapping[proofAttributeKey] = idpAttributeKey;
      }
      return attributeMapping;
    },
    {},
  );
}

type AttributeMappingFormValues = {
  values: AttributeMap[];
};

type Props = {
  idp: SamlProvider;
  closeModal: () => void;
};

export function AttributeMappingModal({ idp, closeModal }: Props) {
  const intl = useIntl();

  const updateSamlProvider = useMutation(UpdateSamlProviderMutation);
  const onSubmit = (data: AttributeMappingFormValues) => {
    const attributeMappingInput = formDataToGraphInput(data);
    return updateSamlProvider({
      variables: {
        input: {
          id: idp.id,
          attributeMapping: attributeMappingInput,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(MESSAGES.updateSuccess),
          position: "topCenter",
        });
        closeModal();
      })
      .catch((error) => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(MESSAGES.updateFailed),
          position: "topCenter",
        });
        captureException(error);
      });
  };
  const defaultValues = {
    values: parseAttributeMapping(idp.attributeMapping),
  };
  const form = useForm<AttributeMappingFormValues>({
    defaultValues,
    shouldUnregister: true,
  });
  const { fields, append, remove } = useFieldArray({
    name: "values",
    control: form.control,
  });

  const { errors, isSubmitting } = form.formState;

  const watch = useWatch({ name: "values", control: form.control });
  const currentProofAttributeKeys = watch.map((item) => item.proofAttributeKey);

  return (
    <WorkflowModal
      autoFocus
      positionTop
      large
      title={intl.formatMessage(MESSAGES.modalTitle)}
      buttons={[
        <Button key="cancel" buttonColor="dark" variant="tertiary" onClick={closeModal}>
          {intl.formatMessage(MESSAGES.cancel)}
        </Button>,
        <Button
          key="save-changes"
          buttonColor="action"
          variant="primary"
          isLoading={isSubmitting}
          onClick={form.handleSubmit(onSubmit)}
        >
          {intl.formatMessage(MESSAGES.saveChanges)}
        </Button>,
      ]}
      footerSeparator={false}
    >
      <div className={Styles.modal}>
        <Paragraph className={Styles.modalDescription}>
          {intl.formatMessage(MESSAGES.modalDescription)}
        </Paragraph>
        <form>
          {fields.map((field, idx) => {
            const fieldError = errors.values?.[idx];
            const selectedKey = currentProofAttributeKeys[idx];
            const items = PROOF_ATTRIBUTE_KEYS.filter((key) => {
              return (
                selectedKey === key || !currentProofAttributeKeys.some((usedKey) => usedKey === key)
              );
            }).map((key) => {
              return { label: PROOF_ATTRIBUTE_KEY_LABELS[key], value: key };
            });
            return (
              <div key={field.id} className={Styles.attributeMappingRow}>
                <div>
                  <Select
                    aria-invalid={isAriaInvalid(fieldError?.proofAttributeKey)}
                    displayRequiredAsterisk
                    label={intl.formatMessage(MESSAGES.proofAttribute)}
                    items={items}
                    placeholder={intl.formatMessage(MESSAGES.selectOne)}
                    selectedValue={selectedKey}
                    {...form.register(`values.${idx}.proofAttributeKey`, { required: true })}
                  />
                  {fieldError?.proofAttributeKey && (
                    <FieldErrorMessage
                      inputName={`values.${idx}.proofAttributeKey`}
                      message={
                        fieldError.proofAttributeKey.message ||
                        intl.formatMessage(MESSAGES.valueRequired)
                      }
                    />
                  )}
                </div>
                <Icon name="configure" className={Styles.configureIcon} />
                <div>
                  <StyledTextInput
                    label={intl.formatMessage(MESSAGES.idpAttribute)}
                    displayRequiredAsterisk
                    aria-invalid={isAriaInvalid(fieldError?.idpAttributeKey)}
                    {...form.register(`values.${idx}.idpAttributeKey`, { required: true })}
                  />
                  {fieldError?.idpAttributeKey && (
                    <FieldErrorMessage
                      inputName={`values.${idx}.idpAttributeKey`}
                      message={
                        fieldError.idpAttributeKey.message ||
                        intl.formatMessage(MESSAGES.idpAttribute)
                      }
                    />
                  )}
                </div>
                <Button
                  variant="tertiary"
                  buttonColor="danger"
                  disabled={isSubmitting}
                  withIcon={{ name: "delete", placement: "left" }}
                  className={Styles.removeRow}
                  onClick={() => remove(idx)}
                >
                  {intl.formatMessage(MESSAGES.remove)}
                </Button>
              </div>
            );
          })}
          <div className={Styles.listControlButtonRow}>
            <Button
              variant={fields.length > 0 ? "secondary" : "primary"}
              disabled={isSubmitting || fields.length >= PROOF_ATTRIBUTE_KEYS.length}
              buttonColor="action"
              withIcon={{ name: "add", placement: "left" }}
              onClick={() => append({ proofAttributeKey: "", idpAttributeKey: "" })}
            >
              {intl.formatMessage(MESSAGES.addCustomMapping)}
            </Button>

            <Button
              variant="tertiary"
              disabled={isSubmitting || fields.length === 0}
              buttonColor="danger"
              withIcon={{ name: "delete", placement: "left" }}
              onClick={() => remove()}
            >
              {intl.formatMessage(MESSAGES.removeAll)}
            </Button>
          </div>
        </form>
      </div>
    </WorkflowModal>
  );
}

type DetailsProps = {
  idp: SamlProvider;
  openEditModal: () => void;
};

export function AttributeMappingDetails({ idp, openEditModal }: DetailsProps) {
  const intl = useIntl();

  const values = parseAttributeMapping(idp.attributeMapping);
  if (values.length === 0) {
    return null;
  }

  return (
    <div className={CommonStyles.samlCardContainer}>
      <Card
        className={CommonStyles.samlCard}
        noMargin
        header={
          <div className={CommonStyles.cardHeader}>
            <Heading level="h2" textStyle="headingTwo" className={CommonStyles.cardTitle}>
              {intl.formatMessage(MESSAGES.cardTitle)}
            </Heading>
            <Button variant="secondary" buttonColor="action" onClick={openEditModal}>
              {intl.formatMessage(MESSAGES.configureAttributeMapping)}
            </Button>
          </div>
        }
      >
        <CardSection>
          {values.map((attributeMap, idx) => (
            <AttributeMapDetail attributeMap={attributeMap} key={idx} />
          ))}
        </CardSection>
      </Card>
    </div>
  );
}

function AttributeMapDetail({ attributeMap }: { attributeMap: NonEmptyAttributeMap }) {
  const intl = useIntl();

  return (
    <div className={Styles.attributeMappingRow}>
      <StyledTextInput
        label={intl.formatMessage(MESSAGES.proofAttribute)}
        disabled
        aria-invalid={false}
        readOnly
        value={PROOF_ATTRIBUTE_KEY_LABELS[attributeMap.proofAttributeKey]}
      />
      <Icon name="configure" className={Styles.configureIcon} />
      <StyledTextInput
        label={intl.formatMessage(MESSAGES.idpAttribute)}
        disabled
        aria-invalid={false}
        readOnly
        value={attributeMap.idpAttributeKey}
      />
    </div>
  );
}
