import type { ComponentProps, ReactNode } from "react";
import classnames from "classnames";

import { Heading } from "common/core/typography";

import Styles from "./empty.module.scss";

type EmptyViewProps = {
  children?: ReactNode;
  img?: ReactNode;
  title?: {
    text: ReactNode;
    headingLevel: ComponentProps<typeof Heading>["level"];
    headingStyle: ComponentProps<typeof Heading>["textStyle"];
  };
};
function EmptyView({ children, title, img }: EmptyViewProps) {
  const wrapperStyles = classnames(Styles.emptyViewWrapper, !img && Styles.noImg);
  return (
    <div className={wrapperStyles}>
      <div className={Styles.emptyView}>
        {img}
        {title && (
          <Heading level={title.headingLevel} textStyle={title.headingStyle}>
            {title.text}
          </Heading>
        )}
        {children}
      </div>
    </div>
  );
}

export default EmptyView;
