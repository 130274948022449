import { useState, useRef, useEffect, useCallback, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";
import { AnnotationSubtype } from "graphql_globals";
import { isiOSDevice } from "util/support";
import { focusForIOs } from "util/html";

import Styles from "./mobile_pdf_toolbar.module.scss";

type ChildrenRenderProp = {
  handleToolClick: () => void;
};
type Props = {
  signerIds: string[];
  document: {
    id: string;
    annotations: {
      edges: {
        node: {
          id: string;
          authorId: string;
        };
      }[];
    };
  };
  onDone: () => void;
  children: (props: ChildrenRenderProp) => ReactNode;
  currentToolType: AnnotationSubtype | undefined | null;
};

const INSTRUCTIONS_KEY = "hasSeenMobilePdfToolbarInstructions";

function MobilePdfToolbar({ document, signerIds, onDone, currentToolType, children }: Props) {
  const [instructions, setInstructions] = useState(() => ({
    hasSeen: window.sessionStorage.getItem(INSTRUCTIONS_KEY) === "true",
    visible: false,
  }));

  const initialDocumentAnnotations = useRef<{ documentId: string; annotationIds?: Set<string> }>({
    documentId: "",
  });

  const dismissInstructions = () => {
    setInstructions({ hasSeen: true, visible: false });
  };

  const toolClick = () => {
    if (!instructions.hasSeen) {
      window.sessionStorage.setItem(INSTRUCTIONS_KEY, "true");
      setInstructions({
        hasSeen: true,
        visible: true,
      });
    }
  };

  useEffect(() => {
    const currentAnnotations = document.annotations.edges.map((e) => e.node);
    const { current: initial } = initialDocumentAnnotations;

    if (initial.documentId !== document.id) {
      initial.documentId = document.id;
      initial.annotationIds = new Set(currentAnnotations.map((a) => a.id));
    }

    if (instructions.hasSeen && instructions.visible) {
      const newSignerPlacedAnnotation = currentAnnotations.find(
        ({ id, authorId }) =>
          initial.annotationIds && !initial.annotationIds.has(id) && signerIds.includes(authorId),
      );
      if (newSignerPlacedAnnotation) {
        dismissInstructions();
      }
    }
  }, [document.id, document.annotations]);
  // We need to add this custom behavior for IOs devices because in an effort to limit programatically fired focus
  // from opening the keyboard, the programatic focus must be paired with a user generated event. So here we fire
  // the programatic focus right after the user clicks the iframe body and when the text annotation tool is selected
  const isIos = isiOSDevice();
  const iframeBody = (
    window.document.querySelector("iframe[title='Uploaded PDF Document']") as
      | HTMLIFrameElement
      | undefined
  )?.contentDocument?.body;
  const removeClickListener = (handler: () => void) => {
    iframeBody?.removeEventListener("click", handler);
  };

  const handleClick = useCallback(() => {
    focusForIOs();
    removeClickListener(handleClick);
  }, []);

  useEffect(() => {
    const freeTextSelected = currentToolType === AnnotationSubtype.FREE_TEXT;
    if (isIos && freeTextSelected && iframeBody) {
      iframeBody.addEventListener("click", handleClick);
    }
    if (isIos && !currentToolType) {
      // We have to delay this removal otherwise the tool data is set to null on click
      // and it will remove the above click handler before it can fire
      setTimeout(() => removeClickListener(handleClick), 100);
    }
  }, [currentToolType]);

  useEffect(() => {
    return () => {
      if (isIos) {
        removeClickListener(handleClick);
      }
    };
  }, []);

  return (
    <>
      <div
        className={Styles.placeToolInstructionsContainer}
        data-automation-id="mobile-pdf-annotations-toolbar"
      >
        {instructions.visible && (
          <span className={Styles.placeToolInstructions}>
            <Icon name="touch" />
            <FormattedMessage
              id="5dfe2dff-f9de-4bda-8e5a-672242ffd086"
              defaultMessage="Tap anywhere to place"
            />
            <button type="button" className={Styles.closeButton} onClick={dismissInstructions}>
              <SROnly>
                <FormattedMessage
                  id="5dfe2dff-f9de-4bda-8e5a-672242ffd086"
                  defaultMessage="Dismiss"
                />
              </SROnly>
              <Icon name="x-mark" />
            </button>
          </span>
        )}
      </div>
      <div className={Styles.mobilePdfToolbar}>
        <Button
          buttonColor="action"
          variant="primary"
          className={Styles.doneButton}
          onClick={onDone}
        >
          <FormattedMessage id="b8444d30-c3b8-43b8-b173-34ac2633532d" defaultMessage="Done" />
        </Button>
        <ul className={Styles.toolbarScrollContainer}>
          {children({ handleToolClick: toolClick })}
        </ul>
      </div>
    </>
  );
}

export default MobilePdfToolbar;
