import { useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import type { OrganizationTypeEnum } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { useMutation, useQuery } from "util/graphql";
import Icon from "common/core/icon";
import { Badge } from "common/core/badge";
import { useForm } from "common/core/form";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { Heading } from "common/core/typography";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import { FieldErrorMessage, isAriaInvalid } from "common/core/form/error";
import { DeprecatedStyledTextInput } from "common/form/inputs/text";
import { NativeLabel } from "common/core/form/text";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { pushNotification } from "common/core/notification_center/actions";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import { useIsCommandCenter } from "common/proof_frame/path";
import { useFeatureFlag } from "common/feature_gating";
import { COMMAND_CENTER_ORG_DETAILS } from "constants/feature_gates";

import UpdateOrganizationBrandMutation from "./graphql/update_organization_brand.mutation.graphql";
import CreateOrganizationBrandMutation from "./graphql/create_organization_brand.mutation.graphql";
import CompanyOrgStructureMain, {
  type CompanyOrgStructureMain_mainOrganization_Organization as MainOrganization,
} from "./graphql/index_main.query.graphql";
import OrganizationBrandQuery, {
  type OrganizationBrand as OrganizationBrandType,
  type OrganizationBrand_organization_Organization_organizationBrand as OrgBrandType,
  type OrganizationBrand_organization_Organization as OrgType,
  type OrganizationBrandVariables as OrgBrandVariablesType,
} from "./graphql/organization_brand.query.graphql";
import CompanyOrgStructureRoot, {
  type CompanyOrgStructureRoot_rootOrganization_Organization as RootOrganization,
  type CompanyOrgStructureRoot_rootOrganization_Organization_subsidiaryOrganizations as RootSubsidiaryOrganization,
} from "./graphql/index_root.query.graphql";
import Styles from "./index.module.scss";
import { navigateToOrg, RowPopoutMenu } from "../common/row_popout_menu";
import { CompanyOrgTable } from "../table_structure/table";
import { OrganizationDetailsModal } from "../modals/organization_details_modal";

type Props = { mainOrganization: MainOrganization; rootOrganization: RootOrganization };

type SubsidiaryOrganizationWithChildren = RootSubsidiaryOrganization & {
  children: SubsidiaryOrganizationWithChildren[];
};

type FormValues = { companyName: string };

type ItemProps = {
  organization: SubsidiaryOrganizationWithChildren;
  mainOrganization: MainOrganization;
  parentOrganization: MainOrganization | RootSubsidiaryOrganization;
  rootOrganizationId: string;
  level: number;
};

type OrganizationTree = {
  __typename: "Organization";
  children: SubsidiaryOrganizationWithChildren[];
  id: string;
  name: string | null;
  organizationType: OrganizationTypeEnum;
  parentOrganizationId: string | null;
  organizationWeight: number;
};

const MESSAGES = defineMessages({
  totalOrgs: {
    id: "3f15a0f8-6af1-4bfc-bf6c-965022613752",
    defaultMessage:
      "{orgCount} {multiple, select, true {total organizations} other {organization}}",
  },
  orgTreeHeader: {
    id: "9fe94eb4-d51d-4e15-8895-6e85ce87a1f8",
    defaultMessage: "organization name / id",
  },
  addEditCompanyTitleAdd: {
    id: "900cde10-4cb7-44ca-9659-750b01075e93",
    defaultMessage: "Add company name",
  },
  addEditCompanyTitleEdit: {
    id: "900cde10-4cb7-44ca-9659-750b01075e93",
    defaultMessage: "Edit company name",
  },
  companyNameInputLabel: {
    id: "91bb8712-e779-4a95-bc73-30b7c9417e2a",
    defaultMessage: "Company name",
  },
  notificationSuccess: {
    id: "1e2f7d95-8ef9-4716-8b90-c71026d6674d",
    defaultMessage: "Company name saved.",
  },
  notificationFailed: {
    id: "7098025c-9074-4da4-b4e6-dc70310b176d",
    defaultMessage: "Failed to update company name.",
  },
});

const findChildren = (
  parents: RootSubsidiaryOrganization[],
  referenceArray: RootSubsidiaryOrganization[],
): SubsidiaryOrganizationWithChildren[] =>
  parents.map((parent) => ({
    ...parent,
    children: findChildren(
      referenceArray.filter((child) => child.parentOrganizationId === parent.id),
      referenceArray,
    ),
  }));

function OrganizationItem(props: ItemProps) {
  const { organization, parentOrganization, mainOrganization, rootOrganizationId, level } = props;

  const [itemSelected, setItemSelected] = useState(false);

  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const mainOrgHighlight = organization.id === mainOrganization.id && isAdminPortal;
  const selectedHighlight =
    itemSelected && !(organization.id === mainOrganization.id && isAdminPortal);
  const itemInnerCx = classnames(
    Styles.organizationItemInner,
    mainOrgHighlight && Styles.organizationItemInnerMain,
    selectedHighlight && Styles.organizationItemInnerSelected,
  );

  return (
    <>
      <div className={Styles.organizationItem}>
        <div className={itemInnerCx} style={{ paddingLeft: level === 0 ? 12 : `${level * 24}px` }}>
          <div>
            {level !== 0 && <Icon name="arrow-down-right" className={Styles.arrowDownRight} />}
            <span>{organization.name}</span>
            <span className={Styles.organizationItemInnerId}>
              (
              <LinkStyledButton
                onClick={() => navigateToOrg(organization.id, organization.organizationType)}
              >
                {organization.id}
              </LinkStyledButton>
              )
            </span>
            {level === 0 && (
              <Badge kind="infoSubtle" className={Styles.organizationItemBadge}>
                <FormattedMessage
                  id="3cc5e963-ea32-4077-970c-d2661cf3a5d7"
                  defaultMessage="Root organization"
                />
              </Badge>
            )}
          </div>
          <div
            className={classnames(
              Styles.sideMenuContainer,
              mainOrgHighlight && Styles.mainOrg,
              selectedHighlight && Styles.selected,
            )}
          >
            <RowPopoutMenu
              {...{ organization, rootOrganizationId, level, setItemSelected }}
              parentOrganization={{ id: parentOrganization.id, name: parentOrganization.name }}
            />
          </div>
        </div>
        <div>
          {organization.children.map((child) => (
            <OrganizationItem
              key={`organization-item-${child.id}`}
              organization={child}
              parentOrganization={organization}
              mainOrganization={mainOrganization}
              rootOrganizationId={rootOrganizationId}
              level={level + 1}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function groupOrganizationsWithParent(
  parentOrganization: OrganizationTree | SubsidiaryOrganizationWithChildren,
  referenceArray: (OrganizationTree | SubsidiaryOrganizationWithChildren)[],
) {
  parentOrganization.children.forEach((org: SubsidiaryOrganizationWithChildren) => {
    referenceArray.push(org);
    if (org.children.length) {
      groupOrganizationsWithParent(org, referenceArray);
    }
  });
  return referenceArray;
}

function CompanyOrgStructure(props: Props) {
  const intl = useIntl();
  const isCommandCenter = useIsCommandCenter();
  const isOrgDetailsFlagEnabled = useFeatureFlag(COMMAND_CENTER_ORG_DETAILS);
  const [currentOrgId, setCurrentOrgId] = useState<string | null>();
  const { rootOrganization, mainOrganization } = props;
  const tree = useMemo(() => {
    const rootChildren = findChildren(
      rootOrganization.subsidiaryOrganizations.filter(
        (i) => i.parentOrganizationId === rootOrganization.id,
      ),
      rootOrganization.subsidiaryOrganizations,
    );

    return { ...rootOrganization, children: rootChildren };
  }, [rootOrganization]);

  const showDetailsTable = isCommandCenter && isOrgDetailsFlagEnabled;

  const items = [tree];
  groupOrganizationsWithParent(tree, items);

  return (
    <div className={Styles.companyOrgStructure}>
      <div className={Styles.orgTreeSubheading}>
        <div className={Styles.orgCount} data-automation-id={"org-count"}>
          {intl.formatMessage(MESSAGES.totalOrgs, {
            orgCount: rootOrganization.subsidiaryOrganizations.length + 1,
            multiple: rootOrganization.subsidiaryOrganizations.length + 1 > 1,
          })}
        </div>
        {!showDetailsTable && (
          <div>
            <Heading level="h2" textStyle="headingThree" className={Styles.orgTreeHeader}>
              {intl.formatMessage(MESSAGES.orgTreeHeader)}
            </Heading>
          </div>
        )}
        {showDetailsTable && <CompanyOrgTable items={items} setCurrentOrgId={setCurrentOrgId} />}
        {currentOrgId && (
          <OrganizationDetailsModal
            organizationId={currentOrgId}
            onCancel={() => {
              setCurrentOrgId(null);
            }}
            mainOrgId={mainOrganization.id}
          />
        )}
      </div>
      <OrganizationItem
        organization={tree}
        parentOrganization={mainOrganization}
        mainOrganization={mainOrganization}
        rootOrganizationId={rootOrganization.id}
        level={0}
      />
    </div>
  );
}

function RootOrg({ mainOrganization }: { mainOrganization: MainOrganization }) {
  const { data, loading } = useQuery(CompanyOrgStructureRoot, {
    variables: { organizationId: mainOrganization.rootOrganizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const rootOrganization = data!.rootOrganization;
  if (rootOrganization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${rootOrganization?.__typename}.`);
  }

  return (
    <CompanyOrgStructure mainOrganization={mainOrganization} rootOrganization={rootOrganization} />
  );
}

export function CompanyName({ organizationId }: { organizationId: string }) {
  const { data, loading } = useQuery(OrganizationBrandQuery, { variables: { organizationId } });
  const [showCompanyNameModal, setShowCompanyNameModal] = useState(false);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (data!.organization!.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${data!.organization!.__typename}.`);
  }

  const org: OrgType = data!.organization!;
  const orgBrand = org.organizationBrand;

  return (
    <>
      {orgBrand?.companyName && (
        <>
          <FormattedMessage
            id="eb096e8a-1c2b-44d5-a233-e43de38fbaf1"
            defaultMessage="Your company name is"
          />
          &nbsp;
          <span className={Styles.companyName}>{orgBrand.companyName}.</span>
        </>
      )}
      <ButtonStyledLink
        variant="tertiary"
        buttonColor="action"
        buttonSize="condensed"
        onClick={() => setShowCompanyNameModal(true)}
        automationId="add-edit-company-name"
      >
        {orgBrand?.companyName && (
          <FormattedMessage
            id="b398cd1a-2d4f-495e-8246-07a70f6045ba"
            defaultMessage="Edit company name"
          />
        )}
        {!orgBrand?.companyName && (
          <FormattedMessage
            id="e4f0499d-f922-4fa5-a867-639a468efc61"
            defaultMessage="Add company name"
          />
        )}
      </ButtonStyledLink>
      {showCompanyNameModal && (
        <AddEditCompanyNameModal
          organization={org}
          closeModal={() => setShowCompanyNameModal(false)}
        />
      )}
    </>
  );
}

function AddEditCompanyNameModal({
  organization,
  closeModal,
}: {
  organization: OrgType;
  closeModal: () => void;
}) {
  const intl = useIntl();

  const orgBrand: OrgBrandType | null = organization.organizationBrand;
  const [loading, setLoading] = useState(false);
  const createOrganizationBrand = useMutation(CreateOrganizationBrandMutation);
  const updateOrganizationBrand = useMutation(UpdateOrganizationBrandMutation);
  const form = useForm<FormValues>({ defaultValues: { companyName: orgBrand?.companyName || "" } });
  const { formState } = form;
  const { errors } = formState;

  const saveCompanyName = form.handleSubmit((values: FormValues) => {
    if (!formState.isDirty) {
      return;
    }
    setLoading(true);
    const successPush = () =>
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        message: intl.formatMessage(MESSAGES.notificationSuccess),
        position: "topCenter",
      });
    const failurePush = () =>
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(MESSAGES.notificationFailed),
        position: "topCenter",
      });

    const { companyName } = values;

    if (orgBrand) {
      updateOrganizationBrand({
        variables: { input: { organizationBrandId: orgBrand.id, companyName } },
      })
        .then(() => {
          successPush();
          closeModal();
        })
        .catch(failurePush)
        .finally(() => setLoading(false));
    } else {
      const organizationId = organization.id;
      createOrganizationBrand({
        variables: { input: { organizationId: organization.id, companyName } },
        update(cacheProxy, { data }) {
          const result = cacheProxy.readQuery<OrganizationBrandType, OrgBrandVariablesType>({
            query: OrganizationBrandQuery,
            variables: { organizationId },
          })!;
          const { organization } = result;
          if (!data?.createOrganizationBrand?.organizationBrand) {
            throw new Error("Organization brand failed to be created.");
          }
          const newNode = {
            ...organization,
            organizationBrand: data.createOrganizationBrand.organizationBrand,
          };
          cacheProxy.writeQuery({
            query: OrganizationBrandQuery,
            variables: { organizationId },
            data: { ...result, organization: newNode },
          });
        },
      })
        .then(() => {
          successPush();
          closeModal();
        })
        .catch(failurePush)
        .finally(() => setLoading(false));
    }
  });

  return (
    <WorkflowModal
      positionTop
      title={
        !orgBrand?.companyName
          ? intl.formatMessage(MESSAGES.addEditCompanyTitleAdd)
          : intl.formatMessage(MESSAGES.addEditCompanyTitleEdit)
      }
      buttons={[
        <Button key="close" buttonColor="action" variant="tertiary" onClick={closeModal}>
          <FormattedMessage id="1c4c187c-23c8-43ff-9f5d-932ff5c279c7" defaultMessage="Close" />
        </Button>,
        <Button
          key="save-changes"
          buttonColor="action"
          variant="primary"
          onClick={saveCompanyName}
          isLoading={loading}
          disabled={!formState.isDirty || Boolean(errors.companyName)}
          data-automation-id="save-company-changes"
        >
          <FormattedMessage
            id="6deab252-2dcd-4099-b1ca-7ee9b3902dd2"
            defaultMessage="Save changes"
          />
        </Button>,
      ]}
      closeBehavior={{ tag: "with-button", onClose: closeModal, disableClickOutside: true }}
      autoFocus
    >
      <NativeLabel
        label={intl.formatMessage(MESSAGES.companyNameInputLabel)}
        htmlFor="companyName"
      />
      <DeprecatedStyledTextInput
        aria-invalid={isAriaInvalid(errors.companyName)}
        data-automation-id="company-name-input"
        id="companyName"
        {...form.register("companyName", { required: true, pattern: /^\S.*$/g })}
        aria-required
      />
      {errors.companyName && (
        <FieldErrorMessage
          inputName="companyName"
          message={
            errors.companyName.message || (
              <FormattedMessage
                id="f6c53271-ec09-49db-999c-2677ccc2bea5"
                defaultMessage="Company name cannot be blank."
              />
            )
          }
        />
      )}
    </WorkflowModal>
  );
}

export function CompanyOrgStructureContainer({ orgId }: { orgId: string }) {
  const { data: mainData, loading: mainLoading } = useQuery(CompanyOrgStructureMain, {
    variables: { organizationId: orgId },
  });

  if (mainLoading) {
    return <LoadingIndicator />;
  }

  const mainOrganization = mainData!.mainOrganization;
  if (mainOrganization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${mainOrganization?.__typename}.`);
  }

  return <RootOrg mainOrganization={mainOrganization} />;
}
