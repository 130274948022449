import { FormattedMessage } from "react-intl";

import { Heading } from "common/core/typography";
import { Card } from "common/core/card";
import IdentityDocumentViewer, {
  parsePhotosForDocumentViewer,
} from "common/identity/document_viewer";

import type { ImageCapture as OrganizationCustomer } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  organizationCustomer: OrganizationCustomer;
};

export function ImageCapture({ organizationCustomer }: Props) {
  const { mostRecentSignerIdentityWithImages } = organizationCustomer;

  if (!mostRecentSignerIdentityWithImages) {
    return null;
  }

  return (
    <div className={Styles.container}>
      <Heading textStyle="subtitle" level="h2">
        <FormattedMessage
          id="9bdc111f-07d4-4106-94a4-edb207441296"
          defaultMessage="Most recent image capture"
        />
      </Heading>
      <Card fullWidth noMargin>
        <IdentityDocumentViewer
          photos={parsePhotosForDocumentViewer(mostRecentSignerIdentityWithImages)}
          requirements={{ secondary: false, selfie: false }}
        />
      </Card>
    </div>
  );
}
