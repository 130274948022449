import type { ComponentPropsWithoutRef, MouseEvent } from "react";
import classnames from "classnames";

import LinkStyles from "./index.module.scss";
import Styles from "./link_styled_button.module.scss";
import type { Props } from ".";

type LinkProps = Pick<
  Props,
  "className" | "disabled" | "loading" | "underlined" | "secondary" | "white" | "black"
>;

export type LinkStyledButtonProps = LinkProps & ComponentPropsWithoutRef<"button">;

export function LinkStyledButton({
  disabled,
  loading,
  underlined = true,
  secondary,
  white,
  black,
  className,
  ...props
}: LinkStyledButtonProps) {
  const cx = classnames(
    Styles.linkStyledButton,
    LinkStyles.link,
    className,
    underlined && LinkStyles.underlined,
    (disabled || loading) && LinkStyles.disabled,
    secondary && LinkStyles.secondary,
    white && LinkStyles.white,
    black && LinkStyles.black,
  );

  function validateOnClick(event: MouseEvent<HTMLButtonElement>) {
    if (disabled) {
      event.preventDefault();
    } // onClick may be undefined if we're using the button as a form submit
    else if (!loading && props.onClick) {
      props.onClick(event);
    }
  }

  return (
    <button
      {...props}
      type="button"
      className={cx}
      aria-disabled={disabled}
      onClick={validateOnClick}
    />
  );
}
