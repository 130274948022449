import { useState, type ReactNode } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";

import { HookFormPassword } from "common/account/password/password";
import { StyledTextInput } from "common/core/form/text";
import { useForm } from "common/core/form";
import { defaultRequiredMessage } from "common/core/form/error";
import { useA11y } from "common/accessibility";
import { useSelector } from "redux/util";
import TosMessage from "common/account/login/tos_message";
import PasswordRequirements from "common/account/password_requirements";
import { Heading } from "common/core/typography";
import request from "util/request";
import { emailDomainRestrictedError, passwordCompromised } from "errors/account";
import { COMPROMISED_PASSWORD, EMAIL_DOMAIN_RESTRICTED } from "redux/actions/authentication";
import { captureException } from "util/exception";
import { resetPasswordWithVerificationCode } from "common/account/login/util";
import { AccountBlurb } from "common/proof_frame/global_nav/proof_id_widget";
import { useDocumentTitles } from "util/document_title";
import { isPasswordStrong } from "util/password";

import ProofCard from "../card";
import { BackButton, Continue } from "./common";
import Styles from "./index.module.scss";
import type { MfaScreenType, MfaAuthOption } from "./mfa";
import type { ForgotPasswordScreenType } from "./forgot_password";
import type { EmailScreenType, OnAuthenticationFailed } from "./email";
import type { PasswordScreenType } from "./password";
import type { VerifyEmailCodeScreenType } from "./verify_email_code";

export type FormValues = {
  password: string;
};

const MESSAGES = defineMessages({
  emailLabel: {
    id: "25feddc4-b612-4b21-8af4-7992fc45b326",
    defaultMessage: "Email address",
  },
  passwordLabel: {
    id: "7474f03b-4279-4ef1-b989-04dd2be6641f",
    defaultMessage: "Password",
  },
  passwordAriaLabel: {
    id: "7474f03b-4279-4ef1-b989-04dd2be6641f",
    defaultMessage: "Create a password",
  },
  backButtonAriaLabel: {
    id: "ae61b865-4258-4b06-840a-7f90136cc7d5",
    defaultMessage: "Back to email input",
  },
  invalidError: {
    id: "b6d89ecc-1515-4188-8750-0c280fe309a0",
    defaultMessage: "Invalid password",
  },
  forgotPasswordLabel: {
    id: "9bad4d37-c021-40e8-8d57-e432bc9eb458",
    defaultMessage: "Forgot password?",
  },
  passwordCompromised: {
    id: "35b4e2a7-4b46-4b50-8c45-c2da2e22d76e",
    defaultMessage:
      "The password you are trying to use is either commonly used or has been identified in a data breach on another platform. Please choose a stronger password.",
  },
  emailDomainRestricted: {
    id: "1a2189b0-19d6-4dff-b2ef-4eabae866bd5",
    defaultMessage: "This email cannot be used due to domain restrictions",
  },
  passwordStrengthErrorLabel: {
    id: "cc089813-544e-4e8c-b9dc-966500d58c3e",
    defaultMessage: "Password not strong enough",
  },
  invalidCode: {
    id: "91312a33-c028-4503-ba30-d82bd0ff2ccc",
    defaultMessage: "Invalid verification code",
  },
});

export type CreatePasswordScreenType = {
  type: "create_password";
  email: string;
  password?: string;
  verificationCode?: string;
};

export type MfaData = {
  auth_options: MfaAuthOption[];
};

type Props = {
  password?: string;
  email: string;
  withEmailReminder?: boolean;
  onNextScreen(
    screen:
      | MfaScreenType
      | EmailScreenType
      | ForgotPasswordScreenType
      | PasswordScreenType
      | VerifyEmailCodeScreenType,
  ): void;
  onPasswordLogin: (
    values: FormValues & { email: string; needsAccount?: boolean },
  ) => Promise<MfaData | undefined>;
  onBack?: () => void;
  showCard?: boolean;
  wrapper?: (children: ReactNode) => ReactNode;
  onAuthenticationFailed?: OnAuthenticationFailed;
  verificationCode?: string;
};

function CreatePasswordForm({
  onPasswordLogin,
  password,
  email,
  onNextScreen,
  onAuthenticationFailed,
  verificationCode,
}: Props) {
  const intl = useIntl();
  const { useDocumentEntitler } = useA11y();
  const [loginLocked, setLoginLocked] = useState(false);
  const form = useForm<FormValues & { email: string }>({
    mode: "all",
    defaultValues: { password: password || "", email },
  });
  const { handleSubmit, register, formState, setFocus, setError } = form;
  const { errors, isSubmitted } = formState;
  const errorId = useA11y().useRegisteredId("password");
  const authentication = useSelector((state) => state.authentication);
  const [searchParams] = useSearchParams();
  useDocumentEntitler({
    priority: "page",
    title: intl.formatMessage(useDocumentTitles().setPassword),
  });

  const submitHandler = async (values: FormValues) => {
    if (loginLocked) {
      return;
    }
    setLoginLocked(true);
    try {
      // if there is a verificationCode that means the user already has an account
      if (verificationCode) {
        await resetPasswordWithVerificationCode({
          email,
          password: values.password,
          verificationCode,
        });

        return onNextScreen({ type: "password", email, password: values.password });
      }
      const params = {
        platform: "web",
        email,
        password: values.password,
        type: "customer",
        peer_referral_gid: searchParams.get("referral_id"),
      };

      await request("post", "accounts", params);

      const mfaData = await onPasswordLogin({
        email,
        password: values.password,
        needsAccount: !verificationCode,
      });

      if (mfaData) {
        onNextScreen({
          type: "mfa",
          authOptions: mfaData.auth_options,
          email,
          password: values.password,
        });
      }
    } catch (error) {
      // We expect a success to redirect us. We don't need or want to unlock.
      setLoginLocked(false);
      setFocus("password");
      onAuthenticationFailed?.({
        withEmail: !!email,
        withPassword: !!password,
        withGoogle: false,
        error,
      });

      const err = error as { type: unknown; body?: { error: unknown } };

      if (err.body?.error === COMPROMISED_PASSWORD) {
        return setError("password", {
          type: passwordCompromised().type,
          message: intl.formatMessage(MESSAGES.passwordCompromised),
        });
      } else if (err.body?.error === EMAIL_DOMAIN_RESTRICTED) {
        return setError("password", {
          type: emailDomainRestrictedError().type,
          message: intl.formatMessage(MESSAGES.emailDomainRestricted),
        });
      } else if (verificationCode && err.type === "ERRORS/SERVER/404") {
        // this assumption is that if the user has a verification code and the error
        // is 404 that the user made the request with an invalid verification code
        return onNextScreen({
          type: "verify_email_code",
          email,
          isInvalidCode: true,
        });
      }
      setError("password", {
        type: authentication.error.type,
        message: intl.formatMessage(MESSAGES.invalidError),
      });

      return captureException(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      noValidate
      data-automation-id="authentication-form"
    >
      <PasswordRequirements className={Styles.passwordRequirements} />
      {!verificationCode && (
        <StyledTextInput
          disabled
          aria-invalid="false"
          defaultValue={email}
          label={intl.formatMessage(MESSAGES.emailLabel)}
          aria-label={intl.formatMessage(MESSAGES.emailLabel)}
          {...register("email", { required: defaultRequiredMessage(intl) })}
        />
      )}
      <HookFormPassword
        setFocus={setFocus}
        label={intl.formatMessage(MESSAGES.passwordLabel)}
        aria-label={intl.formatMessage(MESSAGES.passwordAriaLabel)}
        registerProps={register("password", {
          required: defaultRequiredMessage(intl),
          validate: {
            isPasswordStrong: (password) => {
              return (
                isPasswordStrong(password) ||
                intl.formatMessage(MESSAGES.passwordStrengthErrorLabel)
              );
            },
          },
        })}
        invalid={isSubmitted && Boolean(errors.password)}
        error={isSubmitted ? errors.password : undefined}
        aria-describedby={authentication.error.error ? errorId : undefined}
        wrapperClass={Styles.spacingTopSmall}
        autoComplete="new-password"
      />
      <Continue disabled={loginLocked} />
      <TosMessage size="small" />
    </form>
  );
}

function CreatePasswordScreenCard({
  onPasswordLogin,
  password,
  withEmailReminder,
  email,
  onNextScreen,
  onAuthenticationFailed,
  verificationCode,
  showCard,
}: Props) {
  const intl = useIntl();

  return (
    <ProofCard
      header={
        <div>
          <Heading level="h1" textStyle="subtitle" textAlign="left" className={Styles.mainHeading}>
            <FormattedMessage
              id="02560a61-63ba-4703-9eea-34bdb25eb8a0"
              defaultMessage="Set your password"
            />
          </Heading>
          <AccountBlurb />
        </div>
      }
      body={
        <CreatePasswordForm
          {...{
            onPasswordLogin,
            password,
            email,
            onNextScreen,
            onAuthenticationFailed,
            verificationCode,
            showCard,
          }}
          withEmailReminder={verificationCode ? false : withEmailReminder}
        />
      }
      footer={
        <div className={classNames(Styles.footerCentered)}>
          <BackButton
            onClick={() => onNextScreen({ type: "email", email })}
            aria-label={intl.formatMessage(MESSAGES.backButtonAriaLabel)}
          />
        </div>
      }
    />
  );
}

export default function CreatePasswordScreen(props: Props) {
  const { showCard, wrapper } = props;
  const content = <CreatePasswordForm {...props} />;
  if (showCard) {
    return <CreatePasswordScreenCard {...props} />;
  }
  return <>{wrapper ? wrapper(content) : content}</>;
}
