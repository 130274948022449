import { defineMessages, useIntl } from "react-intl";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";

import { downloadAs } from "util/http";
import Icon from "common/core/icon";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import Env from "config/environment";

import Styles from "./index.module.scss";

import DropStyles from "common/core/asset_generator/upload/drop.module.scss";

const MESSAGES = defineMessages({
  uploadLabel: {
    id: "e6ef153e-b77b-4315-86e3-193376b9f7a9",
    defaultMessage: "Drag and drop a .csv export from Metabase here.",
  },
  uploadInstructions: {
    id: "b04086c2-1e42-4ac0-9cf0-b3d6b96356e8",
    defaultMessage: "(make sure your .csv file contains a user_id column)",
  },
});

const ERROR_MESSAGES = defineMessages<string>({
  invalid_file: {
    id: "610f430d-d7ca-442d-8133-e19342fb36ec",
    defaultMessage: "The file is not a .csv file and cannot be uploaded.",
  },
  forbidden: {
    id: "0b560038-d846-454b-8d0a-74c8ac539644",
    defaultMessage: "You do not have the permission to use this tool.",
  },
  unexpected_error: {
    id: "6f3410f2-d2f8-407a-bacc-94b1b31de332",
    defaultMessage: "An unexpected error occurred.",
  },
});

function sendFile(file: File, setError: (error: string) => void) {
  const formData = new FormData();
  formData.append("file", file);
  return fetch(`${Env.apiHost}/report_exports/metabase_report`, {
    method: "POST",
    body: formData,
    credentials: "include",
  }).then((response) => {
    if (response.status === 400) {
      return response.json().then((data) => setError(data.error));
    } else if (response.status === 403) {
      return setError("forbidden");
    } else if (response.status !== 200) {
      return setError("unexpected_error");
    }
    const filename =
      response.headers.get("Content-Disposition")?.split("filename=")[1] || "data.csv";
    return response.blob().then((blob) => {
      return downloadAs(blob, filename);
    });
  });
}

function ExportMetabaseReports() {
  const intl = useIntl();
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const handleUnsetDragging = useCallback(() => setIsDragging(false), []);
  const handleSetDragging = useCallback(() => setIsDragging(true), []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/csv": [".csv"],
      "text/csv": [".csv"],
    },
    multiple: false,
    onDragLeave: handleUnsetDragging,
    onDragEnter: handleSetDragging,
    onDragOver: handleSetDragging,
    onDropRejected: useCallback(() => {
      setError("invalid_file");
      setIsDragging(false);
    }, []),
    onDropAccepted: useCallback(([file]: File[]) => {
      setIsDragging(false);
      setIsLoading(true);
      sendFile(file, setError).finally(() => setIsLoading(false));
    }, []),
  });

  return (
    <div
      {...getRootProps({
        className: classnames(DropStyles.main, isDragging && DropStyles.dragging),
      })}
    >
      <input {...getInputProps({ "aria-label": intl.formatMessage(MESSAGES.uploadLabel) })} />
      {isLoading ? (
        <div className={Styles.loadingIndicatorContainer}>
          <SmallLoadingIndicator className={Styles.loadingIndicator} />
        </div>
      ) : (
        <div className={DropStyles.upload} aria-hidden="true">
          <Icon name="arrow-up-square" />
        </div>
      )}
      {error ? (
        <p className={DropStyles.error}>
          {error in ERROR_MESSAGES ? intl.formatMessage(ERROR_MESSAGES[error]) : error}
        </p>
      ) : (
        <>
          <p>{intl.formatMessage(MESSAGES.uploadLabel)}</p>
          <p className={DropStyles.subInstructions}>
            {intl.formatMessage(MESSAGES.uploadInstructions)}
          </p>
        </>
      )}
    </div>
  );
}

export default ExportMetabaseReports;
