import { useCallback, useState, useEffect, type ReactNode, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import {
  reduxForm,
  SubmissionError,
  submit as reduxFormSubmit,
  isInvalid,
  isSubmitting,
  getFormSubmitErrors,
  type InjectedFormProps,
  initialize,
} from "redux-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

import type { AuthTypes } from "graphql_globals";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { validatePresence } from "validators/form";
import type { FormError } from "errors/util";
import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import { LongFormattedDateTime } from "common/core/format/date";
import { useSelector } from "redux/util";
import { FullLogo } from "common/core/logo";
import AlertMessage from "common/core/alert_message";

import AuthenticateForSubjectMutation from "./authenticate_for_subject_mutation.graphql";
import Styles from "./index.module.scss";

type Buttons = NonNullable<ComponentProps<typeof WorkflowModal>["buttons"]>;
type AuthGateModalProps = {
  children: ReactNode;
  title: ReactNode;
  actionButton: Buttons[number];
};
type FormValues = {
  secret: string;
};
type FormErrors = {
  secret: GError | null;
};
type GError = Error & { graphQLErrors: null | { code: number; lockedUntil: string }[] };
export type AuthGateAction<Actions> = {
  type: Actions;
  buttonProps: {
    onClick: () => void;
    disabled: boolean;
    isLoading: boolean;
  };
};

const FORM_NAME = "authGateForm";
const FIELD_NAME = "secret";

function getError(error: GError) {
  const graphQLError = error.graphQLErrors?.[0];
  switch (graphQLError?.code) {
    case 401:
      return { type: "invalid" as const };
    case 403:
      return {
        type: "attempts" as const,
        message: (
          <FormattedMessage
            id="2e0b284e-1b05-41ec-9f2c-be0dc9d937ec"
            defaultMessage="Exceeded attempts (can try again at {lockedUntil})"
            values={{
              lockedUntil: (
                // Safari can only parse date format DD/MM/YYYY
                <LongFormattedDateTime
                  value={new Date(graphQLError.lockedUntil.replace(/-/g, "/"))}
                />
              ),
            }}
          />
        ),
      };
    default:
      captureException(error);
      return {
        type: "unexpected" as const,
        message: (
          <FormattedMessage
            id="2fa3618b-9231-423a-ba18-f81d19925841"
            defaultMessage="Unexpected error"
          />
        ),
      };
  }
}

function useAuthGateForm() {
  const [error, setError] = useState<ReturnType<typeof getError> | null>(null);
  const submitting = useSelector(isSubmitting(FORM_NAME));
  const invalid = useSelector(isInvalid(FORM_NAME));
  const submitErrors = useSelector(getFormSubmitErrors(FORM_NAME)) as FormErrors;
  const dispatch = useDispatch();
  const submit = useCallback(() => dispatch(reduxFormSubmit(FORM_NAME)), []);
  const populateTestCode = () => {
    const initialValues = {
      secret: "000000",
    };

    dispatch(initialize(FORM_NAME, initialValues));
  };

  useEffect(() => {
    // don't clear error, just set new error
    if (submitErrors.secret) {
      setError(getError(submitErrors.secret));
    }
  }, [submitErrors]);

  return { submitting, invalid, error, submit, populateTestCode };
}

const renderCustomOverlay: ComponentProps<typeof WorkflowModal>["renderCustomOverlay"] = ({
  children,
}) => {
  return (
    <div className={classnames(Styles.overlay, Styles.backgroundGraphic)}>
      <div className={Styles.overlayTop}>
        <FullLogo whiteLogo className={Styles.overlayLogo} />
      </div>
      {children}
    </div>
  );
};

function AuthGateModal(props: AuthGateModalProps) {
  const { children, title, actionButton } = props;
  const navigate = useNavigate();
  return (
    <WorkflowModal
      title={title}
      closeBehavior={{ tag: "without-button", onClose: close, disableClickOutside: true }}
      buttons={[
        <Button key="cancel" onClick={() => navigate(-1)} variant="tertiary" buttonColor="action">
          <FormattedMessage id="7c8e56bf-dbf5-4e4e-98d6-5d90aded7745" defaultMessage="Cancel" />
        </Button>,
        actionButton,
      ]}
      footerSeparator={false}
      renderCustomOverlay={renderCustomOverlay}
    >
      {children}
    </WorkflowModal>
  );
}

type AuthGateFormProps = {
  renderField: (fieldProps: {
    automationId: string;
    name: string;
    disabled: boolean;
    autoFocus: true;
  }) => ReactNode;
};
type FormProps = InjectedFormProps<FormValues, AuthGateFormProps, FormError>;
function AuthGateForm({ renderField, submitting, handleSubmit }: AuthGateFormProps & FormProps) {
  return (
    <form id={FORM_NAME} onSubmit={handleSubmit}>
      <FormGroup fields={[FIELD_NAME]} disableFormRowStyle>
        {renderField({
          automationId: "auth-input",
          name: FIELD_NAME,
          disabled: submitting,
          autoFocus: true,
        })}
      </FormGroup>
    </form>
  );
}

const AuthGateReduxForm = reduxForm<FormValues, AuthGateFormProps, FormError>({
  form: FORM_NAME,
  validate: validatePresence({ field: FIELD_NAME, label: "Value" }),
})(AuthGateForm);

function AuthGateWithSubmit({
  subjectId,
  authType,
  onSuccess,
  password,
  ...authGateFormProps
}: AuthGateFormProps & {
  subjectId: string;
  authType: AuthTypes;
  onSuccess?: () => void;
  password?: string;
}) {
  const authenticateForSubjectMutateFn = useMutation(AuthenticateForSubjectMutation);

  function submit(values: { secret: string }) {
    return authenticateForSubjectMutateFn({
      variables: {
        input: {
          subjectId,
          secret: values.secret,
          password: password || null,
          authType,
        },
      },
    })
      .then(() => onSuccess?.())
      .catch((error: FormError) => {
        throw new SubmissionError<FormValues, FormError>({
          secret: error,
        });
      });
  }

  return <AuthGateReduxForm {...authGateFormProps} onSubmit={submit} />;
}

type AlertBannerProps = ComponentProps<typeof AlertMessage>;
type AuthGateAlertBannerProps = {
  kind: AlertBannerProps["kind"];
  children: AlertBannerProps["children"];
};
function AuthGateAlertBanner({ kind, children }: AuthGateAlertBannerProps) {
  return (
    <AlertMessage className={Styles.alertBanner} kind={kind} centerText>
      {children}
    </AlertMessage>
  );
}

export {
  AuthGateModal,
  AuthGateWithSubmit as AuthGateForm,
  AuthGateAlertBanner,
  useAuthGateForm,
  renderCustomOverlay,
};
