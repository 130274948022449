import classNames from "classnames";

import Icon from "common/core/icon";

import Styles from "./green_check_icon.module.scss";

type Props = {
  className?: string;
};

function GreenCheckIcon({ className }: Props) {
  return <Icon name="success" className={classNames(Styles.greenCheckIcon, className)} />;
}

export default GreenCheckIcon;
