import { isFuture } from "date-fns";

import { useFeatureFlag } from "common/feature_gating";
import {
  IA_NAV,
  MOVE_TOOLS,
  PROOF_TRANSACTIONS,
  USER_DASHBOARD_RESKIN,
  SIGN_TRANSACTIONS,
  CLONE_TRANSACTION,
  SETTINGS_PROFILE_MVP,
  EDIT_TRANSACTION,
  MOVE_TOOLS_REAL,
} from "constants/feature_gates";
import { usePermissions } from "common/core/current_user_role";
import { isNotaryNST } from "common/notary/capacity";
import { includesOrgAdminRole } from "util/organization";
import { useViewer } from "util/viewer_wrapper";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
// eslint-disable-next-line import/no-restricted-paths
import type { LenderRouterViewer_viewer as LenderViewer } from "lender_portal/router/viewer_query.graphql";
// eslint-disable-next-line import/no-restricted-paths
import type { TitleRouterViewer_viewer as TitleViewer } from "title_portal/router/viewer_query.graphql";
// eslint-disable-next-line import/no-restricted-paths
import type { BusinessRouterViewer_viewer as BusinessViewer } from "business_portal/router/viewer_query.graphql";
import type { TransactionDetailsOrganization } from "common/details/identity";
import { Tier } from "graphql_globals";
import { CURRENT_EXPERIMENTS, useExperimentGroup } from "common/experiments/group";

export function useProofDefendUpsell(
  organization?: Pick<TransactionDetailsOrganization, "proofDefend"> | null,
): boolean {
  const { viewer } = useViewer();
  const userRoles = viewer.user?.organizationMembership?.roles;
  return (
    useFeatureFlag("proof-defend-upsell") &&
    Boolean(organization && !organization.proofDefend) &&
    includesOrgAdminRole(userRoles ?? [])
  );
}

export function useProofDefend(
  organization?: Pick<TransactionDetailsOrganization, "proofDefend"> | null,
): boolean {
  return Boolean(organization?.proofDefend);
}

export function isRiskScoresTrialActive(defendRiskScoresTrial: string): boolean {
  return isFuture(defendRiskScoresTrial);
}

// Remove in REAL-8187
export function useIAnav(): boolean {
  return useFeatureFlag(IA_NAV);
}

export function useSignTransactionsEnabled(): boolean {
  return useFeatureFlag(SIGN_TRANSACTIONS);
}

export function useCloneTransaction(): boolean {
  const { hasPermissionFor } = usePermissions();

  return useFeatureFlag(CLONE_TRANSACTION) && hasPermissionFor("createOrganizationTransactions");
}

export function useProofRequirementsEnabled(
  notaryProfile: Parameters<typeof isNotaryNST>[0],
): boolean {
  return useFeatureFlag(PROOF_TRANSACTIONS) && !isNotaryNST(notaryProfile);
}

export function useUserDashboardReskin(): boolean {
  return useFeatureFlag(USER_DASHBOARD_RESKIN);
}

export function useOdnRemoteWitnessCredViewer(): boolean {
  return useFeatureFlag("new-credential-viewer-for-odn-witness-enabled");
}

export function useOnboardingV2(): boolean {
  return useFeatureFlag("onboarding-v2");
}

export function useSettingProfiles(): boolean {
  return useFeatureFlag(SETTINGS_PROFILE_MVP);
}

export function useDeepfakeAnalysisFeature(): boolean {
  const flagEnabled = useFeatureFlag("deepfake-analysis");
  const { hasPermissionFor } = usePermissions();

  return flagEnabled || hasPermissionFor("viewDeepfakeAnalysis");
}

export function useDeepfakeAnalysisPermission(): boolean {
  const { viewer } = useViewer<TitleViewer | LenderViewer | BusinessViewer>();
  const { hasPermissionFor } = usePermissions();

  return (
    Boolean(viewer.user?.organization?.proofDefend) || hasPermissionFor("viewDeepfakeAnalysis")
  );
}

export function usePeerReferrals(): boolean {
  return useFeatureFlag("peer-referrals");
}

export function useReferralsTitle(): boolean {
  // Only need to check the Title flag, LD has a pre-req set on transactions-referrals, which controls referrals as a whole
  return useFeatureFlag("referrals-title") && CURRENT_PORTAL === Apps.TITLE_AGENCY;
}

export function useProMobileOnboarding(): boolean {
  return useFeatureFlag("pro-mobile-onboarding");
}

export function useMoveTools() {
  const moveToolsBusiness = useFeatureFlag(MOVE_TOOLS);
  const moveToolsReal = useFeatureFlag(MOVE_TOOLS_REAL);
  if (CURRENT_PORTAL === Apps.BUSINESS) {
    // move-tools for business needs to remain until we have Citi updated to the new nav
    return moveToolsBusiness;
  }
  return moveToolsReal;
}

export function useEditTransaction() {
  const { hasPermissionFor } = usePermissions();
  const editTransaction = useFeatureFlag(EDIT_TRANSACTION);

  if (
    (CURRENT_PORTAL === Apps.BUSINESS && hasPermissionFor("editOrganizationTransactions")) ||
    CURRENT_PORTAL === Apps.CUSTOMER
  ) {
    return editTransaction;
  }
  return false;
}

export function useShowIdentityInfo(organization?: TransactionDetailsOrganization | null): boolean {
  return Boolean(organization?.showIdentityInformation);
}

const NEW_ORG_LANDING_EXPT_TIERS = [
  Tier.BUSINESS_PRO_IHN,
  Tier.BUSINESS_PRO_ODN,
  Tier.TITLE_PRO_IHN,
  Tier.TITLE_PRO_ODN,
];
export function useNewOrgLandingExpt(tier?: Tier) {
  const experiment = CURRENT_EXPERIMENTS.NewOrgLanding;
  const newOrgLandingExpt = useExperimentGroup({
    flag: experiment.flag,
    skip: !tier || !NEW_ORG_LANDING_EXPT_TIERS.includes(tier),
    possibleGroups: [experiment.A],
    precedence: "ld",
  });

  return newOrgLandingExpt;
}

export function isFeatureEnabled(
  organization: { featureFlags: { key: string; value: string }[] },
  flag: string,
): boolean {
  return organization.featureFlags.find((f) => f.key === flag)?.value === "true";
}
