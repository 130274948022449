import "common/form/deprecated_form.scss";

import { useState, type ReactNode } from "react";

import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import SaveButton from "common/core/save_button";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedMultiSelectField } from "common/form/fields/multi_select";
import { DeprecatedFirstNameField, DeprecatedLastNameField } from "common/form/fields/name_fields";
import { DeprecatedEmailField } from "common/form/fields/email";
import EmailTypoWarningMessage from "common/core/warnings/email_typo";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { getFormFields } from "util/form";

type Props = {
  title: ReactNode;
  updatedTitle: ReactNode;
  handleSubmit: () => void;
  formValues: { email: string };
  formErrors: { email?: boolean };
  assignableRoles: { id: string; displayName: string }[];
};

function MultiRoleSelect({ assignableRoles }: Pick<Props, "assignableRoles">) {
  const [currentRole, setCurrentRole] = useState<string[]>([]);
  const roleOptions = assignableRoles.map((role) => ({
    label: role.displayName,
    value: role.id,
  }));

  function handleMultiSelect(val: { value: string }[]) {
    const newArray = val.map((item) => item.value);
    setCurrentRole(newArray);
  }

  return (
    <DeprecatedMultiSelectField
      id="roles"
      name="roles"
      items={roleOptions}
      onChange={handleMultiSelect}
      value={currentRole}
      automationId="admin-roles-field"
    />
  );
}

function NewAdminForm({
  handleSubmit,
  title,
  updatedTitle,
  formErrors,
  formValues,
  assignableRoles,
}: Props) {
  return (
    <form className="Form" onSubmit={handleSubmit}>
      <FormGroup fields={["firstName", "lastName"]}>
        <label htmlFor="firstName">Name</label>

        <DeprecatedMultipartRow>
          <DeprecatedFirstNameField
            id="firstName"
            name="firstName"
            component="input"
            data-automation-id="first-name-field"
          />

          <DeprecatedLastNameField
            id="lastName"
            name="lastName"
            component="input"
            data-automation-id="last-name-field"
          />
        </DeprecatedMultipartRow>
        <FormGroupErrors fields={["firstName", "lastName"]} />
      </FormGroup>

      <FormGroup fields={["email"]}>
        <label htmlFor="email">Email</label>

        <DeprecatedEmailField id="email" name="email" />

        <FormGroupErrors fields={["email"]} />

        <EmailTypoWarningMessage email={formValues.email} disabled={Boolean(formErrors.email)} />
      </FormGroup>
      <FormGroup fields={["roles"]}>
        <label htmlFor="roles">Roles</label>
        <MultiRoleSelect assignableRoles={assignableRoles} />
        <FormGroupErrors fields={["roles"]} />
      </FormGroup>

      <SaveButton title={title} updatedTitle={updatedTitle} />
    </form>
  );
}

// Retrieve field data from store
// XXX WARNING, do not use this without a reduxForm called "newAdmin"
export default getFormFields("newAdmin")(NewAdminForm);
