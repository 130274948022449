import { defineMessages, useIntl } from "react-intl";

import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";

import Styles from "./business_name.module.scss";

type Props = {
  labelText?: "businessName" | "notaryBusinessName";
  disabled?: boolean;
};

const messages = defineMessages({
  businessName: {
    id: "8fa48b99-463e-4076-81d9-ee441afae33c",
    defaultMessage: "Business name",
  },
  notaryBusinessName: {
    id: "1d16a845-2d18-4917-a91d-b31e1125f572",
    defaultMessage: "Notary business name (optional)",
  },
});

function SignupBusinessNameField(props: Props) {
  const { disabled, labelText = "businessName" } = props;
  const intl = useIntl();

  return (
    <FormGroup fields={["businessName"]} className={Styles.inputWrapper}>
      <DeprecatedTextField
        id="businessName"
        name="businessName"
        data-automation-id="business-name-field"
        label={intl.formatMessage(messages[labelText])}
        disabled={disabled}
        useNativeLabel
        useStyledInput
      />
      <FormGroupErrors fields={["businessName"]} />
    </FormGroup>
  );
}

export default SignupBusinessNameField;
