import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { useMutation } from "util/graphql";
import { Card, CardHeading } from "common/core/card";
import { usePermissions } from "common/core/current_user_role";
import { SettingsTitle } from "common/settingsv2/common";
import { TransactionAccessLevels } from "graphql_globals";
import Button from "common/core/button";
import { DeprecatedSelectInput } from "common/form/inputs/select";
import { pushNotification } from "common/core/notification_center/actions";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import UpdateOrganizationMutation from "common/settingsv2/sidebar_settings/brand/settings/logo_and_header/logo/update_organization.mutation.graphql";
import { NOTIFICATION_TYPES } from "constants/notifications";

import Styles from "./index.module.scss";

const TransactionAccessLevelsTypeList = Object.values(TransactionAccessLevels).map((value) => ({
  label: value,
  value,
}));

const MESSAGES = defineMessages({
  saveSuccess: {
    id: "81793c19-77af-49ab-894f-f005f7ace4f1",
    defaultMessage: "You have successfully updated your transaction access level",
  },
});

type StatusState =
  | { status: null; message: null }
  | { status: "loading"; message: null }
  | { status: "error"; message: string };

export function TransactionAccessLevel(props: {
  organization: { id: string; transactionAccessLevel: TransactionAccessLevels };
  refetch: () => Promise<unknown>;
}) {
  const { id, transactionAccessLevel } = props.organization;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const canUpdateCompany = hasPermissionFor("editCompanyDetails");

  const [status, setStatus] = useState<StatusState>({ status: null, message: null });

  const [selectedTransactionAccessLevel, setSelectedTransactionAccessLevel] =
    useState(transactionAccessLevel);

  const updateOrganizationMutateFn = useMutation(UpdateOrganizationMutation);

  async function updateTransactionAccessLevel() {
    setStatus({ status: "loading", message: null });
    try {
      await updateOrganizationMutateFn({
        variables: {
          input: {
            id,
            transactionAccessLevel: selectedTransactionAccessLevel,
          },
        },
      });
      await props.refetch();
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.saveSuccess),
      });
      setStatus({ status: null, message: null });
    } catch (e) {
      setStatus({ status: "error", message: (e as Error).message });
    }
  }

  return (
    <>
      <SettingsTitle>
        <FormattedMessage
          id="41b0124a-6115-4495-abc1-bc7f1f1066a8"
          defaultMessage="Transaction Access Level"
        />
      </SettingsTitle>
      <Card>
        {canUpdateCompany && (
          <>
            <CardHeading level="h3">
              <FormattedMessage
                id="c5023d49-a67f-4695-8475-cd63116b0e83"
                defaultMessage="Transaction Access Level"
              />
            </CardHeading>
            <DeprecatedSelectInput
              items={TransactionAccessLevelsTypeList}
              onChange={(selection: TransactionAccessLevels) =>
                setSelectedTransactionAccessLevel(selection)
              }
              value={selectedTransactionAccessLevel}
              autosize={false}
              disabled={status.status === "loading"}
              automationId="organization-transaction-access-level"
            />
            {transactionAccessLevel !== selectedTransactionAccessLevel && (
              <Button
                className={Styles.button}
                buttonColor="action"
                variant="primary"
                onClick={() => updateTransactionAccessLevel()}
                isLoading={status.status === "loading"}
                automationId="update-transaction-access-level"
              >
                Save
              </Button>
            )}
          </>
        )}
      </Card>
      {status.status === "error" && (
        <MutationErrorModal
          message={status.message}
          onClick={() => setStatus({ status: null, message: null })}
        />
      )}
    </>
  );
}
