import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Button from "common/core/button";
import Icon from "common/core/icon";
import { useStableS3Url } from "util/url";
import type { RetakeManager } from "common/identity_verification/retake/notary";

import { PhotoType, type IdentityDocumentViewerEngine } from "./engine";
import Styles from "./index.module.scss";
import { getIndividualRetakeButtonTextFromType } from ".";

export type ActionProps = {
  retakeManager?: RetakeManager;
  engine: IdentityDocumentViewerEngine;
  isSimplifiedViewer?: boolean;
  retakeForSelfieOnly?: boolean;
};

type RetakeButtonProps = {
  retakeManager: RetakeManager;
  currentPhotoType: IdentityDocumentViewerEngine["currentPhotoType"];
};

const MESSAGES = defineMessages({
  nextDocument: {
    id: "ae2ab2a2-ea56-46cf-968c-e7e2489eb0e1",
    defaultMessage: "Next document",
  },
  prevDocument: {
    id: "85dfc724-d88a-45cf-a146-6e8856baf60a",
    defaultMessage: "Previous document",
  },
});

function RetakeButton(props: RetakeButtonProps) {
  const { retakeManager, currentPhotoType } = props;
  return (
    <Button
      buttonColor="action"
      variant="secondary"
      onClick={() => retakeManager.initiate({ individualRetake: currentPhotoType })}
      disabled={retakeManager.inProgress}
    >
      {getIndividualRetakeButtonTextFromType(currentPhotoType)}
    </Button>
  );
}

export default function Actions(props: ActionProps) {
  const { retakeManager, engine, isSimplifiedViewer, retakeForSelfieOnly } = props;
  const intl = useIntl();
  const {
    currentPhoto,
    currentPhotoIndex,
    currentPhotoType,
    totalPhotoCount,
    isFront,
    onChangeDocumentSide,
    onPreviousDocument,
    onNextDocument,
  } = engine;
  const frontPicture = currentPhoto?.frontPicture || null;
  const backPicture = currentPhoto?.backPicture || null;
  const miniSquee = useStableS3Url(isFront ? backPicture : frontPicture);

  return (
    <div className={Styles.actions}>
      <div className={Styles.alternativeSideId}>
        {backPicture && (
          <Button buttonColor="dark" variant="tertiary" onClick={onChangeDocumentSide}>
            <img key={miniSquee} src={miniSquee!} alt="" />
            <FormattedMessage
              id="7b83bb72-6ad5-4378-963e-489bd7f7dcee"
              defaultMessage="View {isFront, select, true{back} other{front}}"
              values={{ isFront }}
            />
          </Button>
        )}
      </div>
      <div className={Styles.switchDocuments}>
        <Button
          variant="tertiary"
          buttonColor="action"
          onClick={onPreviousDocument}
          disabled={retakeManager?.inProgress || !onPreviousDocument}
          aria-label={intl.formatMessage(MESSAGES.prevDocument)}
        >
          <Icon name="caret-left" />
        </Button>
        <FormattedMessage
          id="1f74bead-6564-4f35-b537-144dfe2d3711"
          defaultMessage="{currentPhotoIndex} of {totalPhotoCount}"
          values={{ currentPhotoIndex: currentPhotoIndex + 1, totalPhotoCount }}
        />
        <Button
          variant="tertiary"
          buttonColor="action"
          onClick={onNextDocument}
          disabled={retakeManager?.inProgress || !onNextDocument}
          aria-label={intl.formatMessage(MESSAGES.nextDocument)}
        >
          <Icon name="caret-right" />
        </Button>
      </div>
      <div>
        {!isSimplifiedViewer &&
          retakeManager &&
          (!retakeForSelfieOnly || currentPhotoType === PhotoType.Selfie) && (
            <RetakeButton retakeManager={retakeManager} currentPhotoType={currentPhotoType} />
          )}
      </div>
    </div>
  );
}
