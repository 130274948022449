import { FormattedMessage } from "react-intl";

import type { UseFormReturn } from "common/core/form";
import type { OrganizationApiKeyPayer } from "graphql_globals";

import type { PayerOrganization } from "../payer_organization.graphql";
import { OrganizationPayer, type OrganizationPayerField } from "../common";

export type VerifyFormValues = {
  id: string;
  active: boolean;
  name: string;
  payer: OrganizationApiKeyPayer | undefined;
};

export function VerifyPayer<FormFields extends OrganizationPayerField>(props: {
  organization: PayerOrganization;
  form: UseFormReturn<FormFields>;
}) {
  return (
    <OrganizationPayer
      {...props}
      cardDescription={
        <FormattedMessage
          id="3cf4ca90-5984-4a4e-8c60-4ed4a9673d29"
          defaultMessage="Verify EasyLinks only support payment via your organization."
        />
      }
    />
  );
}

export const DEFAULT_VERIFY_CONFIGURATION = {
  caConfig: { available: true, requirement: "none" },
  ccConfig: { available: true, requirement: "none" },
  selfieConfig: { available: true, requirement: "none" },
};
