import { useState, type ComponentProps } from "react";
import classnames from "classnames";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { dateComparator } from "util/date";
import { b } from "util/html";
import Icon from "common/core/icon";
import { DOC_ORDERING_TYPE } from "constants/document";
import { SUPPORT_HOST } from "constants/support";
import Link from "common/core/link";
import TemplateSplittingResults from "common/transactions/template_splitting_results";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import { usePermissions } from "common/core/current_user_role";
import { SearchField } from "common/core/search_field";
import { OrganizationTransactionDetailedStatus, CompletionStatuses } from "graphql_globals";
import { isIdentify } from "util/transaction";
import Button from "common/core/button";

import { DocumentBundleActions } from "../bundle_actions";
import { asArchive, downloadAllOriginalAssets } from "../save_all";
import SupplementaryMergedDocuments from "../supplementary_merged_documents";
import SupplementaryDocuments from "../supplementary";
import DocumentList from ".";
import type {
  DocumentBundleForTransactionDetailsListContainer,
  DocumentBundleForTransactionDetailsListContainer_documents_edges_node as Document,
} from "./list_container_fragment.graphql";

const MESSAGES = defineMessages({
  searchLabel: {
    id: "9e269396-f9eb-4c66-bc15-9ebe0d3905d5",
    defaultMessage: "Search for document by name",
  },
  searchPlaceholder: {
    id: "81c19a78-e7b8-4e2b-bce3-9675665b4892",
    defaultMessage: "Filter by name",
  },
});

type Props = {
  bundle: DocumentBundleForTransactionDetailsListContainer;
  onDocumentSelected: ComponentProps<typeof DocumentList>["onClick"];
  onSupplementaryDocSelected: (docInfo: { name: string; url: string }) => void;
  lastOpenedId: string | null;
  viewable?: boolean;
  ttlExpired?: boolean;
  showNotarialActs?: boolean;
  showHeader?: boolean;
  showLegalInfoItem?: boolean;
  canViewSplittingResults?: boolean;
  showMergedDocs?: boolean;
  showResponsiveView?: boolean;
  shareable?: boolean;
  downloadable?: boolean;
};

// Sorting logic:
// 1. unsigned documents that need notarization
// 2. finalized documents sorted by updatedAt
// 3. unsigned eSign documents
// 4. wetSign documents
// 5. rejected documents
function getTimestampForDocument(document: Document): string {
  if (document.completionStatus === CompletionStatuses.REJECTED) {
    return "5000-01-01T00:00:00+0000";
  } else if (!document.finalized && document.notarizationRequired) {
    return "1000-01-01T00:00:00+0000";
  } else if (document.finalized) {
    return document.updatedAt;
  } else if (!document.notarizationRequired && !document.signAhead) {
    return "4000-01-01T00:00:00+0000";
  }
  return "3000-01-01T00:00:00+0000";
}

function byTimestamp(a: Document, b: Document): number {
  return dateComparator(getTimestampForDocument(a), getTimestampForDocument(b));
}

function byBundlePosition(a: Document, b: Document): number {
  return a.bundlePosition! - b.bundlePosition!;
}

function useDocumentsForViewing(edges: { node: Document }[]) {
  const [docOrder, setDocOrder] = useState<ObjectValues<typeof DOC_ORDERING_TYPE>>(
    DOC_ORDERING_TYPE.MEETING,
  );
  const [docNameFilter, setDocNameFilter] = useState("");

  const sortFn = docOrder === DOC_ORDERING_TYPE.POSITION ? byBundlePosition : byTimestamp;
  const sortedDocuments = edges.map((e) => e.node).sort(sortFn);

  const searchTerm = docNameFilter.trim().toLowerCase();
  const viewReadyDocuments = searchTerm
    ? sortedDocuments.filter((doc) => doc.name?.toLowerCase().includes(searchTerm))
    : sortedDocuments;

  return { viewReadyDocuments, docNameFilter, setDocNameFilter, docOrder, setDocOrder };
}

function needsPaymentStatus(status: OrganizationTransactionDetailedStatus) {
  return [
    OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
    OrganizationTransactionDetailedStatus.PAYMENT_REQUIRED,
  ].includes(status);
}

export default function DocumentsListContainer(props: Props) {
  const {
    bundle,
    viewable,
    ttlExpired,
    showMergedDocs,
    showHeader,
    onSupplementaryDocSelected,
    showNotarialActs,
    canViewSplittingResults,
    showResponsiveView,
    shareable,
    downloadable,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermissionFor } = usePermissions();
  const intl = useIntl();
  const [showTemplateSplittingResults, setShowTemplateSplittingResults] = useState(false);
  const {
    requiresNsaMeeting,
    publicOrganization,
    detailedStatus,
    templateSplittingResults,
    verifyLink,
  } = bundle.organizationTransaction;
  const { viewReadyDocuments, docNameFilter, docOrder, setDocOrder, setDocNameFilter } =
    useDocumentsForViewing(bundle.documents.edges);
  const brandName = publicOrganization.organizationBrand.name;

  const needsPayment = needsPaymentStatus(detailedStatus);
  const releasable = needsPayment ? !!bundle.releasable : true;
  const isIdentifyTransaction = isIdentify(bundle.organizationTransaction);
  const documentsViewable = viewable && !ttlExpired && releasable;
  const finalDocumentExists = Boolean(bundle.documents.edges[0]?.node.finalAsset?.filename);
  const isSignerPortal = AppSubdomains[CURRENT_PORTAL] === "app";

  const docOrderMenu = (
    <div className="documents-list-header-order">
      <div className="documents-list-header-order-filter">
        <SearchField
          onChange={(target) => setDocNameFilter(target.value)}
          value={docNameFilter}
          className="header-filter-search"
          placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
          aria-label={intl.formatMessage(MESSAGES.searchLabel)}
          searchOnBlur
          searchOnClear
        />
        <PopoutMenu
          className="header-filter"
          placement="bottomRight"
          target={
            <Button
              variant="tertiary"
              buttonColor="action"
              buttonSize="condensed"
              withIcon={{ name: "caret-down", placement: "right" }}
            >
              <FormattedMessage
                id="8ef4358d-200a-4f1b-b1ab-d1c5e767c4ab"
                defaultMessage="Order by <b>{docOrder}</b>"
                values={{ docOrder, b }}
              />
            </Button>
          }
        >
          {({ close }) => (
            <>
              <PopoutMenuItem
                onClick={() => {
                  close();
                  setDocOrder(DOC_ORDERING_TYPE.MEETING);
                }}
              >
                <FormattedMessage
                  id="a8820448-daf9-41d1-8928-6fbaae3f533e"
                  defaultMessage="By Meeting"
                />
              </PopoutMenuItem>
              <PopoutMenuItem
                onClick={() => {
                  close();
                  setDocOrder(DOC_ORDERING_TYPE.POSITION);
                }}
              >
                <FormattedMessage
                  id="1be0e682-0097-43b7-ad53-5696900579ac"
                  defaultMessage="By Document Order"
                />
              </PopoutMenuItem>
            </>
          )}
        </PopoutMenu>
      </div>
      {(finalDocumentExists || hasPermissionFor("documentActions")) && !ttlExpired && (
        <DocumentBundleActions
          isMultiDocument
          shareAction={
            shareable ? () => navigate(`bundleShare/${bundle.id}${location.search}`) : undefined
          }
          // if we are listing unfinalized docs, there are no final assets to download
          downloadAction={bundle.releasable && downloadable ? () => asArchive(bundle) : undefined}
          uploadedAssetDownloadAction={
            hasPermissionFor("assetDownloadAction")
              ? () => downloadAllOriginalAssets(bundle)
              : undefined
          }
          showDownloadWarning={bundle.downloadWarningEnabled}
        />
      )}
    </div>
  );

  return (
    <div
      data-automation-id="documents-list-container"
      className={classnames(
        "documents-list-container",
        showNotarialActs && "show-notarial-acts",
        // This component is also used in the signer portal where the modal is still being used,
        // need to add padding around it in this scenario
        isSignerPortal && "p-400",
      )}
    >
      {canViewSplittingResults && Boolean(templateSplittingResults.length) && (
        <div className="documents-list-container--SplittingResults">
          <Button
            variant="tertiary"
            buttonColor="action"
            buttonSize="condensed"
            onClick={() => {
              setShowTemplateSplittingResults(true);
            }}
          >
            <FormattedMessage
              id="9e4b15e4-354f-4825-b6db-5d5aee92ccf4"
              defaultMessage="Show document splitting results"
            />
          </Button>
        </div>
      )}
      {showTemplateSplittingResults && (
        <TemplateSplittingResults
          onClose={() => {
            setShowTemplateSplittingResults(false);
          }}
          templateSplittingResults={templateSplittingResults}
        />
      )}
      {!isIdentifyTransaction && (
        <DocumentList
          title={bundle.organizationTransaction.name || bundle.name || "Documents"}
          reviewSessions={bundle.reviewSessions!}
          documents={viewReadyDocuments}
          onClick={props.onDocumentSelected}
          orderHeaderSpan={docOrderMenu}
          documentsViewable={documentsViewable}
          showNotarialActs={showNotarialActs && requiresNsaMeeting}
          requiresNsaMeeting={requiresNsaMeeting}
          expired={
            bundle.organizationTransaction.detailedStatus ===
            OrganizationTransactionDetailedStatus.EXPIRED
          }
          organizationName={brandName}
          lastOpenedId={props.lastOpenedId}
          showResponsiveView={showResponsiveView}
        />
      )}
      {hasPermissionFor("downloadTransactionDocuments") && (
        <>
          {showMergedDocs && (
            <div className="documents-list-secondary">
              <SupplementaryMergedDocuments
                bundle={bundle}
                documentsViewable={viewable}
                onSupplementaryDocSelected={onSupplementaryDocSelected}
                showHeader={showHeader}
                showResponsiveView={showResponsiveView}
              />
            </div>
          )}
          <div className="documents-list-tertiary">
            <SupplementaryDocuments
              bundle={bundle}
              documentsViewable={viewable}
              onSupplementaryDocSelected={onSupplementaryDocSelected}
              showHeader={showHeader}
              showLegalInfoItem={props.showLegalInfoItem}
              showResponsiveView={showResponsiveView}
              hasVerifyLink={Boolean(verifyLink)}
            />
          </div>
          {!verifyLink && (
            <div className="documents-list-info">
              <Link
                className="learn-more"
                href={`${SUPPORT_HOST}/hc/en-us/articles/360058800493-Verify-a-Notarized-Document`}
                underlined={false}
              >
                <Icon className="documents-list-info__icon" name="question" />
                <span className="link-text">
                  <FormattedMessage
                    id="3396ecc4-5b8e-48b1-80f3-0428608aa4b4"
                    defaultMessage="Learn more about verifying documents"
                  />
                </span>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}
