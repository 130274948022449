import { memo, useState, type ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";

import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { Feature } from "graphql_globals";
import { LongFormattedDateTime } from "common/core/format/date";
import Tooltip from "common/core/tooltip";
import { userFullName } from "util/user";
import { useQuery } from "util/graphql";
import { useRawMutation } from "util/graphql/mutation";
import { encodeSearchParams } from "util/location";
import { b } from "util/html";
import { TextInput } from "common/core/form/text";
import Modal from "common/modal";
import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";

import MeetingRequestDetailsQuery, {
  type MeetingRequestDetails_meetingRequest as Node,
  type MeetingRequestDetails_meetingRequest_MeetingRequest as MeetingRequest,
  type MeetingRequestDetails_meetingRequest_MeetingRequest_routingResults as RoutingResult,
} from "./details_modal_query.graphql";
import RecomputeRoutingCache from "./recompute_routing_cache.graphql";
import Styles from "./details_modal.module.scss";

type TestResultProps = {
  results: RoutingResult[];
  notaryUserId: string;
};
type RoutingProps = {
  results: TestResultProps["results"];
};
type Props = {
  id: string;
  onClose: () => void;
};

const NA_TEXT = <FormattedMessage id="d33de310-4fe3-4aad-bb97-1c39646c3b5d" defaultMessage="N/A" />;

const MESSAGES = defineMessages({
  testIdPlaceholder: {
    id: "7ebec92e-e6b1-4564-a846-24b0c07a0d0c",
    defaultMessage: "Paste a user id of a notary",
  },
});

function routingCacheInconsistent(routingResults: RoutingResult[]): boolean {
  const routingCacheFilter = routingResults.find(
    (res) => res.filterName === "RoutingCacheValidationFilter",
  )!;
  const rightBeforeCacheSeq = Number(routingCacheFilter.sequence) - 1;
  const beforeCacheFilter = routingResults.find(
    (res) => Number(res.sequence) === rightBeforeCacheSeq,
  )!;
  return (
    [...routingCacheFilter.userIds].sort().join("") !==
    [...beforeCacheFilter.userIds].sort().join("")
  );
}

function isMeetingReq(node: null | Node): node is MeetingRequest {
  return node?.__typename === "MeetingRequest";
}

function encodeNotaryLink({ email }: { email: string | null }): string {
  const search = email ? encodeSearchParams(new URLSearchParams({ email })) : "";
  return `/notary?${search}`;
}

function NotaryTestResult({ notaryUserId, results }: TestResultProps) {
  const reversedResults = results.slice().reverse();
  const lastResultWithUserIdIndex = reversedResults.findIndex((result) =>
    result.userIds.includes(notaryUserId),
  );
  return lastResultWithUserIdIndex === -1 ? (
    <FormattedMessage
      id="8d15eb4f-7853-4ec8-aa98-1a1430f8b9e9"
      defaultMessage="No, we can't find this user ID. Are you sure this is a notary user ID?"
    />
  ) : lastResultWithUserIdIndex !== 0 ? (
    <FormattedMessage
      id="00492fcc-38a9-4ad5-a3ce-6cbb5565524b"
      defaultMessage="No, they didn't pass rule {sequence} - {name}"
      values={{
        sequence: reversedResults[lastResultWithUserIdIndex - 1].sequence,
        name: reversedResults[lastResultWithUserIdIndex - 1].filterName,
      }}
    />
  ) : (
    <FormattedMessage id="7c86ad54-6cb2-427d-8d2f-7e5e977c8273" defaultMessage="Yes!" />
  );
}

function RoutingResultsListing({ results }: RoutingProps) {
  const [openResults, setOpenResults] = useState<Set<RoutingResult["sequence"]>>(new Set());
  const handleRowClick = (sequence: RoutingResult["sequence"]) => {
    setOpenResults((oldSeqs) => {
      const newSeqs = new Set(oldSeqs);
      if (newSeqs.has(sequence)) {
        newSeqs.delete(sequence);
      } else {
        newSeqs.add(sequence);
      }
      return newSeqs;
    });
  };
  return (
    <ul>
      {results.map(({ sequence, description, userIds, filterName }) => {
        const parsableCount = userIds.length >= 1 && userIds.length <= 20;
        return (
          <li
            key={sequence}
            className={parsableCount ? Styles.expandableNotaries : undefined}
            onClick={() => parsableCount && handleRowClick(sequence)}
          >
            <span>{sequence}</span>
            <span>
              <FormattedMessage
                id="fe416866-95a5-439e-b952-108b402001be"
                defaultMessage="{count, plural, one{<b>1</b> Notary} other{<b>#</b> Notaries}} can handle this call after <nameTag>{name}</nameTag>."
                values={{
                  count: userIds.length,
                  name: filterName,
                  b,
                  nameTag: (msg: ReactNode[]) =>
                    description ? (
                      <Tooltip target={<strong>{msg}</strong>}>
                        <div className={Styles.filterDescription}>{description}</div>
                      </Tooltip>
                    ) : (
                      <strong>{msg}</strong>
                    ),
                }}
                tagName="span"
              />
              {openResults.has(sequence) && (
                <ul>
                  {userIds.map((userId) => (
                    <li key={userId}>
                      <Link to={`/users/${userId}`}>{userId}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

function MeetingRequestDetailsModal({ id, onClose }: Props) {
  const intl = useIntl();
  const [notaryTestId, setNotaryTestId] = useState("");
  const { loading, data } = useQuery(MeetingRequestDetailsQuery, { variables: { id } });
  const [recomputeRoutingCache, recomputeRoutingCacheStatus] =
    useRawMutation(RecomputeRoutingCache);

  if (loading || !data) {
    return <LoadingIndicator />;
  }
  const request = data.meetingRequest;
  if (!isMeetingReq(request)) {
    throw new Error(`Unexpected node type ${request?.__typename}`);
  }
  const { meeting, documentBundle, routingResults } = request;
  const { organizationTransaction } = documentBundle;

  const refreshCacheDisabled =
    meeting !== null || request.cancelled || !routingCacheInconsistent(routingResults);

  const handleSync = () => {
    return recomputeRoutingCache({
      variables: {
        input: {
          meetingRequestId: request.id,
        },
      },
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_SUBTYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: "Routing Cache Successfully Synced",
        });
      })
      .catch(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: "Routing Cache Sync Failed",
        });
      });
  };

  return (
    <Modal
      closeRoute={onClose}
      title={
        <FormattedMessage
          id="0a26cf7d-81f9-45d8-9f9d-3b8b5a1a8fc0"
          defaultMessage="Meeting Request Details"
        />
      }
    >
      <div className={Styles.detailsModalContent}>
        <ul>
          <li>
            <FormattedMessage
              id="4509321f-c6f6-41e6-a58a-fe548ba2e843"
              defaultMessage="Requested By"
              tagName="span"
            />
            {request.requestedBy}
          </li>

          <li>
            <FormattedMessage
              id="ab45e91d-dfec-4a8d-89be-06c210c58413"
              defaultMessage="Queue Enter Time"
              tagName="span"
            />
            <LongFormattedDateTime value={request.createdAt} />
          </li>

          <li>
            <FormattedMessage
              id="2d6c226d-aa8b-43ad-b65e-e5d1144f6c71"
              defaultMessage="Bundle"
              tagName="span"
            />
            {documentBundle.id}
          </li>

          <li>
            <FormattedMessage
              id="12cada42-be4d-437e-a790-68f05426b912"
              defaultMessage="Queue ID"
              tagName="span"
            />
            <FormattedMessage
              id="6ff25a2c-c133-4ceb-9d65-5d0240ed8120"
              defaultMessage="{queue, select, DEFAULT {Retail} BUSINESS {Business} LENDER {Lender} TITLE {Title} other {Other}}"
              values={{ queue: request.queue }}
            />
          </li>

          <li>
            <FormattedMessage
              id="31f68376-a0bf-4429-b041-edc7a4743c04"
              defaultMessage="Signer Cancelled?"
              tagName="span"
            />
            <FormattedMessage
              id="c7307556-ea8b-466c-938c-478d38580fda"
              defaultMessage="{cancelled, select, true{Yes} other{No}}"
              values={{ cancelled: Boolean(request.cancelled) }}
            />
          </li>

          <li>
            <FormattedMessage
              id="da446311-897d-425c-a223-06182985cd90"
              defaultMessage="Got a meeting?"
              tagName="span"
            />
            <FormattedMessage
              id="0c11657a-fd38-448f-bff8-14a890427fee"
              defaultMessage="{hasMeeting, select, true{Yes ({meetingId})} other{No}}"
              values={{ hasMeeting: Boolean(meeting), meetingId: meeting?.id }}
            />
          </li>

          {!organizationTransaction.isRetail && (
            <>
              <li>
                <FormattedMessage
                  id="b0123017-80b9-46ed-b721-1a6cd9671e0c"
                  defaultMessage="Transaction"
                  tagName="span"
                />
                <Link
                  to={`/companies/${organizationTransaction.organization.id}/transactions/${organizationTransaction.id}/summary`}
                >
                  {organizationTransaction.id}
                </Link>
              </li>

              <li>
                <FormattedMessage
                  id="df00c13c-553c-4b33-9690-a8ff68a21d58"
                  defaultMessage="Organization"
                  tagName="span"
                />
                <Link to={`/companies/${organizationTransaction.organization.id}/transactions`}>
                  {organizationTransaction.organization.name}
                </Link>
              </li>

              {organizationTransaction.organization.featureList.includes(
                Feature.ORGANIZATION_NOTARIES,
              ) && (
                <>
                  <li>
                    <FormattedMessage
                      id="8c193c2e-5f0d-4e4c-af7c-290857cf6db8"
                      defaultMessage="Assigned Closer"
                      tagName="span"
                    />
                    {request.closer ? (
                      <Link to={encodeNotaryLink(request.closer)}>
                        {userFullName(request.closer)}
                      </Link>
                    ) : (
                      NA_TEXT
                    )}
                  </li>

                  <li>
                    <FormattedMessage
                      id="41c281d8-5222-4773-baa9-f854a1ae379b"
                      defaultMessage="Organization Overflow Time"
                      tagName="span"
                    />

                    {organizationTransaction.organization.featureList.includes(
                      Feature.ORG_NOTARY_OVERFLOW,
                    ) ? (
                      <FormattedMessage
                        id="c67efb10-1b77-438d-bc4f-6ff93e675514"
                        defaultMessage="{waitMinutes, plural, one{1 Minute} other{# Minutes}}"
                        values={{
                          waitMinutes: organizationTransaction.organization.notaryWaitTimeInMinutes,
                        }}
                      />
                    ) : (
                      NA_TEXT
                    )}
                  </li>

                  <li>
                    <FormattedMessage
                      id="e9451f49-5043-40be-97dc-cd8408df6c02"
                      defaultMessage="Organization Explicitly Chose Notarize to Close"
                      tagName="span"
                    />

                    {organizationTransaction.organization.featureList.includes(
                      Feature.ORG_NOTARY_OVERFLOW,
                    ) ? (
                      <FormattedMessage
                        id="d418182a-7cf7-456c-b782-b17a987a1f59"
                        defaultMessage="{notarizeIsCloser, select, true{yes} other{No}}"
                        values={{ notarizeIsCloser: Boolean(request.notarizeCloserOverride) }}
                      />
                    ) : (
                      NA_TEXT
                    )}
                  </li>
                </>
              )}
            </>
          )}

          <li className={Styles.routingResults}>
            <FormattedMessage
              id="5b17d045-40b1-4ecc-8f2c-319cf1af7993"
              defaultMessage="Routing Results"
              tagName="h4"
            />
            <div className={Styles.tester}>
              <label htmlFor="notary-test-id">
                <FormattedMessage
                  id="65258096-8f2e-4844-aab1-82f2b0473fc6"
                  defaultMessage="Can a notary handle this call?"
                />
              </label>
              <TextInput
                id="notary-test-id"
                placeholder={intl.formatMessage(MESSAGES.testIdPlaceholder)}
                value={notaryTestId}
                aria-invalid="false"
                onChange={(evt) => setNotaryTestId(evt.target.value)}
              />
            </div>
            {notaryTestId && (
              <div className={Styles.testerAnswer}>
                <NotaryTestResult notaryUserId={notaryTestId} results={request.routingResults} />
              </div>
            )}
            <RoutingResultsListing results={request.routingResults} />
            <div className={Styles.syncButtonContainer}>
              <Tooltip
                placement="right"
                target={
                  <Button
                    isLoading={recomputeRoutingCacheStatus.loading}
                    buttonColor="action"
                    variant="primary"
                    disabled={refreshCacheDisabled}
                    onClick={handleSync}
                  >
                    <FormattedMessage
                      id="64d6235b-eac5-4524-8a3d-22af1930f709"
                      defaultMessage="Sync Routing Cache"
                    />
                  </Button>
                }
              >
                <FormattedMessage
                  id="dca50c48-4ce0-45ed-bdca-aa1fbe317414"
                  defaultMessage="Use this button if eligible notary is not seeing expected meeting requests (results of RoutingCacheValidationFilter are different from the previous filter)"
                />
              </Tooltip>
            </div>
          </li>
        </ul>
      </div>
    </Modal>
  );
}

export default memo(MeetingRequestDetailsModal);
