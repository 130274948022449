import type { ReactElement } from "react";
import { FormattedNumber } from "react-intl";

type Props = ({ cents: number } | { dollars: number }) & { hideFractionDigits?: boolean };

export default function FormattedPrice({
  hideFractionDigits = false,
  ...rest
}: Props): ReactElement {
  const value = "dollars" in rest ? rest.dollars : rest.cents / 100;
  return (
    <FormattedNumber
      value={value}
      style="currency"
      currency="USD"
      minimumFractionDigits={hideFractionDigits ? 0 : undefined}
      maximumFractionDigits={hideFractionDigits ? 0 : undefined}
    />
  );
}
