import "./index.scss";

import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedMultiSelectInput } from "common/form/inputs/multi_select";
import { CardHeading } from "common/core/card";

import {
  type Options,
  type MortgageClosingStatePreferenceHook,
  type ClosingState,
  optionsToValues,
} from "./util";

type IntraStateProps = {
  eligibleClosingStates: ClosingState[];
  propertyStateOptions: Options[];
  intraStatePreferenceChange: MortgageClosingStatePreferenceHook["intraStatePreferenceChange"];
};

export default function IntraStatePreferenceForm({
  eligibleClosingStates,
  propertyStateOptions,
  intraStatePreferenceChange,
}: IntraStateProps) {
  const intraStatePreferenceOptions = useMemo(() => {
    return eligibleClosingStates
      .filter((closingState) => closingState.intraState)
      .map((closingState) => {
        const { usState } = closingState;
        return { label: usState.name, value: closingState.id };
      });
  }, [eligibleClosingStates]);

  const handleChange = useCallback(
    (updatedStatePreferenceOptions: Options[]) => {
      const intraStatePreferenceIds = optionsToValues(intraStatePreferenceOptions);
      const updatedStatePreferenceIds = optionsToValues(updatedStatePreferenceOptions);
      intraStatePreferenceChange(intraStatePreferenceIds, updatedStatePreferenceIds);
    },
    [intraStatePreferenceOptions],
  );

  return (
    <>
      <CardHeading level="h3">
        <FormattedMessage
          id="47b1c88d-f3ff-4166-ace3-2a7bd80b7e5b"
          defaultMessage="Intrastate Requirements"
        />
      </CardHeading>
      <DeprecatedMultiSelectInput
        data-automation-id="Intra States"
        clearable={false}
        onChange={handleChange}
        items={propertyStateOptions}
        value={intraStatePreferenceOptions}
      />
    </>
  );
}
