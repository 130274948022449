import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";

import { SettingProfileEnum } from "graphql_globals";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { MeatballMenu } from "common/core/table/meatball_menu";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import { Badge } from "common/core/badge";
import Icon from "common/core/icon";
import { hardNavigateTo } from "util/navigation";
import { redirectUrl } from "util/application_redirect";
import { truncate } from "util/string";

import Styles from "./index.module.scss";
import type { OrgTableItem } from "./table";
import type { OrgTablePaginationItem } from "./table_with_pagination";
import type { CompanyOrgsPaginated_node_Organization_settingProfiles as AvailableProfile } from "./graphql/company_orgs_paginated.graphql";
import { EditProfileModal } from "./edit_profile/edit_profile_modal";
import Apps from "../../../../constants/applications";

const MESSAGES = defineMessages({
  brand: {
    id: "11b0f4b7-02e5-470e-9ae2-6c2b9a77665f",
    defaultMessage: "brand",
  },
  orgActions: {
    id: "82a7b8fd-e4db-42b9-b79d-a30d73b71058",
    defaultMessage: "Organization actions",
  },
});

function navigateToOrg(org: OrgTableItem) {
  const subdomain =
    AppSubdomains[org.organizationType.toLocaleLowerCase() as keyof typeof AppSubdomains];
  const url = redirectUrl(subdomain, `/set-active-org/${org.id}`);
  hardNavigateTo(url, { replace: true });
}

function getCompanyNameColumn(flattenTree: boolean) {
  return {
    Header: (
      <FormattedMessage
        id="3b048f12-a6ed-4545-9e31-773d375d77de"
        defaultMessage="Company name / ID"
      />
    ),
    render: (org: OrgTableItem) => (
      <div
        className={Styles.organizationItemTable}
        style={{ paddingLeft: flattenTree ? 0 : `${org.organizationWeight * 12}px` }}
      >
        {org.organizationWeight !== 0 && !flattenTree && (
          <Icon name="arrow-down-right" className={Styles.arrowDownRight} />
        )}
        <span>{org.name}</span>
        <span className={Styles.organizationItemInnerId}>
          (
          <LinkStyledButton
            onClick={() => {
              navigateToOrg(org);
            }}
          >
            {org.id}
          </LinkStyledButton>
          )
        </span>
        {org.organizationWeight === 0 && (
          <Badge kind="infoSubtle" className={Styles.organizationItemBadge}>
            <FormattedMessage
              id="3cc5e963-ea32-4077-970c-d2661cf3a5d7"
              defaultMessage="Root organization"
            />
          </Badge>
        )}
      </div>
    ),
  };
}

function getBrandColumn() {
  return {
    Header: <FormattedMessage id="89db429b-6c38-4105-918b-602b979e3fbe" defaultMessage="Brand" />,
    render: (org: OrgTablePaginationItem) => {
      const orgBrand = org.appliedSettingProfiles.find(
        // disable needed for now since there is only one SettingProfileEnum
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (profile) => profile.profileType === SettingProfileEnum.BRAND,
      );

      return orgBrand ? (
        <div className={Styles.brandColumnValues}>
          <span>{truncate(orgBrand.profileName, 25)}</span>
        </div>
      ) : (
        <div className={Styles.brandColumnValues}>--</div>
      );
    },
  };
}

function getMeatballMenu(settingProfilesFlag: boolean, availableProfiles: AvailableProfile[]) {
  function RenderdMeatballMenu({ org }: { org: OrgTableItem }) {
    const intl = useIntl();
    const isKeystone = CURRENT_PORTAL === Apps.ADMIN;
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const menuItems = [
      {
        onClick: () => {
          navigateToOrg(org);
        },
        content: (
          <FormattedMessage
            id="a0ee2a72-63c6-4844-a7fd-33b18092dced"
            defaultMessage="Go to Organization"
          />
        ),
        automationId: `goto-org-${org.id}`,
      },
    ];

    if (settingProfilesFlag && !isKeystone) {
      menuItems.push({
        onClick: () => {
          setShowEditModal(!showEditModal);
        },
        content: (
          <FormattedMessage id="e9344d4d-b855-4956-abf7-621faea3da3f" defaultMessage="Edit brand" />
        ),
        automationId: `edit-brand-profile-${org.id}`,
      });
    }
    return (
      <>
        <div style={{ width: "38px", paddingRight: 0 }}>
          <MeatballMenu
            menuTitle={intl.formatMessage(MESSAGES.orgActions)}
            automationId={`popout-menu-${org.id}`}
            menuItems={menuItems}
          />
        </div>
        {showEditModal && (
          <EditProfileModal
            profileType={intl.formatMessage(MESSAGES.brand)}
            setShowEditModal={setShowEditModal}
            items={[org as OrgTablePaginationItem]}
            availableProfiles={availableProfiles}
          />
        )}
      </>
    );
  }

  return {
    Header: <></>,
    render: (org: OrgTableItem) => <RenderdMeatballMenu org={org} />,
    preventClick: true,
    collapse: true,
  };
}

export function getTableColumns(
  flattenTree: boolean,
  settingProfilesFlag: boolean = false,
  availableProfiles: AvailableProfile[] = [],
) {
  const columns = [getCompanyNameColumn(flattenTree)];
  if (settingProfilesFlag) {
    // @ts-expect-error -- This happens because Table and Pagination table use different queries but
    // if we're applying the brand column, we're using the pagination table
    columns.push(getBrandColumn());
  }
  columns.push(getMeatballMenu(settingProfilesFlag, availableProfiles));
  return columns;
}
