import type { ReactNode } from "react";
import classnames from "classnames";
import { defineMessages, FormattedMessage, type IntlShape } from "react-intl";

import { IconButton } from "common/core/button/icon_button";
import PopoutMenu from "common/core/popout_menu";
import PopoutMenuItem from "common/core/popout_menu/item";
import {
  FraudStatusEnum,
  OrganizationTransactionColumn,
  OrganizationTransactionDetailedStatus,
  UserAction,
  type AddressType,
  type RiskLevels,
  type OrganizationTransactionLabels,
  type OrganizationTransactionStatus,
  type OrganizationTransactionVariant,
  type SortDirection,
} from "graphql_globals";
import { userFullName, userFullNameLastNameFirst } from "util/user";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { formattedTransactionVariant } from "common/core/format/formatted_transaction_variant";
import { formattedTransactionType } from "common/mortgage/transactions/utils";
import { format, FormattedDuration, FormattedDate } from "common/core/format/date";
import {
  OrganizationTransactionStatusColors,
  OrganizationTransactionDetailedStatusMessage,
} from "common/dashboard/constants";
import { ORDER_PROGRESS_LABEL_MAP } from "common/dashboard/filter_dropdown/common";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import SortableHeader from "util/sortable_header";
import { useFeatureFlag } from "common/feature_gating";
import { PS1583_ATTESTATION } from "constants/feature_gates";
import { recipientEmail, recipientFullName, type Email, type UserName } from "util/recipient_name";
import { useSignTransactionsEnabled } from "util/feature_detection";

import RiskLevel from "./risk_level";
import Styles from "./columns.module.scss";

const messages = defineMessages({
  nameNotProvided: {
    id: "184ab71a-58c9-44b2-b3e2-06770674899d",
    defaultMessage: "Name not provided",
  },
  emailNotProvided: {
    id: "2ca937e7-bc68-45cf-b648-c2cb520275cb",
    defaultMessage: "Email not provided",
  },
  transactionMenu: {
    id: "18dd44fe-2760-4b3c-b3eb-546661f63e30",
    defaultMessage: "Transaction menu",
  },
});

type Sort =
  | {
      column: string;
      direction: SortDirection;
    }
  | undefined;

export type Alert = {
  id: string;
  name: string;
  expiresAt: string;
};

function currentSortDirection(sortBy: Sort, column?: string) {
  const currentColumn = sortBy?.column;
  const currentDirection = sortBy?.direction;
  if (column === currentColumn) {
    return currentDirection;
  }
  return undefined;
}

export const LenderColumn = Object.freeze({
  Header: <FormattedMessage id="7d927992-2907-458f-b28f-8bd36638b30f" defaultMessage="Lender" />,
  render: ({ lenderName }: { lenderName: string | null }) => lenderName,
  collapse: true,
});

export const AddressColumn = Object.freeze({
  Header: (
    <FormattedMessage id="b529c5bb-2904-438a-9a80-f19428970566" defaultMessage="Property Address" />
  ),
  render: ({ address }: { address: AddressType | null }) =>
    address?.line1 && formattedPropertyAddress(address, { short: true }),
});

export const USStateColumn = Object.freeze({
  Header: <FormattedMessage id="acaf9754-7ae5-4958-8d5d-5c46d0436a64" defaultMessage="State" />,
  render: ({ address }: { address: AddressType | null }) => address?.state,
  collapse: true,
});

export const LoanNumberColumn = Object.freeze({
  Header: <FormattedMessage id="e003dd51-868f-4b3d-9492-81748bfb9a37" defaultMessage="Loan No." />,
  render: ({ loanNumber }: { loanNumber: string | null }) =>
    loanNumber && <span className={Styles.loanNumber}>{loanNumber}</span>,
  collapse: true,
});

export const FileNumberColumn = Object.freeze({
  Header: <FormattedMessage id="994eb8da-6de3-4eed-bbc1-2231518e5c21" defaultMessage="File No." />,
  render: ({ fileNumber }: { fileNumber: string | null }) =>
    fileNumber && <span className={Styles.fileNumber}>{fileNumber}</span>,
  collapse: true,
});

export const PrimaryBorrowerColumn = Object.freeze({
  Header: (
    <FormattedMessage id="9c028493-ac31-4d2f-bed0-81d022b92058" defaultMessage="Primary Borrower" />
  ),
  render: ({ customerSigners }: { customerSigners: UserName[] }) =>
    customerSigners.length ? (
      <span className={Styles.primaryBorrower}>
        {userFullNameLastNameFirst(customerSigners[0]!).toLowerCase()}
      </span>
    ) : null,
});

export const TitleAgencyColumn = Object.freeze({
  Header: (
    <FormattedMessage id="3f4e3274-6102-4d2f-9b7e-8b1799a6638b" defaultMessage="Title Agency" />
  ),
  render: ({ titleAgency }: { titleAgency: { name: string | null } | null }) => titleAgency?.name,
});

export const TitleUnderwriterColumn = Object.freeze({
  Header: (
    <FormattedMessage
      id="4a18023d-d8d0-4227-a59b-878f7683fa85"
      defaultMessage="Title Underwriter"
    />
  ),
  render: ({ titleUnderwriter }: { titleUnderwriter: { name: string | null } | null }) =>
    titleUnderwriter?.name,
});

export function ClosingDateColumn(
  sortBy?: Sort,
  onClick?: (sortColumn: OrganizationTransactionColumn, sortDirection?: SortDirection) => void,
) {
  const title = (
    <FormattedMessage id="e368aff0-0d92-4e92-94ab-028ab8871833" defaultMessage="Closing date" />
  );
  const column = OrganizationTransactionColumn.EXPIRY;
  const direction = currentSortDirection(sortBy, column);
  return Object.freeze({
    Header: onClick ? (
      <SortableHeader
        direction={direction}
        allowSearchByDefault
        onClick={(newDirection) => onClick(column, newDirection)}
      >
        {title}
      </SortableHeader>
    ) : (
      title
    ),
    render: ({
      expiry,
      expiryTimezone,
    }: {
      expiry: NotarizeScalarDateWithTimezone | null;
      expiryTimezone: string | null;
    }) => {
      if (!expiry) {
        return null;
      }

      const formattedExpiration = format({
        value: expiry,
        formatStyle: "LL/dd/yy",
        asTimeZone: expiryTimezone ? expiryTimezone : undefined,
      });

      return <span className={Styles.closingDate}>{formattedExpiration}</span>;
    },
    collapse: true,
  });
}

export const OrganizationNameColumn = Object.freeze({
  Header: (
    <FormattedMessage id="c023dea8-b518-4b28-8008-06e6e7aa7d85" defaultMessage="Organization" />
  ),
  render: ({
    organization,
  }: {
    organization: {
      id: string;
      name: string | null;
    };
  }) => {
    return `${organization.name} (${organization.id})`;
  },
  collapse: true,
});

export function DateCreatedColumn(
  sortBy?: Sort,
  onClick?: (sortColumn: OrganizationTransactionColumn, sortDirection?: SortDirection) => void,
) {
  const title = (
    <FormattedMessage id="40cf6565-2ca5-4879-b830-8bfab46a7dc4" defaultMessage="Date created" />
  );
  const column = OrganizationTransactionColumn.CREATED_AT;
  const direction = currentSortDirection(sortBy, column);
  return Object.freeze({
    Header: onClick ? (
      <SortableHeader
        direction={direction}
        onClick={(newDirection) => onClick(column, newDirection)}
      >
        {title}
      </SortableHeader>
    ) : (
      title
    ),
    render: ({ createdAt }: { createdAt: NotarizeScalarDate | null }) => {
      return (
        <span>
          <FormattedDate value={createdAt} />
        </span>
      );
    },
  });
}

export function DateCompletedColumn(
  sortBy?: Sort,
  onClick?: (sortColumn: OrganizationTransactionColumn, sortDirection?: SortDirection) => void,
) {
  const title = (
    <FormattedMessage id="9b9a8151-7c97-4fac-a82a-718ebb537e77" defaultMessage="Date completed" />
  );
  const column = OrganizationTransactionColumn.COMPLETED_AT;
  const direction = currentSortDirection(sortBy, column);
  return Object.freeze({
    Header: onClick ? (
      <SortableHeader
        direction={direction}
        onClick={(newDirection) => onClick(column, newDirection)}
      >
        {title}
      </SortableHeader>
    ) : (
      title
    ),
    render: ({ completedAt }: { completedAt: NotarizeScalarDate | null }) => {
      return (
        <span className="DateCompletedColumn">
          <FormattedDate value={completedAt} />
        </span>
      );
    },
  });
}

export const RecipientNameColumn = (intl: IntlShape) =>
  Object.freeze({
    Header: (
      <FormattedMessage id="924077f9-979e-4d0c-a50d-267be54643bf" defaultMessage="Recipient" />
    ),
    render: ({
      contacts,
      documentBundle,
    }: {
      contacts: UserName[];
      documentBundle: { participants: UserName[] | null; signers: UserName[] | null } | null;
    }) => {
      const customerName = recipientFullName(
        contacts[0],
        intl.formatMessage(messages.nameNotProvided),
        documentBundle,
      );

      return <span>{customerName}</span>;
    },
  });

export const RecipientEmailColumn = (intl: IntlShape) =>
  Object.freeze({
    Header: <FormattedMessage id="924077f9-979e-4d0c-a50d-267be54643bf" defaultMessage="Email" />,
    render: ({
      contacts,
      documentBundle,
    }: {
      contacts: Email[];
      documentBundle: { participants: Email[] | null; signers: Email[] | null } | null;
    }) => {
      const email = recipientEmail(
        contacts[0],
        intl.formatMessage(messages.emailNotProvided),
        documentBundle,
      );
      return <span>{email}</span>;
    },
  });

export const SenderColumn = Object.freeze({
  Header: <FormattedMessage id="f0594298-f011-479e-b628-ba5a17da36a9" defaultMessage="Sender" />,
  render: ({
    employee,
    organization,
  }: {
    employee: UserName;
    organization: { name: string | null };
  }) => {
    const customerName = userFullName(employee, organization.name || "");

    return <span>{customerName}</span>;
  },
});

export const BusinessTransactionNameColumn = Object.freeze({
  Header: (
    <FormattedMessage id="2d3315ec-07eb-4b2a-919c-7a2963b58d2f" defaultMessage="Transaction name" />
  ),
  render: ({ name }: { name: string | null }) => {
    return <span className={Styles.transactionName}>{name}</span>;
  },
  collapse: true,
});

export const BusinessTransactionTypeColumn = (intl: IntlShape) => {
  const ps1583AttestationEnabled = useFeatureFlag(PS1583_ATTESTATION);
  const signTransactionsEnabled = useSignTransactionsEnabled();

  return Object.freeze({
    Header: <FormattedMessage id="18c77561-8b5e-42d5-a3cd-c5c99fd275d6" defaultMessage="Type" />,
    render: ({ transactionVariant }: { transactionVariant: OrganizationTransactionVariant }) => {
      return (
        <span className={Styles.transactionType}>
          {formattedTransactionVariant(
            transactionVariant,
            intl,
            ps1583AttestationEnabled,
            signTransactionsEnabled,
          )}
        </span>
      );
    },
    collapse: true,
  });
};

export type KebabMenuItem<I> = {
  validateItemDisplay?: (item: I) => boolean;
  onClick: (item: I) => void;
  label: string;
}[];

export const KebabMenuColumn = <I,>(intl: IntlShape, items: KebabMenuItem<I>) => {
  return Object.freeze({
    Header: undefined,
    render: (item: I) => {
      return (
        <PopoutMenu
          target={
            <IconButton
              name="kebab-menu"
              variant="tertiary"
              buttonColor="dark"
              buttonSize="condensed"
              label={intl.formatMessage(messages.transactionMenu)}
            />
          }
          placement="leftTop"
          selfManageVerticalAlignment
        >
          {({ close }) =>
            items.map((menuItem, i) => {
              if (menuItem.validateItemDisplay && !menuItem.validateItemDisplay(item)) {
                return null;
              }

              return (
                <PopoutMenuItem
                  key={i}
                  onClick={() => {
                    menuItem.onClick(item);
                    close();
                  }}
                >
                  {menuItem.label}
                </PopoutMenuItem>
              );
            })
          }
        </PopoutMenu>
      );
    },
    preventClick: true,
  });
};

export function TransactionStatus({
  status,
  detailedStatus,
  statusOverride,
  hideCircle,
  "data-automation-id": dataAutomationId,
  className,
}: {
  status: OrganizationTransactionStatus;
  detailedStatus: OrganizationTransactionDetailedStatus;
  statusOverride?: ReactNode;
  hideCircle?: boolean;
  "data-automation-id"?: string;
  className?: string;
}) {
  return (
    <div
      className={classnames(Styles.transactionStatus, className)}
      data-automation-id={dataAutomationId}
    >
      {!hideCircle && (
        <div
          className={classnames(Styles.circle, Styles[OrganizationTransactionStatusColors[status]])}
        />
      )}
      {statusOverride || (
        <OrganizationTransactionDetailedStatusMessage detailedStatus={detailedStatus} />
      )}
    </div>
  );
}

export const TransactionTypeColumn = (intl: IntlShape, signTransactionsEnabled?: boolean) => {
  return Object.freeze({
    Header: <FormattedMessage id="8ef76d22-9ee5-43ef-ab05-1c4ce37dc94c" defaultMessage="Type" />,
    render: ({
      requiresNsaMeeting,
      transactionVariant,
      type,
    }: {
      requiresNsaMeeting: boolean | null;
      type: string | null;
      transactionVariant: OrganizationTransactionVariant;
    }) => (
      <span className={Styles.transactionType}>
        {formattedTransactionType({
          transactionVariant,
          type,
          requiresNsaMeeting,
          intl,
          signTransactionsEnabled,
        })}
      </span>
    ),
    collapse: true,
  });
};

export const RiskLevelColumn = Object.freeze({
  Header: <FormattedMessage id="705139b6-a4fa-435d-afc7-4c812b37f292" defaultMessage="Risk" />,
  render: ({ aggregateRiskLevel }: { aggregateRiskLevel: RiskLevels | null }) => (
    <RiskLevel level={aggregateRiskLevel} />
  ),
});

export const StatusColumn = Object.freeze({
  Header: <FormattedMessage id="f8699e1b-26cd-41ff-8a8e-2d40f189d828" defaultMessage="Status" />,
  render: ({
    status,
    detailedStatus,
    userAction,
  }: {
    status: OrganizationTransactionStatus;
    detailedStatus: OrganizationTransactionDetailedStatus;
    userAction: UserAction | null;
  }) => {
    const statusOverride =
      userAction !== UserAction.EDIT &&
      detailedStatus === OrganizationTransactionDetailedStatus.DRAFT ? (
        <FormattedMessage
          id="cab0b715-987f-4017-af16-b37c1213f307"
          defaultMessage="Draft (read-only)"
        />
      ) : null;

    return (
      <TransactionStatus
        status={status}
        data-automation-id="transaction-status"
        detailedStatus={detailedStatus}
        statusOverride={statusOverride}
      />
    );
  },
  collapse: true,
});

function OrderProgress({ orderProgress }: { orderProgress: OrganizationTransactionLabels }) {
  return ORDER_PROGRESS_LABEL_MAP[orderProgress];
}

export const OrderProgressColumn = Object.freeze({
  Header: (
    <FormattedMessage id="d0792761-72b4-4580-b682-f2c837fdf96c" defaultMessage="Order Progress" />
  ),
  render: ({ orderProgress }: { orderProgress: { label: OrganizationTransactionLabels } | null }) =>
    orderProgress && (
      <span className={Styles.orderProgress}>
        <OrderProgress orderProgress={orderProgress.label} />
      </span>
    ),
  collapse: true,
});

function OrderProgressLastUpdated(props: { createdAt: NotarizeScalarDate }) {
  return (
    <span>
      <FormattedDuration from={props.createdAt} />
    </span>
  );
}

export const TransactionIdColumn = Object.freeze({
  Header: (
    <FormattedMessage id="8d9307e9-2202-4a70-ac48-72606b6578bd" defaultMessage="Transaction ID" />
  ),
  render: ({ id }: { id: string }) => id,
});

export const OrderProgressLastUpdatedColumn = Object.freeze({
  Header: (
    <span>
      <FormattedMessage id="d2fef790-4fcc-426e-9864-74fd38238a9f" defaultMessage="Last updated" />
    </span>
  ),
  render: ({ orderProgress }: { orderProgress: { createdAt: NotarizeScalarDate } | null }) =>
    orderProgress && (
      <span>
        <OrderProgressLastUpdated createdAt={orderProgress.createdAt} />
      </span>
    ),
  collapse: true,
});

export const ClientColumn = (intl: IntlShape) =>
  Object.freeze({
    Header: <FormattedMessage id="9fb831a2-40e7-4490-b792-d9693f3c5fe6" defaultMessage="Client" />,
    render: ({
      contacts,
      documentBundle,
    }: {
      contacts: UserName[];
      documentBundle: {
        participants: UserName[] | null;
        signers: UserName[] | null;
      } | null;
    }) => {
      const customerName = recipientFullName(
        contacts[0],
        intl.formatMessage(messages.nameNotProvided),
        documentBundle,
      );

      return customerName;
    },
  });

export const FraudIssueColumn = Object.freeze({
  Header: null,
  render: ({
    customerSigners,
  }: {
    customerSigners: {
      id: string;
      fraudStatus: { reason: string | null; status: FraudStatusEnum | null } | null;
    }[];
  }) =>
    customerSigners.some((cs) => cs.fraudStatus?.status === FraudStatusEnum.FRAUD) ? (
      <Tooltip
        target={
          <Icon automationId="fraud-issue-warning" name="warning" className={Styles.warningIcon} />
        }
      >
        <FormattedMessage
          id="b3fbb7a5-f347-4993-a05a-bedda3eb512c"
          defaultMessage="Identity issue"
        />
      </Tooltip>
    ) : null,
  clearPadding: true,
  collapse: true,
});

export const IdentityIssueColumn = Object.freeze({
  Header: null,
  render: ({ identityIssues }: { identityIssues: Alert[] }) =>
    identityIssues.length > 0 ? (
      <Tooltip
        target={
          <Icon
            automationId="identity-issue-warning"
            name="warning"
            className={Styles.warningIcon}
          />
        }
      >
        <FormattedMessage
          id="13b221e2-9c6a-49b3-a103-6c6b98be5b1d"
          defaultMessage="Identity issue"
        />
      </Tooltip>
    ) : null,
  clearPadding: true,
  collapse: true,
});
