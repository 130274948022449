import { defineMessages, useIntl } from "react-intl";

import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";

import Styles from "./user_name.module.scss";

type Props = {
  disabled?: boolean;
  verticallyStacked?: boolean;
};

const messages = defineMessages({
  firstName: {
    id: "3237539f-5276-4a18-8378-ac2bf0688935",
    defaultMessage: "First name",
  },
  lastName: {
    id: "1d8b8ea8-0d9c-4476-97a0-f8852ebd7583",
    defaultMessage: "Last name",
  },
});

function SignupUserNameField(props: Props) {
  const { disabled } = props;

  const intl = useIntl();

  const content = (
    <>
      <FormGroup fields={["firstName"]} className={Styles.inputWrapper}>
        <DeprecatedTextField
          id="firstName"
          name="firstName"
          data-automation-id="first-name-field"
          label={intl.formatMessage(messages.firstName)}
          disabled={disabled}
          useNativeLabel
          useStyledInput
        />
        <FormGroupErrors fields={["firstName"]} />
      </FormGroup>
      <FormGroup fields={["lastName"]} className={Styles.inputWrapper}>
        <DeprecatedTextField
          id="lastName"
          name="lastName"
          data-automation-id="last-name-field"
          label={intl.formatMessage(messages.lastName)}
          disabled={disabled}
          useNativeLabel
          useStyledInput
        />
        <FormGroupErrors fields={["lastName"]} />
      </FormGroup>
    </>
  );

  return props.verticallyStacked ? (
    content
  ) : (
    <DeprecatedMultipartRow>
      <div className={Styles.nameInputContainer}>{content}</div>
    </DeprecatedMultipartRow>
  );
}

export default SignupUserNameField;
