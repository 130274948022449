import "./index.scss";

import { memo, forwardRef, type Ref, type ComponentPropsWithoutRef } from "react";
import classnames from "classnames";

type NoChildrenItalicProps = Omit<ComponentPropsWithoutRef<"i">, "children">;
type Props = NoChildrenItalicProps & {
  name: string;
  automationId?: string;
  noMargin?: boolean;
  size?: "small" | "large" | "extraLarge";
  tabIndex?: number;
  title?: string;
};

function Icon(props: Props, ref: Ref<HTMLElement>) {
  const { name, className, size, noMargin, automationId, ...otherProps } = props;
  const cx = classnames(`Icon icon-${name}`, className, {
    [`Icon__${size}`]: size,
    "Icon__no-margin": noMargin,
  });

  return (
    <>
      <i
        {...otherProps}
        className={cx}
        data-icon={name}
        data-automation-id={automationId}
        ref={ref}
      />
    </>
  );
}

export default memo(forwardRef(Icon));
