import { memo, useEffect, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { type InjectedFormProps, reduxForm } from "redux-form";

import { DeprecatedTextField } from "common/form/fields/text";
import Button from "common/core/button";
import { DeprecatedRadioButtonField } from "common/form/fields/radio";
import { useMutation } from "util/graphql";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { Card, CardHeading } from "common/core/card";
import type { FullAdminOrganizationDetails_organization_Organization as Organization } from "admin_portal/company/details_query.graphql";
import { SettingsTitle } from "common/settingsv2/common";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";

import UpdateLenderProfileMutation from "../update_lender_profile_mutation.graphql";

const MESSAGES = defineMessages({
  success: {
    id: "11edba9b-5523-4525-a097-4ad142093fb3",
    defaultMessage: "Successfully updated split title document settings",
  },
});

type Props = {
  organization: Organization;
};
type FormValues = {
  nmlsId: string | null;
  splitTitleDocuments: boolean;
};
type ReduxFormProps = InjectedFormProps<FormValues, Props>;
type InnerProps = Props & ReduxFormProps;

type StatusState =
  | { status: null }
  | { status: "loading" }
  | { status: "error"; message: null | string };

const LenderProfileForm = memo(
  ({ handleSubmit, initialize, organization: { id, nmlsId, splitTitleDocuments } }: InnerProps) => {
    const intl = useIntl();
    const [status, setStatus] = useState<StatusState>({ status: null });
    const updateLenderProfile = useMutation(UpdateLenderProfileMutation);
    const onSubmit = ({ nmlsId, splitTitleDocuments }: FormValues) => {
      setStatus({ status: "loading" });
      updateLenderProfile({
        variables: {
          input: {
            organizationId: id,
            splitTitleDocuments,
            nmlsId,
          },
        },
      })
        .then(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: intl.formatMessage(MESSAGES.success),
          });
          setStatus({ status: null });
        })
        .catch((error: Error) => {
          setStatus({ status: "error", message: error.message });
        });
    };
    useEffect(() => {
      initialize({ nmlsId, splitTitleDocuments });
    }, []);

    return (
      <>
        <SettingsTitle>
          <FormattedMessage
            id="ed89436d-0708-4833-8643-5363921e703e"
            defaultMessage="Lender Profile"
          />
        </SettingsTitle>
        <form
          name="LenderProfileForm"
          className="LenderProfileForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card
            footer={
              <Button
                buttonColor="action"
                variant="primary"
                type="submit"
                isLoading={status.status === "loading"}
              >
                <FormattedMessage id="5423e0b8-9b6c-4e56-a929-e7c7dbdebfb7" defaultMessage="Save" />
              </Button>
            }
          >
            <div className="LenderProfileFormRow">
              <CardHeading level="h3">
                <FormattedMessage
                  id="11e03efe-5f2c-4274-a8fe-ced6e7195e80"
                  defaultMessage="NMLS ID"
                />
              </CardHeading>
              <FormGroup disableFormRowStyle>
                <DeprecatedTextField name="nmlsId" useStyledInput placeholder="NMLS ID" />
              </FormGroup>
              <div className="LenderProfileFormRow">
                <CardHeading level="h3">
                  <FormattedMessage
                    id="5c9f0b94-160a-4998-81a2-750ea9c6a6f5"
                    defaultMessage="Split Title Documents"
                  />
                </CardHeading>
                <DeprecatedRadioButtonField
                  name="splitTitleDocuments"
                  labelText="No"
                  radioValue={false}
                  size="small"
                />
                <DeprecatedRadioButtonField
                  name="splitTitleDocuments"
                  labelText="Yes"
                  radioValue
                  size="small"
                />
              </div>
            </div>
          </Card>
        </form>
        {status.status === "error" && (
          <MutationErrorModal
            message={status.message}
            onClick={() => setStatus({ status: null })}
          />
        )}
      </>
    );
  },
);

const formEnhancer = reduxForm<FormValues, Props>({ form: "LenderProfileForm" });
export default formEnhancer(LenderProfileForm);
