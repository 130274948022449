import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import type { ActivityLog } from "../activity_log_fragment.graphql";
import Organization from "./common/organization";

export type TransactionRecalledActivityLogFragment = ActivityLog & {
  properties: {
    reason: string;
  };
};
type Props = {
  activityLog: TransactionRecalledActivityLogFragment;
};

export function TransactionRecalledLabel() {
  return CURRENT_PORTAL === Apps.BUSINESS ? (
    <FormattedMessage
      id="93519303-f964-4e19-af13-e658b0bddd45"
      defaultMessage="Transaction edited"
    />
  ) : (
    <FormattedMessage
      id="0cccdd96-48f8-4b77-9f2a-e381b436c84d"
      defaultMessage="Transaction recalled"
    />
  );
}

function TransactionRecalled({ activityLog }: Props) {
  return (
    <div className="ActivityLogItemDetailType">
      <Organization organizationName={activityLog.organizationName} />
      <div className="ActivityLogItemDetailType--list-item">
        <span className="ActivityLogItemDetailType--label">
          <FormattedMessage id="d1cef1d9-95b7-442d-b7ec-4e2a9d4126b5" defaultMessage="Reason" />:
        </span>
        <span className="ActivityLogItemDetailType--nonRangeValue">
          {activityLog.properties.reason}
        </span>
      </div>
    </div>
  );
}

export default memo(TransactionRecalled);
