import { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { EmailTextInput, TextInput } from "common/core/form/text";
import { defaultRequiredMessage, emailPatternValidation } from "common/core/form/error";
import Button from "common/core/button";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useMutation } from "util/graphql";
import { useForm } from "common/core/form";
import { AutomaticFormRow } from "common/core/form/layout";
import { usePermissions } from "common/core/current_user_role";

import Styles from "./index.module.scss";
import EditUserEmail from "./edit_user_email_mutation.graphql";

type Props = {
  userEmail: string;
  userId: string;
  refetch?: () => void;
};

type FormValues = {
  email: string;
};

const MESSAGES = defineMessages({
  email: { id: "4d22eff7-a710-4a51-9170-cdd6315cf78b", defaultMessage: "Email" },
  cancel: { id: "23aa911b-a68e-4491-8ebe-3330fdb0f5aa", defaultMessage: "Cancel" },
  save: { id: "137a111b-cd59-407a-ba7f-d0293f508f09", defaultMessage: "Save" },
});

export function LegalTeamEmailEdit({ userEmail, userId, refetch }: Props) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const hasLegalEditPermission = hasPermissionFor("privacyEmailEdit");
  const [mutationStatus, setMutationStatus] = useState<null | "error" | "loading">(null);
  const [showEditEmail, setShowEditEmail] = useState(false);

  const editUserEmail = useMutation(EditUserEmail);
  const form = useForm<FormValues>({
    defaultValues: { email: userEmail },
  });

  const save = ({ email }: FormValues) => {
    setMutationStatus("loading");
    return editUserEmail({
      variables: {
        input: {
          userId,
          emailAddress: email,
        },
      },
      refetchQueries: ["UserQuery", "NotaryDetails"],
    })
      .then(() => {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          message: "Email successfully updated",
        });
        setMutationStatus(null);
        setShowEditEmail(false);
        refetch?.();
      })
      .catch(() => {
        setMutationStatus("error");
      });
  };

  return (
    <>
      {showEditEmail && hasLegalEditPermission ? (
        <form onSubmit={form.handleSubmit(save)}>
          <div className={Styles.flex}>
            <AutomaticFormRow<FormValues>
              aria-label={intl.formatMessage(MESSAGES.email)}
              fullWidth
              form={form}
              name="email"
              registerOptions={{
                required: defaultRequiredMessage(intl),
                pattern: emailPatternValidation(intl),
              }}
              as={EmailTextInput}
            />
            <div className={Styles.buttonGroup}>
              <Button
                className={Styles.button}
                key="cancel"
                type="button"
                onClick={() => {
                  setShowEditEmail(false);
                  form.reset();
                }}
                automationId="email-address-edit-cancel-button"
                buttonColor="dark"
                variant="tertiary"
              >
                <FormattedMessage
                  id="56af321d-90fa-45e2-8748-457fdc184de7"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                aria-label={intl.formatMessage(MESSAGES.save)}
                className={Styles.button}
                key="save"
                type="submit"
                onClick={() => form.handleSubmit(save)}
                automationId="email-address-edit-save-button"
                buttonColor="action"
                variant="primary"
                isLoading={mutationStatus === "loading"}
              >
                <FormattedMessage id="a6d9bdca-8dbe-4160-baef-e0d059819d2a" defaultMessage="Save" />
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <div>
          <div className={Styles.flex}>
            <TextInput
              disabled
              aria-invalid={false}
              aria-label={intl.formatMessage(MESSAGES.email)}
              value={userEmail}
              readOnly
              data-automation-id="email-address-input"
            />
            <div className={Styles.buttonGroup}>
              <Button
                className={Styles.editButton}
                buttonColor="action"
                variant="tertiary"
                onClick={() => setShowEditEmail(true)}
                automationId="email-address-edit-button"
              >
                <FormattedMessage id="55a7f732-9c05-4a1d-82d0-a8b2a3442347" defaultMessage="Edit" />
              </Button>
            </div>
          </div>
        </div>
      )}

      {mutationStatus === "error" && <MutationErrorModal onClick={() => setMutationStatus(null)} />}
    </>
  );
}
