import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { CardText } from "common/core/card";

function Address({ address: { line1, line2, city, state, postal } }) {
  return (
    <CardText>
      {line1 ? (
        <span>{line1}</span>
      ) : (
        <span>
          <FormattedMessage
            id="5f8c5415-f3b4-4c21-add3-ab92f8b300b2"
            defaultMessage="No address on file"
          />
        </span>
      )}
      {line2 && <span>{line2}</span>}
      {city && (
        <span>
          {city}, {state}
        </span>
      )}

      {postal && <span>{postal}</span>}
    </CardText>
  );
}

Address.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postal: PropTypes.string,
  }).isRequired,
};

export default Address;
