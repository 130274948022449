import { memo, type ComponentProps } from "react";
import classnames from "classnames";
import { useIntl, defineMessages } from "react-intl";

import { clamp } from "util/number";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

type Props = {
  /** The total progress this icon could display (e.g. the 10 of 1/10). */
  total: number;
  /** The current progress this icon should display (e.g. the 1 of 1/10). */
  progress: number;
  /** If defined, this will be the icon displayed until complete */
  iconName?: ComponentProps<typeof Icon>["name"];
  className?: string;
};

const MESSAGES = defineMessages({
  label: {
    id: "76f6656d-80ae-40d7-a272-021362578dcc",
    defaultMessage: "{percentCompleted}% completed",
  },
});

/** This is a progress indicator that can be used in conjunction with an icon. */
function ProgressIcon({ total, progress, iconName, className }: Props) {
  // The fill percent should be calculated as progress/total, 0 <= progress <= 1
  const isHidden = total <= 0;
  const innerFillPercent = isHidden ? 0 : clamp(progress / total, 0, 1);

  const mainCx = classnames(Styles.progressIcon, className, {
    [Styles.hidden]: isHidden,
    [Styles.complete]: innerFillPercent >= 1,
  });

  const filledStyle = {
    // This comes from the fact that the CSS style for the circle border, `stroke-dasharray`, forms a
    // complete circle at value 251.
    strokeDashoffset: Math.round((1 - innerFillPercent) * 125),
    opacity: innerFillPercent > 0 ? 1 : 0,
  };

  const intl = useIntl();

  return (
    <div className={mainCx}>
      <svg
        role="img"
        aria-label={intl.formatMessage(MESSAGES.label, {
          percentCompleted: Math.round(innerFillPercent * 100),
        })}
      >
        <circle className={Styles.toBeFilled} cx={25} cy={25} r={20} />
        <circle className={Styles.filler} cx={25} cy={25} r={20} style={filledStyle} />
      </svg>
      {iconName && <Icon name={iconName} className={Styles.icon} />}
      <Icon name="tick" className={Styles.completeIcon} />
    </div>
  );
}

export default memo(ProgressIcon);
