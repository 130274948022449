import { defineMessages, useIntl } from "react-intl";

import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedMultipartRow } from "common/form/inputs/multipart/row";
import { DeprecatedMultipartColumn } from "common/form/inputs/multipart/column";
import { DeprecatedTextField } from "common/form/fields/text";

const messages = defineMessages({
  name: {
    id: "93407518-17b6-4979-94af-e588df7ebe39",
    defaultMessage: "Name",
  },
  first: {
    id: "d7b5063d-3171-4f91-b5a2-6c8cba961ca0",
    defaultMessage: "First",
  },
  last: {
    id: "8dc47ea7-5cbc-43e6-b00f-010b10f4ffaa",
    defaultMessage: "Last",
  },
});

type Props = {
  fieldNamePrefix: string;
  readOnly?: boolean;
};

function NameRow({ fieldNamePrefix, readOnly }: Props) {
  const intl = useIntl();

  const prefixedFirstNameField = `${fieldNamePrefix}.firstName`;
  const prefixedLastNameField = `${fieldNamePrefix}.lastName`;

  return (
    <FormGroup fields={[prefixedFirstNameField, prefixedLastNameField]} disableFormRowStyle>
      <DeprecatedMultipartRow>
        <DeprecatedMultipartColumn width={6}>
          <DeprecatedTextField
            id={prefixedFirstNameField}
            name={prefixedFirstNameField}
            placeholder={intl.formatMessage(messages.first)}
            data-automation-id={`${fieldNamePrefix}.poc-first-name-field`}
            // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
            autoComplete="nope"
            placeholderAsLabel
            useStyledInput
            displayRequiredAsterisk
            disabled={readOnly}
          />
        </DeprecatedMultipartColumn>

        <DeprecatedMultipartColumn width={6}>
          <DeprecatedTextField
            id={prefixedLastNameField}
            name={prefixedLastNameField}
            placeholder={intl.formatMessage(messages.last)}
            data-automation-id={`${fieldNamePrefix}.poc-last-name-field`}
            // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
            autoComplete="nope"
            placeholderAsLabel
            useStyledInput
            displayRequiredAsterisk
            disabled={readOnly}
          />
        </DeprecatedMultipartColumn>
      </DeprecatedMultipartRow>

      <FormGroupErrors fields={[prefixedFirstNameField, prefixedLastNameField]} />
    </FormGroup>
  );
}
export default NameRow;
