import type { ReactNode } from "react";

import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
};

export function SignerDetailsHeader({ children }: Props) {
  return (
    <Heading level="h3" textStyle="subtitleSmall" className={Styles.heading}>
      {children}
    </Heading>
  );
}
