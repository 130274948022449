import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import LoadingIndicator from "common/core/loading_indicator";
import { NetworkStatus, useQuery, useMutation } from "util/graphql";
import { SettingsHeader, SettingsPageWrapper, SettingsSubtitle } from "common/settingsv2/common";
import Button from "common/core/button";
import { pushNotification } from "common/core/notification_center/actions";
import StatelessPagination from "common/pagination/stateless";
import Icon from "common/core/icon";
import Table from "common/core/table";
import Link from "common/core/link";
import { FormattedDate, format } from "common/core/format/date";
import { encodeSearchParams } from "util/location";
import { userFullName } from "util/user";
import { SearchField } from "common/core/search_field";
import { NotaryActivationStatus, NotaryPresenceStatuses } from "graphql_globals";
import { FormattedActivationStatus } from "common/notary/panel/membership_details/activation_status";
import { PRESENCE_STATUSES } from "admin_portal/notaries/util";
import { IconButton } from "common/core/button/icon_button";

import { EditPanelModal } from "../add_or_edit_org_panel_modal";
import DeletePanelModal from "./delete_panel_modal";
import DeleteNotaryModal from "./delete_notary_modal";
import AddNotaryModal from "./add_notary_modal";
import Styles from "./index.module.scss";
import AdminPanelInfoQuery, {
  type AdminPanelInfo_panel_Panel as Panel,
  type AdminPanelInfo_panel_Panel_members_edges as PanelMemberEdge,
  type AdminPanelInfo_panel_Panel_members_edges_node_notaryProfile as NotaryProfile,
} from "./index_query.graphql";
import AddNotariesToPanelMutation from "./add_notaries_to_panel_mutation.graphql";
import DeletePanelMutation from "./delete_panel_mutation.graphql";
import InviteNotariesToPanelMutation from "./invite_notaries_to_panel_mutation.graphql";
import UpdatePanelMutation from "./update_panel_mutation.graphql";
import DeleteNotariesFromPanelMutation from "./delete_notaries_from_panel_mutation.graphql";

type Props = {
  panel: Panel;
  page: number;
  onChangePage: (page: number) => void;
  refetch: () => Promise<unknown>;
  searchTerm: string | null;
  onSearch: (searchTerm: string) => void;
};

type ModalState =
  | { type: "editPanel" }
  | { type: "addNotaries"; notaryProfileIds?: string[] }
  | { type: "deleteNotary"; notaryProfileId: string }
  | { type: "deletePanel" };

const MEMBERS_PER_PAGE = 10;

function getStatusStyle(activationStatus: NotaryActivationStatus) {
  switch (activationStatus) {
    case NotaryActivationStatus.INVITED:
      return Styles.invitedStatus;
    case NotaryActivationStatus.ACCEPTED:
      return Styles.acceptedStatus;
    case NotaryActivationStatus.REJECTED:
    case NotaryActivationStatus.PENDING:
    case NotaryActivationStatus.TERMINATED:
      return undefined;
  }
}

function renderColumns(
  onDelete: (notary: NotaryProfile) => void,
  onInvite: (notary: NotaryProfile) => void,
): { Header: ReactNode; render: (member: PanelMemberEdge) => ReactNode }[] {
  return [
    {
      Header: <FormattedMessage id="776e73a0-d401-4968-a43f-0424030ec73c" defaultMessage="Name" />,
      render: (member) => {
        return userFullName(member.node.notaryProfile.user);
      },
    },
    {
      Header: <FormattedMessage id="fb62700d-536b-4283-8a31-9a4f8bd49c9c" defaultMessage="Email" />,
      render: (member) => member.node.notaryProfile.user.email,
    },
    {
      Header: (
        <FormattedMessage id="3554af41-25d5-47bb-9b65-b4ae0623ca5f" defaultMessage="Status" />
      ),
      render: (member) => {
        const activationStatus = member.node.activationStatus;
        const canInvite =
          activationStatus !== NotaryActivationStatus.ACCEPTED &&
          activationStatus !== NotaryActivationStatus.INVITED;
        return (
          <div className={Styles.activationContainer}>
            <div className={getStatusStyle(activationStatus)}>
              <FormattedActivationStatus status={activationStatus} />
            </div>
            {canInvite && (
              <IconButton
                className={Styles.inviteIcon}
                buttonSize="condensed"
                buttonColor="dark"
                variant="tertiary"
                name="email"
                onClick={() => onInvite(member.node.notaryProfile)}
                label={
                  <FormattedMessage
                    id="7d1f49fe-5249-4869-9896-39ab684156d5"
                    defaultMessage="Invite notary to panel"
                  />
                }
              />
            )}
          </div>
        );
      },
    },
    {
      Header: (
        <FormattedMessage id="a11af963-78ef-4157-bcab-04a92025bd91" defaultMessage="Online" />
      ),
      render: ({ node: { notaryProfile } }) => {
        const { presenceStatus } = notaryProfile;
        return (
          <div className={Styles.presenceCell}>
            <div
              className={classnames(
                Styles.presence,
                presenceStatus === NotaryPresenceStatuses.AVAILABLE && Styles.presenceAvailable,
                presenceStatus === NotaryPresenceStatuses.UNAVAILABLE && Styles.presenceUnavailable,
              )}
            />
            {PRESENCE_STATUSES[presenceStatus]}
          </div>
        );
      },
    },
    {
      Header: (
        <FormattedMessage
          id="4a2de300-9537-4ca9-a5e7-fc43b3e686d2"
          defaultMessage="Last Panel Call"
        />
      ),
      render: (member) =>
        member.node.timeOfLastCompletedMeeting && (
          <FormattedMessage
            id="73a3e726-ba55-429c-a9b2-531c8e8b87cc"
            defaultMessage={"{date} at {time}"}
            values={{
              date: <FormattedDate value={member.node.timeOfLastCompletedMeeting} />,
              time: format({ value: member.node.timeOfLastCompletedMeeting, formatStyle: "p" }),
            }}
          />
        ),
    },
    {
      Header: <FormattedMessage id="5d41b89d-af75-4c0c-af72-382dbe9cc259" defaultMessage="Tier" />,
      render: (member) => member.node.notaryProfile.tier,
    },
    {
      Header: <FormattedMessage id="510dda2d-bf74-46c0-bcf3-852009fecf6f" defaultMessage="CSAT" />,
      render: (member) => (
        <div>
          <Icon className={Styles.starIcon} name="rating-star" />
          {member.node.notaryProfile.averageRating}
        </div>
      ),
    },
    {
      Header: null,
      render: (member) => (
        <IconButton
          name="delete"
          variant="tertiary"
          buttonColor="danger"
          buttonSize="condensed"
          onClick={() => {
            onDelete(member.node.notaryProfile);
          }}
          label={
            <FormattedMessage
              id="24d34fed-caf7-4ef0-8bb9-f00a43cc2ca7"
              defaultMessage="Delete notary from panel"
            />
          }
        />
      ),
    },
    {
      Header: null,
      render: (member) => {
        const search = member.node.notaryProfile.user.email
          ? encodeSearchParams(new URLSearchParams({ email: member.node.notaryProfile.user.email }))
          : "";
        return (
          <>
            <Link href={`/notary?${search}`}>
              <FormattedMessage id="f112e681-2ae8-4fd8-a799-b3f8440bc958" defaultMessage="View" />
            </Link>
            <IconButton
              className={Styles.icon}
              buttonColor="dark"
              variant="tertiary"
              buttonSize="condensed"
              name="caret-right"
              onClick={() => onInvite(member.node.notaryProfile)}
              label={
                <FormattedMessage
                  id="4121e534-7cf4-4858-9bca-eda7b535fe88"
                  defaultMessage="Invite notary to panel"
                />
              }
            />
          </>
        );
      },
    },
  ];
}
const MESSAGES = defineMessages({
  addedNotaries: {
    id: "14fe8892-c41c-42fc-83b7-15f7afb33f8e",
    defaultMessage:
      "Successfully added {numberNotaries, plural, one{notary} other{# notaries}} to panel",
  },
  invitedNotaries: {
    id: "88c8d791-f544-4d8b-80de-e9a28ca2d48b",
    defaultMessage:
      "Successfully invited {numberNotaries, plural, one{notary} other{# notaries}} to panel",
  },
  updatedPanel: {
    id: "e814ced4-6d91-42ce-bb9f-718f11ba3902",
    defaultMessage: "Successfully updated panel",
  },
  searchLabel: {
    id: "25f987d4-1cef-4031-a155-35229a9a38a7",
    defaultMessage: "Search by notary name or email",
  },
});

function PanelInfo(props: Props) {
  const { panel, page, refetch, onChangePage, searchTerm, onSearch } = props;
  const [modalState, setModalState] = useState<ModalState | null>(null);
  const addNotariesToPanelMutateFn = useMutation(AddNotariesToPanelMutation);
  const deleteNotariesFromPanelMutateFn = useMutation(DeleteNotariesFromPanelMutation);
  const deletePanelMutationFn = useMutation(DeletePanelMutation);
  const inviteNotariesToPanelMutateFn = useMutation(InviteNotariesToPanelMutation);
  const updatePanelMutateFn = useMutation(UpdatePanelMutation);
  const navigate = useNavigate();

  const redirectPath = `/companies/${panel.ownerOrganization?.id}/panels`;
  const intl = useIntl();

  const deleteNotaryCb = (notary: NotaryProfile) =>
    setModalState({ type: "deleteNotary", notaryProfileId: notary.id });
  const inviteNotaryCb = (notary: NotaryProfile) => {
    setModalState({ type: "addNotaries", notaryProfileIds: [notary.id] });
  };

  function renderModal(): ReactNode | null {
    switch (modalState?.type) {
      case "editPanel":
        return (
          <EditPanelModal
            panel={panel}
            onSave={async ({
              panelName,
              description,
              routingExpiry,
              allowsAssignment,
              allowsAlerts,
            }) => {
              await updatePanelMutateFn({
                variables: {
                  input: {
                    panelId: panel.id,
                    name: panelName,
                    routingExpiryInSeconds: routingExpiry ? Number(routingExpiry) : null,
                    description,
                    allowsAssignment,
                    allowsAlerts,
                  },
                },
              });
              pushNotification({ message: intl.formatMessage(MESSAGES.updatedPanel) });
              setModalState(null);
            }}
            onCancel={() => setModalState(null)}
          />
        );
      case "addNotaries":
        return (
          <AddNotaryModal
            onSave={async (ids: string[], useInvitationFlow: boolean) => {
              if (useInvitationFlow) {
                await inviteNotariesToPanelMutateFn({
                  variables: { input: { panelId: panel.id, notaryProfileIds: ids } },
                });
                pushNotification({
                  message: intl.formatMessage(MESSAGES.invitedNotaries, {
                    numberNotaries: ids.length,
                  }),
                });
              } else {
                await addNotariesToPanelMutateFn({
                  variables: { input: { panelId: panel.id, notaryProfileIds: ids } },
                });
                pushNotification({
                  message: intl.formatMessage(MESSAGES.addedNotaries, {
                    numberNotaries: ids.length,
                  }),
                });
              }
              setModalState(null);
              return refetch();
            }}
            onCancel={() => setModalState(null)}
            panel={panel}
            notaryProfileIds={modalState.notaryProfileIds}
          />
        );
      case "deleteNotary": {
        const currNotary = panel.members.edges.find(
          (edge) => edge.node.notaryProfile.id === modalState.notaryProfileId,
        )?.node.notaryProfile;

        // After deleteNotariesFromPanelMutateFn resolves, but before modalState is set to null, the component re-renders with the modalState still set to deleteNotary.
        // In that case currNotary will be null, but the modal will already be closed so return null here.
        if (!currNotary) {
          return null;
        }

        return (
          <DeleteNotaryModal
            notary={currNotary}
            onCancel={() => setModalState(null)}
            onDelete={async () => {
              await deleteNotariesFromPanelMutateFn({
                variables: { input: { panelId: panel.id, notaryProfileIds: [currNotary.id] } },
              });
              pushNotification({
                message: (
                  <FormattedMessage
                    id="3ec5c082-864a-4f36-a617-9dde81ec8ab8"
                    defaultMessage="Successfully removed notary"
                  />
                ),
              });
              setModalState(null);
              return refetch();
            }}
          />
        );
      }
      case "deletePanel":
        return (
          <DeletePanelModal
            onCancel={() => setModalState(null)}
            onDelete={async () => {
              await deletePanelMutationFn({ variables: { input: { panelId: panel.id } } });
              navigate(redirectPath);
              pushNotification({
                message: (
                  <FormattedMessage
                    id="3ec5c082-864a-4f36-a617-9dde81ec8ab8"
                    defaultMessage="Successfully deleted panel"
                  />
                ),
              });
              setModalState(null);
            }}
            panel={panel}
          />
        );
      default:
        return null;
    }
  }

  return (
    <SettingsPageWrapper>
      <div className={Styles.content}>
        <div className={Styles.headingContainer}>
          <div className={Styles.heading}>
            <SettingsHeader title={panel.name} />
            <div className={Styles.buttonsContainer}>
              <Button
                className={Styles.editButton}
                onClick={() => setModalState({ type: "editPanel" })}
                variant="tertiary"
                buttonColor="action"
              >
                <FormattedMessage
                  id="82a7b8fd-e4db-42b9-b79d-a30d73b71059"
                  defaultMessage="Edit panel"
                />
              </Button>
              <Button
                className={Styles.deleteButton}
                onClick={() => setModalState({ type: "deletePanel" })}
                buttonColor="danger"
                variant="secondary"
                withIcon={{ name: "delete", placement: "left" }}
              >
                <FormattedMessage
                  id="82a7b8fd-e4db-42b9-b79d-a30d73b71059"
                  defaultMessage="Delete panel"
                />
              </Button>
              <Button
                onClick={() => setModalState({ type: "addNotaries" })}
                variant="secondary"
                buttonColor="action"
                withIcon={{ name: "add", placement: "left" }}
              >
                <FormattedMessage
                  id="82a7b8fd-e4db-42b9-b79d-a30d73b71059"
                  defaultMessage="Add notary"
                />
              </Button>
            </div>
          </div>
          {panel.description && <SettingsSubtitle>{panel.description}</SettingsSubtitle>}
        </div>
        <SearchField
          className={Styles.searchField}
          size="large"
          value={searchTerm || ""}
          onSearch={onSearch}
          placeholder={intl.formatMessage(MESSAGES.searchLabel)}
          aria-label={intl.formatMessage(MESSAGES.searchLabel)}
          searchOnClear
          searchOnBlur
        />
        <StatelessPagination
          maxPageNumber={Math.ceil(panel.members.totalCount / MEMBERS_PER_PAGE)}
          onChange={(page) => onChangePage(page)}
          pageNumber={page}
        >
          <Table
            data={panel.members.edges}
            columns={renderColumns(deleteNotaryCb, inviteNotaryCb)}
            totalItems={panel.members.totalCount}
          />
        </StatelessPagination>
      </div>
      {renderModal()}
    </SettingsPageWrapper>
  );
}

function PanelInfoContainer() {
  const { panelId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;
  const searchTerm = searchParams.get("query");
  const membersOffset = (page - 1) * MEMBERS_PER_PAGE;
  const { data, networkStatus, refetch } = useQuery(AdminPanelInfoQuery, {
    variables: { panelId: panelId!, membersOffset, ...(searchTerm && { searchTerm }) },
  });
  const onChangePage = (page: number) =>
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("page", page.toString());
      return newParams;
    });

  const onSearch = (searchTerm: string) =>
    setSearchParams(new URLSearchParams({ query: searchTerm }));

  if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) {
    return <LoadingIndicator />;
  }
  const { panel } = data!;
  if (panel?.__typename !== "Panel") {
    throw new Error(`Expected Panel, got ${panel?.__typename}`);
  }

  return (
    <PanelInfo
      panel={panel}
      page={page}
      refetch={refetch}
      onChangePage={onChangePage}
      searchTerm={searchTerm}
      onSearch={onSearch}
    />
  );
}

export default PanelInfoContainer;
