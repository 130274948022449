import type { OrganizationTypeEnum } from "graphql_globals";
import Table from "common/core/table";

import { getTableColumns } from "./columns";

export type OrgTableItem = {
  id: string;
  organizationWeight: number;
  name: string | null;
  organizationType: OrganizationTypeEnum;
};

type Props = {
  items: OrgTableItem[];
  flattenTree?: boolean;
  setCurrentOrgId: (id: string) => void;
};

export function CompanyOrgTable({ items, flattenTree = false, setCurrentOrgId }: Props) {
  return (
    <Table
      data={items}
      columns={getTableColumns(flattenTree, false)}
      totalItems={items.length}
      rowInteraction={{
        onClick: (e) => {
          setCurrentOrgId(e.id);
        },
      }}
      stickyHotDogMenu
      noShadow
    />
  );
}
