import "./index.scss";

import { useCallback, memo, type ReactElement, useEffect } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { Feature } from "graphql_globals";
import { useQuery } from "util/graphql";
import { Paragraph } from "common/core/typography";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import CloserAssignmentPills, {
  UNASSIGNED,
  type CustomNotaryOptionsErrorMap,
  type Closer,
} from "common/transactions/closer_assignment";
import Button from "common/core/button";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import AlertMessage from "common/core/alert_message";
import type { UseForm } from "common/transaction_creation/v3/form";

import CloserAssignmentQuery from "./index.query.graphql";
import Styles from "./index.module.scss";

export {
  type CustomNotaryOptionsErrorMap,
  DisabledTypes,
} from "common/transactions/closer_assignment";

export type OuterProps = {
  formName: string;
  organization: {
    id: string;
    featureList: Feature[];
  };
  customNotaryOptionsErrorMap?: CustomNotaryOptionsErrorMap;
  reactHookFormProps?: {
    setValue: UseForm["setValue"];
    formValues: FormValues;
    disabledNotarizeCloserOverride: boolean;
    disabledCloserAssigneeId: boolean;
  };
  notaryAssigneeId?: string | null;
};

type Props = OuterProps &
  V3TransactionProps & {
    canUseIHN: boolean;
    canUseODN: boolean;
    alertSection?: ReactElement | null;
    closers: Closer[];
    automationId: string;
  };

export type FormValues = {
  notarizeCloserOverride?: boolean | null;
  closerAssigneeId?: string | null;
};

type SubFormProps = {
  formValues: FormValues;
  change: (fieldName: keyof FormValues, value: string | boolean | null) => void;
};

export type V3TransactionProps = {
  disabledNotarizeCloserOverride?: boolean;
  disabledCloserAssigneeId?: boolean;
};

// This is a flawed attempt to determine whether the chosen notary is a member of
// the active organization. It produces false negatives because the closers list
// is not fully representative of all the org structure's closers. We are stuck with this approach
// until notary_organization is adjusted to return either the lender/title/NST org and not the parent of any of those orgs.
function hasOrgAssociatedNotary(localNotaryAssigneeId: string, closers: { id: string }[]) {
  return (
    localNotaryAssigneeId &&
    (closers.find((closer) => closer.id === localNotaryAssigneeId) ||
      localNotaryAssigneeId === UNASSIGNED)
  );
}

const MESSAGES = defineMessages({
  onboardInternalNotaries: {
    id: "c24d71a5-354e-4b34-b9c6-8a22a75b6f9f",
    defaultMessage:
      "This transaction will use the Notarize network. Once you onboard internal notaries, you'll be able to select them here.",
  },
});

function DefaultNotaryAssignmentAlert({ canUseIHN }: { canUseIHN: boolean }) {
  const intl = useIntl();

  if (!canUseIHN) {
    return (
      <AlertMessage className={Styles.alertBanner} kind="info">
        {intl.formatMessage(MESSAGES.onboardInternalNotaries)}
      </AlertMessage>
    );
  }

  return null;
}

function CloserAssignment({
  change,
  formValues,
  canUseIHN,
  canUseODN,
  alertSection,
  customNotaryOptionsErrorMap,
  closers,
  notaryAssigneeId,
  automationId,
  reactHookFormProps,
  disabledNotarizeCloserOverride = false,
  disabledCloserAssigneeId = false,
}: Props & SubFormProps) {
  const deps = [change];
  const notarizeCloserOverride = formValues.notarizeCloserOverride ?? null;
  const localNotaryAssigneeId = formValues.closerAssigneeId;
  // the revert button should be displayed if there is a notary on the transaction
  // that is different than the one just selected locally, and the transaction's notary
  // is no longer selectable because it isn't in the closers list (ex. the lender chose this notary)
  const shouldDisplayRevert =
    notaryAssigneeId &&
    notaryAssigneeId !== localNotaryAssigneeId &&
    !hasOrgAssociatedNotary(notaryAssigneeId, closers);
  // the "notary already selected" copy should be displayed when the selected notary
  // is not in the org's available closers list (ex. the lender chose this notary or if the user
  // chooses a new underwriter that produces a different available closers list).

  const isNotarySelectedByOtherOrg =
    localNotaryAssigneeId &&
    localNotaryAssigneeId !== UNASSIGNED &&
    !hasOrgAssociatedNotary(localNotaryAssigneeId, closers);

  const notarizeNetworkOnly = !canUseIHN && canUseODN;

  const handleChangeNotarizeCloserOverride = useCallback(
    (newValue: boolean | null) => change("notarizeCloserOverride", newValue),
    deps,
  );
  const handleChangeAssignee = useCallback(
    (newValue: string | null) => change("closerAssigneeId", newValue),
    deps,
  );

  useEffect(() => {
    // If an organization cannot use IHN, automatically set them to use ODN. Ideally this isn't done for lender/title
    // since they care a lot more about IHN vs ODN and have a lot more requirements
    if (notarizeNetworkOnly && !isNotarySelectedByOtherOrg) {
      handleChangeNotarizeCloserOverride(true);
    }
  }, []);

  const missingCustomNotaryOptionsMap = !customNotaryOptionsErrorMap;
  useEffect(() => {
    // Only continue if its not the base business case of simple IHN/ODN
    if (missingCustomNotaryOptionsMap) {
      return;
    }
    // the purpose of clearing the closer override is we want the user to manually select a notarization option after
    // the selection has been invalidated (ex. by changing underwriter). Cleaning the selection (setting notarizeCloserOverride to null)
    // forces the user to select a new option. But, we don't want them to do so if
    // 1. there is a notary already assigned or...
    // 2. the org can neither use IHN nor ODN. This could happen to lender in a double Lender/Title BYON case. Lender
    // would have to rely on title to select a valid option, which should be available on their side.
    if (isNotarySelectedByOtherOrg || (!canUseIHN && !canUseODN)) {
      return;
    }
    if ((notarizeCloserOverride && !canUseODN) || (!notarizeCloserOverride && !canUseIHN)) {
      handleChangeNotarizeCloserOverride(null);
    }
  }, [
    canUseODN,
    canUseIHN,
    notarizeCloserOverride,
    isNotarySelectedByOtherOrg,
    missingCustomNotaryOptionsMap,
  ]);

  return (
    <div className="CloserAssignment--SubForm" data-automation-id={automationId}>
      <Paragraph className="CloserAssignment--Header">
        <FormattedMessage
          id="c48c9887-ce8d-4c9b-a113-0979a1861ce2"
          defaultMessage="Who will conduct the meeting?"
        />
      </Paragraph>

      {isNotarySelectedByOtherOrg ? (
        <div className="SubFormSection">
          <div className="SubFormSection--content">
            <div className={Styles.overrideContainer}>
              <p>
                <FormattedMessage
                  defaultMessage="A notary has already been assigned."
                  id="b092e696-4312-49e7-b25a-3152d5bf9103"
                />
              </p>
              <Button
                variant="tertiary"
                buttonColor="action"
                className={Styles.overrideButton}
                disabled={disabledCloserAssigneeId || disabledNotarizeCloserOverride}
                onClick={() => {
                  handleChangeAssignee(null);
                  handleChangeNotarizeCloserOverride(notarizeNetworkOnly);
                }}
              >
                <FormattedMessage
                  defaultMessage="Override notary selection"
                  id="b3ca4d2e-0d01-4add-90dc-90c5ddd50ce2"
                />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {alertSection}
          <CloserAssignmentPills
            notarizeCloserOverride={notarizeCloserOverride}
            onChangeNotarizeCloserOverride={handleChangeNotarizeCloserOverride}
            assignee={formValues.closerAssigneeId}
            onChangeAssignee={handleChangeAssignee}
            closers={closers}
            isPersonallyKnownToNotary={false}
            canUseIHN={canUseIHN}
            canUseODN={canUseODN}
            customNotaryOptionsErrorMap={customNotaryOptionsErrorMap}
            disabledNotarizeCloserOverride={disabledNotarizeCloserOverride}
            disabledCloserAssigneeId={disabledCloserAssigneeId}
          />

          {!reactHookFormProps && <FormGroupErrors fields={["notarizeCloserOverride"]} />}
          {shouldDisplayRevert && (
            <div>
              <Button
                variant="tertiary"
                buttonColor="action"
                className={Styles.overrideButton}
                disabled={disabledCloserAssigneeId}
                onClick={() => {
                  handleChangeAssignee(notaryAssigneeId);
                }}
              >
                <FormattedMessage
                  id="5b8d2b84-dc76-4622-bee0-ed951baf78e9"
                  defaultMessage="Revert notary selection"
                />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const subFormEnhancer = deprecatedSubForm({
  getValuesFor: ["notarizeCloserOverride", "closerAssigneeId"],
});

export const MemoizedCloserAssignment = memo(subFormEnhancer(CloserAssignment)) as (
  props: Props,
) => ReactElement;

export const MemoizedCoreFormCloserAssignment = memo(CloserAssignment);

export default (props: OuterProps) => {
  const { data, loading } = useQuery(CloserAssignmentQuery, {
    variables: {
      transactionOrgId: props.organization.id,
    },
  });

  const organization = data?.node;
  if (loading) {
    return null;
  }

  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}.`);
  }
  const canUseODN = props.organization.featureList.includes(Feature.ORG_NOTARY_OVERFLOW);
  const canUseIHN = organization.closers.length > 0;
  const reactHookFormProps = props.reactHookFormProps;

  if (reactHookFormProps) {
    return (
      <MemoizedCoreFormCloserAssignment
        closers={organization.closers}
        canUseIHN={canUseIHN}
        canUseODN={canUseODN}
        alertSection={<DefaultNotaryAssignmentAlert canUseIHN={canUseIHN} />}
        automationId="generic-closer-assignment"
        change={reactHookFormProps.setValue}
        formValues={reactHookFormProps.formValues}
        disabledNotarizeCloserOverride={reactHookFormProps.disabledNotarizeCloserOverride}
        disabledCloserAssigneeId={reactHookFormProps.disabledCloserAssigneeId}
        {...props}
      />
    );
  }

  return (
    <MemoizedCloserAssignment
      closers={organization.closers}
      // For biz, if this form is rendered, the org should be able to use IHN
      // since there are no state restrictions (at least not yet)
      canUseIHN={canUseIHN}
      canUseODN={canUseODN}
      alertSection={<DefaultNotaryAssignmentAlert canUseIHN={canUseIHN} />}
      automationId="generic-closer-assignment"
      {...props}
    />
  );
};
