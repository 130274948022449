import { memo, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { MeetingEndedState } from "graphql_globals";
import { PassFailStatus } from "common/transactions/details/common";
import { Substyle } from "common/core/typography";

import { NotaryItemWrapper } from "../notary_item_wrapper";

type Props = {
  meeting: { endedState: MeetingEndedState };
};
type StatusProps = {
  children: ReactNode;
};

function NeutralStatus({ children }: StatusProps) {
  return <Substyle textColor="subtle">{children}</Substyle>;
}

function SuccessStatus({ children }: StatusProps) {
  return <PassFailStatus status="pass" customStatusText={children} />;
}

function FailedStatus({ children }: StatusProps) {
  return <PassFailStatus status="fail" customStatusText={children} />;
}

function renderContent(endedState: MeetingEndedState) {
  switch (endedState) {
    case MeetingEndedState.NOT_COMPLETED:
      return (
        <NeutralStatus>
          <FormattedMessage id="ae2fe02f-2f49-4ac1-9c00-863a5b0fa18b" defaultMessage="Ongoing" />
        </NeutralStatus>
      );
    case MeetingEndedState.KILLED:
      return (
        <FailedStatus>
          <FormattedMessage id="5477b47b-d607-445c-8d74-f50693d92ccc" defaultMessage="Dropped" />
        </FailedStatus>
      );
    case MeetingEndedState.CUSTOMER_CANCELLED:
      return (
        <FailedStatus>
          <FormattedMessage
            id="de5d3a70-af65-426f-9b70-6ad30ca8781b"
            defaultMessage="Customer Cancelled"
          />
        </FailedStatus>
      );
    case MeetingEndedState.NOTARY_CANCELLED_NO_CHARGE:
      return (
        <FailedStatus>
          <FormattedMessage id="4b9272dd-789d-463c-828a-487318a95ed6" defaultMessage="Cancelled" />
        </FailedStatus>
      );
    case MeetingEndedState.COMPLETED:
      return (
        <SuccessStatus>
          <FormattedMessage id="11fc8b29-8213-4939-b505-08b11714620a" defaultMessage="Completed" />
        </SuccessStatus>
      );
    case MeetingEndedState.NOTARY_CANCELLED_WITH_CHARGE:
      return null;
    default:
      throw new Error(`Unknown ended state ${endedState}`);
  }
}

function NotarizationStatus({ meeting }: Props) {
  return (
    <NotaryItemWrapper
      term={
        <FormattedMessage
          id="2c73f196-6c6a-4a1d-b475-83df85eb6065"
          defaultMessage="Meeting Status"
        />
      }
      automationId="notarization-meeting-status"
      definition={renderContent(meeting.endedState)}
    />
  );
}

export default memo(NotarizationStatus);
