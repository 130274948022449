import { FormattedMessage } from "react-intl";

import { TransactionMessageSubject } from "common/signer/items/transaction_message_subject";
import { TransactionMessage } from "common/signer/items/transaction_message";
import { TransactionSignature } from "common/signer/items/transaction_signature";
import { Card, CardHeading } from "common/core/card";
import { DescriptionList } from "common/core/description_list";

import type { DocumentBundleForTransactionDetailsSummary_transaction as OrganizationTransaction } from "../index_fragment.graphql";
import Styles from "./index.module.scss";

export function CustomEmailDetails({ transaction }: { transaction: OrganizationTransaction }) {
  return (
    <Card
      header={
        <CardHeading level="h2">
          <FormattedMessage
            id="3fc73de0-543f-455d-86ca-df6a91b12df9"
            defaultMessage="Custom email details"
          />
        </CardHeading>
      }
      fullWidth
      noMargin
      className={Styles.card}
    >
      <DescriptionList className={Styles.customEmailGrid} itemGap="large">
        <TransactionMessageSubject transaction={transaction} />
        <TransactionSignature transaction={transaction} />
        <TransactionMessage transaction={transaction} />
      </DescriptionList>
    </Card>
  );
}
