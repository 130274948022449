import { useEffect, useState } from "react";
import classNames from "classnames";
import { useIntl, defineMessages } from "react-intl";

import PasswordRequirements from "common/account/password_requirements";
import { StyledTextInput } from "common/core/form/text";
import type { FieldError, UseFormRegisterReturn, UseFormSetFocus } from "common/core/form";
import { FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { useId } from "util/html";
import { IconButton } from "common/core/button/icon_button";

import Styles from "./index.module.scss";

type Props = {
  invalid?: boolean;
  inputClass?: string;
  withRequirements?: boolean | "always";
  autofocus?: boolean;
  label?: string;
  disabled?: boolean;
  disabledHint?: string;
  setFocus?: UseFormSetFocus<{ password: string }>;
  error?: FieldError;
  "aria-describedby"?: string;
  wrapperClass?: string;
  automationId?: string;
  placeholder?: string;
  autoComplete?: "new-password" | "current-password";
  displayRequiredAsterisk?: boolean;
  placeholderAsLabel?: boolean;
  "aria-label"?: string;
};

type HookFormProps = Omit<Props, "autofocus"> & {
  registerProps: UseFormRegisterReturn<"password">;
};

const MESSAGES = defineMessages({
  toggle: {
    id: "be203ef8-acd9-4035-89e2-81865f8ae725",
    defaultMessage: "Toggle password visibility",
  },
});

export function HookFormPassword({
  invalid,
  inputClass,
  withRequirements,
  setFocus,
  registerProps,
  disabled,
  disabledHint,
  label,
  error,
  wrapperClass,
  automationId = "password-field",
  "aria-describedby": ariaDescribedBy,
  placeholder,
  autoComplete,
  displayRequiredAsterisk,
  placeholderAsLabel,
  "aria-label": ariaLabel,
}: HookFormProps) {
  const intl = useIntl();
  const [isObscured, setIsObscured] = useState(true);
  useEffect(() => {
    if (setFocus) {
      setFocus("password");
    }
  }, [setFocus]);
  const id = useId();
  const showRequirements = withRequirements === "always" || (withRequirements && invalid);
  const showError = invalid && error;

  return (
    <>
      <div className={classNames(wrapperClass, Styles.wrapper)}>
        <StyledTextInput
          displayRequiredAsterisk={displayRequiredAsterisk}
          id="password"
          className={inputClass}
          type={isObscured ? "password" : "text"}
          data-automation-id={automationId}
          aria-invalid={isAriaInvalid(invalid)}
          disabled={disabled}
          disabledHint={disabledHint}
          label={label}
          aria-label={ariaLabel}
          aria-describedby={classNames(showRequirements && id, showError && ariaDescribedBy)}
          placeholder={placeholder}
          placeholderAsLabel={placeholderAsLabel}
          autoComplete={autoComplete}
          {...registerProps}
        />
        <div
          className={classNames(
            Styles.obscureButtonWrapper,
            label && Styles.withLabel,
            showError && Styles.withError,
          )}
        >
          <IconButton
            className={Styles.obscureButton}
            name={isObscured ? "witness-filled" : "visibility-off"}
            buttonColor="dark"
            buttonSize="condensed"
            label={intl.formatMessage(MESSAGES.toggle)}
            variant="tertiary"
            onClick={() => setIsObscured(!isObscured)}
            role="switch"
            aria-checked={!isObscured}
          />
        </div>
        {showError && <FormattedFieldError inputName={"password"} error={error} />}
      </div>
      <div role="alert">
        {showRequirements && (
          <div id={id}>
            <PasswordRequirements className={Styles.passwordRequirements} />
          </div>
        )}
      </div>
    </>
  );
}
