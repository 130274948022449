import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Card, CardSection } from "common/core/card";

import { type AdminCompanyDetailsOrganization_organization_Organization_billableOrganization as RootOrg } from "../organization_query.graphql";
import Styles from "../index.module.scss";

const MESSAGES = defineMessages({
  header: {
    id: "c4c86161-a4ed-4b3d-b6e9-239bee9bfc7e",
    defaultMessage: "Pricing handled by root organization",
  },
});

type Props = {
  billableOrganization: RootOrg;
};

export function ManagedPricingCard({ billableOrganization }: Props) {
  const intl = useIntl();

  return (
    <div className={Styles.container}>
      <Card
        className={Styles.cardContainer}
        key={billableOrganization.id}
        header={<div className={Styles.cardHeading}>{intl.formatMessage(MESSAGES.header)}</div>}
        fullWidth
      >
        <CardSection>
          <div>
            <FormattedMessage
              id="a0dd5525-a950-4037-addd-de19ddeee81c"
              defaultMessage="This organization's charges are being billed to {organizationName} (<link>{organizationId}</link>). Navigate to <link>{organizationId}</link> to view or make changes."
              values={{
                organizationName: billableOrganization.name,
                organizationId: billableOrganization.id,
                link: (text) => (
                  <Link to={`/companies/${billableOrganization.id}/pricing-and-billing/pricing`}>
                    {text}
                  </Link>
                ),
              }}
            />
          </div>
        </CardSection>
      </Card>
    </div>
  );
}
