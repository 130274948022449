import { memo } from "react";
// eslint-disable-next-line no-restricted-imports
import { parseISO, formatDistance, formatDistanceStrict } from "date-fns";
// eslint-disable-next-line no-restricted-imports
import {
  format as fnsFormat,
  fromZonedTime as fnsFromZonedTime,
  toZonedTime as fnsToZonedTime,
} from "date-fns-tz";
import { enUS } from "date-fns/locale";

type AcceptedDate = Date | number | string | null | undefined;
type TimeZoneWindow = { __notarizeTestTimezone?: string | null };
type BaseProps = {
  value: AcceptedDate;
};
type FormatParams = BaseProps & {
  /* Display the value in _this_ timezone, defaults to user's local time */
  asTimeZone?: string | null;
  formatStyle: string;
};
type DurationProps = {
  from: AcceptedDate;
  /** Defaults to now */
  to?: AcceptedDate;
  includeSeconds?: boolean;
  /** Add the indication that its before/after. Defaults to `true` */
  addRelativeIndicator?: boolean;
};
type DurationStrictProps = {
  from: AcceptedDate;
  to: AcceptedDate;
  roundingMethod?: "round" | "floor" | "ceil";
  unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
};
type FnsToZonedParams = Parameters<typeof fnsToZonedTime>;
type FnsFromZonedParams = Parameters<typeof fnsFromZonedTime>;

// Certain timezones aren"t supported anymore in certain browsers, but our database still has them
// Mappings are based on https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// As of 11/04/2019, these are the timezones that do not work on Chrome
// Tested by looping through all timezones and testing if date-fns format errors out or not
const DEFUNC_TIMEZONES: Readonly<Record<string, string>> = Object.freeze({
  "America/Argentina/ComodRivadavia": "America/Argentina/Catamarca",
  "America/Knox_IN": "America/Indiana/Knox",
  "Antarctica/McMurdo": "Pacific/Auckland",
  "Australia/ACT": "Australia/Sydney",
  "Australia/LHI": "Australia/Lord_Howe",
  "Australia/NSW": "Australia/Sydney",
  "Brazil/DeNoronha": "America/Noronha",
  "Canada/East-Saskatchewan": "America/Regina",
  CET: "Europe/Paris",
  CST6CDT: "America/Chicago",
  "Chile/EasterIsland": "Pacific/Easter",
  EET: "Europe/Sofia",
  EST: "America/Cancun",
  EST5EDT: "America/New_York",
  "Etc/UCT": "Etc/UTC",
  GB: "Europe/London",
  "GB-Eire": "Europe/London",
  "GMT+0": "Etc/GMT",
  "GMT-0": "Etc/GMT",
  GMT0: "Etc/GMT",
  HST: "Pacific/Honolulu",
  MET: "Europe/Paris",
  MST: "America/Phoenix",
  MST7MDT: "America/Denver",
  "Mexico/BajaNorte": "America/Tijuana",
  "Mexico/BajaSur": "America/Mazatlan",
  NZ: "Pacific/Auckland",
  "NZ-CHAT": "Pacific/Chatham",
  PRC: "Asia/Shanghai",
  PST8PDT: "America/Los_Angeles",
  ROC: "Asia/Taipei",
  ROK: "Asia/Seoul",
  UCT: "Etc/UTC",
  "US/Alaska": "America/Anchorage",
  "US/Aleutian": "America/Adak",
  "US/Arizona": "America/Phoenix",
  "US/Central": "America/Chicago",
  "US/Eastern": "America/New_York",
  "US/East-Indiana": "America/Indiana/Indianapolis",
  "US/Hawaii": "Pacific/Honolulu",
  "US/Indiana-Starke": "America/Indiana/Knox",
  "US/Michigan": "America/Detroit",
  "US/Mountain": "America/Denver",
  "US/Pacific": "America/Los_Angeles",
  "US/Pacific-New": "America/Los_Angeles",
  "US/Samoa": "Pacific/Pago_Pago",
  WET: "Europe/Lisbon",
  "W-SU": "Europe/Moscow",
});

function formatReadyDate(date: Date | string | number): Date | number {
  return typeof date === "string" ? parseISO(date) : date;
}

/** Timezones are not to be trusted. This normalizes them and makes them safe to format. */
function sanitizeTimeZone(originalTimezone: string) {
  return DEFUNC_TIMEZONES[originalTimezone] || originalTimezone;
}

/** Make sure you fully understand what this function is for. Shouldn't be required most of the time */
export function toZonedTime(value: FnsToZonedParams[0], timeZone: FnsToZonedParams[1]) {
  return fnsToZonedTime(value, sanitizeTimeZone(timeZone));
}

/** Make sure you fully understand what this function is for. Shouldn't be required most of the time */
export function fromZonedTime(value: FnsFromZonedParams[0], timeZone: FnsFromZonedParams[1]) {
  return fnsFromZonedTime(value, sanitizeTimeZone(timeZone));
}

export function getDateOrdinal(day: number) {
  if (day > 3 && day < 21) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function format({ value, formatStyle, asTimeZone }: FormatParams): string | null {
  if (!value) {
    return null;
  }
  if (process.env.NODE_ENV === "test") {
    // In tests, always run in EST time zone by default or in __notarizeTestTimezone if set
    const overrideTimezone = (window as TimeZoneWindow).__notarizeTestTimezone;
    if (!asTimeZone && overrideTimezone) {
      asTimeZone = overrideTimezone;
    } else if (!asTimeZone && overrideTimezone === undefined) {
      // If overrideTimezone is null we do not set the value
      asTimeZone = "America/New_York";
    }
  }
  const readyDate = formatReadyDate(value);
  const timeZonedDate = asTimeZone ? toZonedTime(readyDate, asTimeZone) : readyDate;
  return fnsFormat(timeZonedDate, formatStyle, {
    locale: enUS,
    timeZone: (asTimeZone && sanitizeTimeZone(asTimeZone)) || undefined,
  });
}

export function formatDuration({
  from,
  to = new Date(),
  addRelativeIndicator = true,
  includeSeconds,
}: DurationProps) {
  if (!from || !to) {
    return null;
  }
  const formatReadyFrom = formatReadyDate(from);
  const formatReadyTo = formatReadyDate(to);
  return formatDistance(formatReadyFrom, formatReadyTo, {
    includeSeconds,
    addSuffix: addRelativeIndicator,
    locale: enUS,
  });
}

/** Like `formatDuration`, but will always show unit */
export function formatDurationStrict({ from, to, unit, roundingMethod }: DurationStrictProps) {
  if (!from || !to) {
    return null;
  }
  const formatReadyFrom = formatReadyDate(from);
  const formatReadyTo = formatReadyDate(to);
  return formatDistanceStrict(formatReadyFrom, formatReadyTo, {
    unit,
    roundingMethod,
    locale: enUS,
  });
}

/** Standard i18n date format */
export const FormattedDate = memo(({ value }: BaseProps) => (
  <time>{format({ value, formatStyle: "P" })}</time>
));
FormattedDate.displayName = "FormattedDate";

export const FormattedDuration = memo((props: DurationProps) => <>{formatDuration(props)}</>);
FormattedDuration.displayName = "FormattedDuration";

export const CustomFormattedDateTime = memo((props: FormatParams) => <time>{format(props)}</time>);
CustomFormattedDateTime.displayName = "CustomFormattedDateTime";

/** Standard i18n long form date with time format */
export const LongFormattedDateTime = memo(
  ({ asTimeZone, value }: BaseProps & { asTimeZone?: string }) => (
    <time>{format({ value, asTimeZone, formatStyle: asTimeZone ? "Pp, z" : "Pp" })}</time>
  ),
);
LongFormattedDateTime.displayName = "LongFormattedDateTime";
