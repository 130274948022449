import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { userFullName } from "util/user";
import Icon from "common/core/icon";
import ProofLogo from "assets/images/logos/proof-logo-full-white.svg";

import Styles from "./card.module.scss";

type Props = {
  user: Parameters<typeof userFullName>[0];
  disabled?: boolean;
  className?: string;
  clickable?: boolean;
};

function barSize(columns: number) {
  return { gridColumnEnd: `span ${columns}` };
}

const BARS = (
  <div className={Styles.bars} aria-hidden="true">
    <div className={Styles.blackBar} style={barSize(7)} />
    <div className={Styles.greenBar} style={barSize(4)} />

    <div className={Styles.blackBar} style={barSize(4)} />
    <div className={Styles.greenBar} style={barSize(5)} />
    <div className={Styles.darkBlueBar} style={barSize(2)} />

    <div style={barSize(1)} />
    <div className={Styles.blueBar} style={barSize(2)} />
    <div className={Styles.darkBlueBar} style={barSize(2)} />
    <div style={barSize(2)} />
    <div className={Styles.blackBar} style={barSize(4)} />

    <div className={Styles.blueBar} style={barSize(2)} />
    <div style={barSize(3)} />
    <div className={Styles.darkGreenBar} style={barSize(4)} />
  </div>
);

function CertificateCard({ user, disabled, className, clickable }: Props) {
  return (
    <div
      className={classNames(
        Styles.main,
        disabled && Styles.grayScale,
        className,
        clickable && Styles.hoverEffect,
      )}
    >
      <div className={Styles.title}>
        <img src={ProofLogo} alt="Proof" />
      </div>
      {BARS}
      <div className={Styles.content}>
        <div className={Styles.name}>{userFullName(user)}</div>
        <div className={Styles.certified}>
          <span className={Styles.ial2Certified}>
            <FormattedMessage
              id="b713a15f-4cd9-4569-afef-9459d49a9025"
              defaultMessage="IAL2 Certified"
            />
          </span>
          <div className={Styles.cert}>
            <FormattedMessage
              id="385cca4d-0a35-4c92-84d8-088300008cf5"
              defaultMessage="Digital Certificate"
            />
          </div>
        </div>
        <Icon name="user-shield-fill" className={Styles.lock} />
      </div>
    </div>
  );
}

export default CertificateCard;
