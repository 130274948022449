import "common/form/deprecated_form.scss";

import PropTypes from "prop-types";
import { reduxForm, SubmissionError } from "redux-form";
import classnames from "classnames";

import compose from "util/compose";
import SaveButton from "common/core/save_button";
import { currentPassword as currentPasswordError } from "errors/account";
import { composeValidators } from "util/form";
import { useLogout } from "common/authentication";
import { useQuery, useMutation } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { AuthenticationTypes } from "graphql_globals";

import PasswordQuery from "./password_query.graphql";
import UpdatePassword from "./update_password_mutation.graphql";
import {
  DeprecatedUpdatePasswordFields,
  validationRules as passwordValidationRules,
} from "./update_password_fields";

function validate(values, props) {
  return composeValidators(passwordValidationRules(values, props))(values);
}

function PasswordForm(props) {
  const {
    viewer: { user },
    onSubmit,
    handleSubmit,
    reset,
    className,
  } = props;
  const logout = useLogout();
  const passwordSet = user.authenticationTypes.includes(AuthenticationTypes.PASSWORD);
  const ssoEnforced = !passwordSet && user.authenticationTypes.includes(AuthenticationTypes.SSO);
  const googleEnforced =
    !passwordSet && user.authenticationTypes.includes(AuthenticationTypes.GOOGLE_SIGN_IN);
  const UpdatePasswordMutation = useMutation(UpdatePassword);

  const save = ({ currentPassword, password }) => {
    onSubmit && onSubmit();

    return UpdatePasswordMutation({
      variables: {
        mutationInput: {
          currentPassword,
          password,
          logout: passwordSet,
        },
      },
    })
      .then((response) => {
        const { errors } = response.data.updatePassword;

        if (errors === null) {
          reset();

          if (passwordSet) {
            logout();
          } else {
            return Promise.resolve();
          }
        } else if (errors[0].attribute === "current_password") {
          return Promise.reject(
            new SubmissionError({
              currentPassword: currentPasswordError(),
            }),
          );
        }
      })
      .catch(() => {
        return Promise.reject(
          new SubmissionError({
            currentPassword: currentPasswordError(),
          }),
        );
      });
  };

  const formCx = classnames("Form", className);

  return (
    <section>
      <form
        className={formCx}
        onSubmit={handleSubmit(save)}
        data-automation-id="update-password-form"
      >
        <DeprecatedUpdatePasswordFields
          passwordSet={passwordSet}
          ssoEnforced={ssoEnforced}
          googleEnforced={googleEnforced}
        />
        {!(ssoEnforced || googleEnforced) && <SaveButton {...props} className="is-form" />}
      </form>
    </section>
  );
}

PasswordForm.displayName = "PasswordForm";

PasswordForm.propTypes = {
  viewer: PropTypes.object.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,

  // Redux Forms
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

/** @deprecated - please use components in common/core/form */
export const DeprecatedPasswordForm = compose(
  // Connect w/ redux-form
  reduxForm({
    form: "passwordForm",
    validate,
    initialValues: {
      password: "",
    },
  }),
)((props) => {
  const { data, loading } = useQuery(PasswordQuery);
  return loading ? <LoadingIndicator /> : <PasswordForm {...props} viewer={data.viewer} />;
});
