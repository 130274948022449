import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";

import { useFieldArray } from "common/core/form";
import { SubSectionHeader } from "common/transaction_creation/v3/common";
import {
  OrganizationTransactionContactRoleType,
  OrganizationTypeEnum,
  type MortgageTransactionType,
} from "graphql_globals";
import {
  type SectionContract,
  type SectionComponentProps,
  isPerTransactionPlaceOrderEnabled,
  isPlaceOrder,
} from "common/transaction_creation/v3/form";
import {
  canHaveNononboardedCollaborator,
  usersOrgCreatedTransaction,
} from "common/transaction_creation/v3/real_estate/util";
import { COLLABORATORS } from "constants/points_of_contact";
import type { Address } from "common/core/form/address";
import { usePermissions } from "common/core/current_user_role";
import { userCanEditContact } from "util/points_of_contact";

import { AddCardButton, AddCardButtonWrapper } from "../common";
import type {
  AdditionalContacts,
  AdditionalContacts_titleAgency as AdditionalContactsTitleAgency,
} from "./transaction_fragment.graphql";
import {
  COLLABORATOR_MESSAGES,
  CONTACT_MESSAGES,
  CollaboratorCard,
  ContactCard,
  MESSAGES,
} from "./cards";
import type { AdditionalContactsUser } from "./user_fragment.graphql";
import type { AdditionalContactsOrg } from "./organization_fragment.graphql";

const MAX_NUM_POINTS_OF_CONTACT = 50;

export const CONFIGS = {
  contactFirstName: "contactFirstName",
  contactLastName: "contactLastName",
  contactEmail: "contactEmail",
  contactPhoneNumber: "contactPhoneNumber",
  contactRole: "contactRole",
  collaboratorRole: "collaboratorRole",
  contactTitle: "contactTitle",
  contactOrganizationName: "contactOrganizationName",
  contactOrganizationAddress: "contactOrganizationAddress",
  titleAgency: "titleAgency",
  contactShownToSigner: "contactShownToSigner",
  contactAccessToTransaction: "contactAccessToTransaction",
  collaboratorShownToSigner: "collaboratorShownToSigner",
  collaboratorAccessToTransaction: "collaboratorAccessToTransaction",
  canAddCollaborators: "canAddCollaborators",
  hasDefaultContact: "hasDefaultContact",
  placeOrderCapable: "placeOrderCapable",
} as const;
export const FORM_FIELDS = {
  contacts: "contacts",
  titleAgencyId: "titleAgencyId",
  titleAgencyName: "titleAgencyName",
} as const;

function AdditionalContactsSection({
  config,
  form,
  organization,
  transaction,
  user,
}: SectionComponentProps<AdditionalContacts, AdditionalContactsOrg, AdditionalContactsUser>) {
  const intl = useIntl();
  const contactsFormName = FORM_FIELDS.contacts;
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: contactsFormName,
  });
  const { hasPermissionFor } = usePermissions();
  const canEditUnownedTransaction = hasPermissionFor("editUnownedTransaction");
  const hasCollaboratorOption = config.canAddCollaborators;
  const { contacts, organization: transactionOrganization, transactionType } = transaction;

  const defaultContact = {
    contactId: null,
    firstName: config.contactFirstName.default ?? "",
    lastName: config.contactLastName.default ?? "",
    email: config.contactEmail.default ?? "",
    phoneNumber: config.contactPhoneNumber.default ?? "",
    role: config.contactRole.default ?? null,
    title: config.contactTitle.default ?? "",
    organizationName: config.contactOrganizationName.default ?? "",
    organizationAddress: config.contactOrganizationAddress.default ?? null,
    shownToSigner: config.contactShownToSigner.default ?? false,
    accessToTransaction: config.contactAccessToTransaction.default ?? false,
    authorOrganizationId: "",
  };

  const defaultCollaborator = {
    ...defaultContact,
    role: config.collaboratorRole.default ?? null,
    shownToSigner: config.collaboratorShownToSigner.default ?? false,
    accessToTransaction: config.collaboratorAccessToTransaction.default ?? false,
  };

  useEffect(() => {
    // Ideally these contacts are added on transaction create. Move this when workflows comes along.
    if (config.hasDefaultContact) {
      // We only want to add the current title user as a point of contact if there are no
      // other points of contact already present.
      if (
        transactionOrganization.organizationType === OrganizationTypeEnum.TITLE_AGENCY &&
        usersOrgCreatedTransaction(transaction, organization) &&
        !hasPermissionFor("manageOpenOrders") &&
        !contacts.length
      ) {
        append({
          ...defaultContact,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: OrganizationTransactionContactRoleType.TITLE_AGENT,
        });
      }

      if (
        config.canAddCollaborators &&
        canHaveNononboardedCollaborator(
          organization.realEstateCollabEnabled,
          transactionType as MortgageTransactionType,
        )
      ) {
        const collabIndex = contacts.findIndex(({ role, email }) => {
          return COLLABORATORS.includes(role) && !!email;
        });

        if (collabIndex < 0) {
          append(defaultCollaborator);
        }
      }
    }
  }, []);

  useEffect(() => {
    // BIZ-5678: Make a utility function
    form.getValues(contactsFormName).forEach((formContact, contactIndex) => {
      const savedContact =
        contactIndex < transaction.contacts.length ? transaction.contacts[contactIndex] : null;
      if (!formContact.contactId && savedContact) {
        form.setValue(`${contactsFormName}.${contactIndex}.contactId`, savedContact.id);
      }
    });
  }, [transaction]);

  const contactOption = {
    iconName: "user-filled",
    title: intl.formatMessage(CONTACT_MESSAGES.title),
    description: intl.formatMessage(CONTACT_MESSAGES.description),
    onClick: () => append(defaultContact),
    "data-automation-id": "add-contact-option",
  };
  const addOptions = hasCollaboratorOption
    ? [
        {
          iconName: "briefcase",
          title: intl.formatMessage(COLLABORATOR_MESSAGES.title),
          description: intl.formatMessage(COLLABORATOR_MESSAGES.description),
          onClick: () => append(defaultCollaborator),
          "data-automation-id": "add-collaborator-option",
        },
        contactOption,
      ]
    : [contactOption];

  const addCardButton = (
    <AddCardButtonWrapper>
      <AddCardButton
        disabled={fields.length === MAX_NUM_POINTS_OF_CONTACT}
        options={addOptions}
        data-automation-id="add-contact-button"
        text={
          <FormattedMessage
            id="7158a47d-ee08-410e-8324-e7c1a850260f"
            defaultMessage="Add contact"
          />
        }
      />
    </AddCardButtonWrapper>
  );

  return (
    <>
      <SubSectionHeader
        title={intl.formatMessage(MESSAGES.title)}
        titleAction={fields.length === 0 ? addCardButton : null}
      />
      {hasCollaboratorOption
        ? fields.map((contact, index) => {
            // Ideally this happens in modifyConfig, but this is calculated per contact so can't calculate this
            // there until we come up with a better way to handle array fields
            const disabled = !userCanEditContact({
              contact: {
                ...contact,
                id: contact.contactId,
              },
              organization,
              canEditUnownedTransaction,
            })
              ? true
              : undefined;
            return contact.role && COLLABORATORS.includes(contact.role) ? (
              <CollaboratorCard
                key={contact.id}
                index={index}
                config={config}
                form={form}
                disabled={disabled}
                remove={index > 0 ? remove : undefined}
                transaction={transaction}
              />
            ) : (
              <ContactCard
                key={contact.id}
                index={index}
                collabEnabled
                config={config}
                form={form}
                disabled={disabled}
                remove={remove}
              />
            );
          })
        : fields.map((contact, index) => {
            const disabled = !userCanEditContact({
              contact: {
                ...contact,
                id: contact.contactId,
              },
              organization,
              canEditUnownedTransaction,
            })
              ? true
              : undefined;
            return (
              <ContactCard
                key={contact.id}
                index={index}
                collabEnabled={false}
                config={config}
                disabled={disabled}
                form={form}
                remove={remove}
              />
            );
          })}

      {fields.length > 0 && addCardButton}
    </>
  );
}

export type AdditionalContactsFormValues = {
  [FORM_FIELDS.contacts]: {
    contactId: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    role: OrganizationTransactionContactRoleType | null;
    title: string | null;
    organizationName: string | null;
    organizationAddress: Address | null;
    shownToSigner: boolean | null;
    accessToTransaction: boolean | null;
    authorOrganizationId: string | null;
  }[];
  [FORM_FIELDS.titleAgencyId]: AdditionalContactsTitleAgency["id"] | null;
  [FORM_FIELDS.titleAgencyName]: AdditionalContactsTitleAgency["name"];
};
type AdditionalContactsSubmitData = {
  contacts: {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    role: string | null;
    title: string | null;
    organizationName: string | null;
    organizationAddress: Address | null;
    shownToSigner: boolean | null;
    accessToTransaction: boolean | null;
  }[];
  titleAgencyOrgId: AdditionalContactsTitleAgency["id"] | null;
  titleAgencyName: AdditionalContactsTitleAgency["name"] | null;
};
export const ADDITIONAL_CONTACTS_SECTION = {
  Component: AdditionalContactsSection,
  configs: CONFIGS,
  modifyConfig({ sectionConfig, transaction, organization }) {
    const modifiedConfig = { ...sectionConfig };

    // Going forward we will just always add the user as a contact, instead of just PAO and collab
    // for now only do this behavior when place order _could_ be enabled
    // eventually this will apply even perTransactionPlaceOrderEnabled is false
    if (
      isPerTransactionPlaceOrderEnabled({ transaction, config: sectionConfig }) ||
      isPlaceOrder({ placeOrder: transaction.placeOrder, transaction, config: sectionConfig })
    ) {
      modifiedConfig.hasDefaultContact = true;
    }

    if (!organization.nonOnboardedTitleEnabled) {
      modifiedConfig.canAddCollaborators = false;
    }

    return modifiedConfig;
  },
  getDefaultFormValues(transaction) {
    return {
      contacts: transaction.contacts.map(
        ({
          id,
          firstName,
          lastName,
          email,
          phoneNumber,
          role,
          title,
          organizationName,
          organizationAddress,
          shownToSigner,
          accessToTransaction,
          authorOrganizationId,
        }) => ({
          contactId: id,
          firstName,
          lastName,
          email,
          phoneNumber,
          role,
          title,
          organizationName,
          organizationAddress: {
            line1: organizationAddress.line1,
            line2: organizationAddress.line2,
            city: organizationAddress.city,
            state: organizationAddress.state,
            postal: organizationAddress.postal,
            country: organizationAddress.country,
          },
          shownToSigner,
          accessToTransaction,
          authorOrganizationId,
        }),
      ),
      titleAgencyId: transaction.titleAgency?.id || null,
      titleAgencyName: transaction.titleAgency?.name || null,
    };
  },
  getSubmitData({ sectionFormValues }) {
    return {
      contacts: sectionFormValues.contacts.flatMap(
        ({
          contactId,
          firstName,
          lastName,
          email,
          phoneNumber,
          role,
          title,
          organizationName,
          organizationAddress,
          shownToSigner,
          accessToTransaction,
        }) => {
          // This accounts for the scenario in collab when a title agency is present and the first collaborator
          // isn't required. It's possible it's not filled out and we want to remove it from the data
          // Update how we handle this in BIZ-5812
          if ((!email && !phoneNumber) || !firstName || !lastName || !role) {
            return [];
          }

          return {
            id: contactId,
            firstName,
            lastName,
            email,
            phoneNumber,
            role,
            title,
            organizationName,
            organizationAddress,
            shownToSigner,
            accessToTransaction,
          };
        },
      ),
      titleAgencyOrgId: sectionFormValues.titleAgencyId,
      titleAgencyName: sectionFormValues.titleAgencyName,
    };
  },
} satisfies SectionContract<
  AdditionalContactsFormValues,
  AdditionalContactsSubmitData,
  AdditionalContacts,
  AdditionalContactsOrg,
  AdditionalContactsUser,
  typeof CONFIGS
>;
