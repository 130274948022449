import { tap, type OperatorFunction } from "rxjs";

import store from "redux/store";
import { browserReportContext, browserReportProperties } from "util/browser_report";
import { getCookie } from "util/cookie";
import { MARKETING_SESSION_ID_COOKIE_KEY } from "util/marketing_session";
import { readMarketingFlowType } from "common/analytics/signer_flow/util";

declare const window: Window & {
  analytics?: {
    initialized: boolean;
    track: typeof segmentTrack;
    identify: (
      uid: string,
      params?: UnknownObject,
      options?: UnknownObject,
      callback?: () => void,
    ) => void;
    page: (options?: UnknownObject, context?: UnknownObject) => void;
    reset: () => void;
    user?: () => { anonymousId: () => string };
  };
};

type UnknownObject = Record<string, unknown>;

function contextWithNoTrackIp(options: UnknownObject | undefined) {
  return {
    ...options,
    context: {
      ...(options?.context as UnknownObject | undefined),
      ...browserReportContext(),
      ip: "0.0.0.0",
    },
  };
}

function getAnalyticsContext() {
  return store.getState().analytics || null;
}

/**
 * @description flattens nested object/arrays into single object with complex keys
 * @example
   const result = flatten({ one: 1, two: { three: 3 }, four: [ 4, { five: 5 } ] });
   console.log(result); // { one: 1, 'two.three': 3, 'four.0': 4, 'four.1.five': 5 }
 */
function flatten(value: unknown): UnknownObject {
  if (value !== Object(value)) {
    return value as UnknownObject;
  }
  const flatObj: UnknownObject = {};
  function flattenInner(_value: unknown, prefix?: string) {
    if (_value === Object(_value)) {
      Object.keys(_value as UnknownObject).forEach((key) =>
        flattenInner((_value as UnknownObject)[key], prefix ? `${prefix}.${key}` : key),
      );
    } else {
      flatObj[prefix!] = _value;
    }
  }
  flattenInner(value);
  return flatObj;
}

export function segmentTrack(
  event: string,
  properties: UnknownObject = {},
  options?: UnknownObject,
  callback?: () => void,
): void {
  // Check initialized due to ad blockers allowing window.analytics to form but will prevent
  // calls completing and therefore won't execute callback
  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/troubleshooting/
  if (!window.analytics?.initialized) {
    callback?.();
    return;
  }
  return window.analytics.track(
    `${event} [Web]`,
    { ...flatten(properties), ...browserReportProperties(), ...getAnalyticsContext() },
    contextWithNoTrackIp(options),
    callback,
  );
}

export function segmentTrackAsync(
  event: string,
  properties?: UnknownObject,
  options?: UnknownObject,
): Promise<unknown> {
  return new Promise<void>((resolve) => segmentTrack(event, properties, options, resolve));
}

export function segmentIdentify(uid: string, traits?: UnknownObject, callback?: () => void): void {
  // Check initialized due to ad blockers allowing window.analytics to form but will prevent
  // calls completing and therefore won't execute callback
  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/troubleshooting/
  if (!window.analytics?.initialized) {
    callback?.();
    return;
  }
  window.analytics.identify(
    uid,
    { ...traits, ...browserReportProperties(), ...getAnalyticsContext() },
    {},
    callback,
  );
}

export function segmentIdentifyAsync(uid: string, traits?: UnknownObject): Promise<unknown> {
  return new Promise<void>((resolve) => segmentIdentify(uid, traits, resolve));
}

export function segmentTrackObservable<T>(
  eventName: string,
  propertiesFn: (value: T) => UnknownObject | undefined,
): OperatorFunction<T, T> {
  return (source) => source.pipe(tap((value) => segmentTrack(eventName, propertiesFn(value))));
}

export function segmentPage(options: UnknownObject = {}) {
  const flowType = readMarketingFlowType();
  window.analytics?.page(
    {
      marketingSessionID: getCookie(MARKETING_SESSION_ID_COOKIE_KEY),
      flow_type: flowType,
      ...options,
    },
    browserReportContext(),
  );
}

export function segmentReset() {
  window.analytics?.reset();
}

export function segmentAnonymousId() {
  return window.analytics?.user?.().anonymousId();
}

export function segmentAddAnonymousIdToUrl(urlString: string) {
  const anonymousId = segmentAnonymousId();

  // Adding the anonymous ID is only necessary when adding to a URL that's redirecting
  // to another portal
  if (urlString.includes("https://") && anonymousId) {
    const url = new URL(urlString);

    // Don't want to add twice
    if (url.searchParams.has("ajs_aid")) {
      return urlString;
    }

    url.searchParams.append("ajs_aid", anonymousId);
    return url.toString();
  }

  return urlString;
}
