import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { DownloadWarningModal } from "common/modals/download_warning_modal";
import type { useSaveAsTemplate } from "common/organization_templates/util";

import Styles from "./index.module.scss";

type Props = {
  shareAction?: () => void;
  downloadAction?: () => Promise<unknown>;
  uploadedAssetDownloadAction?: () => Promise<unknown>;
  isMultiDocument?: boolean;
  showDownloadWarning?: boolean;
  saveAsTemplate?: Omit<ReturnType<typeof useSaveAsTemplate>, "showSaveAsTemplate">;
};

export function DocumentBundleActions({
  isMultiDocument,
  shareAction,
  downloadAction,
  uploadedAssetDownloadAction,
  showDownloadWarning,
  saveAsTemplate,
}: Props) {
  const [downloading, setDownloading] = useState(false);
  const [downloadingOriginal, setDownloadingOriginal] = useState(false);
  const [downloadWarningModal, setDownloadWarningModal] = useState(false);
  if (!shareAction && !downloadAction && !uploadedAssetDownloadAction) {
    return null;
  }
  const onDownloadClick = (
    downloadActionHandler: () => Promise<unknown>,
    isOriginal: boolean = false,
  ) => {
    const setDownloadingState = isOriginal ? setDownloadingOriginal : setDownloading;
    setDownloadingState(true);
    segmentTrack(SEGMENT_EVENTS.SIGNER_ATTEMPTED_DOWNLOAD);
    downloadActionHandler()
      .then(() => {
        segmentTrack(SEGMENT_EVENTS.SIGNER_COMPLETED_DOWNLOAD);
      })
      .catch(() => {
        segmentTrack(SEGMENT_EVENTS.SIGNER_FAILED_DOWNLOAD);
      })
      .finally(() => {
        setDownloadingState(false);
      });
  };

  return (
    <div className={Styles.documentBundleActions}>
      {saveAsTemplate && (
        <Button
          buttonColor="action"
          variant="secondary"
          isLoading={saveAsTemplate.savingTemplate}
          onClick={saveAsTemplate.onSaveAsTemplateClick}
          automationId="save-doc-as-template"
        >
          <FormattedMessage
            id="0ff674e1-689d-47ff-9977-aebc226d29ee"
            defaultMessage="Save as template"
          />
        </Button>
      )}
      {uploadedAssetDownloadAction && (
        <Button
          buttonColor="action"
          variant="secondary"
          automationId="download-original-documents"
          withIcon={{ name: "arrow-down-square", placement: "left" }}
          disabled={downloadingOriginal}
          isLoading={downloadingOriginal}
          onClick={() => {
            showDownloadWarning
              ? setDownloadWarningModal(true)
              : onDownloadClick(uploadedAssetDownloadAction, true);
          }}
        >
          <FormattedMessage
            id="859e98f4-2dc8-4d57-8846-25646e144342"
            defaultMessage="Download {isMultiDocument, select, true{all original} other{original}}"
            values={{ isMultiDocument }}
          />
        </Button>
      )}
      {downloadAction && (
        <Button
          buttonColor="action"
          variant="secondary"
          automationId="download-all-documents"
          withIcon={{ name: "arrow-down-square", placement: "left" }}
          disabled={downloading}
          isLoading={downloading}
          onClick={() => {
            showDownloadWarning ? setDownloadWarningModal(true) : onDownloadClick(downloadAction);
          }}
        >
          <FormattedMessage
            id="3b5332a8-e649-4bed-95c5-393d2d9b3d25"
            defaultMessage="{isMultiDocument, select, true{Download all} other{Download}}"
            values={{ isMultiDocument: Boolean(isMultiDocument) }}
          />
        </Button>
      )}
      {shareAction && (
        <Button
          buttonColor="action"
          variant="primary"
          withIcon={{ name: "share", placement: "left" }}
          onClick={shareAction}
          automationId="send-by-email"
        >
          <FormattedMessage
            id="7426ba3d-9210-4cbf-a59e-c3a9d7be1380"
            defaultMessage="Share by email"
          />
        </Button>
      )}
      {downloadWarningModal && (
        <>
          {downloadAction && (
            <DownloadWarningModal
              onConfirm={() => {
                onDownloadClick(downloadAction);
                setDownloadWarningModal(false);
              }}
              onCancel={() => setDownloadWarningModal(false)}
            />
          )}
          {uploadedAssetDownloadAction && (
            <DownloadWarningModal
              onConfirm={() => {
                onDownloadClick(uploadedAssetDownloadAction);
                setDownloadWarningModal(false);
              }}
              onCancel={() => setDownloadWarningModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
}

export function DocumentBundleActionsContainer(props: Props) {
  return (
    <div className={Styles.container}>
      <DocumentBundleActions {...props} />
    </div>
  );
}
