import { createContext, useRef, useMemo, useContext, type ReactNode } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import classnames from "classnames";

import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import { userFullName, userInitials } from "util/user";

import Styles from "./closer.module.scss";

type IconProps = {
  closer: null | { id: string; firstName: string | null; lastName: string | null };
  notarizeCloserOverride: boolean;
};
type ColorStore = {
  lookup: Record<string, string | undefined>;
  currentColorIndex: number;
};
type Context = {
  getColorClassname: (closerUserId: string) => string;
};

const MESSAGES = defineMessages({
  buttonLabel: {
    id: "036fb3ca-972c-40fe-a6e3-2fe33275e10b",
    defaultMessage: "{notarizeCloserOverride, select, true{Notarize Network } other{}}Closer",
  },
});
const NUM_COLORS = 6;
const ColorContext = createContext<Context>({ getColorClassname: () => "" });

/**
 * This context publishes a getColorClassname for `<CloserIcon />` to get a consistent,
 * round-robined, color per closer userId.
 */
export function CloserColorsContext({ children }: { children: ReactNode }) {
  const colorsStoreRef = useRef<ColorStore>({ lookup: {}, currentColorIndex: 0 });
  const colors = useMemo(
    () =>
      Object.freeze({
        getColorClassname: (closerUserId: string) => {
          const { current } = colorsStoreRef;
          const storedColor = current.lookup[closerUserId];
          if (storedColor) {
            return storedColor;
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          const newColor = Styles[`closer${current.currentColorIndex}` as keyof typeof Styles];
          current.lookup[closerUserId] = newColor;
          current.currentColorIndex += 1;
          if (current.currentColorIndex >= NUM_COLORS) {
            current.currentColorIndex = 0;
          }
          return newColor;
        },
      }),
    [],
  );
  return <ColorContext.Provider value={colors}>{children}</ColorContext.Provider>;
}

export function CloserIcon({ closer, notarizeCloserOverride }: IconProps) {
  const context = useContext(ColorContext);
  const intl = useIntl();
  const cx = notarizeCloserOverride
    ? Styles.closerNotarize
    : closer
      ? context.getColorClassname(closer.id)
      : Styles.closerUnassigned;
  return (
    <Tooltip
      placement="top"
      containerClassName={Styles.tooltip}
      targetClassName={classnames(Styles.icon, cx)}
      triggerButtonLabel={intl.formatMessage(MESSAGES.buttonLabel, { notarizeCloserOverride })}
      target={
        notarizeCloserOverride ? (
          <Icon name="notarize-line" />
        ) : closer ? (
          userInitials(closer)
        ) : (
          <Icon name="name" />
        )
      }
    >
      {notarizeCloserOverride ? (
        "Notarize Network"
      ) : closer ? (
        userFullName(closer)
      ) : (
        <FormattedMessage id="6abf3659-ccea-4ff8-892a-6229330362d9" defaultMessage="Unassigned" />
      )}
    </Tooltip>
  );
}
