export const CHARGE_ITEM_LABELS = {
  additional_identity_confirmation: "Additional Identity Confirmation Fee",
  additional_seal: "Additional Seal Fee",
  additional_signer: "Additional Signer Fee",
  esigned_doc: "Additional Sign Fee",
  first_identity_confirmation: "Base Price",
  first_seal: "Base Price",
  esigned_bundle: "Base Price",
  remote_odn_witness: "Notarize Network Witness",
} as const;
