import "./index.scss";

import { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import { captureException } from "util/exception";
import Card from "common/card";
import Button from "common/core/button";
import Link from "common/core/link";
import { usePermissions } from "common/core/current_user_role";
import { Mutation } from "util/graphql/mutation";
import { QueryWithLoading } from "util/graphql/query";
import { userFullName } from "util/user";
import { ACCESS_LEVEL, ACCESS_LEVEL_MESSAGES, apiKeyDocumentationUrl } from "constants/api";
import { useActiveOrganization } from "common/account/active_organization";
import { isNotaryNST, isNotaryODN } from "common/notary/capacity";
import { IconButton } from "common/core/button/icon_button";

import { ApiSettingsTable, ApiSettingsTableRow, ApiSettingsTableCell } from "./table";
import ApiSettingsValue from "./value";
import ApiSettingsRevokeKeyModal from "./revoke_key_modal";
import ApiSettingsErrorModal from "./error_modal";
import ApiSettingsUpdateKey from "./update_key";
import GENERATE_API_KEY_MUTATION from "./generate_api_key_mutation.graphql";
import REVOKE_API_KEY_MUTATION from "./revoke_api_key_mutation.graphql";
import ORGANIZATION_QUERY from "./organization_query.graphql";

const CX = "ApiSettings";

class ApiSettings extends PureComponent {
  state = {
    loading: null,
    keyToRevoke: null,
    keyToUpdate: null,
    error: false,
  };

  async generateKey(accessLevel) {
    const { generateApiKey, organization } = this.props;

    this.setState({ loading: accessLevel });

    try {
      await generateApiKey({
        variables: {
          mutationInput: {
            id: organization.id,
            accessLevel,
          },
        },
      });
    } catch (error) {
      captureException(error);
      this.setState({ error: true });
    }

    this.setState({ loading: null });
  }

  async deleteKey(value) {
    const { revokeApiKey } = this.props;

    this.setState(({ keyToRevoke }) => ({ loading: keyToRevoke }));

    try {
      await revokeApiKey({
        variables: {
          mutationInput: {
            value,
          },
        },
      });

      this.setState({ keyToRevoke: null });
    } catch (error) {
      captureException(error);
      this.setState({ error: true });
    }

    this.setState({ loading: null });
  }

  render() {
    const { organization, intl, viewer, hasPermissionFor } = this.props;
    const { loading, keyToRevoke, keyToUpdate, error } = this.state;

    if (keyToUpdate) {
      return (
        <ApiSettingsUpdateKey
          apiKey={keyToUpdate}
          organization={organization}
          onCancel={() => this.setState({ keyToUpdate: null })}
          isNSTAndODN={
            isNotaryNST(viewer.user.notaryProfile) && isNotaryODN(viewer.user.notaryProfile)
          }
        />
      );
    }

    const title = (
      <FormattedMessage id="fd753440-4268-4c4a-ade9-3568c0a88ec2" defaultMessage="API Keys" />
    );
    const apiLinkText = (
      <FormattedMessage
        id="47dddeef-5200-4aa5-91f3-c0be242626fe"
        defaultMessage="API documentation"
      />
    );
    const apiLink = <Link href={apiKeyDocumentationUrl}>{apiLinkText}</Link>;

    return (
      <Card horizontallyCentered={false} className={CX} title={title} noPadding>
        <div className={`${CX}--description`}>
          <div className={`${CX}--buttons`}>
            {hasPermissionFor("createOrganizationApiKeys") && (
              <>
                <Button
                  className={`${CX}--button`}
                  onClick={() => this.generateKey(ACCESS_LEVEL.FULL)}
                  disabled={Boolean(loading)}
                  isLoading={loading === ACCESS_LEVEL.FULL}
                  automationId="generate-full-api-key"
                  buttonColor="action"
                  variant="secondary"
                >
                  <FormattedMessage
                    id="fb54bd26-b921-49f9-8aa9-a1f8feb0b27f"
                    defaultMessage="Generate Full Access Key"
                  />
                </Button>
                <Button
                  className={`${CX}--button`}
                  onClick={() => this.generateKey(ACCESS_LEVEL.CLIENT_ONLY)}
                  disabled={Boolean(loading)}
                  isLoading={loading === ACCESS_LEVEL.CLIENT_ONLY}
                  automationId="generate-client-api-key"
                  buttonColor="action"
                  variant="secondary"
                >
                  <FormattedMessage
                    id="7a6d8717-72e7-43e5-8209-8e9d2f039ddc"
                    defaultMessage="Generate Client Only Key"
                  />
                </Button>
              </>
            )}
            <p>
              <FormattedMessage
                id="ef4afc27-1713-4b76-a213-96ac1c6847a1"
                defaultMessage="A 'Full Access' key lets you use all Business API endpoints, whereas 'Client Only' key only lets you create transactions, and is meant for public use."
              />
            </p>
            <p>
              <FormattedMessage
                id="4412e30e-5e17-415c-ad3a-7888ca5d75ca"
                defaultMessage="Learn about API keys in our {apiLink}."
                values={{ apiLink }}
              />
            </p>
          </div>
        </div>
        {organization.apiKeys && organization.apiKeys.length > 0 && (
          <ApiSettingsTable className={`${CX}--table`}>
            <ApiSettingsTableRow isHeader>
              <ApiSettingsTableCell>
                <FormattedMessage id="91ea0a0e-996d-412e-a9c0-b3c2d9886e64" defaultMessage="Name" />
              </ApiSettingsTableCell>
              <ApiSettingsTableCell>
                <FormattedMessage
                  id="ab9ee842-2585-410c-bd8f-4c5b759f8de6"
                  defaultMessage="Value"
                />
              </ApiSettingsTableCell>
              <ApiSettingsTableCell>
                <FormattedMessage
                  id="f6d8048a-57ed-49d0-bf77-f1051f32c144"
                  defaultMessage="Access Level"
                />
              </ApiSettingsTableCell>
              <ApiSettingsTableCell>
                <FormattedMessage
                  id="354dccfc-c6ac-4860-b2e8-42b528c8db85"
                  defaultMessage="Created"
                />
              </ApiSettingsTableCell>
              <ApiSettingsTableCell />
            </ApiSettingsTableRow>
            {organization.apiKeys.map((apiKey) => {
              const creatorName = userFullName(apiKey.user);
              const cellIconCx = classnames(`${CX}--icon`, {
                [`${CX}--icon__disabled`]: loading,
              });
              const deleteCellIconCx = classnames(cellIconCx, `${CX}--icon__alert`);
              return (
                <ApiSettingsTableRow key={apiKey.value}>
                  <ApiSettingsTableCell className={`${CX}--name-cell`}>
                    {apiKey.name || (
                      <FormattedMessage
                        id="26d5f32c-0b8d-4d67-93ff-f5a1dfb09ae9"
                        defaultMessage="Not provided"
                      />
                    )}
                  </ApiSettingsTableCell>
                  <ApiSettingsTableCell className={`${CX}--value-cell`}>
                    <ApiSettingsValue value={apiKey.value} />
                  </ApiSettingsTableCell>
                  <ApiSettingsTableCell automationId="api-key-access-level">
                    {intl.formatMessage(ACCESS_LEVEL_MESSAGES[apiKey.accessLevel])}
                  </ApiSettingsTableCell>
                  <ApiSettingsTableCell>
                    {apiKey.createdAt &&
                      new Date(apiKey.createdAt).toLocaleString(undefined, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    {creatorName && (
                      <p className={`${CX}--sub-text`}>
                        <FormattedMessage
                          id="2e7167bb-1f58-4be9-b3ed-4ab358a1fd2c"
                          defaultMessage="by {creatorName}"
                          values={{ creatorName }}
                        />
                      </p>
                    )}
                  </ApiSettingsTableCell>
                  <ApiSettingsTableCell rightAlign>
                    {hasPermissionFor("editOrganizationApiKeys") && (
                      <IconButton
                        className={cellIconCx}
                        name="settings-filled"
                        onClick={() => !loading && this.setState({ keyToUpdate: apiKey })}
                        automationId="api-key-settings"
                        variant="tertiary"
                        buttonColor="dark"
                        buttonSize="condensed"
                        label={intl.formatMessage(ACCESS_LEVEL_MESSAGES.UPDATE_KEY)}
                      />
                    )}
                    {hasPermissionFor("deleteOrganizationApiKeys") && (
                      <IconButton
                        className={deleteCellIconCx}
                        name="delete"
                        onClick={() => !loading && this.setState({ keyToRevoke: apiKey.value })}
                        automationId="api-key-delete"
                        variant="tertiary"
                        buttonColor="dark"
                        buttonSize="condensed"
                        label={intl.formatMessage(ACCESS_LEVEL_MESSAGES.DELETE_KEY)}
                      />
                    )}
                  </ApiSettingsTableCell>
                </ApiSettingsTableRow>
              );
            })}
          </ApiSettingsTable>
        )}

        {keyToRevoke && (
          <ApiSettingsRevokeKeyModal
            onRevoke={() => this.deleteKey(keyToRevoke)}
            onCancel={() => this.setState({ keyToRevoke: null })}
            isRevoking={loading === keyToRevoke}
          />
        )}

        {error && (
          <ApiSettingsErrorModal
            onClose={() => this.setState({ error: false })}
            message={
              keyToRevoke ? (
                <FormattedMessage
                  id="ce68f5ef-074e-4b86-8771-388de82df001"
                  defaultMessage="Failed to revoke api key."
                />
              ) : (
                <FormattedMessage
                  id="6ef58cec-2eb8-4f90-abd3-692f95fbe661"
                  defaultMessage="Failed to generate api key."
                />
              )
            }
          />
        )}
      </Card>
    );
  }
}

ApiSettings.propTypes = {
  generateApiKey: PropTypes.func.isRequired,
  revokeApiKey: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    defaultPayer: PropTypes.string,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  hasPermissionFor: PropTypes.func.isRequired,
};

function ApiSettingsContainer(props) {
  const [activeOrganizationId] = useActiveOrganization();
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  return (
    <Mutation mutation={GENERATE_API_KEY_MUTATION}>
      {(generateApiKey) => (
        <Mutation mutation={REVOKE_API_KEY_MUTATION}>
          {(revokeApiKey) => (
            <QueryWithLoading
              query={ORGANIZATION_QUERY}
              variables={{ organizationId: activeOrganizationId }}
            >
              {(query) => (
                <ApiSettings
                  {...props}
                  intl={intl}
                  generateApiKey={generateApiKey}
                  revokeApiKey={revokeApiKey}
                  organization={query.data.node}
                  viewer={query.data.viewer}
                  hasPermissionFor={hasPermissionFor}
                />
              )}
            </QueryWithLoading>
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

export default ApiSettingsContainer;
