import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import NotaryMeetingTime from "common/details/meeting/notary_details/items/notary_meeting_time";
import SigningScheduleType from "common/details/meeting/notary_details/items/signing_schedule_type";
import { Card, CardHeading } from "common/core/card";
import { DescriptionList } from "common/core/description_list";

import TransactionExpiryForm from "./transaction_expiry_form";
import TransactionActivationForm from "./transaction_activation_form";
import type { TransactionForTransactionDetailsAdminDateTime } from "./index_fragment.graphql";

type Props = {
  transaction: TransactionForTransactionDetailsAdminDateTime;
  onSubmitActivationForm: ComponentProps<typeof TransactionActivationForm>["onSubmitForm"];
  onSubmitExpiryForm: ComponentProps<typeof TransactionExpiryForm>["onSubmitForm"];
  direction?: ComponentProps<typeof DescriptionList>["direction"];
};

function AdminTransactionDetailsDateTimes(props: Props) {
  const { transaction, direction } = props;

  if (
    (!transaction.activationTime && !transaction.expiry) ||
    (!transaction.signingScheduleType && !transaction.notaryMeetingTime)
  ) {
    return null;
  }

  return (
    <Card
      header={
        <CardHeading level="h2">
          <FormattedMessage id="64b1b80d-ae0a-48c2-a15b-6eb944381c0b" defaultMessage="Schedule" />
        </CardHeading>
      }
      noMargin
      fullWidth
    >
      <DescriptionList direction={direction} itemGap="small">
        <SigningScheduleType transaction={transaction} />
        <TransactionActivationForm
          transaction={transaction}
          onSubmitForm={props.onSubmitActivationForm}
        />
        <TransactionExpiryForm transaction={transaction} onSubmitForm={props.onSubmitExpiryForm} />
        <NotaryMeetingTime transaction={transaction} />
      </DescriptionList>
    </Card>
  );
}

export default AdminTransactionDetailsDateTimes;
