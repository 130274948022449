import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import {
  getTransactionTypeFromParam,
  useCreateRealEstateTransaction,
  type Organization,
} from "common/transactions/create/hooks/use-create-real-estate-transaction";
import { AuthTypes, ProofRequirementMfa } from "graphql_globals";

export function getProofRequirement({
  defaultAuthenticationRequirement,
  defaultVerifiedEsignEnabled,
}: Pick<Organization, "defaultAuthenticationRequirement" | "defaultVerifiedEsignEnabled">) {
  // Respect the default auth requirement for MfA/KBA
  return {
    ca: null,
    mfa:
      defaultAuthenticationRequirement === AuthTypes.SMS ? { type: ProofRequirementMfa.SMS } : null,
    kba: defaultVerifiedEsignEnabled,
  };
}

function NewTransaction() {
  const [searchParams] = useSearchParams();
  const hasCreatedTransaction = useRef(false);
  const transactionType = getTransactionTypeFromParam(searchParams.get("type"));

  const { createTransaction, loading } = useCreateRealEstateTransaction({
    getErrorRoute: () => "/",
  });

  useEffect(() => {
    if (!loading && !hasCreatedTransaction.current) {
      createTransaction({ transactionType });
      hasCreatedTransaction.current = true;
    }
  }, [loading, createTransaction, transactionType]);

  return <LoadingIndicator />;
}

export default NewTransaction;
