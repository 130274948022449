import type { ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useParams } from "react-router-dom";

import Tab from "common/core/tabs/tab";
import { SettingsHeader, SettingsPageWrapper } from "common/settingsv2/common";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";

import OrganizationQuery, {
  type AdminCompanyDetailsOrganization_organization_Organization as Organization,
} from "./organization_query.graphql";

const MESSAGES = defineMessages({
  title: {
    id: "281d0933-0d26-4029-b940-38f15f5ea9ba",
    defaultMessage: "Pricing & billing",
  },
});

export default function AdminCompanyDetailsPricingAndBilling({
  children,
}: {
  children: ({ organization }: { organization: Organization }) => ReactNode;
}) {
  const organizationId = useParams().globalID!;
  const intl = useIntl();

  const { data, loading } = useQuery(OrganizationQuery, {
    variables: { organizationId },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data!.organization;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }
  const showCompanyBillingAndPricing =
    organization.id === organization.rootOrganizationId &&
    organization.companyBillingAndPricingEnabled;

  return (
    <SettingsPageWrapper>
      <SettingsHeader
        title={intl.formatMessage(MESSAGES.title)}
        tabs={
          <>
            {showCompanyBillingAndPricing && (
              <Tab to={`/companies/${organizationId}/pricing-and-billing/company`}>
                <FormattedMessage
                  id="ee184ae0-1439-4638-827d-db5c1310c820"
                  defaultMessage="Company billing and pricing"
                />
              </Tab>
            )}
            <Tab to={`/companies/${organizationId}/pricing-and-billing/pricing`}>
              <FormattedMessage
                id="0ec7de77-8311-49a6-a70a-50a8b6410ef0"
                defaultMessage="Pricing"
              />
            </Tab>
            <Tab to={`/companies/${organizationId}/pricing-and-billing/billing`}>
              <FormattedMessage
                id="b1fdad65-74a0-4e47-be10-d5eae4bedfeb"
                defaultMessage="Billing"
              />
            </Tab>
            {organization.companyBillingAndPricingEnabled && (
              <Tab to={`/companies/${organizationId}/pricing-and-billing/payer-settings`}>
                <FormattedMessage
                  id="bc512e32-ce2a-4def-965f-67cf4115f43f"
                  defaultMessage="Payer settings"
                />
              </Tab>
            )}
            <Tab to={`/companies/${organizationId}/pricing-and-billing/recent-payments`}>
              <FormattedMessage
                id="088927d7-3e8b-4d5c-aff7-411a5ba5489a"
                defaultMessage="Recent Payments"
              />
            </Tab>
          </>
        }
      />
      {children({ organization })}
    </SettingsPageWrapper>
  );
}
