import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { Heading } from "common/core/typography";
import { useMatchScreenClass, Visible } from "common/core/responsive";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { b } from "util/html";
import Link from "common/core/link";
import type { RetakeManager } from "common/identity_verification/retake/notary";

import Styles from "./index.module.scss";
import { useTrustedRefereeActionManagerContext } from "../../action_manager";

export default function IdentityProfile({ retakeManager }: { retakeManager: RetakeManager }) {
  const {
    identityAttributes: { unresolvedCount, resolvedCount, status },
    meetingCompletion: { caPassed },
    timeline,
  } = useTrustedRefereeActionManagerContext();

  const totalAttributes = resolvedCount + unresolvedCount;
  const largeScreen = useMatchScreenClass("xs", "sm", "md", "lg");

  return (
    <div className={Styles.container}>
      <Visible xl xxl xxxl>
        <Heading level="h2" textStyle="subtitleSmall">
          <FormattedMessage
            id="c7abdd96-534d-42a3-b5fa-cc08a589593f"
            defaultMessage="Identity profile"
          />
        </Heading>
      </Visible>

      <div>
        <div
          className={classnames(Styles.status, {
            [Styles.success]: status === "validated",
            [Styles.warn]: status === "invalid",
          })}
        >
          <Heading level="h1" textStyle="headingThree">
            {status === "incomplete" && (
              <FormattedMessage
                id="02d3d04e-31e1-4d2d-ab19-72ad97b25785"
                defaultMessage="Incomplete"
              />
            )}
            {status === "invalid" && (
              <FormattedMessage
                id="3039c10f-bd2b-4c3d-9283-b08d7be4b9fb"
                defaultMessage="Invalid"
              />
            )}
            {status === "validated" && (
              <FormattedMessage
                id="08a808e3-5beb-4d7a-a66c-f5007b01fc00"
                defaultMessage="Validated"
              />
            )}
          </Heading>
          <span>
            <FormattedMessage
              id="ac5b3b25-6c07-4e1a-a037-dfb66e8fbbc8"
              defaultMessage="{complete}/{total} complete"
              values={{ complete: resolvedCount, total: totalAttributes }}
            />
          </span>
        </div>
        <div
          className={classnames(Styles.progress, {
            [Styles.warn]: status === "invalid",
            [Styles.success]: status === "validated",
          })}
        >
          <div style={{ width: `${(resolvedCount / totalAttributes) * 100}%` }} />
        </div>
        {!caPassed && !largeScreen && (
          <div className={Styles.fraudSignal}>
            <AlertMessage kind="warning">
              <FormattedMessage
                id="da0e22d2-13db-4046-898b-b29edf2f09ec"
                defaultMessage="<b>An identity alert exists.</b> A mobile retake is required. <timelineLink>View in timeline.</timelineLink>"
                values={{
                  b,
                  timelineLink: (text) => (
                    <Link onClick={timeline.scrollToLatestFraudSignal}>{text}</Link>
                  ),
                }}
                tagName="span"
              />
            </AlertMessage>
            <Button
              buttonColor="action"
              variant="primary"
              fullwidth
              onClick={retakeManager.initiate}
              isLoading={retakeManager.inProgress}
            >
              <FormattedMessage
                id="f08e63f8-45b8-44dd-86e5-d36bb6b76c8f"
                defaultMessage="Prompt retake"
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
