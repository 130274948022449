import "./index.scss";

import type { ReactNode } from "react";
import classnames from "classnames";

import Icon from "common/core/icon";
import ClickOutside from "common/core/click_outside";
import { DeprecatedCheckbox } from "common/form/inputs/checkbox";
import ActionButton from "common/core/action_button";
import { useEscapeKey } from "util/keyboard_navigation";

type Props = {
  popup?: ReactNode;
  hasSelection: boolean;
  unselectedLabel: ReactNode;
  label: ReactNode;
  onSelectAll?: () => void;
  children: ReactNode;
  disabled?: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  "data-automation-id"?: string;
  className?: string;
};

type OptionProps = {
  selected: boolean;
  onChange: () => void;
  children: ReactNode;
  disabled?: boolean;
  largeRow?: boolean;
  "data-automation-id"?: string;
  "data-pendo-id"?: string;
};

type GroupProps = {
  label: ReactNode;
  onChange: () => void;
  selected: boolean;
  children?: ReactNode;
  disabled?: boolean;
};

type FilterDropdownTargetProps = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
  hasSearch?: boolean;
  hasSelection: boolean;
  onClear?: () => void;
  "data-automation-id"?: string;
  className?: string;
};

const noop = () => {};

function FilterDropdownTarget({
  isOpen,
  setIsOpen,
  disabled,
  children,
  label,
  hasSearch,
  onClear,
  hasSelection,
  "data-automation-id": dataAutomationId,
  className,
}: FilterDropdownTargetProps) {
  return (
    <ClickOutside onClickOutside={() => setIsOpen(false)}>
      <button
        type="button"
        className={classnames(className, "FilterDropdown", {
          "FilterDropdown--search": hasSearch,
          open: isOpen,
          disabled,
        })}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen ? "true" : "false"}
        aria-haspopup="true"
        data-automation-id={hasSearch ? "search-filter-dropdown" : dataAutomationId}
      >
        <div className="FilterDropdown--inner">
          <div className="FilterDropdown--inner--label">{label}</div>
          {onClear && (
            <div className={`FilterDropdown--inner--clear ${hasSelection ? "has-selection" : ""}`}>
              {hasSelection && (
                <ActionButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                  color="subtle"
                >
                  <Icon name="x-mark-circle" />
                </ActionButton>
              )}
            </div>
          )}
          <div className="FilterDropdown--inner--expander"></div>
        </div>
        {isOpen && children}
      </button>
    </ClickOutside>
  );
}

export function FilterGroup({ label, onChange, selected, disabled, children }: GroupProps) {
  return (
    <div className={classnames("FilterGroup", { disabled })}>
      <header
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) {
            return;
          }
          onChange();
        }}
        data-automation-id="filter-group-header"
      >
        <div className="FilterGroup--check">
          <DeprecatedCheckbox checked={selected} onChange={noop} />
        </div>
        {label}
      </header>

      <div className="FilterGroup--inner">{children}</div>
    </div>
  );
}

export function FilterOption({
  selected,
  onChange,
  disabled,
  children,
  "data-automation-id": dataAutomationId,
  "data-pendo-id": dataPendoId,
}: OptionProps) {
  return (
    <ActionButton
      color="dark"
      role="menuitem"
      className={classnames("FilterOption", { selected })}
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) {
          return;
        }
        onChange();
      }}
      automationId={dataAutomationId}
      data-pendo-id={dataPendoId}
    >
      <div className="FilterOption--check">{selected && <Icon name="tick" />}</div>
      {children}
    </ActionButton>
  );
}

export function FilterMultiOption({
  selected,
  onChange,
  disabled,
  children,
  largeRow,
}: OptionProps) {
  return (
    <div
      className={classnames("FilterMultiOption", { disabled, largeRow })}
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) {
          return;
        }
        onChange();
      }}
      data-automation-id="filter-multi-option"
    >
      <div className="FilterMultiOption--check">
        <DeprecatedCheckbox checked={selected} onChange={noop} />
      </div>
      {children}
    </div>
  );
}

export function FilterDropdown({
  isOpen,
  setIsOpen,
  hasSelection,
  unselectedLabel,
  label,
  onSelectAll,
  disabled,
  children,
  popup,
  "data-automation-id": dataAutomationId,
  className,
}: Props) {
  const close = () => setIsOpen(false);
  useEscapeKey(close, isOpen);

  return (
    <FilterDropdownTarget
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClear={onSelectAll}
      label={hasSelection ? label : unselectedLabel}
      disabled={disabled}
      hasSelection={hasSelection}
      data-automation-id={dataAutomationId}
      className={className}
    >
      {!popup ? (
        <div role="menu" className="FilterDropdown--dropdown" onClick={(e) => e.stopPropagation()}>
          <div className="FilterDropdown--options-container">{children}</div>
        </div>
      ) : (
        <div className="FilterDropdown--popup" onClick={(e) => e.stopPropagation()}>
          {popup}
        </div>
      )}
    </FilterDropdownTarget>
  );
}
