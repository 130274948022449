import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  timer,
  combineLatest,
  map,
  tap,
  distinctUntilChanged,
  startWith,
  skip,
  timestamp,
} from "rxjs";

import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { fromSocketEvent } from "socket/util";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import type Channel from "socket/channel";

import TriggerSignerCallbackMutation from "../trigger_signer_callback_mutation.graphql";

type ConnectionState =
  | "None"
  | "Disconnected_Callback"
  | "Disconnected_Time"
  | "Connecting"
  | "Connected";
type CallbackState = "None" | "Joining" | "Cancelled";
type MeetingPromptStatusMessage = {
  meeting_prompt_call: {
    call_status: string;
  };
};

const TEST_INTERVAL = 6_000;
const ELAPSE_UNTIL_CONNECTED = 8_000;
const ELAPSE_UNTIL_DISCONNECTED = 120_000;

export default function useSignerConnectionState(
  channel: Channel,
  simulated: boolean,
  meetingId: string,
  meetingPlatform: string | null,
): ConnectionState {
  const [connectionState, setConnectionState] = useState<ConnectionState>("Connecting");
  const triggerSignerCallbackMutateFn = useMutation(TriggerSignerCallbackMutation);
  const messageLimitRef = useRef(0);

  // this effect handles calling the trigger callback mutation
  useEffect(() => {
    const timer = setTimeout(() => {
      triggerSignerCallbackMutateFn({
        variables: { input: { meetingId } },
      })
        .then(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.MEETING,
            message: (
              <FormattedMessage
                id="16095651-1222-4ad7-91cf-32c445cc4e20"
                defaultMessage="Sending the signer a reminder call"
              />
            ),
            duration: 10_000,
          });
        })
        .catch((error) => {
          const callbackNumberError =
            isGraphQLError(error) && error.graphQLErrors[0].message === "missing_callback_number";
          if (!callbackNumberError) {
            captureException(error);
          }
        });
    }, 15_000);
    return () => clearTimeout(timer);
  }, [meetingId, meetingPlatform]);

  // this effect synthesizes callback and socket connection states
  // to produce a value of type ConnectionState
  useEffect(() => {
    if (simulated) {
      setConnectionState("Connected");
      return;
    }
    const timeStampedDeviceStatus$ = fromSocketEvent(channel, "device_status").pipe(
      map(() => Date.now()),
      startWith(Date.now() - ELAPSE_UNTIL_CONNECTED),
    );
    const timeStampedCallbackStatus$ = fromSocketEvent<MeetingPromptStatusMessage>(
      channel,
      "meeting.prompt_call_status_change",
    ).pipe(
      tap((message) => {
        if (message.meeting_prompt_call.call_status === "SIGNER_CONFIRMED_JOINING") {
          pushNotification({
            type: NOTIFICATION_TYPES.MEETING,
            message: (
              <FormattedMessage
                id="ab1edfd0-187f-4cd9-b382-2c4a8f2c0be0"
                defaultMessage="Signer has indicated they are joining the meeting"
              />
            ),
          });
        }
      }),
      map<MeetingPromptStatusMessage, CallbackState>((message) => {
        switch (message.meeting_prompt_call.call_status) {
          case "SIGNER_CONFIRMED_JOINING":
            return "Joining";
          case "SIGNER_CONFIRMED_CANCELING":
            return "Cancelled";
          default:
            return "None";
        }
      }),
      startWith("None" as const),
      timestamp<CallbackState>(),
    );

    const connectionState$ = combineLatest([
      timer(0, TEST_INTERVAL),
      timeStampedDeviceStatus$,
      timeStampedCallbackStatus$,
    ]).pipe(
      map(([, deviceStatusTimeStamp, callbackStatus]) => {
        const deviceElapsed = Date.now() - deviceStatusTimeStamp;
        const elapsedTimeUntilDisconnected =
          callbackStatus.value === "Joining" && callbackStatus.timestamp > deviceStatusTimeStamp
            ? 300_000
            : ELAPSE_UNTIL_DISCONNECTED;
        // we need to check if we're on web because a signer can connect to the websocket
        // but not be physically present at their computer. otherwise, we only care about
        // the cancelled callback state if it is more recent than the device state
        if (callbackStatus.value === "Cancelled" && meetingPlatform === "WEB") {
          return "Disconnected_Callback";
        } else if (deviceElapsed > elapsedTimeUntilDisconnected) {
          return "Disconnected_Time";
        } else if (deviceElapsed > ELAPSE_UNTIL_CONNECTED) {
          return "Connecting";
        }
        return "Connected";
      }),
      distinctUntilChanged(),
      // we skip here because the seeded values (startWith)
      // default to a connection state of "connecting", and we don't want to
      // update this connection state until it changes away from this
      skip(1),
    );

    const sub = connectionState$.subscribe((state) => {
      setConnectionState(state);
      if (messageLimitRef.current >= 3) {
        return;
      }
      switch (state) {
        case "Connected": {
          messageLimitRef.current += 1;
          return pushNotification({
            type: NOTIFICATION_TYPES.MEETING,
            message: (
              <FormattedMessage
                id="d3e66af8-8aac-44d5-9e58-0e216df917ca"
                defaultMessage="Signer connected!"
              />
            ),
          });
        }
        case "Connecting": {
          messageLimitRef.current += 1;
          return pushNotification({
            type: NOTIFICATION_TYPES.MEETING,
            message: (
              <FormattedMessage
                id="8f334bcf-4b40-4990-b802-7a3b89cf20b5"
                defaultMessage="Trying to reconnect with signer"
              />
            ),
            title: (
              <FormattedMessage
                id="9019494f-ef02-4bd0-96b3-84109e5d9b61"
                defaultMessage="Signer disconnected from document..."
              />
            ),
            duration: 0,
            removeSignal$: connectionState$,
          });
        }
      }
    });
    return () => sub.unsubscribe();
  }, [channel, simulated, meetingPlatform]);
  return connectionState;
}
