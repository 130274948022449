import "./footer_action_dropdown.scss";
import { useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import Icon from "common/core/icon";
import ClickOutside from "common/core/click_outside";

type Props = {
  actionItems: { action: () => void; text: ReactNode; key: string }[];
  sendDisabledReason?: ReactNode;
};
function FooterActionDropdown({ actionItems, sendDisabledReason = null }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  return (
    <ClickOutside onClickOutside={handleClose}>
      <div className="FooterActionDropdown">
        <Button
          buttonColor="action"
          variant="primary"
          disabled={Boolean(sendDisabledReason)}
          disabledHint={sendDisabledReason ? sendDisabledReason : undefined}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="FooterActionDropdown--label">
            <FormattedMessage id="1bd05b5e-76b2-456e-9f6d-db29221444a8" defaultMessage="Send">
              {(txt) => <span className="FooterActionDropdown--labelText">{txt}</span>}
            </FormattedMessage>
            <Icon size="large" name={`caret-down`} />
          </div>
        </Button>
        {isOpen && (
          <ul className="FooterActionDropdown--menu">
            {actionItems.map(({ action, text, key }) => (
              <li key={key}>
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    action();
                    handleClose();
                  }}
                >
                  {text}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </ClickOutside>
  );
}

export default FooterActionDropdown;
