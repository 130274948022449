import type { ReactNode } from "react";
import { Outlet } from "react-router-dom";

import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { useViewer } from "util/viewer_wrapper";
import { UserRole, Permissions } from "graphql_globals";

import type { ViewerCurrentUserRole as Viewer } from "./current_user_role_fragment.graphql";

export type UserPermissions =
  | "notaryPaymentInfo"
  | "terminationInfo"
  | "reclaimPayout"
  | "filterTerminatedMeetings"
  | "advancedTransactionDetails"
  | "seeNotarizationSummaryReadOnly"
  | "adminOnlySummaryDetails"
  | "summaryCreationSource"
  | "summaryLenderSection"
  | "summaryTitleAgencySection"
  | "transactionDetailsDateTimes"
  | "summaryTransactionScheduleInfo"
  | "paymentStatus"
  | "templateCreate"
  | "templatePreset"
  | "observeMeeting"
  | "summaryUnderWriter"
  | "toggleDesignation"
  | "refundTransaction"
  | "renderStripeLinks"
  | "buinessDesignationStyles"
  | "signerAltNames"
  | "videoSessionDetailedIds"
  | "showEmailViewOnSignerDetails"
  | "showSignerRecord"
  | "viewPhotoId"
  | "credentialVerification"
  | "fullDocumentRequirements"
  | "documentActions"
  | "assetDownloadAction"
  | "canReprocessDocuments"
  | "canViewSplittingResults"
  | "annotationDesignationUpdate";

export type AdminPermissions =
  | "canViewNotaries"
  | "createNotary"
  | "updateNotary"
  | "notaryDetails"
  | "notaryCertificateAuthority"
  | "canViewAdmins"
  | "canViewMeetingQueue"
  | "createAdmin"
  | "adminDetails"
  | "editAdmin"
  | "canViewCompanies"
  | "companyTeamDetails"
  | "companyTeamDetailsMerge"
  | "companyTeamDetailsEdit"
  | "createMemberDirectly"
  | "editOwner"
  | "requestMemberCreate"
  | "transactions"
  | "transactionDetails"
  | "summaryPayerInfo"
  | "companyDetails"
  | "editCompanyDetails"
  | "editCompanyDetailsBilling"
  | "editCompanyDetailsUnderwriters"
  | "editCompanyDetailsTier"
  | "editCompanyDetailsDocVisibility"
  | "editParentOrgNotaryAssignment"
  | "eligibilitySearch"
  | "exportMetabaseReports"
  | "addRecordingLocations"
  | "GIDDecoder"
  | "templateCopier"
  | "userSearchTool"
  | "userDetails"
  | "expireSignerIdentity"
  | "forceUserLogout"
  | "deleteUser"
  | "userTags"
  | "createUserTags"
  | "canViewAnalytics"
  | "meetingDetails"
  | "meetingDetailsVideo"
  | "editSettingsUpdateForm"
  | "editSignerName"
  | "editSignerPhone"
  | "editCustomerEmail"
  | "downloadTransactionDocuments"
  | "viewSettingsUpdateTool"
  | "createTier"
  | "tierDetails"
  | "editTier"
  | "provisionOrganization"
  | "forceCompleteTransactions"
  | "releaseTransactions"
  | "markBundleFree"
  | "editTransactionTitleAgency"
  | "banUser"
  | "qualityControl"
  | "refinalizeDocument"
  | "privacyEmailEdit"
  | "editOrgFlagsUpdateForm"
  | "viewSettingsUpdateForm"
  | "viewOrgFlagsUpdateForm"
  | "selfServeForceComplete"
  | "markCustomerAsFraudulent"
  | "canViewPanel"
  | "mfaReset"
  | "generateProofIdentityReport"
  | "viewProofReasonCodes"
  | "editProofReasonCodes"
  | "editDomainsVerification"
  | "revokeUserCert"
  | "viewDeepfakeAnalysis"
  | "showIdentityInfo";

export type OrganizationPermissions =
  | "viewTools"
  | "viewToolsReal"
  | "viewOrganizationDetails"
  | "editOrganizationDetails"
  | "viewOrganizationApiKeys"
  | "createOrganizationApiKeys"
  | "editOrganizationApiKeys"
  | "deleteOrganizationApiKeys"
  | "viewTeamDetails"
  | "addTeamMember"
  | "deleteTeamMember"
  | "editTeamMember"
  | "viewOrganizationTransactions"
  | "sendOrganizationTransactions"
  | "createOrganizationTransactions"
  | "deleteOrganizationTransactions"
  | "editOrganizationTransactions"
  | "issueRefundOrganizationTransactions"
  | "viewTransactionSigners"
  | "editTransactionSigners"
  | "viewTransactionSignersIdentity"
  | "accessSiloedTransactions"
  | "viewMeetings"
  | "createOrgNotaries"
  | "setTextTagSyntax"
  | "disableDocumentAutoTag"
  | "resubmitDocument"
  | "manageDeletedDocuments"
  | "requireWetSign"
  | "viewDownloadOriginalPdf"
  | "provideDocumentFeedback"
  | "useFreeTextDesignation"
  | "viewAnnotationToggles"
  | "viewVisibilityControl"
  | "editSignerPhoneOrgPermission"
  | "overrideEligibilityCheck"
  | "updateDocumentPosition"
  | "manageOpenOrders"
  | "editUnownedTransaction"
  | "duplicateTransaction"
  | "manageTemplates"
  | "manageLenderTemplates"
  | "manageTitleTemplates"
  | "viewSplittingResults"
  | "viewTransactionPii"
  | "updateReferralCampaigns"
  | "createReferralCampaigns"
  | "viewEasyLinks"
  | "updateEasyLinks"
  | "createEasyLinks"
  | "accessCommandCenter"
  | "commandCenterAccessControls"
  | "generateProofIdentityReport"
  | "viewIdentityCRM"
  | "commandCenterSettingProfiles";

// With the work to make it so that an organization account can both send and sign
// documents, we need to scope their role to the portal that they're in
function getUserRoleFromViewer({ user }: Viewer) {
  const roles = user?.roles || [];

  // Roles can be null if the user has a token but it is expired
  if (!roles.length) {
    return null;
  }

  switch (CURRENT_PORTAL) {
    case Apps.CUSTOMER:
      return roles.find((r) => r === UserRole.CUSTOMER);
    case Apps.LENDER:
    case Apps.TITLE_AGENCY:
    case Apps.BUSINESS:
      // The reason that we only care about the org member role and not whether the user is an admin/partner/employee
      // is because that is covered by the orgRole context value in organization_role_context. Do not try to get
      // the specific org member role from currentUserRole
      return roles.find((r) => r === UserRole.ORGANIZATION_MEMBER);
    case Apps.NOTARY:
      return roles.find((r) => r === UserRole.NOTARY);
    case Apps.ADMIN:
      return roles.find((r) => r === UserRole.ADMIN);
    default:
      return roles[0];
  }
}

export function usePermissions() {
  const { viewer } = useViewer<Viewer>();
  const currentUserRole = getUserRoleFromViewer(viewer) || null;
  const currentAdminPermissions = new Set(viewer.user?.adminProfile?.permissions || []);
  const currentOrganizationPermissions = new Set(
    viewer.user?.organizationMembership?.permissions || [],
  );

  function hasPermissionFor(
    permission: UserPermissions | AdminPermissions | OrganizationPermissions,
  ): boolean {
    switch (permission) {
      // admin
      case "advancedTransactionDetails":
      case "adminOnlySummaryDetails":
      case "summaryLenderSection":
      case "summaryCreationSource":
      case "summaryTitleAgencySection":
      case "summaryUnderWriter":
      case "transactionDetailsDateTimes":
      case "terminationInfo":
      case "seeNotarizationSummaryReadOnly":
      case "summaryTransactionScheduleInfo":
      case "observeMeeting":
      case "videoSessionDetailedIds":
      case "signerAltNames":
      case "showSignerRecord":
      case "credentialVerification":
      case "renderStripeLinks":
      case "viewDeepfakeAnalysis":
      case "showIdentityInfo":
        return currentUserRole === UserRole.ADMIN;
      // admin and notary
      case "notaryPaymentInfo":
        return (
          currentUserRole === UserRole.ADMIN ||
          Boolean(viewer.user?.roles?.includes(UserRole.NOTARY))
        );
      // notary
      case "showEmailViewOnSignerDetails":
      case "fullDocumentRequirements":
        return currentUserRole === UserRole.NOTARY;
      // org member
      case "buinessDesignationStyles":
        return currentUserRole === UserRole.ORGANIZATION_MEMBER;
      // document bundle actions
      case "assetDownloadAction":
      case "canReprocessDocuments":
      case "canViewSplittingResults":
      case "documentActions":
        return (
          currentUserRole === UserRole.ADMIN ||
          currentOrganizationPermissions.has(
            Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ACTIONS,
          )
        );
      // sender
      case "annotationDesignationUpdate":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ANNOTATIONVIEW__ANNOTATIONDESIGNATIONUPDATE,
        );

      // AdminProfile Permissions
      // fraud
      case "markCustomerAsFraudulent":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__CUSTOMER__EDIT__FRAUDSTATUS,
        );
      // panels
      case "canViewPanel":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__PANELS);
      // notaries
      case "canViewNotaries":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__NOTARIES);
      case "updateNotary":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__NOTARIES__EDIT);
      case "createNotary":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__NOTARIES__CREATE);
      case "notaryDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__NOTARIES__DETAILS);
      case "notaryCertificateAuthority":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__NOTARIES__EDIT__CERTAUTHORITY);
      // admin
      case "canViewMeetingQueue":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__QUEUESTATS);
      case "canViewAdmins":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ADMINS);
      case "createAdmin":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ADMINS__CREATE);
      case "adminDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ADMINS__DETAILS);
      case "editAdmin":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ADMINS__EDIT);
      // companies
      case "canViewCompanies":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS);
      // company team
      case "companyTeamDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__MEMBERS);
      case "companyTeamDetailsMerge":
        return (
          currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__MERGE) &&
          currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__MEMBERS)
        );
      case "companyTeamDetailsEdit":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__MEMBERS__EDIT);
      case "requestMemberCreate":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__MEMBERS__REQUESTCREATE,
        );
      case "createMemberDirectly":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__MEMBERS__CREATE,
        );
      case "editOwner":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__MEMBERS__EDIT__OWNER,
        );
      // company transactions
      case "transactions":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS);
      case "transactionDetails":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__DETAILS,
        );
      case "downloadTransactionDocuments":
        return (
          currentUserRole !== UserRole.ADMIN ||
          currentAdminPermissions.has(
            Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__DOWNLOADS,
          )
        );
      case "paymentStatus":
        return (
          currentUserRole === UserRole.ADMIN &&
          currentAdminPermissions.has(
            Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__DETAILS,
          )
        );
      case "provisionOrganization":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__PROVISION);
      case "summaryPayerInfo":
      case "reclaimPayout":
      case "refundTransaction":
        return (
          currentUserRole === UserRole.ADMIN &&
          currentAdminPermissions.has(
            Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT__ISSUEREFUND,
          )
        );
      case "editSignerName":
      case "editSignerPhone":
      case "editCustomerEmail":
        return (
          currentUserRole === UserRole.ADMIN &&
          currentAdminPermissions.has(
            Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT,
          ) &&
          currentAdminPermissions.has(Permissions.ADMINPROFILE__SIGNERS__EDIT) &&
          currentAdminPermissions.has(Permissions.ADMINPROFILE__SIGNERS)
        );
      // company details
      case "companyDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__DETAILS);
      case "editCompanyDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT);
      case "editCompanyDetailsBilling":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT__BILLING);
      case "editCompanyDetailsUnderwriters":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT__UNDERWRITERS,
        );
      case "editCompanyDetailsTier":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT__TIER);
      case "editCompanyDetailsDocVisibility":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT__DOCVISIBILITY,
        );
      case "editParentOrgNotaryAssignment":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT__PARENTORGNOTARYASSIGNMENT,
        );
      //  tools
      case "eligibilitySearch":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ELIGIBILITYSEARCH);
      case "exportMetabaseReports":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__EXPORTMETABASEREPORTS);
      case "addRecordingLocations":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__ADDRECORDINGLOCATIONS);
      case "GIDDecoder":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__GIDDECODER);
      case "templateCopier":
        return (
          currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__TEMPLATES) &&
          currentAdminPermissions.has(Permissions.ADMINPROFILE__ORGANIZATIONS__TEMPLATES__COPY)
        );
      case "userSearchTool":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS);
      // users
      case "userDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__DETAILS);
      case "expireSignerIdentity":
        return (
          currentAdminPermissions.has(Permissions.ADMINPROFILE__SIGNERS__EXPIREIDENTITIES) &&
          currentAdminPermissions.has(Permissions.ADMINPROFILE__SIGNERS)
        );
      case "forceUserLogout":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__FORCELOGOUT);
      case "deleteUser":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__DELETE);
      case "userTags":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__TAGS);
      case "createUserTags":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__TAGS__CREATE);
      case "banUser":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__BAN);
      case "mfaReset":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__MFARESET);
      case "revokeUserCert":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__REVOKECERT);
      // analytics
      case "canViewAnalytics":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__MEETINGS);
      case "meetingDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__MEETINGS__DETAILS);
      case "meetingDetailsVideo":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__MEETINGS__DETAILS__VIDEO);
      // settings
      case "viewSettingsUpdateTool":
        return (
          currentAdminPermissions.has(Permissions.ADMINPROFILE__SETTINGS__SYSTEM__EDIT) &&
          currentAdminPermissions.has(Permissions.ADMINPROFILE__SETTINGS__ORGANIZATIONS__EDIT)
        );
      case "editSettingsUpdateForm":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__SETTINGS__SYSTEM__EDIT);
      case "viewSettingsUpdateForm":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__SETTINGS__SYSTEM);
      // org flags
      case "editOrgFlagsUpdateForm":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__SETTINGS__ORGANIZATIONS__EDIT);
      case "viewOrgFlagsUpdateForm":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__SETTINGS__ORGANIZATIONS);
      // reason codes
      case "viewProofReasonCodes":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__SETTINGS__PROOFREASONCODES__VIEW,
        );
      case "editProofReasonCodes":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__SETTINGS__PROOFREASONCODES__EDIT,
        );
      // tiers
      case "tierDetails":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__FINANCE__TIERS__DETAILS);
      case "createTier":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__FINANCE__TIERS__CREATE);
      case "editTier":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__FINANCE__TIERS__EDIT);
      case "forceCompleteTransactions":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT__FORCECOMPLETE,
        );
      case "releaseTransactions":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT__RELEASE,
        );
      case "markBundleFree":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT__MARKBUNDLEFREE,
        );
      case "editTransactionTitleAgency":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT__TITLEAGENCY,
        );
      // 'NOT' PERMISSIONS ARE DEPRECATED. DO NOT ADD TO THESE PERMISSIONS CASES.
      case "viewPhotoId":
        return currentUserRole !== UserRole.ORGANIZATION_MEMBER;
      case "toggleDesignation":
        return currentUserRole !== UserRole.CUSTOMER;
      case "filterTerminatedMeetings":
        return !(currentUserRole === UserRole.ADMIN || currentUserRole === UserRole.NOTARY);
      case "qualityControl":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__QUALITYCONTROL);
      case "privacyEmailEdit":
        return currentAdminPermissions.has(Permissions.ADMINPROFILE__USERS__EDIT__EMAIL);
      case "refinalizeDocument":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__TRANSACTIONS__EDIT__REFINALIZEDOCUMENT,
        );
      case "editDomainsVerification":
        return currentAdminPermissions.has(
          Permissions.ADMINPROFILE__ORGANIZATIONS__EDIT__DOMAINS__VERIFICATIONS,
        );
      case "viewTools":
        // They can view tools if they have access to even one of:
        //   - Document templates
        //   - Referral campaigns
        //   - Easylinks
        return (
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__EASYLINKS) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__REFERRALS__UPDATE) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__REFERRALS__CREATE)
        );
      case "viewToolsReal":
        // They can view tools if they have access to even one of:
        //   - Document templates - Lender or Title
        //   - Referral campaigns
        //   - Easylinks
        return (
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES__LENDER) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES__TITLE) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__EASYLINKS) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__REFERRALS__UPDATE) ||
          currentOrganizationPermissions.has(Permissions.ORGANIZATION__REFERRALS__CREATE)
        );
      case "viewOrganizationDetails":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__DETAILS);
      case "editOrganizationDetails":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__DETAILS__EDIT);
      case "viewOrganizationApiKeys":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__APIKEYS);
      case "createOrganizationApiKeys":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__APIKEYS__CREATE);
      case "editOrganizationApiKeys":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__APIKEYS__EDIT);
      case "deleteOrganizationApiKeys":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__APIKEYS__DELETE);
      case "viewTeamDetails":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__MEMBERSHIPS);
      case "addTeamMember":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__MEMBERSHIPS__CREATE);
      case "deleteTeamMember":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__MEMBERSHIPS__DELETE);
      case "editTeamMember":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__MEMBERSHIPS__EDIT);
      case "viewOrganizationTransactions":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS);
      case "sendOrganizationTransactions":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS__ACTIVATE);
      case "createOrganizationTransactions":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS__CREATE);
      case "deleteOrganizationTransactions":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS__DELETE);
      case "editOrganizationTransactions":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS__EDIT);
      case "issueRefundOrganizationTransactions":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__ISSUEREFUND,
        );
      case "viewTransactionSigners":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS__SIGNERS);
      case "editTransactionSigners":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__SIGNERS__EDIT,
        );
      case "viewTransactionSignersIdentity":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__SIGNERS__IDENTITY,
        );
      case "accessSiloedTransactions":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__SUPERVISE,
        );
      case "viewMeetings":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__MEETINGS);
      case "selfServeForceComplete":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__EDIT__FORCECOMPLETE,
        );
      case "createOrgNotaries":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__NOTARIES__CREATE);
      case "setTextTagSyntax":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__SETTEXTTAGSYNTAX,
        );
      case "disableDocumentAutoTag":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__DISABLEAUTOTAG,
        );
      case "resubmitDocument":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__RESUBMIT,
        );
      case "manageDeletedDocuments":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__MANAGEDELETED,
        );
      case "requireWetSign":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__REQUIREWETSIGN,
        );
      case "viewDownloadOriginalPdf":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ANNOTATIONVIEW__DOWNLOADORIGINALPDF,
        );
      case "provideDocumentFeedback":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ANNOTATIONVIEW__FEEDBACK,
        );
      case "useFreeTextDesignation":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ANNOTATIONVIEW__FREETEXTDESIGNATION,
        );
      case "viewAnnotationToggles":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ANNOTATIONVIEW__TOGGLES,
        );
      case "viewVisibilityControl":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__ANNOTATIONVIEW__VISIBILITYCONTROLS,
        );
      case "editSignerPhoneOrgPermission":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__SIGNERS__EDITPHONE,
        );
      case "overrideEligibilityCheck":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__OVERRIDEELIGIBILITYCHECK,
        );
      case "updateDocumentPosition":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__UPDATEPOSITION,
        );
      case "manageOpenOrders":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__MANAGEORDERS,
        );
      case "editUnownedTransaction":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__EDIT__UNOWNED,
        );
      case "duplicateTransaction":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DUPLICATE,
        );
      case "manageTemplates":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES);
      case "manageLenderTemplates":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES__LENDER);
      case "manageTitleTemplates":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES__TITLE);
      case "viewSplittingResults":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__TRANSACTIONS__DOCUMENTS__VIEWSPLITTINGRESULTS,
        );
      case "templateCreate":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TEMPLATES__CREATE);
      case "templatePreset":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__PRESETTEMPLATES);
      case "viewTransactionPii":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__TRANSACTIONS__PII);
      case "updateReferralCampaigns":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__REFERRALS__UPDATE);
      case "createReferralCampaigns":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__REFERRALS__CREATE);
      case "viewEasyLinks":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__EASYLINKS);
      case "updateEasyLinks":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__EASYLINKS__UPDATE);
      case "createEasyLinks":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__EASYLINKS__CREATE);
      case "accessCommandCenter":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__COMMANDCENTER);
      case "commandCenterAccessControls":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__COMMANDCENTER__ACCESSCONTROLS,
        );
      case "commandCenterSettingProfiles":
        return currentOrganizationPermissions.has(
          Permissions.ORGANIZATION__COMMANDCENTER__SETTINGPROFILES,
        );
      case "generateProofIdentityReport":
        return (
          currentOrganizationPermissions.has(
            Permissions.ORGANIZATION__TRANSACTIONS__IDENTITY__REPORTS__VIEW,
          ) ||
          currentAdminPermissions.has(
            Permissions.ADMINPROFILE__ORGANIZATIONS__IDENTITY__REPORTS__VIEW,
          )
        );
      case "viewIdentityCRM":
        return currentOrganizationPermissions.has(Permissions.ORGANIZATION__IDENTITIES__CRM__VIEW);
    }
  }

  return { currentUserRole, hasPermissionFor };
}

export function PermissionRedirect({
  permissions,
  children,
  redirectRoute,
}: {
  permissions: OrganizationPermissions[];
  redirectRoute?: ReactNode;
  children?: ReactNode;
}) {
  const { hasPermissionFor } = usePermissions();

  return permissions.every(hasPermissionFor) ? <>{children || <Outlet />}</> : <>{redirectRoute}</>;
}
