import { useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { NetworkStatus, useQuery } from "util/graphql";
import { SettingsHeader, SettingsPageWrapper, SettingsTitle } from "common/settingsv2/common";
import NotaryInstructions from "common/details/instructions";
import LoadingIndicator from "common/core/loading_indicator";
import { MORTGAGE_TYPES } from "constants/mortgage_types";
import { Feature } from "graphql_globals";
import DefaultAuthenticationRequirementForm from "common/organization/default_authentication_form";
import { usePermissions } from "common/core/current_user_role";
import { OrganizationOverview } from "admin_portal/company/organization_details/overview";
import { OrganizationType } from "admin_portal/company/organization_details/type";
import { OrganizationFeatures } from "admin_portal/company/organization_details/features";
import { TransactionAccessLevel } from "admin_portal/company/organization_details/transaction_access_level";
import { Card } from "common/core/card";
import LenderProfileForm from "admin_portal/company/organization_details/lender_profile_form";
import { Evault } from "admin_portal/company/organization_details/evault";

import OrganizationDefaultsAdminForm from "./organization_defaults_admin_form";
import OrganizationTags from "./tags";
import MergeAccount from "./merge_account";
import ContactEmail from "./contact_email";
import FullAdminOrganizationDetailsQuery, {
  type FullAdminOrganizationDetails_viewer as Viewer,
  type FullAdminOrganizationDetails_organization_Organization as Organization,
} from "./details_query.graphql";
import SmallAdminOrganizationDetailsQuery from "./details_small_query.graphql";

type LoadedProps = {
  viewer: Viewer;
  organization: Organization;
  refetch: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  orgDetails: {
    id: "e01fa5b5-69e1-44d6-8a16-276b660332ed",
    defaultMessage: "Organization Details",
  },
});

function getMortgageType({
  lenderAccess,
  titleAgencyAccess,
  titleUnderwriterAccess,
}: Organization) {
  if (titleAgencyAccess) {
    return MORTGAGE_TYPES.TITLE;
  } else if (lenderAccess) {
    return MORTGAGE_TYPES.LENDER;
  } else if (titleUnderwriterAccess) {
    return MORTGAGE_TYPES.UNDERWRITER;
  }
  return null;
}

function CompanyDetails(props: LoadedProps) {
  const { organization, viewer, refetch } = props;
  const { featureList } = organization;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const [organizationMortgageType, setOrganizationMortgageType] = useState<string | null>(
    getMortgageType(organization),
  );

  const canUpdateCompany = hasPermissionFor("editCompanyDetails");
  const notesSupported = featureList.includes(Feature.NOTARY_NOTES);

  return (
    <SettingsPageWrapper>
      <SettingsHeader title={intl.formatMessage(MESSAGES.orgDetails)} />

      <OrganizationOverview organization={organization} refetch={refetch} />

      <TransactionAccessLevel organization={organization} refetch={refetch} />

      <OrganizationFeatures organization={organization} />

      <ContactEmail organization={organization} />

      {canUpdateCompany && (
        <OrganizationType
          setMortgageType={setOrganizationMortgageType}
          organization={organization}
          viewer={viewer}
          organizationMortgageType={organizationMortgageType}
          refetch={refetch}
        />
      )}

      {organizationMortgageType === MORTGAGE_TYPES.LENDER && (
        <LenderProfileForm organization={organization} />
      )}

      {canUpdateCompany && <Evault organization={organization} />}

      {canUpdateCompany && <OrganizationTags organization={organization} />}

      {hasPermissionFor("companyTeamDetailsMerge") && <MergeAccount organization={organization} />}

      {notesSupported && (
        <>
          <SettingsTitle>
            <FormattedMessage
              id="d8d375ea-969a-473d-a13a-5827a8e96806"
              defaultMessage="Notary Instructions"
            />
          </SettingsTitle>
          <Card>
            <NotaryInstructions
              organization={organization}
              viewer={{ ...viewer, user: { ...viewer.user!, organization: null } }}
              bundle={null}
              refetch={refetch}
            />
          </Card>
        </>
      )}

      <DefaultAuthenticationRequirementForm organizationId={organization.id} />

      <OrganizationDefaultsAdminForm organization={organization} />
    </SettingsPageWrapper>
  );
}

function CompanyDetailsContainer() {
  const { globalID } = useParams();
  const resultSmall = useQuery(SmallAdminOrganizationDetailsQuery, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    variables: { organizationId: globalID! },
  });
  const smallOrg = resultSmall.data?.organization as Organization | undefined;
  const resultFull = useQuery(FullAdminOrganizationDetailsQuery, {
    skip: !smallOrg,
    variables: {
      organizationId: globalID!,
      organizationType: smallOrg?.organizationType.toLowerCase() || "",
    },
  });
  if (
    resultFull.networkStatus === NetworkStatus.loading ||
    resultSmall.loading ||
    !resultFull.data
  ) {
    return <LoadingIndicator />;
  }
  const { organization, viewer } = resultFull.data;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }
  return (
    <CompanyDetails
      refetch={() => resultFull.refetch()}
      organization={organization}
      viewer={viewer}
    />
  );
}

export default CompanyDetailsContainer;
