import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import { Payer } from "graphql_globals";
import FormattedPrice from "common/core/format/formatted_price";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormRowHelpTooltip } from "common/form/form_row_help_tooltip";
import { DeprecatedSelectField } from "common/form/fields/select";

const MESSAGES = defineMessages({
  whoPaysPlaceholder: {
    id: "4afe7188-3e87-481d-befb-0ffb828942cf",
    defaultMessage: "Choose an option…",
  },
  whoPaysLabel: {
    id: "384ec8a8-c51d-4ecb-bae6-cb0924b80cce",
    defaultMessage: "Who pays",
  },
});

function PayerSelectRow({
  useModalStyle,
  fieldName,
  companyName,
  onChange,
  showHelp,
  prices,
  intl,
}) {
  const payerOptions = [
    { label: companyName || "Company", value: Payer.ORGANIZATION },
    { label: "Signer", value: Payer.CUSTOMER },
  ];

  return (
    <FormGroup fields={[fieldName]} disableFormRowStyle={useModalStyle}>
      {useModalStyle ? (
        <DeprecatedSelectField
          id="who-pays"
          data-automation-id="select-payment-field"
          items={payerOptions}
          name={fieldName}
          value="CUSTOMER"
          label={
            <FormattedMessage
              id="b188741b-13cf-48c0-a3c7-b5bf5af1ddb8"
              defaultMessage="Who will be paying for the transactions?"
            />
          }
          displayRequiredAsterisk
          onChange={onChange}
          aria-invalid="false"
          useCoreFormInput
        />
      ) : (
        <>
          <label htmlFor="who-pays">{intl.formatMessage(MESSAGES.whoPaysLabel)}</label>
          <DeprecatedSelectField
            id="who-pays"
            data-automation-id="select-payment-field"
            name={fieldName}
            placeholder={intl.formatMessage(MESSAGES.whoPaysPlaceholder)}
            items={payerOptions}
            onChange={onChange}
          />

          {showHelp && (
            <DeprecatedFormRowHelpTooltip tooltipPlacement="bottom" iconOutside>
              <FormattedMessage
                id="bf6af09a-940e-4110-826b-6623d8be56a1"
                defaultMessage="Notarizations and identity confirmations start at {firstSealPrice}. Choose to pay for this fee or pass payment onto the signers."
                values={{
                  firstSealPrice: <FormattedPrice cents={prices.firstSeal} />,
                }}
              />
            </DeprecatedFormRowHelpTooltip>
          )}
        </>
      )}
      <FormGroupErrors fields={[fieldName]} />
    </FormGroup>
  );
}

PayerSelectRow.propTypes = {
  useModalStyle: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  showHelp: PropTypes.bool,
  companyName: PropTypes.string,
  onChange: PropTypes.func,
  prices: PropTypes.object.isRequired,

  // react-intl
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PayerSelectRow);
