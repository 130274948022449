import classnames from "classnames";

import type { RetakeManager } from "common/identity_verification/retake/notary";

import DocumentPlaceholder from "./document_placeholder";
import DocumentScrutinizer from "./scrutinizer";
import {
  type DocumentViewerPhoto,
  type IdentityDocumentViewerEngine,
  parsePhotosForDocumentViewer,
  type PhotoRequirements,
  useIdentityDocumentViewerEngine,
} from "./engine";
import Actions from "./actions";
import Title from "./title";
import Styles from "./index.module.scss";

type Props = {
  photos: ReturnType<typeof parsePhotosForDocumentViewer>;
  requirements: PhotoRequirements;
  retakeManager?: RetakeManager;
  className?: string;
  onPopout?: () => void;
  isSimplifiedViewer?: boolean;
  retakeForSelfieOnly?: boolean;
};

export function ControlledIdentityDocumentViewer(
  props: Omit<Props, "photos" | "requirements"> & {
    engine: IdentityDocumentViewerEngine;
  },
) {
  const { retakeManager, engine, className, onPopout, isSimplifiedViewer, retakeForSelfieOnly } =
    props;
  const { currentPhoto, currentPhotoType, documentUrl } = engine;

  return (
    <div className={classnames(Styles.main, className)}>
      <Title
        photo={currentPhoto}
        photoType={currentPhotoType}
        retakeManager={retakeManager}
        isSimplifiedViewer={isSimplifiedViewer || retakeForSelfieOnly}
      />
      {retakeManager?.inProgress || !documentUrl ? (
        <div className={Styles.placeholderContainer}>
          <DocumentPlaceholder
            className={Styles.placeholderContent}
            retakeManager={retakeManager}
            engine={engine}
            isSimplifiedViewer={isSimplifiedViewer}
          />
        </div>
      ) : (
        /* here we want the scrutinizer to reset state with key when we change document */
        <DocumentScrutinizer
          key={documentUrl}
          src={{
            type: documentUrl.endsWith(".pdf") ? "pdf" : "image",
            url: documentUrl,
          }}
          onPopout={onPopout}
        />
      )}

      <Actions
        engine={engine}
        retakeManager={retakeManager}
        isSimplifiedViewer={isSimplifiedViewer}
        retakeForSelfieOnly={retakeForSelfieOnly}
      />
    </div>
  );
}

function IdentityDocumentViewer(props: Props) {
  const engine = useIdentityDocumentViewerEngine({
    photos: props.photos,
    requirements: props.requirements,
  });
  return <ControlledIdentityDocumentViewer {...props} engine={engine} />;
}

export default IdentityDocumentViewer;
export {
  parsePhotosForDocumentViewer,
  useIdentityDocumentViewerEngine,
  type IdentityDocumentViewerEngine,
  type DocumentViewerPhoto,
};
export {
  getSpecificDocumentTitleFromClaimedType,
  getGenericDocumentTitleFromType,
  getIndividualRetakeButtonTextFromType,
} from "./title";
