import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";

export function ConfirmSaveEditsModal({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: () => void;
}) {
  return (
    <WorkflowModal
      autoFocus
      footerSeparator={false}
      isSensitive={false}
      title={
        <FormattedMessage
          id="505ffb3f-b359-49c5-aca9-246f7312000b"
          defaultMessage="Continue saving changes without updating"
        />
      }
      buttons={[
        <Button variant="tertiary" buttonColor="dark" key="cancel" onClick={onCancel}>
          <FormattedMessage id="2c87ae58-2deb-4cca-be7c-8a3ea121ab37" defaultMessage="Cancel" />
        </Button>,
        <Button buttonColor="action" variant="primary" key="save" onClick={onConfirm}>
          <FormattedMessage
            id="6aab5d05-a743-4614-a349-173aca0c9047"
            defaultMessage="Save changes"
          />
        </Button>,
      ]}
    >
      <Paragraph>
        <FormattedMessage
          id="b1bc82f6-d56b-45ea-9a36-13c89af34f68"
          defaultMessage={`Your changes will be saved in "Editing" mode.{br}Recipients can't view this transaction if it stays in "Editing" mode.`}
          values={{ br: <br /> }}
        />
      </Paragraph>
    </WorkflowModal>
  );
}
